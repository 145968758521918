import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


// const httpOptions = {
//   headers: new HttpHeaders({
//   }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
// };

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class LookupsDefinitionService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 
	createLookup(data, reqBody): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "lookup/createLookup?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("createLookup")))
	  }


	getlookupSearch(data, reqBody): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "lookup/lookupsearch?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getlookupSearch")))
	  }	


	  getLookupTypes() : Observable<any>{
		let initialUrl = this.defaultUrl + "lookup/getLookupTypes";
		return this.http
	      .post(initialUrl, '', httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getLookupTypes")))

	  }


	  displayLookupRecord(data, reqBody): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "lookup/displayLookupRecord?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("displayLookupRecord")))
	  }	

	  
	  updateColumnSeq(reqBody): Observable<any> {
	    let initialUrl = this.defaultUrl + "lookup/switchcolumns";
		console.log("Printing initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("updateColumnSeq")))
	  }

	  createColumn(reqBody): Observable<any> {
	    let initialUrl = this.defaultUrl + "lookup/createLookupRecord";
		console.log("Printing initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("createColumn")))
	  }

	  updateColumn(reqBody): Observable<any> {
	    let initialUrl = this.defaultUrl + "lookup/updateColoumns";
		console.log("Printing initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("updateColumn")))
	  }

	  // deleteColumn
	  deleteColumn(reqBody): Observable<any> {
	    let initialUrl = this.defaultUrl + "lookup/updateColoumns";
		console.log("Printing initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("deleteColumn")))
	  }


}


