import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { LookupsDefinitionService } from './lookups-definition.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-lookups-definition',
  templateUrl: './lookups-definition.component.html',
  styleUrls: ['./lookups-definition.component.css']
})
export class LookupsDefinitionComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  lookupId:any;
  lookupData:any = [];

  headerlookupType:any;
  headerLookupId:any;
  headerlookupName:any;
  headerlookupDescription:any;
  headerlookupViewName:any;
  recPerPage=10;
  pageNum:any=1;
  numOfRecsMsg:any="";
  arrPgLinks:any=[];
  count:any=0;
  deleteButtonDisplayFlag:boolean=false;
  currentRecord:any={};
  newColumnType:any ="Char";
  newMandatoryFlag:any ='Y';
  editlookupColumnName:any;
  editlookupColumnNameSec:any;
  editdisplayColumnName:any;
  editMandatoryFlag:any="Y";
  newlookupColumnSeq:any;
  newlookupColumnName:any;
  newdisplayColumnName:any;
  lookupTypes:any=[];
  selectedLookupType:any="";


  constructor(private activatedRoute: ActivatedRoute, private lookupsDefinitionService: LookupsDefinitionService, private router: Router,private commonService:CommonServiceService) { 
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['lookupId']!=undefined)
      this.lookupId = params['lookupId'];

    });
  }

  ngOnInit() {
    if(this.lookupId!=undefined){
      var reqBody= {
        "lookupId": this.lookupId,
        "lookupEntity": "",
        "count":"",
        "userId":"",
        "lookupName": "",
        "lookupDescription":"",
        "startDate":"",
        "endDate": "",
        "lookupColumnName":"",
        "displayName":"",
        "columnType": "",
"displayLength": "",
"mandatoryFlag":"",
"lookupColumnId":"",
"action":""
      }

      var param = {
        lookupEntity: ""
      }
      this.lookupsDefinitionService.displayLookupRecord(param, reqBody).subscribe(
        data=>{
          console.log(data);

          this.headerlookupType= data.Headers[0].lookupType;
          this.headerLookupId= data.Headers[0].lookupId;
          this.headerlookupName = data.Headers[0].lookupName;
          this.headerlookupDescription = data.Headers[0].lookupDescription;
          this.headerlookupViewName = data.Headers[0].lookupViewName;

          this.lookupData = data.Columns;
        
          this.lookupData.sort((a,b)=> parseInt(a.columnSeq) - parseInt(b.columnSeq));
          console.log("Printing data for lokup definition: ",this.lookupData);
          this.count= data.count;
          this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
          this.arrPgLinks=this.commonService.getPgLinks(this.count,this.pageNum,this.recPerPage);


      }
      );
      
    }
    else{

      this.lookupsDefinitionService.getLookupTypes().subscribe(
        data=>{
          console.log(data.data);
          this.lookupTypes=data.data;
        }
      );
    // enableParams(false);
    // setLookupTypeDropdown(); 
    }
 
  }

  selectLookup(value){
    console.log(value);
    if(value!=""){
		$('#lookupSubmit').removeAttr('disabled');
    $('#headerlookupName').removeAttr("disabled");


    }
    else {
      $('#lookupSubmit').attr("disabled","disabled");
      $('#headerlookupName').attr("disabled","disabled");
    }

  
  }

  createLookup(){
    this.headerlookupName=this.headerlookupName.trim();
    this.headerlookupDescription=this.headerlookupDescription.trim();
    var body ={
      "lookupName": this.headerlookupName,
      "lookupDescription": this.headerlookupDescription

    };
 var param = {
  "lookupEntity": this.selectedLookupType

 };
 this.lookupsDefinitionService.createLookup(param,body ).subscribe(
  data=>{
    console.log(data);
    this.lookupId= data.lookupId;
    this.ngOnInit();
  }
 );



  }

  lookupSearch(){
    this.router.navigate(['definelookups']);
  }

  rowClick(lookup){
    this.currentRecord=lookup;
    console.log("Printing lookup value: " );
    console.log(lookup);
    if(lookup.dataExistsFlag=="Y"){
      this.deleteButtonDisplayFlag=false;

    }
    else{
      this.deleteButtonDisplayFlag=true;
    }

  this.editlookupColumnName=lookup.lookupName;
  this.editdisplayColumnName=lookup.displayColumnName;
  this.editMandatoryFlag=lookup.mandatoryFlag;
  this.newlookupColumnSeq= lookup.columnSeq;
  this.editlookupColumnNameSec=lookup.lookupColumnName;


  }

updateColumnSeq(){
    this.blockUI.start();
var reqBody ={
  "id" : this.currentRecord.lookupId,
  "subId":this.currentRecord.lookupColumnId,
  "newSeq": this.newlookupColumnSeq,
  "scType": 'lookup'
} 
  
  this.lookupsDefinitionService.updateColumnSeq(reqBody).subscribe(
    data=>{
      console.log(data);
      this.ngOnInit();
      this.blockUI.stop();

    }
  );
  }


createColumn(){
this.blockUI.start();
this.newlookupColumnName=this.newlookupColumnName.trim();
this.newdisplayColumnName=this.newdisplayColumnName.trim();
var reqBody ={
  "lookupEntity":this.selectedLookupType,
  "lookupId" : this.headerLookupId,
  "lookupName":this.headerlookupName,
  "lookupColumnName": this.newlookupColumnName,
  "displayName": this.newdisplayColumnName,
  "columnType": this.newColumnType,
  "displayLength": 4000,
  "mandatoryFlag": this.newMandatoryFlag
} 
console.log("Printing createColumn reqBody data", reqBody);
  
  this.lookupsDefinitionService.createColumn(reqBody).subscribe(
    data=>{
      console.log("Printing createColumn response data", data);
      this.ngOnInit();
      this.newlookupColumnName="";
      this.newdisplayColumnName="";
      this.newColumnType="Char";
      this.newMandatoryFlag="Y";
      this.blockUI.stop();

    }
    
  );
  }

  updateColumn(){
    this.blockUI.start();
    var reqBody ={
      "lookupEntity":"",
      "lookupId" : this.headerLookupId,
      "lookupColumnId":this.currentRecord.lookupColumnId,
      "displayLength": 4000,
      "displayName": this.editdisplayColumnName,
      "lookupColumnName": this.editlookupColumnNameSec,
      "mandatoryFlag": this.editMandatoryFlag,
      "action": "edit"
    } 
    console.log("Printing updateColumn reqBody data", reqBody);
      this.lookupsDefinitionService.updateColumn(reqBody).subscribe(
        data=>{
          console.log(data);
          this.ngOnInit();
          this.blockUI.stop();
    
        }
      );
      }


      deleteColumn(){
        this.blockUI.start();
        var reqBody ={
          "lookupEntity":"",
          "lookupId" : this.currentRecord.lookupId,
          "lookupColumnId":this.currentRecord.lookupColumnId,
          "displayLength": 4000,
          "displayName": this.editdisplayColumnName,
          "lookupColumnName": this.editlookupColumnNameSec,
          "mandatoryFlag": this.editMandatoryFlag,
          "action": "remove"
        } 
          
          this.lookupsDefinitionService.deleteColumn(reqBody).subscribe(
            data=>{
              console.log(data);
              this.ngOnInit();
              this.blockUI.stop();
        
            }
          );
          }     


}