import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class MarketingAdminService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util: Util) {
      this.defaultUrl = this.util.getBaseUrl();
  }

  loadDefault = function(oParams){
    let url = this.defaultUrl + "common/service/parties";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("parties"))));
  }


  saveContent = function(oParams){
    let url = this.defaultUrl + "common/service/saveHomePage";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("saveHomePage"))));
  }
}
