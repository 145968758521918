import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[NumberField]'
})
export class NumberFieldDirective {

  constructor(private elementRef: ElementRef) { }
 
  /**
   * Key board action
   * @param event 
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'
    ];
    if (
      // Allow special keys
      allowedKeys.includes(event.key) ||
      // Allow Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
      (event.ctrlKey && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) ||
      // Allow numbers
      (/^[0-9]$/.test(event.key))
    ) {
      return;
    }
    // Prevent the default key behavior
    event.preventDefault();
  }
   
 
}
