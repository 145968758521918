import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { DatePipe } from '@angular/common';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-configurator-myquotes',
  templateUrl: './configurator-myquotes.component.html',
  styleUrls: ['./configurator-myquotes.component.css']
})
export class ConfiguratorMyquotesComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  quoteList: any =[];
  totalRecords=0;
  pageNum=1;
  recPerPage=10;
  start:number=0;
  end:number=10;
  sDate="";
  qName="";
  qStatus="";
  qStartDate="";
  qEndDate="";
  length=1000;
  searchText: string;
  searchFilterPropertyNames : string[] = ['creationDate','quoteName','quoteNumber','status'];
  defaultUrl: string;
  eDate: string ="";
 
  constructor(private route : ActivatedRoute,private configquoteService:ConfiguratorQuotesService,private util : Util,public datepipe: DatePipe,private titleService: Title,private router: Router) { 
    this.defaultUrl = this.util.getBaseUrl();}

  ngOnInit() {
    this.titleService.setTitle("Configurator Quotes");
    this.getQuotes(1,null);
  }
  getQuotes(pgNum: any,event: any){
   
    //this.pleaseWaitCls=true;
    this.pageNum=pgNum;
    this.blockUI.start();
    if(this.qStartDate)
    {
       this.sDate = this.datepipe.transform(this.qStartDate, 'MM/dd/yyyy');
    }
    if(this.qEndDate)
    {
      this.eDate = this.datepipe.transform(this.qEndDate, 'MM/dd/yyyy');
    }
    let oParams = {"qName":this.qName,"qStatus":this.qStatus,"qStartDate":this.sDate,"qEndDate":this.eDate,"start":this.start,"length":this.length};
    let data = this.configquoteService.configQuoteGenericMtd(oParams,'getQuotes').subscribe(
      data=>{        
         this.totalRecords=data.iTotalRecords; 
         this.blockUI.stop();     
         if(this.totalRecords == 0) {
          }
        else
        {
          this.quoteList = data.data;     
          console.log("quoteList:"+this.quoteList);   
        }
        
      });
      
  }
  viewQuote(quoteNum: any,event: { preventDefault: () => void; })
  {
    if(event)
    {
      event.preventDefault();

    }
    let oParams = { "quoteId": quoteNum};
                const navigationExtras: NavigationExtras = {
                  queryParams: oParams
      
                };
                this.router.navigate(['/configsummary'], navigationExtras);
           
  }
  downloadQuote(qNum:any,event){
    if(event){
      event.preventDefault();
    }
    var url= this.defaultUrl + "configurator/downloadQuotePdf?quoteId="+qNum;
    window.open(url);

  }
  createQuoteRedirect(event){
    if(event){
      event.preventDefault();
    }
    let oParams = { "quoteId": -1};
    const navigationExtras: NavigationExtras = {
      queryParams: oParams

    };
    this.router.navigate(['/createquote'], navigationExtras);

  }
  clear(event: { preventDefault: () => void; }) {
    if(event)
    {
      event.preventDefault();

    }
    this.qName = '';
    this.qStatus = '';
    this.qStartDate = '';
    this.qEndDate = '';
    this.sDate = '';
    this.eDate = '';
    this.getQuotes(1,null);
  }
 
}
