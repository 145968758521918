export class TonerCustomerInfo{

    custName       :string;
    accountNumber  :string;
    address        :string;
    city           :string;
    state          :string;
    zipCode        :string;
    firstName      :string;
    lastName      :string;
    contactPhone   :string;

    constructor (){
        this.custName       ="";
        this.accountNumber  ="";
        this.address        ="";
        this.city           ="";
        this.state          ="";
        this.zipCode        ="";
        this.firstName      ="";
        this.lastName       ="";
        this.contactPhone   ="";

    }


}