import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { RoleDefinitionService } from 'src/app/webadmin/role-definition/role-definition.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-definition',
  templateUrl: './role-definition.component.html',
  styleUrls: ['./role-definition.component.css']
})
export class RoleDefinitionComponent implements OnInit {

   constructor(private commonService:CommonServiceService, private roleDefService : RoleDefinitionService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title, private activatedRoute: ActivatedRoute) { }

  apps = [];
  app_ID;
  app_Code;
  appCodestar=false;

  entities = [];
  entity_ID;
  
  role_TEXT;

  roles = [];
  rolesCount;

  recPerPage = 10;
  numOfRecsMsg;
  arrPgLinks = [];
  pageNum: number = 1;


  ngOnInit() {
	
	this.getAppsAndEntities();
	
  }
 
  async getAppsAndEntities() {
		var resp = await this.roleDefService.getAppsAndEntitiesSync(); // .subscribe(resp => {
		if (resp != null) {
			console.log('getAppsAndEntities():', resp);
			this.apps = resp.apps;
			this.app_ID = this.apps[0].id;
			this.app_Code = this.apps[0].appCode;
			this.entities = resp.entities;
			this.onSearch(1, null);
		}
		// });	
  } 

  onSearch(pageNumber, event:any){
	
	console.log("searchRole params : ", this.app_ID , this.entity_ID , 	this.role_TEXT);
	
	
	if(event!=null){
      	event.preventDefault();
    }	
	
	this.pageNum = pageNumber;
		
	 var param = {
		"from" : ( (this.pageNum -1 ) * (this.recPerPage) ) + 1,
		"to" : this.pageNum * this.recPerPage ,
		"app_id" : this.app_ID, //2617,
		"entity_id" : this.entity_ID, // 19040
		"role_text" : this.role_TEXT
	};
	
	this.roleDefService.rolesSearch(param).subscribe(resp => {
			if (resp != null) {
				console.log('rolesSearch():', resp);
				this.roles = resp.roles;
				
				console.log(JSON.stringify(this.roles[1]));
				
				this.rolesCount = resp.count;
				
				this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.rolesCount,this.recPerPage,this.pageNum);
	         	this.arrPgLinks=this.commonService.getPgLinks(this.rolesCount,this.pageNum,this.recPerPage);			
				
			}
		});	
	
  }

  onReset() {
	
	this.app_ID = "";
	this.entity_ID = "";
	this.role_TEXT = "";
	
  }

  onCreateRole() {
	
	var roleObj = {
		appId : this.app_ID,
		appCode : 'CUSTOMER_PORTAL',
		roleName : "",
		roleDescription :"",
		show_external_flag : 'N' ,
		portletFlag : 'N'
	};
	
	var roleInfo = JSON.stringify(roleObj);
	
	this.route.navigate(["rolemanage" , {role :roleInfo, portletFlag : 'N', isCreateMode : true } ] );	
  }

  manageRole(roleIdx){
	
	var roleInfo = JSON.stringify(this.roles[roleIdx]);
	this.route.navigate(["rolemanage" , {role :roleInfo, portletFlag : 'N'} ] );
	
  }	

}
