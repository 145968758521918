import { Component, HostListener, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { SubmitPaymentService } from './submit-payment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-submit-payment',
  templateUrl: './submit-payment.component.html',
  styleUrls: ['./submit-payment.component.css']
})
export class SubmitPaymentComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  invoicePaymentList:any=[];
  selectedInvoiceList:any=[];
  status:string = null;
  isOpen:boolean = false;
  isEdit:boolean = false;
  selectedRadio:string = "OTP";
  retryCount:number = 0;
  showACH:boolean = false;
  showCC:boolean = false;
  newCCflg:boolean = false; 
  usedCCflg:boolean = false; 
  showNewAch:boolean = false; 
  usedACHflg:boolean = false;  
  isACHOpen:boolean = false;
  pciUrlforAch:SafeResourceUrl = "";
  achUrl:SafeResourceUrl = "";
  PciUrl:SafeResourceUrl = "";
  achPaymentList:any=[];
  ccPaymentList:any=[];
  intervalForAch:any = '';
  sequenceNumber:string = '';
  paymentSystem:string = '';
  accountNumber:string = '';
  isACHSuccess:boolean = false;
  authCode:string = ''; 
  authAmount:string = '';
  paymentDate:Date = new Date();
  isError:boolean=false;
  paymentError:boolean = false;
  errMsg:string = '';
  selectedInvoice:any = '';
  target:any = '';
  selectedCard:string = '1';
  selectedAch:string = '1';
  selectedRadioCC:string = '';
  selectedRadioACH:string = '';
  hideExpDate:boolean = true;
  showCCErr:boolean = false; 
  showACHErr:boolean = false;
  expDate:string = '';
  pattern:RegExp = /^[0-9]+\.?[0-9]*$/;
  noneSelected:boolean = false; 
  ccExpDate = "";
  creditCardType = "";
  pmtFlg = "N";
  profileId = "";
  date:any="";
  retCCStatus="";
  enableCCSubmit:boolean = false;
	enableACHSubmit:boolean = false;
  transactionId = "";
  displayPaymentNote = false;
  newACHSequenceNum = '';
  hideRoutingNum = true;
  selectedACHRoutingNum = '';
  achEnabled = "N";
  amtErrMsg = '';
  phoneNum = '';

  constructor(private submitPaymentService:SubmitPaymentService, 
              private sanitizer: DomSanitizer, 
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
   
    this.blockUI.start();
    this.selectedInvoiceList.forEach(invoice => {
      invoice.isPaidInFull = true;
      invoice.amountPaid = parseFloat(invoice.amountDue).toFixed(2) + "";        
    }); 

    var paramObj = {};	
    paramObj["Payinvoice"] = JSON.stringify(this.selectedInvoiceList);
    
    let oParams = paramObj;

    const paymentData = this.submitPaymentService.getPaymentDetails(oParams);

    const ccListData = this.submitPaymentService.getCCList();

    const achListData = this.submitPaymentService.getACHList();

    forkJoin([paymentData, ccListData, achListData]).subscribe(
      data => {

        this.achPaymentList = data[2]['achPaymentList'];

        this.ccPaymentList = data[1]['ccPaymentList'];

        this.sequenceNumber = data[0]['sequenceNumber'];
        this.newACHSequenceNum = data[0]['sequenceNumber'];
        this.paymentSystem = data[0]['paymentSystem'];
        this.accountNumber = data[0]['accountNumber'];

        this.selectedInvoiceList.forEach(invoice => {
          invoice.custNum = this.accountNumber;
        });   

        this.achUrl = data[0]['achUrlWithoutSeq'];
        this.achEnabled = data[0]['achEnabled'];
        this.PciUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data[0]['newPciUrl']);
        //this.phoneNum = data[0]['phoneNum'];
        this.blockUI.stop();
       
      }
    )

    /* this.submitPaymentService.getPaymentDetails(oParams).subscribe(
      data=>{
        this.sequenceNumber = data.sequenceNumber;
        this.newACHSequenceNum = data.sequenceNumber;
        this.paymentSystem = data.paymentSystem;
        this.accountNumber = data.accountNumber;

        this.selectedInvoiceList.forEach(invoice => {
          invoice.custNum = this.accountNumber;
        });   

        this.achUrl = data.achUrlWithoutSeq;
        this.achEnabled = data.achEnabled;
        this.PciUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.newPciUrl);
        this.blockUI.stop();   
    });

    this.submitPaymentService.getCCList().subscribe(
      ccData=>{
        this.ccPaymentList = ccData.ccPaymentList;
        this.blockUI.stop();
      }
    );

    this.submitPaymentService.getACHList().subscribe(
      achData=>{
        this.achPaymentList = achData.achPaymentList;
        this.blockUI.stop();
      }
    ); */
  }

  expandCollapseACH() {	
    this.showACH = true;
    this.showCC = false;
    this.newCCflg = false; 
    this.usedCCflg = false; 
    this.selectedRadioCC = '';
    this.selectedRadioACH = '';
	  this.hideRoutingNum = true;
	  this.selectedAch = '1';
    this.usedACHflg = false;
  }
   
  expandCollapseCC() {	
    this.showCC = true;
    this.showACH = false;
    this.showNewAch = false; 
    this.usedACHflg = false;  
    this.selectedRadioACH = '';
    this.selectedRadioCC = '';
    this.hideExpDate = true;
    this.isOpen=false;
    this.selectedCard = 'Select CC';
    this.showCCErr = false;
    this.usedCCflg = false;
    this.newCCflg = false;
  }

  toggleSaveEdit(value:any) {
    if(value == 'edit'){
      this.isEdit = true;  
      this.usedCCflg = false;
      this.showCC = false;
      this.showACH = false;
      this.showNewAch = false;
    }else{
      let amtError = false;
      for (var i=0; i<this.selectedInvoiceList.length; i++){	
          var invoice = this.selectedInvoiceList[i];
          this.amountCheck(invoice);
          if(this.amtErrMsg != ''){
            amtError = true;
          }
      }

      if(!amtError){
        this.isEdit = false;
        this.selectedInvoice = {};
      }
    }
    //this.isEdit = (value == 'edit') ? true : false; 
  }

  updateValue(newValue: number) {
    // Use the number pipe to format the value to two decimal places
    //this.amountPaid = parseFloat(newValue.toFixed(2));
    console.log('new value ', newValue);
  }

  amountCheck(invoice) {
    this.amtErrMsg = '';
    this.selectedInvoice = invoice;

    let amtPaid = invoice.amountPaid;
    let amtDue = invoice.amountDue; 

    if(amtPaid=="" || amtDue==""){
      this.amtErrMsg = 'Amount required.'
    }else if(isNaN(amtPaid) || isNaN(amtDue) || amtPaid=="" || amtDue==""){
      this.amtErrMsg = 'Amount not valid.'
    }else{
      var amountDue = parseFloat(amtDue).toFixed(2);
      var enteredVal = parseFloat(amtPaid).toFixed(2);
      if(Number(amountDue) < Number(enteredVal)){
        this.amtErrMsg = 'Amount should be less than or equal to Amount Due.'
      }
    }
  }

  payInFull(invoice:any) {
    invoice.amountPaid = parseFloat(invoice.amountDue).toFixed(2) + "";
  }

  onNewCC() {
    this.isOpen = true;
    this.newCCflg = true; 
    this.usedCCflg = false;
    this.showNewAch = false; 
    this.usedACHflg = false;  
    this.hideExpDate = true;
	  this.selectedCard = '1';  
  }

  onUsedCC() {
    //$("#selectCardDropDown").prop('selectedIndex', 1);

    this.isOpen = false;
    this.newCCflg = false; 
    this.usedCCflg = true; 
    this.showNewAch = false; 
    this.usedACHflg = false;  
    this.selectedCard = '1'; 
  }

  saveExistingCC() {
    this.blockUI.start();
    var selectedIndex = $("select[name='usedCard'] option:selected").index() - 1;

    var authAmount=0;
    var scheduleIds ="";
    var sAmounts="";
    var comma="";
    var chAmt = 0;
    for (var i=0; i<this.selectedInvoiceList.length; i++){	
      if(i>0)
        comma=",";
        var invoice = this.selectedInvoiceList[i];
        if (invoice.isPaidInFull){
          chAmt = invoice.amountDue;
          authAmount=authAmount*1 + chAmt*1;
        }else{
          chAmt = invoice.amountPaid;
          /* if(chAmt==""){
            chAmt=0;
          } */
        authAmount=authAmount*1 + chAmt*1;
      }
      sAmounts= sAmounts+comma+invoice.amountPaid;
    }

    var amount2Decimal = authAmount.toFixed(2);

    var authAmt=Math.round(authAmount*100)/100;	

    scheduleIds = this.selectedInvoiceList.map(function (e) {
      return e.paymentScheduleId+e.source;
    }).join(', ');

    let invNums = this.selectedInvoiceList.map(function (e) {
        return e.invNumber;
      }).join(', ');
    
    // selectedIndex should be 0 for new CC
    if (selectedIndex == -1) {
      this.hideExpDate = true;
      this.showCCErr = true;

      selectedIndex = 0;
      return;
    }

    //let ccExpDate = "";
    //let profileId = "";
    if (selectedIndex < -1) {
      this.ccExpDate = this.ccPaymentList[0].EXPIRY_MONTH + '-' + this.ccPaymentList[0].EXPIRY_YEAR;
      this.profileId = this.ccPaymentList[0].PROFILE_ID;
    } else {
      this.ccExpDate = this.ccPaymentList[selectedIndex].EXPIRY_MONTH + '-' + this.ccPaymentList[selectedIndex].EXPIRY_YEAR;
      this.profileId = this.ccPaymentList[selectedIndex].PROFILE_ID;
    }

    this.sequenceNumber= this.ccPaymentList[selectedIndex].SEQ_ID;

    var paramObj = {};	
    paramObj["authAmount"] = amount2Decimal;
    paramObj["invNum"] = invNums;
    paramObj["scheduleIds"] = scheduleIds;
    paramObj["CCNum"] = '';
    paramObj["CCExpDate"] = this.ccExpDate;
    paramObj["sAmounts"] = sAmounts;
    paramObj["profileId"] = this.profileId;
    paramObj["paymentType"] = '';
    paramObj["paymentSystem"] = 'ORACLE';

    this.submitPaymentService.makePaymentvalidationJSON(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        this.confirmPaymentCC(data[0], data[1]);
    });

  }

  onNewACH() {
    var amount = 0; 
  
    for (let entry of this.selectedInvoiceList) {
      amount += Number(entry.amountPaid);
    }

    var amount2Decimal = amount.toFixed(2);

    this.sequenceNumber = this.newACHSequenceNum;
    this.pciUrlforAch = this.sanitizer.bypassSecurityTrustResourceUrl(this.achUrl + window.btoa("&_&sequenceNumber="+this.sequenceNumber) + window.btoa("&_&amount="+amount2Decimal));

    /*if (this.pciUrlforAch == '') {
      this.pciUrlforAch = this.sanitizer.bypassSecurityTrustResourceUrl(this.achUrl + window.btoa("&_&amount="+amount));
    }*/
    
    this.showNewAch = true;
    this.usedACHflg = false;
    this.newCCflg = false; 
    this.usedCCflg = false; 
    this.hideRoutingNum = true;
    this.selectedAch = '1';
			
	  this.insertPaymentAuditDetails();
  }

insertPaymentAuditDetails() {
  console.log('insertPaymentAuditDetails');
  
  var paramObj = {};	
  paramObj["sequenceNumber"] = this.sequenceNumber;
  paramObj["accountNumber"] = this.accountNumber;
  paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList);

  this.submitPaymentService.insertPaymentAuditDetails(paramObj).subscribe(
    data => {
        console.log("result.data[0]:" + data[0]);
    console.log("result.data[1]:" + data[1]);

  })
}

  onUsedACH() {
    $("#selectACHDropDown").prop('selectedIndex', 1);

    this.isACHOpen = false;
    this.showNewAch = false; 
    this.usedACHflg = true; 
    this.newCCflg = false; 
    this.usedCCflg = false; 
    this.selectedAch = '1';
  }

  achChange() {
    var selectedIndex = $("select[name='usedAch'] option:selected").index();
    if(selectedIndex <= 0) {
      this.showACHErr = true;
      this.enableACHSubmit=false;
      this.showNewAch = false;
      this.hideRoutingNum = true;
      this.selectedACHRoutingNum = '';
    }else {
      var amount = 0; 
  
      for (let entry of this.selectedInvoiceList) {
        amount += Number(entry.amountPaid);
      }

      var amount2Decimal = amount.toFixed(2);
      //this.pciUrlforAch = this.sanitizer.bypassSecurityTrustResourceUrl(this.achUrl + window.btoa("&_&sequenceNumber="+this.achPaymentList[selectedIndex-1].SEQ_ID) + window.btoa("&_&amount="+amount2Decimal) + window.btoa("&_&accountType=E") + window.btoa("&_&displayAcctNum="+this.achPaymentList[selectedIndex-1].BANK_ACCT_NUM_DSP) + window.btoa("&_&maskedAcct="+this.achPaymentList[selectedIndex-1].BANK_ACCT_NUM) + window.btoa("&_&routingNum="+this.achPaymentList[selectedIndex-1].ROUTING_NUM));
      
      this.pciUrlforAch = this.sanitizer.bypassSecurityTrustResourceUrl(this.achUrl + window.btoa("&_&sequenceNumber="+this.sequenceNumber) + window.btoa("&_&amount="+amount2Decimal) + window.btoa("&_&accountType=E") + window.btoa("&_&displayAcctNum="+this.achPaymentList[selectedIndex-1].BANK_ACCT_NUM_DSP) + window.btoa("&_&maskedAcct="+this.achPaymentList[selectedIndex-1].BANK_ACCT_NUM) + window.btoa("&_&routingNum="+this.achPaymentList[selectedIndex-1].ROUTING_NUM));

      this.showNewAch = true;

      this.showACHErr = false;
      this.enableACHSubmit=true;
      //this.sequenceNumber = this.achPaymentList[selectedIndex-1].SEQ_ID;
      this.hideRoutingNum = false;
      this.selectedACHRoutingNum = this.achPaymentList[selectedIndex-1].ROUTING_NUM;
      this.insertPaymentAuditDetails();
    }
  }

  /* saveExistingACH() {
    var selectedIndex = $("select[name='usedAch'] option:selected").index() - 1;

    let invNums = this.selectedInvoiceList.map(function (e) {
        return e.invNumber;
      }).join(', ');
       
    if (selectedIndex == -1) {
      this.hideExpDate = true;
      this.showACHErr = true;
      selectedIndex = 0;
      return;
    }

    if(this.selectedRadio == 'OTP') {
      this.insertACHPaymentDetails();
    }
    else{
      this.insertAutoACHPaymentDetails();
    }
  } */

  onRadioChange(value:any) {
    this.selectedRadio = value;

    if (value == 'SAP') {
      this.selectedInvoiceList.forEach(invoice => {
        invoice.isPaidInFull = true;
        invoice.amountPaid = parseFloat(invoice.amountDue).toFixed(2) + "";
      });  
    }
  }

  @HostListener('window:message', ['$event'])
  onWindowBlur(event: any): void {
    if(event.data == "Submitted"){		
      this.blockUI.start();
      console.log('Submitted');		
    }else if(event.data == "Rejected"){		
      this.blockUI.stop();
      console.log('Rejected');
    }else if(event.data == "Accepted"){
      console.log('Accepted');
      //  this.saveExistingCC();
      this.validatePaymentInfo();
    }else if(event.data.msg == "AcceptedACH") {
      console.log('AcceptedACH');
      console.log('event.data.phoneNum:' + event.data.phoneNum);
      this.checkProfile_ACH_Embedded(event.data.transId,event.data.phoneNum); 
    }else if (event.data.msg == "ErrorACH") {
      console.log('ErrorACH');
      this.blockUI.stop();
    }
  }

  //  CC FIX
  validatePaymentInfo() {
    let paramObj = {};	
      paramObj["sequenceNumber"] = this.sequenceNumber;
      this.submitPaymentService.validatePaymentInfo(paramObj).subscribe(  
        data=>{
          this.blockUI.stop();
         let sRes="";
              sRes=data.response["validatePaymentInfo"];
              sRes= sRes.trim();
              
              if(sRes.length>0){
                if(sRes=="-1"){
                  // window closed
                  
                }else if(sRes.indexOf(":") > 0){	
                  this.profileId = sRes.split(":")[0]
                  let expMnth="";
                  expMnth=  sRes.split(":")[1];
            
                  this.ccExpDate =expMnth.charAt(0)+expMnth.charAt(1)+"-"+expMnth.charAt(5)+expMnth.charAt(6);
                  let creditCardType="";
                  creditCardType =  sRes.split(":")[2]; 
                  this.creditCardType = creditCardType;
                  this.pmtFlg = "Y";
                  //$('#clrSumbitPayment').show();
                  if(this.selectedRadio == "OTP"){
                      this.getAuthCodeAndLoadCnfrmPage();
                  }else if(this.selectedRadio == "SAP"){
                    //this.setupAutoPay();
                      this.saveDataForAutoPay();
                  }
                }
             }
      },
      function(reason) {
        this.blockUI.stop();
        console.log(reason);
    });
  }

  getAuthCodeAndLoadCnfrmPage(){
    //showPleaseWait();
    this.blockUI.start();
    this.isError= false;
    this.errMsg = "";
    let authAmount=0;
    let chAmt=0;
    let invNum="";
    let scheduleIds ="";
    let sAmounts="";
    
    for (let i=0; i<this.selectedInvoiceList.length; i++){	
      let comma="";
      if(i>0)
        comma=",";
        let invoice = this.selectedInvoiceList[i];
      if (invoice.isPaidInFull){
        chAmt = invoice.amountDue;
        authAmount=authAmount*1 + chAmt*1;
      }else{
        chAmt = invoice.amountPaid;
        if(!chAmt){
          chAmt=0;
        }
        authAmount=authAmount*1 + chAmt*1;
      }
      sAmounts= sAmounts+comma+invoice.amountPaid;
    }
    
    let authAmt=Math.round(authAmount*100)/100;	

    var amount2Decimal = authAmount.toFixed(2);
    
    invNum = this.selectedInvoiceList.map(function (e) {
      return e.invNumber;
    }).join(', ');
    
    scheduleIds = this.selectedInvoiceList.map(function (e) {
      return e.paymentScheduleId+e.source;
    }).join(', ');
    
    let paramObj = {};	
      paramObj["authAmount"] = amount2Decimal;
      paramObj["invNum"] = invNum;
      paramObj["CCNum"] = "";
      paramObj["CCExpDate"] = this.ccExpDate;
      paramObj["profileId"] = this.profileId;
      paramObj["paymentType"] = "";
      paramObj["paymentSystem"] = this.paymentSystem;
      paramObj["scheduleIds"] = scheduleIds;
      paramObj["sAmounts"] = sAmounts;
      
    //let qryStr=$.param(paramObj);

    this.submitPaymentService.paymentValidation(paramObj).subscribe(

      data=>{
        this.blockUI.stop(); 
        let res="";
        res=data;            
        if(res.includes("F")){
          
          this.isError= true;
          if(this.retryCount == 0){
            this.errMsg = "Credit Card Settlement has not completed successfully. Please click Process Retry to resubmit the credit card payment. If you need assistance, please contact Customer Service at 1-800-613-2228.";
          }else{
            this.errMsg = "We are unable to complete the payment. Please contact Customer Service at 1-800-613-2228 for assistance.";
          }
        } else{
          this.authAmount = res[0];
          this.authCode = res[1];
          this.retCCStatus = res[2];
          this.confirmPayment();
          this.paymentDate = new Date();
        }
      },
        function(reason) {
        this.blockUI.stop(); 
        console.log(reason);
    });
  }

  saveDataForAutoPay(){
    this.blockUI.start();
    let selectedIndex = $("select[name='usedCard'] option:selected").index() - 1;
    let authAmount=0;
    let scheduleIds ="";
    let sAmounts="";
    let comma="";
    let chAmt = 0;

    for (let i=0; i<this.selectedInvoiceList.length; i++){	
      if(i>0)
        comma=",";

      var invoice = this.selectedInvoiceList[i];
      if (invoice.isPaidInFull){
        chAmt = invoice.amountDue;
        authAmount=authAmount*1 + chAmt*1;
      }else{
        chAmt = invoice.amountPaid;
        /* if(chAmt==""){
          chAmt=0;
        } */
        authAmount=authAmount*1 + chAmt*1;
      }
      sAmounts= sAmounts+comma+invoice.amountPaid;
    }

    var amount2Decimal = authAmount.toFixed(2);
  let authAmt=Math.round(authAmount*100)/100;	

  scheduleIds = this.selectedInvoiceList.map(function (e) {
    return e.paymentScheduleId+e.source;
  }).join(', ');


  let	invNums = this.selectedInvoiceList.map(function (e) {
    return e.invNumber;
  }).join(', ');
   
  let paramObj = {};	
  paramObj["authAmount"] = amount2Decimal;
  paramObj["invNum"] = invNums;
  paramObj["scheduleIds"] = scheduleIds;
  paramObj["CCNum"] = '';
  paramObj["CCExpDate"] = this.ccExpDate;
  paramObj["sAmounts"] = sAmounts;
  paramObj["profileId"] = this.profileId;
  paramObj["paymentType"] = '';
  paramObj["paymentSystem"] = 'ORACLE';
   
 
  this.submitPaymentService.makePaymentvalidationJSON(paramObj).subscribe(
    result=>{
      console.log(result);
      this.blockUI.stop();
      this.confirmPaymentCC(result[0], result[1]);
    },
    function(reason) {
      console.log(reason);
      this.blockUI.stop();
    });
  }

  checkProfile_ACH_Embedded(transId, phoneNum){
    this.blockUI.start();
    this.transactionId = transId;
    this.phoneNum = phoneNum;
    //Insert the details first
    var paramObj = {};	
    paramObj["sequenceNumber"] = this.sequenceNumber;
    paramObj["paymentMethod"] = this.paymentSystem;
    paramObj["accountNumber"] = this.accountNumber;

    /* this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.amountPaid = tag.amountPaid;
    }); */

    paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList); //angular.toJson($scope.invoiceList);
    paramObj["transactionId"] = this.transactionId;

    this.submitPaymentService.updateACHInvoicePaymentDetails(paramObj).subscribe(
      data => {
        if(data['error_flag'] == 'S'){
          this.blockUI.stop();
          if (this.selectedRadio == 'OTP'){ 
            this.validatePaymentInfo_ACH();
          } else {
            this.validatePaymentInfo_ACHAuto();
          }
        }else{
          //$scope.isACHSuccess = false;
          this.status='true';
          this.authCode = data['request_id'];
          this.displayPaymentNote = true;
          this.authAmount = data['authAmount'];
          this.paymentDate = new Date();
          this.blockUI.stop();
        }
      });		    
  }

  validatePaymentInfo_ACH() {
    console.log('Validate ACH ');
    this.blockUI.start();
    var paramObj = {};	
    paramObj["sequenceNumber"] = this.sequenceNumber;
    paramObj["paymentMethod"] = this.paymentSystem;
    paramObj["accountNumber"] = this.accountNumber;
    /* this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.amountPaid = tag.amountPaid;
    }); */

    paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList); //angular.toJson($scope.invoiceList);
    paramObj["transactionId"] = this.transactionId;
    paramObj["source"] = 'makepayment';
    paramObj["phoneNum"] = this.phoneNum;
      
    this.submitPaymentService.validatePaymentInfoACH(paramObj).subscribe(
      data=>{
        var sRes="";
        sRes=data;            
        //this.blockUI.stop();

        if(sRes['success'] == true){	
          var qryStr=$.param(paramObj);
          //this.blockUI.start();
          this.submitPaymentService.insertACHPaymentDetails(paramObj).subscribe(
            data1=>{
              this.status = data1.status;
              if (data1.status == 'S'){
                this.isACHSuccess = true;
                this.authCode = data1.request_id;
                this.status = data1.status;
                this.authAmount = data1.authAmount;
                this.paymentDate = new Date();
                clearInterval(this.intervalForAch);
                this.blockUI.stop();
              }else{
                console.log("There was an error creating the receipt.");
                //$scope.isACHSuccess = false;
                this.status='true';
                this.authCode = data1.request_id;
                this.displayPaymentNote = true;
                this.authAmount = data1.authAmount;
                this.paymentDate = new Date();
                this.blockUI.stop();
              }
            });
        }else{  
          console.log("There was an error validating the payment.");
          //$scope.isACHSuccess = false;
          this.status='true';
          this.authCode = data.request_id;
          this.displayPaymentNote = true;
          this.authAmount = data.authAmount;
          this.paymentDate = new Date();
          this.blockUI.stop();
        }
      })
  }

  validatePaymentInfo_ACHAuto(){
      console.log('Validate ACH Auto');
      this.blockUI.start();

      var paramObj = {};	
      paramObj["sequenceNumber"] = this.sequenceNumber;
      paramObj["paymentMethod"] = this.paymentSystem;
      paramObj["accountNumber"] = this.accountNumber;				
      /* this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
        return tag.amountPaid = tag.amountPaid;
      }); */
      paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList); //angular.toJson($scope.invoiceList);
      paramObj["transactionId"] = this.transactionId;
      paramObj["source"] = 'makepayment';
      paramObj["phoneNum"] = this.phoneNum;

      let existingSeqNum = 0;
      if(this.achPaymentList.length > 0 && this.selectedRadioACH == 'existingACH'){
        let selectedIndex = $("select[name='usedAch'] option:selected").index();
        existingSeqNum = this.achPaymentList[selectedIndex-1].SEQ_ID
      }
      
      paramObj["existingSeqNum"] = existingSeqNum;
          
      this.submitPaymentService.validatePaymentInfoACH(paramObj).subscribe(
        data=>{
          var sRes="";
          sRes=data;
          //this.blockUI.stop();
          if(sRes['success'] == true){	
            var qryStr=$.param(paramObj);
            //this.blockUI.start();
            this.submitPaymentService.insertACHAutoDetails(paramObj).subscribe(
              data1=>{
                //this.blockUI.stop();
                if (data1.status == 'S'){
                  this.isACHSuccess = true;
                  this.authCode = data1.request_id;
                  this.status = data1.status;
                  this.authAmount = data1.authAmount;
                  this.paymentDate = new Date();
                  clearInterval(this.intervalForAch);
                  this.blockUI.stop();
                  /* if(this.selectedRadio == "SAP"){
                    this.setupAutoPay();
                  } */
                }else{
                  console.log("There was an error creating the receipt.");
                  //$scope.isACHSuccess = false;
                  this.status = 'true';
                  this.authCode = data1.request_id;
                  this.displayPaymentNote = true;
                  this.authAmount = data1.authAmount;
                  this.paymentDate = new Date();
                  this.blockUI.stop();
                }
              });  
            }else{
              //$scope.isACHSuccess = false;
              this.status = 'true';
              this.authCode = data.request_id;
              this.displayPaymentNote = true;
              this.authAmount = data.authAmount;
              this.paymentDate = new Date();
              this.blockUI.stop();
            }
          });
  }          

  changeCC() {
    if (this.selectedCard != '1') {
      this.hideExpDate = false;
      this.showCCErr = true; 
      this.enableCCSubmit=true;
      var idx = $("#selectCardDropDown").prop('selectedIndex') - 1;
      this.sequenceNumber = this.ccPaymentList[idx].SEQ_ID;
      this.expDate = this.ccPaymentList[idx].EXPIRY_MONTH + '-' + this.ccPaymentList[idx].EXPIRY_YEAR;

    }else{
      this.hideExpDate = true;
      this.showCCErr = false;
      this.enableCCSubmit=false;
    }
  }

  confirmPaymentCC(authAmount, authCode){

    this.blockUI.start();
    var paramObj = {};	
    paramObj["authAmount"] = authAmount;
    paramObj["authCode"] = authCode;

/*     this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.isPaidInFull = false;
    }); 

    let selList = this.invoicePaymentList.filter(function(tag) {
      return tag.selected == true;
    });  */

    paramObj["invoices"] = JSON.stringify(this.selectedInvoiceList); 
    paramObj["paymentSystem"] = this.paymentSystem;
      
    var qryStr=$.param(paramObj);

    this.submitPaymentService.confirmPayment(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        if(data.error_flag){
          this.isError= true;
          this.paymentError = true;
          if(this.retryCount == 0){
            this.errMsg = "Credit Card Settlement has not completed successfully. Please click Process Retry to resubmit the credit card payment. If you need assistance, please contact Customer Service at 1-800-613-2228.";
          }else{
            this.errMsg = "We are unable to complete the payment. Please contact Customer Service at 1-800-613-2228 for assistance.";
          }
        }else{
          this.status = data.successCode;
          this.authCode = data.authCode; 
          this.authAmount = data.authoAmount;
          this.paymentDate = new Date();
          this.isACHSuccess = false; 
          //this.showCC = false;

          if(this.selectedRadio == "SAP"){
            this.setupAutoPay();
          }
        }
      });
  }

  setupAutoPay(){
    this.blockUI.start();
    var invNums = this.selectedInvoiceList.map(function (e) {
      return e.invNumber;
    }).join(',');
    
    var paramObj = {};	
      paramObj["invNums"] = invNums;
      paramObj["sequenceNumber"] = this.sequenceNumber;
      
    var qryStr=$.param(paramObj);

    this.submitPaymentService.setupAutoPay(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        this.status = data.status;
      });

  }
 
  /* insertACHPaymentDetails() {
    this.blockUI.start();
    console.log('Insert ACH Payment Details ');

    var paramObj = {};	
    paramObj["sequenceNumber"] = this.sequenceNumber;
    paramObj["paymentMethod"] = this.paymentSystem;
    paramObj["accountNumber"] = this.accountNumber;
      
    this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.amountPaid = tag.amountPaid;
    });

    paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList); //angular.toJson($scope.invoiceList);

    var qryStr=$.param(paramObj);

    this.submitPaymentService.insertACHPaymentDetails(paramObj).subscribe(
      data=>{
        this.blockUI.stop();

        if (data.status == 'S'){
          this.blockUI.stop();
          this.isACHSuccess = true;
          this.authCode = data.request_id;
          this.authAmount = data.authAmount;
          this.paymentDate = new Date();
          this.status = data.status;
        }
      });
  } */
    
  /* insertAutoACHPaymentDetails() {
    console.log('Insert Auto ACH Payment Details');
    this.blockUI.start();
    var paramObj = {};	
    paramObj["sequenceNumber"] = this.sequenceNumber;
    paramObj["paymentMethod"] = this.paymentSystem;
    paramObj["accountNumber"] = this.accountNumber;
    this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.amountPaid = tag.amountPaid;
    });				
    paramObj["invoiceList"] = JSON.stringify(this.selectedInvoiceList); //angular.toJson($scope.invoiceList);

    var qryStr=$.param(paramObj);

    this.submitPaymentService.insertACHAutoDetails(paramObj).subscribe(
    data1=>{
      this.blockUI.stop();

      if (data1.status == 'S'){
        this.blockUI.stop();
        this.isACHSuccess = true;
        this.authCode = data1.request_id;
        this.status = data1.status;
        this.authAmount = data1.authAmount;
        this.paymentDate = new Date();
      }
    });						 
  } */

  closePopup() {
    this.submitPaymentService.notifyMakePaymentModalClosed();
    this.activeModal.close();
    //this.paymentModal.hide();
    //this.resetValues();
    //this.getInvoicePaymentRequest(1, null);
  }

  /* checkProfile_ACH(){
    this.intervalForAch = setInterval(function() { 
      if(this.selectedRadio == 'OTP'){ 
        this.validatePaymentInfo_ACH();
      }else {
        this.validatePaymentInfo_ACHAuto();
      }		    
    }, 5000);
  } */
     
  confirmPayment(){
    this.blockUI.start();
    let paramObj = {};	
    paramObj["authAmount"] = this.authAmount;
    paramObj["authCode"] = this.authCode;
    paramObj["invoices"] = JSON.stringify( this.selectedInvoiceList); //   angular.toJson(this.invoiceList);
    paramObj["paymentSystem"] = this.paymentSystem;
		//	let qryStr=$.param(paramObj);
    
    this.submitPaymentService.confirmPayment(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        if(data.error_flag){
          this.isError= true;
          this.paymentError = true;
          if(this.retryCount == 0){
            this.errMsg = "Credit Card Settlement has not completed successfully. Please click Process Retry to resubmit the credit card payment. If you need assistance, please contact Customer Service at 1-800-613-2228.";
          }else{
            this.errMsg = "We are unable to complete the payment. Please contact Customer Service at 1-800-613-2228 for assistance.";
          }
        }else{
          this.status = data.successCode;
          this.date = new Date();
        }
        //this.auditInvoicePaymentDetails();			
      });
  }

}
