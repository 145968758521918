import { Component, OnInit } from '@angular/core';
import { RequestDevicesService } from './request-devices.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Location } from '@angular/common';

@Component({
  selector: 'app-request-devices-processed',
  templateUrl: './request-devices-processed.component.html',
  styleUrls: ['./request-devices-processed.component.css']
})
export class RequestDevicesProcessedComponent implements OnInit {

@BlockUI() blockUI : NgBlockUI;
isSubmitEnabled: boolean = false;
//value:any =  (String [])request.getAttribute("sNumber");
value:any=[];
value1:any =[];
cBox:any = [];
address:any = "";
serialNum;any = "";
insId =  0;
dAddress:any = [];
dserNum = [];
invalid_dserNum = [];
dinsId = [];
dModel = [];
dFlag = [];
dReqOrg = [];
source = [];

valuecheck:any = "";
i = 0;
inSerials = 0;
vaSerials = 0;
vaFlag = 0;
inFlag = 0;
serMap:any={};
duplicate:boolean= false;
duplicateArray:any= [];
sizeArray=[];
hideButton:boolean=true;
isConfirmed:boolean=false;

constructor(private commonService:CommonServiceService, private activatedRoute: ActivatedRoute, private requestDevicesService: RequestDevicesService, private router:Router,private location: Location) {
  this.activatedRoute.queryParams.subscribe(params => {
    if(params['sNumber']!=undefined){
    this.value = params['sNumber'].split(",");
    console.log("Printing value data in constructor: ");
    console.log(this.value);
    //this.ngOnInit();
    }

  });

 }

  ngOnInit() {
    if(this.value==undefined || this.value.length==0){
      return;
    }
    this.blockUI.start();

    this.requestDevicesService.doValidateSubmit(this.value).subscribe(
      data=>{
        this.serMap = data;
        console.log(this.serMap);
        this.dFlag=["N"];
        let i=0;
        let temp = [];
        
        for(let val of this.value){
          console.log(val);
          if(temp.includes(val)){
            this.duplicateArray.push(true);

          }
          else {
            this.duplicateArray.push(false);
          }
         temp.push(val);
         let serList= this.serMap[val];
         this.sizeArray.push(serList.length);
         for(let sn of serList){
          this.dAddress[i] = sn.installLocation;
				  this.dserNum[i] = sn.serialNumber;
				  this.dModel[i] = sn.model;
				  this.dinsId[i] = sn.instanceId;	
				  this.dFlag[i] = sn.flag;
				  this.dReqOrg[i] = sn.reqOrgNumber;
				  this.source[i] = sn.source;
				  this.vaSerials++;

         }
         console.log("Printng serialNumbaer and Model below:");
         console.log( this.dserNum);
         console.log(this.dModel);
         console.log(this.dFlag);
        

         i++;

        }
      

        console.log("Printing duplicate array...:");
        console.log( this.duplicateArray);
        console.log(this.sizeArray);
        this.checkSelected();
        this.blockUI.stop();
      });
      this.commonService.setCommonFunctions();
  }


  doSubmit1() {
    console.log("Printing inside doSubmit1 method...");
    
    //document.serConfirm.action="accountdevices.htm?mthd=ssn";
    //this.router.navigate(['/accountdevices/submitSerialNumber']);

    //const form = document.serConfirm;
  
    var cboxes = this.dinsId;
    var sNumber = this.dserNum;
    var count="Single";
    var verify:any = [];
    console.log("Pritning input params of submitSerialNumber method of controller class");
    console.log(count);
    console.log(this.dinsId);
    console.log(this.dModel);
    console.log(this.dAddress);
    console.log(this.dReqOrg);
    console.log();

    console.log(count + this.dinsId + this.dModel +this.dAddress +  this.dReqOrg + this.dserNum + this.source );


     if (cboxes.length == undefined)
     {
      if (cboxes.length>0)
      {
        this.blockUI.start();
        count="SINGLE";
        verify="TRUE";
        //showPleaseWait();
        //this.submit(); 
        console.log("Pritning input params of submitSerialNumber method of controller class");
        console.log(count + this.dinsId + this.dModel +this.dAddress +  this.dReqOrg + this.dserNum + this.source );
        this.requestDevicesService.dosubmitSerialNumber(count,this.dinsId,
          this.dModel, this.dAddress, this.dReqOrg, this.dserNum, this.source,verify, ).subscribe(
            data=>{
              this.blockUI.stop();

            });
        
      }

     else
      {
        alert("Please select at least one Serial Number");
    
      }
    }
    else
    {
      count="MULTIPLE";
    for(var i=0; i< cboxes.length; i++)
      {
          verify[i]="TRUE";
      
      }
         //showPleaseWait();
      //form.submit(); 
      this.blockUI.start();
      this.requestDevicesService.dosubmitSerialNumber(count,this.dinsId,
          this.dModel, this.dAddress, this.dReqOrg, this.dserNum, this.source,verify, ).subscribe(
          data=>{
            this.blockUI.stop();
            //this.router.navigate(['requestdeviceprocessedconfirmation']);
            this.isConfirmed=true;
          }
        );
    }
    
    
    } 

    doReturn(){
      //showPleaseWait();
      this.router.navigate(['accountdevices']);
      $('#view').val("accountDevices");
      $('#serConfirm').submit();
    }


    doValidate1(){
      console.log("Printing serial number in doValidate1 method..");  
      console.log(this.dserNum);
      console.log(this.value);
      console.log(this.value1);

      var value2 = [];
      for(let x of this.value){
        if(x!="" && x!=undefined && x.length>0){
         value2.push(x);
        }
       }
       for(let x of this.value1){
        if(x!="" && x!=undefined && x.length>0){
         value2.push(x);
        }
       }
      console.log(value2);
      this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
      this.router.navigate(['/requestdevices'], { relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
         queryParams: { sNumber: value2.join()} });
        //  setTimeout(() => {
        //   // Your action to be executed after the delay
        //   console.log('Delayed action executed');
        // }, 5000);
        // const currentURL = this.location.path();
        // console.log('Current URL:', currentURL);
      //location.reload();
      //showPleaseWait();
      //document.serConfirm.action="accountdevices.htm?mthd=vsn";
      //this.router.navigate(['/accountdevices'], { queryParams: { mthd: 'vsn' } });

      // this.requestDevicesService.doValidateSubmit(this.dserNum).subscribe(
      //   data=>{}
      // );
      //const form = document.serConfirm;
      //form.submit(); 
      
    }
    
    doCancel1(){
      $("#serConfirm input").val("");
      $('#view').val("requestSerial");
      this.router.navigate(['requestdevices'])
  }
 getRequestDevices(){
  this.router.navigate(["accountdevices"]);
}

 ready(){
  //$("#submitbenabled").hide();
  this.isSubmitEnabled = true;
// $("#submitbdisabled").show(); 
}

checkSelected()
{    
  console.log("Printing checbox count.."); 
 
  var cnt = $("input:checked").length;	 
  console.log(cnt);
  if(cnt > 0)
  {	  	
    this.hideButton=false;   
  //  $("#submitbenabled").show();
  //  $("#submitbdisabled").hide();
   
          
  }	 
  else 
  {	   		
    this.hideButton=true; 
  //   $("#submitbenabled").hide();
  //  $("#submitbdisabled").show();       
  }

}

getDFlagValue(){

}









}
