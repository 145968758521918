import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
 

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class DeviceDetailsService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
   getClosedServiceRequestsJSON=function(oParams)
  {
      let url = this.defaultUrl + "accountManagement/closedServiceRequest";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getClosedServiceRequestsJSON"))));
    }

    getDeviceStatusJSON=function(oParams)
    {
      let url = this.defaultUrl + "accountManagement/deviceStatus";
      let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
  
        return this.http
          .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getModgetDeviceStatusJSONelList"))));
    }

    getInvoiceDetailsRequestJSON=function(objParams:any)
    {
      let url = this.defaultUrl + "accountManagement/invoiceDetailsRequest";
    
      let params = new URLSearchParams();
      for (let key in objParams) {
        params.set(key, objParams[key])
      }
      return this.http
        .post(url, params.toString(), httpOptions)
        .pipe(catchError((this.util.handleError("getInvoiceDetailsRequestJSON"))));
    }

    getMachinConfigurations=function(oParams)
    {
        let url = this.defaultUrl + "accountManagement/machineConfigs";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
  
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("getMachinConfigurations"))));
      }
      getSupplyHistory=function(oParams)
      {
          let url = this.defaultUrl + "accountManagement/supplyHistory";
          let params = new URLSearchParams();
          for (let key in oParams) {
            params.set(key, oParams[key])
          }
          let qryStr=params.toString();
    
          return this.http
            .post(url,qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getSupplyHistory"))));
        }
        getMeterHistory=function(oParams)
        {
            let url = this.defaultUrl + "accountManagement/meterHistory";
            let params = new URLSearchParams();
            for (let key in oParams) {
              params.set(key, oParams[key])
            }
            let qryStr=params.toString();
      
            return this.http
              .post(url,qryStr, httpOptions)
              .pipe(catchError((this.util.handleError("getMeterHistory"))));
          }
}
