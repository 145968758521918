import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HomepageService } from './homepage.service';
import { CommonServiceService } from '../service/common-service/common-service.service';
import { UserDetailsComponent } from '../common/user-details/user-details.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  homepage:string="";
  fullUrl: string="";
  fileName: string =""
;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private homePageService: HomepageService, 
    private router:Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.getHOmePageDetails();
  }

  getHOmePageDetails(){
    this.blockUI.start();
    
    this.homePageService.getHomePageDetails().subscribe(
      res => {
        this.homepage=res.homepageDetails;
        this.homepage = decodeURIComponent(this.homepage); //this.homepage
        this.initCarousel();
        this.hoverAll();

        this.checkUserDetails();

        this.blockUI.stop();
        
      });
  }



  initCarousel (){ setTimeout(function (){
    (<any>$('.carousel')).carousel({  interval:5000});
    $('body').on('hidden.bs.modal', '.modal', function () {
      $('video').trigger('pause');
      });
    $(".tri-card-item-inner-wrapper").hover(function () {
      $(this).children(".tri-card-info-container").removeClass("opacity-0");
      $(this).children(".tri-card-info-container").addClass("opacity-1");
  }, function () {
      $(this).children(".tri-card-info-container").removeClass("opacity-1");
      $(this).children(".tri-card-info-container").addClass("opacity-0");
  });
    },2000);
  }

  hoverAll(){
    
  }

  checkUserDetails(){
    console.log('Inside checkUserDetails');

    //Check if mandatory data is missing - firstName, lastName, phoneNumber
    let firstName = sessionStorage.getItem('firstName');
    let lastName = sessionStorage.getItem('lastName');
    let phoneNumber = sessionStorage.getItem('phoneNumber');

    if(!firstName || !lastName || !phoneNumber){
      console.log('Missing some data');
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        windowClass: 'scrollable-modal' 
      };
      
      this.modalService.open(UserDetailsComponent, ngbModalOptions);
    }else{
      console.log('Not missing any data');
      this.router.navigate(['home']); 
    }
    
    console.log('Leaving checkUserDetails');
  }

}
