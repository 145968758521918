import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
 

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class RequestListingService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
  getRequestServiceList=function(oParams)
  {
      let url = this.defaultUrl + "servicerequest/getSR";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getRequestServiceList"))));
    }

   getModelList=function()
    {
      let url = this.defaultUrl + "servicerequest/getModelList";
      let params={};
      return this.http
        .post(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("getModelList"))));
    }

    getOpenCall=function(oParams)
    {
      let url = this.defaultUrl + "servicerequest/getOpenCall";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;
      //let qryStr=params.toString();
      return this.http
        .post(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getOpenCall"))));
    }
    getDisplayAlert=function()
    {
      let url = this.defaultUrl + "common/service/gda";
      let params={};
      return this.http
        .post(url, params.toString, httpOptions) 
        .pipe(catchError((this.util.handleError("getDisplayAlert"))));
    }
  
}
