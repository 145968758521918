import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-account-support-response',
  templateUrl: './account-support-response.component.html',
  styleUrls: ['./account-support-response.component.css']
})
export class AccountSupportResponseComponent implements OnInit {

  constructor(private router: Router, private activatedRoute : ActivatedRoute, private titleService: Title) { }

  noTicketNumber = false;
  custCareTicketNumber = "";	
	
  ngOnInit() {
	
	this.titleService.setTitle("Account Support");
	
	this.custCareTicketNumber = this.activatedRoute.snapshot.paramMap.get('custCareTicketNumber');
	
	if(this.custCareTicketNumber === undefined || this.custCareTicketNumber == "" || this.custCareTicketNumber == null || this.custCareTicketNumber == "null"  ) {
		this.noTicketNumber = true;
	}	
	
  }

  navigateToAccountSupport() {
    this.router.navigate(['/accountsupport']);
  }

}
