import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserIdleService } from 'angular-user-idle';
import { LoginAuthenticationService } from './service/common-service/login-authentication.service';
 import {ApexNonAxisChartSeries,ApexResponsive,ApexChart,ChartComponent,
  ApexDataLabels,ApexPlotOptions,ApexYAxis,ApexTitleSubtitle,
  ApexLegend,ApexStroke,ApexXAxis,ApexFill,ApexTooltip} from "ng-apexcharts";
export type ChartOptions = { series: ApexNonAxisChartSeries;chart: ApexChart;
  responsive: ApexResponsive[];  labels: any;dataLabels: ApexDataLabels;plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;xaxis: ApexXAxis;fill: ApexFill;tooltip: ApexTooltip;stroke: ApexStroke;
  legend: ApexLegend;title: ApexTitleSubtitle;};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
 @BlockUI() blockUI: NgBlockUI;   
  title = 'myCanonApp';
  invalidLogin=false;
  showModal = false;
  authenticated = false;
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  loggedUserName='';
  loggedUserFullName='';
  userAuthToken: string ='';
  loginCss: boolean= false;

  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService,private userIdle: UserIdleService,private activatedRoute: ActivatedRoute) { }
  ngOnInit()
  {
 
  //this.blockUI.start("Please wait...");
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader =this.activatedRoute.firstChild.snapshot.data['showHeader'];
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data['showSidebar'];
        this.showFooter = this.activatedRoute.firstChild.snapshot.data['showFooter'];
        this.loginCss = (this.activatedRoute.firstChild.snapshot.data['loginCss'] !== undefined) ? this.activatedRoute.firstChild.snapshot.data['loginCss'] : false;
       // this.loginCss = this.activatedRoute.firstChild.snapshot.data['loginCss'];
      }
    });
    setTimeout(() => {
      //this.blockUI.stop();
    }, 2500);
	  
   
	}
  restart() {
    console.log('Timer reset done.');
    //this.showModal = false;
    window.location.reload();
    this.userIdle.resetTimer();
    this.showModal = false;
  }
}

