import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class CreateSrService {
  defaultUrl: string;

  constructor(private http: HttpClient, private util: Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getCreateSrInfo = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getCreateSrInfo";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getCreateSrInfo"))));
  }
  getProbleCode = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getProbleCode";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getProbleCode"))));
  }

  getProbleDesc = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getProbleDesc";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getProbleDesc"))));
  }

  getInstallLocation = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getInstallLocation";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getInstallLocation"))));
  }

  getBillToLocation = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getBillToLocation";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getBillToLocation"))));
  }

  getAmazonCallType = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getAmazonCallType";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getAmazonCallType"))));
  }

  getCallTypeDetails = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getCallTypeDetails";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getCallTypeDetails"))));
  }
  getAHSCallType = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getAHSCallType";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getAHSCallType"))));
  }

  getOracleAHSCallType(oParams : any) : Observable<any>{
    let url = this.defaultUrl + "servicerequest/getOracleAHSCallType";

    /* let params = new URLSearchParams();
    
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    
    let qryStr=params.toString(); */
    return this.http
      .post(url, JSON.stringify(oParams), httpJsonOptions)
      .pipe(catchError((this.util.handleError("getOracleAHSCallType"))));
  }
  getReviewServiceDetails = function (oParams) {
    let url = this.defaultUrl + "servicerequest/getReviewServiceDetails";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getReviewServiceDetails"))));
  }
  submitServiceRequest = function (oParams) {
    let url = this.defaultUrl + "servicerequest/submitServiceRequest";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("submitServiceRequest"))));
  }
  submitServiceRequestWithHeatWS = function (oParams) {
    let url = this.defaultUrl + "servicerequest/submitServiceRequestWithHeatWS";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("submitServiceRequestWithHeatWS"))));
  }

  validatePaymentInfo = function (oParams) {
    let url = this.defaultUrl + "servicerequest/validatePaymentInfo";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("validatePaymentInfo"))));
  }

  validatePaymentInfoACH = function (oParams) {
    let url = this.defaultUrl + "servicerequest/validatePaymentInfoACH";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = params.toString();

    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("validatePaymentInfoACH"))));
  }

}
