import { TonerCustomerInfo } from "./TonerCustomerInfo";
import { TonerItem } from "./TonerItem";

export class TonerReturn {

    customer:TonerCustomerInfo;
    itemList:TonerItem[];
    returnReason:string;

    constructor(){

        this.customer= new TonerCustomerInfo();
        this.itemList=[];
        this.returnReason="";
    }

}   