import { Component, OnInit } from '@angular/core';
import { ContractSuppliesService } from '../contract-supplies.service';

@Component({
  selector: 'app-purchase-supplies',
  templateUrl: './purchase-supplies.component.html',
  styleUrls: ['./purchase-supplies.component.css']
})
export class PurchaseSuppliesComponent implements OnInit {

  constructor(private contractSuppliesService: ContractSuppliesService) { }

  ngOnInit() {
      this.contractSuppliesService.purchaseSupplies().subscribe(
        data =>{
          if(data.redirectURL != ''){
            window.location.href = data.redirectURL;
          }
        }
      )
    
  }

}
