import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


// const httpOptions = {
//   headers: new HttpHeaders({
//   }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
// };

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class DefineLevelsService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


	getlookupSearch(data, reqBody): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "lookup/lookupsearch?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, reqBody, httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getBAUsers")))
	  }	


}


