import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';
import { MfpSettingService } from './mfp-setting.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-mfp-setting',
  templateUrl: './mfp-setting.component.html',
  styleUrls: ['./mfp-setting.component.css']
})
export class MfpSettingComponent implements OnInit {
	
	@BlockUI() blockUI : NgBlockUI;
	@ViewChild('printModal') printModal:TemplateRef<any>;
	@ViewChild('folderScanModal') folderScanModal:TemplateRef<any>;
	@ViewChild('emailScanModal') emailScanModal:TemplateRef<any>;
	

  	constructor(private activatedRoute: ActivatedRoute, private mfpService : MfpSettingService, private modalService: BsModalService, private titleService: Title) { }
 
	printModalRef: BsModalRef;
	folderScanModalRef : BsModalRef;
	emailScanModalRef  : BsModalRef;
	
	surveyreadonly;
	mfpSetting : any;
	scanFormat : any;
	device : any;
	selectedPrintLanguage="";
	printLanguages = [];
	scanFolderFileFormat="";
	scanFolderLocation;
	scanFormats = [];
	userAccountName;
	userAccountPassword;
	uncOrFolderFullPath;
	scanEmailFileFormat="";
	smptpServerIpAndName;
	smtpLogonRequired;
	scanEmailAuthUserName;
	scanEmailAuthPassword;
	scanDeviceEmailAddress;
	mfpMessageFailDisplay = false;
	mfpMessageSuccessDisplay = false;
	
	somNumber;
	versionNumber;
	somQuoteFlag;
	somStatus;
	
	printModalDisplay = "none";
	
  	ngOnInit() {
	
		this.printLanguages = this.getPrintLanguages();
		this.scanFormats = this.getScanFormats();
	/*
		this.activatedRoute.queryParams.subscribe(params => {
			this.surveyreadonly = params['surveyreadonly ='], 
      		this.somNumber = params['somNumber'];
			this.versionNumber = params['versionNumber'];
			this.somQuoteFlag = params['somQuoteFlag'];
			this.somStatus = params['somStatus'];
    	});	
	*/
	
		this.surveyreadonly = 'N';
		this.somNumber = 1388650;
		this.versionNumber = 2;
		this.somQuoteFlag = "S";
		this.somStatus = "InProgress";
	
		var param = {
			"somNumber" : this.somNumber,
			"versionNumber" : this.versionNumber,
			"somQuoteFlag" : this.somQuoteFlag,
			"somStatus" : this.somStatus
		};
	
		this.blockUI.start();
		this.mfpService.getMFPSetting(param).subscribe(data => {
			this.blockUI.stop();
			if (data != null) {
				console.log('getMFPSetting():', data);
				this.mfpSetting = data;
			}
		});
		
	
  	}

	getPrintLanguages(){
		var printLanguages = [
            { languageName : "UFRII" },
            { languageName : "PCL" },
            { languageName : "PS" }
		];
		
		return printLanguages;
	}
	
	getScanFormats(){
		var scanFormats = [
	        { formatType : 'PDF' },
	        { formatType : 'TIFF' },
	        { formatType : 'Compact PDF' },
	        { formatType : 'JPG' },
        ];

		return scanFormats;
	}

  	switchCustomTab(tab) {
	
  	}

	printingChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.printing = 'Y';
			this.printModalRef = this.modalService.show(this.printModal);
		} else {
			this.mfpSetting.printing = 'N';
		}
	}
	
	setPrintingInfo() {
		this.mfpSetting.printLanguage = this.selectedPrintLanguage;
		this.closePrintModal();
		console.log(this.mfpSetting.printLanguage);
	}
	
	closePrintModal() {
		this.printModalRef.hide();
	}
	
	scanToFolderChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.scanToFolder = 'Y';
			this.folderScanModalRef = this.modalService.show(this.folderScanModal);
		} else {
			this.mfpSetting.scanToFolder = 'N';
		}
	}
	
	setScanToFolderInfo(){
		
		this.mfpSetting.scanFolderFileFormat = this.scanFolderFileFormat;
		this.mfpSetting.scanFolderLocation = this.scanFolderLocation;
		this.mfpSetting.userAccountName = this.userAccountName;
		this.mfpSetting.userAccountPassword = this.userAccountPassword;
		this.mfpSetting.uncOrFolderFullPath = this.uncOrFolderFullPath;
		
		this.closeFolderScanModal();
		console.log(this.mfpSetting);

	}
	
	closeFolderScanModal() {
		this.folderScanModalRef.hide();
	}
	
	scanToEmailChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.scanToEmail = 'Y';
			this.emailScanModalRef = this.modalService.show(this.emailScanModal);
		} else {
			this.mfpSetting.scanToEmail = 'N';
		}
	}
	
	setScanToEmailInfo() {
		
		this.mfpSetting.scanEmailFileFormat = this.scanEmailFileFormat;
		this.mfpSetting.smptpServerIpAndName = this.smptpServerIpAndName;
		this.mfpSetting.smtpLogonRequired = this.smtpLogonRequired;
		this.mfpSetting.scanEmailAuthUserName = this.scanEmailAuthUserName;
		this.mfpSetting.scanEmailAuthPassword = this.scanEmailAuthPassword;
		this.mfpSetting.scanDeviceEmailAddress = this.scanDeviceEmailAddress;		
		
		this.closeEmailScanModal();
		console.log(this.mfpSetting);
	}
	
	closeEmailScanModal() {
		this.emailScanModalRef.hide();
	}
	
	faxChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.fax = 'Y';
		} else {
			this.mfpSetting.fax = 'N';
		}
	}	
	
	faxFrwdChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.faxForward = 'Y';
		} else {
			this.mfpSetting.faxForward = 'N';
		}
	}
	
	efiChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.efiFiery = 'Y';
		} else {
			this.mfpSetting.efiFiery = 'N';
		}
	}
	
	canonCtrlChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.canonController = 'Y';
		} else {
			this.mfpSetting.canonController = 'N';
		}
	}
	
	saveMfpSetting(tab) {
		this.mfpMessageFailDisplay = false;
		this.mfpMessageSuccessDisplay = false;

		var param = {
			"mfpSetting" : JSON.stringify(this.mfpSetting)
		}
		
		this.blockUI.start();
		this.mfpService.saveMFPSetting(param).subscribe(data => {
			this.blockUI.stop();
			if (data != null) {
				console.log('saveMFPSetting():', data);
				this.mfpSetting = data;
				if(data.errorExist) {
					this.mfpMessageFailDisplay = true;
				} else {
					this.mfpMessageSuccessDisplay = true;
				}
			}
		});
		
	}
	
	
}
