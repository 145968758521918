import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfiguratorAdminService } from './configurator-admin.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { SwitchUserService } from 'src/app/admin/switch-user/switch-user.service';

@Component({
  selector: 'app-configurator-admin',
  templateUrl: './configurator-admin.component.html',
  styleUrls: ['./configurator-admin.component.css']
})
export class ConfiguratorAdminComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  //partyId:string="14669070";
  partyId:string="";
  configCustomers:any=[];
  recPerPage=10;
  pageNum=0;
  numOfRecsMsg:string="";
  accNum:string="";
  partyNum:string="";
  partyName:string="";
  totalRecords=0;
  start:string="1";
  arrPgLinks:any=[];
  length: string="10";
  @ViewChild('cusModal') cusTemplate:TemplateRef<any>;
  cusModalRef: BsModalRef;
  @ViewChild('userTemplModal') userTemplate:TemplateRef<any>;
  custTmplModalRef: BsModalRef;
  chooseAcctParam: any;
  chooseAcctFlag: boolean =false;
  selCust: any;
  publishStatus: any;
  publishUnPublishStatus: any;
  templateId: any;
  publishParam: any;
  displayTab: string="";
  compParam: any;

  tmplUserName:string="";
  userList = [];
  ulrecPerPage=10;
  ulpageNum=0;
  ulnumOfRecsMsg:string="";
  ularrPgLinks:any=[];
  ultotalRecords=0;
  ulnorecsmsg = "";
  ulshowMsg =false;
  
  templUserName = "";

  userPartyTemplates = [];
  userTemplMsg= "";
  showUserTemplMsg = false;
  showUsrNameValMsg = false;


  constructor(private suService:SwitchUserService, private commonService:CommonServiceService, private configuratorAdminService:ConfiguratorAdminService,private titleService: Title,private route : ActivatedRoute, private modalService: BsModalService,) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Admin");
   
  }

  clearCusModalFields() {
	this.accNum = "";
	this.partyNum="";
	this.partyName="";
  this.getSalesRepAccts(1,null);
  }

  clearUsrTmptField() {
	//console.log("Clearing username field..");
	this.tmplUserName = "";
  }

  searchUsrTmpts(pgNum: any,event: any) {
	if(event){
      event.preventDefault();
    }

    this.showUsrNameValMsg = false;
	if(this.tmplUserName.trim() == '') {
		this.showUsrNameValMsg = true;
		return;
	}

    this.blockUI.start();
    this.ulpageNum=pgNum;
	this.ulshowMsg=false;
	this.tmplUserName = this.tmplUserName.toUpperCase();
	
	let oParams = { "pageNum": this.ulpageNum, "recPerPage": this.ulrecPerPage, 
                               "userName":this.tmplUserName };
   	let data = this.suService.getUserList(oParams).subscribe(
       data=>{
		this.blockUI.stop();
          if(data){
            this.userList = data.userList; 
            this.ultotalRecords=data.totalRecords;
            this.ulnumOfRecsMsg=this.commonService.getNumOfRecsMsg(this.ultotalRecords,this.ulrecPerPage,this.ulpageNum);
            this.ularrPgLinks=this.commonService.getPgLinks(this.ultotalRecords,this.ulpageNum,this.ulrecPerPage);
            if(this.ultotalRecords<1){
              this.ulnorecsmsg="No records found.";
              this.ulshowMsg=true;
            } 
           
          }
       });	
	
  }

  openCustTemplModal(userName,$event){
	console.log("in openCustTemplModal()", userName);
	this.templUserName = userName;
	
	if(event){
      event.preventDefault();
    }

	$('#usrMdlBtnClose').click();
	this.closeCustTemplModal();
	this.blockUI.start();
	
	let oParams = { "userName":this.templUserName };
   	let data = this.configuratorAdminService.getUserPartyTemplates(oParams).subscribe(
       data=>{
		this.blockUI.stop();
          if(data){
	
			console.log(data);
            this.userPartyTemplates = data.data; 
			
			this.custTmplModalRef = this.modalService.show(this.userTemplate,{
        		class: 'modal-dialog-centered',ignoreBackdropClick: true, 
        		keyboard: false
     		});	

          }
       });	
	
	
  }

  closeCustTemplModal() {
	if (this.custTmplModalRef){
    	this.custTmplModalRef.hide();
	}
  }


  saveUserTemplate(mode){
	
	var selUsrTemplateStr  = document.querySelector('input[name = usrTemplRadio]:checked').getAttribute('value');
	console.log(selUsrTemplateStr);
	
	if(selUsrTemplateStr == undefined) {
		this.userTemplMsg = "Please select User Template";
		this.showUserTemplMsg = true; 
		return;
	}
	
	this.blockUI.start();
		
	this.userTemplMsg = "";
	this.showUserTemplMsg = false;
	  
	if(mode == 'save') {
		        
		var selUsrTemplate = this.fromJson(selUsrTemplateStr);
		
		let oParams = { "confPartyId": selUsrTemplate.confPartyId, "confAccountNum": selUsrTemplate.confAccountNumber, 
	                               "userName":selUsrTemplate.userName };
	
	   	let data = this.configuratorAdminService.updateUserConfigParty(oParams).subscribe(
	       data=>{
			this.blockUI.stop();
	          if(data){
				console.log(data); 
				if(data.status == 'S') {
					this.userTemplMsg = "Saved Successfully";
				} else {
					this.userTemplMsg = "Error occurred while saving.";
				}
				this.showUserTemplMsg = true;          
	          }
	       });	
		
	} else {
		// remove
		
		var selUsrTemplate = this.fromJson(selUsrTemplateStr);
		
		let oParams = { "confPartyId": "" , "confAccountNum": "" ,  "userName":selUsrTemplate.userName };
	
	   	let data = this.configuratorAdminService.updateUserConfigParty(oParams).subscribe(
	       data=>{
			this.blockUI.stop();
	          if(data){
				console.log(data); 
				if(data.status == 'S') {
					this.userTemplMsg = "Saved Successfully";
				} else {
					this.userTemplMsg = "Error occurred while saving.";
				}
				this.showUserTemplMsg = true;          
	          }
	       });	
	}
	
  }

  toJson(data) {
		return JSON.stringify(data);
	}
	
  fromJson(data) {
		return JSON.parse(data);
	}



  getSalesRepAccts(pgNum: any,event: any)
  {
    this.chooseAcctFlag =false;
    if(event){
      event.preventDefault();
    }
    this.blockUI.start();
    this.pageNum=pgNum;
    let oParams = { "start": pgNum, "length": this.recPerPage, 
    "partyNum":this.partyNum,"accNum":this.accNum,"partyName":this.partyName};
    this.configuratorAdminService.getCustomers(oParams).subscribe(
      data=>{
        console.log("Printing data for getCustomers..");
        //console.log(data.res);
        this.totalRecords=data.noOfRecords;
        this.configCustomers=data.configAccountList;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(data.noOfRecords,this.recPerPage,this.pageNum);
       	this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
       	this.blockUI.stop();
		this.closeCusModal();
		this.cusModalRef = this.modalService.show(this.cusTemplate,{
        class: 'modal-dialog-centered',ignoreBackdropClick: true, 
        keyboard: false
      });
        
      }
    );
  }
  
  closeCusModal()
  {
	if (this.cusModalRef){
    	this.cusModalRef.hide();
	}
  }
  chooseAcct(param: any,event: any)
  {        
    if(event){
      event.preventDefault();
    }
    this.blockUI.start(); 
    let oParams = { "start": "1", "length": this.recPerPage, "partyId":param.partyId,
    "partyNum":param.partyId,"accNum":param.accNum,"partyName":param.partyName};
    this.configuratorAdminService.getSelectedCustomer(oParams).subscribe(
      data=>{
        console.log("Printing data for getSelectedCustomer..");
        //console.log(data.res);
        this.totalRecords=data.noOfRecords;
        this.selCust=data.selectedAccountList[0];
        //this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(data.noOfRecords,this.recPerPage,this.pageNum);
       //this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
       this.closeCusModal();
       this.chooseAcctFlag =true;
        this.blockUI.stop();
      }
    );
  }
  publishStatusParam(param: any)
  {
    this.publishParam =param;
  }
  updateStatus(param: any,event: any)
  {       
   param=this.publishParam;
    if(event){
      event.preventDefault();
    }
    this.blockUI.start(); 
    let oParams = { "partyId":param.partyId,
    "templateId":param.templateId,"accNum":param.accNum,"partyName":param.partyName,"status":this.publishUnPublishStatus};
    this.configuratorAdminService.updateStatus(oParams).subscribe(
      data=>{
        console.log("Printing data for updateStatus..");
        this.publishStatus=data;
        this.blockUI.stop();
      }
    );
  }
  setTab(tab:any,event: any)
  {
    if(event){
      event.preventDefault();
    }
    this.displayTab =tab;
    this.compParam =this.selCust;
  }
}
