import { Component, OnInit } from '@angular/core';
import { P66Service } from '../p66.service';

@Component({
  selector: 'app-p66-new-registration',
  templateUrl: './p66-new-registration.component.html',
  styleUrls: ['./p66-new-registration.component.css']
})
export class P66NewRegistrationComponent implements OnInit {
  sFlag: string;

  constructor(private p66Service: P66Service) { }

  ngOnInit() {

    this.p66Service.registerUser().subscribe(
      data => {
        this.sFlag = data.sFlag;
      }
    )

  }

}
