import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

const httpOptionsForm = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

@Injectable({
  providedIn: 'root'
})
export class ContractSuppliesService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util) {
    this.baseUrl = this.util.getBaseUrl();
  }

  getDeviceListing(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getOpenSupply';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    //return this.http.post<Employee[]>(url, '', httpOptions).pipe(catchError(this.handleError()));
    //.pipe(catchError(this.util.handleError("getMenu")));

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getDeviceListing', [])));
  }

  hasAutoTonerAccess() : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/hasAutoTonerAccess';

    //return this.http.post<Employee[]>(url, '', httpOptions).pipe(catchError(this.handleError()));
    //.pipe(catchError(this.util.handleError("getMenu")));

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('hasAutoTonerAccess', [])));
  }

  getReadsReqFlag(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getReadsReqFlag';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getReadsReqFlag', [])));
  }

  getCounterReadDetails(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getCounterReadDetails';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getCounterReadDetails', [])));
  }

  submitMeterReadsSup(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/submitMeterReadsSup';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('submitMeterReadsSup', [])));
  }

  getShipToSite(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getShipToSite';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getShipToSite', [])));
  }

  getAttnLbl(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getAttnLbl';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getAttnLbl', [])));
  }

  getShipToLocations() : Observable<any>{
    let url = this.baseUrl + 'productorder/shipLocations';

    /* let qryStr = $.param(oParams);  
    url = url + "?" + qryStr; */

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getShipToLocations', [])));
  }

  createSupplyOrder(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/createSupOrder';

    let qryStr = $.param(oParams);  
    //url = url + "?" + qryStr;

    //return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('createSupplyOrder', [])));
    return this.http.post(url, qryStr, httpOptionsForm).pipe(catchError(this.handleError('createSupplyOrder', [])));
  }

  getOrderDetails(oParams) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/getOrderDetails';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getOrderDetails', [])));
  }

  getAutoTonerDetails(oParams) : Observable<any>{
    let url = this.baseUrl + 'accountManagement/getAutoToner';

    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;

    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('getAutoTonerDetails', [])));
  }

  updateAutoToner(data) : Observable<any>{
    let url = this.baseUrl + 'accountManagement/updateAutoToner';

    /*let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;*/

    return this.http.post(url, JSON.stringify(data), httpOptions).pipe(catchError(this.handleError('updateAutoToner', [])));
  }
  
  enableAutoToner(data) : Observable<any>{
    let url = this.baseUrl + 'accountManagement/enableAutoToner';

    /* let qryStr = $.param(oParams);  
    url = url + "?" + qryStr; */

    return this.http.post(url, JSON.stringify(data), httpOptions).pipe(catchError(this.handleError('enableAutoToner', [])));
  }

  downloadAutoToner() : Observable<any>{
     let url = this.baseUrl + 'ordersupplies/downloadAutoTonerDetails';

    
    //return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('downloadAutoToner', [])));
   
    /* return this.http.get(url, { responseType: 'blob' }).pipe(map((response)=>{
      return {
          filename: 'yourFileName.xls',
          data: response
      };
  })); */

    //return this.http.get(url, {responseType: 'blob'});

    return this.http.get(url, {responseType: 'blob'});

  }

  reviewResults() : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/downloadUploadErrors';

   
   //return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('downloadAutoToner', [])));
  
   /* return this.http.get(url, { responseType: 'blob' }).pipe(map((response)=>{
     return {
         filename: 'yourFileName.xls',
         data: response
     };
 })); */

   //return this.http.get(url, {responseType: 'blob'});

   return this.http.get(url, {responseType: 'blob'});

 }
 autoTonerUploadCompleted() : Observable<any>{
  let url = this.baseUrl + 'ordersupplies/autoTonerUploadCompleted';

  return this.http.post(url, '').pipe(catchError(this.handleError('autoTonerUploadCompleted', [])));
}

  uploadAutoTonerDetails(file: File) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/uploadAutoTonerDetails';

    const formData: FormData = new FormData();

    formData.append('file', file);

    /* let qryStr = $.param(oParams);  
    url = url + "?" + qryStr; */

    return this.http.post(url, formData).pipe(catchError(this.handleError('uploadAutoTonerDetails', [])));
  }

  purchaseSupplies() : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/redirectToCSAShop';
    return this.http.post(url, '', httpOptions).pipe(catchError(this.handleError('purchaseSupplies', [])));
  }

  isCSPurchaseable(data) : Observable<any>{
    let url = this.baseUrl + 'ordersupplies/isCSPurchaseable';
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError('isCSPurchaseable', [])));
  }

  handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
  

  }
}
