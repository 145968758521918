import { Component, OnInit ,TemplateRef,ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { SubmitMeterReadsService } from './submit-meter-reads.service';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-submit-meter-reads',
  templateUrl: './submit-meter-reads.component.html',
  styleUrls: ['./submit-meter-reads.component.css']
})
export class SubmitMeterReadsComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  @ViewChild('confirmModal') confirmTemplate:TemplateRef<any>;
  confirmModalRef: BsModalRef
   
  serialNumber:string="";
  model:string="";
  readDate:string="";
  assignedTo:string="";
 
  pendingMtrSrlList=[];
  modelList=[];
  assignedTos=[]; 
  readDates=[];
  selectedUserAccts:string="";



  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[];
   

  userAccountList=[];
  success:boolean=false;  
  errMsg:string="";
  warningMsg:string="";

  serialNumsWithSameRd:string="";
  serialNums:string="";

  constructor(private commonService:CommonServiceService,private route: ActivatedRoute,private router: Router, private submitMeterReadsService:SubmitMeterReadsService, private util : Util, private modalService: BsModalService,private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Submit Meter Reads"); 
    this.route.queryParams.subscribe(
      params => {
        let sSrl=params['srl'];
        if(sSrl){
          this.serialNumber=sSrl;
        }
       }
    )

    this.getDropDownData();
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
    });
    this.getPendingMeterReadsList(1,null);
    this.commonService.setCommonFunctions();
  }

  getPendingMeterReadsList(pgNum: any,event: any){
    if(event!=null){
     event.preventDefault();
   }
   this.blockUI.start();
   this.pageNum=pgNum;
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   console.log("pageNum :"+pgNum);
   console.log("recPerPage :"+this.recPerPage);
    
   let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
    "srh_serial_num":this.serialNumber,"srh_model_num":this.model,"srh_read_date":this.readDate,
    "selectedUserAccts":this.selectedUserAccts ,"assignedTo":this.assignedTo }; 
   let data = this.submitMeterReadsService.getPendingMeterReadsList(oParams).subscribe(
     data=>{
        this.pendingMtrSrlList = data.data; 
        this.totalRecords=data.totalRecords;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
         
        if(this.pendingMtrSrlList&&this.pendingMtrSrlList.length>0){
           for(let k in this.pendingMtrSrlList){
               this.getMeterReadsDetail(k);
           }
        }
        this.blockUI.stop();
     });
 
 }

 getMeterReadsDetail(idx:any){
  this.pendingMtrSrlList[idx]["readList"]=[];
 

  let srlObj=this.pendingMtrSrlList[idx];
  
  let oParams = { "serial_number":srlObj["serialNumber"],"contract_number":srlObj["contractNumber"],
      "instance_id":srlObj["instanceId"] };
  let data = this.submitMeterReadsService.getMeterReadsDetail(oParams).subscribe(
   data=>{

      let mtrRdDtls = data; 
      this.pendingMtrSrlList[idx]["readList"]=mtrRdDtls;
      //console.log(this.pendingMtrSrlList[idx]["readList"]);
      for(let j in mtrRdDtls ){
        let rdObj= mtrRdDtls[j];
        let lstRdDateFmt=this.pendingMtrSrlList[idx]["readList"][j]["lastReadingDtFormatted"];
        let fstRdDateFmt=this.pendingMtrSrlList[idx]["readList"][j]["frstReadingDtFormatted"];
        this.pendingMtrSrlList[idx]["readList"][j]["lastReadingDt"]=lstRdDateFmt;
        this.pendingMtrSrlList[idx]["readList"][j]["frstReadingDt"]=fstRdDateFmt;

        if(rdObj["source"]=="S21"){
          this.pendingMtrSrlList[idx]["readList"][j]["readDate"]=this.getCurrentDate();
         }else{
           this.pendingMtrSrlList[idx]["readList"][j]["readDate"]=new Date(this.pendingMtrSrlList[idx]["scheduleDate"]);
          //this.pendingMtrSrlList[idx]["readList"][j]["readDate"]=this.pendingMtrSrlList[idx]["scheduleDate"];
         }
      }

      setTimeout(function (){
        $(".mat-form-field-infix").css({"width":"100%"});
      },2000);

      
      //console.log(this.pendingMtrSrlList[idx]);
    });

}


getDropDownData(){
   
  this.blockUI.start();
  let oParams = {};
  let data = this.submitMeterReadsService.getDropDownData(oParams).subscribe(
   data=>{
      let d = data; 
      this.modelList=d["models"];
      this.readDates=d["readDates"];
       
      let arrAT=d["assignedTos"];
      for(let k in arrAT){
        var objArr = arrAT[k]["option"].split("|");
        var userObj = {};
        userObj["userName"] = objArr[0];
        userObj["email"] = objArr[1];
        this.assignedTos.push(userObj);
      }
       this.blockUI.stop();
     });

}

submitMeterRead()  {
  let submitVal=true;
  this.errMsg = "";
  this.warningMsg = "";
  this.success = false;
  let length = this.pendingMtrSrlList.length;
  for (let i = 0; i < length; i++) {
      let fieldBlank = false;
      let fieldEntered = false;
      
      /*this.pendingMtrSrlList[i].errMsg = "";*/
      for (let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
          let read = this.pendingMtrSrlList[i].readList[j];
          if(read.readingReqFlg == 'Y' && (!read.curCounterRd )){
            fieldBlank = true;
          } else if (read.curCounterRd != ''){
            fieldEntered = true;
          }
          
/*							if(read.readingReqFlg != '' && read.lastReadingAmnt != ''){
            //let curCounterRd = read.curCounterRd.toString().replace(/,/g, "");
            //let lstReadAmt = read.lastReadingAmnt.toString().replace(/,/g, "");
            let curCounterRd = parseInt(read.curCounterRd);
            let lstReadAmt = parseInt(read.lastReadingAmnt);
            if(curCounterRd < lstReadAmt){						
              let parsedSubmitSerials = this.getAllInvalidSerialNums().replace(/,\s*$/, "");// look for , space etc at the end
              parsedSubmitSerials = " Serial Numbers : "+ parsedSubmitSerials;																		
              this.errMsg = "Current meter read should be greater than last meter read." + parsedSubmitSerials;
              submitVal = false;				
            }
          }*/
        }
        
        if(this.pendingMtrSrlList[i].readList.length > 0 && fieldBlank && fieldEntered){
          this.errMsg = "Please enter reads in all required fields.";	
          submitVal = false;
          this.scrollToPgTop();
          return;
        }
    }
    
    let parsedSubmitSerials = this.getAllInvalidSerialNums().replace(/,\s*$/, "");// look for , space etc at the end
    if(parsedSubmitSerials.length > 0){
      this.errMsg = "Current meter read should be greater than last meter read. Serial Numbers: " + parsedSubmitSerials;
      submitVal = false;
      this.scrollToPgTop();
      return;	
    }

    let srlsTotalAndBWRd= this.checkTotalAndBWReads().replace(/,\s*$/, "");// look for , space etc at the end
    if(srlsTotalAndBWRd.length > 0){
      this.errMsg = "BW(Hard Read) cannot be greater than Total(Hard Read) for Serial Numbers: " + srlsTotalAndBWRd;
      submitVal = false;
      this.scrollToPgTop();
      return;	
    }

    if(submitVal){
      this.serialNums = this.getAllValidSerialNums();
      this.serialNumsWithSameRd = this.getAllValidSerialNumsWithSameRd();
      if(this.serialNums.length > 0){
        this.confirmSubmit();
      }else{
        this.errMsg = "Error: No Meter(s) Entered. You must update the meter reads for at least one device prior to submission.";
        this.scrollToPgTop();
      }	
    }
} 
		
getAllInvalidSerialNums = function(){
  let srls = '';
  for (let i = 0; i < this.pendingMtrSrlList.length; i++) {
    for(let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
     let read = this.pendingMtrSrlList[i].readList[j];
      let curCounterRd = parseInt(read.curCounterRd);
     let lstReadAmt = parseInt(read.lastReadingAmnt);
     if((curCounterRd+'') !='' ) { 
      //&& lstReadAmt!=''){
/*						curCounterRd=curCounterRd.replace(/,/g, "");
       lstReadAmt=lstReadAmt.replace(/,/g, "");*/
       //curCounterRd = parseInt(curCounterRd);
       //lstReadAmt = parseInt(lstReadAmt);
       if(curCounterRd < lstReadAmt){						
         let s = this.pendingMtrSrlList[i].serialNumber;
         /*srls = ( srls.length>0 && srls.indexOf(s)<0 ) ? srls + ", " + s : s;*/
         if(srls.length>0 && srls.indexOf(s)<0 ){
           srls = srls + ", " + s
         }else if(srls.length>0 && srls.indexOf(s)>0){
           srls = srls;
         }else{
           srls = s;
         }											
       }
     }
    }  
     
    }
 return srls;
}


checkTotalAndBWReads = function(){
  let srls = '';
  for (let i = 0; i < this.pendingMtrSrlList.length; i++) {
    let curBwRead=0;
    let curTotalRead=0;
    for(let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
     let read = this.pendingMtrSrlList[i].readList[j];
      let curCounterRd = parseInt(read.curCounterRd);
      if(read.counterName=="Total (Hard Read)"){
       curTotalRead=curCounterRd;
       }
      if(read.counterName=="BW (Hard Read)"){
      curBwRead=curCounterRd;
      }
   
    }  
     
    if(curTotalRead < curBwRead){						
      let s = this.pendingMtrSrlList[i].serialNumber;
      if(srls.length>0 && srls.indexOf(s)<0 ){
        srls = srls + ", " + s
      }else if(srls.length>0 && srls.indexOf(s)>0){
        srls = srls;
      }else{
        srls = s;
      }				
      console.log("serial : "+s +" , curBwRead:  "+curBwRead+" ,curTotalRead : "+curTotalRead);							
     }

    }
 return srls;
}
 
   

getAllValidSerialNumsWithSameRd = function(){
  let srls = '';
  for (let i = 0; i < this.pendingMtrSrlList.length; i++) {
    for(let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
     let read = this.pendingMtrSrlList[i].readList[j];
     let curCounterRd = parseInt(read.curCounterRd);
     let lstReadAmt = parseInt(read.lastReadingAmnt);
     if(curCounterRd) {
       //  && lstReadAmt != ''){
/*						curCounterRd=curCounterRd.replace(/,/g, "");
       lstReadAmt=lstReadAmt.replace(/,/g, "");*/
      // curCounterRd = parseInt(curCounterRd);
      // lstReadAmt = parseInt(lstReadAmt);
       if(curCounterRd == lstReadAmt){						
         let s = this.pendingMtrSrlList[i].serialNumber;
/*							srls = ( srls.length>0 && srls.indexOf(s)<0 ) ? srls + ", " + s : s;*/
         if(srls.length>0 && srls.indexOf(s)<0 ){
           srls = srls + ", " + s
         }else if(srls.length>0 && srls.indexOf(s)>0){
           srls = srls;
         }else{
           srls = s;
         }										
       }
     }
    }  
     
    }
 return srls;
}

getAllValidSerialNums = function(){
  let srls = '';
  for (let i = 0; i < this.pendingMtrSrlList.length; i++) {
    for(let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
     let read = this.pendingMtrSrlList[i].readList[j];
     let curCounterRd = parseInt(read.curCounterRd);
     let lstReadAmt = parseInt(read.lastReadingAmnt);
     if(curCounterRd) { 
      //  && lstReadAmt != ''){
/*						curCounterRd=curCounterRd.replace(/,/g, "");
       lstReadAmt=lstReadAmt.replace(/,/g, "");*/
      // curCounterRd = parseInt(curCounterRd);
      // lstReadAmt = parseInt(lstReadAmt);
       if(curCounterRd >= lstReadAmt){						
         let s = this.pendingMtrSrlList[i].serialNumber;
/*							srls = ( srls.length>0 && srls.indexOf(s)<0 ) ? srls + ", " + s : s;*/
         if(srls.length>0 && srls.indexOf(s)<0 ){
           srls = srls + ", " + s
         }else if(srls.length>0 && srls.indexOf(s)>0){
           srls = srls;
         }else{
           srls = s;
         }										
       }
     }
    }  
     
    }
 return srls;
}

confirmSubmit  (){
 console.log("CONFIRM MODEL"+this.serialNums+"\n"+this.serialNumsWithSameRd)  ;
 this.openModel(); 
}
 
 
processReads = function(){
  this.blockUI.start();
  this.closeModel();				
  let requestJson=JSON.stringify(this.pendingMtrSrlList);
  let paramObj =  { "requestJson":requestJson };
  
 let d= this.submitMeterReadsService.submitMeterReads(paramObj).subscribe(
    data=>{ 
      this.blockUI.stop(); 
      if(data!=null){
        let resData = data.data;
        console.log("resData : "+resData);
        if(resData == ""){
          this.success = true;
        }else{
          let errResData = resData.split('^');
          if(errResData!=null && errResData.length>0){
             this.errorMsg="";
             this.warningMsg="";
            for(let i=1;i<errResData.length;i++){	
               let retresData = errResData[i].split('|');
              if(retresData!=null){
                let serial = retresData[0];
                let errorFlg = retresData[1];
                let source = retresData[3];
                if(errorFlg!='S'){
                  let retmsg = retresData[2];
                  if(retmsg.indexOf("WARNING") !== -1){
                    this.warningMsg = this.warningMsg+retmsg;
                  }else{
                    this.errorMsg = this.errorMsg+retmsg;
                  }												
                  if(source === 'S21' && errorFlg=='W'){
                      errorFlg="Y";
                  }
                  
                  for (let i = 0; i < this.pendingMtrSrlList.length; i++) {
                    if(this.pendingMtrSrlList[i].serialNumber == serial){
                      for (let j = 0; j < this.pendingMtrSrlList[i].readList.length; j++) {
                        this.pendingMtrSrlList[i].readList[j].processFlag = errorFlg;
                      }
                    }
                    
                  }
                }

              }
            }
            }
        }
      }
      
      if(this.errorMsg!=null && this.errorMsg.length>0){
        this.errMsg = this.errorMsg;
      }else if(this.warningMsg!=null && this.warningMsg.length>0){
        this.this.warningMsg = this.warningMsg;
      }else{
        this.success = true;
        this.getPendingMeterReadsList(1,null);		
      }
      this.scrollToPgTop();
      
      
  
  });
}

openModel() {
  this.confirmModalRef = this.modalService.show(this.confirmTemplate);
//  $("#openModalBtn").trigger("click");

 // this.confirmModal.nativeElement.className = 'modal fade show';
}
closeModel() {
  this.confirmModalRef.hide();
}

scrollToPgTop(){
  let objDiv = document.getElementById("main-section");
  objDiv.scrollIntoView({behavior: 'smooth'});  

}

clear (){
 this.pendingMtrSrlList=[];
 this.serialNumber="";
 this.model="";
 this.readDate="";
 this.assignedTo="";
 this.recPerPage=10;
 
 this.getPendingMeterReadsList(1,null);

}


 getCurrentDate(){
    let currentDate = new Date();
    let day = currentDate.getDate();
		let months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
	  let  curMonth = months[currentDate.getMonth()];
    let year = currentDate.getFullYear();
		let scheduleDate = day+"-"+curMonth+"-"+year;
    return scheduleDate;
 }



 displayDeviceDtls(param,event)
 {
   const navigationExtras: NavigationExtras = {
     queryParams: param
   }; 
  if(event){
    event.preventDefault();
  }
  this.router.navigate(['/devicedetails'],navigationExtras);
   
 }

 stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }


}
