import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class TonerReturnService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
   this.defaultUrl = this.util.getBaseUrl();
  }

  getSerialTonerDetails=function(oParams)
  {
    let url = this.defaultUrl + "tonerReturn/gstd";
     let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getSerialTonerDetails"))));
  }


  getSuppliesBySupplyItem=function(oParams)
  {
    let url = this.defaultUrl + "tonerReturn/gssi";
     let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getSuppliesBySupplyItem"))));
  }


  getSerialList(data): Observable<any> {
   let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key])
    }
    let qryStr=params.toString();

    let url = this.defaultUrl + "tonerReturn/gsl";
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError(this.util.handleError("getSerialList")))
  }	

  getAddressList(data): Observable<any> {
    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key])
    }
    let qryStr=params.toString();
 
    let url = this.defaultUrl + "tonerReturn/gll";
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError(this.util.handleError("getAddressList")))
  }	

  
  getManufacturerList(data): Observable<any> {
    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key])
    }
    let qryStr=params.toString();

    let url = this.defaultUrl + "tonerReturn/gml";
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError(this.util.handleError("getManufacturerList")))
  }	 
  
  getReturnReasonList(data): Observable<any> {
    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key])
    }
    let qryStr=params.toString();

    let url = this.defaultUrl + "tonerReturn/grrl";
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError(this.util.handleError("getReturnReasonList")))
  }	 

  getSupplyItemsList(data): Observable<any> {
    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key])
    }
    let qryStr=params.toString();

    let url = this.defaultUrl + "tonerReturn/gsil";
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError(this.util.handleError("getSupplyItemsList")))
  }	  

  submitTonerReturn(data): Observable<any> {
    
    let url = this.defaultUrl + "tonerReturn/str";
    return this.http
      .post(url, data, httpJsonOptions)
      .pipe(catchError(this.util.handleError("submitTonerReturn")))
  }	  

  
}
