import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-devices-processed-confirmation',
  templateUrl: './request-devices-processed-confirmation.component.html',
  styleUrls: ['./request-devices-processed-confirmation.component.css']
})
export class RequestDevicesProcessedConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
