import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ProductCatalogService {

	defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }
  
  getProductCatalog=function()
  {
      let url = this.defaultUrl + "productCatalog/prodCat";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("productCatalog/prodCat"))));
  }

  searchCatalog(oParams) : Observable<any>{
    let url = this.defaultUrl + 'productCatalog/searchCatalog';
 
    let qryStr = $.param(oParams);  
    url = url + "?" + qryStr;
 
    return this.http.post(url, '', httpOptions).pipe(catchError(this.util.handleError('productCatalog/searchCatalog', [])));
  }

  updateCustomerCatalog(oParams) : Observable<any>{
    let url = this.defaultUrl + "productCatalog/updateCatalog";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("updateCatalog"))));

  }

}
