import { Component, OnInit } from '@angular/core';
import { EliteService } from './elite.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-elite',
  templateUrl: './elite.component.html',
  styleUrls: ['./elite.component.css']
})
export class EliteComponent implements OnInit {

@BlockUI() blockUI: NgBlockUI;
  manufactureList: any = [];
  modelList: any = [];
  manufacturer: string = "";
  model: string = "";
  serialNumber: string = "";
  zipcode: string = "";
  lstMtrObj: any;
  submitres: any = "";
  strModel: string = "";
  strSerialNumber: string = "";
  contractNumber: string = "";
  readingType: string = "";
  lastReading: string = "";
  readingDate: string = "";
  location: string = "";
  counterLst: any = "";
  strlocation: string = "";
  // strModel: string ="";
  strSerialNumber1: string = "";
  strContractNumber1: string = "";
  counterName1: string = "";
  lastReadingAmnt1: string = "";
  newReading1: string = "";
  meterreadres: string = "";
  strContractId: string = "";
  processFlag: string = "";
  lastReadingDt: string = "";
  firstReadingDate: string = "";
  readRequiredFlag: string = "";
  newReadingDate: string = "";
  counterId: string = "";
  counterType: String = "";
  lastReadAmount: string = "";
  firstReadAmount: string = "";
  adcvAttribute: string = "";
  emailAddress: string = "";
  initial: boolean = false;
  view: boolean = false;
  submit: boolean = false;
  meterReadForm: FormGroup;
  showSearch: boolean = false;
  meterreaddue: boolean = false;
  submitsuccess: boolean = false;
  combman_model_serial: boolean = false;
  submitbutton: boolean = false;
  contractNumberArray: any = [];
  displaytable: boolean = false;
  dynamicFormGroup: FormGroup;
  output: any;
  counterLstview: any = "";
  submtFlag: string ="";
  errorMsg: string ="";
  errorFlag: string="";
  errorMessages:string="";
  errorflag1:boolean=false;
  elitesubmitsuccess=false;

  qryStrModel:string="";
  rdType:string="";
  emailMsg:string="";
  resflag:string="";

  constructor(private eliteService: EliteService, private formBuilder: FormBuilder,private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.meterreaddue = false;
    this.submitbutton = false;
    this.combman_model_serial = false;
    this.displaytable = false;
    this.submitsuccess=false;
    //this.blockUI.start();
   // this.manufacture_model();
   /*
    this.createInvoiceForm();
    this.dynamicFormGroup = this.formBuilder.group({
      Address: new FormArray([this.createItem]),
    });
    this.route.queryParams.subscribe(
      params => {

        let pModel=params['model'];
        if(pModel){
          this.qryStrModel=pModel;
          this.model=pModel;
          console.log("qry model: "+this.qryStrModel);
        }

        let pManufacturer=params['manufacturer'];
        if(pManufacturer){
          this.manufacturer=pManufacturer;
          console.log("qry : "+this.manufacturer);
          this.changeManufacturer(this.manufacturer);
        }
 
        
        let pSerialNumber=params['serialNumber'];
        if(pSerialNumber){
          this.serialNumber=pSerialNumber;
        }

        let pZipcode=params['zipcode']; 
        if(pZipcode){
          this.zipcode=pZipcode;
        }
        let pRdType=params['rdType'];
        if(pRdType){
          this.rdType=pRdType;
          this.showSearch=true;
          if(this.rdType=="E"){
            this.submit=true;
          }else{
            this.view=true;
          }
          this.gtmtrdtls(this.manufacturer,this.model,this.serialNumber,this.zipcode);
        }
      // zip code , type
       }
    )
       */
  }

  get createItem(): FormGroup {
    return this.formBuilder.group({
      streetAddress: [],
      city:[],
      state:[]
    })
  }

  onSubmit() {
    //logic implementation
    this.output = this.dynamicFormGroup.controls['address'].value;
        //console.log(this.output);
    }


  manufacture_model() {
    this.meterreaddue = false;
    this.blockUI.start();
    this.eliteService.getManufacture_model().subscribe(
      (res: any) => {
        for (let i = 0; i < res.lstManObj.length; i++) {
          if (i == 0) {
            if(this.manufacturer.length<1){
              this.manufacturer = res.lstManObj[i].strManufacturer;
            }
           }
          this.manufactureList.push(res.lstManObj[i].strManufacturer);
        }
        for (let i = 0; i < res.lstMdlObj.length; i++) {
          if (i == 0) {
            if(this.model.length<1){
              this.model = res.lstMdlObj[i].strModel;
            }
          }
          this.modelList.push(res.lstMdlObj[i].strModel);
        }
        this.blockUI.stop();
      });
  }


  changeManufacturer(manufacturer) {
    var paramObj = {};
    paramObj["manufacturer"] = manufacturer;
    this.blockUI.start();
    this.eliteService.getModels(paramObj).subscribe(
      (res: any) => {
        this.modelList = [];
         for (let i = 0; i < res.modelList.length; i++) {
          this.modelList.push(res.modelList[i].strModel);
         
            if(i==res.modelList.length-1){
              if(this.qryStrModel.length>1){
                this.model=this.qryStrModel;
                console.log("mdl 4:"+this.model);
                this.qryStrModel="";
              }
            }
         }
        
        this.blockUI.stop();
      }
    );
  }





  gtmtrdtls(manufacturer, model, serialNumber, zipcode) {
    var paramObj = {};
    this.errorMessages="";
    this.errorflag1=false;
	this.elitesubmitsuccess=false;
    paramObj["manufacturer"] = this.manufacturer;
    paramObj["model"] = this.model;
    paramObj["serialNumber"] = this.serialNumber;
    paramObj["zipcode"] = this.zipcode;

    this.displaytable = true;
    this.submitbutton = true;
    this.blockUI.start();
    this.eliteService.gtmtrdtls(paramObj).subscribe(
      (res: any) => {

        let temp =res.lstMtrObj.flatMap(({ counterLst, ...rest }) =>
        counterLst.map(o => ({
          ...rest,
          ...o
        }))
        
      );
      this.blockUI.stop();
        this.lstMtrObj = temp;
        if(this.lstMtrObj.length==0){
          this.errorMessages="Your combination of Manufacturer, Model, Serial # and Zip Code is invalid. Please try again or Contact Customer Service: 800-613-2228 .";
          this.errorflag1=true;
          return;
        } 
        
        for(let i in this.lstMtrObj){
          this.lstMtrObj[i]["newReading"] = "";
        }
        
      }
    );
  }


  // checkmeterread(newReading,Object) {
  //   var paramObj = {};
  //   paramObj["counterName"] = this.readingType;
  //   paramObj["currentReadingAmount"] = this.meterReadForm.controls['newReading'].value;
  //   paramObj["serialNumber"] = this.serialNumber;
  //   paramObj["readDate"] = this.readingDate;
  //   this.blockUI.start();
  //   this.eliteService.getRollOverMeterRead(paramObj).subscribe(
  //     (res: any) => {
  //       this.meterreadres = res;
  //       this.blockUI.stop();
  //     }
  //   );
  // }

  checkmeterread(parentIndex,index) {
    var paramObj = {};
    console.log("vlue of" + parentIndex  + " index ==" + index);
    //for(let i=0;i<this.lstMtrObj.length;i++){
    paramObj["counterName"] = parentIndex.counterName; 
    paramObj["currentReadingAmount"] = parentIndex.newReading;
    paramObj["serialNumber"] = this.serialNumber;
    paramObj["readDate"] = parentIndex.schdlDateVal;
    console.log("this.lstMtrObj.counterName == " + parentIndex.counterName +
    "     this.lstMtrObj.newReading ==" + parentIndex.newReading + "this.serialNumber " +
    this.serialNumber   + "       this.lstMtrObj.schdlDateVal  == " + parentIndex.schdlDateVal);
  // i=this.lstMtrObj.length +2;
    this.blockUI.start();
    this.eliteService.getRollOverMeterRead(paramObj).subscribe(
      (res: any) => {
        this.meterreadres = res.value1;
       console.log("this.meterreadres   == " + this.meterreadres);
       if(this.meterreadres=="H"){
        this.errorMessages="This machine has a Maximum Meter Read. The counter value displayed on the machine will reset to 0 once the meter has crossed this threshold.";
      this.errorflag1=true;
       }
        this.blockUI.stop();
      }
    );
   //  }
  }

  submitMeterRead() {
    var paramObj = {};
  let submitvalidation=true;
  this.errorMessages="";
  this.errorflag1=false;
  this.elitesubmitsuccess=false;
  console.log("this.meterreadres in submitMeterRead  == " + this.meterreadres);
  for(let i=0;i<this.lstMtrObj.length;i++){
    if(this.lstMtrObj[i].newReading== null || this.lstMtrObj[i].newReading ==""){
      
      this.errorMessages="Please enter reads in all required fields.";
      this.errorflag1=true;
      submitvalidation=false;
      return;
    }
    
    if(this.lstMtrObj[i].newReading<=this.lstMtrObj[i].lastReadingAmnt){
      
      this.errorMessages="Current meter read should be greater than last meter read.";
      
      this.errorflag1=true;
      
      submitvalidation=false;
      
      return;
    }
  }

  let curBwRead=0;
  let curTotalRead=0;
  for(let i=0;i<this.lstMtrObj.length;i++){
   
    let read=this.lstMtrObj[i];
    if(read.counterName=="Total (Hard Read)"){
      curTotalRead=parseInt(this.lstMtrObj[i].newReading);
      }
     if(read.counterName=="BW (Hard Read)"){
     curBwRead=parseInt(this.lstMtrObj[i].newReading);
     } 
  }

  if(curTotalRead < curBwRead){	
    this.errorMessages="BW(Hard Read) cannot be greater than Total(Hard Read).";
    this.errorflag1=true;
    submitvalidation=false;
    return;
  }
  
    for(let i=0;i<this.lstMtrObj.length;i++){

      paramObj["manufacturer"] = this.manufacturer;
      paramObj["model"] = this.model;
      paramObj["serialNumber"] = this.serialNumber;
      paramObj["zipcode"] = this.zipcode;
      paramObj["meterSerialNumber"] = this.serialNumber;
      paramObj["contractNumber"] = this.lstMtrObj[i].strContractNumber;
      paramObj["contractId"] = this.lstMtrObj[i].strContractId;
      paramObj["counterName"+i] = this.lstMtrObj[i].counterName;
      paramObj["lastReadingDate"+i] = this.lstMtrObj[i].lastReadingDt;
      paramObj["firstReadingDate"+i] = this.lstMtrObj[i].frstReadingDt;
      paramObj["readRequiredFlag"+i] = this.lstMtrObj[i].readingReqFlg; 
      paramObj["newReading"+i] = this.lstMtrObj[i].newReading;
      paramObj["strNewRead"+i] = this.lstMtrObj[i].newReading;
      paramObj["newReadingDate"+i] = this.lstMtrObj[i].schdlDateVal;
      paramObj["counterId"+i] = this.lstMtrObj[i].counterId;
      paramObj["counterType"+i] = this.lstMtrObj[i].counterTpe;
      paramObj["lastReadAmount"+i] = this.lstMtrObj[i].lastReadingAmnt;
      paramObj["firstReadAmount"+i] = this.lstMtrObj[i].firstReadingAmnt;
      paramObj["adcvAttribute"+i] = this.lstMtrObj[i].adcvAttribute;
      paramObj["processFlag"+i] = this.meterreadres;
    
    }
    paramObj["counterSize"] = this.lstMtrObj.length;
    paramObj["emailAddress"] = this.emailAddress;
   // paramObj["meterreadres"] = this.meterreadres;
    
    if(submitvalidation){
      this.blockUI.start();
    this.eliteService.submitMeterReads(paramObj).subscribe(
      (res: any) => {
        this.submtFlag=res.errorFlag;
        this.errorMsg=res.errorMsg;
        this.errorFlag=res.errorFlag;
        this.submitres=res.lstMtrObj[0]["strScheduledDate"];
      
        if(this.submtFlag == "E"){
          this.errorMessages=this.errorMsg;
          this.errorflag1=true;
          this.elitesubmitsuccess=false;
        }else if (this.submtFlag == "W"){
          this.meterreadres=this.submtFlag;
          this.errorMessages=this.errorMsg;
          this.errorflag1=true;
          this.elitesubmitsuccess=false;
        }else{
           this.gtmtrdtls(this.manufacturer,this.model,this.serialNumber,this.zipcode);
           this.elitesubmitsuccess=true;
         }
        
        this.blockUI.stop();

      }
    );
    }


    
  }

  getMeterReadHistoryDtls() {
    
    var paramObj = {};
    paramObj["manufacturer"] = this.manufacturer;
    paramObj["model"] = this.model;
    paramObj["serialNumber"] = this.serialNumber;
    paramObj["zipcode"] = this.zipcode;
    this.blockUI.start();
    this.eliteService.getMeterReadHistoryDtls(paramObj).subscribe(
      (res: any) => {
        for (let i = 0; i < res.lstManObj.length; i++) {
          if (i == 0) {
            this.strModel = res.lstMtrObj[i].strModel;
            this.strSerialNumber = res.lstMtrObj[i].strSerialNumber;
            this.counterLst = res.lstMtrObj[i].counterLst;
          }
        }
        this.lstMtrObj = res.lstMtrObj;
        this.blockUI.stop();

      }
    );

  }

  createInvoiceForm() {

    this.meterReadForm = new FormGroup  (       //new FormGroup(
      {
      newReading: new FormArray([])
      }
    )
  }

  viewMeterReadHistory() {
    this.initial = false;
    this.submit = false;
    this.view = true;
    this.errorflag1=false;
    this.errorMessages="";
   if(this.serialNumber === null || this.zipcode === null){
   
    this.errorflag1=true;
    this.errorMessages="No History Exists.";
   }
   else{
      var paramObj = {};
      paramObj["manufacturer"] = this.manufacturer;
      paramObj["model"] = this.model;
      paramObj["serialNumber"] = this.serialNumber;
      paramObj["zipcode"] = this.zipcode;
      //paramObj["meterSerialNumber"] = this.serialNumber;
      this.blockUI.start();
      this.eliteService.getMeterReadHistoryDtls(paramObj).subscribe(
        (res: any) => {
          
         let temp =res.mrdDtlsLstObj.flatMap(({ counterLst, ...rest }) =>
        counterLst.map(o => ({
          ...rest,
          ...o
        }))
      );

      this.counterLstview = temp;
      console.log("this.counterLstview == " + this.counterLstview.counterName);
      if(this.counterLstview.length==0){
        this.errorflag1=true;
        this.errorMessages="No History Exists.";
      }
      this.blockUI.stop();
        
        }
      );
   }

  }

  validateEmailAdd()
  {
    
  let atpos=this.emailAddress.indexOf("@");
  var dotpos=this.emailAddress.lastIndexOf(".");
  if (atpos<1 || dotpos<atpos+2 || dotpos+2>=this.emailAddress.length)
    {
          //$('#counterMsg').html("Not a valid email address.");
          this.emailMsg="Invalid email address";
       return false;
    }else{
      this.emailMsg="";
      return true;
    }
  }


  submitMeterReadHistory() {
    this.initial = false;
    this.view = false;
    this.submit = true;
    this.elitesubmitsuccess=false;
    var paramObj = {};
    this.errorMessages="";
    this.errorflag1=false;
    paramObj["manufacturer"] = this.manufacturer;
    paramObj["model"] = this.model;
    paramObj["serialNumber"] = this.serialNumber;
    paramObj["zipcode"] = this.zipcode;

    this.displaytable = true;
    this.submitbutton = true;
    this.blockUI.start();
    this.eliteService.gtmtrdtls(paramObj).subscribe(
      (res: any) => {

        let temp =res.lstMtrObj.flatMap(({ counterLst, ...rest }) =>
        counterLst.map(o => ({
          ...rest,
          ...o
        }))
        
      );
      this.blockUI.stop();
        this.lstMtrObj = temp;
        // if(this.lstMtrObj.length==0){
        //   this.errorMessages="Your combination of Manufacturer, Model, Serial # and Zip Code is invalid. Please try again or Contact Customer Service: 800-613-2228 .";
        //   this.errorflag1=true;
        //   return;
        // } 
        
        // for(let i in this.lstMtrObj){
        //   this.lstMtrObj[i]["newReading"] = "";
        // }
        
      }
    );
      

  }

  enableSubmit() {
    
    if (this.serialNumber == null || this.serialNumber == "" || this.zipcode == null || this.zipcode == "") {
      this.showSearch = false;
    }
    else {
      this.showSearch = true;
    }
    
  }

}
