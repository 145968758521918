import { Component, OnInit } from '@angular/core';
import { ReviewApprovalSerialsService } from './review-approval-serials.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-review-approval-serials',
  templateUrl: './review-approval-serials.component.html',
  styleUrls: ['./review-approval-serials.component.css']
})
export class ReviewApprovalSerialsComponent implements OnInit {

  serialApprovalList: any = [];
  checkboxtrue:boolean=false;
  totalRecords: any = 0;
  numOfRecsMsg: string = "";
  arrPgLinks: any = [];
  recPerPage: any = 10;
  pageNum: any = 1;

  constructor(private reviewApprovalSerialsService: ReviewApprovalSerialsService,
    private commonService: CommonServiceService) { }

  ngOnInit() {
    this.getSerialApprovalList(1, null);
  }

  enable(){
    this.checkboxtrue=true;
   }

   print(){
    window.print();
  }

   onRemove(){
    alert("S21 API is not ready");
   }

  getSerialApprovalList(pgNum: any, event: any) {
    // $("#ApproveBtn").attr('disabled', 'disabled');
   //$(".editBtn").attr("disabled", "disabled");
     
     this.pageNum=pgNum;
    let oParams = {
      "pageNum": pgNum, "recPerPage": this.recPerPage
    };
     this.reviewApprovalSerialsService.getPendingSerialApprovalList1(oParams).subscribe(
       (res: any) => {
        this.serialApprovalList=res.data;
        this.totalRecords = res.iTotalRecords;
        console.log("this.totalRecords  == " + this.totalRecords);
        console.log("this.recPerPage  == " + this.recPerPage);
        console.log("this.pageNum  == " + this.pageNum);
        this.numOfRecsMsg = this.commonService.getNumOfRecsMsg(this.totalRecords, this.recPerPage, this.pageNum);
        console.log("this.numOfRecsMsg  == " + this.numOfRecsMsg);
        this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords, this.pageNum, this.recPerPage);
        console.log("this.arrPgLinks  == " + this.arrPgLinks);
        console.log("this.serialApprovalList == " + this.serialApprovalList);
       }
     );
     
   }

}
