import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpJsonOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  defaultUrl: string;
  iframe: any;

  constructor(private util: Util, private http: HttpClient) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  ngOnInit() { }

  updateUserPreferences = function (data) {
    let url = this.defaultUrl + "updateUserPreferences";

    return this.http
      .post(url, data, httpJsonOptions)
      .pipe(catchError((this.util.handleError("updateUserPreferences"))));
  }

  getUserPreferences() : Observable<any>{
    return this.http.get(this.defaultUrl + "getUserPreferences").pipe(
      map(
        data => {
          return data;
        })
    );
  }

  getUserNotifPreferences() {
    return this.http.get(this.defaultUrl + "getUserNotifPreferences");
  }

  getIsWebAdmninUser() {
    return this.http.get(this.defaultUrl + "getIsWebAdmninUser");
  }

  getUserDataToDisplayNotif(): Observable<any>{
    return this.http.get(this.defaultUrl + "getUserDataToDisplayNotif").pipe(
      map(
        data => {
          return data;
        })
    );
  }

  saveUserNotifPreferences = function (data) {
    let url = this.defaultUrl + "saveUserNotifPreferences";

    return this.http
      .post(url, data, httpJsonOptions)
      .pipe(catchError((this.util.handleError("saveUserNotifPreferences"))));
  }
  private getIframeDocument(): Document {
    return this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document;
  }
  getTermsHtml=function(data)
  {
      let url = this.defaultUrl + "getTermsHtml";
  
      return this.http.post(url, null, { responseType: 'text' }).subscribe(
        (res) => {
          const iframeDocument = this.getIframeDocument();
          iframeDocument.write(res);
        },
        (error) => {
          console.error('Error loading content:', error);
        }
      );
    }

    processTermsAndCodn=function(data)
  {
      let url = this.defaultUrl + "processTermsAndCodn?userName="+data.userName+"&tc_id="+data.tc_id+"&AcceptedTnC="+data.tncAcceptanceFlag;
  
      return this.http
        .post(url, data, httpJsonOptions)
        .pipe(catchError((this.util.handleError("processTermsAndCodn"))));
    }

  saveUserDetails(data) : Observable<any>{
      let url = this.defaultUrl + "updateUserDetails";
  
      return this.http
        .post(url, data, httpJsonOptions)
        .pipe(catchError((this.util.handleError("updateUserDetails"))));
    }    
}
