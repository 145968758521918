export class canonUser {
    userId: string;
    userName: string;
    fullName: string;
    pwdChangeFlag: string;
    defaultAppCode: string;
    defaultCustomerAccount: string;
    defaultRole: string;
    salesTeamFlag: string;
    emsdCustomerFlag: string;
    limitAccessFlag: string;
    projectsAccessFlag: string;
    oracleUserFlag: string;
    emailAddress: string;
    oTwoFacAuthFlg: string;
    authenticated: boolean;
    route: string;
    errorMessage: string;
    phoneNumber: string;
    pwdHintFlag: string;
    description: string;
    firstName: string;
    lastName: string;
     tcId: string;

    canonUser() {
        this.userId = "";
        this.userName = "";
        this.fullName = "";
        this.pwdChangeFlag = "";
        this.defaultAppCode = "";
        this.defaultCustomerAccount = "";
        this.defaultRole = "";
        this.salesTeamFlag = "";
        this.emsdCustomerFlag = "";
        this.limitAccessFlag = "";
        this.projectsAccessFlag = "";
        this.oracleUserFlag = "";
        this.emailAddress = "";
        this.oTwoFacAuthFlg = "";
        this.authenticated;
        this.route = "";
        this.errorMessage = "";
        this.phoneNumber = "";
        this.pwdHintFlag = "";
        this.description = "";
        this.firstName = "";
        this.lastName = "";
    }

}