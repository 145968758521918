import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class PendingSerialApprovalService {
  defaultUrl: string;

  constructor(private http: HttpClient, private util: Util) {
      this.defaultUrl = this.util.getBaseUrl();
  }

  getPendingSerialApprovalList1 = (paramObj) => {
    let url = this.defaultUrl + 'userApprove/getPendingSerialApprovalUser?';
    let params = new URLSearchParams();
    for (let key in paramObj) {
        params.set(key, paramObj[key])
    }
    
    let qryStr = params.toString();
    url = url + qryStr;

    return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

}


approveRequest(oParams) : Observable<any>{
  let url = this.defaultUrl + "userApprove/setApproveSerialRel";
  let params = new URLSearchParams();
  for (let key in oParams) {
    params.set(key, oParams[key])
  }
  let qryStr = $.param(oParams);  
  
  return this.http
    .post(url,qryStr, httpOptions)
    .pipe(catchError((this.util.handleError("updateCatalog"))));

}

rejectRequest(oParams) : Observable<any>{
  let url = this.defaultUrl + "userApprove/setRejectSerialRel";
  let params = new URLSearchParams();
  for (let key in oParams) {
    params.set(key, oParams[key])
  }
  let qryStr = $.param(oParams);  
  
  return this.http
    .post(url,qryStr, httpOptions)
    .pipe(catchError((this.util.handleError("updateCatalog"))));

}

getExportToExcel = (paramObj) => {
  let url = this.defaultUrl + 'userApprove/getPendingSerialApprovalUserExport?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

}
}
