import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OrderSuppliesViewCartService {




	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
   counterReadDetailList=function(paramObj)
  {
      let url = this.defaultUrl + "ordersupplies/getCounterReadDetails?"; 

    let qryStr=$.param(paramObj);  
    url=url+qryStr;
      // "'ordSup.htm?mthd=gcrd&'"";
      return this.http
        .get(url, httpOptions)
        .pipe(catchError((this.util.handleError("counterReadDetailList"))));


    }

    getReadsReqFlag=function(paramObj) {
      let url = this.defaultUrl + "ordersupplies/getReadsReqFlag?"; 
      let qryStr=$.param(paramObj);
      url=url+qryStr;  
      //'ordSup.htm?mthd=rdsReqFlg&'
      return this.http
      .get(url, httpOptions)
      .pipe(catchError((this.util.handleError("getReadsReqFlag"))));



    }


}
