import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class NewPaymentService {

  private newPaymentAddedNotify = new Subject<any>();
  newPaymentAddedNotifyObservable$ = this.newPaymentAddedNotify.asObservable();

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  notifyNewPaymentAdded() {
    this.newPaymentAddedNotify.next();
  }

  getACHInfo=function()
  {
      let url = this.defaultUrl + "paymentMethod/getACHInfo";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getACHInfo"))));
  }

  getPCIDetails=function()
  {
      let url = this.defaultUrl + "paymentMethod/getpcid";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getPCIDetails"))));
  }

  validatePaymentInfo=function(oParams)
  {
      let url = this.defaultUrl + "makePayment/validateInfo?";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      url += qryStr;

      return this.http
        .post(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("validatePaymentInfo"))));
  }

  validatePaymentInfoACH=function(oParams)
  {
      let url = this.defaultUrl + "makePayment/validateInfoACH?";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("validatePaymentInfoACH"))));
  }

  insertACHAutoContDeets=function(oParams)
  {
      let url = this.defaultUrl + "paymentMethod/insertACHAutoContDeets?";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      //url += qryStr;

      return this.http
        .post(url,oParams, httpOptions)
        .pipe(catchError((this.util.handleError("insertACHAutoContDeets"))));
  }

  saveCCPayment=function(oParams)
  {
      let url = this.defaultUrl + "paymentMethod/saveCC";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("saveCCPayment"))));
  }


}
