import { Component, Input, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ProductExclusionService } from 'src/app/admin/configurator/product-exclusion/product-exclusion.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-exclusion',
  templateUrl: './product-exclusion.component.html',
  styleUrls: ['./product-exclusion.component.css']
})
export class ProductExclusionComponent implements OnInit {

  constructor(private commonService:CommonServiceService, private prodExclService : ProductExclusionService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title, private activatedRoute: ActivatedRoute) { }

  @BlockUI() blockUI : NgBlockUI;

  levelsList = [];  
  templateId;
  labelLineId;

  partyId;
  priceList = [];
  selPL : string = "-1";	

  priceBookLabelList = [];
  priceBookLabelListSize;
  itemCd = "";
  itemDescp ="";
  selLineId;
  showWaitMsg = false;
  showNoRecsMsg=false;
  recPerPage = 10;
  numOfRecsMsg;
  arrPgLinks = [];
  pageNum: number = 1;
  pbSortCol = "";
  pbSortOrd = "";

  bundleDetails = [];
  bundleModelImg="";
  bundleName = "";
  bundleItemNum= "";
  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
	/*
	this.activatedRoute.queryParams.subscribe(params => {
		this.labelLineId = params['labelLineId']; 
  		this.templateId = params['templateId'];
		this.partyId = params['partyId'];
		
		this.getUserLevels();
	});		
	*/
	
	this.templateId = this.param.templateId;
	this.labelLineId = -1;
	this.partyId = this.param.partyId;
	
	this.getLevelsList();
	this.getPriceListLOV();
	
  }

  getLevelsList() {
		var param = {
			"templateId"  : this.templateId,
			"labelLineId" : this.labelLineId 
		};
			
		this.prodExclService.getLevelsByParty(param).subscribe(resp => {
			if (resp != null) {
				console.log('getUserLevels():', resp);
				this.levelsList = resp.levelsDataList;
			}
		});	
  }	


  async getSubLevel(level, refAnc: HTMLElement){
	
	if (refAnc.children[0].classList.contains('openimg')) {
		refAnc.children[0].classList.remove("openimg");
      	refAnc.children[0].classList.add("closeimg");
		level.src="assets/images/details_close.png";
		
		var param = {
			"templateId"  : this.templateId,
			"labelLineId" : level.labelLineId 
		};
			
		var resp = await this.prodExclService.getLevelsByPartySync(param);
		console.log('getSubLevel():', resp); 
		if (resp != null) {
			level.subLevelList = resp.levelsDataList;
		}
		
		
	} else {
		refAnc.children[0].classList.remove("closeimg");
      	refAnc.children[0].classList.add("openimg");
		level.src="assets/images/details_open.png";
	}
	
  }


  getPriceListLOV() {
	
		var param = {
			"partyId"  : this.partyId,
		};
			
		this.prodExclService.getPriceListLOV(param).subscribe(resp => {
			if (resp != null) {
				console.log('getPriceListLOV():', resp);
				//this.priceList = resp.responseList[0];
				let map = new Map(Object.entries(resp.responseList[0]));
				map.forEach((values, keys) => {
					var entry = {
						"id" : keys,
						"name" : values
					}
					this.priceList.push(entry);
				});
					
			}
		});		
	
  }


  loadpriceBookModal(level) {
	this.selLineId = level.labelLineId;
	this.getPriceBookList(1, null);
  }

  getPriceBookList(pageNumber, event:any) {
	
	if(event!=null){
      	event.preventDefault();
    }	
	
	this.pageNum = pageNumber;
	this.showNoRecsMsg = false;
	
	var param = {
		"partyId" : this.partyId,
		"templateId": this.templateId,
		"priceListId": this.selPL,
		"itemDesc": this.itemDescp,
		"itemCode": this.itemCd,
		"labelLineId": this.selLineId,
		"start" :  (this.pageNum == 1 ) ? 0 : ( (this.pageNum -1 ) * (this.recPerPage) ),
		"length" : this.recPerPage, //(this.pageNum == 1 ) ? this.recPerPage  : this.recPerPage -1,
		"orderCol" : this.pbSortCol,
		"orderDir" : this.pbSortOrd,
	};
	
	this.showWaitMsg = true;
	this.prodExclService.getPriceBookLabelList(param).subscribe(resp => {
		if (resp != null) {
			console.log('getPriceBookLabelList():', resp);
			this.priceBookLabelList = resp.data[0];
			this.priceBookLabelListSize = resp.data[1];
			
			this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.priceBookLabelListSize,this.recPerPage,this.pageNum);
         	this.arrPgLinks=this.commonService.getPgLinks(this.priceBookLabelListSize,this.pageNum,this.recPerPage);			
		}
		
		this.showWaitMsg = false;
		if(this.priceBookLabelListSize == 0){
			this.showNoRecsMsg = true;
		}
	});	
		
  }


  upsertPricebookDetails(priceBookEntry, pbChkBox: any){
	
	var param = {
		"invItemId"	: priceBookEntry.itemId,
		"item"		: priceBookEntry.item,
		"itemDesc"	: priceBookEntry.itemDescription,
		"model"		: priceBookEntry.model,
		"templateId": this.templateId + "",
		"partyId"	: this.partyId + "",
		"labelLineId":this.selLineId + "",
		"bundleName": priceBookEntry.bundleName, 
		"exclude"	: (pbChkBox.checked) ? "EXCLUDE" : "INCLUDE",	
	};
	
	console.log('upsertPricebookDetails()', param);
	this.blockUI.start();
	this.prodExclService.upsertPricebookDetails(param).subscribe(resp => {
		console.log('upsertPricebookDetails() -> resp : ', resp);
		this.blockUI.stop();
	});
	
  }

  getBundleDetails(priceBookEntry) {
	
	var param = {
		"priceListId" : priceBookEntry.priceListId,
		"bundleName"  : priceBookEntry.bundleName	
	};
	
	this.prodExclService.getBundleDetails(param).subscribe(resp => {
		console.log('getBundleDetails() -> resp : ', resp);
		this.bundleModelImg = "https://mycanonbusiness.usa.canon.com/cpImages/config/"+priceBookEntry.model + ".jpg";
		this.bundleName = priceBookEntry.bundleName;
		this.bundleItemNum = priceBookEntry.item;
		this.bundleDetails = resp.data;
	});	
  }

  setPBSort(column){
	
	if(this.pbSortCol == "") {
		this.pbSortOrd = "";
	} else if (this.pbSortCol == column) {
		if (this.pbSortOrd == "" || this.pbSortOrd == "ASC") {
			this.pbSortOrd = "DESC";
		} else {
			this.pbSortOrd = "ASC";
		}
	} else if (this.pbSortCol != column) {
		this.pbSortOrd = "";
	}
	
	this.pbSortCol = column;
	
	this.getPriceBookList(1, null);
	
  }

}
