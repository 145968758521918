import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonServiceService } from '../service/common-service/common-service.service';
import { catchError } from 'rxjs/operators';
import { Util } from '../app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class SalesRepService {
  defaultUrl: string;
  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
 
  getSalesRepAccts=function(oParams)
  {
      let url = this.defaultUrl + "salesrep/getAcctList";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getSalesRepAccts"))));
  }

  getSalesRepDefaultAccount=function()
  {
      let url = this.defaultUrl + "salesrep/getDefAcct";
      return this.http
        .post(url,{}, httpOptions)
        .pipe(catchError((this.util.handleError("getSalesRepDefaultAccount"))));
  }
  switchSalesRepAccts=function(oParams)
  {
      let url = this.defaultUrl + "salesrep/switchAcct";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("switchSalesRepAccts"))));
  }

  checkSalesRespUser=function()
  {
      let url = this.defaultUrl + "salesrep/checkSalesRespUser";
      return this.http
        .post(url,{}, httpOptions)
        .pipe(catchError((this.util.handleError("checkSalesRespUser"))));
  }
  
}
