import { Component, OnInit } from '@angular/core';
import { ProductCatalogService } from './product-catalog.service';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: ['./product-catalog.component.css']
})
export class ProductCatalogComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  prodCatList:any=[];
  isGeneric:boolean = false; 
  custNumber:string = "";
  custName:string = "";
  alCatalog:any = [];
  productCategory:string = "0";
  productFamily:string = "0";

  constructor(private prodCatalogService:ProductCatalogService, private util : Util) { }

  ngOnInit() {
    this.blockUI.start();
    this.fetchProductCatalog();
    this.resetValues();    
  }

  setEdit(i, evt) {
    if(evt.target.checked) {
      this.alCatalog[i].edit = true;
    } else {
      this.alCatalog[i].edit = false;
    }
    
  }

  filterItemsOfType(type){
    let list = this.prodCatList.filter(item => item.productCategory == type);
    return list[0].productFamily.split(',');
}

  changedGeneric(evt) {
    if(evt.target.checked) {
      this.custName = "Generic";
      this.custNumber = "99999";
    } else { 
      this.custName = "";
      this.custNumber = "";
    }
  }

  resetValues() {
    this.fetchProductCatalog(); 
    this.isGeneric = false; 
    this.custNumber = "";
    this.custName = "";
    this.alCatalog = [];
    this.productCategory = "0";
    this.productFamily = "0";
  }

  fetchProductCatalog() {
    let data = this.prodCatalogService.getProductCatalog().subscribe(
      data=>{
          this.prodCatList = data;
          this.blockUI.stop();
      });
  }

  focusDescFunction(i) {
    $("#ItemDesc_"+i).attr("style", "height: 100px; width: 200px;");
  }

  focusOutDescFunction(i) {
    $("#ItemDesc_"+i).attr("style", "height: 35px; width: 200px;");
  }

  focusC1Function(i) {
    $("#comment1_"+i).attr("style", "height: 100px; width: 200px;");
  }
  
  focusOutC1Function(i) {
    $("#comment1_"+i).attr("style", "height: 35px; width: 200px;");
  }

  focusC2Function(i) {
    $("#comment2_"+i).attr("style", "height: 100px; width: 200px;");
  }
  
  focusOutC2Function(i) {
    $("#comment2_"+i).attr("style", "height: 35px; width: 200px;");
  }

  focusC3Function(i) {
    $("#comment3_"+i).attr("style", "height: 100px; width: 200px;");
  }
  
  focusOutC3Function(i) {
    $("#comment3_"+i).attr("style", "height: 35px; width: 200px;");
  }

  updateCustomerCatalog() {
    var count = 0;
    var myJson = {}; 
    this.blockUI.start();
        
    this.alCatalog.forEach((item) => {
      if (item.edit ) {
        var objName = "catalogId" + count;
        var objValue = item.catalogId;
        myJson[objName] = objValue;

        objName = "selectedItem" + count;
        objValue = 0;
        myJson[objName] = objValue;

        objName = "itemType" + count;
        objValue = item.strItemType;
        myJson[objName] = objValue;

        objName = "prodCat" + count;
        objValue = item.productCategory;
        myJson[objName] = objValue;

        objName = "prodFam" + count;
        objValue = item.productFamily;
        myJson[objName] = objValue;

        objName = "configuration" + count;
        objValue = item.strConfiguration;
        myJson[objName] = objValue;

        objName = "itemName" + count;
        objValue = item.strItemName;
        myJson[objName] = objValue;

        objName = "itemDesc" + count;
        objValue = item.strItemDesc;
        myJson[objName] = objValue;

        objName = "startDate" + count;
        objValue = item.startDate;
        myJson[objName] = this.convertDateFormat(objValue);

        objName = "endDate" + count;
        objValue = item.endDate;
        myJson[objName] = this.convertDateFormat(objValue);

        objName = "endDateFlag" + count;
        objValue = 'Yes';
        myJson[objName] = objValue;

        objName = "qty" + count;
        objValue = item.strQty;
        myJson[objName] = objValue;

        objName = "itemUrl" + count;
        objValue = item.strItemUrl;
        myJson[objName] = objValue;

        objName = "itemImage" + count;
        objValue = item.strImageDet;
        myJson[objName] = objValue;

        objName = "emailAddress" + count;
        objValue = item.strEmailAddress;
        myJson[objName] = objValue;

        objName = "comment1" + count;
        objValue = item.comment1;
        myJson[objName] = objValue;

        objName = "comment2" + count;
        objValue = item.comment2;
        myJson[objName] = objValue;

        objName = "comment3" + count;
        objValue = item.comment3;
        myJson[objName] = objValue;

        objName = "customerNumber" + count;
        var objValue1 = item.strCustNumber;
        myJson[objName] = objValue1;
    
        objName = "custName";
        var objValue1 = item.strCustomerName;
        myJson[objName] = objValue1;
    
        count++;
      }      
      
    });
    var objName = "catalogSize";
    var objValue = count;
    myJson[objName] = objValue;

    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    
    const formattedDate = `${day}-${month}-${year}`;

    objName = "checkDate";
    var objValue1 = formattedDate;
    myJson[objName] = objValue1;

    let data = this.prodCatalogService.updateCustomerCatalog(myJson).subscribe(
      data=>{
        this.blockUI.stop();
        this.resetValues();
      });
  }

  searchCatalog() { 
    this.blockUI.start();
    let params = {
      "custNumber": this.custNumber,
      "custName" : this.custName,
      "model" : '',
      "prodCat" : this.productCategory,
      "prodFam" : this.productFamily,
      "selectedCat" : this.productCategory,
      "selectedFam" : this.productFamily
    };
    
    let data = this.prodCatalogService.searchCatalog(params).subscribe(
      data=>{
          this.alCatalog = data.alCatalog;
          this.blockUI.stop();

          this.alCatalog.forEach((item) => {
            item.edit = false; 
          })
    
      });
  }

  convertDateFormat(inputDate: string): string {
    // Parse the input date string
    const parts = inputDate.split('-');
    const year = parts[0];
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);
  
    // Define an array of month names
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    // Create the output date string in the desired format
    const outputDate = `${day}-${monthNames[month - 1]}-${year}`;
  
    return outputDate;
  }

}
