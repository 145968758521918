import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OtherCanonProductsService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


   getProducts=function(){
    let url = this.defaultUrl + "products/gp"; // "products.htm/gp',data";
    //var productPromise = httpService.postJsonData('products.htm/gp',data);
    //return productPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getProducts"))));
	}

  getProductDetails=function(pId, itemNum){
    let url = this.defaultUrl + "products/gpcd"; // "products.htm/gpcd',data";
    var data:any = {};
    	data.productId = pId;
    	data.itemNumber = itemNum;
    	// var productDetailsPromise = httpService.postJsonData('products.htm/gpcd',data);
     	// return productDetailsPromise;
    return this.http
    .post(url,data, httpOptions)
    .pipe(catchError((this.util.handleError("getProducts"))));
	}






  


}
