import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class EliteService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }

    getManufacture_model = function () {
      let url = this.defaultUrl + "elite/getManufacturer";
      let params = {};
      return this.http
          .post(url, params.toString, httpOptions)
          .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
  }

  changeManufacturer = function () {
    let url = this.defaultUrl + "elite/getModels?";
    let params = {};
    return this.http
        .post(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}

getModels = (paramObj)=> {
  let url = this.defaultUrl + "elite/getModels?";
  let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        
  return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}

gtmtrdtls = (paramObj)=> {
  let url = this.defaultUrl + "elite/getMeterReadDtls?";
  let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        console.log("url == " + url);
  return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}

getRollOverMeterRead = (paramObj)=> {
  let url = this.defaultUrl + "elite/getRollOverMeterRead?";
  let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        console.log("url == " + url);
  return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}

submitMeterReads = (paramObj)=> {
  let url = this.defaultUrl + "elite/submitMeterReads?";
  let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        console.log("url == " + url);
  return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}

getMeterReadHistoryDtls = (paramObj)=> {
  let url = this.defaultUrl + "elite/getMeterReadHistoryDtls?";
  let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        console.log("url == " + url);
  return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
}





}
