import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AutopayService } from './autopay.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer} from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SearchFilterPipe } from 'src/app/custompipes/search-filter.pipe';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-autopay',
  templateUrl: './autopay.component.html',
  styleUrls: ['./autopay.component.css']
})
export class AutopayComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  ccPaymentList:any = [];
  achPaymentList:any = [];
  activeTab:string = 'auto';
  isCCPayment:boolean = false; 
  modalContractNumArr:string = '';
  modalSelectedSequenceNumber:string = '';
  contractNum:string = '';								
  cntrNotAssoMsg:string = '';
  cntrNotValidMsg:string = '';
  isAutoPayPageOn:boolean = false;
  achPayment:any='';
  ccPayment:any='';
  changeModalCCPayment:any='';
  changeModalACHPayment:any='';
  paymentMethod:string = '1';

  @ViewChild('addContractModal') addContractTemplate:TemplateRef<any>;
  addContractModal: BsModalRef;

  @ViewChild('autoPayDeletePaymentModal') autoPayDeletePaymentTemplate:TemplateRef<any>;
  autoPayDeletePaymentModal: BsModalRef;

  @ViewChild('changePaymentModal') changePaymentTemplate:TemplateRef<any>;
  changePaymentModal: BsModalRef;

  constructor(private autopayService:AutopayService, private sanitizer: DomSanitizer, private modalService: BsModalService) { }

  ngOnInit() {
    //this.blockUI.start();
    //this.setAutoFlg(false);
    this.onLoad();
  }

  onLoad() {
    this.blockUI.start();

    this.isCCPayment = false; 
    this.modalContractNumArr = '';
    this.modalSelectedSequenceNumber = '';
    this.contractNum = '';								
    this.cntrNotAssoMsg = '';
    this.cntrNotValidMsg = '';
    this.isAutoPayPageOn = false;
    this.achPayment='';
    this.ccPayment='';
    this.changeModalCCPayment='';
    this.changeModalACHPayment='';
    this.paymentMethod = '1';
    var checkBox = document.querySelector("#auto-pay-checkbox");

    $("#autoPayErrMsgSuccDiv").html("");	
		$("#autoPayErrMsgFailDiv").html("");	


    const existingACHList = this.autopayService.getExistingACHInfo();

    const existingCCList = this.autopayService.geccl();

    const autoPayFlag = this.autopayService.getAutoPayPageFlagOnStatus();

    forkJoin([existingACHList, existingCCList, autoPayFlag]).subscribe(
      data => {

        this.achPaymentList = data[0]['achPaymentList'];
        this.ccPaymentList = data[1]['ccPaymentList'];
        this.isAutoPayPageOn = data[2]['isAutoPayPageOn'];
        this.blockUI.stop();
      });
    
  }

  setAutoFlg(val:boolean) {
    if (val) {
      $('#apaychecked').removeClass("hidden");
      $('#apaynotchecked').addClass("hidden");
      $("#auto-pay-checkbox").prop('checked', true);
    } else {
      $('#apaynotchecked').removeClass("hidden");
      $('#apaychecked').addClass("hidden");
      $("#auto-pay-checkbox").prop('checked', false);
    }
  }

  redirectToNewPayment() {
    if (this.paymentMethod == 'card') {
      this.autopayService.card();  
    } else if (this.paymentMethod == 'bank') {
      this.autopayService.bank();
    }
  }

  autoPayChangePayment(value:string) {
    this.closeCPModal();
    if (value == 'card') {
      this.autopayService.card();  
    } else if (value == 'bank') {
      this.autopayService.bank();
    }
  }

  openAutoPayChangePaymentModal(payment:any, isCCPayment:boolean) {
    $("#autoPayErrMsgSuccDiv").html("");	
		$("#autoPayErrMsgFailDiv").html("");	
    this.isCCPayment = isCCPayment;
    if (isCCPayment) {
      this.ccPayment = payment; 
    } else {
      this.achPayment = payment;
    }

    this.openCPModal();
  }

  autoPayOpenAddContractModal(payment:any, isCCPayment:boolean){		
    this.isCCPayment = isCCPayment;	
   
     $("#autoPayErrMsgSuccDiv").html("");	
     $("#autoPayErrMsgFailDiv").html("");		
         
     if(isCCPayment){
           this.modalContractNumArr = payment.CONTRACT_LIST;
         }else{
           this.modalContractNumArr = payment.ACH_CONTRACT_LIST;
         }
         this.modalSelectedSequenceNumber = payment.SEQ_ID;
         
         this.openModal(); 
  }

autoPaySearchContractInfoRequest(){  
  
  $("#modalErrMsgDiv").html('');						
  //$('#modalContractListDiv').hide();
  $("#modalErrMsgDivFooter").html('');						
  //$('#modalErrMsgDivFooter').hide();

  let searchContractNum = $("#modalSearchContractNumber").val()

  if(searchContractNum === ''){
    $("#modalErrMsgDiv").html('Please enter the contract number. This is located within your invoice in the "Invoice Detail" section.');						
    $('#modalContractListDiv').hide();
  }else{
    this.blockUI.start();
    var paramObj = {};			
    paramObj["contractNumber"] = searchContractNum	
  
    let data = this.autopayService.getContractInfo(paramObj).subscribe(
      data=>{
        this.contractNum =  data.contractList;								
        this.cntrNotAssoMsg = data.cntrNotAssoMsg;
        this.cntrNotValidMsg = data.cntrNotValidMsg;
        this.blockUI.stop();
  
        $("#modalContractChkBxLbl").text(this.contractNum);	
            if (this.cntrNotValidMsg != null && this.cntrNotValidMsg.length > 0) {
              $("#modalErrMsgDiv").html(this.cntrNotValidMsg);						
              $('#modalContractListDiv').hide();
            }else if (this.cntrNotAssoMsg!= null && this.cntrNotAssoMsg.length > 0) {
              $("#modalErrMsgDiv").html(this.cntrNotAssoMsg);						
              $('#modalContractListDiv').hide();
            }else{
              $('#modalContractListDiv').show();
            }
      });
  }


}

openModal() {
  this.addContractModal = this.modalService.show(this.addContractTemplate);
}

closeModal() {
  this.addContractModal.hide();
}

openCPModal() {
  this.paymentMethod = '1';
  $("#pMethod").prop('selectedIndex', 1);

  this.changePaymentModal = this.modalService.show(this.changePaymentTemplate);
  this.autoPayChangeModalInit();
}

autoPayCancelChangePaymentCC() {
  this.closeCPModal();
}

closeCPModal() {
  this.changePaymentModal.hide();
  this.onLoad();
}

autoPayChangeModalInit(){
  if(this.isCCPayment){				
    this.changeModalCCPayment = this.ccPayment;				 		
  }else{
    this.changeModalACHPayment = this.achPayment; 
  }		
 };

openAutoPayDeleteConfirm(payment: any, isCCPayment: boolean) {
  this.autoPayDeletePaymentModal = this.modalService.show(this.autoPayDeletePaymentTemplate);
  this.isCCPayment = isCCPayment;
  if (isCCPayment) {
    this.ccPayment = payment; 
  } else {
    this.achPayment = payment;
  }
}

autoPayCancelDeleteCC() {
  this.autoPayDeletePaymentModal.hide();
  this.onLoad();
}

autoPayInsertContractDtlsModal(){
  var isCCPayment =  this.isCCPayment ;		
    var modalSelectedContractNums = '';
    var modalCntrChkBx = $('[name="modalCntrChkBx"]');

    $.each(modalCntrChkBx, function() {
            var $this = $(this);
            if(!$this.is(":checked")) {
                  modalSelectedContractNums += $(this).attr("id") + ",";        
            }
          }); 
        
          if( $('#modalSrchContractChkBx').is(':checked') ){
              modalSelectedContractNums += $('#modalContractChkBxLbl').text();        
          }
          
          if(modalSelectedContractNums.length == 0){              
            $("#modalErrMsgDivFooter").html(" Please enter the contract number. This is located within your invoice in the &quot;Invoice Detail&quot; section.");      
              return;
          }else{
              $("#modalErrMsgDivFooter").html("");      
          }
          
          let params = {
            "contractNumbers": modalSelectedContractNums, 
            "sequenceNumber": this.modalSelectedSequenceNumber
          };

          if(isCCPayment){
              this.insertAutoPayContractDtls(params);
          }else{
              this.callInsertACHAutoContDetails(params);				  							  		
          }	
          //this.autoPayCancelDeleteCC();	
  }

  insertAutoPayContractDtls(qryStr){			
    this.blockUI.start();	

    let data = this.autopayService.insertAutoPayContractDtls(qryStr).subscribe(
      data=>{
        this.blockUI.stop();
        var status = data.status;
        this.isAutoPayPageOn = data.isAutoPayPageOn;					
        if(status === 'S'){								
              this.isAutoPayPageOn = data.isAutoPayPageOn;	
              //this.closeModal();
              //this.refreshAutoPayPage();
              

              this.autopayService.geccl().subscribe(
                data1=>{
                  
                  $("#modalSearchContractNumber").val('');

                  $('#modalContractListDiv').hide();
                  this.ccPaymentList = data1.ccPaymentList;
          
                  /* this.ccPaymentList.forEach((payment) => {
                    if (payment.AUTO_FLG == "Y") {
                      this.setAutoFlg(true);
                    }
                  }); */

                  let searchFilterPipe = new SearchFilterPipe();
                  let selectedPaymentList = searchFilterPipe.transform(this.ccPaymentList,this.modalSelectedSequenceNumber, ['SEQ_ID']);
                  if(selectedPaymentList.length > 0){
                    this.modalContractNumArr = selectedPaymentList[0].CONTRACT_LIST;
                  }
                });
              
              

              $("#autoPayErrMsgSuccDiv").html("The Credit Card contract details has been saved successfully."); 	
              window.scrollTo(0, 0);
              this.blockUI.stop();
        }else {
              this.refreshAutoPayPage();	
              $("#autoPayErrMsgFailDiv").html("Error in saving Credit Card contract details. Please try again.");  
        }		
        //this.activeTab = 'auto';        
      });
  }

  callInsertACHAutoContDetails(qryStr) {
    this.blockUI.start();

    let data = this.autopayService.insertACHAutoContDetails(qryStr).subscribe(
      data=>{
         					
          if (data.status == 'S'){				  		
            this.isAutoPayPageOn = data.isAutoPayPageOn;	
            //this.closeModal();
            //this.refreshAutoPayPage();	
            //this.autoPayAddContractModalInstance.dismiss('cancel');	
            
            this.autopayService.getExistingACHInfo().subscribe(
              data1=>{
                
                $("#modalSearchContractNumber").val('');

                $('#modalContractListDiv').hide();
                this.achPaymentList = data1.achPaymentList;
        
                /* this.achPaymentList.forEach((payment) => {
                  if (payment.AUTO_FLG == "Y") {
                    this.setAutoFlg(true);
                  }
                }); */

                let searchFilterPipe = new SearchFilterPipe();
                let selectedPaymentList = searchFilterPipe.transform(this.achPaymentList,this.modalSelectedSequenceNumber, ['SEQ_ID']);
                if(selectedPaymentList.length > 0){
                  this.modalContractNumArr = selectedPaymentList[0].ACH_CONTRACT_LIST;
                }
              });
            $("#autoPayErrMsgSuccDiv").html("The ACH contract details has been saved successfully."); 
            this.blockUI.stop();
          }else{
              this.refreshAutoPayPage();	
              $("#autoPayErrMsgFailDiv").html("Error in saving ACH contract details. Please try again.");  
              this.blockUI.stop();
            }		           		
            //this.activeTab = 'auto';
          });

}

insertAutoPayContractDtlsModal(){
  this.autoPayInsertContractDtlsModal();
  //this.closeModal();
};

  refreshAutoPayPage(){	
    //this.blockUI.start();			
    //this.getAutoPayPageFlagOnStatus();	

      this.onLoad();
      //this.isAutoPayOn(this.isAutoPayPageOn,this.ccPaymentList,this.achPaymentList,false);
  }

  isAutoPayOn(isAutoPayPageOn,ccPaymentList,achPaymentList,isAutoPayTab) {   
    this.blockUI.stop();
    $('#auto-pay-checkbox').click(function(){return false;});            	
    if(isAutoPayTab == undefined){
      $("#autoPayErrMsgSuccDiv").html('');	
      $("#autoPayErrMsgFailDiv").html('');	
    }

    let checkBox = document.querySelector("#auto-pay-checkbox");
	  //const autoPayOn = document.querySelectorAll(".auto-pay-checked");
	  //const autoPayOff = document.querySelectorAll(".auto-pay-not-checked");
    
    if(isAutoPayPageOn){  
      //checkBox.checked = true;	   
      if(ccPaymentList!= null){
      for(var i=0;i<ccPaymentList.length;i++){
      if(ccPaymentList[i].CONTRACT_LIST!= null && ccPaymentList[i].CONTRACT_LIST.length ==0){
          $("#chngPymntCCModalBtn-"+ccPaymentList[i].SEQ_ID).prop('disabled', true);
      }else{
          $("#chngPymntCCModalBtn-"+ccPaymentList[i].SEQ_ID).prop('disabled', false);
      }					
      }
      }
    }

    if(achPaymentList!= null){
      for(var i=0;i< (achPaymentList!= null &&  achPaymentList.length);i++){
        if(achPaymentList[i].ACH_CONTRACT_LIST!= null && achPaymentList[i].ACH_CONTRACT_LIST.length ==0){
           $("#chngPymntACHModalBtn-"+achPaymentList[i].SEQ_ID).prop('disabled', true);
        }else{
           $("#chngPymntACHModalBtn-"+achPaymentList[i].SEQ_ID).prop('disabled', false);
        }					
      }	
    
    }else{         	
         //checkBox.checked = false;	         	
    }

    /* autoPayOn.forEach((element) => {
      element.classList.remove("hidden");
    });
    autoPayOn.forEach((element) => {
      element.classList.add("hidden");
    });
    autoPayOff.forEach((element) => {
      element.classList.remove("hidden");
    });
    autoPayOff.forEach((element) => {
      element.classList.add("hidden");
    }); */

    /*
    if (checkBox.checked == true) { 
      autoPayOn.forEach((element) => {
        element.classList.remove("hidden");
      });
    } else { 
        autoPayOff.forEach((element) => {                	
        element.classList.remove("hidden");
      });
    }*/  
  }

  autoPayDelete(){
    if(this.isCCPayment){				 		
      this.autoPayDeleteCC();
    } else{										
     this.deleteACHPayment(this.achPayment.SEQ_ID);
     
   }
   //this.autoPayCancelDeleteCC();
  }

  autoPayDeleteCC(){	
    this.blockUI.start();
    var paramObj = {};						
    paramObj["sequenceNumber"] = this.ccPayment.SEQ_ID;						
    var qryStr=$.param(paramObj);		
    
    let data = this.autopayService.deleteCCPayment(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        if(data.status == 'S'){
          							
          this.isAutoPayPageOn = data.isAutoPayPageOn;	
          this.refreshAutoPayPage();
          	
          $("#autoPayErrMsgSuccDiv").html("The Credit Card has been deleted successfully.");
          this.autoPayDeletePaymentModal.hide();	
          window.scrollTo(0, 0);
          
        }else{
            this.refreshAutoPayPage();	
            $("#autoPayErrMsgFailDiv").html("Error in deleting Credit Card details. Please try again.");  	
        }									
        //this.activeTab = 'auto';
      });
    
  }	

  deleteACHPayment(sequenceNumber){		
    this.blockUI.start();
    var paramObj = {};						
    paramObj["sequenceNumber"] = sequenceNumber;			
    
    let params = {
      "sequenceNumber": sequenceNumber
    };

    
    let data = this.autopayService.deleteACHPayment(paramObj).subscribe(
      data=>{
        this.blockUI.stop();
        if(data.status == 'S'){					
          this.isAutoPayPageOn = data.isAutoPayPageOn;	
          this.refreshAutoPayPage();					
          $("#autoPayErrMsgSuccDiv").html("The ACH Payment has been deleted successfully.");	
          this.autoPayDeletePaymentModal.hide();	
          window.scrollTo(0, 0);							
        }else{
          this.refreshAutoPayPage();	
          $("#autoPayErrMsgFailDiv").html("Error in deleting ACH Payment. Please try again.");  	
        }						
        //this.activeTab = 'auto';
      });
    
  }

  autoPayApplyPayment(){
    this.blockUI.start();
    let ccSeqId:any  = $('input[name="pymntOptnsCCModalRadBtn"]:checked').val();				
    let achSeqId:any = $('input[name="pymntOptnsACHModalRadBtn"]:checked').val();						
    let params = {};	
   
    if(this.isCCPayment){				 
       if(ccSeqId != null && ccSeqId.length > 0){
        params = {
          "sequenceIdTo": ccSeqId, 
          "sequenceIdFrom": this.ccPayment.SEQ_ID,
          "paymentMthdFrom" : 'CC',
          "paymentMthdTo" : 'CC'
        };

       }
       if(achSeqId != null && achSeqId.length > 0){
        params = {
          "sequenceIdTo": achSeqId, 
          "sequenceIdFrom": this.ccPayment.SEQ_ID,
          "paymentMthdFrom" : 'CC',
          "paymentMthdTo" : 'ACH'
        };

       }							
    }else{
    
        if(ccSeqId != null && ccSeqId.length > 0){
          params = {
            "sequenceIdTo": ccSeqId, 
            "sequenceIdFrom": this.achPayment.SEQ_ID,
            "paymentMthdFrom" : 'ACH',
            "paymentMthdTo" : 'CC'
          };
  
       }
       if(achSeqId != null && achSeqId.length > 0){
        params = {
          "sequenceIdTo": achSeqId, 
          "sequenceIdFrom": this.achPayment.SEQ_ID,
          "paymentMthdFrom" : 'ACH',
          "paymentMthdTo" : 'ACH'
        };
       }	
    
    }
      
      let data = this.autopayService.changePaymentInfo(params).subscribe(
        data=>{
          this.blockUI.stop();
          this.autoPayCancelChangePaymentCC();
          var status = data.status;
          if(status == 'S'){
            this.refreshAutoPayPage();	
            $("#autoPayErrMsgSuccDiv").html("Payment Changed successfully");
            window.scrollTo(0, 0);
          }else {
            this.refreshAutoPayPage();	
            $("#autoPayErrMsgFailDiv").html("Error in saving the changed Payment details.");
          }
          this.activeTab = 'auto';    
        });
      
     }		 	 			 	

}

