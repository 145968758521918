import { Component, OnInit } from '@angular/core';
import { RequestPrintAssessmentService } from './requestprintassessment.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-requestprintassessment',
  templateUrl: './requestprintassessment.component.html',
  styleUrls: ['./requestprintassessment.component.css']
})
export class RequestprintassessmentComponent implements OnInit {

  bEmailValue:any="";
  bPersonValue:any="";
  bTelValue:any="";
  tEmailValue:any="";
  tPersonValue:any="";
  tTelValue:any="";

  constructor(private commonService:CommonServiceService, private activatedRoute: ActivatedRoute, private requestPrintAssessmentService:RequestPrintAssessmentService , private router:Router) { }

  ngOnInit() {
    this.requestPrintAssessmentService.getPhoneNum().subscribe(
      data=>{
        this.bTelValue=data.phoneNo;
      }
    );
    this.bEmailValue = sessionStorage.getItem('email');
    this.bPersonValue = sessionStorage.getItem('fullName');
    console.log("Printing values:  "+ this.bEmailValue + " : "+ this.bPersonValue);
  }

 clearClick(){
    //	alert('in clear');
      $("#assesForm input").val("");
       $('#counterMsgS').html("");
       $('#counterMsgE').html("");
      }


      submitbuttomMethod(){
        
        if(this.bEmailValue.trim()==""){
          alert("Email'  is required.");
          return;

        }
        else{
          var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if(re.test(this.bEmailValue))
    			  {
              
              this.requestPrintAssessmentService.sendEmail(this.bPersonValue,
                this.bEmailValue, this.bTelValue, this.tPersonValue, this.tEmailValue, this.tTelValue).subscribe(
                  data=>{
                    if((data)==undefined || (data)==null || (data)=="" || (data)=="E" || (data)!="Y"  ){
                      $('#counterMsgE').html("We were unable to process your request. Please confirm you have entered all information below.</br>If you continue to have an issue, please contact Customer Service at 1-800-613-2228.");
                     // alert('We were unable to process your request. Please confirm you have entered all information below.</br>If you continue to have an issue, please contact Customer Service at 1-800-613-2228.'); 
                              }
                     else if((data.trim())=="Y")
                     {
                       $('#counterMsgS').html("Request submitted successfully.");
                     //  alert('Request submitted successfully!!');
                     }
                  }
                );
              
            }
            else {
              alert("'Email'  is Invalid.");
              return;
            }
        }


      }



}
