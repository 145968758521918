import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class SiteSurveyService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


  getUserContactInfo=function(sn) {
    let url = this.defaultUrl + "survey/getContactDetails?sn="+ sn;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getUserContactDetails"))));
    
  }

  saveUserContactInfo=function(input) {
    let url = this.defaultUrl + "survey/saveContactDetails";
    return this.http
    .post(url,input, httpOptions)
    .pipe(catchError((this.util.handleError("saveUserContactInfo"))));
    
  }


  getDeviceDetails=function(input) {
    let url = this.defaultUrl + "survey/getDeviceDetails";
    return this.http
    .post(url,input, httpOptions)
    .pipe(catchError((this.util.handleError("getDeviceDetails"))));
    
  }

  saveDeviceInfo=function(input) {
    let url = this.defaultUrl + "survey/saveDeviceDetails";
    return this.http
    .post(url,input, httpOptions)
    .pipe(catchError((this.util.handleError("saveDeviceDetails"))));
    
  }

  //MFP code below:
  getMFPSetting(data): Observable<any> {
    let data1 = JSON.stringify(data);
  let qryStr=$.param(data); 
    let initialUrl = this.defaultUrl + "survey/getMfpSetting?" + qryStr;
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.util.handleError("getMFPSetting")))
  }	

saveMFPSetting(data): Observable<any> {
    let data1 = JSON.stringify(data);
  let qryStr=$.param(data); 
    let initialUrl = this.defaultUrl + "survey/saveMfpSetting?" + qryStr;
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.util.handleError("saveMfpSetting")))
  }	


  validateSubmit(data): Observable<any> {
    let data1 = JSON.stringify(data);
    let initialUrl = this.defaultUrl + "survey/validateSubmit"
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.util.handleError("validateSubmit")))
  }	

saveDeviceDetails(data): Observable<any> {
  let data1 = JSON.stringify(data);
  let initialUrl = this.defaultUrl + "survey/saveDeviceDetails"
  return this.http
    .post(initialUrl, data1, httpOptions)
    .pipe(catchError(this.util.handleError("saveDeviceDetails")))
}	


submitApi(data): Observable<any> {
  let data1 = JSON.stringify(data);
  let initialUrl = this.defaultUrl + "survey/submitApi"
  return this.http
    .post(initialUrl, data1, httpOptions)
    .pipe(catchError(this.util.handleError("submitApi")))
}


submit(data): Observable<any> {
  let data1 = JSON.stringify(data);
  let initialUrl = this.defaultUrl + "survey/submit"
  return this.http
    .post(initialUrl, data1, httpOptions)
    .pipe(catchError(this.util.handleError("submit")))
}

uploadAttachment(file: File, somnumber, fileDesc): Observable<any> {
  const formData: FormData = new FormData();

  formData.append('file', file);
  formData.append('somQuoteNum',somnumber );
  formData.append('fileDesc',fileDesc );
  let initialUrl = this.defaultUrl + "surveyattach/uploadAttachment"
  return this.http
    .post(initialUrl, formData)
    .pipe(catchError(this.util.handleError("uploadAttachment")))
}

deleteAttachment(id, somQuoteNum): Observable<any> {
  var url = this.defaultUrl +  "surveyattach/deleteAttachment" + "?id=" + id+ "&somQuoteNum=" + somQuoteNum;
  return this.http
  .post(url,null)
  .pipe(catchError((this.util.handleError("deleteAttachment"))));
}

getQuoteAttachmentList(somQuoteNum): Observable<any> {
  var url = this.defaultUrl +  "surveyattach/getQuoteAttachmentList" + "?somQuoteNum=" + somQuoteNum;
  return this.http
  .post(url,null)
  .pipe(catchError((this.util.handleError("getQuoteAttachmentList"))));
}

}
