import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { SavedPaymentService } from './saved-payment.service';
import { AutopayService } from '../autopay/autopay.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-saved-payment',
  templateUrl: './saved-payment.component.html',
  styleUrls: ['./saved-payment.component.css']
})
export class SavedPaymentComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  activeTab:string = 'save';
  achPaymentList:any=[];
  ccPaymentList:any=[];
  addedPayMsg:string="";
  fullName:string="";
  sequenceNumber:string = '';
	expiryMon:string = '';
	expiryYear:string = '';
	selectedExpiryMon:string = '';
  showCC:boolean = false;
  monthList:any = [];
  yearList:any = [];
  monthErrMsg:string = '';
  yearErrMsg:string = '';
  saveSuccessMessage:string = '';
  selectedCC:any = [];
  type:string = '';
  achIndex:any = '';

  @ViewChild('deleteModal') deleteModalTemplate:TemplateRef<any>;
  deleteModal: BsModalRef;

  constructor(private commonService:CommonServiceService, private sanitizer: DomSanitizer, private modalService: BsModalService,
              private savedPaymentService:SavedPaymentService, private autopayService:AutopayService) { }

  ngOnInit() {
    this.getInitialData();
    this.getCCandACH();
    this.addedPayMsg = '';
    this.monthErrMsg = '';
    this.yearErrMsg = '';
  }

  confirm() {
    if (this.type == 'ach') {
      this.deleteACHPaymentInfo(this.achIndex);
    } else if (this.type == 'cc'){
      this.deleteCC(this.selectedCC);
    }
    this.closeModal();
  }

  openModal(type:string, x:any) {
    this.type = type;
    if (type == 'ach') {
      this.achIndex = x;
    } else if (type == 'cc') {
      this.selectedCC = x;
    }
    this.deleteModal = this.modalService.show(this.deleteModalTemplate);
  }
  
  closeModal() {
    this.deleteModal.hide();
  }

  changeAutoPay() {
    this.autopayService.autopay();
  }

  getCCandACH() {
    this.blockUI.start();
    let ccData = this.savedPaymentService.getCCList().subscribe(
      ccData=>{
        this.ccPaymentList = ccData.ccPaymentList;
        this.blockUI.stop();
      }
    );
  
    let achData = this.savedPaymentService.getACHList().subscribe(
      achData=>{
        this.achPaymentList = achData.achPaymentList;
        this.blockUI.stop();
      }
    );
  }

  saveCCPayment(ccDetails){
    this.blockUI.start();
    var paramObj = {};	
    
    this.showCC = true;
    this.expiryMon = ccDetails.EXPIRY_MONTH;
    this.expiryYear = ccDetails.EXPIRY_YEAR;
    this.sequenceNumber = ccDetails.SEQ_ID
    
    paramObj["sequenceNumber"] = this.sequenceNumber;
    paramObj["expiryMon"] = this.expiryMon;
    paramObj["expiryYear"] = this.expiryYear;
      
    var qryStr=$.param(paramObj);
    
    let confirmPaymentPromise = this.savedPaymentService.saveCCPayment(paramObj).subscribe(
      confirmPaymentPromise=>{
        this.blockUI.stop();
        if(confirmPaymentPromise.status == 'S'){
          this.monthErrMsg = '';
          this.yearErrMsg = '';
          this.showCC = false;
          this.saveSuccessMessage = 'The payment method is saved successfully.';
          this.getCCandACH();
          this.addedPayMsg = 'The payment method is saved successfully.';
          window.scrollTo(0, 0);
        }else{
          this.monthErrMsg = confirmPaymentPromise.monthErrMsg;
          this.yearErrMsg = confirmPaymentPromise.yearErrMsg;
        }
        
      }
    );

  }

  saveACHPayment(id:any) {
    var fullName:any = $('#'+id+'_new-payment-bank-account-holder-name').val(); 
    var routingNo:any = $('#'+id+'_new-payment-bank-routing-number-name').val(); 
    this.blockUI.start();
    var validationFailed = false; 
    
    if (fullName == '' || fullName == null || fullName == undefined) {
      $('#'+id+'_holderName').removeAttr('hidden');	
      validationFailed = true;
    } else {
      $('#'+id+'_holderName').attr("hidden","true");
    }

    if (routingNo == '' || routingNo == null || routingNo == undefined) {
      $('#'+id+'_bankRoutingNo').removeAttr('hidden');	
      validationFailed = true;
    } else {
      $('#'+id+'_bankRoutingNo').attr("hidden","true");
    }
    
    if (validationFailed) {
      this.blockUI.stop();
      return;
    }
    
    var nameArray = fullName.split(" ");
    var firstName = nameArray[0];
    var lastName = nameArray[1];
    
    var paramObj = {};
    
    paramObj["firstName"] = firstName;
    paramObj["lastName"] = lastName;
    paramObj["sequenceNumber"] = this.achPaymentList[id].SEQ_ID;		
    paramObj["routingNumber"] = routingNo;
          
    let updateBankInfoPromise = this.savedPaymentService.updateBankInfo(paramObj).subscribe(
      updateBankInfoPromise=>{
        //this.ccPaymentList = updateBankInfoPromise.ccPaymentList;
        if (updateBankInfoPromise.status == 'S'){
          this.addedPayMsg = 'The payment method is saved successfully.';
          window.scrollTo(0, 0);
        }
        this.blockUI.stop();
      }
    );

  }

  deleteCC(ccPayment: any){
    this.blockUI.start();
    var paramObj = {};	
    
    paramObj["sequenceNumber"] = ccPayment.SEQ_ID;
      
    var qryStr=$.param(paramObj);
    
    let deleteCC = this.savedPaymentService.deleteCCPayment(paramObj).subscribe(
      deleteCC=>{
        this.blockUI.stop();
        if(deleteCC.status == 'S'){
          this.saveSuccessMessage = 'The changes have been saved successfully.';
          this.getCCandACH();
        }
      }
    );

  }

  deleteACHPaymentInfo(id:any) {
    this.blockUI.start();
    if (this.achPaymentList[id].AUTO_FLG == 'Y') {
      $('#'+id+'_deleteErrorAlert').toggleClass("hidden");
      $('#'+id+'_errorText').text('Failed to delete as auto pay is enabled.');
    }
    else {		
      var paramObj = {};						
        paramObj["sequenceNumber"] = this.achPaymentList[id].SEQ_ID;			
        var qryStr=$.param(paramObj);					
        
        let deleteACH = this.savedPaymentService.deleteACHInfo(paramObj).subscribe(
          deleteACH=>{
            this.blockUI.stop();
            if(deleteACH.status == 'S'){				
              this.getCCandACH();				
              $('#'+id+'_deleteSuccessAlert').attr("hidden","true");										
            }else{
              $('#'+id+'_deleteErrorAlert').attr("hidden","true");
            }          
          }
        );

    }
  }

  getInitialData(){
    this.blockUI.start();
    
    let initialData = this.savedPaymentService.getInitialData().subscribe(
      initialData=>{
        this.blockUI.stop();
        this.monthList = initialData.monthList;
        this.yearList = initialData.yearList;
      }
    );  
  }

  clearCCDetails(ccDetails){
    ccDetails.EXPIRY_MONTH = '';
    ccDetails.EXPIRY_YEAR = '';
  }

  clearBankInfo(index){
    $('#'+index+'_new-payment-bank-account-holder-name').val('');
    $('#'+index+'_new-payment-bank-routing-number-name').val('');
  }
  
  cancelBankInfo(index) {
    var firstName = this.achPaymentList[index].FIRST_NAME ? this.achPaymentList[index].FIRST_NAME : '';
    var lastName = this.achPaymentList[index].LAST_NAME ? this.achPaymentList[index].LAST_NAME : '';
    $('#'+index+'_new-payment-bank-account-holder-name').val(firstName + ' ' + lastName);
    $('#'+index+'_new-payment-bank-routing-number-name').val(this.achPaymentList[index].ROUTING_NUM);
  }

}
