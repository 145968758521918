import { Component, OnInit } from '@angular/core';
import { PendingSerialApprovalService } from './pending-serial-approval.service';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-pending-serial-approval',
  templateUrl: './pending-serial-approval.component.html',
  styleUrls: ['./pending-serial-approval.component.css']
})
export class PendingSerialApprovalComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;

  serialApprovalList: any = [];
  statusMsgApprove:string="";
  statusFlgApprove:string="";
  statusMsgReject:string="";
  statusFlgReject:string="";
  statusFlgApproved:string="";
  statusFlgRejectMsg:string="Selected Account Request is been Rejected.";
  statusFlgApproveMsg:string="Selected Account Request are Successfully linked.";
  rejectstatussuccess:boolean=false;
  rejectstatusfailure:boolean=false;
  approvedstatussuccess:boolean=false;
  approvedstatusfailure:boolean=false;
  checkboxtrue:boolean=false;
  totalRecords: any = 0;
  numOfRecsMsg: string = "";
  arrPgLinks: any = [];
  recPerPage: any = 10;
  pageNum: any = 1;
  
  

  constructor(private pendingSerialApprovalService: PendingSerialApprovalService,
    private router: Router, private commonService: CommonServiceService) { }

  ngOnInit() {
    this.getPendingSerialApprovalList(1, null);
  }

  getPendingSerialApprovalList(pgNum: any, event: any) {
   // $("#ApproveBtn").attr('disabled', 'disabled');
	//$(".editBtn").attr("disabled", "disabled");
	
	this.approvedstatussuccess=false;
	this.rejectstatussuccess=false;
	this.approvedstatusfailure=false;
	this.rejectstatusfailure=false;
	
	this.blockUI.start();
	
  	this.pageNum=pgNum;
    let oParams = {
      "pageNum": pgNum, "recPerPage": this.recPerPage
    };
    this.pendingSerialApprovalService.getPendingSerialApprovalList1(oParams).subscribe(
      (res: any) => {
		this.blockUI.stop();
        this.serialApprovalList=res.data;
        this.totalRecords = res.iTotalRecords;
        console.log("this.totalRecords  == " + this.totalRecords);
        console.log("this.recPerPage  == " + this.recPerPage);
        console.log("this.pageNum  == " + this.pageNum);
        this.numOfRecsMsg = this.commonService.getNumOfRecsMsg(this.totalRecords, this.recPerPage, this.pageNum);
        console.log("this.numOfRecsMsg  == " + this.numOfRecsMsg);
        this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords, this.pageNum, this.recPerPage);
        console.log("this.arrPgLinks  == " + this.arrPgLinks);
        console.log("this.serialApprovalList == " + this.serialApprovalList);
      }
    );
    
  }
  
  exportToExcel(){
    let oParams = {
      "start": 0, 
      "length": 10
    };
    this.pendingSerialApprovalService.getExportToExcel(oParams).subscribe(
      (res: any) => {
        //this.serialApprovalList=res.data;
        console.log("res.data  == " + res.data);
        this.jsonToExcel(res.data,"PendingSerials",true,null,null);
      }
    );
  }

  print(){
    window.print();
  }

  jsonToExcel(JSONData, outputFileName, ShowLabel,bCustom,customLblsArr) {
        
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';    
    //Set Report title in first row or line
    //CSV += ReportTitle + '\r\n\n';
    
     
    var lbls;
    if(bCustom){
        lbls=customLblsArr;
    }else{
        lbls=arrData[0];
    }
    
    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";
        //This loop will extract the label from 1st index of on array
        if(bCustom){
            for (var index in lbls) {
                //Now convert each value to string and comma-seprated
                var lbl=lbls[index];
                lbl=this.camel2title(lbl);  
                row += lbl + ',';
            }
        }else{ 
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                index=this.camel2title(index);
                row += index + ',';
            }
        }
        row = row.slice(0, -1);
        //append Label row with line break
       /* if(row.indexOf("Dealer Dba")>-1){
          row=row.replace("Dealer Dba","Dealer DBA");
        }
        */
        CSV += row + '\r\n';
    }
    
    if(bCustom){
        
            //1st loop is to extract each row
            for (var i = 0; i < arrData.length; i++) {
                var row = "";
            //2nd loop will extract each column and convert it in string comma-seprated
                 var oData=arrData[i];
                
                for(const k in lbls){  
                    
                    //Skip "" when '=' is appended for preserving leading zeros
                    var arrDataVal = oData[lbls[k]];
                    //console.log(arrDataVal);
                    //check for null and assign "" string
                    if(null == arrDataVal)
                        arrDataVal = "";
                    if(!isNaN(arrDataVal))
                        arrDataVal = arrDataVal.toString();
                    if(arrDataVal.startsWith('=')){
                        row += arrDataVal + ',';
                        continue;
                    }
                     
                    row += '"' + arrDataVal + '",';
                 
            }
                row.slice(0, row.length - 1);
                //add a line break after each row
                CSV += row + '\r\n';
        }
        
    }else{
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";
        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
            
            //Skip "" when '=' is appended for preserving leading zeros
            var arrDataVal = (arrData[i][index]);
            //check for null and assign "" string
            if(null == arrDataVal)
                arrDataVal = "";
            if(!isNaN(arrDataVal))
                arrDataVal = arrDataVal.toString();
            if(arrDataVal.startsWith('=')){
                row += arrDataVal + ',';
                continue;
            }
            row += '"' + arrDataVal + '",';
        }
        row.slice(0, row.length - 1);
        //add a line break after each row
        CSV += row + '\r\n';
    } 
    
    }
    if (CSV == '') {        
        alert("Invalid data");
        return;
    }
    
   //this trick will generate a temp "a" tag
    var link = document.createElement("a");    
    link.id="lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);

    var csv = CSV;  
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
    
    //Generate a file name
    var fileName =outputFileName;   
    //this will remove the blank-spaces from the title and replace it with an underscore
   // fileName += outputFileName.replace(/ /g," ");
 /*
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName + ".csv")
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var csvUrl = window.URL.createObjectURL(blob);
                    link.setAttribute("href", csvUrl);
                    link.setAttribute("download", fileName + ".csv");
                    link.style.display = "visibility:hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }           
            }
       */   
      
       var link = document.createElement("a");
           if (link.download !== undefined) { // feature detection
               // Browsers that support HTML5 download attribute
               var csvUrl = window.URL.createObjectURL(blob);
               link.setAttribute("href", csvUrl);
               link.setAttribute("download", fileName + ".csv");
               link.style.display = "visibility:hidden";
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
           }   
}

camel2title(camelCase) {
  return camelCase
   // inject space before the upper case letters
   .replace(/([A-Z])/g, function(match) {
      return " " + match;
   })
   // replace first char with upper case
   .replace(/^./, function(match) {
     return match.toUpperCase();
   });
}

  enable(){
   this.checkboxtrue=true;
  }


  setEdit(i, evt) {
    if(evt.target.checked) {
      this.serialApprovalList[i].edit = true;
    } else {
      this.serialApprovalList[i].edit = false;
    }
    
  }

  navigateBack(){
    //this.router.navigateByUrl('submitmeterread');
    this.router.navigate(['/','reviewapprovedserials']);
  }

  onApproval(){
    //console.log("In the On Approval");
    var count = 0;
    var myJson = {}; 
    this.approvedstatussuccess=false;
	this.rejectstatussuccess=false;
	this.approvedstatusfailure=false;
	this.rejectstatusfailure=false;

	this.blockUI.start();
    this.serialApprovalList.forEach((item) => {
      if (item.chk ) {
        var objName = "userName";
        console.log("item.userName == " + item.userName);
        var objValue = item.userName;
        myJson[objName] = objValue;

        var objName = "curOrgNumber";
        console.log("item.curOrgNumber == " + item.curOrgNumber);
        var objValue = item.curOrgNumber;
        myJson[objName] = objValue;

        var objName = "reqOrgMumber";
        console.log("item.reqOrgMumber == " + item.reqOrgMumber);
        var objValue = item.reqOrgMumber;
        myJson[objName] = objValue;

        let data = this.pendingSerialApprovalService.approveRequest(myJson).subscribe(
          data=>{
	 		this.blockUI.stop();
            this.statusFlgApproved = data.statusFlg;
            console.log(" this.statusFlgReject  ==" +  this.statusFlgApproved);
            if(this.statusFlgApproved=='S'){
              console.log("INSIDE THE IF CONDITION");
			  this.getPendingSerialApprovalList(1, null);
              this.approvedstatussuccess=true;
              this. statusFlgApproveMsg="Selected Account Request is Successfully linked";
              
            } else {
				this.approvedstatusfailure=true;
			}
          });
          
        //count++;
      }     
      
      console.log("myJson  == " + myJson);

      
      
    });
  }

  onReject(){
	this.approvedstatussuccess=false;
	this.rejectstatussuccess=false;
	this.approvedstatusfailure=false;
	this.rejectstatusfailure=false;
	
    console.log("In the On Approval");
    var count = 0;
    var myJson = {}; 

	this.blockUI.start();
    this.serialApprovalList.forEach((item) => {
      if (item.chk ) {
        var objName = "userName";
        console.log("item.userName == " + item.userName);
        var objValue = item.userName;
        myJson[objName] = objValue;

        var objName = "curOrgNumber";
        console.log("item.curOrgNumber == " + item.curOrgNumber);
        var objValue = item.curOrgNumber;
        myJson[objName] = objValue;

        var objName = "reqOrgMumber";
        console.log("item.reqOrgMumber == " + item.reqOrgMumber);
        var objValue = item.reqOrgMumber;
        myJson[objName] = objValue;

        let data = this.pendingSerialApprovalService.rejectRequest(myJson).subscribe(
          data=>{
			this.blockUI.stop();
            this.statusFlgReject = data.statusFlg;
            console.log(" this.statusFlgReject  ==" +  this.statusFlgReject);
            if(this.statusFlgReject=='S'){
              console.log("INSIDE THE IF CONDITION 22222222222222 " + this.statusFlgReject);
			  this.getPendingSerialApprovalList(1, null);
              this.rejectstatussuccess=true;
              this. statusFlgRejectMsg="Selected Account Request has been Rejected.";
            } else {
				this.rejectstatusfailure=true;
			}
          });
    
        //count++;
      }       
    });
  }

}
