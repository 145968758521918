import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
 

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class MyDevicesService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
  getMyDevicesList=function(oParams)
  {
      let url = this.defaultUrl + "mydevices/gmdl";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getMyDevicesList"))));
    }

   getModelList=function()
    {
      let url = this.defaultUrl + "mydevices/mdlList";
      let params={};
      return this.http
        .post(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("getModelList"))));
    }

    checkMenuAccess=function()
    {
      let url = this.defaultUrl + "mydevices/cma";
      let params={};
      return this.http
        .post(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("checkMenuAccess"))));
    }


    updateDevices=function(objParams:any)
    {
      let url = this.defaultUrl + "mydevices/updateDevices";
    
      let params = new URLSearchParams();
      for (let key in objParams) {
        params.set(key, objParams[key])
      }
      return this.http
        .post(url, params.toString(), httpOptions)
        .pipe(catchError((this.util.handleError("updateNotes"))));
    }

  isCSPurchaseable=function(oParams)
  {
      let url = this.defaultUrl + "ordersupplies/isCSPurchaseable";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("isCSPurchaseable"))));
    }
}
