import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { EncrDecr } from './encrdecr.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
declare var $:any;



@Injectable({
  providedIn: 'root'
})
export class TceppsCreditCardSevice {
  defaultUrl:string="";
   tceppsInvocationError:boolean = false;
   _ccDeferred:any;
   _ccModelName="#divEnterCC";
  top: any;
  tceppsProxyIFrame: any;
  creditCardDet = {
    width:400,
    height:250,
    show_tcepps_error:true
  };
  

  constructor(private http: HttpClient, private util : Util, private encrDecr: EncrDecr,private dialog: MatDialog) {
    this.defaultUrl = this.util.getBaseUrl();
   }




  getCreditCardForm(tcepps) {
    this.addTceppsActionApp(tcepps);
  
    var form = 
      this.generateTceppsProxyForm(
        tcepps.action
      ,	tcepps.target
      ,	tcepps.method
      )
    ;
    this.appendHiddenInputs(form, tcepps);
    
    this.submitTceppsProxyForm(form, false);	
  }

 
  invokeTceppsProxy(
    tceppsProxy
  ) {
    
    this.addTceppsActionApp(tceppsProxy);
    
    var form = 
      this.generateTceppsProxyForm(
        tceppsProxy.action
      ,	"tceppsProxyIFrame"
      ,	"post"
      )
    ;
  
    this.appendHiddenInputs(form, tceppsProxy);
    
    this.submitTceppsProxyForm(form, false);
  }


  addTceppsActionApp(tcepps) {
    if (tcepps.app) {
      this.addTceppsActionParameter(tcepps, "tceppsApp", tcepps.app.value);
    }
  }
  
  addTceppsActionParameter(tcepps, parameterName, parameterValue) {
    if (tcepps.action.indexOf(parameterName + "=") < 0) {
      if (tcepps.action.indexOf("?") < 0) {
        tcepps.action += "?";
      } else {
        tcepps.action += "&";
      }
      tcepps.action += (parameterName + "=" + parameterValue);
    }	
  }
 
  
   submitCreditCardForm(queryString) {	
    this.tceppsInvocationError = false;
    if (queryString.length > 0) {
        var parameters = this.parseQueryString(queryString);
        if (this.top.creditCardFormIFrame.invokeTceppsServiceOperation) {
          this.top.creditCardFormIFrame.invokeTceppsServiceOperation(parameters);
        }
    }
  }
  parseQueryString(queryString: any) {
    throw new Error('Method not implemented.');
  }

    invokeTceppsResponseError(tcepps, errorMessage) {
    var form = 
      this.generateTceppsProxyForm(
        tcepps.proxyUrl.value + "?status=ERROR&errorMessage=" + errorMessage
      ,	"tceppsProxyIFrame"
      ,	"post"
      )
    ;					
    this.submitTceppsProxyForm(form, false);	
  }
  
  
    getTceppsProxyForm(
    tcepps
  ) {
    
    this.addTceppsActionApp(tcepps);
    
    var form = this.generateTceppsProxyForm(
      tcepps.action
    , 	"tceppsProxyIFrame"
    ,	"post"
    );
  
    this.appendHiddenInput(form, "tcepps.app"					, tcepps.app.value);
    this.appendHiddenInput(form, "tcepps.token"					, tcepps.token.value);
    this.appendHiddenInput(form, "tcepps.sign"					, tcepps.sign.value);
    this.appendHiddenInput(form, "tcepps.serviceOperation"		, tcepps.serviceOperation.value);
    
    return form;
  }
  
    generateTceppsProxyForm(
    action
  ,	target
  ,	method
  ){
    this.generateTceppsProxyIFrame();
  
    var form = document.createElement("form");
    form.action = action;
    form.target = target;
    form.method = method;
    
    return form;
  }
  
    generateTceppsProxyIFrame() {
    if(document.getElementById("tceppsProxyIFrame")) return;
    
    var tceppsProxyIFrame = document.createElement("iframe");
    document.body.appendChild(tceppsProxyIFrame);
    tceppsProxyIFrame.id 					= "tceppsProxyIFrame";
    tceppsProxyIFrame.name 					= "tceppsProxyIFrame";
    this.tceppsProxyIFrame.width					= 10;
    this.tceppsProxyIFrame.height				= 10;	
    tceppsProxyIFrame.contentWindow.name 	= "tceppsProxyIFrame";
    tceppsProxyIFrame.style.display 		= "none";
    this.addOnLoadEventListener(tceppsProxyIFrame);
    return tceppsProxyIFrame;
  }
  
    addOnLoadEventListener(tceppsProxyIFrame) {
    if (tceppsProxyIFrame.addEventListener) {
      tceppsProxyIFrame.addEventListener("load", this.tceppsProxyIFrameOnLoad(), true);
    } else {
      tceppsProxyIFrame.attachEvent("onload", this.tceppsProxyIFrameOnLoad);
    }	
  }
  
    tceppsProxyIFrameOnLoad() {
    //document.body.removeChild(document.getElementById("tceppsProxyIFrame"));
    setTimeout(this.checkTceppsInvocationError, 500);
  }
  
    checkTceppsInvocationError() {
    if (this.tceppsInvocationError) {
      if (this.tceppsResponseError) {
        this.tceppsResponseError("Failed to invoke TCEPPS Service.");
      } else {
        alert("Failed to invoke TCEPPS Service.");
      }
    }
  }
  
    appendHiddenInputs(targetForm, sourceForm) {
    for (var i = 0; i < sourceForm.elements.length; i++) {
      var sourceFormElement = sourceForm.elements[i];
      this.appendHiddenInput(targetForm, sourceForm.name + "." + sourceFormElement.name, sourceFormElement.value);
    }
  }
  
    appendHiddenInput(form, name, value) {
    var hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = name;
    hiddenInput.value = value;
    form.appendChild(hiddenInput);	
  }
  
  
    submitTceppsProxyForm(tceppsProxyForm, checkInvocationError) {
    this.tceppsInvocationError = checkInvocationError;
    document.body.appendChild(tceppsProxyForm);	
    tceppsProxyForm.submit();
    document.body.removeChild(tceppsProxyForm);
  }
  
    getParentUrl() {
    var url = window.location.href;
    return url.substr(0, url.lastIndexOf("/") + 1);			
  }
  
    tceppsResponseSuccess(parameters) {
    var operation=parameters["tcepps.serviceOperation"];
    if (operation != "cancel") {
        if (parameters["appToken"]) {
          this.creditCardDet = parameters["appToken"];
        }
      
        if (parameters["tceppsEncryptedResponse"]) {
          this.creditCardDet = parameters["tceppsEncryptedResponse"];
        }
        if (parameters["tceppsEncryptedResponseKey"]) {
          this.creditCardDet = parameters["tceppsEncryptedResponseKey"];
        }
        if(operation=="profileAdd"){
          //{ppa.procStatusMessage=Profile Request Processed, ppa.cardBrand=VI, ppa.ccAccountNum=1111, ppa.ccExp=201612, ppa.procStatus=0, ppa.customerRefNum=77891956}
          this.creditCard(parameters,$.extend({}, {"action":"close"}, parameters));
        }else if( operation=="newOrder"){
          // {pno.respDateTime=20160921152243, pno.customerRefNum=77893072, pno.respCode=00, pno.txRefNum=57E2DE0234DD2145C9EEDD04CBA0B94F0A25541E, pno.cardBrand=VI, pno.procStatus=0, pno.procStatusMessage=Approved, pno.profileProcStatus=0, pno.avsRespCode=3 , pno.approvalStatus=1}
          $(this._ccModelName)[0].innerHTML="";
          if((parameters["procStatus"]!=="0" || parameters["approvalStatus"]!=="1" ) && parameters["txRefNum"]){
            delete parameters["txRefNum"];
          }
          delete parameters["x"];
          this.creditCard(parameters,$.extend({}, {"action":"received"}, parameters));
        }
      }
  }
  
    tceppsResponseError(parameters) {
    var operation=parameters["tcepps.serviceOperation"];
    if(operation=="profileAdd"){
      this.creditCard(parameters,$.extend({}, {"action":"profie_add_error"}, parameters));
    }else if( operation=="newOrder"){
      $(this._ccModelName)[0].innerHTML="";
      delete parameters["x"];
      this.creditCard(parameters,$.extend({}, {"action":"new_order_error"}, parameters));
    }else{
      alert("Invalid operation "+operation);
      this._ccDeferred.reject(parameters);
    }
    
  }
  
    cleanUpTceppsProxyIFrame(){
    var iframe=document.getElementById("tceppsProxyIFrame");
    while(iframe){
      document.body.removeChild(iframe);
      iframe=document.getElementById("tceppsProxyIFrame");
    }
  }
  
    openCCDialog(title,url,height,width,top, options)
  {

    $(this._ccModelName).dialog({
      cache: false,
      height: 240,
      title: title, 
      top: top,
      modal: true ,
      zIndex:1005,
        resizable: false,
      autoOpen: false,
      width: width,
      closeOnEscape: true
      ,buttons: {
      "Submit": function() {
        var tceppsProxy	= document.forms["tceppsProxy"];
          this.invokeTceppsProxy(tceppsProxy);
      },
      "Close": function() {

        $(this).dialog("close");
        $(this).dialog("destroy");
        $(this._ccModelName).html("");
        $(this._ccModelName).trigger("closed");
          this.cleanUpTceppsProxyIFrame();
      }}
    });
    
    delete options["title"];
    delete options["height"];
    delete options["width"];


     this.http.get(url, { responseType: 'text' }).subscribe(
      data=>{
        $(this._ccModelName).html("");
        $(this._ccModelName).html(data);

  	    setTimeout(()=>{
        console.log("Pinrinting inside setTimeout function.");
        var tcepps= document.forms["tcepps"];
        console.log("Printing tcepps value: ", tcepps);
        this.getCreditCardForm(tcepps);
        this.cleanUpTceppsProxyIFrame();
  
        }, 3000);
      }
    );

      $( this._ccModelName ).dialog("open")
        .dialog("widget")
        .find(".ui-dialog-buttonset .ui-button-text").addClass("btn").end()
        .find(".ui-dialog-title").css({"float":"none"}).end()
        .find(".ui-dialog-content").css({"overflow":"hidden","border":"none"});
    $(".ui-dialog-titlebar").css({"background-color":"#000","color":"#FFF"});
    $("div.ui-dialog-titlebar button").remove();
    
    $(".ui-dialog button").attr("class","btn btn-primary btn-sm");
    //$(".ui-dialog button").addClass("btn").addClass("").addClass("");
    
    $(".ui-dialog").css({"top":top,"float":"none"});
  
  }
  
    postCCAuth(url,options)
  {
     this.http.get(url, { responseType: 'text' }).subscribe(
      data=>{
        $(this._ccModelName).html(data).hide();
          
          setTimeout(()=>{
          console.log("Printing inside 2nd setTimeout function..");
          var tcepps= document.forms["tcepps"];
          console.log("Printing tcepps value: "+ tcepps);
          this.getCreditCardForm(tcepps);
          this.cleanUpTceppsProxyIFrame();
          var tcepps		= document.forms["tcepps"];
          var tceppsProxy	= document.forms["tceppsProxy"];
          
          tcepps.serviceOperation.value = 'newOrder';
          tceppsProxy.serviceOperation.value = 'newOrder';
          this.invokeTceppsProxy(tceppsProxy);
  
          }, 3000);

          
      }
    );


}

creditCard(url,options) {
	// If url is an object, simulate pre-1.5 signature
	if ( typeof url === "object" ) {
		options = url;
		url = undefined;
	}

	// Force options to be an object
	options = options || {};
	
	if(options.paymentType == "B")
	{
		options= $.extend( {}, this.creditCardDet, options, {title:"Enter Bank Account"} );
	}
	else
	{
		options= $.extend( {}, this.creditCardDet, options, {title:"Enter Credit Card"} );
	}
	//alert(JSON.stringify(options));
	 
	if(options.action=="open"){
		this._ccDeferred=jQuery.Deferred();
		
		$.extend(options,{"secure":window.location.protocol == "https:"});
		this.openCCDialog(options.title,url,options.height,options.width, options.top, options);
		return this._ccDeferred.promise();
		
	}else if(options.action=="close"){
		$(this._ccModelName).dialog("close");
		$(this._ccModelName).dialog("destroy");
		$(this._ccModelName).html("");
		$(this._ccModelName).trigger("closed");
		this.cleanUpTceppsProxyIFrame();
		
		this._ccDeferred.resolve(options);
		
	}else if(options.action=="auth"){
		this._ccDeferred=jQuery.Deferred();
		if(options.profileId){
			$.extend(options,{"secure":window.location.protocol == "https:"});
			this.postCCAuth(url, options);
		}else{
			this._ccDeferred.resolve({"error":"missing profileId"});
		}
		return this._ccDeferred.promise();
	}else if(options.action=="received"){
		this._ccDeferred.resolve(options);
	}else if(options.action=="profie_add_error"){
		$(this._ccModelName).dialog("close");
		$(this._ccModelName).dialog("destroy");
		$(this._ccModelName).html("");
		$(this._ccModelName).trigger("closed");
		this.cleanUpTceppsProxyIFrame();
		
		if(options.show_tcepps_error){
			var msg= "TCEPPS communication error occurred";
			msg=options.errorMessage? msg+ ": "+options.errorMessage: msg;
			alert(msg);
		}
		this._ccDeferred.reject(options);
	}else if(options.action=="new_order_error"){
		this.cleanUpTceppsProxyIFrame();
		
		if(options.show_tcepps_error){
			var msg= "TCEPPS communication error occurred";
			msg=options.errorMessage? msg+": "+options.errorMessage: msg;
			alert(msg);
		}
		this._ccDeferred.reject(options);
	}
	
}




}