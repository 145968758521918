import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from 'src/app/app.util';
import { UserserviceService } from 'src/app/service/common-service/userservice.service';

@Component({
  selector: 'app-user-terms-condition',
  templateUrl: './user-terms-condition.component.html',
  styleUrls: ['./user-terms-condition.component.css']
})
export class UserTermsConditionComponent implements OnInit {
  @ViewChild('iframeRef') iframe: ElementRef;
  user: string;
  defaultUrl: string;
  AcceptedTnC: any;
  twoFactorFlag: string;

  constructor(private route: ActivatedRoute,private userService:UserserviceService,private util: Util, private http: HttpClient,private router:Router) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  message1: string = "Terms & Conditions - Site Use Agreement";
  message2: string = "Please read the following MyCanon Business Service Agreement (\"Agreement\") before proceeding. If you agree with the terms of the Agreement, select \"I accept the agreement\" and then click \"Submit\". If you do not agree with the terms of the Agreement select \"I do not accept the agreement\" and then click \"Submit\". Access to MyCanon Business will only be granted if this Agreement is accepted.";
  userName: string | null = null;
  tc_id: string | null = null;

  ngOnInit(): void {
    this.userName = this.route.snapshot.paramMap.get('userName');
    this.tc_id = sessionStorage.getItem('tcId');
    this.AcceptedTnC = 'accepted';
  }
  ngAfterViewInit() {
    this.user =sessionStorage.getItem('fullName');
    var inputParams = { user:this.user};
    let url = this.defaultUrl + "getTermsHtml";
  
      return this.http.post(url, null, { responseType: 'text' }).subscribe(
        (res) => {
          const iframeDocument = this.getIframeDocument();
          iframeDocument.write(res);
        },
        (error) => {
          console.error('Error loading content:', error);
        }
      );
  }
  private getIframeDocument(): Document {
    return this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document;
  }
  noBack() {
    window.history.forward();
  }

  // processTermsAndCodn() {
  //   const form = (document as any).TermsAndCondsForm; // Adjust the type if necessary
  //   console.log("Form:"+form);
  //  //form.action = 'login.htm?mthd=ptac';
  //  // form.submit();
  // }
  processTermsAndCodn(){
	console.log(this.AcceptedTnC);
	if(this.AcceptedTnC == 'rejected') {
		this.router.navigate(['login']);
		return;
	}
	
	
    this.user =sessionStorage.getItem('userName');
    this.twoFactorFlag =sessionStorage.getItem('oTwoFacAuthFlg');
  var inputParams = { userName: this.user, tc_id : this.tc_id,tncAcceptanceFlag:this.AcceptedTnC};
  this.userService.processTermsAndCodn(inputParams).subscribe
  (data =>{
   console.log(data);
   if (data.pwdChangeFlag == "Y") { 
   this.router.navigate(['resetpassword']);
   }
   else if(this.twoFactorFlag == "N")
   {
    this.router.navigate(['twoFactor']);
   }
   else
   {
    this.router.navigate(['home']);
   }
   //console.log("Forgot Password component data:"+data);    
 
},
 error=>{
   console.log(error);
  
 }
  );

}
}