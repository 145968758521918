import { Component, Input, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { DefineLevelsService } from 'src/app/admin/configurator/define-levels/define-levels.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-define-levels',
  templateUrl: './define-levels.component.html',
  styleUrls: ['./define-levels.component.css']
})
export class DefineLevelsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;

  levelList_org = [];
  levelList = [];
  

  constructor(private commonService:CommonServiceService, private levelService : DefineLevelsService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title, private activatedRoute: ActivatedRoute) { }

  partyId;
  templateId;  
  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
	/*
	this.activatedRoute.queryParams.subscribe(params => {
		this.partyId = params['partyId']; 
  		this.templateId = params['templateId'];
		this.getUserLevels();
	});		
	*/
	
	this.partyId = this.param.partyId;
    this.templateId = this.param.templateId;
	this.getUserLevels();
 
  }

  async getUserLevels() {
	
		var param = {
			"partyId" 	 : this.partyId,
			"templateId" : this.templateId 
		};
			
		//this.levelService.getUserLevels(param).subscribe(resp => {
		var resp = await this.levelService.getUserLevelsSync(param);	
			if (resp != null) {
				console.log('getUserLevels():', resp);
				//this.levelList_org = resp.data;
				this.levelList_org = [];
				resp.data.forEach(val => this.levelList_org.push(Object.assign({}, val)));
				this.levelList = resp.data;
			}
		//});
	
  }

  onAddLevel() {
	
	$(".editBtn").attr("disabled", "disabled");	
	$("#addBtn").attr('disabled', 'disabled');
	
	// parent id is the Id of prev entry if none present then -1
	var parentID = -1;
	if(this.levelList.length > 0) {
		parentID = this.levelList[this.levelList.length - 1].id;
	}
	
	var newLevel = {
		"id" : "",
		"parentId" : parentID,
		"status" : "A",
		"templateId" : this.templateId + "",
		"partyId" : this.partyId + "",
		"name" : "",
		"isNewLevel" : true
	}
	
	this.levelList.push(newLevel);
	
	
  }

  onCancel() {
	//this.levelList = this.levelList_org;
	this.levelList = [];
	this.levelList_org.forEach(val => this.levelList.push(Object.assign({}, val)));;
	
	$(".editBtn").removeAttr('disabled');
	$("#addBtn").removeAttr('disabled');
	$(".leveltxt").attr('disabled', 'disabled');
	$(".editBtn").show();
	$(".saveBtn").css('display', 'none');
  }

  onEdit(idx) {
	$("#addBtn").attr('disabled', 'disabled');
	$(".editBtn").attr("disabled", "disabled");
	$("#editBtn"+idx).hide()
	$("#level"+idx).removeAttr('disabled');
	$("#chkLvl"+idx).removeAttr('disabled');
	
	$("#saveBtn"+idx).css('display', 'flex');
	
  }
  
  onSave(idx) {
	
	var param = {
		"id" : this. levelList[idx].id,
		"parentId" : this. levelList[idx].parentId,
		"status" :  $("#chkLvl"+idx).prop('checked')? 'A' : 'I',   // this. levelList[idx].status,
		"templateId" : this.templateId + "",
		"partyId" : this.partyId + "",
		"name" : this. levelList[idx].name
	}
	
	console.log(param);
	this.blockUI.start();
	this.levelService.saveUserLevel(param).subscribe(resp => {
			if (resp != null) {
				console.log('saveUserLevel():', resp);
				if (resp.status == 'S') {
					this.getUserLevels();
					$("#addBtn").removeAttr('disabled');
					this.blockUI.stop();
				}
				
			}
		});
	
	
  }
	

}
