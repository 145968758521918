import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { UserroleAssignmentService } from './userrole-assignment.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-userrole-assignment',
  templateUrl: './userrole-assignment.component.html',
  styleUrls: ['./userrole-assignment.component.css']
})
export class UserroleAssignmentComponent implements OnInit {
  @ViewChild('gridRolesModal') gridRolesModalTemplate:TemplateRef<any>;
  gridRolesModal: BsModalRef;

  @BlockUI() blockUI : NgBlockUI;
  userName:string = '';
  appCodes:any = [];
  entities:any = [];
  roles:any = [];
  count:number = 0;
  firstName:string = "";
  lastName:string = "";
  roleList:any = [];
  msg:string="";
  constructor(private userService:UserroleAssignmentService, private util : Util,
              private modalService: BsModalService, private route: ActivatedRoute) { 

      this.route.queryParams.subscribe(params => {
        this.userName = params['userName'];
        this.firstName = params['firstName'];
        this.lastName = params['lastName'];      
      });
  }

  ngOnInit() {
    this.search();
  }

  checkedItem(i, evt) {
    if(evt.target.checked) {
      this.entities[i].accFlg  = 'Y';
      $('#select'+i).prop('disabled', false);
    } else {
      this.entities[i].accFlg = 'N';
      $('#select'+i).prop('disabled', 'disabled');
      $('#select'+i).prop('value', "0" );
    }
  }

  openGridRoles() {
    this.gridRolesModal = this.modalService.show(this.gridRolesModalTemplate);
    this.blockUI.start();
    let params = {
      "userName": this.userName
    };

    let data = this.userService.getUserGridRoles(params).subscribe(
      data=>{
        this.roleList = data.data;
        console.log(this.roleList);
        this.blockUI.stop();
      });
  }

  search() {
    this.blockUI.start();
    let params = {
      "userName": this.userName
    };
    
    let data = this.userService.getUserAppEntityRoleList(params).subscribe(
      data=>{
        this.appCodes = data.appcodes;
        this.entities = data.entities;
        this.roles = data.roles;
        this.blockUI.stop();
      });
  }

  cancelModal() {
    this.gridRolesModal.hide();
  }


  filterList(i:number, itemList: any[]): any[] {
    if (this.count >= this.entities.length) {
      this.count = 0;
    }

    if (itemList != null && itemList != undefined) {
      let result: any[] = itemList.filter(
        item => item.entity === this.entities[this.count].entityCode);
      this.count++;
      return result;
    }
    
  }

  saveGridRoles(uaFlag) {
    this.blockUI.start();
    let roleId:string = this.roleList[0].roleId;

    let params = {
      "userName": this.userName,
      "roleIds" : roleId,
      "uFlag" : uaFlag
    };

    let data = this.userService.saveUserGridRoles(params).subscribe(
      data=>{
        console.log(data);
        this.cancelModal();
        this.blockUI.stop();
      });
  }

  saveRoles(uaFlag) {
    this.msg="";
    let roleId:string = this.appCodes[0].appId + ':';
    let count = 0;
    this.blockUI.start();

    this.entities.forEach((item) => {
      if (item.accFlg == 'Y') {
        if (count > 0) {
          roleId += '_';
        }
        console.log("Printing values of ", count);
        console.log( $('#select'+count).val());
        console.log($('#list'+count).val());
        let selectCountVal = $('#select'+count).val();
        if(selectCountVal==undefined || selectCountVal=='undefined'){
          selectCountVal='';
        }
        let listCountVal = $('#list'+count).val();
        if(listCountVal==undefined || listCountVal=='undefined'){
          listCountVal='';
        }

        roleId += selectCountVal + '~' + listCountVal;
      }
      count++;
    })

    let params = {
      "userName": this.userName,
      "userFullName": this.lastName + ',' + this.firstName,
      "roles" : roleId,
      "uaFlag" : uaFlag
    };
    
    let data = this.userService.saveUserRoleAssignments(params).subscribe(
      data=>{
        console.log(data);
        if(data){
          let flg="";
          flg=data["errFlg"];
          if(flg=="S"){
               this.msg="All changes saved.";
          }else{
            this.msg="Error occured while saving.";
          }
        }
        this.blockUI.stop();
      });
    }

}
