import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class UserroleAssignmentService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getUserAppEntityRoleList = function(oParams){
    let url = this.defaultUrl + "adminUser/getUserAppEntityRoleList";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getUserAppEntityRoleList"))));
  }

  saveUserRoleAssignments = function(oParams){
    let url = this.defaultUrl + "adminUser/saveUserRoleAssignments";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("saveUserRoleAssignments"))));
  }

  getUserGridRoles = function(oParams){
    let url = this.defaultUrl + "adminUser/getUserGridRoles";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getUserGridRoles"))));
  }

  saveUserGridRoles = function(oParams){
    let url = this.defaultUrl + "adminUser/saveUserGridRoles";

    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("saveUserGridRoles"))));
  }

}
