import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class HomepageService {
  defaultUrl: string;

  constructor(private http: HttpClient, private util: Util) {
      this.defaultUrl = this.util.getBaseUrl();
  }

  getHomePageDetails = function () {
    let url = this.defaultUrl + "common/service/homePageDetails";
    let params = {};
    return this.http
        .post(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("homepage"))));
}
  
}
