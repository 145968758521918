import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AutopayService } from '../autopay/autopay.service';
import { Subscription } from 'rxjs';
import { NewPaymentComponent } from '../new-payment/new-payment.component';
import { SavedPaymentComponent } from '../saved-payment/saved-payment.component';
import { AutopayComponent } from '../autopay/autopay.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { NewPaymentService } from '../new-payment/new-payment.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  @Input() item = 'new'; 
  @ViewChild(NewPaymentComponent) newPaymentCmp:NewPaymentComponent;
  @ViewChild(SavedPaymentComponent) savePaymentCmp:SavedPaymentComponent;
  @ViewChild(AutopayComponent) autopayCmp:AutopayComponent;

  constructor(private autopayService:AutopayService, private titleService: Title, 
              private commonService : CommonServiceService, private newPaymentService : NewPaymentService) { }
  private subscription: Subscription;
  interval;

  ngOnInit() {

    this.commonService.setCommonFunctions(); 

    this.titleService.setTitle("Payment Method");

    if (this.item == 'new') {
      this.newPayment();
    } else if (this.item == 'save'){
      this.savedPayment();
    } else if (this.item == 'auto'){ 
      this.autopay();
    }

    this.newPaymentService.newPaymentAddedNotifyObservable$.subscribe(
      data => {
        this.savedPayment();
      }
    )
    
    this.subscription = this.autopayService.cardObservable$.subscribe(() => {
      this.item = 'new';
      this.newPayment();
    });

    this.subscription = this.autopayService.bankObservable$.subscribe(() => {
      this.item = 'new';
      this.newPayment();
    });

    this.subscription = this.autopayService.autopayObservable$.subscribe(() => {
      this.item = 'auto';
      this.autopay();
    });

  }

  newPayment() {
    this.newPaymentCmp.loadData();

    $( "#newpayment" ).addClass( "active" );
    $( "#newpayment" ).addClass( "show" );
    $( "#newpayHeading" ).addClass( "active" );

    $( "#savedpayment" ).removeClass( "active" );
    $( "#savedpayment" ).removeClass( "show" );
    $( "#savedpayHeading" ).removeClass( "active" );

    $( "#autopay" ).removeClass( "active" );
    $( "#autopay" ).removeClass( "show" );
    $( "#autopayHeading" ).removeClass( "active" );
  }

  savedPayment() {
    this.item = 'save'
    this.savePaymentCmp.ngOnInit();
    ;
    $( "#savedpayment" ).addClass( "active" );
    $( "#savedpayment" ).addClass( "show" );
    $( "#savedpayHeading" ).addClass( "active" );

    $( "#newpayment" ).removeClass( "active" );
    $( "#newpayment" ).removeClass( "show" );
    $( "#newpayHeading" ).removeClass( "active" );

    $( "#autopay" ).removeClass( "active" );
    $( "#autopay" ).removeClass( "show" );
    $( "#autopayHeading" ).removeClass( "active" );
  }

  autopay() {
    this.item = 'auto';
    this.autopayCmp.onLoad();

    $( "#autopay" ).addClass( "active" );
    $( "#autopay" ).addClass( "show" );
    $( "#autopayHeading" ).addClass( "active" );

    $( "#savedpayment" ).removeClass( "active" );
    $( "#savedpayment" ).removeClass( "show" );
    $( "#savedpayHeading" ).removeClass( "active" );

    $( "#newpayment" ).removeClass( "active" );
    $( "#newpayment" ).removeClass( "show" );
    $( "#newpayHeading" ).removeClass( "active" );
  }

/*   @HostListener('window:message', ['$event'])
  onWindowBlur(event: any): void {
    if(event.data == "Rejected"){		
      this.blockUI.stop();
      console.log('Rejected');
    }else
    if(event.data == "Accepted"){
      console.log('Accepted');
      setTimeout(() => {  
          this.savedPayment();
       }, 5000);
      
    }else 
    if(event.data == "AcceptedSaveACH") {
      console.log('AcceptedSaveACH');
       setTimeout(() => {  
        this.savedPayment();
     }, 5000); 
    } 
  }   */

  
  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
