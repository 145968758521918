import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class CreateCatalogService {

	defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getConfiguration=function(oParams)
  {
    let url = this.defaultUrl + "productCatalog/getConfiguration";
  
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr = $.param(oParams);  
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("productCatalog/getConfiguration"))));
  }

  getCustomers=function(oParams)
  {
    let url = this.defaultUrl + "productCatalog/getCustomers";
  
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr = $.param(oParams);  
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("productCatalog/getCustomers"))));
  }

  createCatalog(oParams) : Observable<any>{
    let url = this.defaultUrl + "productCatalog/createCatalog";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("createCatalog"))));

  }

  getImages=function()
  {
    let url = this.defaultUrl + "productCatalog/getItems";
        
      return this.http
        .post(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("productCatalog/getItems"))));
  }

  getImg(oParams) : Observable<any>{
    let url = this.defaultUrl + "productCatalog/getImg";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr = $.param(oParams);  
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getImg"))));

  }

  uploadFile(formData: FormData): Observable<any>{
    let url = this.defaultUrl + "productCatalog/uploadFile";

    return this.http.post<any>(url,formData,{});
  }

}
