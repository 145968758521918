import { Component, OnInit } from '@angular/core';
import { GridRoleService } from './grid-role.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-grid-role',
  templateUrl: './grid-role.component.html',
  styleUrls: ['./grid-role.component.css']
})
export class GridRoleComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  gridCd:any="";
  roleNm:any="";
  gridRoleTblId:any="#gridRoleTbl";
  gridRoleTblSet:boolean=false;
  reload:boolean=false;
   dt:any;
   flag:any = "N";
  recordsTotal: any;
  recordsFiltered: any;
  gridRoleList: any=[];
  allGridRole:any=[];
  allRoles:any=[];
  gridCode:any ="METER_READ";
  roleName:any = "DASHBOARD_ROLE";
  allNewRowRecords:any=[];

  constructor(private gridRoleService:GridRoleService, private commonService:CommonServiceService) { }

  ngOnInit() {
    this.gridRoleSearch();
    this.getAllGridRole();

  }

  addNewRowRecords(){
    var newRecord = {
      "gridCode"  : this.allGridRole[0].gridCode,
      "roleName" : this.allRoles[0].roleName
    };
    this.allNewRowRecords.push(newRecord);

  }

  gridRoleSearch(){
    this.blockUI.start();
    var param = {
      "gridCd"  : this.gridCd,
      "roleNm" : this.roleNm
    };
    this.gridRoleService.getGridRoleList(param).subscribe(
      data=>{
        console.log(data);
        this.recordsTotal = data.recordsTotal;
        this.recordsFiltered = data.recordsFiltered;
        this.gridRoleList = data.data;
        this.blockUI.stop();

      }
    );
  }

  clearSearch(){
    this.gridCd="";
    this.roleNm ="";
    this.gridRoleSearch();
  
  }

  clearSelectedSearch(i){
console.log("Removing selected element index: ", i);
this.allNewRowRecords.splice(i,1);


  }

  getAllGridRole(){
     this.blockUI.start();
    this.gridRoleService.getAllGridRole().subscribe(
      data=>{
        console.log('Printing data from getAllGridRole',data);
        if(data!='undefined' || data!=''){
        this.allGridRole = data.grids;
        this.allRoles = data.roles;
      }
        this.blockUI.stop();

       }
    );

  }

  deleteSeletedGridRole(gridrole){
    console.log('Printing data from deleteSeletedGridRole',gridrole);
    var deletePayload = {
      "gridCd"  : gridrole.gridCode,
      "roleNm" : gridrole.roleName
    };
    this.gridRoleService.removeGridAndRole(deletePayload).subscribe(
      data=>{
        console.log(data);
        if(data.flag=="S"){
          this.gridRoleSearch();
        }
      }
    );

  }

  saveNewRowRecords(){
    console.log("Inside saveNewRowRecords method", this.allNewRowRecords);
    if(this.allNewRowRecords.length==0){
      return;

    }

    var SavePayload = {
      "gridRoles"  : this.allNewRowRecords
    };

this.gridRoleService.saveGridAndRole(SavePayload).subscribe(
  data=>{
    if(data.flag=='S'){
      this.gridRoleSearch();
      this.allNewRowRecords=[];
    }
  }
);
    
  }


}
