import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ActivatedRoute } from '@angular/router';
import { OrderInquiryService } from './order-inquiry.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 

@Component({
  selector: 'app-order-inquiry',
  templateUrl: './order-inquiry.component.html',
  styleUrls: ['./order-inquiry.component.css']
})
export class OrderInquiryComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  openSupplyList = [];
  myDeviceList:any=[];
  orderDetails = "";
  dateRanges = "30";
  userList = [];
  start = 1;
  end = 0;
  recPerPage: any = "10";
  pageNum = 1;
  arrPgLinks = [];
  recMsg = "";
  msg = "";
  showMsg = false;
  msgClass = "";	
  
  invoices = [];
  isVisible = [];
  orderNumber: any="";
  serialEqpNumber: any = "";
  model: any = "";  
  poNumber :any = "";
  users: any = "";
  selectedUserAccts:string="";
  userAccountList=[];
  pleaseWaitCls:boolean = false;
  totalRecords: any;
  numOfRecsMsg: string;
  sub: any;
  serialNumber:string="";
  location:string="";
  
  orderHeader:any;
  orderLineList = [];
  isBillableOrder:boolean=false;
  invList = "";
  selectedOrder: any;
  closedOrderService: any;
  duration: string;
  assignedTo: string;
  coverageType: string;
  refNumber: any;
  orderStatus: any;
  openOrder=true;
  closedOrder: any;
  cancelledOrder: any;
  orderSupplyList:any;
  refNum: string;
  showInvoice: any;
  isChecked: boolean = true;
  defaultUrl: string;
  constructor( private commonService:CommonServiceService, private orderInquiryService: OrderInquiryService, 
    private util : Util, private modalService: NgbModal) { 
    this.defaultUrl = this.util.getBaseUrl();
  }



  ngOnInit() {
    this.blockUI.start();
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
        this.blockUI.stop();
      });

      this.commonService.setCommonFunctions();
      this.getOrderInquiryRequests(1);
  
    }

    getOrderInquiryRequests(pgNum: any){

      this.pageNum = pgNum;
      this.showMsg = false;
      this.isVisible = [];
      var currentElement = $("#rpg").val();
      if(currentElement != undefined){
        this.recPerPage = currentElement;
      }
      var paramObj = {};
      paramObj["srch_order_num"] = (this.orderNumber=== undefined) ?"" : this.orderNumber;
      paramObj["srch_serail_num"] = (this.serialEqpNumber=== undefined)?"" : this.serialEqpNumber;
      paramObj["srch_product_num"] = (this.poNumber== undefined)?"":this.poNumber;
      //paramObj["srch_product_num"] = (this.poNumber === undefined) ? "" : this.poNumber;
      paramObj["srch_reference_num"] =  "";
      paramObj["assgined_to"] = "";
      var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
      var endItem = (this.pageNum * parseInt(this.recPerPage));
      paramObj["start"] = startItem-1;
      paramObj["length"] = this.recPerPage;
      
      var dNow = new Date();
       var d = new Date();
      var perivousdays;
      var fromdate;
      var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
      perivousdays = new Date(d.setDate( eval('d.getDate()-' + parseInt(this.dateRanges))) );
    		fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			
	        paramObj["srch_date_num"] = this.dateRanges;
			this.checkVal();
			paramObj["order_status"] = this.orderStatus;
			var arrUserAcc = [];
			//arrUserAcc = getSelecteUserAccounts();
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
			paramObj["party_ids"] = this.selectedUserAccts;
			paramObj["orderCol"] = "";
	    	paramObj["orderDir"] = "asc";
        this.blockUI.start();
			var adPromise = this.orderInquiryService.getOrderInquiryRequestList(paramObj).subscribe(
        data=>{
          this.orderSupplyList = data.orderList;					
					this.totalRecords = data.totalRecords;
					this.showInvoice =  data.invoiceAccess;
					
					if (this.orderSupplyList.length < 1) {
						this.showMsg = true;
						this.msg = "No Records found";
						this.msgClass = "alert-danger";
					}
          this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
					
					this.setPgLinks();
					this.setRecMsg();
					//hidePleaseWait();
          //this.pleaseWaitCls = false;
          this.blockUI.stop();

        }
      );


  //below is end of method....
  }
  checkVal() {
    var status = [];
    if(this.openOrder){
      status.push("'OPEN'");
    }
    if(this.closedOrder){
      status.push("'CLOSED'");
    }
    if(this.cancelledOrder){
      status.push("'CANCELLED'");
    }
    this.orderStatus = status.join();
  }

  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.orderNumber = "";
    this.serialEqpNumber = "";
    this.model = "";
    this.users = "";
    this.poNumber = "";
    this.dateRanges = "30";
    this.openOrder = true;
    this.closedOrder = false;
    this.cancelledOrder = false;
    this.openOrder=true;

    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
    this.getOrderInquiryRequests(1);
  }

  clearAll (){
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
    this.orderNumber = "";
    this.serialEqpNumber = "";
    this.poNumber = "";
    this.dateRanges = "30";
    this.openOrder = true;
    this.closedOrder = false;
    this.cancelledOrder = false;
    this.refNum = "";
    this.getOrderInquiryRequests(1);
    setTimeout(() => {
    //showPleaseWait();
    }, 200);
  }

  getInvoiceNumbersMain (ordNum,source){
    //showPleaseWait();
    $('#orderNumber').val(ordNum);
    $('#source').val(source);
    var adPromise = this.orderInquiryService.getInvoiceNumbers(ordNum);
    adPromise.then(function(result){
      this.invList = result.data.data;
      var htmlData = '';
      for (var i = 0; i < this.invList.length; i++) {
              var invoiceStr = "";
            var invNumber = this.invList[i];
                        if(invNumber!=null){
                        if(source == "S21"){
                      
                      invoiceStr = "<a href='javascript:void(0);' onclick='getInvoicePdfS21("+ invNumber +")' >"+invNumber+"</a>";
            }else {
              invoiceStr = "<a href='javascript:void(0);' onclick='getInvoicePdf("+invNumber+","+source+")'>"+invNumber+"</a>";
                      
                    }
                        htmlData += "<tr><td>" + invoiceStr + "</td></tr>";
          }
          }
          $('#invData').html(htmlData);
          //$('#inv-modal').modal('show');
    }
);
    //hidePleaseWait();
  }

  getOrderDetailsOld(orderId,source,totalAmt){
    //showPleaseWait();
    var paramObj = {};
    paramObj["orderId"] = orderId;
    paramObj["orderBy"] = "PART_NUM";
    paramObj["sortBy"] = "ASC";
    paramObj["action"] = "inquiry";
    paramObj["source"] = source;
    paramObj["totalAmt"] = totalAmt;
    this.blockUI.start();
    var adPromise = this.orderInquiryService.getOrderDetails(paramObj).subscribe(
      data=>{
        this.orderDetails = data;
        console.log("pritning ......");
        console.log(this.orderDetails);
        var idx = this.orderDetails.indexOf("<form");
        var substring = this.orderDetails.substr(idx);
        var substr1 = substring.substr(0,substring.indexOf("<script"));
        this.orderDetails = substr1;					
        $("#orderDetailsData").html(this.orderDetails);
        //$('#order-details-modal').modal('show');
        //$scope.orderDetailsModal($scope.orderDetails);
        //hidePleaseWait();
        this.blockUI.stop();
      }
    );
  }


  getOrderDetails(ordID, src, totPrice) {
    const orderDetailsModalRef = this.modalService.open(OrderDetailsComponent, {size: <any>'xl'});
    orderDetailsModalRef.componentInstance.ordID = ordID;
    orderDetailsModalRef.componentInstance.src = src;
    orderDetailsModalRef.componentInstance.totPrice = totPrice;
    
    }	

    exportToExcel() {
      //this.blockUI.start();

      this.pageNum = 1;
      this.showMsg = false;
      this.isVisible = [];
      var currentElement = $("#rpg").val();
      if(currentElement != undefined){
        this.recPerPage = currentElement;
      }
      var paramObj = {};
      paramObj["srch_order_num"] = (this.orderNumber=== undefined) ?"" : this.orderNumber;
      paramObj["srch_serail_num"] = (this.serialEqpNumber=== undefined)?"" : this.serialEqpNumber;
      paramObj["srch_product_num"] = (this.poNumber== undefined)?"":this.poNumber;
      //paramObj["srch_product_num"] = (this.poNumber === undefined) ? "" : this.poNumber;
      paramObj["srch_reference_num"] =  "";
      paramObj["assgined_to"] = "";
      var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
      var endItem = (this.pageNum * parseInt(this.recPerPage));
      paramObj["start"] = 0;
      paramObj["length"] = this.totalRecords;
      
      var dNow = new Date();
       var d = new Date();
      var perivousdays;
      var fromdate;
      var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
      perivousdays = new Date(d.setDate( eval('d.getDate()-' + parseInt(this.dateRanges))) );
    		fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			
	        paramObj["srch_date_num"] = this.dateRanges;
			this.checkVal();
			paramObj["order_status"] = this.orderStatus;
			var arrUserAcc = [];
			//arrUserAcc = getSelecteUserAccounts();
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
			paramObj["party_ids"] = this.selectedUserAccts;
			paramObj["orderCol"] = "";
	    	paramObj["orderDir"] = "asc";


 		let url = "orderInquiry/inqOrdInvoiceExport?";  ;
		let qryStr = $.param(paramObj);
    	url=url+qryStr;

		this.commonService.exportToExcel(url);
		
			
		/*
	  	var adPromise = this.orderInquiryService.getOrderInquiryListJSON(paramObj).subscribe(
	        data=>{
	          this.commonService.jsonToExcel(data.orderList,"OrderInquiry",true,false,null);	
	          this.blockUI.stop();
	
	        }
      	);
		*/

  }


  exportToExcel1() {	
    //showPleaseWait();			
    this.blockUI.start();
    var paramObj = {};
    paramObj["srch_order_num"] = (this.orderNumber== undefined)?"":this.orderNumber;
    paramObj["srch_serail_num"] = (this.serialEqpNumber== undefined)?"":this.serialEqpNumber;
    //paramObj["srch_product_num"] = "";
    paramObj["srch_product_num"] = (this.poNumber== undefined)?"":this.poNumber;
    //paramObj["srch_reference_num"] =  (this.refNumber== undefined)?"":this.refNumber;
    paramObj["srch_reference_num"] = "";
    paramObj["assgined_to"] = "";
    var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
    var endItem = (this.pageNum * parseInt(this.recPerPage));
    
    var dNow = new Date();
     var d = new Date();
    var perivousdays;
    var fromdate;
    var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    
      perivousdays = new Date(d.setDate( eval('d.getDate()-' + parseInt(this.dateRanges))) );
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;

    paramObj["srch_date_num"] = this.dateRanges;
    this.checkVal();
    paramObj["order_status"] = this.orderStatus;;
    var arrUserAcc = [];
    //arrUserAcc = getSelecteUserAccounts();
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    paramObj["party_ids"] = this.selectedUserAccts;
    paramObj["orderCol"] = "";
      paramObj["orderDir"] = "";
     
      this.orderInquiryService.getOrderInquiryListJSON(paramObj).subscribe(
           data => { 
              let d=    data.data;
              if(d.length<1){
                   d=["No Records Found"];
                 this.commonService.jsonToExcel(d,"OrderInquiry",false,false,null);
               } else{
                 this.commonService.jsonToExcel(d,"OrderInquiry",true,false,null);
               }
              this.blockUI.stop();
           }
      );
    
     
  }

 getInvPdf (invNum:any,source:any){
    //showPleaseWait();
    //let url="/orderInquiry/inqOrdInvoiceDetEmanage?invoice_number="+invNum + "&source="+source;
    console.log("Printing Invoice PDF link:...");
    console.log(url);
    var url= this.defaultUrl + "orderInquiry/inqOrdInvoiceDetEmanage?invoice_number="+invNum + "&source="+source;
    window.open(url);
    //hidePleaseWait();
  }

  getInvPdfS21(invNum:any){
    //showPleaseWait();
    //var url="invoicepdf.htm?mthd=gipdfe&invoice_number="+invNum;
    var url= this.defaultUrl + "orderInquiry/inqOrdInvoiceDetEmanage?invoice_number="+invNum;
    window.open(url);
    //hidePleaseWait();
  }


  toggleDiv (id, event,orderHeaderId){		
    event.preventDefault();			
      //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;	  	
      this.isVisible[id] = this.isVisible[id]?false : true;		
      //this['isVisible'+id] = this['isVisible'+id] ? false:true;  		
      
      
      //var orderList = this.orderSupplyList.filter('filter')(this.orderSupplyList, {'strOrdId' : orderHeaderId}, true);
      var orderList = this.orderSupplyList.filter(item => item.strOrdId === orderHeaderId);
          
          if(orderList.length > 0){
              this.selectedOrder = orderList[0];
          }
       
      if(!this.selectedOrder.itemInfoAvailable){
        this.blockUI.start();
        var trackingShipment = {
          "orderNum" : orderHeaderId,
          "source" : this.selectedOrder.source
        }	
        
         var orderItemPromise = this.orderInquiryService.getSupplyOrderItems(trackingShipment).subscribe(
          data=>{
            var orderTrackItems = data;
            
            this.selectedOrder.partDetailsList = orderTrackItems;
    
            this.selectedOrder.itemInfoAvailable = 'Y';
            this.blockUI.stop();
          }
         );
    
      }
  }

 getInvoiceNumbers(orderNumber, source){
    var adPromise = this.orderInquiryService.getInvoiceNumbers(orderNumber).subscribe(

      data=>{
        this.invoices = data.data
      }
    );
  }

  getTrackingURLMsg() {}

   getInvoicePdf(invNum: any,source: any){
    debugger;
    //orderInquiry/inqOrdInvoiceDetEmanage?invoice_number=148436691&source=ORACLE
    let url="/orderInquiry/inqOrdInvoiceDetEmanage?invoice_number="+invNum + "&source="+source;
    console.log("Printing Invoice PDF link:...");
    console.log(url);
    window.open(url);
    
 }
  
 getInvoicePdfS21(invNum: any){
   debugger;
   let url="invoicepdf.htm?mthd=gipdfe&invoice_number="+invNum;
    window.open(url);
    
 }

 setRecMsg () {
  this.recMsg = "";
  this.start = (parseInt(this.recPerPage) * (this.pageNum - 1)) + 1;
  this.end = (parseInt(this.recPerPage) * this.pageNum);
  if (this.end > this.totalRecords) {
    this.end = this.totalRecords;
  }
  if (this.totalRecords > 0)
    this.recMsg = "Showing " + this.start + " to " + this.end + " of " + this.totalRecords;

}

setPgLinks() {
  this.arrPgLinks = [];
  var tr = this.totalRecords;
  var rpp = parseInt( this.recPerPage);
  if (tr > rpp) {
    var tl = tr / rpp;
    var nop = (tr % rpp == 0) ? (tl) : (tl) + 1; 

    var t2 = nop;
    var t1=1;
    if (nop > 10){
      
      var pgNum=this.pageNum;
       
      if(pgNum<10){ 
        t1=pgNum;
        t2=pgNum+9;
      }else{
        t1=pgNum-5; 
        t2=pgNum+4; 
      }
      
      for (var i = t1; i <= t2; i++) {
            if(i<nop){
                this.arrPgLinks.push(i);
                      }
        }
      
     }else{
      for (var i = t1; i <= t2; i++) {
        this.arrPgLinks.push(i);
        }
    }
    

  }

}

stopVideo(id: string){
 console.log('Printing id value: ', id);
 const video = document.getElementById(id) as HTMLVideoElement;
 if(video){
  video.pause();
  //video.load();
 }
}




}
