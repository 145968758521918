import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};



@Injectable({
  providedIn: 'root'
})
export class SwitchUserService {

  defaultUrl: string;
  private switchUserNotify = new Subject<any>();
  switchUserNotifyObservable$ = this.switchUserNotify.asObservable();
  
  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
   notifySwitchUser() {
    this.switchUserNotify.next();
  }

   getUserList=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/getUserList";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getUserList"))));
  }

  switchUser=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/su";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("switchUser"))));
  }

  checkSwitchUser=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/checksu";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("checkSwitchUser"))));
  }

  removeSwitchUser=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/rmsu";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("removeSwitchUser"))));
  }

  checkUser=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/cwau";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("checkUser"))));
  }
  checkSwitchedUser=function(oParams)
  {
      let url = this.defaultUrl + "switchuser/cswtu";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("checkSwitchedUser"))));
  }

}
