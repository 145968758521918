import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-configurator-maintenance',
  templateUrl: './configurator-maintenance.component.html',
  styleUrls: ['./configurator-maintenance.component.css','../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorMaintenanceComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  
  totalRecords=0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any =[];
  myBuild ={} as any;
  configId: number =-1;
  quoteId: number;
  imgUrl="https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any =[];
 productMaintenance ={} as any;
  maintenanceAmt;
  showShippingBtn = false;
  showColorDetails = true;
 deviceDetails = {};
  
  //Get Id from cart
 listAccessory = [];
 listAccessoryType = [];
 localOptions = {};
  servicePlan ={} as any;
  selectedTermRange ={} as any;
  
  selectedAvailableTerm: boolean;

		        // Accessories in cart 
  constructor(private configquoteService:ConfiguratorQuotesService,private route : ActivatedRoute,private router: Router,private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Maintenance");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
    });
    this.selectedTermRange = { availableTerm: 'defaultValue' };
    this.loadInitialData(this.quoteId,this.configId);
  }
  
   // Accessories in cart 
		        
   deleteItemMyBuildOptions(partyId, productGroupId, 
    machineItemCode, purchaseType, leaseRate, accessoryType, option, bundleName, priceListId, configQty) 
    {  
      let oParams = {"quoteId":this.quoteId,"partyId":partyId,"productGroupId":productGroupId,"machineItemCode":machineItemCode,"purchaseOption":this.myBuild.purchaseOption,
      "leaseRate":leaseRate,"bundleName":bundleName,"priceListId":priceListId,"configQty":configQty,"purchaseType":purchaseType,
      "option":option,"accessoryType":accessoryType}
  
      let deleteAction =  this.configquoteService.configQuoteGenericMtd(oParams,'deleteOptionItem').subscribe(
      deleteAction=> {
      console.log('result', deleteAction);
      let optionData = deleteAction.data.data;
      this.updateScopeData(optionData);
    },
    function(reason){
      console.log(reason);
    }
    );
  }
  updateScopeData(optionData: any) {
    this.myBuild.listRequiredAccessoryTypeDetails = optionData.listRequiredAccessoryTypeDetails;
    this.myBuild.listAccessoryTypeDetails = optionData.listAccessoryTypeDetails;
    this.myBuild.listRequiredAccessory = optionData.listRequiredAccessory;
    this.myBuild.listRequireOneOfItemAccessory = optionData.listRequireOneOfItemAccessory;
    this.myBuild.listRequiredBundleAccessory = optionData.listRequiredBundleAccessory;
    this.myBuild.bundleIncludesRequireOneOfItemAccessory = optionData.bundleIncludesRequireOneOfItemAccessory;
    console.log('myBuild',this.myBuild);
  } 
  isHeaderEnable (listAccessory){
    return ConfiguratorQuotesService.isHeaderEnable(listAccessory);
  }

   findLengthItems (listAccessory) {
       return ConfiguratorQuotesService.findLengthItems(listAccessory);
   }
   //Remove machine level in the Options page
   removeFromCartOptionsPage (configId,quoteId) {
     let oParams = {"quoteId":this.quoteId,"configId":this.configId};
       let quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(quoteId,'getQuoteConfigDetails').subscribe(
       quoteDetailsPromise =>{
           console.log('getquoteDetails', quoteDetailsPromise);
               let quoteDet = JSON.parse(quoteDetailsPromise.data);
               let configLength = quoteDet.config.length;
               console.log('configLength',configLength);
               this.configquoteService.removeConfig(configId);
              let oParams = { "quoteId": this.quoteId, "configId": this.configId};
              const navigationExtras: NavigationExtras = {
                queryParams: oParams
    
              };
             if(configLength > 1){             
                this.router.navigate(['/configsummary'], navigationExtras);
             }else {
              this.router.navigate(['/createquote'], navigationExtras);
             }
            },
           function(reason) {
               console.log(reason);
           });
     }

maintenanceChecked(){

if(this.maintenanceAmt && this.maintenanceAmt != ''){
    return true;
  }else{
    return false;
  }   

}

async loadInitialData(quoteId,configId){
    //Get the selected model details
    this.blockUI.start();
    let oParams = {"quoteId":quoteId,"configId":configId};
  let quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(  
  (quoteDetailsPromise:any) =>{
    if (quoteDetailsPromise && quoteDetailsPromise.data) {
      try {
        this.myBuild = JSON.parse(quoteDetailsPromise.data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
        
        if(this.myBuild.config.length > 0){
        let configDetails = this.myBuild.config[0];
        this.myBuild.itemDescription = configDetails.itemDescription;
        this.myBuild.purchaseOption = configDetails.purchaseOption;
        if(configDetails.leaseTerm != null && configDetails.leaseTerm != undefined)
        {
          this.myBuild.leaseTerm = configDetails.leaseTerm;
        }else
        {
          this.myBuild.leaseTerm = "";
        }
        
        this.myBuild.amount = configDetails.amount;
        this.myBuild.productGroupId = configDetails.productGroupId;
        this.myBuild.machineItemCode = configDetails.itemCode;
        this.myBuild.leaseRate = configDetails.leaseRate;
        this.myBuild.partyId = configDetails.partyId;
        this.myBuild.model = configDetails.model;
        this.myBuild.maintDetailsAvailable = false;
        this.myBuild.bundleName = configDetails.bundleName;
        this.myBuild.priceListId = configDetails.pricelistId;
        this.myBuild.purchaseType = configDetails.purchaseType;
        this.myBuild.configQty = configDetails.configQty;
        if(this.myBuild.maintenance.length > 0){
          this.myBuild.maintDetailsAvailable = true;
          let maintDetails = this.myBuild.maintenance[0];
          
          if(maintDetails.isRejected == 'Y'){
            this.myBuild.maintTerm = "No Maintenance";
          }else{
            this.myBuild.maintTerm = maintDetails.termUser + " Months (" + maintDetails.contractType + ")";
          }
          this.myBuild.maintenanceAmt = this.configquoteService.getMaintTotal(this.myBuild.maintenance);
        }
        
        this.myBuild.imgSrc = this.imgUrl + this.myBuild.model+".jpg";	
        }//end of if
        
        let oParams = {"quoteId":this.quoteId,"partyId":this.myBuild.partyId,"configId":this.configId,"productGroupId":this.myBuild.productGroupId,"itemCode":this.myBuild.machineItemCode,"purchaseType":this.myBuild.purchaseType,
           "leaseRate":this.myBuild.leaseRate,"bundleName":this.myBuild.bundleName,"priceListId":this.myBuild.priceListId,"configQty":this.myBuild.configQty}
        let buildOptionPromise =  this.configquoteService.configQuoteGenericMtd(oParams,'getMachineItems').subscribe(
          buildOptionPromise=>{        
            let optionData = buildOptionPromise.data;
            this.updateScopeData(optionData);

          }
        );
        
        //console.log("qryStr:"+qryStr);
       // let oParams_maint = {"configId":1060,"purchaseOption":"L","leaseTerm":"36 Month Lease" }
       let oParams_maint = {"configId":this.configId,"purchaseOption":this.myBuild.purchaseOption,"leaseTerm":this.myBuild.leaseTerm}
        let productPromise = this.configquoteService.configQuoteGenericMtd(oParams_maint,'getMaintenanceDetails').subscribe(
          productPromise=>{
                  console.log('result', productPromise);
                  this.blockUI.start();
              //this.myBuild.productMaintenance = result.data;	
              this.productMaintenance = productPromise.data;
              console.log('pm availableTerm:',this.productMaintenance[0].availableTerm);
              
              //If No Maintenance is the only option then select that option by default
              if(this.productMaintenance.length == 1){
                this.selectedTermRange = this.productMaintenance[0];
                this.showShippingBtn = true;
              }
              
              //Set selected values
            for (let i in this.productMaintenance) {
              let currentMaint = this.productMaintenance[i];
              
              //Check if the current term is selected
              if(currentMaint.selected){
                
                this.selectedTermRange = currentMaint;
                this.selectedAvailableTerm = true;
                if(currentMaint.termFrom == -1){
                  this.showShippingBtn = true;
                  this.maintenanceAmt = 0;
                }else{

                  this.servicePlan = currentMaint.options[0];
                  this.maintenanceAmt = this.servicePlan.amount;
                  this.showShippingBtn = true;
                  
                    if(this.servicePlan.hasColor == false){
                      this.showColorDetails = false;
                    }				    					
                }
              }
            }
            this.blockUI.stop();
              },
              function(reason) {
                  console.log(reason);
              });			    	
        
            }
            else {
              console.error('Empty or incomplete JSON data received.');
            }
            this.blockUI.stop();
      },
      error => {
        console.error('Error retrieving quote details:', error);
      }
      
  );	
    //let productPromise = httpService.getData('configurator.htm?mthd=getMD&configId=' + configId);
  
    
  }
  isOptionChecked(opt){
    return ConfiguratorQuotesService.isOptionChecked(opt);
  }

  //Called when we select a service option under a term
setMaintenanceOption() {
    if(this.servicePlan != null){
      this.maintenanceAmt = this.servicePlan.amount;
      console.log("this.maintenanceAmt: " + this.maintenanceAmt);
      
      this.showShippingBtn = true;
      
      if(this.servicePlan.hasColor == false){
        this.showColorDetails = false;
      }
    }else{
      this.showShippingBtn = false;
    }
 
  }

  //Called when we select the term
  retrieveMaintenanceOption(maintenance) {
    
    //If no term range is selected hide the button
    if(this.selectedTermRange == null){
      this.showShippingBtn = false;
      this.servicePlan = "";
    }else{
      //Check if No Maint is selected then display the Shipping button
      if(this.selectedTermRange.termFrom == -1){
        this.maintenanceAmt = 0;
        this.showShippingBtn = true;
        this.servicePlan = "";
      }else{
        this.showShippingBtn = false;
        this.servicePlan = this.selectedTermRange.options[0];
        this.maintenanceAmt = this.servicePlan.amount;
        this.showShippingBtn = true;
          
        if(this.servicePlan.hasColor == false){
          this.showColorDetails = false;
        }			        			
      }
    }
  }
  
  
  saveMaint (event){
    if(event){
      event.preventDefault();
    }
    let valid = true;
    
    //Check if all the data is selected
    if(this.selectedTermRange == null){
      alert("Please select the Term Range");
      valid = false;	
    }else{
      
      if(this.selectedTermRange.termFrom != -1){

    if(this.servicePlan.hasColor && this.servicePlan.colorEstCopies == ''){
          alert("Please enter a value for Color Estimated Copies");
          valid = false;
        }else if(this.servicePlan.bwEstCopies == ''){
          alert("Please enter a value for Black & White Estimated Copies");
          valid = false;
        }
      }
    }
    
    if(!valid) return false;
    
    
    
    let maintObj = {} as any;
    
    //Create the js object with all the selected data

  maintObj.quoteId = this.quoteId;
  maintObj.configId = this.configId;
  
/*    			maintObj.inventoryItemId = invItemId;
  maintObj.model = model;
  maintObj.partyId = partyId;
*/    			
  
  maintObj.termFrom = this.selectedTermRange.termFrom;
  maintObj.termTo = this.selectedTermRange.termTo;
  
  if(this.selectedTermRange.termFrom != -1 && this.selectedTermRange.termTo != -1){
    maintObj.termUser = this.selectedTermRange.availableTerm;
    
  maintObj.planType = this.servicePlan.planType;
  maintObj.contractType = this.servicePlan.contractType;
  
  maintObj.hasColor = this.servicePlan.hasColor;

  maintObj.bwCPC = this.servicePlan.bwCPC;
  maintObj.bwAvgCopyVol = this.servicePlan.bwAvgCopyVol;
  maintObj.bwEstCopies = this.servicePlan.bwEstCopies;
  maintObj.bwBasePrice = this.servicePlan.bwBasePrice;
  maintObj.bwOverageRate = this.servicePlan.bwOverageRate == null? 0:this.servicePlan.bwOverageRate;
  maintObj.bwListLineId = this.servicePlan.bwListLineId;
  maintObj.bwMaintId = this.servicePlan.bwMaintId;
  
  maintObj.colorOverageRate = this.servicePlan.colorOverageRate == null? 0:this.servicePlan.colorOverageRate;
  maintObj.colorCPC = this.servicePlan.colorCPC;
  maintObj.colorAvgCopyVol = this.servicePlan.colorAvgCopyVol;
  maintObj.colorEstCopies = this.servicePlan.colorEstCopies;
  maintObj.colorBasePrice = this.servicePlan.colorBasePrice;
  maintObj.colorListLineId = this.servicePlan.colorListLineId;
  maintObj.colorMaintId = this.servicePlan.colorMaintId;
  
  maintObj.priceListId = this.servicePlan.priceListId;
  
  
  maintObj.servicePkgName = this.servicePlan.servicePackageName;
  maintObj.priceBookName = this.servicePlan.priceBookName;
  }
    console.log("$.param(maintObj):" + $.param(maintObj));        
    
    let qryStr=$.param(maintObj);
    
      let maintPromise = this.configquoteService.configQuoteGenericMtd(maintObj,'saveMaintenanceDetails').subscribe(
      maintPromise =>{
              console.log('result', maintPromise);
              let oParams = { "quoteId": this.quoteId, "configId": this.configId };
              const navigationExtras: NavigationExtras = {
                queryParams: oParams
            
              };
              this.router.navigate(["/configshipping"], navigationExtras);
            // window.location.href="configurator.htm?view=configShipping&quoteId=" + this.quoteId + "&configId=" + this.configId;
          },
          function(reason) {
              console.log(reason);
          });        	
    
  }  
  
  calculateCharge(type){

    let valid = true;
    //Check the value entered is numeric and > 0 for estimated color and bw copies
    if ( type =="color"){
      if (isNaN(this.servicePlan.colorEstCopies) && typeof this.servicePlan.colorEstCopies !== 'number') {
        alert("Please enter numeric values");
        valid = false;
      }
      else if(this.servicePlan.colorEstCopies < 1){
        alert("Please enter value > 0");
        valid = false;
      }
    }else if ( type =="bw"){
      if(isNaN(this.servicePlan.bwEstCopies) && typeof this.servicePlan.bwEstCopies !== 'number' ){
        alert("Please enter numeric values");
        valid = false;
      }
      else if(this.servicePlan.bwEstCopies < 1){
        alert("Please enter value > 0");
        valid = false;
      }
    }

    if(!valid) 
    return;
    
    switch (type){
      case "color":
        console.log("this.servicePlan:" + this.servicePlan);
        let colorCharge = this.servicePlan.colorBasePrice + (this.servicePlan.colorEstCopies*this.servicePlan.colorCPC);
        let totalCharge = colorCharge + this.servicePlan.bwCalculatedCharge;        
        this.servicePlan.colorCalculatedCharge = colorCharge;
        this.maintenanceAmt = totalCharge;        
        break;
      case "bw":
      let bwCharge = this.servicePlan.bwBasePrice + (this.servicePlan.bwEstCopies*this.servicePlan.bwCPC);
        totalCharge = bwCharge + (this.servicePlan.hasColor? this.servicePlan.colorCalculatedCharge : 0);
      this.servicePlan.bwCalculatedCharge = bwCharge;
      this.maintenanceAmt = totalCharge;      
      break;
    }
    //this.loadInitialData(this.quoteId,this.configId);
}
  
routePage(event,pageName)
{
 if(event)
 {
  event.preventDefault();
 }
 let oParams = {"quoteId":this.quoteId,"configId":this.configId};
 const navigationExtras: NavigationExtras = {
   queryParams: oParams

 };
 this.router.navigate([pageName], navigationExtras);
}
//Remove machine level in the Options page
removeFromCart(configId,event) {
 if(event)
 {
  event.preventDefault();
 }
let oParams = {"quoteId":this.quoteId};
  var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
  quoteDetailsPromise =>{
      console.log('getquoteDetails', quoteDetailsPromise);
          var quoteDet = JSON.parse(quoteDetailsPromise.data);
          var configLength = quoteDet.config.length;
          console.log('configLength',configLength);
          this.configquoteService.removeConfig(configId);
         
         const navigationExtras: NavigationExtras = {
          queryParams: oParams
         };
          if(configLength > 1){
            this.router.navigate(['/configsummary'], navigationExtras);
          }else {
            this.router.navigate(['/createquote'], navigationExtras);
          }
       },
      function(reason) {
          console.log(reason);
      });
}

}


