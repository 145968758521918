import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { LookupMaintainService } from './lookup-maintain-value.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-lookup-maintain-value',
  templateUrl: './lookup-maintain-value.component.html',
  styleUrls: ['./lookup-maintain-value.component.css']
})
export class LookupMaintainValueComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  lookupId:any;
  header:any;
  headerlookupName:any;
  headerlookupId:any;
  headerlookupDescription:any;
  headerlookupViewName:any;
  lookupValues:any=[];
  recPerPage=10;
  pageNum:any=1;
  numOfRecsMsg:any="";
  arrPgLinks:any=[];
  count:any=0;
  newlookupSerialNumber:any;
  newlookupModel:any;
  itemDiscription:any;
  responsiveTime:any;
  afterHourResponsiveTime:any;
  editlookupSerialNumber: any;
  editlookupModel: any;
  edititemDiscription: any;
  editresponsiveTime: any;
  editafterHourResponsiveTime: any;
  currentRecord: any;
  deleteButtonDisplayFlag: boolean;
  editlookupColumnName: any;
  editdisplayColumnName: any;
  editMandatoryFlag: any;
  newlookupColumnSeq: any;
  editlookupColumnNameSec: any;
  selectedLookupID:any;
  lookupColumns:any;
  lookupColumnsShort:any;
  lookupColumnsLength:any;
  newlookupName:any;
  newlookupValue:any;
  newlookupCpntext:any;

  editlookupValueID:any;
  editlookupName:any;
  editlookupValue:any;
  editlookupContext:any;
  highlightedRowIndex = -1;

  objEditLookup:any;


  lookupDataSet:any=[];
  

  constructor(private lookupMaintainService:LookupMaintainService, private router: Router,private activatedRoute: ActivatedRoute, private commonService:CommonServiceService ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['lookupId']!=undefined)
      this.lookupId = params['lookupId'];

    });

  }

  ngOnInit() {
   this.getLookupValues();

  }



  lookupSearch(){
    this.router.navigate(['enterlookups']);
  }

  getLookupValues(){
    var param= {
      "lookupId":this.lookupId
    }
    this.blockUI.start();
        this.lookupMaintainService.getLookupValues(param).subscribe(
          data=>{
            this.lookupDataSet=[];
            this.lookupColumnsLength = data.Columns.length;
            this.header = data.Headers[0];
            this.headerlookupName= this.header.lookupName;
            this.headerlookupId= this.header.lookupId;
            this.headerlookupDescription = this.header.lookupDescription;
            this.headerlookupViewName = this.header.lookupViewName;
            this.lookupValues = data.Values;
            this.lookupColumns = data.Columns;
            let lenth = data.Columns.length;
            console.log('Printing data column length', lenth);
           
            this.highlightedRowIndex=-1;
            this.count= data.count;

            for(let cId in this.lookupColumns){
                let col= this.lookupColumns[cId];

                  for(let vId in this.lookupValues){
                  let val= this.lookupValues[vId];
                  let tmpLookupColumnName= col["lookupColumnName"];

                  let objTmp=this.lookupDataSet[vId];
                  if(!objTmp){
                    objTmp={};
                  } 

                  objTmp[tmpLookupColumnName]=val[ parseInt(col.columnSeq)-1 ];
                   

                  this.lookupDataSet[vId]=objTmp;
               }
              
            }

           // console.log("********");
          //console.log(this.lookupDataSet);


            console.log("Printing record per page: ", this.recPerPage);
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.count,this.pageNum,this.recPerPage);
    
    
            console.log(data);
            console.log("this.count : "+this.count);
            this.blockUI.stop();
    
          }
    
        );

  }

  createLookupColVal(event:any){
    if(event!=null){
      event.preventDefault();
    }
    var param={};
 
    param= {
    "ID":"0",
    "lookupId":this.headerlookupId,
    "lookupViewName": this.headerlookupViewName,
    "lookupDescription":this.headerlookupDescription,
    "lookupName":this.headerlookupName,
     "action":"I"
   
  }

  $(".cr_lkp").each(function (){
    param[$(this).data("sid")] = $(this).val();
    console.log($(this).data("sid") +"------"+$(this).val());
   });
  console.log("**** New Lkp entry Param ****");
  console.log(param);

    this.blockUI.start();

    this.lookupMaintainService.createLookupColVal(param).subscribe(
      data=>{
        console.log(data);
        this.getLookupValues();
        this.blockUI.stop();
      }
    );


  }

  updateLookupColVal(event:any){
    if(event!=null){
      event.preventDefault();
    }
    var param= {};

  
     param={
        "ID":this.objEditLookup.ID, 
        "lookupId":this.headerlookupId,
        "lookupViewName": this.headerlookupViewName,
        "lookupDescription":this.headerlookupDescription,
        "lookupName":this.headerlookupName,
        "action":"U"
     
    }

    $(".edt_lkp").each(function (){
      param[$(this).data("sid")] = $(this).val();
      console.log($(this).data("sid") +"------"+$(this).val());
     });
    console.log("**** Edit look up entry param ****");
    console.log(param);
      
          this.blockUI.start();
         
     
          this.lookupMaintainService.createLookupColVal(param).subscribe(
            data=>{
              console.log(data);
              this.getLookupValues();
              this.blockUI.stop();
            }
          ); 

  }

  deleteLookupColVal(event:any){
    if(event!=null){
      event.preventDefault();
    }
   // console.log("DELETE Lookup");

   // console.log(this.objEditLookup);

    var param= {};
 
    param={
      "ID":this.objEditLookup.ID, 
      "lookupId":this.headerlookupId,
      "lookupViewName": this.headerlookupViewName,
      "lookupDescription":this.headerlookupDescription,
      "lookupName":this.headerlookupName,
      "action":"D"
     }
      
     for (let k in this.objEditLookup){
      if(!(k=='LOOKUP_ID' || k=='LOOKUP_NAME')){
         param[k]=this.objEditLookup[k];
        }
     }
     

          this.blockUI.start();
      
          this.lookupMaintainService.createLookupColVal(param).subscribe(
            data=>{
              console.log(data);
              this.getLookupValues();
              this.blockUI.stop();
            }
          );

  }

  rowClick(lookup,index){
    this.highlightedRowIndex = index;
    this.currentRecord=lookup;
   
    console.log("Printing lookup value: " );
    console.log(lookup);
    
    this.objEditLookup=lookup;


  }
 
  toggleIcon(refDivEl: HTMLElement) {
	
		if (refDivEl.children[0].classList.contains('fa-plus')) {
		      refDivEl.children[0].classList.remove("fa-plus");
		      refDivEl.children[0].classList.add("fa-minus");
		      refDivEl.children[0].classList.remove("color-green");
		      refDivEl.children[0].classList.add("color-red");
		}else {
			refDivEl.children[0].classList.remove("fa-minus");
		    refDivEl.children[0].classList.add("fa-plus");
		    refDivEl.children[0].classList.remove("color-red");
		    refDivEl.children[0].classList.add("color-green");
		}
  }

}
