import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CsaInvoiceInquiryService } from 'src/app/invoices/csa-invoice-inquiry/csa-invoice-inquiry.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { formatDate } from '@angular/common';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SubmitPaymentComponent } from '../submit-payment/submit-payment.component';
import { SubmitPaymentService } from '../submit-payment/submit-payment.service';

@Component({
  selector: 'app-csa-invoice-inquiry',
  templateUrl: './csa-invoice-inquiry.component.html',
  styleUrls: ['./csa-invoice-inquiry.component.css']
})
export class CsaInvoiceInquiryComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  @ViewChild('autoPayModal') autoPayModalTemplate:TemplateRef<any>;
  autoPayModal: BsModalRef;

  numOfRecsMsg:string="";
  invoicePaymentList:any=[];
  selectedInvoiceList:any=[];
  arrPgLinks:any=[];
  userAccountList:any=[];
  pageNum:any=0;
  totalRecords:any=0;
  isVisible:any=[];
  recPerPage:any=10;
  start:number=1;
  end:number=10;
  selectedUserAccts:string = "";
  invoiceNum:string = "";
  urnNumber:string = "";
  receiptNumber:string = "";
  orderNum:string = "";
  serialEqpNum:string = "";
  billToAccNum:string = "";
  billToAddress:string = "";
  installAddress:string = "";
  dueFromDate:Date = null;
  dueToDate:Date = null;
  transTypeSearch:string = "";
  tranStatusSearch:string = "OP";
  sDocType:string = "CONT";
  transactionFromDate:Date = null;
  transactionToDate:Date = null;

  status:string = null;
  selectedInvoice:any = '';
  target:any = '';
  noneSelected:boolean = false; 
  showMsg : boolean = false;
  msg : string = "";
 
  resetValues() {
    this.numOfRecsMsg="";
    this.invoicePaymentList=[];
    this.selectedInvoiceList=[];
    this.arrPgLinks=[];
    //this.userAccountList=[];
    this.pageNum=0;
    this.totalRecords=0;
    this.recPerPage=10;
    this.start=1;
    this.end=10;
    this.selectedUserAccts = "";
    this.invoiceNum = "";
    this.urnNumber = "";
    this.receiptNumber = "";
    this.orderNum = "";
    this.serialEqpNum = "";
    this.billToAccNum = "";
    this.billToAddress = "";
    this.installAddress = "";
    this.transactionFromDate = null;
    this.transactionToDate = null;
    this.dueFromDate = null;
    this.dueToDate = null;
    this.transTypeSearch = "";
    this.tranStatusSearch = "OP";
    this.sDocType = "CONT";
    this.status = null;
    this.selectedInvoice = '';
    this.target = '';
    this.noneSelected = false;

    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }

    this.getCsaInvoiceList(1, null);
  }


  constructor(private commonService:CommonServiceService, 
              private csaInvoiceInquiryService:CsaInvoiceInquiryService, private util : Util, private ngbModalService: NgbModal,
              private titleService: Title, private modalService: BsModalService,
              private submitPaymentService: SubmitPaymentService) { }

  ngOnInit() {
    this.titleService.setTitle("CSA Invoice Inquiry");
    this.blockUI.start();

    this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
        //this.getCsaInvoiceList(1, null);
        this.blockUI.stop();
      });
    

    //this.commonService.setCommonFunctions();
    this.commonService.setMoreInfo();
    this.commonService.expandCollapseAll();
    this.commonService.keepSearchSectionOpen();

    this.submitPaymentService.makePaymentModalClosedObservable$.subscribe(() => {
      this.resetValues();
      //this.getCsaInvoiceList(1, null);
    })
  }

  confirm() {
    this.closeModal();
  }

  getCsaInvoiceList(pgNum: any,event: any){
    if(event!=null){
      event.preventDefault();
    }
    this.blockUI.start();
    this.clearMsg();
    for (var i=0; i < this.recPerPage; i++) {
      this.isVisible.push(false);  
    }
    const format = 'dd-MMM-yyyy';
    const locale = 'en-US';
    
    
    this.pageNum=pgNum;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("pageNum :"+pgNum);
    console.log("recPerPage :"+this.recPerPage);
    let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.invoiceNum, "urnNumber" : this.urnNumber, 
                    "receiptNumber" : this.receiptNumber, "contractNumber" : this.orderNum, "serialNumber": this.serialEqpNum, 
                    "billToAccNumber" : this.billToAccNum, "billToLocation" : this.billToAddress, "installLocation" : this.installAddress,
                    "invDateFrom" : (this.transactionFromDate == null ? '' :formatDate(this.transactionFromDate, format, locale)), "invDateTo" : (this.transactionToDate == null ? '' :formatDate(this.transactionToDate, format, locale)), 
                    "dueDateFrom" : (this.dueFromDate == null ? '' :formatDate(this.dueFromDate, format, locale)),  "dueDateTo" : (this.dueToDate == null ? '' : formatDate(this.dueToDate, format, locale)),
                    "invType" : this.transTypeSearch, "tranStatus" : this.tranStatusSearch, "sDocType" : this.sDocType,
                    "pageNum":this.pageNum, "recsPerPage":this.recPerPage };

    this.csaInvoiceInquiryService.getInvoiceList(oParams).subscribe(
      data=>{
        this.invoicePaymentList = data.data; 
        this.totalRecords=data.noOfRecs;

        if(this.totalRecords == 0){
          this.showMsg = true;
          this.msg = "No Records Found";
        }

        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
        this.blockUI.stop();
      });
  }

  exportToExcel(event:any){
    if(event){
      event.preventDefault();
    }
    let url="invoiceInquiry/invoiceInquiryExport";

    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);

    const format = 'dd-MMM-yyyy';
    const locale = 'en-US';

    let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.invoiceNum, "urnNumber" : this.urnNumber, 
                    "receiptNumber" : this.receiptNumber, "contractNumber" : this.orderNum, "serialNumber": this.serialEqpNum, 
                    "billToAccNumber" : this.billToAccNum, "billToLocation" : this.billToAddress, "installLocation" : this.installAddress,
                    "invDateFrom" : (this.transactionFromDate == null ? '' :formatDate(this.transactionFromDate, format, locale)), "invDateTo" : (this.transactionToDate == null ? '' :formatDate(this.transactionToDate, format, locale)), 
                    "dueDateFrom" : (this.dueFromDate == null ? '' :formatDate(this.dueFromDate, format, locale)),  "dueDateTo" : (this.dueToDate == null ? '' : formatDate(this.dueToDate, format, locale)),
                    "invType" : this.transTypeSearch, "tranStatus" : this.tranStatusSearch, "sDocType" : this.sDocType,
                    "pageNum":this.pageNum, "recsPerPage":this.recPerPage };
    let qryStr="";
    for (let entry in oParams) {
      qryStr += entry + '=' + encodeURIComponent(oParams[entry]) + '&';
    }
    qryStr = qryStr.substring(0, qryStr.length-1); // remove last '&'

    this.commonService.exportToExcel(url+"?"+qryStr);

  }

  getInvPdf(invNumber){
    var url="invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNumber+"&source=ORACLE";
    window.open(this.util.getBaseUrl() + url);
  }

  downloadPdf (inv){
    let source = inv.source;
    let invoiceType = inv.invoiceType;
    let invNum = inv.invNumber;
    let fileId = inv.fileId;
    let pathWithFile = inv.pathWithFile;
    let fileName = inv.fileName;
    
  if(source == 'S21' || source == 's21'){
    if(invoiceType == 'MSB'){
      window.open('invoicePdf/getInvoicePdfExcelForEmanage?invoice_number='+invNum);
    }
    else if (invoiceType == 'SB'){
      var excelUrl = "invoicePdf/getInvoiceExcelForEmanage?invoice_number="+invNum+"&invoiceType="+invoiceType+"&fileId="+fileId+"&pathWithFile="+pathWithFile+"&source="+source+"&fileName="+fileName;
            window.open(excelUrl);				
    }else if (invoiceType != 'MSB' && invoiceType != 'SB')
        {
           if (fileId != null)
             {
        window.open("invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNum+"&invoiceType="+invoiceType+"&fileId="+fileId+"&pathWithFile="+pathWithFile+"&source="+source+"&fileName="+fileName);
            }	            
         } 
  }                             		                           	
}

  filterSelected(invoice:any, event:any) {
    var paramObj = {};	
    paramObj["invoiceNumber"] = invoice.invNumber;
    this.selectedInvoice = invoice; 

    var qryStr=$.param(paramObj);
    this.target = event.target;

    if (event.target['checked'] == true) {
      invoice.selected = true;
      /* this.csaInvoiceInquiryService.getAutoPayStsFlag(qryStr).subscribe(
        data=>{
          if (data.status == 'Y') {
            this.openModal();
          }
        }); */
    } else {
      invoice.selected = false; 
    }
    
    this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
      return tag.selected == true;
    }); 
  }

  openModal() {
    this.autoPayModal = this.modalService.show(this.autoPayModalTemplate);
  }
  
  cancelModal() {
    this.target['checked'] = false; 
    this.invoicePaymentList.forEach((payment) => {
      if (payment.invNumber == this.selectedInvoice.invNumber) {
        payment.selected = false; 
        this.selectedInvoiceList = this.selectedInvoiceList.filter(item => item.invNumber !== payment.invNumber);
      }
    });
    this.closeModal();
  }

  closeModal() {
    this.autoPayModal.hide();
  }

  openMakePaymentModal(){
    if (this.selectedInvoiceList.length <= 0) {
      this.noneSelected = true;
      return;
    } else {
      this.noneSelected = false;
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        windowClass: 'scrollable-modal',
        size: <any>'xl' 
      };
      const makePaymentModalRef = this.ngbModalService.open(SubmitPaymentComponent, ngbModalOptions);
      makePaymentModalRef.componentInstance.selectedInvoiceList= this.selectedInvoiceList;
    }
  }

  clearMsg() {
		this.showMsg = false;
		this.msg = "";
	}

  toggleDiv = function(index,$event){	
    $event.preventDefault();				
    this.isVisible[index] = !this.isVisible[index]; 

}

stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }
 
}
