import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TonerReturnService } from './toner-return.service';
import { TonerReturn } from './TonerReturn';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-toner-return',
  templateUrl: './toner-return.component.html',
  styleUrls: ['./toner-return.component.css']
})
export class TonerReturnComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  @ViewChild('srlModal') srlTemplate:TemplateRef<any>;
  srlModalRef: BsModalRef

  @ViewChild('addrModal') addrTemplate:TemplateRef<any>;
  addrModalRef: BsModalRef

  tonerReturn: TonerReturn = new TonerReturn();
  bSearchBySerial=false;
  bSearchByAddress=false;
  bCustItmRsn=false;
  searchSerial:string="";
  searchAddress:string="";
  partySiteId:string="";
  suppItem:string="";
  
  pageNum: number = 1;
  recPerPage = 10;
  numOfRecsMsg="";
  searchSerialTxt="";
  modelTxt="";
  addressTxt="";
  arrPgLinks=[];
  totalRecords = 0;	
  
  aPageNum: number = 1;
  aRecPerPage = 10;
  aNumOfRecsMsg="";
  searchAddrTxt:string="";
  aArrPgLinks=[];
  aTotalRecords = 0;	
   

  manufacturer:string="";
  suppItm:string="";
  returnReason:string="";

  srlList =[];
  addrList=[];
  mnftrList=[];
  suppItmList=[];
  rtnRsnList=[];

  msg:string="";
  msgClass:string="";
  
  constructor(private tonerReturnService:TonerReturnService,private commonService:CommonServiceService,private modalService: BsModalService,private router : Router) { }

  ngOnInit() { 
    this.getReturnReasonList();
    this.commonService.setCommonFunctions();
  }

  getSerialTonerDetails(srl: any,event: any){
    this.bSearchBySerial=false;
    this.bCustItmRsn=false;
    if(event!=null){
     event.preventDefault();
   }
   this.searchSerial=srl;
   this.tonerReturn = new TonerReturn();
   this.blockUI.start();
    let oParams = { "serialNumber": this.searchSerial};
    let data = this.tonerReturnService.getSerialTonerDetails(oParams).subscribe(
     data=>{
          
          this.tonerReturn=data;
          console.log(this.tonerReturn);
          this.bSearchBySerial=true;
          this.bCustItmRsn=true;
          this.blockUI.stop(); 
     });
 
 }


 getSuppliesBySupplyItem(event: any){
  //this.bSearchByAddress=false;
  this.bCustItmRsn=false;
  this.tonerReturn = new TonerReturn();
  if(event!=null){
   event.preventDefault();
 }
  
 this.tonerReturn = new TonerReturn();
 this.blockUI.start();
  let oParams = { "partySiteId": this.partySiteId , "suppItem": this.suppItm};
 
  let data = this.tonerReturnService.getSuppliesBySupplyItem(oParams).subscribe(
   data=>{
        
        this.tonerReturn=data;
        console.log(this.tonerReturn);
      //  this.bSearchByAddress=true;
        this.bCustItmRsn=true;
        this.blockUI.stop();
   });

}

 getSerialList(pgNum: any,event: any){
  this.searchAddress=""; 
  this.clearSrlData();
  if(event!=null){
   event.preventDefault();
 }
 if(this.searchSerial){
  this.searchSerial=this.searchSerial.toUpperCase();
}

 this.blockUI.start();
 this.pageNum=pgNum;
 let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
   "serialNumber":this.searchSerial,"model":this.modelTxt,"address":this.addressTxt
    
  };

 let data = this.tonerReturnService.getSerialList(oParams).subscribe(
   data=>{
      this.srlList = data.data; 
      console.log(data.data);
     // console.log(this.srlList);
      this.totalRecords=data.recordsTotal;
      this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
      this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
      this.blockUI.stop();
   });

}


searchSerialList(event: any){
  if(event!=null){
    event.preventDefault();
  }
  if(this.searchSerialTxt){
    this.searchSerialTxt=this.searchSerialTxt.toUpperCase();
  }
  this.searchSerial=this.searchSerialTxt; 
  this.getSerialList(1,null);
} 


getAddrList(aPgNum: any,event: any){
  this.bCustItmRsn=false;
  this.searchSerial ="";
  this.clearAddrData();
  if(event!=null){
   event.preventDefault();
 }
 if(this.searchAddress){
  this.searchAddress=this.searchAddress.toUpperCase();
 }

 this.blockUI.start();
 this.aPageNum=aPgNum;
 let oParams = { "pageNum": aPgNum, "recPerPage": this.aRecPerPage, 
   "address":this.searchAddress
    
  };

 let data = this.tonerReturnService.getAddressList(oParams).subscribe(
   data=>{
      this.addrList = data.data; 
     // console.log(this.addrList);
      this.aTotalRecords=data.recordsFiltered;
      this.aNumOfRecsMsg=this.commonService.getNumOfRecsMsg(this.aTotalRecords,this.aRecPerPage,this.aPageNum);
      this.aArrPgLinks=this.commonService.getPgLinks(this.aTotalRecords,this.aPageNum,this.aRecPerPage);
      this.blockUI.stop();
   });

}

searchAddressList(event: any){
  if(this.searchAddrTxt){
    this.searchAddrTxt=this.searchAddrTxt.toUpperCase();
  }
  this.searchAddress=this.searchAddrTxt;
  if(event!=null){
    event.preventDefault();
  }
  this.searchAddress=this.searchAddrTxt; 
  this.getAddrList(1,null);
} 

getFormattedAddr(objSrl:any){
  let sAddr="";
  try {
    let address=objSrl["address"];
    let address2=objSrl["address2"];
    let city=objSrl["city"];
    let state=objSrl["state"];
    let postalCode=objSrl["postalCode"];
    
    if(address)sAddr=address;
    if(address2)sAddr+=","+address2;
    if(city)sAddr+=",<br>"+city;
    if(state)sAddr+=","+state;
    if(postalCode)sAddr+=","+postalCode;
    

  } catch (error) {
    console.log("ERROR:"+error);
  }
  return sAddr;
}

submitTonerReturn(event:any){
 let bError=false;
 let errMsg="";
 this.msg="";
 this.msgClass="";
 this.blockUI.start(); 
 this.msg="";
  if(event!=null){
    event.preventDefault();
  }

  //console.log("*********");
  //console.log(this.tonerReturn);
 
  let trObj= new TonerReturn();
   trObj=this.tonerReturn;
  let itmSelected=false;
  for (let i = 0; i < trObj.itemList.length; i++) {
    let itm = trObj.itemList[i];
    if(itm.isChecked){
      itmSelected=true;
      let tmpQty=(itm.qty)?parseInt(itm.qty+""):0;
      if(tmpQty <1 ){
        bError=true;
        errMsg+="<br> Invalid quantity for Item : "+itm.itemNumber;
      } 
    } 
  }
  if(!itmSelected){ 
    bError=true; 
    errMsg+="<br> Please select Items to return.";
   } 

  if(this.returnReason.length<1){
    bError=true;
    errMsg+="<br> Please select Return Reason.";
  }else{
    trObj["returnReason"]=this.returnReason;
  }
   

  if(!bError){

  
  let o=JSON.stringify(trObj);
 
  let data = this.tonerReturnService.submitTonerReturn(o).subscribe(
    data=>{
      this.blockUI.stop();
      let objData= data;
       console.log( objData); 
        let ordNum="";
        ordNum=objData["ordNum"];
        if(ordNum&&ordNum.trim().length>0){

          let params={};
          params['returnNum']=ordNum;
          const navigationExtras: NavigationExtras = {
            queryParams: params
          };
          this.router.navigate(['/tonerreturnconfirm'],navigationExtras);

        }else{
            this.msg="Error occurred while creating order."
            this.msgClass="error";
            this.scrollToErrMsg();
        }
        
    });
  
  }else{
    this.msg=errMsg;
    this.msgClass="error";
    this.blockUI.stop();
    this.scrollToErrMsg();
  }

 }

scrollToErrMsg(){
  let elMsg = document.getElementById("msg");
    elMsg.scrollIntoView(true);
}


getManufacturerList(){
 this.mnftrList=[]; 
 this.blockUI.start(); 
 let oParams = {   };

 let data = this.tonerReturnService.getManufacturerList(oParams).subscribe(
   data=>{
      this.mnftrList = data.data; 
       this.blockUI.stop();
   });

}

getReturnReasonList(){
  this.rtnRsnList=[]; 
  this.blockUI.start(); 
  let oParams = {   };
 
  let data = this.tonerReturnService.getReturnReasonList(oParams).subscribe(
    data=>{
       this.rtnRsnList = data.data; 
        this.blockUI.stop();
    });
 
 }

getSupplyItemsList(event:any){
  this.suppItmList=[];
   if(event!=null){
     event.preventDefault();
   }
  this.blockUI.start(); 
  let oParams = { "manufacturer" :this.manufacturer };
 
  let data = this.tonerReturnService.getSupplyItemsList(oParams).subscribe(
    data=>{
       this.suppItmList = data.data; 
        this.blockUI.stop();
    });
 
 }

setAddress(addr:any,event:any){

  if(event!=null){
     event.preventDefault();
  }
  this.getManufacturerList();
  this.bSearchByAddress=true;
  this.bSearchBySerial=false;
  this.partySiteId=addr.partySiteId;
}

clearSrlData(){
  this.tonerReturn=new TonerReturn();
  this.srlList = []; 
  this.totalRecords=0;
  this.numOfRecsMsg="";
  this.arrPgLinks=[];
  this.bCustItmRsn=false;
}


clearAddrData(){
  this.tonerReturn=new TonerReturn();  
  this.addrList = []; 
  this.aTotalRecords=0;
  this.aNumOfRecsMsg="";
  this.aArrPgLinks=[];
  this.bCustItmRsn=false;  
}

 openSrlModel() {  
  this.srlModalRef = this.modalService.show(this.srlTemplate);
  this.bSearchByAddress=false;
  this.bSearchBySerial=true;
  this.getSerialList(1,null); 
 }
closeSrlModel() {
   
  this.srlModalRef.hide(); 
}


openAddrModel() {  
  this.addrModalRef = this.modalService.show(this.addrTemplate);

  this.getAddrList(1,null); 
 }
closeAddrModel() {
 
  this.addrModalRef.hide(); 
}
 

}
