import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UpdateSerialService } from 'src/app/manage-user/update-serial/update-serial.service';
import { Util } from '../../app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-update-serial',
  templateUrl: './update-serial.component.html',
  styleUrls: ['./update-serial.component.css']
})
export class UpdateSerialComponent implements OnInit {

	@BlockUI() blockUI : NgBlockUI;

	msg="";	
	msgClass = "";
	showMsg = false;
	
	recPerPageA=10;
	arrPgLinksA= [];
	recMsgA = "";
	assignedSerialList = [];
	pageNumA=1;
	aSerialsTotal
	selectedASerials = [];
	
	uSerialsTotal;
	serialU = "";
	modelU = "";
	instLocU = "";
	recPerPageU=10;
	pgU="";
	arrPgLinksU = [];
	recMsgU= "";
	unassignedSerialList = [];
	pageNumU=1;
	selectedUSerials = [];
	
	user:any;
	
  constructor(public activeModal: NgbActiveModal, private util: Util, private updateSerialService : UpdateSerialService, private commonService:CommonServiceService) { }

  ngOnInit() {
	console.log("Update Serial Comp init()..");
	this.getAssignedSerialList(1);
	this.getUnassignedSerialList(1);
  }

  closeDialog(){
    this.activeModal.close();
  }

  
  async getAssignedSerialList(pageNum) {
	this.selectedASerials= [];
	this.clearmsg();
	this.showMsg = false;
	
	this.pageNumA = pageNum;
	
	var param = {
		      "pageNum":  pageNum,
		      "pageSize": this.recPerPageA,
		      "buEmail"	: this.user.usr_name,
		    };	
	this.blockUI.start();
	let data = await  this.updateSerialService.getassignedserialsSync(param); 
	if (data != null) {
		console.log("Assigned", data);
		this.assignedSerialList = data.assignedSerials;
		this.aSerialsTotal = data.totalRecords;
		
		this.recMsgA=this.commonService.getNumOfRecsMsg(this.aSerialsTotal,this.recPerPageA,this.pageNumA);
 		this.arrPgLinksA=this.commonService.getPgLinks(this.aSerialsTotal,this.pageNumA,this.recPerPageA);
	}
	this.blockUI.stop();
	
  }

  async getUnassignedSerialList(pageNum) {
	this.selectedUSerials = [];
	this.clearmsg();
	this.showMsg = false;
	
	this.pageNumU = pageNum;
	var param = {
		     "pageNum":  pageNum,
		     "pageSize": this.recPerPageU,
		     "buEmail"	: this.user.usr_name,
			 "instLoc" : this.instLocU,
			 "srlNum" : this.serialU,
			 "model" : this.modelU,
		    };	
	this.blockUI.start();
	let data = await  this.updateSerialService.getunassignedserialsSync(param); 
	if (data != null) {
		console.log("Un-Assigned", data);
		this.unassignedSerialList= data.unassignedSerials;
		this.uSerialsTotal = data.totalRecords;
		
		this.recMsgU=this.commonService.getNumOfRecsMsg(this.uSerialsTotal,this.recPerPageU,this.pageNumU);
 		this.arrPgLinksU=this.commonService.getPgLinks(this.uSerialsTotal,this.pageNumU,this.recPerPageU);
	}
	this.blockUI.stop();
	
	
  }

  searchAssigned(recsPerPage){
	
  }


  updateAssignedSerials(mode) {
	console.log(this.user);
  }

  clearUnassignedSerialSearch() {
	this.serialU = "";
	this.modelU = "";
	this.instLocU = "";

  }

  searchUnassigned() {
	
  }

  

  async assignSerials() {
	// get all selected in unassigned 
	this.clearmsg();
	this.showMsg = false;
	
	if(this.selectedUSerials.length == 0) {
		this.msg="Please select at least one Serial to assign";
		this.msgClass = "alert-danger";
		this.showMsg = true;
		this.scrollToMsg();
		
		return;
	}
	
	
	var updateParams = [];
	
	for(var curSerial of this.selectedUSerials) {
		var element: HTMLInputElement;
		element = document.getElementById("uNote1_" + curSerial) as HTMLInputElement;
		var note1 = element.value;
		element = document.getElementById("uNote2_" + curSerial) as HTMLInputElement;
		var note2 = element.value;
		
		var curSerialInfo = this.unassignedSerialList.filter(item => item.serialNumber == curSerial);
		
		var param = {
				"serialNumber" 	: curSerial,
				"note1"		: note1,
				"note2" 	: note2,
				"instanceId": curSerialInfo[0].instanceId,
				"source" 	: curSerialInfo[0].source,
				};
				
		updateParams.push(param);			
	}
	
	var postParam = {
		"action" 	: "UPDATE",
		"buEmail" 	: this.user.usr_name,
		"srls"		: JSON.stringify(updateParams),
	};
	
	console.log("AssignSerials:", postParam);
	this.blockUI.start();
	let data = await  this.updateSerialService.assignserialsSync(postParam);  
	if (data != null) {
		console.log("AssignedResult", data);
		if(data.flg=="S"){
			this.getAssignedSerialList(1); 
			this.getUnassignedSerialList(1);
			this.msg = "All changes Saved";
			this.msgClass = "alert-success";
		}else{
			this.msg = "Error while saving";
		   	this.msgClass = "alert-danger";
		}	
		this.showMsg = true;
		this.scrollToMsg();
	}
	this.blockUI.stop();
	
  }

  setUSrl(serialElement: any, idx) {
			
		console.log(serialElement.value + ":" + serialElement.checked + ":" + idx);
		if(serialElement.checked) {
			this.selectedUSerials = this.selectedUSerials.filter(item => item != serialElement.value);
			this.selectedUSerials.push(serialElement.value);
		}else {
			this.selectedUSerials = this.selectedUSerials.filter(item => item != serialElement.value);
		}
  }



  async updateSerials(action) {
	// get all selected in unassigned 
	this.clearmsg();
	this.showMsg = false;
	
	if(this.selectedASerials.length == 0) {
		if(action == 'REMOVE') {
			this.msg="Please select at least one Serial to remove";
		} else {
			this.msg="Please select at least one Serial to save";
		}
		
		this.msgClass = "alert-danger";
		this.showMsg = true;
		this.scrollToMsg();
		return;
	}
	
	var updateParams = [];
	
	for(var curSerial of this.selectedASerials) {
		var element: HTMLInputElement;
		element = document.getElementById("aNote1_" + curSerial) as HTMLInputElement;
		var note1 = element.value;
		element = document.getElementById("aNote2_" + curSerial) as HTMLInputElement;
		var note2 = element.value;
		
		var curSerialInfo = this.assignedSerialList.filter(item => item.serialNumber == curSerial);
		
		var param = {
				"serialNumber" 	: curSerial,
				"note1"		: note1,
				"note2" 	: note2,
				"instanceId": curSerialInfo[0].instanceId,
				"source" 	: curSerialInfo[0].source,
				};
				
		updateParams.push(param);			
	}
	
	var postParam = {
		"action" 	: action,
		"buEmail" 	: this.user.usr_name,
		"srls"		: JSON.stringify(updateParams),
	};
	
	console.log("UpdateSerials:", postParam);
	this.blockUI.start();
	let data = await this.updateSerialService.assignserialsSync(postParam);  
	if (data != null) {
		console.log("UpdateSerialResult", data);
		if(data.flg=="S"){
			this.getAssignedSerialList(1); 
			this.getUnassignedSerialList(1);
			this.msg = "All changes Saved";
			this.msgClass = "alert-success";
		}else{
			this.msg = "Error while saving";
		   	this.msgClass = "alert-danger";
		}	
		this.showMsg = true;
		this.scrollToMsg();
	}

	this.blockUI.stop();
  }


  setASrl(serialElement: any, idx) {
			
		console.log(serialElement.value + ":" + serialElement.checked + ":" + idx);
		if(serialElement.checked) {
			this.selectedASerials = this.selectedASerials.filter(item => item != serialElement.value);
			this.selectedASerials.push(serialElement.value);
		}else {
			this.selectedASerials = this.selectedASerials.filter(item => item != serialElement.value);
		}
  }
	
	
  clearmsg() {
	this.msg = "";
	this.msgClass = "";
  }

  setExpandCollapse(idx) {
	//
	var element: HTMLInputElement;
	element = document.getElementById("collapseItem" + idx) as HTMLInputElement;
	if (element.classList.contains('show')) {
      element.classList.remove("show"); 
	}else {
		element.classList.add("show"); 
	}
		
  }
  

  scrollToMsg(){ 
	
	//document.getElementById("msgDiv").scrollTo({top:0,behavior:'smooth'});
	//document.getElementById("msgDiv").scrollTop=0;
	
	/*
	var position = $("msgDiv").position();
	scroll(0,position.top);
	
	
   	 $('html, body').animate({
   	        scrollTop: $("#msgDiv").offset().top-60
   	    }, 100);
	*/
	
  }



}
