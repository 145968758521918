import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class ManageleveldetailsService  {

  baseUrl="";
	
  	constructor(private http: HttpClient, private util : Util) { 
		this.baseUrl = this.util.getBaseUrl();
	}

  getconfigLabelLevelDetails=function(urlData) {
    let url = this.baseUrl + "configuratorAdmin/getLabelLineDetails?"+ urlData;
    console.log("url 111 ===  " + url);
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getConfigUsers"))));
    
  }
  

  createNewEntry = (paramObj) => {
	let url = this.baseUrl + 'configuratorAdmin/addLabelLine?';
	let params = new URLSearchParams();
	for (let key in paramObj) {
		params.set(key, paramObj[key])
	}
	let qryStr = params.toString();
	url = url + qryStr;

	return this.http
		.post(url, qryStr, httpOptions)
		.pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
   
}


updateEntry = (paramObj) => {
	let url = this.baseUrl + 'configuratorAdmin/updateLabelLine?';
	let params = new URLSearchParams();
	for (let key in paramObj) {
		params.set(key, paramObj[key])
	}
	let qryStr = params.toString();
	url = url + qryStr;

	return this.http
		.post(url, qryStr, httpOptions)
		.pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
   
}
	
	getUserLevels(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/getUserLevels?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getUserLevels")))
	  }	

	getUserLevelsSync(data): Promise<any>{
		return this.getUserLevels(data).toPromise();
		
	}

	saveUserLevel(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/saveLevelJson?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("saveUserLevel")))
	  }	

	  deleteUserLevel(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/deleteLabelLine?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("saveUserLevel")))
	  }	

}

