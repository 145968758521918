import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {OtherCanonProductsCartConfirmationService} from './other-canon-products-cartconfirmation.service';

@Component({
  selector: 'app-other-canon-products-cartconfirmation',
  templateUrl: './other-canon-products-cartconfirmation.component.html',
  styleUrls: ['./other-canon-products-cartconfirmation.component.css']
})
export class OtherCanonProductsCartconfirmationComponent implements OnInit {
  orderNumber:any;
  getCartPromise:any;
  cart: any;
  scrollAmount:any;
  direction:any;
  step:any;
  distance: any;
  totalCartItemsCount:any=0;
  grandTotalCount:any=0;
  constructor(private router: Router, private otherCanonProductsCartConfirmationService: OtherCanonProductsCartConfirmationService) { }

  ngOnInit() {
    //$('[data-toggle="tooltip"]').tooltip(); -->this is for html
    this.getOrderNumber();
    //showPleaseWait();
    this.getCartFromDb(this.orderNumber);
    

  }
  nextButtonclick () {
    var container = document.getElementById('itcg-shop-confirmaion-promo-cate-inner');
    sideScroll(container,'right',5,600,10);
 };

 backOnclick = function () {
  var container = document.getElementById('itcg-shop-confirmaion-promo-cate-inner');
  sideScroll(container,'left',5,600,10);
};

  getCartFromDb(orderNumber){
    
		// var getCartPromise = httpService.getData('productsCheckout.htm/gcfd?orderNumber='+orderNumber);
    //  	return getCartPromise;
    this.otherCanonProductsCartConfirmationService.getCartData(orderNumber).subscribe(
      data=>{
        this.getCartPromise=data;
       
        this.cart = data;
        this.totalCartItems();
        this.grandTotal();
        console.log("Printing cart data value..");
        console.log(this.cart);
      }
    );
	}

  getOrderNumber() {
		if (sessionStorage.getItem('itcgOrderNumber') != null) {
			this.orderNumber = JSON.parse(sessionStorage.getItem('itcgOrderNumber'));
		}
		console.log("itcgOrderNumber :", this.orderNumber);
		return this.orderNumber;
	}

//  grandTotalOld(){
// 		var grandTotal = 0;
// 		if(this.cart != null && this.cart.productMap != null && this.cart.productMap != 'undefined')
// 		{
// 			for( var key in this.cart.productMap){
// 				grandTotal =  grandTotal 
// 					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].listPrice) 
// 					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].selectedCarePak.listPrice);
// 			}
// 		}
// 		console.log('Calculated Grand Total ', grandTotal);
//     this.grandTotalCount= grandTotal
// 		return grandTotal;
// 	}

  grandTotal(){
		var grandTotal = 0;
		if(this.cart != null && this.cart.productMap != null && this.cart.productMap != 'undefined')
		{
      console.log(this.cart.productMap);
			for( let key in this.cart.productMap){
        console.log(key);
				grandTotal =  grandTotal 
					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].listPrice) 
					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].selectedCarePak.listPrice);
			}
		}
    this.grandTotalCount= grandTotal
		return grandTotal;
	}



  totalCartItems(){
		var length = 0;
		if(this.cart != null &&  this.cart.productMap != null && this.cart.productMap != 'undefined')
		{
			length = Object.keys(this.cart.productMap).length;
		}
    this.totalCartItemsCount = length;
		return length;
	}

  navigateTo(cat){
		//var url = 'products.htm?view=CanonCpShopProducts&cat='+encodeURIComponent(cat);
		//window.location.href = url;
    let params = {
      "cat": cat 
    }
    this.router.navigate(['othercanonproducts'],{queryParams : params});
	}

 

}
function sideScroll(element,direction,speed,distance,step){
  this.scrollAmount = 0;
  
  
  var slideTimer = setInterval(function(){
      if(direction == 'left'){
          element.scrollLeft -= step;
      } else {
          element.scrollLeft += step;
      }
      
      
      this.scrollAmount += step;
      
    
      if(this.scrollAmount >= distance){
          window.clearInterval(slideTimer);
      }
  }, speed);
}