import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { EncrDecr } from './encrdecr.service';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  defaultUrl:string="";
  constructor(private http: HttpClient, private util : Util, private encrDecr: EncrDecr,) {
    this.defaultUrl = this.util.getBaseUrl();
   }
 
  isSalesUser(){
    let salesUser=false;
    let sFlag:string="";
    sFlag= sessionStorage.getItem('salesTeamFlag');
    if(sFlag){
       if(sFlag=="Y"){
        salesUser=true;
       }
    }
    return salesUser;
  }

  getUserAccounts=function()
  {
    let url = this.defaultUrl + "common/service/gua";
    let params={};
    return this.http
      .post(url, params.toString, httpOptions) 
      .pipe(catchError((this.util.handleError("getUserAccounts"))));
  }

  getSelectedUserAccounts(userAccountList:any){
    let selectedUserAccts="";
     
    try {
       let actArr=[];
       let enabled:boolean=false;
      for( let acct of userAccountList){
        enabled =acct["enabled"];
        if(enabled){
          actArr.push(acct["partyId"]);
        }
      }
      selectedUserAccts=actArr.join(",");
    } catch (error) {
      
    }
    return selectedUserAccts;
  }
 
  getNumOfRecsMsg(totalRecords:any,recPerPage:any,pgNum:any){
    let FN="[getNumOfRecsMsg]";
    let start:Number;
    let msg="";

    try {
     
     start = (recPerPage * (pgNum - 1)) + 1;
		     let end = (recPerPage * pgNum);
			     if (end > totalRecords) {
				       end = totalRecords;
			      }
			     if (totalRecords > 0)
				      msg = "Showing " + start + " to " +end + " of " +totalRecords +" records.";
      } catch (error) {
        console.log(FN+"ERROR:"+error);
        msg="";
      }
      return msg;
  }
   
  



  getPgLinks (totalRecords:any,pageNum:any,recPerPage:any){
    let arrPgLinks = [];
    let FN="[getPgLinks]";
  try{
   
    let tr = totalRecords;
    let rpp = parseInt( recPerPage);
    if (tr > rpp) {
      let tl = tr / rpp;
      let nop = (tr % rpp == 0) ? parseInt(tl+"") : parseInt(tl+"") + 1;

      let t2 = nop;
      let t1=1;
      if (nop > 10){
        
        let pgNum=pageNum;
         
        if(pgNum<10){
          t1=pgNum;
          t2=pgNum+9;
        }else{
          t1=pgNum-4;
          t2=pgNum+5;
        }
        
        for (let i = t1; i <= t2; i++) {
              if(i<nop){
                  arrPgLinks.push(i);
                        }
          }
        
       }else{
        for (let i = t1; i <= t2; i++) {
           arrPgLinks.push(i);
          }
      }
     }
 
    } catch (error) {
      console.log(FN+"ERROR:"+error);
       
    }
    return arrPgLinks;
}

exportToExcel(dwnldUrl:string) {
  
  var link = this.util.getBaseUrl() + dwnldUrl;
  location.href=link;
}


toggleSearchSection(){

  $("#page-search-form-minimize-button,#search-aside-tab-button").on("click", function(event) {
    $('#page-search-aside').toggleClass("hidden");
    $('#page-search-result-aside').toggleClass("aside-search-result-default-full");
    $('#search-aside-tab-main').toggleClass("shown"); 
  });
  $("#page-search-form-minimize-button").trigger("click");
/*
  $('#page-search-form-minimize-button,#search-aside-tab-button').click(function () {
    $('#page-search-aside').toggleClass("hidden");
    $('#page-search-result-aside').toggleClass("aside-search-result-default-full");
    $('#search-aside-tab-main').toggleClass("shown"); 
         if ($(".ui-multiselect").length){
    $(".ui-multiselect-checkboxes").css({
        'width': ($(".ui-multiselect").width() + 'px')
      });
  }  
  
});
*/
}

keepSearchSectionOpen(){

  $("#page-search-form-minimize-button,#search-aside-tab-button").on("click", function(event) {
    $('#page-search-aside').toggleClass("hidden");
    $('#page-search-result-aside').toggleClass("aside-search-result-default-full");
    $('#search-aside-tab-main').toggleClass("shown"); 
  });
}

setMoreInfo(){
   $(".page-more-icon").hover(function () {
      $(this).next().stop().fadeIn();
    }, function () {
        $(this).next().stop().fadeOut();
    });
 
   if ($('div.page-more-icon').length) {	
		$(".page-more-icon .fa-info-circle ").trigger("mouseenter");
	   setTimeout(function (){$(".page-more-icon .fa-info-circle").trigger("mouseleave")},7000);
	}

 }

 expandCollapseAll(){

  $('#showAll-panel').click(function (event) {
    $('.result-item-more-info-container').addClass("show");
  });
  $('#closeAll-panel').click(function (event) {
      $('.result-item-more-info-container').removeClass("show");
  });
} 

  
setCommonFunctions(){
  this.setMoreInfo(); 
  this.expandCollapseAll();
  this.toggleSearchSection();
}

sortView(){
  $('#grid-view-button').click(function (event) {
    $('#order-supplies-gridview-main').addClass("order-supplies-gridview");
});
$('#line-view-button').click(function (event) {
    $('#order-supplies-gridview-main').removeClass("order-supplies-gridview");
});
}

camel2title(camelCase) {
  return camelCase
   // inject space before the upper case letters
   .replace(/([A-Z])/g, function(match) {
      return " " + match;
   })
   // replace first char with upper case
   .replace(/^./, function(match) {
     return match.toUpperCase();
   });
}

jsonToExcel(JSONData, outputFileName, ShowLabel,bCustom,customLblsArr) {
  console.log("Printing inside jsonToExcel method: ",JSONData," , ", outputFileName," , ", ShowLabel," , ",bCustom," , ",customLblsArr);
  console.log(typeof JSONData);
        
       //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
       var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
       var CSV = '';    
       //Set Report title in first row or line
       //CSV += ReportTitle + '\r\n\n';
       
        
       var lbls;
       if(bCustom){
           lbls=customLblsArr;
       }else{
           lbls=arrData[0];
       }
       
       //This condition will generate the Label/Header
       if (ShowLabel) {
           var row = "";
           //This loop will extract the label from 1st index of on array
           if(bCustom){
               for (var index in lbls) {
                   //Now convert each value to string and comma-seprated
                   var lbl=lbls[index];
                   lbl=this.camel2title(lbl);  
                   row += lbl + ',';
               }
           }else{ 
               for (var index in arrData[0]) {
                   //Now convert each value to string and comma-seprated
                   index=this.camel2title(index);
                   row += index + ',';
               }
           }
           row = row.slice(0, -1);
           //append Label row with line break
          /* if(row.indexOf("Dealer Dba")>-1){
             row=row.replace("Dealer Dba","Dealer DBA");
           }
           */
           CSV += row + '\r\n';
       }
       
       if(bCustom){
           
               //1st loop is to extract each row
               for (var i = 0; i < arrData.length; i++) {
                   var row = "";
               //2nd loop will extract each column and convert it in string comma-seprated
                    var oData=arrData[i];
                   
                   for(const k in lbls){  
                       
                       //Skip "" when '=' is appended for preserving leading zeros
                       var arrDataVal = oData[lbls[k]];
                       //console.log(arrDataVal);
                       //check for null and assign "" string
                       if(null == arrDataVal)
                           arrDataVal = "";
                       if(!isNaN(arrDataVal))
                           arrDataVal = arrDataVal.toString();
                       if(arrDataVal.startsWith('=')){
                           row += arrDataVal + ',';
                           continue;
                       }
                        
                       row += '"' + arrDataVal + '",';
                    
               }
                   row.slice(0, row.length - 1);
                   //add a line break after each row
                   CSV += row + '\r\n';
           }
           
       }else{
       //1st loop is to extract each row
       for (var i = 0; i < arrData.length; i++) {
           var row = "";
           //2nd loop will extract each column and convert it in string comma-seprated
           for (var index in arrData[i]) {
               console.log("Printing index & i values : ",index, " ," + i)
               //Skip "" when '=' is appended for preserving leading zeros
               var arrDataVal = (arrData[i][index]);
               //check for null and assign "" string
               if(null == arrDataVal)
                   arrDataVal = "";
               if(!isNaN(arrDataVal))
                   arrDataVal = arrDataVal.toString();
               if(arrDataVal.toString().startsWith('=') ){
                   row += arrDataVal + ',';
                   continue;
               }
               row += '"' + arrDataVal + '",';
           }
           row.slice(0, row.length - 1);
           //add a line break after each row
           CSV += row + '\r\n';
       } 
       
       }
       if (CSV == '') {        
           alert("Invalid data");
           return;
       }
       
      //this trick will generate a temp "a" tag
       var link = document.createElement("a");    
       link.id="lnkDwnldLnk";

       //this part will append the anchor tag and remove it after automatic click
       document.body.appendChild(link);

       var csv = CSV;  
       var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
       
       //Generate a file name
       var fileName =outputFileName;   
       //this will remove the blank-spaces from the title and replace it with an underscore
      // fileName += outputFileName.replace(/ /g," ");
    /*
       if (navigator.msSaveBlob) { // IE 10+
           navigator.msSaveBlob(blob, fileName + ".csv")
               } else {
                   var link = document.createElement("a");
                   if (link.download !== undefined) { // feature detection
                       // Browsers that support HTML5 download attribute
                       var csvUrl = window.URL.createObjectURL(blob);
                       link.setAttribute("href", csvUrl);
                       link.setAttribute("download", fileName + ".csv");
                       link.style.display = "visibility:hidden";
                       document.body.appendChild(link);
                       link.click();
                       document.body.removeChild(link);
                   }           
               }
          */   
         
          var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var csvUrl = window.URL.createObjectURL(blob);
                  link.setAttribute("href", csvUrl);
                  link.setAttribute("download", fileName + ".csv");
                  link.style.display = "visibility:hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }   
   }


  getStates() {
		var states = [
		              	 {name:'Select State', value: ''},
		                 {name:'Alabama', value: 'AL'},
		                 {name:'Alaska', value: 'AK'},
		                 {name:'Arizona', value: 'AZ'},
		                 {name:'Arkansas', value: 'AR'},
	                	 {name:'California', value: 'CA'},
	                	 {name:'Colorado', value: 'CO'},
	                	 {name:'Connecticut', value: 'CT'},
	                	 {name:'Delaware', value: 'DE'},
	                	 {name:'District Of Columbia', value: 'DC'},
	                	 {name:'Florida', value: 'FL'},
	                	 {name:'Georgia', value: 'GA'},
	                	 {name:'Hawaii', value: 'HI'},
	                	 {name:'Idaho', value: 'ID'},
	                	 {name:'Illinois', value: 'IL'},
	                	 {name:'Indiana', value: 'IN'},
	                	 {name:'Iowa', value: 'IA'},
	                	 {name:'Kansas', value: 'KS'},
	                	 {name:'Kentucky', value: 'KY'},
	                	 {name:'Louisiana', value: 'LA'},
	                	 {name:'Maine', value: 'ME'},
	                	 {name:'Maryland', value: 'MD'},
	                	 {name:'Massachusetts', value: 'MA'},
	                	 {name:'Michigan', value: 'MI'},
	                	 {name:'Minnesota', value: 'MN'},
	                	 {name:'Mississippi', value: 'MS'},
	                	 {name:'Missouri', value: 'MO'},
	                	 {name:'Montana', value: 'MT'},
	                	 {name:'Nebraska', value: 'NE'},
	                	 {name:'Nevada', value: 'NV'},
	                	 {name:'New Hampshire', value: 'NH'},
	                	 {name:'New Jersey', value: 'NJ'},
	                	 {name:'New Mexico', value: 'NM'},
	                	 {name:'New York', value: 'NY'},
	                	 {name:'North Carolina', value: 'NC'},
	                	 {name:'North Dakota', value: 'ND'},
	                	 {name:'Ohio', value: 'OH'},
	                	 {name:'Oklahoma', value: 'OK'},
	                	 {name:'Oregon', value: 'OR'},
	                	 {name:'Pennsylvania', value: 'PA'},
	                	 {name:'Rhode Island', value: 'RI'},
	                	 {name:'South Carolina', value: 'SC'},
	                	 {name:'South Dakota', value: 'SD'},
	                	 {name:'Tennessee', value: 'TN'},
	                	 {name:'Texas', value: 'TX'},
	                	 {name:'Utah', value: 'UT'},
	                	 {name:'Vermont', value: 'VT'},
	                	 {name:'Virginia', value: 'VA'},
	                	 {name:'Washington', value: 'WA'},
	                	 {name:'West Virginia', value: 'WV'},
	                	 {name:'Wisconsin', value: 'WI'},
	                	 {name:'Wyoming', value: 'WY'}
		               ];
    console.log("Printing states...");                
		console.log(states);
		return states;
	}


  getAutoSleepTime() {
		var autoSleepTime = [
		              	 {name:'Select Time', value: ''},
		                 {name:'1 min', value: '1min'},
		                 {name:'2 mins', value: '2mins'},
		                 {name:'5 mins', value: '5mins'},
		                 {name:'10 mins', value: '10mins'},
	                	 {name:'15 mins', value: '15mins'},
	                	 {name:'20 mins', value: '20mins'},
	                	 {name:'30 mins', value: '30mins'},
	                	 {name:'40 mins', value: '40mins'},
	                	 {name:'50 mins', value: '50mins'},
	                	 {name:'1 hour', value: '1hour'},
	                	 {name:'1.5 hours', value: '1.5hours'},
	                	 {name:'2 hours', value: '2hours'},
	                	 {name:'3 hours', value: '3hours'},
	                	 {name:'4 hours', value: '4hours'}	                	 
		               ];
		return autoSleepTime;
	}


  getAutoResetTime() {
		var autoResetTime = [
		              	 {name:'Select Time', value: ''},
		                 {name:'0sec', value: '0sec'},
		                 {name:'10 sec', value: '10secs'},
		                 {name:'20 sec', value: '20secs'},
		                 {name:'30 sec', value: '30secs'},
		                 {name:'40 sec', value: '40secs'},
		                 {name:'50 sec', value: '50secs'},
		                 {name:'60 sec', value: '60secs'},
	                	 {name:'2 mins', value: '2mins'},
	                	 {name:'3 mins', value: '3mins'},
	                	 {name:'4 mins', value: '4mins'},
	                	 {name:'5 mins', value: '5mins'},
	                	 {name:'6 mins', value: '6mins'},
	                	 {name:'7 mins', value: '7mins'},
	                	 {name:'8 mins', value: '8mins'},
	                	 {name:'9 mins', value: '9mins'}	                	 
	                	                 	 
		               ];
		return autoResetTime;
	}

  eLearningAuthentication(){
    var today = new Date();
    var secret = today.getFullYear() + '' + this.addZero(today.getMonth() + 1) + '' + this.addZero(today.getDate()) + '' + this.addZero(today.getHours()) + '' + this.addZero(today.getMinutes()) + '' + this.addZero(today.getSeconds());
    var url = window.location.hostname;

    var encryptedVal = this.encrDecr.set('123456$#@$^@1ERF', '123456$#@$^@1ERF', secret);

    var eLearnURL = "https://elearning.cusa.canon.com?host=" + url + "?key=" + encryptedVal;
    console.log("eLearnURL:" + eLearnURL);
    window.open(eLearnURL);

  }

  addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }

}
