import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpJsonOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  defaultUrl: string;

  constructor(private http: HttpClient, private router: Router, private util: Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }
  
  getWhatsNewLinks(): Observable<any> {
    let url = this.defaultUrl + 'common/service/whatsNew';
    console.log("getWhatsNewLinks::" + url);
    return this.http.get(url, httpJsonOptions).pipe(
      map(data => {
        return data;
      }));
  }

}


