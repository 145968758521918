import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { AssignDevicesService } from './assign-devices.service';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-assign-devices',
  templateUrl: './assign-devices.component.html',
  styleUrls: ['./assign-devices.component.css']
})
export class AssignDevicesComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;


  assignDeviceList:any=[];
  modelList:any=[];
  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[];

  serialNumber:string="";
  model:string="";
  location:string="";
  selectedUserAccts:string="";

  selectAll:boolean=false;
   
  userAccountList=[];
  srlList=[];
  showMsg = false;
  msg = "";
  msgClass = "alert-danger";


  constructor( private commonService:CommonServiceService,private assignDevicesService:AssignDevicesService, private util : Util, private router : Router,private titleService: Title) { }

  ngOnInit() {

    this.titleService.setTitle("Assign Devices"); 
    this.getAssignDevicesList(1,null);
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });
    this.commonService.setCommonFunctions();  
  }

  

  getAssignDevicesList(pgNum: any,event: any){
    if(event!=null){
     event.preventDefault();
   }
   this.blockUI.start(); 
   this.clearMsg();
      this.pageNum=pgNum;
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   console.log("pageNum :"+pgNum);
   console.log("recPerPage :"+this.recPerPage);
   let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
   "serialNumber":this.serialNumber,"model":this.model,"location":this.location,"selectedUserAccts":this.selectedUserAccts };
   let data = this.assignDevicesService.getAssignDevicesList(oParams).subscribe(
     data=>{
       this.assignDeviceList = data.devicesList; 
        if(this.assignDeviceList.length<1){
            this.showMsg = true;
	 					this.msg = "Validate if serial is assigned on My Devices. If not, proceed to Request Devices.";
						this.msgClass = "alert-danger";
        }
        
        if(this.srlList.length>0 && this.assignDeviceList.length>0){

         for(let j=0;j<this.srlList.length;j++){  
             for(let k=0;k<this.assignDeviceList.length;k++){
                if(this.assignDeviceList[k]["serialNumber"]==this.srlList[j]["serialNumber"]){
                  this.assignDeviceList[k]["isChecked"]=true;
                }
             }
           }

        }

        this.totalRecords=data.totalRecords;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
        this.blockUI.stop();
     });
 
 }

 clearSearch (event:any){
  if(event){
    event.preventDefault();
  }
  this.serialNumber="";
  this.model="";
  this.location="";
  this.recPerPage=10;
  this.selectAll=false;

  for(let actIdx in this.userAccountList){
    this.userAccountList[actIdx]["enabled"]=true;
  }
  this.getAssignDevicesList(1,null);
 }
 checkAll(e:any){
   this.srlList=[];
  for (let k in this.assignDeviceList) {
    this.assignDeviceList[k]["isChecked"]=e.target.checked;
    if(e.target.checked){
    this.srlList.push(this.assignDeviceList[k]);
    }
   }
  
 }
 loadMyDevices(){
  this.router.navigateByUrl('/mydevices');
 } 

 checkSrl(event:any,srl:any){
  if(event!=null){
    event.preventDefault();
  }

  let sNum=srl.serialNumber;
  let bChk=srl.isChecked;
  if(this.srlList.length>0){
     
     let bSrlExist=false;
     let cnt=-1;
     for(let obj of this.srlList){
      cnt++;
        if(sNum==obj["serialNumber"]){
          bSrlExist=true;
          if(!bChk){
            this.srlList.splice(cnt, 1);
          }else{
             // in case of notes change
            this.srlList.splice(cnt, 1);
            this.srlList.push(srl);
          }
           break;
        }
     }  

    if(!bSrlExist){
       if(bChk){
        this.srlList.push(srl);
       }
    } 
     

  }else{
    if(bChk){
      this.srlList.push(srl);
    }
  }
  console.log(this.srlList);
  console.log(srl.isChecked);
 } 


 saveAssignedDevices(){
  this.clearMsg();
  let objArr = [];
  for (let k in this.srlList) {
          let objTemp = this.srlList[k];
          if (objTemp.isChecked) {
            let obj = {};
            obj["serialNumber"] = objTemp.serialNumber;
            obj["note1"] = objTemp.note1;
            obj["note2"] = objTemp.note2;
            obj["instanceId"] = objTemp.instanceId;
            obj["source"] = objTemp.source;
  
            objArr.push(obj);
          }
  }
  if (objArr.length < 1) {
    this.showMsg = true;
    this.msg = "Please select at least one Serial to save";
    this.msgClass = "alert-danger";
  } else {
     this.blockUI.start();
     let data=this.assignDevicesService.saveAssignedDevices(objArr).subscribe(
         data=>{
           this.blockUI.stop();
           this.router.navigate(["mydevices"]);
         }
     );
  } 
    

 }

 clearMsg() {
  this.showMsg = false;
  this.msg = "";
  this.msgClass = "";
   
}

  exportToExcel(){
    let url="mydevices/dadl";
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    let qryStr="serialNumber="+this.serialNumber+"&model="+this.model+"&location="+this.location+"&selectedUserAccts="+this.selectedUserAccts ;
    url=url+"?"+qryStr;
    this.commonService.exportToExcel(url);
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
