import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ProductExclusionService {

  	baseUrl="";
	
  	constructor(private http: HttpClient, private util : Util) { 
		this.baseUrl = this.util.getBaseUrl();
	}
	
	getLevelsByParty(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/getLevelsByParty?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getLevelsByParty")))
	  }	

	getLevelsByPartySync(data): Promise<any>{
		return this.getLevelsByParty(data).toPromise();
		
	}	
	
	getPriceListLOV(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/getPriceListLOV?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getPriceListLOV")))
	  }	
	

	getPriceBookLabelList(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/getPriceBookLabelList?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getPriceBookLabelList")))
	  }	

	upsertPricebookDetails(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/upsertPriceBookDetails?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("upsertPricebookDetails")))
	  }	

	getBundleDetails(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "configuratorAdmin/getBundleDetails?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getBundleDetails")))
	  }


	
}
