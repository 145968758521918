import { Component, OnInit} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import {ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-configurator-createquote',
  templateUrl: './configurator-createquote.component.html',
  styleUrls: ['./configurator-createquote.component.css','../configurator-myquotes/configurator-myquotes.component.css'],

})
export class ConfiguratorCreatequoteComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  models: any =[];
  totalRecords=0;
  pageNum=1;
  recPerPage=10;
  start:number=0;
  end:number=10;
  sDate="";
  qName="";
  qStatus="";
  qStartDate="";
  qEndDate="";
  length=1000;
  labelLineId= "-1";
  imgUrl="https://mycanonbusiness.usa.canon.com/cpImages/config/"
  showModelLabel= false;
  showNoModelLabel= false;
   myBuild = {};
  quoteId: number = null;
  showLoader: boolean = false;
  bundle: any =[];
  configId: number =-1;
   objectId = "-1";
  levelDropDown: any[] = [];
  showDropdownBtn: boolean;
  showDropdownLabel: boolean;
  DropdownLabel: string;
  type ='default';

  constructor(private configquoteService:ConfiguratorQuotesService,private router: Router,private route : ActivatedRoute,private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Models");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
      
    });
    sessionStorage.setItem('myBuild',JSON.stringify(this.myBuild));
     //If we are creating a new quote do not get the active quote.
     if(this.quoteId != -1 ){
      this.getUserActiveQuote();
      }
      else{
        this.getDropdown(this.type, this.objectId); 
      }
  }
   getDropdown(type, objectId) {
    let oParams = {"objectId":this.objectId};
    var dropDownPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getLevels').subscribe(
      result=>{
            console.log('getDropdown', result);
            this.levelDropDown = result.data;
            if (this.levelDropDown.length == 0) {
                this.showDropdownBtn = false;
                this.showDropdownLabel = false;
                this.DropdownLabel="";
            }
            else{
            this.DropdownLabel=result.data.data[0]["labelName"];
            }
        console.log(" this.showDropdownBtn:"+ this.showDropdownBtn);
        console.log("  this.showDropdownLabel:"+  this.showDropdownLabel);
            this.getModelList(type, objectId);
        },
        function(reason) {
            console.log(reason);
        });
}
refreshDropdown(opt) {
  this.getDropdown(opt.name, opt.id);
}


  getModelList(type, objectId){
   
    this.blockUI.start();
    this.showNoModelLabel = false;
    this.showModelLabel = false;
    let oParams = {"labelLineId":objectId};
    let data = this.configquoteService.configQuoteGenericMtd(oParams,'getModelList').subscribe(
      data=>{        
         this.totalRecords=data.iTotalRecords; 
         this.blockUI.stop();     
         if(this.totalRecords == 0) {
          this.showNoModelLabel = true;
          }
        else
        {
          this.models = data.data;     
          this.showModelLabel = true;
         // console.log("models:"+this.models);   
         for (let i = 0; i < this.models.length; i++) {
          if (this.models[i].feature1) {
            this.models[i].feature1 = this.models[i].feature1.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
    
          if (this.models[i].feature2) {
            this.models[i].feature2 = this.models[i].feature2.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
    
          if (this.models[i].feature3) {
            this.models[i].feature3 = this.models[i].feature3.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
    
          this.models[i].quickDescription = false;
          this.models[i].imgSrc = this.imgUrl + this.models[i].model + ".jpg";
          this.models[i].configQty = 1;
          // getImage(this.models[i].image, i);
        }
      }
        }
    );
}
selectModel(selectedModel,event: Event)
{
  selectedModel.quoteId = this.quoteId === null ? this.quoteId : this.quoteId;
  selectedModel.configId = '-1';
  selectedModel.quoteName = '';
  selectedModel.userQuoteName = '';
  selectedModel.status = '';
  selectedModel.lease = '';
    sessionStorage.setItem('myBuild', JSON.stringify(selectedModel));
   if(event){
     event.preventDefault();
   }
   let oParams = {"quoteId":selectedModel.quoteId,"configId":selectedModel.configId};
   const navigationExtras: NavigationExtras = {
     queryParams: oParams
 
   };
   this.router.navigate(['/buildquote'],navigationExtras); 
}

 getUserActiveQuote() {
  let oParams = {};
  let data = this.configquoteService.configQuoteGenericMtd(oParams,'getActiveQuote').subscribe(
    data=>{ 
      this.quoteId = data.quoteId; 
      this.configId =-1;
      this.getDropdown(this.type, this.objectId);      
    }
  );
}

bundleModal(bundle,event: Event) {
  event.preventDefault();
  this.bundle= bundle;
}

}


