import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../../app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class UpdateSerialService {

	baseUrl: string;
	
  	constructor(private http: HttpClient, private util: Util) {
        this.baseUrl = this.util.getBaseUrl();
    }

	getunassignedserials(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "manageuser/getunassignedserials?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getunassignedserials")))
	  }	

	getunassignedserialsSync(data): Promise<any>{
		return this.getunassignedserials(data).toPromise();
	}


	getassignedserials(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "manageuser/getassignedserials?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getassignedserials")))
	  }		

	getassignedserialsSync(data): Promise<any>{
		return this.getassignedserials(data).toPromise();
	}

	assignserials(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "manageuser/updateassignedserials?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("assignedserials")))
	  }		

	assignserialsSync(data): Promise<any>{
		return this.assignserials(data).toPromise();
	}


}
