import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { ActivatedRoute,NavigationExtras, Router} from '@angular/router';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { MyDevicesService } from './my-devices.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ContractSuppliesService } from 'src/app/product-supplies/contract-supplies.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-devices',
  templateUrl: './my-devices.component.html',
  styleUrls: ['./my-devices.component.css']
})
export class MyDevicesComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  @ViewChild('csModal') csTemplate:TemplateRef<any>;
  csModalRef: BsModalRef
   

  myDeviceList:any=[];
  modelList:any=[];
  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[];

  serialNumber:string="";
  model:string="";
  location:string="";
  selectedUserAccts:string="";

  userAccountList=[];
  menuAccess={};
  csPurchaseErrMsg:string="";
  isAdmin:boolean=false;
  
 
   
  constructor( private commonService:CommonServiceService,private myDevicesService:MyDevicesService,private csService: ContractSuppliesService, private util : Util,private router: Router,private modalService: BsModalService,private titleService: Title) { }

  ngOnInit( ) {
   this.titleService.setTitle("My Devices");
   this.getModelList();
   this.checkMenuAccess();
   this.getMyDevicesList(1,null);
    
   let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });
    this.commonService.setCommonFunctions();

console.log("Printing username: ", sessionStorage.getItem('userName'));
    this.isAdmin = sessionStorage.getItem('userName')=='WEBADMIN';
    console.log("Printing isAdmin flag: ", this.isAdmin)
   }

  
  getMyDevicesList(pgNum: any,event: any){
     if(event!=null){
      event.preventDefault();
    }
    this.blockUI.start();
    this.pageNum=pgNum;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("pageNum :"+pgNum);
    console.log("recPerPage :"+this.recPerPage);
    let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
    "serialNumber":this.serialNumber,"model":this.model,"location":this.location,"selectedUserAccts":this.selectedUserAccts };
    let data = this.myDevicesService.getMyDevicesList(oParams).subscribe(
      data=>{
         this.myDeviceList = data.myDevicesList; 
         this.totalRecords=data.totalRecords;
         this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
         this.blockUI.stop();
      });
  
  }

  getModelList(){
    
     let data = this.myDevicesService.getModelList().subscribe(
      data=>{
        this.modelList = data.modelList;
        this.model="";
        //console.log(this.modelList.length);
      });
  
  }

checkMenuAccess(){
  this.myDevicesService.checkMenuAccess().subscribe(
     data=>{
       this.menuAccess=data;
  });
}

  updateNotes(srl:any){
   var alertMessageBox = window.confirm("Do you want to update?");
   if(alertMessageBox){
    this.blockUI.start();
     let oParams = {"sNumber":srl["serialNumber"],"note1": srl["note1"] , 
     "note2": srl["note2"] , "instanceId": srl["instanceId"] ,"source": srl["source"] ,"process":"UPDATE" };
     this.myDevicesService.updateDevices(oParams).subscribe(
      data=>{
        this.blockUI.stop();
          let d=data.data;
      });
   }else{
    return;
   }
  
  }

  removeDevice(srl:any,event:any){
    if(event!=null){
      event.preventDefault();
    }
 
     
    var alertMessageBox = window.confirm("You have requested to remove a device from My Devices.\n Please note, this does not remove the device from contract. \n Click OK to continue or Cancel to discard changes");
    if(alertMessageBox){
      this.blockUI.start();
      let oParams = {"sNumber":srl["serialNumber"],"note1": srl["note1"] , 
      "note2": srl["note2"] , "instanceId": srl["instanceId"] ,"source": srl["source"] ,"process":"REMOVE" };
      this.myDevicesService.updateDevices(oParams).subscribe(
       data=>{
        this.blockUI.stop();
           let d=data.data;
           this.getMyDevicesList(1,null);
       });
    }else{
     return;
    }
   
   }
   
  clearSearch(event:any){
    
    if(event){
      event.preventDefault();
    }
    this.serialNumber="";
    this.model="";
    this.location="";
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
    this.getMyDevicesList(1,null);
  }

  exportToExcel(){
   let url="mydevices/donwloadExcel";
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   let qryStr="serialNumber="+this.serialNumber+"&model="+this.model+"&location="+this.location+"&selectedUserAccts="+this.selectedUserAccts ;
   url=url+"?"+qryStr;
   this.commonService.exportToExcel(url);
 }

   getFloorMapDetails(srl){
	  
		 /*
    var sUrl = 'mydevices.htm?mthd=gfm&serialNumber='+srl;
     $.ajax( {
        url : sUrl,  
        cache : false,
        type : "POST",
         async:false,
        success : function(res) {
           $("#divFM").html(res);
            } 
         }); 
  */
  }
  isPurchaseable (objSrl,event){
    if(event){
      event.preventDefault();
    }
    this.blockUI.start();
    this.csPurchaseErrMsg="";
    let oParams = { 
       "serialNumber":objSrl.serialNumber,
       "contractNumber":objSrl.contractNumber
     };
      this.myDevicesService.isCSPurchaseable(oParams).subscribe(
       data=>{
        this.blockUI.stop();
          let status=data["status"];
          let message=data["message"];
          if(status=="S"){
            this.displaySupplyListing(objSrl);
          }else{
            this.csPurchaseErrMsg=message;
            this.openCSModel();
          }
       }
       )
    
  }

  dispalyMtrRds(srl,event){
    if(event){
      event.preventDefault();
    }
    this.router.navigateByUrl('/submitmeterreads?srl='+srl);
  }
  dispalyServiceRequest(srl,event){
    if(event){
      event.preventDefault();
    }
    this.router.navigateByUrl('/servicerequest?srl='+srl);
  }


  displaySupplyListing(objSrl){
    this.blockUI.start();
   let oParams = { 
    "pageNum": 1, 
    "recPerPage": 10, 
    "serialNumber":objSrl.serialNumber,
    "model":"",
    "location":"",
    "selectedUserAccts":"",
    "searchAll":""  
   };

  this.csService.getDeviceListing(oParams).subscribe(
      data => {
        let d = data.data[0];  
          this.blockUI.stop();
          let params = {
            "serialNum": d.serialNo,  
            "contractId": d.contractId, 
            "contractNum": d.contractNumber, 
            "sectionId": d.sectionid, 
            "instanceId": d.instanceId, 
            "model": d.model, 
            "source": d.source, 
            "address": d.address, 
          }
          this.router.navigate(['osmeterreads'], {queryParams : params});
      }
    )
    
    
 }
 openCSModel() {
  this.csModalRef = this.modalService.show(this.csTemplate);
}
closeCSModel() {
  this.csModalRef.hide();
}

 
 


  displayAccDtls(param,event)
  {
    param['fromPage']='myDevices';
    const navigationExtras: NavigationExtras = {
      queryParams: param
    };
   if(event){
     event.preventDefault();
   }
   this.router.navigate(['/devicedetails'],navigationExtras);
    
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

 }
 
