import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class CfsInvoiceInquiryService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
  getInvoiceList=function(oParams)
  {
      let url = this.defaultUrl + "cfsInvoice/cfsInvoiceRequest";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getInvoiceList"))));
    }

   getStatusList=function()
    {
      let url = this.defaultUrl + "cfsInvoice/cfsInvoiceStatus";
      let params={};
      return this.http
        .get(url, params.toString, httpOptions)
        .pipe(catchError((this.util.handleError("getStatusList"))));
    }

    downloadPdf=function(oParams)
    {
        let url = this.defaultUrl + "cfsInvoice/downloadInvPdf";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
        
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("downloadPdf"))));
    }
}
