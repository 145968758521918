import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OtherCanonProductsCheckoutService } from './other-canon-products-checkout.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-other-canon-products-checkout',
  templateUrl: './other-canon-products-checkout.component.html',
  styleUrls: ['./other-canon-products-checkout.component.css']
})
export class OtherCanonProductsCheckoutComponent implements OnInit {

  cart: any;
  existingShippingAddress:boolean = false;
	newShippingAddress:boolean = false;
	existingBillingAddress:boolean = false;
	newBillingAddress:boolean = false;
	isBillingChecked:boolean = true;
	//states = checkoutService.getStates();
	shippingDetails:any = {};
	billingDetails:any = {};
	errMsg:any = '';  
  showLoader: boolean;
  selectedShipAddress:any="";
  selectedBillAddress:any="";
  checkoutForm: FormGroup;
  shippingPromise: any;
  existingShipAddressList:any;
  existingBillAddressList:any;
  page:any=1;
  checkoutFormSubmitted=false;
  totalCartCount:any=0;
  grantTotalCont:any=0;
  billingAddressCheckbox:any;
  constructor(private router: Router, private otherCanonProductsCheckoutService:OtherCanonProductsCheckoutService) { }

  ngOnInit() {
    

    this.getCart();
    this.totalCartItems();
    this.grandTotal();
    


  }

  handlePageChange(event) {
    this.page = event;
  }

	getCart() {
		if (localStorage.getItem('itcgCart') != null) {
			this.cart = JSON.parse(localStorage.getItem('itcgCart'));
		}
		return this.cart;
	}

  shopBillingAddressCheckbox(){
    $("#itcg-shop-billing-address-wrapper").toggle();
    	
    if(this.billingAddressCheckbox == 1){
      $(".itcg-shop-checkout-info-container-wrapper").removeClass("width-100");
      $(".itcg-shop-checkout-back,.itcg-shop-checkout-submit").removeClass("button-constrain");
      $(".itcg-shop-checkout-info-summary-container-wrapper").removeClass("width-100");
      this.billingAddressCheckbox = 0;
      
    }else{

      $(".itcg-shop-checkout-info-container-wrapper").addClass("width-100");
      $(".itcg-shop-checkout-back,.itcg-shop-checkout-submit").addClass("button-constrain");
      $(".itcg-shop-checkout-info-summary-container-wrapper").addClass("width-100");
      this.billingAddressCheckbox = 1;
    }
  }

  shopcheckoutpobutton(){
    $("#itcg-shop-checkout-po-input").show();
  }

 showExistingShipAddrList(){
		this.newShippingAddress = false;
		this.existingShippingAddress = true;
		//showPleaseWait();
		//$("#existingShipAddrListModal").modal('show');  --> //$("#product-box-1").modal('show'); // Use it in HTML with data-target like this: <a href="#" id="deleteOrd" type="button" class="btn btn-secondary" data-toggle="modal" data-target="#emptyCartModal">Empty Cart</a>
		this.showLoader = true;
		this.getShippingLocations();
		
	}

  getShippingLocations(){
    // var shippingPromise = httpService.getData('productsCheckout.htm/gsal'); // This requires Backend implementation of "CanonCpProductsCheckoutController.java" in myCSA4.
    // console.log(shippingPromise);
    //    return shippingPromise;
    this.otherCanonProductsCheckoutService.getShipping().subscribe(
      data=>{
        var shippingPromise = data;
        this.existingShipAddressList = data;
					//hidePleaseWait();
					this.showLoader = false;
        
      });
    }

  
  selectShipAddr (address){
    //if(address != "" && address != null && address != 'undefined'){
      this.shippingDetails = address;
      this.selectedShipAddress = address.displayAddr;
      //$('#existingShipAddrListModal').modal('hide'); -->  //$("#product-box-1").modal('show'); // Use it in HTML with data-target like this: <a href="#" id="deleteOrd" type="button" class="btn btn-secondary" data-toggle="modal" data-target="#emptyCartModal">Empty Cart</a>
    
   // }
  }

    newShipAddressSelected(){
      this.existingShippingAddress = false;
      this.newShippingAddress = true;
      this.shippingDetails = {};
      this.selectedShipAddress = "";
    }

    showExistingBillAddrList(){
      this.newBillingAddress = false;
      this.existingBillingAddress = true;
      //showPleaseWait();
      //$("#existingBillAddrListModal").modal('show'); -->  //$("#product-box-1").modal('show'); // Use it in HTML with data-target like this: <a href="#" id="deleteOrd" type="button" class="btn btn-secondary" data-toggle="modal" data-target="#emptyCartModal">Empty Cart</a>
      this.showLoader = true;
      this.getBillingLocations();

      
    }
  // getBillingLocations() {
  //   throw new Error('Method not implemented.');
  // }


    getBillingLocations(){
			// var billingPromise = httpService.getData('productsCheckout.htm/gbal'); // This requires Backend implementation of "CanonCpProductsCheckoutController.java" in myCSA4.
			// console.log(billingPromise);
	    //  	return billingPromise;
      this.otherCanonProductsCheckoutService.getBillingLocations().subscribe(
        data=>{
          var billingPromise= data;
          this.existingBillAddressList = data;
            this.showLoader = false;
          return billingPromise;
        }
      );

	    }


    selectBillAddr (address){
        this.billingDetails = address;
        this.selectedBillAddress = address.displayAddr;
        //$('#existingBillAddrListModal').modal('hide');
      }

    newBillAddressSelected(){
        this.existingBillingAddress = false;
        this.newBillingAddress = true;
        this.billingDetails = {};
        this.selectedBillAddress = "";
      }

    onBillingCheckClick(){
        if(this.isBillingChecked){
          this.existingBillingAddress = false;
          this.newBillingAddress = false;
          this.selectedBillAddress = "";
        }
      }

      setOrderNumber(orderNumber) {
        if (this.cart != null) {
          sessionStorage.setItem('itcgOrderNumber', JSON.stringify(orderNumber));
        }
      }

      clearLocalStorage(){
        localStorage.removeItem('itcgCart');
      }

    checkout(){

        this.errMsg = '';
        console.log('shippingDetails',this.shippingDetails);
        console.log('billingDetails',this.billingDetails);
        
        if(!(this.existingShippingAddress || this.newShippingAddress)){
          this.errMsg = 'Please select shipping address';
          return;
        }
        
        if(this.isBillingChecked){
          this.billingDetails = this.shippingDetails;
        }else{
          if(!(this.existingBillingAddress || this.newBillingAddress)){
            this.errMsg = 'Please select billing address';
            return;
          }
        }
        
        this.cart.shippingAddress = this.shippingDetails;
        this.cart.billingAddress = this.billingDetails;
        this.submitOrder(this.cart);
        
        //if (this.checkoutForm.valid) {  		  
          //form is valid
          //showPleaseWait();
          //this.submitOrder(this.cart);


         // }
         // else {
              //if form is not valid set $scope.checkoutForm.submitted to true     
            //  this.checkoutFormSubmitted=true;    
         // }
      }   
      // grandTotal(){
      //   return cartService.grandTotal();
      // }

      submitOrder(cart) {
        // var submitOrderPromise = httpService.postJsonData('productsCheckout.htm/so',cart); //// This requires Backend implementation of "CanonCpProductsCheckoutController.java" in myCSA4.
        // return submitOrderPromise;
        this.otherCanonProductsCheckoutService.postJsonData(cart).subscribe(
          data=>{
            var submitOrderPromise=data;
            if(data.status == 'S'){
              //hidePleaseWait();
              this.cart.orderNumber = data.orderNumber;
              this.cart.referenceNumber = data.referenceNumber;
              this.setOrderNumber(this.cart.orderNumber);
              this.clearLocalStorage();
              //var url = "products.htm?view=CanonCpShopConfirmation";
              //window.location.href = url;
              this.router.navigate(['othercanonproductcartconfirmation']);
            }
            else if(data.status == 'PC'){
              //hidePleaseWait();

              if(data.cart != null && data.cart.productMap != null && data.cart.productMap != 'undefined')
              {
                for( var key in data.cart.productMap){
                  this.cart.productMap[key].listPrice = data.cart.productMap[key].listPrice;
                  this.cart.productMap[key].selectedCarePak.listPrice =data.cart.productMap[key].selectedCarePak.listPrice;
                }
              }
              this.errMsg = data.message;
            }else{
              //hidePleaseWait();
              this.errMsg = data.message;
            }
           
          }
        );
      }

      grandTotal() {
        this.cart = this.getCart();
        var grandTotal = 0;
        if(this.cart.productMap != null && this.cart.productMap != 'undefined')
        {
          for( var key in this.cart.productMap){
            grandTotal =  grandTotal 
              + (this.cart.productMap[key].selectedQty * this.cart.productMap[key].listPrice) 
              + (this.cart.productMap[key].selectedQty * this.cart.productMap[key].selectedCarePak.listPrice);
          }
        }
        console.log('Calculated Grand Total ', grandTotal);
        this.grantTotalCont=grandTotal;
        return grandTotal;
      }
      
      // $scope.totalCartItems = function(){
      //   return cartService.totalCartItems();
      // }

      totalCartItems() {
        var length = 0;
        var cart = this.getCart();
        if(cart.productMap != null && cart.productMap != 'undefined')
        {
          length = Object.keys(cart.productMap).length;
        }
        this.totalCartCount=length;
        return length;
      }
      
      back(){

        // var url = "products.htm?view=CanonCpShopCart";
        // window.location.href = url;

        this.router.navigate(['othercanonproductshopcart']);
      }


}
