import { Component, Input, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import {ConfigUsersService} from './config-users.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-config-users',
  templateUrl: './config-users.component.html',
  styleUrls: ['./config-users.component.css']
})
export class ConfigUsersComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  partyId:string="14669070";
  configUsers:any=[];
  recPerPage=10;
  pageNum=1;
  numOfRecsMsg:string="";

  constructor(private commonService:CommonServiceService, private configUsersService:ConfigUsersService) { }
  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
    this.blockUI.start();
    this.configUsersService.getConfigUsers(this.param.partyId).subscribe(
      data=>{
        console.log("Printing data for configUsers..");
        console.log(data.res);
        this.configUsers=data.res;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(data.iTotalRecords,this.recPerPage,this.pageNum);
        this.blockUI.stop();
      }
    );
  }




}
