import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../service/common-service/common-service.service';
import { Util } from '../app.util';
import { ContractBillReportsService } from './contract-bill-reports.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contract-bill-reports',
  templateUrl: './contract-bill-reports.component.html',
  styleUrls: ['./contract-bill-reports.component.css']
})
export class ContractBillReportsComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  
  invDateFrom:any="";
  invDateTo:any="";
  billToAcct:any="";
  billToLoc:any="";
  ctrNum:any="";
  installLocation:any="";
  serialEquipment:any="";
  invNum:any="";
  consolidatedBill:any="";
  poNum:any="";
  ts:any=0;
  reportId:any;
  
  constructor(private ctrBillRptService:ContractBillReportsService , private commonService:CommonServiceService,private util : Util,private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Contract Billing Reports");
    this.commonService.setMoreInfo();
    this.setStyles();
    
  }

  downloadReport(rptType:string){
    console.log("Printing tpo check if hitting ContractBillingdownloadReport");
    this.blockUI.start();
    let d = new Date();
	  let ms = d.getTime();
    this.reportId=ms;
    var url ="Needs to be filled.."
    let oParams ={}
    if(rptType=="A" || rptType=="P"){
       oParams = { "reportId": ms,"action":rptType};
    }else if(rptType=="C"){
      let frmDt=this.getFormatedDate(this.invDateFrom);
      let toDt=this.getFormatedDate(this.invDateTo);
       oParams = { "reportId": ms,"action":rptType,"invDateFrom":frmDt,
        "invDateTo":toDt,"billToAcct":this.billToAcct,"billToLoc":this.billToLoc,
        "ctrNum":this.ctrNum,"installLocation":this.installLocation,
        "serialEquipment":this.serialEquipment,"invNum":this.invNum,
        "consolidatedBill":this.consolidatedBill,"poNum":this.poNum
      };
    }
    //this.checkStatus();
    let rptUrl="contractBilling/downloadReport?"+$.param(oParams);
    this.commonService.exportToExcel(rptUrl);
    this.blockUI.stop();
       

    
  }
   
  checkStatus(){
     setTimeout(()=>{                         
        this.getStatus();
     }, 2000);

  }
   
  getStatus(){
    let oParams={"reportId":this.reportId};
    let data=this.ctrBillRptService.getStatus(oParams).subscribe(
       data=>{
          let d="";
            d=data["rpt_status"];
            console.log("report status :"+d);
            if(d && d.trim()=="Y"){
              this.blockUI.stop();
            }else{
              if(this.ts<1440){ // 2 hrs
                this.ts++;
                this.checkStatus();
              }
            }
      });
  }
 
 getFormatedDate(dt){

  if(dt=="" || dt==null || dt==undefined)
    return "";
    let fmtDt="";
    try {
      let tmp= new Date(dt);
      let mnthLbl=tmp.toLocaleString('default', { month: 'short' });
      fmtDt=tmp.getDate()+"-"+mnthLbl+"-"+tmp.getFullYear(); 
       
    } catch (error) {
      fmtDt=dt;
    }
    return fmtDt;
 }

  clearAll(){
    this.invDateFrom="";
    this.invDateTo="";
    this.billToAcct="";
    this.billToLoc="";
    this.ctrNum="";
    this.installLocation="";
    this.serialEquipment="";
    this.invNum="";
    this.consolidatedBill="";
    this.poNum="";
  }
  setStyles(){
 
    $(".mat-form-field-infix").css({"padding-bottom":"0px!important"});
    $(".mat-form-field").css({"display":"block"});
    $(".mat-form-field-infix").css({"padding":"0px 0px"});
    $(".mat-form-field-wrapper").css({"padding-bottom":"0px"});
     
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }
}
