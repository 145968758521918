import { Component, OnInit } from '@angular/core';
import { ClosedServiceService } from 'src/app/service-request/closed-service/closed-service.service';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-closed-service',
  templateUrl: './closed-service.component.html',
  styleUrls: ['./closed-service.component.css']
})
export class ClosedServiceComponent implements OnInit {

	  @BlockUI() blockUI : NgBlockUI;

	  closedServiceRequestList=[];
	  userAccountList=[];
	  selectedUserAccts:string="";
	  serialEqpNumber:string="";
	  requestNumber:string="";
	  model:string="";
	  users="";
	  userList=[];
	  modelList=[];
	  dateRanges="30";
	  numOfDaysList=[];
	  showMsg=false;
	  recPerPage=10;
	  arrPgLinks=[];
	  pageNum=1;
	  msgClass;
	  srchTxt ="";
	  numOfRecsMsg="";
	  totalRecords=0;

	  pleaseWaitCls:boolean = false;
	
  	constructor(private commonService:CommonServiceService, private closedServiceService:ClosedServiceService, private util : Util, private titleService: Title) { }

  	ngOnInit() {
		this.titleService.setTitle("Closed Service Request");
		
		this.getClosedServiceRequests(1, null);
		
		let data = this.commonService.getUserAccounts().subscribe(
	      data=>{
	        this.userAccountList = data.data;
	      });

		this.commonService.setCommonFunctions();
  	}


  	clearAll(event:any) {
	
		if(event){
	      event.preventDefault();
	    }

		this.selectedUserAccts="";
		this.requestNumber="";
		this.serialEqpNumber="";
		this.model="";
		this.users="";
		this.dateRanges="30";
		
		for(let actIdx in this.userAccountList){
	      this.userAccountList[actIdx]["enabled"]=true;
	    }

		this.getClosedServiceRequests(1, null);

	
	}

	getClosedServiceRequests(pagenumber,event: any) {
		
		if(event!=null){
      		event.preventDefault();
    	}
		
		this.showMsg=false;
		//this.pleaseWaitCls=true;
		this.blockUI.start();
		
		this.pageNum=pagenumber;
		this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
		
		var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"partyIDs": this.selectedUserAccts,
			"srhReqNum": this.requestNumber,
    		"srhSerialNum":this.serialEqpNumber,
			"srhModelNum":this.model,
			"srhAssignedTo":"All Users",
			"srhDuration":this.dateRanges,
			//"sortCol":this.sortCol,
			//"orderBy":this.orderBy,
			//"partyIDs":
	    };
		
		this.closedServiceService.getClosedServiceRequests(param).subscribe(data => {
		
		if (data != null) {
		    
			console.log('getClosedServiceRequests:', data);
			this.closedServiceRequestList = data.data;
			this.modelList=data.modelList.split("|");
			this.userList=data.assignToList.split("|");
			this.numOfDaysList=data.numDaysList.split("|");
			this.totalRecords=data.totalRecords;
			
			this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         	this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

			if(this.totalRecords == 0) {
				this.showMsg=true;
			}
			
			//this.pleaseWaitCls=false;
			this.blockUI.stop();
		}

    });
		
	
	}

	exportToExcel1(){
		console.log("Printing inside exportToExcel method of Closed service requests");
		let url="servicerequest/downloadExcel";
		this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
		var params = {
			"partyIDs":"",
			"srhReqNum": this.requestNumber,
    		"srhSerialNum":this.serialEqpNumber,
			"srhModelNum":this.model,
			"srhAssignedTo":"All Users",
			"srhDuration":this.dateRanges,
			"sortColumn":"incident_number",
			"orderBy":"D"
			//"orderBy":this.orderBy,
			//"partyIDs":
	    };
		
		let qryStr = Object.keys(params)
       .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
       .join('&');
		url=url+"?"+qryStr;
		console.log("Printing url for export to Excel in Closed service requests:", url);
		this.commonService.exportToExcel(url);
	  }



		exportToExcel() {
			this.blockUI.start();
			
			this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
			var dNow = new Date();
			var d = new Date();
		   var perivousdays;
			var fromdate;
			var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
			if (this.dateRanges === "15")
				  {
			  perivousdays = new Date(d.setDate(d.getDate()-15));
			  fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
				  }
			else if (this.dateRanges === "30")
				  {
			  perivousdays = new Date(d.setDate(d.getDate()-30));
			  fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
				  }
			   else if(this.dateRanges === "90")
			   {
				 perivousdays = new Date(d.setDate(d.getDate()-90));
				 fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			   }
			   else if(this.dateRanges === "180")
			   {
				 perivousdays = new Date(d.setDate(d.getDate()-180));
				 fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			   }
			   else if(this.dateRanges === "365")
			   {
				 perivousdays = new Date(d.setDate(d.getDate()-365));
				 fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			   }
			   else if(this.dateRanges === "547")
			   {
				 perivousdays = new Date(d.setDate(d.getDate()-547));
				 fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			   } 
			var param = {
				"pageNum": 1,
				"recPerPage":this.totalRecords, 
				"partyIDs":this.selectedUserAccts,
				"srhReqNum": this.requestNumber,
				"srhSerialNum":this.serialEqpNumber,
				"srhModelNum":this.model,
				"srhAssignedTo":"All Users",
				"srhDuration":this.dateRanges,
				"dateFrom":fromdate,
				"dateTo": todate
				//"sortCol":this.sortCol,
				//"orderBy":this.orderBy,
				//"partyIDs":
			};
			let initialUrl = "servicerequest/downloadExcel?" + $.param(param);
			console.log("Printing url : ", initialUrl);
			this.commonService.exportToExcel(initialUrl);

			this.blockUI.stop();
			
		// 	this.closedServiceService.getClosedServiceRequests(param).subscribe(data => {
			
		// 	if (data != null) {
				
		// 		console.log('getClosedServiceRequests:', data);
		// 		this.commonService.jsonToExcel(data.data,"ClosedServiceRequest",true,false,null);
				
		// 		this.blockUI.stop();
		// 	}
	
		// });
			
		
		}

		stopVideo(id: string){
			console.log('Printing id value: ', id);
			const video = document.getElementById(id) as HTMLVideoElement;
			if(video){
			 video.pause();
			 //video.load();
			}
		   }

}
