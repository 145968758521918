import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ContractSuppliesService } from '../contract-supplies.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AutoTonerDetailsModalComponent } from 'src/app/common/auto-toner-details-modal/auto-toner-details-modal.component';
import { AutoTonerUploadDownloadModalComponent } from 'src/app/common/auto-toner-upload-download-modal/auto-toner-upload-download-modal.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-contract-supplies',
  templateUrl: './order-contract-supplies.component.html',
  styleUrls: ['./order-contract-supplies.component.css']
})
export class OrderContractSuppliesComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  srlList : any[];

  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  start:any=1;
  end:any=10;
  arrPgLinks:any=[];

  serialNumber:string="";
  model:string="";
  location:string="";
  selectedUserAccts:string="";

  userAccountList=[];

  pleaseWaitCls:boolean = false;

  searchAll : string = "N";
  hasATRole : string = "N";
  showMsg : boolean = false;
  msg : string = "";
  isCartEmpty = true;
	cartItemCount = 0;
  itemInTheSession;
  itemInfoArray = [];
  userId = sessionStorage.getItem('userName');

  selectedSerialNo : string = "";
  salesRepUser : string = "N";

  constructor(private commonService:CommonServiceService, private csService: ContractSuppliesService,
    private router : Router, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Order Supplies");
    this.loadInitialData();

  }

  loadInitialData(){
    this.commonService.setCommonFunctions();
    this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });

    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);

    this.csService.hasAutoTonerAccess().subscribe(
      data => {
        this.hasATRole = data.hasATAccess;
      }
    )

    this.getDeviceListing(1);

    this.itemInTheSession = localStorage.getItem(this.userId + '__ItemDetail');
     if( this.itemInTheSession !== null && this.itemInTheSession !== undefined && this.itemInTheSession !== "" ){
     this.itemInfoArray = JSON.parse( this.itemInTheSession );
       }

    if( this.itemInfoArray.length > 0 ){
      this.isCartEmpty = false;
      this.cartItemCount = this.itemInfoArray.length;
    }
    
  }
  

  getDeviceListing(pageNum){

    this.blockUI.start();

    this.clearMsg();

    this.srlList = [];

    this.pageNum = pageNum;
    
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);

    let oParams = { 
      "pageNum": this.pageNum, 
      "recPerPage": this.recPerPage, 
      "serialNumber":this.serialNumber,
      "model":this.model,
      "location":this.location,
      "selectedUserAccts":this.selectedUserAccts,
      "searchAll":this.searchAll  
     };

    this.csService.getDeviceListing(oParams).subscribe(
        data => {
          this.srlList = data.data;  
          this.totalRecords = data.iTotalDisplayRecords;
          this.salesRepUser = data.salesRepUser;
          if(this.totalRecords == 0){
            this.showMsg = true;
            this.msg = "No Records Found";
          }
          this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
          this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

          this.blockUI.stop();
        }
      )
  }

  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.pageNum = 1;
    this.recPerPage = 10;
    this.serialNumber="";
    this.model="";
    this.location="";
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
    this.searchAll = "N";

    this.clearMsg();

    this.getDeviceListing(1);
  }

  search(){
    this.searchAll = "Y";
    this.getDeviceListing(1);
  }

  clearMsg() {
		this.showMsg = false;
		this.msg = "";
	}

  displaySupplyListing(deviceDetails){
    this.pleaseWaitCls = true;
    let params = {
      "serialNum": deviceDetails.serialNo,  
      "contractId": deviceDetails.contractId, 
      "contractNum": deviceDetails.contractNumber, 
      "sectionId": deviceDetails.sectionid, 
      "instanceId": deviceDetails.instanceId, 
      "model": deviceDetails.model, 
      "source": deviceDetails.source, 
      "address": deviceDetails.address, 
    }
    this.pleaseWaitCls = true;
    this.router.navigate(['osmeterreads'], {queryParams : params});
    this.pleaseWaitCls = false;
  }

  exportToExcel(){
    this.blockUI.start();
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);

    let oParams = { 
      "pageNum": 1, 
      "recPerPage": 9999, 
      "serialNumber":this.serialNumber,
      "model":this.model,
      "location":this.location,
      "selectedUserAccts":this.selectedUserAccts,
      "searchAll":this.searchAll  
     };
     this.csService.getDeviceListing(oParams).subscribe(
      data => {
        let d = data.data;  
        let arrData=[];
        let objLbl=['Contract Number','Supplies Included','Model','Serial Number','Equipment ID','Install Address','Auto Toner Flag','Auto Toner Status'];
				arrData.push(objLbl);
        for(let idx in d){
					var arr=[];
					var objJD=[];
					objJD=d[idx];
					arr.push(objJD["contractNumber"]);
					arr.push(objJD["suppliesIncFlag"]);
					arr.push(objJD["model"]);
					arr.push(objJD["serialNo"]);
					arr.push(objJD["external_reference"]);
					arr.push(objJD["formatted_address"]);
					arr.push(objJD["autoTonerFlag"]);
					arr.push(objJD["autoTonerStatus"]);
					
					arrData.push(arr);
				}
				 
				if(d.length<1){
					let  arr=["No Records Found","","","","","","",""];
			 		arrData.push(arr);
					
				}

         this.commonService.jsonToExcel(arrData,"OrderContractSupply",false,false,null);
      

        this.blockUI.stop();
      }
    )

  }

  displayATDetails(serialNo){
    this.selectedSerialNo = serialNo;
    const atDetailsModalRef = this.modalService.open(AutoTonerDetailsModalComponent, { windowClass: 'scrollable-modal' });
    atDetailsModalRef.componentInstance.serialNum= serialNo
    atDetailsModalRef.componentInstance.pageSource = 'ordersupplies'
  }

  requestAT(){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalService.open(AutoTonerUploadDownloadModalComponent, ngbModalOptions);
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
