import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserserviceService } from 'src/app/service/common-service/userservice.service';
import { canonUser } from '../bean/canonUser';
import { Router } from '@angular/router';
import { CanonUserNotifyPreference } from '../bean/CanonUserNotifyPreference';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.css']
})



export class UserPreferencesComponent implements OnInit {
  canonUserObj: canonUser;
  canonUserNotifyPreference: CanonUserNotifyPreference;
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
  isUpdtPwdChecked: boolean = false;
  newPwd: string;
  inputConfirmPassword: string;
  errorMessage: string;
  isSaveDisabled: boolean = false;
  oracleUserFlag: string;
  username: string;
  firstName: string = "";
  lastName: string = "";
  email: string;
  phoneNumber: string = "";
  isCaSubMtrRead: boolean;
  isCPUser: boolean;
  submissionError: boolean;
  submissionSuccess:boolean;
  userPrefForm: FormGroup;
  resetPwdAutoFill = false;  
  nameNotValid : boolean = false;
  phoneNotValid : boolean = false;
  pwdNotValid : boolean = false;

  constructor(private userserviceService: UserserviceService, 
    private router: Router, 
    private loginAuthenticationService: LoginAuthenticationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef) {
    this.canonUserObj = new canonUser();   
  }


  ngOnInit() {
    this.getUserPreferences();
    this.getUserDataToDisplayNotif();
    this.errorMessage = "";
    this.submissionError = false;
    this.submissionSuccess = false;
    this.newPwd = "";
    this.username = this.loginAuthenticationService.getAuthenticatedUser();
    //this.isSaveDisabled = false;   
    this.displaySaveChanges();
  }

  updateUserPreferences() {  
    this.validateFnameLname();
    
    var dailyVar = '';
    var weeklyVar = '';
    var monthlyVar = '';
    var isUpdtPwdCheckedVar;

    if (this.daily) {
      dailyVar = 'Y';
    } else {
      dailyVar = 'N';
    }

    if (this.weekly) {
      weeklyVar = 'Y';
    } else {
      weeklyVar = 'N';
    }

    if (this.monthly) {
      monthlyVar = 'Y';
    } else {
      monthlyVar = 'N';
    }

    if (this.isUpdtPwdChecked) {
      isUpdtPwdCheckedVar = 'Y';
    }

    var saveNotifData = {
      "daily": dailyVar,
      "weekly": weeklyVar,
      "monthly": monthlyVar,
      "order": this.canonUserNotifyPreference.order,
      "serialReq": this.canonUserNotifyPreference.serialReq,
      "payment": this.canonUserNotifyPreference.payment
    }

    var data = {
      // "userName": this.username,
      "firstName": this.canonUserObj.firstName,
      "lastName": this.canonUserObj.lastName,
      //"email": this.canonUserObj.emailAddress,
      "phoneNumber": this.canonUserObj.phoneNumber,
      "defaultAppCode": this.canonUserObj.defaultAppCode,
      "defaultCustAccount": this.canonUserObj.defaultCustomerAccount,
      "defaultRole": this.canonUserObj.defaultRole,
      "description": this.canonUserObj.description,
      "pwdHintFlag": this.canonUserObj.pwdHintFlag,
      "newPwd": this.newPwd,
      "updPwdFlag": isUpdtPwdCheckedVar
    }

    this.userserviceService.updateUserPreferences(data).subscribe((data: any) => {     
      //data.errFlg = 'F'; 
      if (data.errFlg === "S") {
        if (this.isCPUser) {
          if (this.daily || this.weekly || this.monthly) {
            this.saveUserNotifPreferences(saveNotifData);
          }else {
            this.submissionError = false;
            this.submissionSuccess = true;         
            this.router.navigate(['home']);         
          }
        } 
      } else {
        this.submissionError = true;
        this.isSaveDisabled = false;
      }
    })
  }

  getUserPreferences() {
    return this.userserviceService.getUserPreferences().subscribe((data: any) => {
      this.canonUserObj = data.canonUserObj[0];     
    });
  }

  getUserNotifPref() {
    this.userserviceService.getUserNotifPreferences().subscribe((data: any) => {
      this.canonUserNotifyPreference = data;
      if (data.daily === 'Y') {
        this.daily = true;
      }

      if (data.weekly === 'Y') {
        this.weekly = true;
      }

      if (data.monthly === 'Y') {
        this.monthly = true;
      }    
    });
  }

  getUserDataToDisplayNotif() {
    return this.userserviceService.getUserDataToDisplayNotif().subscribe((data: any) => {
      this.isCaSubMtrRead = data.isCaSubMtrRead;
      this.isCPUser = data.isCPUser;
      if (this.isCPUser) {
        this.getUserNotifPref();
      }
    });
  }

  saveUserNotifPreferences(data) {
    return this.userserviceService.saveUserNotifPreferences(data).subscribe((data: any) => {
      if (data.errFlg === "S") {       
        this.submissionError = false;
        this.submissionSuccess = true;       
        this.router.navigate(['home']);        
      } else {
        this.submissionError = true;
        this.submissionSuccess = false;
        this.isSaveDisabled = false;
      }

    });
  }

  requirePwd(e) {
    this.isUpdtPwdChecked = e.target.checked;   
    if (!this.isUpdtPwdChecked) {
      //this.isSaveDisabled = false;
      this.errorMessage = "";
      this.newPwd = '';  
      this.inputConfirmPassword = '';
      this.pwdNotValid = false;
    }else{
      //this.isSaveDisabled = true;
      this.pwdNotValid = true;
    }
    this.displaySaveChanges();
  }

  cancel() {
    this.router.navigate(['home']);
  }

  pwdStrength() {

    var psId = "#ps";
    var b = false;

    $(psId).css({ "color": "#000000" });
    var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");
    var enoughRegex = new RegExp("(?=.{8,}).*", "g");
    if (false == enoughRegex.test(this.newPwd)) {
      $(psId).css({ "color": "red" });
      $(psId).html('Password must have minimum 8 characters.');
    } else if (mediumRegex.test(this.newPwd)) {
      $(psId).css({ "color": "green" });
      $(psId).html('Strong');
      b = true;
    } else {
      $(psId).css({ "color": "red" });
      $(psId).html('Password must have 1 Uppercase, 1 Lowercase , 1 Number. No repeating characters.');
    }
    return b;
  }

  matchPasswords() {
    if ((this.newPwd != this.inputConfirmPassword) || this.newPwd.length < 6) {
      this.errorMessage = "Passwords did not match.";
      //this.isSaveDisabled = true;
      this.pwdNotValid = true;
    }
    else {
      //this.isSaveDisabled = false;
      this.pwdNotValid = false;
      this.errorMessage = "";
    }

    this.displaySaveChanges();
  }

  validatePhone() {
    var inpObj = document.getElementById('phoneNumber');   
    var Validformats = 'Valid Formats:\r\n\
  (+351) 282 43 50 50\r\n\
  90191919908\r\n\
  555-8909\r\n\
  001 6867684\r\n\
  001 6867684x1\r\n\
  1 (234) 567-8901\r\n\
  1-234-567-8901 x1234\r\n\
  1-234-567-8901 ext1234\r\n\
  1-234 567.89/01 ext.1234\r\n\
  1(234)5678901x1234\r\n\
  (123)8575973\r\n\
  (0055)(123)857597';

    var retObj = { isValid: true, msg: '' };
    var inpVal = this.canonUserObj.phoneNumber;
 if (inpVal.trim() != '') {
      var pVldtr = new RegExp(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i);
      retObj.isValid = pVldtr.test(inpVal);
      if (!retObj.isValid) {
        retObj.msg = '<span vdb="true" class="ErrorFlagger" style="display:block !important;color:red;font-size: medium;">Invalid Phone Number. \
          <button style="padding: 0px 6px;" type="button" class="btn btn-default" data-toggle="tooltip" html="true" data-placement="bottom" title="' + Validformats + '">?</button></span>';
          //this.isSaveDisabled = true;
          this.phoneNotValid = true;
        }else{
          //this.isSaveDisabled = false; 
          this.phoneNotValid = false;   
        }
    }else{
      //this.isSaveDisabled = false;  
      this.phoneNotValid = false;  
    }

    this.displaySaveChanges();
    $(inpObj).siblings('span').remove();
    $(inpObj).after(retObj.msg);
    return retObj;
  }

  validateFName():boolean{
    if(this.canonUserObj.firstName.length == 0){
      return true;
     }else{    
      return false;
     }
   }
   validateLName():boolean{
    if(this.canonUserObj.lastName.length == 0){     
      return true;
     }else{    
      return false;
     }
   }

   validateFnameLname(){
    var fNameInValid = this.validateFName();
    var lNameInValid = this.validateLName();
    if(fNameInValid  && lNameInValid){
      //this.isSaveDisabled = true;  
      this.nameNotValid = true;
    }else  if(fNameInValid  || lNameInValid){
      //this.isSaveDisabled = true; 
      this.nameNotValid = true; 
    }else {
      //this.isSaveDisabled = false; 
      this.nameNotValid = false; 
    }
    this.displaySaveChanges();
 }


	phNumKeyPress(event:KeyboardEvent) {
		const pattern = /[0-9]/;
    	const inputChar = String.fromCharCode(event.charCode);
    	if (!pattern.test(inputChar)) {
        	// invalid character, prevent input
    	   	event.preventDefault();
   		}
	}

   chkPhNum(){

	    var len,i;
		var phEle: HTMLInputElement;
		phEle = document.getElementById("phoneNumber") as HTMLInputElement;
		var str = phEle.value;
		var newstr = "";

		if (this.hasAlphabets(str)) {
			// means auto fill happened
			this.canonUserObj.phoneNumber = "";
			this.resetPwdAutoFill = true;
		}


		/*

		for (i = 0, len = str.length; i < len; i++) {
		    var code = str.charCodeAt(i);
		    if ( (code > 47 && code < 58) // && // numeric (0-9)
		        //(code > 64 && code < 91) || // upper alpha (A-Z)
		        //(code > 96 && code < 123)) { // lower alpha (a-z)
			){
		      newstr = newstr + str.charAt(i);
		    }
	  	}

		this.canonUserObj.phoneNumber = newstr;

		*/
		
		}

   resetPwdAutofill() {
		if(this.resetPwdAutoFill) {
			var phEle: HTMLInputElement;
			phEle = document.getElementById("newPwd") as HTMLInputElement;
			phEle.value = "";
			this.newPwd= "";
			this.resetPwdAutoFill = false;
		}
   }


	hasAlphabets(str) {
	  var code, i, len;
	
	  for (i = 0, len = str.length; i < len; i++) {
	    code = str.charCodeAt(i);
	    if ( // !(code > 47 && code < 58) && // numeric (0-9)
	        (code > 64 && code < 91) || // upper alpha (A-Z)
	        (code > 96 && code < 123)) { // lower alpha (a-z)
	      return true;
	    }
	  }
	  return false;
	};

  displaySaveChanges(){

/*     if(this.isUpdtPwdChecked){
      this.pwdStrength();
      this.matchPasswords();
    } */

    if(this.nameNotValid || this.phoneNotValid || (this.isUpdtPwdChecked && this.pwdNotValid)){
      this.isSaveDisabled = true;
    }else{
      this.isSaveDisabled = false;
    }

  }

}

