import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OrderSuplliesListingService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
   getItemList=function(serNum, contractId, sectionId, instanceId, model)
  {
      let url = this.defaultUrl + "ordersupplies/getSupplyItems?serialNum="+serNum+"&contractId="+contractId+"&sectionId="+sectionId+"&instanceId="+instanceId+"&model="+model;
    ; 
      // "trackSupplies.htm?mthd=gso?";
      return this.http
        .get(url, httpOptions)
        .pipe(catchError((this.util.handleError("getItemList"))));
    }


}
