import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class LeaseoptionsService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }

    getleaseOptions = (paramObj) => {
      let url = this.defaultUrl + 'configuratorAdmin/getLeaseOptions?';
      let params = new URLSearchParams();
      for (let key in paramObj) {
          params.set(key, paramObj[key])
      }
      let qryStr = params.toString();
      url = url + qryStr;

      return this.http
          .post(url, qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("leaseoptions"))));

  }

  saveleaseOptions = (paramObj) => {
    let url = this.defaultUrl + 'configuratorAdmin/saveLeaseOptionsJson?';
    let params = new URLSearchParams();
    for (let key in paramObj) {
        params.set(key, paramObj[key])
    }
    let qryStr = params.toString();
    url = url + qryStr;

    return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("leaseoptions"))));

}


}
