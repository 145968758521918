import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
 

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ConfiguratorQuotesService {

	defaultUrl: string;
	myBuild ={} as any;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
   configQuoteGenericMtd(oParams,mthd): Observable<any>
  {
      let url = this.defaultUrl + "configurator/"+mthd;
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError(mthd))));
    }
    static isOptionChecked(opt){
    	if(opt.checked){
			return true;
		}else {   
			return false;
		}
    }
   
	
    static isHeaderEnable (listAccessory){
    	var isEnable = false;
		for(var itemId in listAccessory){
			if(listAccessory[itemId].checked){
				isEnable = true;
			}
		}
		return isEnable;
    }
    
    static findLengthItems (listAccessory){
		var lengthItems = 0;
		if(listAccessory != null && listAccessory != undefined)
		{
			for(var itemId in listAccessory)
			{
				if(listAccessory[itemId].checked)
				{
					lengthItems = lengthItems + 1;
				}
			}
		}
		return lengthItems;
	}
	 setConfigData(myBuild){
    	var configDetails = myBuild.config[0];
		myBuild.imgSr = configDetails.imagePath;
		myBuild.itemDescription = configDetails.itemDescription;
		myBuild.purchaseOption = configDetails.purchaseOption;
		myBuild.model = configDetails.model;
		if(configDetails.leaseTerm != null && configDetails.leaseTerm != undefined)
		{
			myBuild.leaseTerm = configDetails.leaseTerm;
		}else
		{
			myBuild.leaseTerm = "";
		}
		
		myBuild.amount = configDetails.amount;
		myBuild.productGroupId = configDetails.productGroupId;
		myBuild.machineItemCode = configDetails.itemCode;
		myBuild.leaseRate = configDetails.leaseRate;
		myBuild.partyId = configDetails.partyId;
		myBuild.bundleName = configDetails.bundleName; 
		myBuild.priceListId = configDetails.pricelistId;
		myBuild.purchaseType = configDetails.purchaseType;
		myBuild.configQty = configDetails.configQty;
		return myBuild;
    }

	getMaintTotal(maintArray: any): any {
		let monthlyTotal = 0;
		  let totalAmt = 0;
		  let userTerm = 0;
		
		for (let i in maintArray) {
			let maint = maintArray[i];
			if(maint.isRejected == 'Y'){
				return totalAmt;
			}else{
				userTerm = maint.termUser;
		  monthlyTotal = parseFloat(monthlyTotal.toString()) + parseFloat(maint.baseRate.toString()) + (parseFloat(maint.cpcRate.toString()) * parseFloat(maint.avgVolUser.toString()));
	
			}
		}
		totalAmt = monthlyTotal*parseFloat(userTerm.toString());
		return totalAmt;
	  }

	  removeConfig(configId) {

		if (configId == -1) {
			sessionStorage.removeItem('myBuild');
		} else {
			let params = {"configId": configId};
			let postAction =this.configQuoteGenericMtd(params,'deleteConfig');
			return postAction;
	
		}
	}
	validateRequiredOneOfItem (listRequireOneOfItemAccessory){
    	var valid = false;
    	if(listRequireOneOfItemAccessory != null && listRequireOneOfItemAccessory != 'undefined' 
    		&& listRequireOneOfItemAccessory.length > 0)
    	{
	    	for(var itemId in listRequireOneOfItemAccessory)
			{
				if(listRequireOneOfItemAccessory[itemId].checked)
				{
					valid = true;
					break;
				}
			}
    	}else
		{
    		valid = true;
		}
    	return valid;
    }
	addToMyBuildOptions = function(partyId, quoteId, configId, productGroupId, 
		machineItemCode, purchaseType, leaseRate, accessoryType, option, 
		bundleName, priceListId, configQty){
	let postData = {} as any;
	postData.quoteId = quoteId;
	postData.configId = configId;
	postData.productGroupId = productGroupId;
	postData.machineItemCode = machineItemCode;
	postData.accessoryType = accessoryType;
	postData.itemCode = option.itemCode;
	postData.description = option.itemDescription;
	postData.price = option.price;
	postData.quantity = option.quantity;
	postData.purchaseType = purchaseType;
	postData.partyId = partyId;
	postData.bundleName = bundleName; 
	postData.priceListId = priceListId;
	postData.configQty = configQty;
	if(leaseRate != null && leaseRate != undefined)
	{
		postData.leaseRate = leaseRate;
	}
	
	console.log("Add Item Object ", $.param(postData))
	let oparams =$.param(postData);
			let postAction =this.configQuoteGenericMtd(oparams,'addOptionItem');
			return postAction;
}

updMyBuildOptions(partyId, quoteId, configId, productGroupId, 
		machineItemCode, purchaseType, leaseRate, accessoryType, option, 
		bundleName, priceListId, configQty){
	console.log( option.itemId + "|" + option.quantity + "|" + leaseRate + "|" + purchaseType);
	
	var postData = {} as any;
	postData.partyId = partyId;
	postData.quoteId = quoteId;
	postData.configId = configId;
	postData.productGroupId = productGroupId;
	postData.machineItemCode = machineItemCode;
	postData.purchaseType = purchaseType;
	postData.accessoryType = accessoryType;
	postData.optionId = option.optionId;
	postData.itemCode = option.itemCode;
	postData.description = option.itemDescription;
	postData.price = option.price;
	postData.quantity = option.quantity;
	postData.bundleName = bundleName; 
	postData.priceListId = priceListId;
	postData.configQty = configQty;
	if(leaseRate != null && leaseRate != undefined)
	{
		postData.leaseRate = leaseRate;
	}
	
	console.log("Update Item Object ", $.param(postData))
	let oparams =$.param(postData);
			let postAction =this.configQuoteGenericMtd(oparams,'updateOptionItem');
			return postAction;
}
uploadSummaryFile(data:any) : Observable<any>{
	let url = this.defaultUrl + "configurator/uploadFile?quoteId="+ data.quoteId+"&fileDesc="+data.fileDesc;

	const formData: FormData = new FormData();

	formData.append('file', data.file);
    //formData.append('quoteId', data.quoteId);
    //formData.append('fileDesc', data.fileDesc);
	return this.http.post(url, formData).pipe(catchError(this.util.handleError('uploadSummaryFile', [])));
  }
uploadFileToUrl = function(data){
    var fd = new FormData();
    fd.append('file', data.file);
    fd.append('quoteId', data.quoteId);
    fd.append('fileDesc', data.fileDesc);
    var uploadPromise = this.uploadSummaryFile(data);
    return uploadPromise;
} 

deleteDoc(id, quoteId) {
		let oparams ={"id":id,"quoteId":quoteId};
		var deletePromise = this.configQuoteGenericMtd(oparams,'deleteFile');
		return deletePromise;
 }

 setQuote (quotename,date){
	if(sessionStorage.getItem('myBuild') != null){
		 this.myBuild = JSON.parse(sessionStorage.getItem('myBuild'));
	}	
	
	  for(var id in this.myBuild){
			//var key = Object.keys(myBuild)[0];
			this.myBuild[id].quoteName = quotename;
			this.myBuild[id].date = date;
			this.myBuild[id].status = 'Created';
			sessionStorage.setItem('myBuild',JSON.stringify(this.myBuild));
		  
	  }

	
}

}
