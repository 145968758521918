import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  user: string;

  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService, private titleService: Title) { }
  ngOnInit(): void {
    this.titleService.setTitle("Home");
    this.user =sessionStorage.getItem('fullName');
    //throw new Error('Method not implemented.');
  }
  logout() {

    this.loginAuthenticationService.logout();
    
  }
}
