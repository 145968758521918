// Template component

import { Component } from "@angular/core";

// Use block-ui-template class to center div if desired
@Component({
    template: `
    <div class="block-ui-template" id="please-wait-loader-container">
    <div class="please-wait-loader-wrapper">
      <div class="please-wait-loader-text">
        Please wait, processing request...
      </div>
      <div class="please-wait-loader-spinner-wrapper">
      <img src="assets/images/icons/Spinner-Gray.gif" class="please-wait-loader-spinner"/>
      </div>
      </div>  
      </div> 
    `,
  })
  export class BlockTemplateComponent {}