import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMeterReadsValue]'
})
export class MeterReadsValueDirective {

  regexStr = '^[0-9]+$';
  constructor(private _el: ElementRef) {}
   @ HostListener('keypress', ['$event'])
  onKeyPress(event) {
      return new RegExp(this.regexStr).test(event.key);
  }
  // block from copy paste
   @ HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
      this.validateFields(event);
  }
  validateFields(event: ClipboardEvent) {
    event.preventDefault();

    const origText = event.clipboardData.getData('text/plain');

    const separator = ".";

    //console.log('origText:' + origText);

    const origTextArray = origText.split(separator);

    //console.log('origTextArray:' + origTextArray);

    let pasteData = '';
    if(origTextArray.length > 0){
      pasteData = origTextArray[0]
      //console.log('pasteData1:' + pasteData);
      pasteData = pasteData.replace(/[^0-9]/g, '');
      //console.log('pasteData2:' + pasteData);
    }
    
    document.execCommand('insertHTML', false, pasteData);
  }


}
