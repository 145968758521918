import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginAuthenticationService } from './login-authentication.service';


@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

   auth:boolean;

  constructor(private loginAuthenticationService:LoginAuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
    if (this.loginAuthenticationService.isUserLoggedIn())
    {
      return true;
   
    }
    else
    {
      this.router.navigate(['login']);
      return false;
    }
       
  }
 
}