import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class SystemadministrationEditlinksService {

	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }



   

  updateSolutionSuppURLs=function(input, save){
    let url = this.defaultUrl + "mdsc/updatesolusuppurl?save="+ save;

    return this.http
    .post(url,input, httpOptions)
    .pipe(catchError((this.util.handleError("updateSolutionSuppURLs"))));
	}





   getUrl=function(){
    let url = this.defaultUrl + "mdsc/getsolusuppurl"; 
    console.log("Printing inside getUrl method...");
    console.log(url);
    return this.http
    .get(url, httpOptions)
    .pipe(catchError((this.util.handleError("getData"))));
	}




}
