import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class RequestDevicesService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


   doCancelSubmit=function(){
    let url = this.defaultUrl + "orderInquiry/inqOrd?"; // "orderInquiry.htm?mthd=gsoi";
		//var adPromise = httpMyCsaService.postData('orderInquiry.htm?mthd=gsoi', qryStr);
		//return adPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getOrderInquiryRequestList"))));
	}


  doValidateSubmit=function(sNumber, ){
    let url = this.defaultUrl + "accountdevices/validateSerialNumber?sNumber="+ sNumber; // "orderInquiry.htm?mthd=gsoi";
		//var adPromise = httpMyCsaService.postData('orderInquiry.htm?mthd=gsoi', qryStr);
		//return adPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("doValidateSubmit"))));
	}

  dosubmitSerialNumber=function(count,instanceIds, model,instLocation, reqOrgNum,sNumber,source, verify ){
    let url = this.defaultUrl + "accountdevices/submitSerialNumber?count="+ count+"&instanceIds="+instanceIds+ "&model="+model
    +"&instLocation="+instLocation+"&reqOrgNum="+reqOrgNum+"&sNumber="+sNumber+"&source="+source+"&verify="+verify;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("dosubmitSerialNumber"))));
    
  }





  


}
