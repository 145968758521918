import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class ReviewApprovalSerialsService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }

  getPendingSerialApprovalList1 = (paramObj) => {
    let url = this.defaultUrl + 'userApprove/getApprovedSerialUserRequest?';
    let params = new URLSearchParams();
    for (let key in paramObj) {
        params.set(key, paramObj[key])
    }
    
    let qryStr = params.toString();
    url = url + qryStr;

    return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

}
}
