import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractSuppliesService } from 'src/app/product-supplies/contract-supplies.service';

@Component({
  selector: 'app-auto-toner-upload-download-modal',
  templateUrl: './auto-toner-upload-download-modal.component.html',
  styleUrls: ['./auto-toner-upload-download-modal.component.css']
})
export class AutoTonerUploadDownloadModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private csService : ContractSuppliesService) { }

  ngOnInit() {
  }

  closeLoginModal() {
    this.csService.autoTonerUploadCompleted().subscribe(
      data => {
        this.activeModal.close();
      }
    )
    
  }

}
