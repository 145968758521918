import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


// const httpOptions = {
//   headers: new HttpHeaders({
//   }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
// };

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class GridRoleService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


	getGridRoleList(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "canonGridRole/getGridRoleList?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, "", httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getGridRoleList")))
	  }	


	  getAllGridRole(): Observable<any> {
	    let initialUrl = this.defaultUrl + "canonGridRole/getAllGridRole";
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, "", httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getAllGridRole")))
	  }	  


	  removeGridAndRole(data): Observable<any> {
		let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "canonGridRole/removeGridAndRole?"+ qryStr; //http://localhost:8070/csaApp4/canonGridRole/removeGridAndRole?userName=&gridCd=METER_READ&roleNm=DASHBOARD_ROLE
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, "", httpJsonOptions)
	      .pipe(catchError(this.util.handleError("getAllGridRole")))
	  }	




saveGridAndRole(reqBody): Observable<any> {
	let initialUrl = this.defaultUrl + "canonGridRole/saveGridAndRole"; // body : {"gridRoles":[{"gridCode":"METER_READ","roleName":"DASHBOARD_ROLE"}]}
	console.log("Pritnign initialUrl: " + initialUrl);
	return this.http
	  .post(initialUrl, reqBody, httpJsonOptions)
	  .pipe(catchError(this.util.handleError("getAllGridRole")))
  }	

}


