export class canonRegisterUser {
    
    emailAddress: string;
    serialNumber: string;
    model: string ;
    zipCode: string;
    firstName:string;
    lastName:string; 
    phoneNumber:string;  
    errorMessage:string;  
    adminOpt:string;
    source:string;
    defCustAccount:string;
    regType:string;
    regValues:string = "";
    roles:string;
    partySiteNum: string;
    notes: string;
    username: string;
    
 }