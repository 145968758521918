import { Component, EventEmitter, OnInit,Output,TemplateRef,ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { SwitchUserService } from './switch-user.service';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';
 

@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.css']
})
export class SwitchUserComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  @ViewChild('suModal') suTemplate:TemplateRef<any>;
  @Output() removeSwitchUserClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() switchUserClick: EventEmitter<void> = new EventEmitter<void>();
  suModalRef: BsModalRef;

  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[];
  userList:any=[];
  userName:string="";
  showMsg:any=false;
  msg:any="";
  bUserSwitched:boolean=false;
  isWebAdmin:boolean=false;

  constructor(private suService:SwitchUserService,private commonService:CommonServiceService,private util : Util, private modalService: BsModalService, private router: Router, private route:ActivatedRoute) {
  
 }
  ngOnInit() {
   this.checkUser(); 
     
  }

  
  getUserList (pgNum: any,event: any){
    this.msg="";
    this.showMsg=false;
    if(event!=null){
     event.preventDefault();
   }
   if(this.userName.length<1){
    this.msg="Please enter User Name";
    this.showMsg=true;
    return false;
   }else{
    this.userName=this.userName.toUpperCase();
   }
   
   this.blockUI.start();
   this.pageNum=pgNum;
   let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
                               "userName":this.userName };
   let data = this.suService.getUserList(oParams).subscribe(
       data=>{
          if(data){
            this.userList = data.userList; 

            for(let k in this.userList){
               let tmpUser=this.userList[k];
               let tmpUsername=tmpUser.userName;
               if(tmpUsername.length>30){
                tmpUsername= tmpUsername.replace("@","\n@");
                tmpUser.userName=tmpUsername;
                this.userList[k]=tmpUser;
               }

            }

            this.totalRecords=data.totalRecords;
            this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
            this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
            this.blockUI.stop();
            if(this.totalRecords<1){
              this.msg="No records found.";
              this.showMsg=true;
            }
           
          }
       });

   
 }

 checkSwitchUser(){
  let oParams = { };
  this.blockUI.start();
  let data = this.suService.checkSwitchUser(oParams).subscribe(
    data=>{
       if(data){
         let flg = data["flag"]; 
         this.blockUI.stop();
         if(flg=="Y"){
           this.bUserSwitched=true;
          }else{
            this.bUserSwitched=false;
         }
        }
    });
 }

 
 checkUser(){
  let oParams = { };
  this.blockUI.start();
  let data = this.suService.checkUser(oParams).subscribe(
    data=>{
       if(data){
         let wa = data["webadmin"]; 
         this.blockUI.stop();
         if(wa==true){
           this.isWebAdmin=true;
           this.checkSwitchUser();
          }else{
            this.isWebAdmin==false;
         }
        }
    });
 }

  removeSwitchUser(){
    let oParams = {};
    this.blockUI.start();
    let data = this.suService.removeSwitchUser(oParams).subscribe(
      data=>{
         if(data){
           let flg = data["flag"];           
           if(flg=="S"){
             this.bUserSwitched=false;
            /*
              //this.removeSwitchUserClick.emit();  
              //console.log("Printing to test Switch Back...");  
              //this.router.navigate(['/home']); 
              // this.router.navigateByUrl('/home', { skipLocationChange: true }); 
              //this.suService.notifySwitchUser();   
              // this.router.navigateByUrl('/'); 
            */
             this.blockUI.stop();
             this.router.navigate(['home']).then(() => {
               window.location.reload(); 
             });  
            } 
          }
      });
  }

 openMdlAndGetUserList(event:any){
  
  this.openSuModel();
  
 }

 switchUser (u:any){
  this.blockUI.start();
  this.showMsg=false;
  this.msg="";
  let flName="";
   flName=u["lastName"];
  if(flName&&flName.length>0)
    flName+=',';
  flName+=u["firstName"];
  flName= flName.replace("'","&apos;");
  var sUserName="";	 
  sUserName=u["userName"];
  sUserName=sUserName.replace(/'/g, "&#39;");
  sUserName=sUserName.replace(/\n/g, "");

  let oParams = { "suUserName": sUserName,   "fullName":flName};
  let data = this.suService.switchUser(oParams).subscribe(
    data=>{
      console.log("Printing Switch User response data: ", data);
       if(data){
         let flg = data["flag"];          
         if(flg=="S"){
          //this.switchUserClick.emit();
           this.bUserSwitched=true;          
           this.closeSuModel();
          this.suService.notifySwitchUser();
          //this.router.navigate(['/home']);  
         if(sUserName.indexOf("@")>0){
          this.router.navigateByUrl('/home', { skipLocationChange: false }); 
          this.blockUI.stop();
         }else{
          this.blockUI.stop();
          this.router.navigate(['home']).then(() => {
            window.location.reload(); 
          });
         } 
         
        
         // window.location.reload();
          
         }else{
           this.msg="Error in Switch User";
           this.showMsg=true;
         }
        }
    });



 }

 clearSearch(event:any){
  if(event){
    event.preventDefault();
  }
  this.userName="";
  this.recPerPage="10";
  this.showMsg=false;
  this.msg="";
  this.userList=[];
  this.arrPgLinks=[];
 }

 openSuModel() {
  this.suModalRef = this.modalService.show(this.suTemplate);
 }

 closeSuModel() {
  this.suModalRef.hide(); 
  this.clearSearch(null);
}

}
