import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemadministrationEditlinksService } from './systemadministration-editlinks.service';

@Component({
  selector: 'app-systemadministration-editlinks',
  templateUrl: './systemadministration-editlinks.component.html',
  styleUrls: ['./systemadministration-editlinks.component.css']
})
export class SystemadministrationEditlinksComponent implements OnInit {

  thereForeURL:any="";
  veraURL:any="";
  prismalyticsURL:any="";
  presseroURL:any="";
  colorlynxURL:any="";
  colorlynxProfilerURL:any="";
  irisxtractURL:any="";
  openTextRightFaxURL:any="";
  fax2mailURL:any="";
  intelligentgradingURL:any="";
  eCopyURL:any="";
  uniFlowURL:any="";

  constructor(private systemadministrationEditlinksService:SystemadministrationEditlinksService,private router: Router) { }

  ngOnInit() {
    this.systemadministrationEditlinksService.getUrl().subscribe(
      data=> {
        console.log("Printing url: ");
        console.log(data);
        this.thereForeURL= data.soluSupp.therefore;
        this.veraURL= data.soluSupp.vera;
        this.prismalyticsURL=data.soluSupp.prismalytics;
        this.presseroURL= data.soluSupp.pressero;
        this.colorlynxURL= data.soluSupp.colorlynx;
        this.colorlynxProfilerURL=data.soluSupp.colorlynxProfiler;
        this.irisxtractURL= data.soluSupp.irisxtract;
        this.openTextRightFaxURL=data.soluSupp.openTextRightFax;
        this.fax2mailURL=data.soluSupp.fax2mail;
        this.intelligentgradingURL=data.soluSupp.intelligentgrading;
        this.eCopyURL=data.soluSupp.eCopy;
        this.uniFlowURL=data.soluSupp.uniFlow;

      }
    );

  }

  		//openTextRightFax
      //$("#openTextRightFaxTestBtn")
      testSolutionSuppURL(url){
        console.log("Printing url: "+ url);
if ((url).trim().length < 1) {
  alert("Enter URL to test.");
  return;
} else {
  var newWindow = window
      .open(url, 'Test',
          'scrollbars,width=800,height=550,left=100,top=100');
  newWindow.focus();
}

  }

  SaveSolutionSuppURL(save){
    console.log("Printing save method name: "+ save);
    var input:any={};
    input.uniFLOWInput=this.uniFlowURL;
    input.thereforeInput=this.thereForeURL;
    input.irisxtractInput=this.irisxtractURL;
    input.veraInput = this.veraURL;
    input.prismalyticsInput = this.prismalyticsURL;
    input.presseroInput = this.presseroURL;
    input.colorlynxInput = this.colorlynxURL;
    input.colorlynxProfilerInput = this.colorlynxProfilerURL;
    input.openTextRightFaxInput = this.openTextRightFaxURL;
    input.fax2mailInput = this.fax2mailURL;
    input.intelligentgradingInput = this.intelligentgradingURL;
    input.eCopyInput = this.eCopyURL;



    this.systemadministrationEditlinksService.updateSolutionSuppURLs(input,save).subscribe(
      data=>{
        if((data)!="Y" || (data)=="" || (data)==undefined || (data)==null){
          $('#counterMsgEr')
              .html(
                  "We were unable to process your request. Please confirm you have entered all information below.</br>If you continue to have an issue, please contact Customer Service at 1-800-613-2228.");
        } else if ((data) == "Y") {
          $('#counterMsgSu')
              .html(
                  "Request submitted successfully.");
        }

      }
    );

  }



}
