import { Component, Input, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { DefineLevelsService } from 'src/app/admin/configurator/define-levels/define-levels.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ManageleveldetailsService } from './manageleveldetails.service';

@Component({
	selector: 'app-manageleveldetails',
	templateUrl: './manageleveldetails.component.html',
	styleUrls: ['./manageleveldetails.component.css']
})
export class ManageleveldetailsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;

  levelList_org = [];
  levelList = [];
  managelevelList_org = [];
  managelevelList = [];
  rootLabel:string="";
  id:string="";
  labelId:string="";
  edit:boolean=false;
  save:boolean=false;


  

  constructor(private commonService:CommonServiceService, private levelService : DefineLevelsService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title, private activatedRoute: ActivatedRoute,
				private manageleveldetailsService:ManageleveldetailsService) { }

  partyId;
  templateId;  
 
  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
	/*
	this.activatedRoute.queryParams.subscribe(params => {
		this.partyId = params['partyId']; 
  		this.templateId = params['templateId'];
		this.getUserLevels();
	});		
	*/
	
	this.partyId = this.param.partyId;
    this.templateId = this.param.templateId;
	//this.getUserLevels();
  this.displayLabelLevelDetails(this.templateId,this.partyId);
 
  }


  displayLabelLevelDetails(templateId, partyId) {
		//alert(templateId + "--------" + partyId);
		if(templateId >= -1)
		{
			var urlData = "&templateId=" + 103 + "&partyId=" + 7341073;

      var resp = this.manageleveldetailsService.getconfigLabelLevelDetails(urlData).subscribe(
        (res: any) =>{
    //      if(data.errFlg=='S' ){ //|| (flg=='E' && errMsg == 'No Label found in the previous tab')
			console.log();
      this.managelevelList_org = [];
      res.data.forEach(val => this.managelevelList_org.push(Object.assign({}, val)));
      this.managelevelList = res.data;
	  this.rootLabel= res.rootLabel;
	  this.edit=true;
		this.save=false;
	  console.log("this.managelevelList == " + this.managelevelList);
						//this.prepareTreeStructure(data.data, data.templateId, data.rootLabel);
					// }else{
					// }
        }
      );
		}	
	}




  onAddLevel() {
	
	$(".editBtn").attr("disabled", "disabled");	
	$("#addBtn").attr('disabled', 'disabled');
	$("#editBtn").html("Save");
	this.edit=false;
	this.save=true;
	// parent id is the Id of prev entry if none present then -1
	var rootId = -1;
	if(this.managelevelList.length > 0) {
		rootId = this.managelevelList[this.managelevelList.length - 1].id;
	}
	var paramObj = {};
	//paramObj["partyId"] = "14669070";
      paramObj["templateId"] = this.templateId;

	var newLevel = {
	//	"id" : "",
		"rootId" : rootId,
		"templateId" : this.templateId + "",
		"partyId" : this.partyId + "",
		"name" : ""
	}

	  this.managelevelList.push(newLevel);
	
	this.manageleveldetailsService.createNewEntry(paramObj).subscribe(
        (response: any) => {
		this.id=response.data.id;
		this.labelId=response.data.labelId;
          console.log("this.id ==" + this.id +
		  "      this.labelId ==" + this.labelId);
          this.blockUI.stop();
        }
      );
    }
	


  onCancel() {
	//this.levelList = this.levelList_org;
	this.levelList = [];
	this.levelList_org.forEach(val => this.levelList.push(Object.assign({}, val)));;
	
	$(".editBtn").removeAttr('disabled');
	$("#addBtn").removeAttr('disabled');
	$(".leveltxt").attr('disabled', 'disabled');
	$(".editBtn").show();
	$(".saveBtn").css('display', 'none');
  }

  onEdit(idx) {
	$("#addBtn").attr('disabled', 'disabled');
	$(".editBtn").attr("disabled", "disabled");
	$("#editBtn"+idx).html("Save");
	$("#level"+idx).removeAttr('disabled');
	this.save=true;
	this.edit=false;
	//$("#chkLvl"+idx).removeAttr('disabled');
	
	$("#saveBtn"+idx).css('display', 'flex');
	
  }
  
  //onSave(idx) {

	onSave(idx) {


		var param = {};
		param["templateId"] = this.templateId;
		if(this.id !=null && this.id != ""){
			param["labelLineId"] = this.id;
		}else{
		param["labelLineId"] = this.managelevelList[idx].rootId;
		}
		param["labelLineName"] = this.managelevelList[idx].name;//inv.comment;
		param["parentId"] = -1;//this.managelevelList[idx].parentId;
		if(this.labelId !=null && this.labelId != ""){
			param["labelId"] = this.labelId;
		}else{
		param["labelId"] = this.managelevelList[idx].labelId;
		}

	
		// var param = {
		// 	"templateId" : this.templateId ,
		// 	"labelLineId" : this.managelevelList[idx].rootId,
		// 	"labelLineName" :  this.managelevelList[idx].name,   // this. levelList[idx].status,
		// 	"parentId" : this.managelevelList[idx].parentId,
		// 	"labelId" : this.managelevelList[idx].labelId
		// }
		
		
	console.log(param);
	this.blockUI.start();

	this.manageleveldetailsService.updateEntry(param).subscribe(
        (response: any) => {
		console.log("update response == " + response.data);
		this.displayLabelLevelDetails(this.templateId,this.partyId);
          this.blockUI.stop();
        }
      );
	
	
  }


  onDelete1(idx) {
console.log("In the Delete");

var param = {
	"templateId" : this.templateId ,
	"labelLineId" : this.managelevelList[idx].rootId,
	"labelLineName" :  this.managelevelList[idx].name,   // this. levelList[idx].status,
	"parentId" : this.managelevelList[idx].parentId,
	"labelId" : this.managelevelList[idx].labelId
}

	
	console.log(param);
	this.blockUI.start();
	this.manageleveldetailsService.deleteUserLevel(param).subscribe(
        (response: any) => {
		console.log("update response == " + response.data);
		this.displayLabelLevelDetails(this.templateId,this.partyId);
          this.blockUI.stop();
        }
      );
	
	
  }
	

}
