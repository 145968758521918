import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


export class Menu{
  constructor(
    public appCode : string,
    public roleId : string,
    public roleDescription : string,
    public menuId : string,
    public immediateParent : string,
    public entity : string,
    public lvl : string,
    public readonlyFlag : string,
    public menuClass : string,
    public pgName : string,
    public pgDefControllerUrl : string,
    public pgCompControllerUrl : string,
    public menuDescription : string,
    public mainNavID : string,
  ) {}
}


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util) {
    this.baseUrl = this.util.getBaseUrl();
  }

  getMenu() : Observable<Menu[]>{
    let url = this.baseUrl + 'gm';

    //return this.http.post<Employee[]>(url, '', httpOptions).pipe(catchError(this.handleError()));
    //.pipe(catchError(this.util.handleError("getMenu")));

    return this.http.post<Menu[]>(url, '', httpOptions).pipe(catchError(this.handleError<Menu[]>('getMenu', [])));
  }

  handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
  

}
}
