import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DeviceDetailsService } from './device-details.service';
import { event, param } from 'jquery';
import { OpenOrderService } from 'src/app/product-supplies/open-orders/open-orders.service';
import { Util } from 'src/app/app.util';
import { Title } from '@angular/platform-browser';
import { MeterHistoryService } from 'src/app/meter-reads/meter-history/meter-history.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ContractSuppliesService } from 'src/app/product-supplies/contract-supplies.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.css']
})
export class DeviceDetailsComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  @ViewChild('dealerMsgModal') dealerMsgTemplate:TemplateRef<any>;
  dealerMsgModalRef: BsModalRef
  
serialNumber: any;
  instanceId: any;
  externalRef: any;
  model: any;
  installAddress: any;
  source: any;
  contractNum: any;
  isSourceOracle: any;
  pageNum=1;
  totalRecords=0;
  recPerPage=10;
  length=1000
  showMsg: boolean;
  machineConfigList:any=[];
  numOfRecsMsg: any;
  hasAccess:any;
  deviceStatus:any=[];
  lastCommunicationDate: any;
  salesRepName: any;
  salesRepEmail: any;
  imagewareStatus: any;
  machineAddress: any;
  invoiceHistList:any=[];
  serviceHistList:any=[];
  orderBy:string="";
  sortColumn: string="";
  start:number=0;
  end:number=10;
  supplyHistList:any=[];
  meterHistList:any=[];
  orderHeader: any;
  orderLineList: any;
  isBillableOrder: any;
  invList: any;
  machinedetails: any = [];
  billHistorydetails: any = [];
  assignedUserList: any = [];
  totalMeterRecords=0;
  invMsg: string;
  meterMsg: string;
  supMsg: string;
  serMsg: string;
  defaultUrl: any;
  note1:string="";
  note2:string="";
  defaultTab : string = 'autotoner';
  defaultHistoryTab : string='invoicehistory';
  pageSource : string = 'devicedetails';
  dealerMsg: string;
  hasOSAccess: any;
  hasSRAccess: any;
  hasSMRAccess: any;
  salesRepUser: any;
  machineConfigRecordsTotal:any;
  fromPage:any;
  invoiceCount:0;
  serviceHistCount:0;
  meterReadCount:0;
  supplyHistCount:0;
  pageNum1:1;
  pageNum2:1;
  pageNum3:1;
  pageNum4:1;
  constructor(private route : ActivatedRoute,private deviceService:DeviceDetailsService,private openOrderService: OpenOrderService,private meterHistoryService: MeterHistoryService,
    private util : Util, private router: Router,private titleService: Title, private commonService : CommonServiceService, private csService : ContractSuppliesService,private modalService: BsModalService) { 
    this.defaultUrl = this.util.getBaseUrl();
  }

  ngOnInit() {
    this.titleService.setTitle("Dashboard View");
    this.route.queryParams.subscribe(
      params => {
        this.serialNumber =params['serialNumber'];
        this.instanceId = params['instanceId'];
        this.externalRef = params['externalRef'];
        this.model = params['model'];
        this.installAddress = params['installLocation'];
        this.source = params['source'];
        this.contractNum = params['contractNumber'];
        this.note1 = params['note1'];
        this.note2 = params['note2'];
        this.pageSource = params['pageSource'];
        this.fromPage = params['fromPage'];
        console.log("Printing fromPage value: "+ this.fromPage);

        console.log('this.pageSource:'+  this.pageSource);
        
      }
    )
    if(this.source =='Oracle')
    {
      this.isSourceOracle =true;

    }else{
      this.defaultTab =  'configuration';
    }

    if(this.pageSource == 'ordersupplies'){
      //this.router.navigate([], { fragment: "meterReads" });
      this.defaultHistoryTab = 'supplyhistory';
      this.getSupplyHistory(1, null);
    }
    this.getDeviceStatus();
    this.getMachinConfigurations(false);
    this.getInvoiceDetailsRequestJSON(1,null, false);
   // this.getClosedServiceRequestsJSON(1,null);
   // this.getInvoiceDetailsRequestJSON(1,null);
    //this.getSupplyHistory(1,null);
    //this.getMeterHistory();
    this.commonService.setCommonFunctions();
  }

  getMachinConfigurations(waitLoad:boolean){
   
   //this.pleaseWaitCls=true;
   if(waitLoad==true){
   this.blockUI.start();
   }
   this.invMsg="";
   this.supMsg="";
   this.serMsg="";
   let oParams = {"serialNumber":this.serialNumber,"instanceId":this.instanceId};
   let data = this.deviceService.getMachinConfigurations(oParams).subscribe(
     data=>{        
        //this.totalRecords=data.NumRecs; 
        if(waitLoad==true){
        this.blockUI.stop();
        }     
      
         this.machineConfigList = data.data;
         this.machineConfigRecordsTotal = data.recordsTotal;     
         console.log("machineConfigList:"+this.machineConfigList);   
       
     });
     
 }
 getDeviceStatus(){
  this.invMsg="";
  this.supMsg="";
  this.serMsg="";
  //this.pleaseWaitCls=true;
  this.blockUI.start();
  let oParams = {"serialNumber":this.serialNumber,"instanceId":this.instanceId};
  let data = this.deviceService.getDeviceStatusJSON(oParams).subscribe(
    data=>{        
       //this.totalRecords=data.NumRecs; 
       this.blockUI.stop();     
     
        this.deviceStatus = data.adList;    
        this.lastCommunicationDate = this.deviceStatus[0].lastCommunicationDate; 
        this.salesRepName  = this.deviceStatus[0].salesRepName; 
        this.salesRepEmail = this.deviceStatus[0].salesRepEmail; 
        this.imagewareStatus  = this.deviceStatus[0].imagewareStatus; 
        this.machineAddress  = this.installAddress; 

        this.hasOSAccess = data.hasOSAccess;
        this.hasSMRAccess = data.hasSMRAccess;
        this.hasSRAccess = data.hasSRAccess;
        this.salesRepUser = data.salesRepUser;
        console.log("lastCommunicationDate:"+this.lastCommunicationDate);   
      
    });    
}
getInvoiceDetailsRequestJSON(pgNum: any,event: any, waitLoad:boolean){
  this.invMsg="";
  this.supMsg="";
  this.serMsg="";
  //this.pleaseWaitCls=true;
  this.pageNum1=pgNum;
    if(waitLoad==true){
      this.blockUI.start();
    }
 
  this.showMsg=false;
  let oParams = {"serialNumber":this.serialNumber,"instanceId":this.instanceId,"pageNum": pgNum, "length": this.length,"sord":this.sortColumn,"start": this.start};
  let data = this.deviceService.getInvoiceDetailsRequestJSON(oParams).subscribe(
    data=>{        
       this.totalRecords=data.NumRecs; 

        if(waitLoad==true){
        this.blockUI.stop();
        }     
       if(this.totalRecords == 0) {
        this.showMsg=true;
        }
      else
      {
        this.showMsg=false;
        this.invoiceHistList = data.adList; 
        this.invoiceCount= this.invoiceHistList.length;   
        console.log("invoiceHistList:"+this.invoiceHistList.length);   
      }
      
    });
    
}

getClosedServiceRequestsJSON(pgNum: any,event: any){
  this.invMsg="";
  this.supMsg="";
  this.serMsg="";
  //this.pleaseWaitCls=true;
  this.pageNum3=pgNum;
  this.blockUI.start();
  this.showMsg=false;
  let oParams = {"serialNumber":this.serialNumber,"instanceId":this.instanceId,"pageNum": pgNum, "length": this.length,"sord":this.sortColumn,"start": this.start};
  let data = this.deviceService.getClosedServiceRequestsJSON(oParams).subscribe(
    data=>{        
       this.totalRecords=data.NumRecs; 
       this.blockUI.stop();     
       if(this.totalRecords == 0) {
        this.showMsg=true;
        }
      else
      {
        this.serviceHistList = data.adList;     
        console.log("serviceHistList:"+this.serviceHistList);   
      }
      
    });
    
}
getSupplyHistory(pgNum: any,event: any){
  this.invMsg="";
  this.supMsg="";
  this.serMsg="";
  //this.pleaseWaitCls=true;
  this.pageNum4=pgNum;
  this.blockUI.start();
  this.showMsg=false;
  let oParams = {"serialNumber":this.serialNumber,"instanceId":this.instanceId,"pageNum": pgNum, "length": this.length,"sord":this.sortColumn,"start": this.start};
  let data = this.deviceService.getSupplyHistory(oParams).subscribe(
    data=>{        
       this.totalRecords=data.NumRecs; 
       this.blockUI.stop();     
       if(this.totalRecords == 0) {
        this.showMsg=true;
        }
      else
      {
        this.showMsg=false;
        this.supplyHistList = data.adList;
        this.supplyHistCount=this.supplyHistList.length;     
        console.log("supplyHistList:"+this.supplyHistList);   
      }
      
    });
    
}
getMeterHistory(){
  this.invMsg="";
  this.supMsg="";
  this.serMsg="";
  this.pageNum2=1;
  //this.pleaseWaitCls=true;
  this.blockUI.start();
  this.showMsg=false;
  var paramObj = {};
  paramObj["serialNumber"] = this.serialNumber;
  paramObj["conNum"] = this.contractNum;
  paramObj["model"] = this.model;
  paramObj["address"] = this.installAddress;
  paramObj["instanceID"] = this.instanceId;
  paramObj["source"] = this.source;
  paramObj["dbuid"] =sessionStorage.getItem('userName');
  this.meterHistoryService.gsnld(paramObj).subscribe(
    (response: any) => {
      if(response != null)
      {
      this.machinedetails = response;
      this.billHistorydetails = response.billHistorydetails;
      this.totalRecords=this.billHistorydetails.length; 
      this.meterReadCount=this.billHistorydetails.length;
      this.assignedUserList =response.assignedUserList;
      }
      else
      {
        this.meterMsg ="Meter reading information of the selected machine does not exist because selected machine is non-metered model.";
      }
     this.blockUI.stop();
      console.log("machinedetails:"+this.machinedetails);  
    }  
  );
  console.log("getMeterHistory:");  
    
}

getOrderDetails(ordID, src, totPrice) {
	
	this.orderHeader = undefined;
	this.orderLineList = [];
	this.isBillableOrder = false;
	this.invList = "";
	
	/*
	var param = {
		"orderId" 	: "20409422",
		"orderBy" 	: "PART_NUM",
		"sortBy" 	: "ASC",
		"action" 	: "closed",
		"source" 	: "ORACLE",
		"totalAmt" 	: "0.00"
	}
	*/
	
	var param = {
		"orderId" 	: ordID,
		"orderBy" 	: "PART_NUM",
		"sortBy" 	: "ASC",
		"action" 	: "open",
		"source" 	: src,
		"totalAmt" 	: 0
	}
	this.blockUI.start();
	let data = this.openOrderService.getOrderDetails(param).subscribe(
      data=>{
        console.log(data);
		this.orderHeader = data.orderHeaderDetails;
  		this.orderLineList = data.orderLineDetails;
      for(let i =0;i<this.orderLineList.length;i++){
        if(this.orderLineList[i].strReqDlvyDate==undefined || this.orderLineList[i].strReqDlvyDate==null || this.orderLineList[i].strReqDlvyDate==''){
          this.orderLineList[i].strReqDlvyDate='-';

        }

      }

      console.log(this.orderLineList);
  		this.isBillableOrder = data.isBillableOrder;
  		this.invList = data.invoiceDetail;
      this.blockUI.stop();
      });		
  }	

  getInvHistoryPdf(invNum:any,event){
    if(event){
      event.preventDefault();
    }
    var url= this.defaultUrl + "openSupply/opInvoiceDetailForEmanage?invoice_number="+invNum;
    window.open(url);

  }
  onPrint(){
    window.print();
  }
  

  eLearningRequest(){
    this.commonService.eLearningAuthentication();
  }
  serviceRequest(){

    let params = {
      "srCreateFlag": 'Y', 
      "srl": this.serialNumber
    }

    this.router.navigate(['servicerequest'], {queryParams : params});

  }

  submitMeterRead(){

    let params = {
      "srl": this.serialNumber
    }

    this.router.navigate(['submitmeterreads'], {queryParams : params});

  }


  isPurchaseable(){

    let purchaseableParams = { 
      "serialNumber": this.serialNumber,
      "contractNumber" : this.contractNum
    };
    
    this.csService.isCSPurchaseable(purchaseableParams).subscribe(
      data =>{
        if(data.status == 'S') {
          if(data.message != ''){
              this.dealerMsgModalRef = this.modalService.show(this.dealerMsgTemplate);
              this.dealerMsg = data.message;
          } else {

            let params = { 
              "serialNumber": this.serialNumber,
              "searchAll" : 'Y'
            };

            this.csService.getDeviceListing(params).subscribe(
              data => {
                if(!data.error_flag) {
                  if(data.data.length > 0){
                    var d = data.data[0];	

                    let qParams = {
                      "serialNum": this.serialNumber,
                      "contractId" : d.contractId,
                      "contractNum" : d.contractNumber,
                      "sectionId" : d.sectionid,
                      "instanceId" : d.instanceId,
                      "model" : d.model,
                      "source" : this.source,
                      "address" :  d.address
                    }
                
                    this.router.navigate(['osmeterreads'], {queryParams : qParams});

                    
                  } else {


                    let qParams = {
                      "serialNum": this.serialNumber,
                    }
                    this.router.navigate(['ordersupplies'], {queryParams : qParams});
                  }
                 } 
              }
            )
          }
         } 
      }
    );

  }

  closeDealerMsgModel() {
    this.dealerMsgModalRef.hide();
  }

  redirectToPrevious(){
    console.log("Printing inside  redirectToPrevious method: " + this.fromPage);
    if(this.fromPage=='accountDevices'){
      this.router.navigate(['accountdevices']);

    }

    else{
      this.router.navigate(['mydevices']);
    }

    }

    stopVideo(id: string){
      console.log('Printing id value: ', id);
      const video = document.getElementById(id) as HTMLVideoElement;
      if(video){
       video.pause();
       //video.load();
      }
     }  



  }
