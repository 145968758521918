import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { EliteService } from './elite.service';
import { Router } from '@angular/router';
 

@Component({
  selector: 'app-elite-home',
  templateUrl: './elite-home.component.html',
  styleUrls: ['./elite-home.component.css']
})
export class EliteHomeComponent implements OnInit {

  
@BlockUI() blockUI: NgBlockUI;
manufactureList: any = [];
modelList: any = [];
manufacturer: string = "";
model: string = "";
serialNumber: string = "";
zipcode: string = "";
showSearch: boolean = false;


  constructor(private eliteService: EliteService,private router: Router) { }

  ngOnInit() {
  //  this.manufacture_model();
  }


  manufacture_model() {
    this.blockUI.start();
    this.eliteService.getManufacture_model().subscribe(
      (res: any) => {
        for (let i = 0; i < res.lstManObj.length; i++) {
          if (i == 0) {
            this.manufacturer = res.lstManObj[i].strManufacturer;
          }
          this.manufactureList.push(res.lstManObj[i].strManufacturer);
        }
        for (let i = 0; i < res.lstMdlObj.length; i++) {
          if (i == 0) {
            this.model = res.lstMdlObj[i].strModel;
          }
          this.modelList.push(res.lstMdlObj[i].strModel);
        }
        this.blockUI.stop();
      });
  }


  changeManufacturer(manufacturer) {
    var paramObj = {};
    paramObj["manufacturer"] = manufacturer;
    this.blockUI.start();
    this.eliteService.getModels(paramObj).subscribe(
      (res: any) => {
        this.modelList = [];
        for (let i = 0; i < res.modelList.length; i++) {
          this.modelList.push(res.modelList[i].strModel);
        }
        this.blockUI.stop();
      }
    );
  }

  loadEntryHistory(sType){
    let qryStr="manufacturer="+this.manufacturer+"&model="+this.model+"&serialNumber="+this.serialNumber
    +"&zipcode="+this.zipcode+"&rdType="+sType;
    this.router.navigateByUrl('/elitereads?'+qryStr);
  }

  enableSubmit() {  
    
    if (this.serialNumber.length<1  || this.zipcode.length<1 ) {
      this.showSearch = false;
    }
    else {
      this.showSearch = true;
    }
    
  }


}
