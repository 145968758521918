import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValuePipe } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-other-canon-products-shopcart',
  templateUrl: './other-canon-products-shopcart.component.html',
  styleUrls: ['./other-canon-products-shopcart.component.css']
})
export class OtherCanonProductsShopcartComponent implements OnInit {
 @BlockUI() blockUI : NgBlockUI;
  cart: any;
  onlyNumbers:any = /^\d+$/;
  totalCount:any = 0;
  grandTotalCount:any=0;

  constructor(private router: Router) { }

  ngOnInit() {
	this.blockUI.start();
    this.getCart();
    this.totalCartItems();
    this.grandTotal();
	this.blockUI.stop();

  }


  getCart() {
		if (localStorage.getItem('itcgCart') != null) {
			this.cart = JSON.parse(localStorage.getItem('itcgCart'));
		}
		return this.cart;
	}

  updateProductQty(currentKey, product) {
	console.log("Printing currentKey", currentKey);
	console.log("Printing product", product);
		this.cart = this.getCart();

		if(this.cart.productMap != null && this.cart.productMap != 'undefined')
		{
			for( var key in this.cart.productMap){
				if(key == currentKey){
					this.cart.productMap[key] = product;
					break;
				}
			}
			this.setCart(this.cart);
		}
		this.updateAllSelectedCarePak();
		console.log('Updated Quantity', this.cart);
	    this.totalCartItems();
        this.grandTotal();
		return this.cart;
	}

setCart(cart) {
		if (cart != null) {
			localStorage.setItem('itcgCart', JSON.stringify(cart));
		}
	}

  updateAllSelectedCarePak(){
		var cart:any = this.getCart();
		for(var productKey in cart.productMap){
			var carPaklist = cart.productMap[productKey].carePakOptions;
			for(var cpIndex in carPaklist){
				if(carPaklist[cpIndex].carePakItemNumber == cart.productMap[productKey].selectedCarePak.carePakItemNumber){
					cart.productMap[productKey].selectedCarePak = carPaklist[cpIndex];
				}
			}
		}
		return cart;
	}

	changeCarePakInfo(currentKey, product){
		var changeKey = product.itemNumber + '_' + product.selectedCarePak.carePakItemNumber;
		var isExist = this.findOtherSameItemAndUpdateQty(currentKey, changeKey, product.selectedQty);
		var tempProductMap = {};
		if(!isExist){
			this.cart = this.getCart();
			if(this.cart.productMap != null && this.cart.productMap != 'undefined')
			{
				for( var key in this.cart.productMap){
					if(key == currentKey){
						/*cart.productMap[changeKey] = cart.productMap[currentKey];
						delete cart.productMap[currentKey];*/
						tempProductMap[changeKey] = product;
						//break;
					}else {
						tempProductMap[key] = this.cart.productMap[key];
					}
				}
				this.cart.productMap = tempProductMap;
			}
			this.setCart(this.cart);
		}
		this.updateAllSelectedCarePak();
		console.log('Change Care Pak Info', this.cart);
		return this.cart;
	}


findOtherSameItemAndUpdateQty(currentKey, changeKey, changeQty){
		var itemQuntity = null;
		this.cart = this.getCart();
		var isExist = false;
		if(this.cart.productMap != null && this.cart.productMap != 'undefined')
		{
			for( var key in this.cart.productMap){
				if(key != currentKey && key == changeKey ){
					this.cart.productMap[key].selectedQty = 
						(parseInt(this.cart.productMap[key].selectedQty) + parseInt(changeQty)) + "";
					delete this.cart.productMap[currentKey];
					isExist = true;
					this.setCart(this.cart);
					break;
				}
			}
		}
		return isExist
	}


  removeProduct (key) {
		this.cart = this.getCart();
		
		delete this.cart.productMap[key];
		
		this.setCart(this.cart);
		this.updateAllSelectedCarePak();
    if(this.totalCartItems() == 0){
			//var url = "products.htm?view=CanonCpShopProducts";
		this.router.navigate(['othercanonproducts']);
		}
		return this.cart;
	}
  totalCartItems() {
		var length = 0;
		var cart:any = this.getCart();
    console.log("Pringing cart value..");
    console.log(cart);
		if(cart!=null && cart.productMap != null && cart.productMap != 'undefined')
		{
			length = Object.keys(cart.productMap).length;
		}
    this.totalCount=length;
		return length;
	}

  setDefaultQuantity (currentKey, product){
		//cartService.setDefaultQty(currentKey, product);
    this.setDefaultQty(currentKey, product);
	}

  setDefaultQty (currentKey, product ) {
		//console.log("quantity is...",$scope.selectedItem.selectedQty);
		var cart = this.getCart();
		if(product.selectedQty == "" || product.selectedQty == null || product.selectedQty == 'undefined'){
			product.selectedQty = "1";
			console.log("quantity is...",product.selectedQty);
			cart.productMap[currentKey] = product;
			this.setCart(cart);
		}
		return cart;
	}

  grandTotal() {
		this.cart = this.getCart();
		var grandTotal = 0;
		if(this.cart.productMap != null && this.cart.productMap != 'undefined')
		{
			for( var key in this.cart.productMap){
				grandTotal =  grandTotal 
					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].listPrice) 
					+ (this.cart.productMap[key].selectedQty * this.cart.productMap[key].selectedCarePak.listPrice);
			}
		}
		console.log('Calculated Grand Total ', grandTotal);
    this.grandTotalCount=grandTotal;
		return grandTotal;
	}
  checkout(){
		//var url = "products.htm?view=CanonCpShopCheckout";
		this.router.navigate(['othercanonproductcheckout']);
	}

  continueShopping(){
		// var url = "products.htm?view=CanonCpShopProducts";
		// window.location.href = url;
    this.router.navigate(['othercanonproducts']);
	}

}
