import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-p66-login',
  templateUrl: './p66-login.component.html',
  styleUrls: ['./p66-login.component.css']
})
export class P66LoginComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  username : string
  errorMessage: string;
  invalidLogin: boolean;
  constructor(private router: Router, private loginAuthenticationService: LoginAuthenticationService, private titleService: Title){ }

  ngOnInit() {
    this.blockUI.start();
    this.loginAuthenticationService.executeJDBCAuthenticationService('', '').subscribe(
      data => {
        console.log('I am inside p66 auto login component');
        if (data && data.authenticated == true) {
          this.blockUI.stop();
          this.router.navigate(['home']);
        }
        else{
          this.blockUI.stop();
          this.router.navigate(['/']);
        }
  },
        error => {
          console.log(error);
          this.errorMessage = 'Invalid username and/or password';
          this.invalidLogin = true;
          this.blockUI.stop();
        }
    );
  }
}
