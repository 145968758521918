import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ManageUserService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

    getManageUserList = (paramObj) => {
      let url = this.defaultUrl + 'manageuser/getuserslist?';
      let params = new URLSearchParams();
      for (let key in paramObj) {
          params.set(key, paramObj[key])
      }
      let qryStr = params.toString();
      url = url + qryStr;

      return this.http
          .post(url, qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

  }

  UnAssignUsers = (paramObj) => {
    let url = this.defaultUrl + 'manageuser/manageusers?';
    let params = new URLSearchParams();
    for (let key in paramObj) {
        params.set(key, paramObj[key])
    }
    let qryStr = params.toString();
    url = url + qryStr;

    return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("UnAssignUsers"))));

}

assignUsers = (paramObj) => {
  let url = this.defaultUrl + 'manageuser/manageusers?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("assignUsers"))));

}

  getUserContactDetails = (paramObj):Observable<any> => {
    let url = this.defaultUrl + 'manageuser/getusercontactdetails?';
    let params = new URLSearchParams();
    for (let key in paramObj) {
        params.set(key, paramObj[key])
    }
    let qryStr = params.toString();
    url = url + qryStr;

    return this.http
        .post(url, qryStr, httpOptions);
      //  .pipe(catchError((this.util.handleError("getManageUser"))));

}


//getuserappentityroellist


getuserappentityroellist = (paramObj):Observable<any> =>{
  let url = this.defaultUrl + 'manageuser/getuserroles?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions);
    //  .pipe(catchError((this.util.handleError("getManageUser"))));

}

saveupdatedroles = (paramObj) => {
  let url = this.defaultUrl + 'manageuser/saveuserroleassignments?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions);
    //  .pipe(catchError((this.util.handleError("getManageUser"))));

}


updatecontactdetails = (paramObj) => {
  let url = this.defaultUrl + 'manageuser/updatecontactdetails?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions);
    //  .pipe(catchError((this.util.handleError("getManageUser"))));

}

}
