import { Component, OnInit ,TemplateRef,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Util } from 'src/app/app.util';
import { RequestListingService } from 'src/app/service-request/request-service/request-listing.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { OtherCanonProductsService } from './other-canon-products.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-other-canon-products',
  templateUrl: './other-canon-products.component.html',
  styleUrls: ['./other-canon-products.component.css']
})
export class OtherCanonProductsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  //@ViewChild('product-box-1') confirmTemplate:TemplateRef<any>;
  //confirmModalRef: BsModalRef

  cat:any= "";
  scrollAmount:any;

	//$scope.oService = orderService;
	newProducts = [];
  newProductsFiltered=[];
	selectedCategory ="";
	orderVal = "Sort";
	selectedCarePak = {};
	//selectedCarePak.description = "";
	numberOfItems = 24;
	isSalesRep = false;
	catLevel2List = [];
	selectedLevel2Category = "";
	catLevel3List = [];
	selectedLevel3Category = "";
	//cart = orderService.getCart();
	//showPleaseWait();
	//var pc = this;
	isProductsLoaded = false;
  newCategories: any=[];
  search: string;
  searchText: string;
  selectedItem: any={};
  pc: any;
  itemAddedMessage = true;
 totalItems:any=0;
  page:any=1;
  cart:any={};
  plength:any=0;
	//$scope.pleaseWaitCls = 'pleaseWaitCls';
  //var productPromise = productService.getProducts();
  constructor(private commonService:CommonServiceService,private otherCanonProductsService:OtherCanonProductsService,private router: Router, private modalService: BsModalService) { }

  ngOnInit() {
    this.blockUI.start();
    this.totalItems = this.totalItemsCount();
    console.log("Printing total Items in the cart: ", this.totalItems);
    $('#product-box-1').insertAfter('.itcg-shop-products-layout-wrapper');
	   
    //$('[data-toggle="tooltip"]').tooltip(); // use ngbTooltip in HTML wherever we are using it.
this.otherCanonProductsService.getProducts().subscribe(
  data=>{
    this.newProducts = data.PRODUCTS;
    this.newProductsFiltered= data.PRODUCTS;
    this.newCategories = data.CATEGORIES;
    console.log("Printing newCategories");
    console.log(this.newCategories);
    this.isSalesRep = data.isSalesRep;
    this.selectedCategory ="Printers";
    
    if(this.cat){
      this.selectedCategory = this.cat;
    }
    //$scope.pleaseWaitCls = '';
    //hidePleaseWait();
    this.isProductsLoaded = true;
    
    for ( let i in this.newProducts){
      
      this.newProducts[i].selectedQty = "1";
      this.newProductsFiltered[i].selectedQty ="1";


    }

    this.showCategoryLevel2();
    console.log('Products',this.newProducts);
    //console.log('Cart',this.cart);
    this.blockUI.stop();
  
  }
);
    
  }
  // showCategoryLevel2() {
  //   throw new Error('Method not implemented.');
  // }

  scrollToPgTopPagination(){
    $('html, body, .modal').animate({
           scrollTop: $("#pTop").offset().top-120
       }, 300);
 }

 handlePageChange(event) {
  this.page = event;
}

 buttonClick () {
  var container = document.getElementById('itcg-shop-products-promo-cate-inner');
  this.sideScroll(container,'right',5,600,10);
}

backButtonClick () {
  var container = document.getElementById('itcg-shop-products-promo-cate-inner');
  this.sideScroll(container,'left',5,600,10);
}

sideScroll(element,direction,speed,distance,step){
  this.scrollAmount = 0;
  
  
  var slideTimer = setInterval(function(){
      if(direction == 'left'){
          element.scrollLeft -= step;
      } else {
          element.scrollLeft += step;
      }
      
      
      this.scrollAmount += step;
      
    
      if(this.scrollAmount >= distance){
          window.clearInterval(slideTimer);
      }
  }, speed);
}

setSelectedCategory(catName){
  this.selectedCategory = catName;
  this.search = "";
  this.searchText="";
  this.numberOfItems = 24;
  this.showCategoryLevel2();
}

popUpItems(product){
  
  console.log('p....',product);
  this.pc = product;
  this.selectedItem = product;
  //angular.element('#itcg-product-popup-tab-Overview').trigger('click');
  if (document.querySelector('#itcg-product-popup-tab-Overview') !== null && document.querySelector('#itcg-product-popup-tab-Overview') !== undefined) {
  document.querySelector('#itcg-product-popup-tab-Overview').addEventListener;
  }
  if (document.querySelector('.features a') !== null && document.querySelector('.features a') !== undefined) {
  document.querySelector('.features a').classList.remove('active');
  }
  //angular.element('.features a').removeClass('active');
  //angular.element('.specs a').removeClass('active');
  if (document.querySelector('.specs a') !== null && document.querySelector('.specs a') !== undefined) {
  document.querySelector('.specs a').classList.remove('active');
  }
  if (document.querySelector('.includes a') !== null && document.querySelector('.includes a') !== undefined) {
  document.querySelector('.includes a').classList.remove('active');
  }
  //angular.element('.includes a').removeClass('active');

  this.otherCanonProductsService.getProductDetails(this.selectedItem.productId,this.selectedItem.itemNumber).subscribe(
    data=>{
      var specData = data;
      console.log('specData content : ', specData );
      
      this.selectedItem.carePakOptions = specData.carePakOptions;
      this.selectedItem.compatibility = specData.compatibility;
      this.selectedItem.features =  specData.features;
      this.selectedItem.overviewData =  specData.overview;
      this.selectedItem.specifications =  specData.specifications;
      this.selectedItem.relatedProds =  specData.relatedProds;
    var cpArray = this.selectedItem.carePakOptions;
    var maxCarePak = cpArray[0];
    var cpLength = cpArray.length; 
    if(cpLength > 1){
      for (let cpIndex of cpArray) {

          if(parseInt(cpIndex) < cpLength -1  && maxCarePak.listPrice <  cpArray[parseInt(cpIndex) + 1].listPrice){
            maxCarePak = cpArray[parseInt(cpIndex)+1];
          }
        
        }
      }

    this.selectedItem.selectedCarePak = maxCarePak;
    //angular.element('.overview a').addClass('active');
    document.querySelector('.overview a').classList.add('active');
    //angular.element('#itcg-product-popup-tab-Overview').addClass('active');
    document.querySelector('#itcg-product-popup-tab-Overview').classList.add('active');


  });
  //$("#product-box-1").modal('show'); // Use it in HTML with data-target like this: <a href="#" id="deleteOrd" type="button" class="btn btn-secondary" data-toggle="modal" data-target="#emptyCartModal">Empty Cart</a>
  //this.openModel(); 
}




checkQty() {
  if(this.selectedItem.selectedQty == 0 || this.selectedItem.selectedQty == "" || this.selectedItem.selectedQty == null || this.selectedItem.selectedQty == 'undefined'){
    this.selectedItem.selectedQty = "1";
  }
}

searchproduct() {
  //$scope.newProducts = $scope.newProductsCopy;
  this.selectedCategory ="";
  this.selectedLevel2Category = "";
  this.selectedLevel3Category = "";
  this.search = this.searchText;
  this.showCategoryLevel2();
  
}



addToCartOld(product) {
  //console.log('product added...',product);
 // orderService.addProduct(product);
  //this.cart = orderService.getCart();
  // this.itemAddedMessage = false;
  //   $timeout(function() {
  //         $scope.itemAddedMessage = true;
  //   }, 3000);
}

totalItemsCount() {
  this.plength = 0;
  this.cart = this.getCart();
  if(this.cart.productMap != null && this.cart.productMap != 'undefined')
  {
    this.plength = Object.keys(this.cart.productMap).length;
  }
  
  return this.plength;

}

getShopCart(){
  //this.closeModel();
  this.router.navigate(['othercanonproductshopcart']);
}

addToCart = function(product){
  console.log('product added...',product);
  //orderService.addProduct(product);
  this.addProduct(product);
  //$scope.cart = orderService.getCart();
  this.cart = this.getCart();
  this.totalItemsCount();
  this.itemAddedMessage = false;
}

getCart() {
  if (localStorage.getItem('itcgCart') != null) {
    this.cart = JSON.parse(localStorage.getItem('itcgCart'));
  }
  return this.cart;
}

addProduct (product) {
  if (localStorage.getItem('itcgCart') != null) {
    this.cart = JSON.parse(localStorage.getItem('itcgCart'));
  }
  
  if(this.cart.productMap == null || this.cart.productMap == 'undefined')
  {
    this.cart.productMap = {};
  }
  
  var itemCode = product.itemNumber+'_'+product.selectedCarePak.carePakItemNumber;
  var productMap = this.cart.productMap;
  var isProductExist = false;
  if(productMap){
    for(let key in productMap){
      if(key == itemCode){
        productMap[key].selectedQty = (parseInt(productMap[key].selectedQty) + parseInt(product.selectedQty)) + "";
        this.cart.productMap = productMap;
        isProductExist = true;
        
      }
    }
    if(!isProductExist){
      this.cart.productMap[itemCode] = product;
    }
  }
  

  localStorage.setItem('itcgCart', JSON.stringify(this.cart));
  console.log('added product', this.cart);
}




redirectToCheckout() {
  var url = "products?view=CanonCpShopCart";
  window.location.href = url;
}

showCategoryLevel2() {
  this.catLevel2List = [];
  this.catLevel3List = [];
  this.selectedLevel2Category = '';
  this.selectedLevel3Category = '';
  
  if(this.selectedCategory != ''){
    //var categoryLevel2List = $filter('filter')(this.newProducts, { productCat : this.selectedCategory });
    const categoryLevel2List = this.newProducts.filter(item => item.productCat === this.selectedCategory);
    
    this.catLevel2List = Array.from(new Set(categoryLevel2List.map(item => item.catLevel2)));
    
    const arr = this.catLevel2List;

    const results = arr.filter(element => {
      return element !== '' && element !== null;
    });
    console.log("Printing inside selectedCategory.....11111...");
    console.log(results);
    
    this.catLevel2List = results;
    console.log("Printing inside selectedCategory.....222222...");
      console.log(this.catLevel2List)
  }
  this.itemFilter();
  
}	


showCategoryLevel3 = function(){

		
  if(this.selectedCategory != ''){
    
    if(this.selectedLevel2Category == ''){
      this.showCategoryLevel2();	
    }else{
      if(this.selectedCategory != '' && this.selectedLevel2Category != ''){
    
        //var categoryLevel2List = $filter('filter')(this.newProducts, { productCat : this.selectedCategory });
        const categoryLevel2List = this.newProducts.filter((item: { productCat: any; }) => item.productCat === this.selectedCategory);
        
        //var categoryLevel3List = $filter('filter')(categoryLevel2List, {catLevel2 : this.selectedLevel2Category});
        const categoryLevel3List = categoryLevel2List.filter((item: { catLevel2: any; }) => item.catLevel2 === this.selectedLevel2Category);
        
        this.catLevel3List = Array.from(new Set(categoryLevel3List.map(item => item.catLevel3)));
        
        const arr = this.catLevel3List;
  
        const results = arr.filter(element => {
          return element !== '' && element !== null;
        });
        
        console.log(results);
        
        this.catLevel3List = results;
        
          console.log(this.catLevel3List)
      }
    }
  }
}


categoryLevel3Selected() {
	
		
  if(this.selectedCategory != '' && this.selectedLevel2Category != ''){
    
    if(this.selectedLevel3Category == ''){
      this.showCategoryLevel3();
    }
  }
}

// app.filter('cut', function () {
//   return 
  
  cut (value, wordwise, max, tail) {
      if (!value) return '';

      max = parseInt(max, 10);
      if (!max) return value;
      if (value.length <= max) return value;

      value = value.substr(0, max);
      if (wordwise) {
          var lastspace = value.lastIndexOf(' ');
          if (lastspace !== -1) {
            //Also remove . and , so its gives a cleaner result.
            if (value.charAt(lastspace-1) === '.' || value.charAt(lastspace-1) === ',') {
              lastspace = lastspace - 1;
            }
            value = value.substr(0, lastspace);
          }
      }

      return value + (tail || '...');
  }

// app.filter("itemFilter", function(){

//   return 
  
itemFilter (){

      var addUser;
      //var selectedUsers = [];
      this.newProductsFiltered=[];

      var catLevel1 = this.selectedCategory;
      var catLevel2 = this.selectedLevel2Category;
      var catLevel3 = this.selectedLevel3Category;
      if(catLevel1 === ''){
        this.newProductsFiltered= this.newProducts;
        return;
      }
  
      for(let i=0;i<this.newProducts.length;i++){

          addUser = false;



          if (this.newProducts[i].productCat == catLevel1){

      if(catLevel2 == ''){
        addUser = true; 
      }else{
        if(this.newProducts[i].catLevel2 == catLevel2){
          
          if(catLevel3 == ''){
            addUser = true;  
          }else{
            if(this.newProducts[i].catLevel3 == catLevel3){
              addUser = true; 
            }
          }
        }
              }
    }
              

          if (addUser){
      console.log('added :' + this.newProducts[i].productCat);
              this.newProductsFiltered.push(this.newProducts[i]);
          }

      }
  }


// app.directive('numbersOnly', function () 
// {
//   return {
//       require: 'ngModel',
//       link: function (scope, element, attr, ngModelCtrl) {
//           function fromUser(text) {
//               /*if (text) {*/
//                   var transformedInput = text.replace(/[^0-9]/g, '');
                  
//                  /*if(transformedInput == ''){
//                     transformedInput = "1";
//                  }else*/ 
//                   if(transformedInput != '' && parseInt(transformedInput) <= 0 ){
//                    transformedInput = "1";
//                   }

//                   if (transformedInput !== text) {
//                       ngModelCtrl.$setViewValue(transformedInput);
//                       ngModelCtrl.$render();
//                   }
//                   return transformedInput;
//               /*}*/
//               return undefined;
//           }            
//           ngModelCtrl.$parsers.push(fromUser);
//       }
//   };
// });

// app.directive("limitTo", [function() {
//   return {
//       restrict: "A",
//       link: function(scope, elem, attrs) {
//           var limit = parseInt(attrs.limitTo);
//           angular.element(elem).on("keypress", function(e) {
//               if (this.value.length == limit) e.preventDefault();
//           });
//       }
//   }
// }]);

// app.filter('split', function() {
//   return function(input, splitChar, splitIndex) {
//       // do some bounds checking here to ensure it has that index
//       return input.split(splitChar)[splitIndex];
//   }
// });

// app.filter('trustUrl', function ($sce) {
//   return function(url) {
//     return $sce.trustAsResourceUrl(url);
//   };
// });

// app.directive('errSrc', function() {
//   return {
//     link: function(scope, element, attrs) {
//       element.bind('error', function() {
//         if (attrs.src != attrs.errSrc) {
//           attrs.$set('src', attrs.errSrc);
//         }
//       });
      
//       attrs.$observe('ngSrc', function(value) {
//         if (!value && attrs.errSrc) {
//           attrs.$set('src', attrs.errSrc);
//         }
//       });
//     }
//   }
// });

// app.filter('replace', [function () {

//   return function 
  
  replace (input, from, to) {
    
    if(input === undefined || input === null) {
      return;
    }

    var regex = new RegExp(from, 'g');
    return input.replace(regex, to);
     
  };


//}]);

// app.directive('scrollToBookmark', function() {
//   return {
//     link: function(scope, element, attrs) {
//       var value = attrs.scrollToBookmark;
//       element.click(function() {
//         scope.$apply(function() {
//           var selector = "[scroll-bookmark='"+ value +"']";
//           var element = $(selector);
//           if(element.length)
//             window.scrollTo(0, element[0].offsetTop - 55);  
//         });
//       });
//     }
//   };
// });






}


