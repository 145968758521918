import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

@Injectable({
  providedIn: 'root'
})
export class CsaInvoicePaymentService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getInvoiceList(oParams) : Observable<any>{

    let url = this.defaultUrl + "invoicePayment/invoicePaymentRequest";
    let params = new URLSearchParams();
    
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    
    let qryStr=params.toString();
      
    return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getInvoiceList"))));
  }

  getAutoPayStsFlag(qryStr) : Observable<any>{
    let url = this.defaultUrl + "paymentMethod/getAutoPayStsFlag";
      
    return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getAutoPayStsFlag"))));
  }
}
