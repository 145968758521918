import { Component, OnInit } from '@angular/core';
import { locateHostElement } from '@angular/core/src/render3/instructions';
import { OrderSuppliesViewCartService } from './order-supplies-view-cart';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-order-supplies-view-cart',
  templateUrl: './order-supplies-view-cart.component.html',
  styleUrls: ['./order-supplies-view-cart.component.css']
})
export class OrderSuppliesViewCartComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  [x: string]: any;

  selectedItemArray: any = [];
  pleaseWaitCls:boolean = false;
  showUniqueCtrMsg: Boolean = true;
  subTotal = 0.00;
  machInfoArray: any = [];
  tempMap = new Map();
  machineDetailArray = [];
  errMsg = "";
  clrErrMsg = "";
  bwErrMsg = "";
  userId = sessionStorage.getItem('userName');
  currentDate = new Date();
  day = this.currentDate.getDate();
  months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
  curMonth = this.months[this.currentDate.getMonth()];
  year = this.currentDate.getFullYear();
  currDate = this.day+"-"+this.curMonth+"-"+this.year;
  date1 = new Date();
  cartExpireDate:any;
  showUniqueCtrMsg2: boolean;
  showAddMore: boolean;
  machInfo: string;
  bwTonerSelQty:any;
  currentDate1:any;
  emptyCartFlag:boolean=false;



	
      


  constructor(private commonService:CommonServiceService, private orderSuppliesViewCartService: OrderSuppliesViewCartService,private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    console.log("Printing userId in view-cart: "+ this.userId + " & session Storage value: "+ sessionStorage.getItem("userName"));
    this.date1.setDate(this.date1.getDate() + 1);
    this.cartExpireDate = this.date1;	
    if(localStorage.getItem(this.userId + '__ItemDetail') !== null){
      this.selectedItemArray = JSON.parse(localStorage.getItem(this.userId + '__ItemDetail'));
    }
    if(this.selectedItemArray.length == 0){
      //window.location.href = '#/ordersupplies';
      this.router.navigate(['ordersupplies']);
    }

    this.checkUniqueContracts();
    //this.getMeterReadsDetail();
    this.currentDate1 = this.datePipe.transform(new Date(), 'dd-MMM-yyyy');

    this.commonService.setCommonFunctions();

  }

  checkUniqueContracts()  {

    try { 
      this.showUniqueCtrMsg = false;
      this.showUniqueCtrMsg2 = false;
      this.showAddMore = true;
      
      var strMchDtl="";
          strMchDtl=localStorage.getItem( this.userId + '__MachDetail');
          strMchDtl=$.trim(strMchDtl);
          if(strMchDtl.length>0){
          var arrObj=strMchDtl.split("*");
          //console.log(arrObj);
          var ctrArr=[];
          for(let k of arrObj){
            var m="";
            m=arrObj[k];
            var arrM=m.split("|");
           // console.log(arrM[3]);
            if (ctrArr.indexOf(arrM[3]) > -1) {
                // do nothing
            }else{
              ctrArr.push(arrM[3]);
            }
          }
          var uniqueCtrArrLen = ctrArr.length;
        //	var uniqueCtrArr = Array.from(new Set(ctrArr));
        //	var uniqueCtrArrLen=uniqueCtrArr.length;
        //	console.log("uniqueCtrArrLen --- "+uniqueCtrArrLen);
          if(uniqueCtrArrLen>7){  
            this.showUniqueCtrMsg = true;
            this.showUniqueCtrMsg2 = true;
           this.showAddMore = false;
          }
          }
          
      } catch (e) {
        console.log("Error : "+e);
      }
    }


    getCounterReadDetails(id: any) {
  
      var paramObj = {};
      console.log("Printing machInfoArray below:::" );
      console.log(this.machInfoArray);
      paramObj["instance_id"] =this.machInfoArray[id].instanceId;
      paramObj["serial_number"] = this.machInfoArray[id].serialNumber;
      paramObj["contract_number"] =this.machInfoArray[id].contractNumber;
      paramObj["readTpe"] = "T";
      this.blockUI.start();
      var counterReadDetailsPromise = this.orderSuppliesViewCartService.counterReadDetailList(paramObj).subscribe(
        response=>{
          console.log("Printing111");
          console.log(response);
          console.log(this.tempMap);
          var index;
          if(response != null && response.length > 0){
            index = this.tempMap.get(response[0].serialNumber);
            console.log("Printing index" + index);
            console.log(this.machInfoArray);
          }
          if(index != null && index != undefined){
            //var data = JSON.parse(response.data);
            var pData="";
            this.machInfoArray[index].readList = [];
            this.machInfoArray[index].readList = response;
            let i =0;
            this.machInfoArray[index].readList.forEach((d: any) => {
              
                if(d.lastReadFlag!=null){
                      pData = d.lastReadFlag.split("*");
                      if(pData!=null){
                        if(pData[0] != null &&  pData[0] != undefined)
                          this.machInfoArray[index].readList[i].lstRdFlg = pData[0];
                        if(pData[1] != null &&  pData[1] != undefined)
                        this.machInfoArray[index].readList[i].previousRd = pData[1];
                        if(pData[2] != null &&  pData[2] != undefined)
                        this.machInfoArray[index].readList[i].previousDt = pData[2];
                          
                        if(this.machInfoArray[index].readList[i].lstRdFlg=="Y" || this.machInfoArray[id].supReadReqFlag=="N" || this.machInfoArray[id].mpsFlg=="Yes" || this.machInfoArray[id].isImageware=="Y"){
                          this.machInfoArray[index].readList[i].readOnly = true;
                        }else{
                          this.machInfoArray[index].readList[i].readOnly = false;
                  }
                  
                  this.machInfoArray[index].readList[i].currentRd = '';
                      }
                    }
                    
                    this.machInfoArray[index].readList[i].curSchdDt = this.currDate;
                    i++;
                    console.log("Printing macinfoArray after forloop: ");
                    console.log(this.machInfoArray);
            });
          }
          this.blockUI.stop();
        }
      )
  }


  getMeterReadsDetail() {
    //this.pleaseWaitCls = true;
    this.blockUI.start();
    if(localStorage.getItem(this.userId + '__MachDetail') !== null){
      this.machInfo = localStorage.getItem(this.userId + '__MachDetail');
      console.log('machInfo : '+ this.machInfo);
    }
    
    this.machineDetailArray = this.machInfo.split('*');
    
    if(this.machineDetailArray!=null && this.machineDetailArray.length>0){
      console.log('machineDetailArray : '+ this.machineDetailArray);
      for(let x=0;x<this.machineDetailArray.length;x++){
        var prtDt =  this.machineDetailArray[x];
        //console.log(' prtDt: '+ prtDt);
        var data = this.machineDetailArray[x].split('|');
        if(data!=null){
          this.machInfoArray[x] = {};
          this.machInfoArray[x].instanceId = data[1];
          this.machInfoArray[x].contractNumber = data[2];
          this.machInfoArray[x].contractId = data[3];
          this.machInfoArray[x].serialNumber = data[4];
          this.machInfoArray[x].readList = [];
          this.tempMap.set(data[4],x);
        }
      
      var paramObj = {};
      paramObj["serialNumber"] = this.machInfoArray[x].serialNumber;
      paramObj["contractId"] = this.machInfoArray[x].contractId;
      paramObj["instance_id"] = this.machInfoArray[x].instanceId;

      
      var reqFlagPromise = this.orderSuppliesViewCartService.getReadsReqFlag(paramObj).subscribe(
        response =>{
          console.log("Printing response in getMeterReadsDetail method: ");
          console.log(response.data);
          if(response!=null){
          
              var dataFlg = response;
              //var dataFlg = ["N", "N", "No", null, "2UL07305"];
            if(dataFlg!=null){
              var index = this.tempMap.get(dataFlg[4]);
              if(index != null && index != undefined ){
                if(dataFlg[0] != null && dataFlg[0] != undefined)
                this.machInfoArray[index].supReadReqFlag = dataFlg[0];
                if(dataFlg[1] != null && dataFlg[1] != undefined)
                this.machInfoArray[index].isImageware = dataFlg[1];
                if(dataFlg[2] != null && dataFlg[2] != undefined)
                  this.machInfoArray[index].mpsFlg = dataFlg[2];
                if(dataFlg[3] != null && dataFlg[3] != undefined && dataFlg[3] != '')
                   this.machInfoArray[index].scheduleDate = dataFlg[3];
                //console.log('supReadReqFlag : ' + supReadReqFlag + ' isImageware : ' + isImageware + ' mpsFlg : ' + mpsFlg);
              }
            }
            }
            console.log("Printing end of  getMeterReadsDetail method...")
            this.getCounterReadDetails(index);
            this.blockUI.stop();

        }
      )
        
      }
    }
    //this.pleaseWaitCls = false;
    
  }

  emptyCart() {
    console.log("Priting inside emptyCart");
    this.emptyCartFlag=true;
    //$("#emptyCartModal").modal('show');
  }	
  
  emptyCartConfirm () {
    console.log("Priting inside emptyCartConfirm");
    this.emptyCartFlag=false;
    localStorage.removeItem(this.userId + '__ItemDetail');
    localStorage.removeItem(this.userId + '__MachDetail');
    localStorage.removeItem(this.userId + '__AttnLbl');
    localStorage.removeItem(this.userId + '__ShipAddrs');
    this.router.navigate(['ordersupplies']);
  }

  addMore() {
    //this.submitMeter_fn('addMore');
    this.validateAndUpdateCart('addMore');
  }

  reviewInfo() {
		//this.submitMeter_fn('review');
    this.validateAndUpdateCart('review');
	}	

  validateAndUpdateCart (source) {

    this.errMsg = "";
    this.clrErrMsg = "";
    this.bwErrMsg = "";
    
    var checkQty = this.checkItemQty();
    console.log('checkQty : '+checkQty);
    if(checkQty){
      if( this.selectedItemArray.length !== 0 ){
        var s = JSON.stringify( this.selectedItemArray );
        //console.log( s );
        localStorage.setItem(this.userId + '__ItemDetail', s);
        if(source == 'review'){
          //window.location.href = '#/osshippingaddress';
          this.router.navigate(['osshippingaddress']);
        }else{
          //window.location.href = '#/ordersupplies';
          this.router.navigate(['ordersupplies']);
        }
      }	
    }
    
  }

  updateCart (source) {
    if( this.selectedItemArray.length !== 0 ){
      var s = JSON.stringify( this.selectedItemArray );
      //console.log( s );
      localStorage.setItem(this.userId + '__ItemDetail', s);
      if(source == 'review'){
        //window.location.href = '#/osshippingaddress';
        this.router.navigate(['osshippingaddress']);
      }else{
        //window.location.href = '#/ordersupplies';
        this.router.navigate(['ordersupplies']);
      }
    }	
  }

  
  qtyChange (index, item) {
    
    if(item.qty == 0){
      this.deleteItem(index, item);
    }
  }

  deleteItem (index, item) {
    
    this.errMsg = "";
    this.clrErrMsg = "";
    this.bwErrMsg = "";
    console.log("Printing inside delete item above");
    console.log(this.selectedItemArray);
    this.selectedItemArray.splice(index,1);
    console.log("Printing inside delete item below");
    console.log(this.selectedItemArray);
    
    var s = JSON.stringify( this.selectedItemArray );
    localStorage.setItem(this.userId + '__ItemDetail', s);
    
    //var filterList = $filter('filter')(this.selectedItemArray, { serialNumber : item.serialNumber });
    const serialNumber = item.serialNumber; // Replace with the desired serialNumber value
    const filterList = this.selectedItemArray.filter((item) => item.serialNumber === serialNumber);

    
    if(filterList.length == 0){
      let i =0;
      console.log("Printing above error log:");
      var machineDetailArrayTemp:any = this.machineDetailArray;
      var serialNumber1:any;
      for(let d of machineDetailArrayTemp){
        console.log(d);
        var data = d.split('|');
        serialNumber1 = data[4];
        if(serialNumber1 == item.serialNumber){
         this.machineDetailArray.splice( i, 1 );
         this.machInfoArray.splice( i, 1 );
        }
        i++;
      }
      console.log(this.machineDetailArray);
      //machineDetailArrayTemp.forEach(function(d) {

     // });
      
      var ss = this.machineDetailArray.join("*");
      localStorage.setItem( this.userId + '__MachDetail', ss );
      //this.getMeterReadsDetail();
    }
    
    if( this.selectedItemArray.length == 0 ){
     localStorage.removeItem(this.userId + '__MachDetail');
     localStorage.removeItem(this.userId + '__ShipAddrs');	
     localStorage.removeItem(this.userId + '__AttnLbl');
      //window.location.href = 'ordSup.htm?view=orderSupplies';
      this.router.navigate(['ordersupplies']);
    }
    
    this.checkUniqueContracts();
  }


  checkItemQty () {
    console.log("Checking in View Cart CheckItemQty method:");
    var submitFlag = 'Y';
    var qtyTr="";
    var dispClrTonerVal = '';
    var clrMsg="";
    var bwMsg="";
    var passMsg="";		
    var dispBwTonerVal = '';
    
    this.selectedItemArray.forEach((value: any) =>{
      var clrTonerSelQty = 0;
      var bwTonerSelQty=0;
      var currentItem = value; 
      if(currentItem.qty > 0){
        this.selectedItemArray.forEach( (value1: any) =>{
          var item = value1; 
          if(currentItem.serialNumber == item.serialNumber){			
            if(currentItem.paasFlag == "Y"){
              if((item.supCatg=='01' || item.supCatg=='02' || item.supCatg=='03' || item.supCatg=='04') && item.minQtyFlag != "Y"){
                var clrTonerVal = item.dispQty;
                clrTonerSelQty = (clrTonerSelQty)+parseInt(item.qty);
                if((clrTonerSelQty)>(clrTonerVal)){
                  passMsg = "The quantity available for selection is the Total CAP Remaining for toner and maintenance kit. "+
                  "For toners (B/C/M/Y) , total quantity for all toners per serial cannot exceed total toner CAP remaining.";
                  //dispClrTonerVal =clrTonerVal;
                  submitFlag = "N";
                }
              }
            }else{
              if((item.supCatg=='02' || item.supCatg=='03' || item.supCatg=='04') && item.minQtyFlag != "Y"){
                console.log("printing inside viewcart else blcik:");
                console.log(typeof item.dispQty);
                console.log(item.dispQty);
                var clrTonerVal = item.dispQty;
                console.log(clrTonerVal);
                console.log(typeof item.qty);
                clrTonerSelQty = (clrTonerSelQty)+parseInt(item.qty);
                console.log(clrTonerSelQty);
                console.log(typeof clrTonerSelQty);
                if((clrTonerSelQty)>(clrTonerVal)){
                  clrMsg = item.clrCtrMsg;
                  dispClrTonerVal =clrTonerVal;
                  submitFlag = "N";
                }
              }else if(item.supCatg=='01' && item.minQtyFlag != "Y"){
                var bwTonerVal = item.dispQty;
                //bwTonerSelQty = parseInt(bwTonerSelQty)+parseInt(itemQty);
                bwTonerSelQty = (this.bwTonerSelQty)+parseInt(item.qty);
                //alert('bwTonerSelQty view: '+bwTonerSelQty+' bwTonerVal: '+bwTonerVal);
                if((this.bwTonerSelQty)>(bwTonerVal)){
                  bwMsg = item.bwCtrMsg;
                  dispBwTonerVal =bwTonerVal;
                  console.log('view msg : '+bwMsg);
                  submitFlag = "N";
                  //alert(bwMsg+' '+dispBwTonerVal);
                }
                
              }
            }
          }	
        });
      }
    });
    
    if(submitFlag=='N'){
      if($.trim(clrMsg).length>0){
        this.clrErrMsg = clrMsg+' '+dispClrTonerVal;
        //alert(clrMsg+' '+dispClrTonerVal);
      }else if($.trim(bwMsg).length>0){
        this.bwErrMsg = bwMsg+' '+dispBwTonerVal;
        //alert(bwMsg+' '+dispBwTonerVal);
      }else if($.trim(passMsg).length>0){
        this.clrErrMsg = passMsg;
        //alert(bwMsg+' '+dispBwTonerVal);
      }
      return false;
    }else{
      return true;
    }
  }

  submitMeter_fn (source) {
    this.errMsg = "";
    this.clrErrMsg = "";
    this.bwErrMsg = "";
    
    var checkQty = this.checkItemQty();
    console.log('checkQty : '+checkQty);
    if(checkQty){
      //angular.forEach(this.machInfoArray, function(d, i) {
      for(let i=0;i<this.machInfoArray.length;i++){	
        var d = this.machInfoArray[i];
        var cntrRds='';
        var submitVal=true;
        var readsExist = false;
        console.log("Printing machInfoArray inside 1st forloop below: ");
        console.log(this.machInfoArray[i].readList);
        for(let j=0;j<this.machInfoArray[i].readList.length;j++){
          console.log("Printing machInfoArray inside 2nd forloop below: ");
          console.log(this.machInfoArray[i].readList[j]);
          var read = this.machInfoArray[i].readList[j];
        //angular.forEach(this.machInfoArray[i].readList, function(read, j) {
          if(!read.readOnly && read.readingReqFlg == 'Y' &&  !read.currentRd){
            this.errMsg = "Supplies Counter Read is required.";
            submitVal = false;
            return;
          }

          if(!read.readOnly && read.readingReqFlg == 'Y' &&  read.currentRd != '' && read.currentRd !== undefined && read.previousRd != '' && read.previousRd !== undefined){
            
            var curCounterRd = parseInt(read.currentRd);
            var lstReadAmt = parseInt(read.previousRd);
            readsExist = true;
            if(curCounterRd < lstReadAmt){	
                      this.errMsg = "Current meter read should be greater than last meter read.";
              submitVal = false;
              return;
            }
          }
          
          if(read.currentRd == '' || read.currentRd == 'null'  || read.currentRd == undefined){
            read.currentRd = 0;
          }
          if(j==0){
            cntrRds = read.counterName +":"+ read.currentRd;
          }else{
            cntrRds+=","+read.counterName +":"+ read.currentRd;
          }
        }
        //});
        
        if((submitVal) && (readsExist)){
          $.ajax({
            url:'ordSup.htm?mthd=smrs',
            method: 'POST',
            async : false,
            //dataType: "json",
            data: {readMeatersData: JSON.stringify(this.machInfoArray[i].readList), reads: cntrRds, date: this.machInfoArray[i].readList[0].curSchdDt, contractNum: d.contractNumber, contract_id: d.contractId, readTpe:'T'}, 
            complete: function (response){
              //console.log(response);
              if(response!=null){
                var respMsg = response.responseText;
                console.log("respMsg : "+respMsg);
                var data = respMsg.split('|');
                if(data!=null){
                  var errorFlg = data[0];
                  var errorMsg = data[1];
                  if(errorFlg=="S"){
                    //this.$apply(function(){
                              this.errMsg = "";
                      this.updateCart(source);
                          //});
                    }else{
                      //this.$apply(function(){
                                this.errMsg = errorMsg;
                        return;
                            //});
                  }
                }
              }
            },
            error: function (){
              //console.log(e);
            }
          });
        }else{
          this.updateCart(source);
        }
      //});
      }
    }
  }

	onPrint (){
    window.print();
  }
  
  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}




