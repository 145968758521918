import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {
    @BlockUI() blockUI : NgBlockUI;
  // externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/faq-myCsa.html?topic='+ this.contactUsComponent.topic;
   externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/faq-myCsa.html';
    faqHtml;
    topic = "";
    faqUrl: string;
    constructor(private http: HttpClient, private sanitizer: DomSanitizer,private modalService: NgbModal,private titleService: Title,private activatedRoute: ActivatedRoute) {

    }
    
    ngOnInit(): void {
        console.log("Inside ngOnInit...");
        this.titleService.setTitle("ContactUs");
        this.activatedRoute.queryParams.subscribe(
            params => {
              this.topic =params['topic'];
            }         
        );
        this.blockUI.start();
        this.faqUrl = this.externalUrl+'?topic='+this.topic;
        console.log("Calling external URL: ", this.faqUrl);
        this.http.get(this.faqUrl, { responseType: 'text' }).subscribe(data => {
            console.log("called the external URL..");
            console.log(data);
            this.faqHtml = this.sanitizer.bypassSecurityTrustHtml(data);


            setTimeout(() => { 
                console.log("Printing to know if setTimeout function is working with BlockUI..");
                this.blockUI.stop();
                this.applyScript();   
            }, 10000);
        })
    }
    private applyScript() {
            // Your script here
            const selectID = '#select-' + this.topic;
            console.log("Pritning selectID : ", selectID);
            //Pritning selectID :  #select-deviceManagement
            if (selectID !== '') {
              $(selectID).prop('selected', 'selected');
              $('.faqs-topic-container').removeClass('csa-show');
              const content = '#' + this.topic + '-option-section';
              $(content).addClass('csa-show');
            }
        
                $('#faqs-topic-select').change(function () {
                    switch ($(this).val()) {
                        case "accountManagement-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#accountManagement-option-section").addClass("csa-show");
                            break;
                        case "deviceManagement-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#deviceManagement-option-section").addClass("csa-show");
                            break;
                        case "userManagement-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#userManagement-option-section").addClass("csa-show");
                            break;
                        case "autoToner-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#autoToner-option-section").addClass("csa-show");
                            break;
                        case "orderSupplies-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#orderSupplies-option-section").addClass("csa-show");
                                break;
                        case "servicingDevices-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#servicingDevices-option-section").addClass("csa-show");
                            break;
                        case "meterReads-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#meterReads-option-section").addClass("csa-show");
                            break;
                        case "invoicing-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#invoicing-option-section").addClass("csa-show");
                            break;
                        case "taxForms-option":
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#taxForms-option-section").addClass("csa-show");
                            break;
                        default:
                            $(".faqs-topic-container").removeClass("csa-show");
                            $("#accountManagement-option-section").addClass("csa-show");
                        }
                        console.log("Printing after applied value: ", $(this).val());
                });
              
    }
}

