import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class RoleDefinitionService {

  baseUrl="";
	
  	constructor(private http: HttpClient, private util : Util) { 
		this.baseUrl = this.util.getBaseUrl();
	}
	
	getAppsAndEntities(): Observable<any> {
	    let initialUrl = this.baseUrl + "sysadmin/getAppsAndEntities";
	    return this.http
	      .post(initialUrl, null, httpOptions)
	      .pipe(catchError(this.util.handleError("getAppsAndEntities")))
	}
	
	getAppsAndEntitiesSync(): Promise<any>{
		return this.getAppsAndEntities().toPromise();
		
	}
	
	rolesSearch(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "sysadmin/rolesSearch?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("rolesSearch")))
	  }


}
