import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { ActivatedRoute } from '@angular/router';
import { MarketingAdminService } from './marketing-admin.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-marketing-admin',
  templateUrl: './marketing-admin.component.html',
  styleUrls: ['./marketing-admin.component.css']
})
export class MarketingAdminComponent implements OnInit {

  @ViewChild('custSearchModal1') custSearchModalTemplate1:TemplateRef<any>;
  custSearchModal1: BsModalRef;

  @BlockUI() blockUI : NgBlockUI;
  numOfRecsMsg:string="";
  arrPgLinks:any=[];
  pageNum:any=1;
  totalRecords:any=0;
  recsPerPage:any=10;
  partyList:any = [];
  byCustomer:boolean = false; 
  source:string = '';
  partyNumber:string = '';
  partyName:string = '';
  partyId:string='';
  selectedMessage:any = {};
  selectedMessage1:any = {};
  rowData:any = {};
  status:string="";

  constructor(private commonService:CommonServiceService, private marketingService:MarketingAdminService, private util : Util,
    private modalService: BsModalService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadDefault();
  }

  onClickEMSD(evt) {
    this.byCustomer = false;
    var target = evt.target;
    if (target.checked) {
      this.loadEMSD();
    } 
  }

  loadCustomer(evt) {
    var target = evt.target;
    if (target.checked) {
      this.byCustomer = true;
    } else {
      this.byCustomer = false;
    }
  }

  loadPage(pgNum: any,event: any){
    if(event!=null){
     event.preventDefault();
    }
  
    this.pageNum=pgNum;
    this.call(this.source,null);
  }  

  loadCustomerType(evt, val) {
    var target = evt.target;
    if (target.checked) {
      this.byCustomer = true;
    } else {
      this.byCustomer = false;
    }
    this.source = val;
    this.call(val,null);
  }

  openEditMessage (msg) {
    this.selectedMessage1=decodeURIComponent(msg);
   
    }

    loadCustomerModal(row) {
      
      this.openEditMessage(row);
      
      this.custSearchModal1 = this.modalService.show(this.custSearchModalTemplate1);
    }

    saveMessage(){
    
    let params = {
      "content" : encodeURIComponent(this.selectedMessage1),
      "partyId" : this.partyList[0].partyId,
      "partyName": this.partyList[0].partyName,
      "partyNumber" : this.partyList[0].partyNumber,
      "source" : this.source
      
    };

let data = this.marketingService.saveContent(params).subscribe(
  data=>{
    this.status=data.status;
    //this.ngForm.
  console.log("this.status  == " + this.status);
  if(this.status == "S"){
    this.custSearchModal1.hide();
    $("#optionsByCustomer2").prop("checked", true);
    this.loadDefault();
  }
  else{}
  });   
    }

    close(){
      this.custSearchModal1.hide();
    }

  search() {
    this.blockUI.start();
    let params = {
      "partyNumber" : this.partyNumber,
      "partyName" : this.partyName,
      "source": this.source,
      "pageNum" : this.pageNum,
      "recsPerPage" : this.recsPerPage,
      "orderCol" : 'partyNumber',
      "orderDir" : 'asc'
    };
    
    let data = this.marketingService.loadDefault(params).subscribe(
      data=>{
        this.partyList = data.data;
        this.totalRecords=data.noOfRecs;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recsPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recsPerPage); 
        this.blockUI.stop();
      });
  }

  call(val,row) {
    this.blockUI.start();
    let params = {
      "source": val,
      "pageNum" : this.pageNum,
      "recsPerPage" : this.recsPerPage,
      "orderCol" : 'partyNumber',
      "orderDir" : 'asc'
    };
    
    let data = this.marketingService.loadDefault(params).subscribe(
      data=>{
        this.partyList = data.data;
        this.totalRecords=data.noOfRecs;
        this.selectedMessage = JSON.parse(JSON.stringify(row));
        console.log("this.selectedMessage  == " + this.selectedMessage);
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recsPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recsPerPage); 
        this.blockUI.stop();
      });
  }

  loadEMSD() {
    this.blockUI.start();
    let params = {
      "pFlag": 'EMSD',
      "pageNum" : this.pageNum,
      "recsPerPage" : this.recsPerPage,
      "orderCol" : 'partyNumber',
      "orderDir" : 'asc'
    };
    
    let data = this.marketingService.loadDefault(params).subscribe(
      data=>{
        this.partyList = data.data;
        this.totalRecords=data.noOfRecs;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recsPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recsPerPage); 
        this.blockUI.stop();
      });
  }

  loadDefault() {
    this.byCustomer = false; 
    this.blockUI.start();
    let params = {
      "pFlag": 'DEFAULT',
      "pageNum" : this.pageNum,
      "recsPerPage" : this.recsPerPage,
      "orderCol" : 'partyNumber',
      "orderDir" : 'asc'
    };
    
    let data = this.marketingService.loadDefault(params).subscribe(
      data=>{
        this.partyList = data.data;
        this.partyId=data.data.partyId;
        console.log("this.partyId    == " + this.partyId);
        this.totalRecords=data.noOfRecs;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recsPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recsPerPage); 
        this.blockUI.stop();
      });
  }

}


