import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class SubmitMeterReadsService {
	defaultUrl: string;
  constructor(private http:HttpClient,private util:Util) {
     this.defaultUrl=util.getBaseUrl();
   }

   getPendingMeterReadsList=function(oParams)
   {
       let url = this.defaultUrl + "meterreading/pendingMtrList";
       let params = new URLSearchParams();
       for (let key in oParams) {
         params.set(key, oParams[key])
       }
       let qryStr=params.toString();
 
       return this.http 
         .post(url,qryStr, httpOptions)
         .pipe(catchError(this.util.handleError("getPendingMeterReadsList")));
     }
     getMeterReadsDetail=function(oParams)
     {
         let url = this.defaultUrl + "meterreading/getCtrRdDtls";
         let params = new URLSearchParams();
         for (let key in oParams) {
           params.set(key, oParams[key])
         }
         let qryStr=params.toString();
   
         return this.http 
           .post(url,qryStr, httpOptions)
           .pipe(catchError(this.util.handleError("getCtrRdDtls")));
       }

    getDropDownData=function(oParams)
     {
         let url = this.defaultUrl + "meterreading/getDropDownData";
         let params = new URLSearchParams();
         for (let key in oParams) {
           params.set(key, oParams[key])
         }
         let qryStr=params.toString();
   
         return this.http 
           .post(url,qryStr, httpOptions)
           .pipe(catchError(this.util.handleError("getDropDownData")));
       }

    submitMeterReads=function(oParams)
     {
         let url = this.defaultUrl + "meterreading/submitMeterReads";
         let params = new URLSearchParams();
         for (let key in oParams) {
           params.set(key, oParams[key])
         }
         let qryStr=params.toString();
   
         return this.http 
           .post(url,qryStr, httpOptions)
           .pipe(catchError(this.util.handleError("getDropDownData")));
       }

     

}
