import { Component, Input, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { LeaseoptionsService } from './leaseoptions.service';

@Component({
  selector: 'app-leaseoptions',
  templateUrl: './leaseoptions.component.html',
  styleUrls: ['./leaseoptions.component.css']
})
export class LeaseoptionsComponent implements OnInit {

  partyId: string ="";
  templateId: string ="";
  data:any={};
  leaseOptionsList: any = [];
  tewlveMonth: string ="";
 twentyFourMonth: string ="";
 thritySixMonth: string ="";
 thrityNineMonth: string ="";
 fourtyEightMonth: string ="";
 sixtyMonth: string ="";
 status:string="";
 lease:any=[];
 price:string="";
 leaseprice:string="";
 successMessage:string="";
 errorMessage:string="";
 successFlag:boolean=false;
 errorFlag:boolean=false;

  constructor(private leaseoptionsService: LeaseoptionsService, private commonService: CommonServiceService) { }
  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
    this.getleaseoptions();
  }

  getleaseoptions() {
   
  
    this.partyId = "14669070";
    this.templateId = "106";
  
    let oParams = {
      "partyId": this.param.partyId, 
      "templateId": this.param.templateId
    };

    this.leaseoptionsService.getleaseOptions(oParams).subscribe(
      (res: any) => {
        
        this.leaseOptionsList = res;
      //   for(let i=0;i<this.data.length;i++){
      //   this.leaseOptionsList.push(this.data[i]);
      // }
        console.log("this.leaseOptionsList  == " + this.leaseOptionsList);
        
      },
      
    );
    
  }



  saveleaseoptions() {
   
  console.log("In the save lease options"+ this.leaseOptionsList);
    this.partyId = "14669070";
    this.templateId = "106";
   this.tewlveMonth=this.leaseOptionsList[0].price;
    this.twentyFourMonth=this.leaseOptionsList[1].price;
    this.thritySixMonth=this.leaseOptionsList[2].price;
    this.thrityNineMonth =this.leaseOptionsList[3].price;
    this.fourtyEightMonth=this.leaseOptionsList[4].price;
    this.sixtyMonth=this.leaseOptionsList[5].price;

    console.log("this.tewlveMonth ==" + this.tewlveMonth);
    console.log("this.twentyFourMonth ==" + this.twentyFourMonth);
    let oParams = {
      "partyId": this.partyId, 
      "templateId": this.templateId,
      "tewlveMonth":this.tewlveMonth,
      "twentyFourMonth": this.twentyFourMonth, 
      "thritySixMonth": this.thritySixMonth,
      "thrityNineMonth":this.thrityNineMonth,
      "fourtyEightMonth": this.fourtyEightMonth,
      "sixtyMonth":this.sixtyMonth

    };

    this.leaseoptionsService.saveleaseOptions(oParams).subscribe(
      (res: any) => {
        
        this.status = res.data;
        console.log("this.status 111  == " + this.status);
        if(this.status=="S"){
        this.successFlag=true;
        this.successMessage="Lease Updated";
        console.log("this.status 222  == " + this.status);
      }

      if(this.status!="S"){
        this.errorFlag=true;
        this.errorMessage="Error occured during save";
        console.log("this.status 222  == " + this.status);
      }

      
        
        
      },
      
    );
    
  }

}
