import { Component, OnInit } from '@angular/core';
import { TonerLevelService } from './toner-level.service'; 
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-toner-level',
  templateUrl: './toner-level.component.html',
  styleUrls: ['./toner-level.component.css']
})
export class TonerLevelComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  url:any="";

  constructor(private tonerLevelService:TonerLevelService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.blockUI.start();
    this.tonerLevelService.getUrl().subscribe(
      data=> {
        console.log("Printing url: ");
        console.log(data);
        //this.url = data.url;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
        this.blockUI.stop();
      }
    );
  }

}
