import { Component, OnInit } from '@angular/core';
import { RequestDevicesService } from './request-devices.service';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-request-devices',
  templateUrl: './request-devices.component.html',
  styleUrls: ['./request-devices.component.css']
})
export class RequestDevicesComponent implements OnInit {

  sNumber:any =[];
  validateData:any;

  constructor(private commonService:CommonServiceService, private requestDevicesService: RequestDevicesService, private router:Router) { }

  ngOnInit() {

    this.commonService.setCommonFunctions();
  }



  doCancel(){
    $("#serConfirm input").val("");
    $('#view').val("requestSerial");
    //document.serConfirm.action = "accountdevices.htm?mthd=rd";
    //document.serConfirm.submit();
    this.router.navigate(['requestdevices'])
    //this.requestDevicesService.doCancelSubmit();
}

doReturn(){
	//showPleaseWait();
	//$('#view').val("accountDevices");
	//$('#serConfirm').submit();
  this.router.navigate(["accountdevices"]);
}

getAccountDevices(){
  this.router.navigate(["accountdevices"]);
}


 doValidate(){

  console.log('SerialNumber: ', this.sNumber);
  this.router.navigate(['/requestdevices'], { queryParams: { sNumber: this.sNumber.join()} });
  //    var submitflag = false;
  //    for(var s=0; s<document.serConfirm.sNumber.length; s++){
  //       if(document.serConfirm.sNumber[s].value != "")
  //       {
  //        submitflag = true;
  //       } 
  //  }
  //    if(submitflag) {
  //      showPleaseWait();
  //      document.serConfirm.action="accountdevices.htm?mthd=vsn";
  //      form = document.serConfirm;
  //      form.submit(); 
  //    } else {
  //      alert("Please select at least one Serial Number");
  //    }
}

}
