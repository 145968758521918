import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class P66Service {
  defaultUrl: string;



  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  registerUser() : Observable<any> {
    
    let url = this.defaultUrl + "p66NewUser/register";
    let params={};
    return this.http
      .post(url, params.toString, httpOptions) 
      .pipe(catchError((this.util.handleError("registerUser"))));
  }
}
