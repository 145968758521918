import { Component, Input, OnInit,ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AccountDevicesService } from 'src/app/account-mgmt/account-devices/account-devices.service';
import { ContractSuppliesService } from 'src/app/product-supplies/contract-supplies.service';
import { OrderDetailsComponent } from 'src/app/product-supplies/order-details/order-details.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-auto-toner-details',
  templateUrl: './auto-toner-details.component.html',
  styleUrls: ['./auto-toner-details.component.css']
})
export class AutoTonerDetailsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  @Input() serialNum : string
  @Input() pageSource :  string
  atDetails : any
  orderDetails : any
  shipLabel : string
  attnLabel : string
  contactName : string
  shipAttnlabel: any;
  primaryContactName: any;
  shipInstruction: any;
  primaryContactEmail: any;
  primaryContactPhone: any;
  itPrimaryContactName: any;
  itPrimaryContactEmail: any;
  itPrimaryContactPhone: any;
  errorDetails : any = {};
  displayForm = false;
  contactUsEmail : string = '';

  @ViewChild('msgModal') msgTemplate:TemplateRef<any>;
  msgModalRef: BsModalRef


  constructor(private csService: ContractSuppliesService, private router:Router, private activeModal: NgbActiveModal, private modalService: NgbModal ,private bsModalService: BsModalService) { }

  ngOnInit() {
    console.log('this.serialNum:' + this.serialNum);
    this.getInitialData();
  }

  closeMsgModel() {
    this.msgModalRef.hide();
  }

   getInitialData(){

    //this.serialNum = '2JU03773';
    this.blockUI.start();
    
    let params = { 
      "serialNumber": this.serialNum 
    };
    const orderDetails = this.csService.getOrderDetails(params);

    const atDetails = this.csService.getAutoTonerDetails(params);

    forkJoin([orderDetails, atDetails]).subscribe(
      data => {
        console.log(data);
        this.orderDetails = data[0];
        this.atDetails = data[1]['autoTonerDetails'];
        this.contactUsEmail = data[1]['contactUsEmail']
        this.shipLabel = this.getShippingLabel();

        if(this.orderDetails.atFlag == 'ENABLED' || this.orderDetails.atFlag == 'NOT ELIGIBLE'){
          this.displayForm = false;
        }else if(this.orderDetails.atFlag == 'NOT ENABLED'){
          this.displayForm = true
        }
        this.blockUI.stop();
      }
    )
  } 

  getShippingLabel(){
    var shipLabel = "";
    
    var attnLabel = this.atDetails.shipAttnlabel ? this.atDetails.shipAttnlabel : "" ;
    var contactName = this.atDetails.primaryContactName ? this.atDetails.primaryContactName : "";
    var serialNumber = this.atDetails.serialNumber ? this.atDetails.serialNumber : "";
    
    //if(hasATAccess === "Y"){
      /*attnLabel = $("#edit-auto-toner-shipping-label").val() ? $("#edit-auto-toner-shipping-label").val() : ""; 
  
      contactName = $("#edit-auto-toner-shipping-label2").val() ? $("#edit-auto-toner-shipping-label2").val() : "";*/
      
      //attnLabel = this.attnLabel; 
  
      //contactName = this.contactName;
//}
  
    const regex = /^Attn/i;
    
    if(attnLabel) shipLabel = attnLabel;
    
    if(contactName && contactName.toUpperCase() != attnLabel.toUpperCase()) shipLabel = shipLabel + (shipLabel ? "-"  : "") +  contactName;
    
    shipLabel = shipLabel + (shipLabel ? "-"  : "") +  serialNumber;
    
    if(shipLabel.search(regex) == -1){
      shipLabel = "Attn:" + shipLabel;
    }
      
    //Show only the 1st 35 char including the Attn: label
    shipLabel = shipLabel.substring(0, 35);
    
    return shipLabel;
  }

  updateOrEnableAT(){

    this.blockUI.start();
    let details = { 
      shipAttnlabel: this.atDetails.shipAttnlabel,
      primaryContactName: this.atDetails.primaryContactName,
      shipInstruction: this.atDetails.shipInstruction,
      primaryContactEmail: this.atDetails.primaryContactEmail,
      primaryContactPhone: this.atDetails.primaryContactPhone, 
      itPrimaryContactName: this.atDetails.itPrimaryContactName,
      itPrimaryContactEmail: this.atDetails.itPrimaryContactEmail,
      itPrimaryContactPhone: this.atDetails.itPrimaryContactPhone,
      serialNumber: this.serialNum,
      locAddress: this.orderDetails.address,
      autoShipToner: 'X'
    };

    this.errorDetails = {}
    let orderDetails

    if(this.orderDetails.atFlag == 'ENABLED'){
      orderDetails = this.csService.updateAutoToner(details);
    }else if(this.orderDetails.atFlag == 'NOT ENABLED'){
      orderDetails = this.csService.enableAutoToner(details);
    }


    orderDetails.subscribe(
    //this.csService.updateAutoToner(details).subscribe(
      data => {

        this.errorDetails = data;

        if(data.status === 'S'){
					this.shipLabel = this.getShippingLabel();

          if(this.orderDetails.atFlag == 'ENABLED'){
            this.displayForm = false;
          }
		}
		
        this.blockUI.stop();

		this.msgModalRef = this.bsModalService.show(this.msgTemplate);
		
      }
    )
  }

  editATDetails(){
    this.displayForm = true;
  }

  viewAll(){

    if(this.pageSource == 'ordersupplies'){
      this.activeModal.close();

      let queryParams : any = {};
  
      queryParams.serialNumber = this.serialNum;
      queryParams.instanceId = this.orderDetails.instanceId
      queryParams.model = this.orderDetails.model
      queryParams.installLocation = this.orderDetails.address
      queryParams.contractNumber = this.atDetails.contractNumber
      queryParams.note1 = this.orderDetails.note1
      queryParams.note2 = this.orderDetails.note2
      queryParams.source = this.orderDetails.source
      queryParams.pageSource = this.pageSource
  
      this.router.navigate(['devicedetails'],{queryParams: queryParams, fragment: 'meterReads'});
    }else{
      this.router.navigate([], { fragment: "meterReads", skipLocationChange: true });
    }
    
  }

  displayOrderDetails(orderId, source){
    const orderDetailsModalRef = this.modalService.open(OrderDetailsComponent,  {size: <any>'xl'});
    orderDetailsModalRef.componentInstance.ordID = orderId;
    orderDetailsModalRef.componentInstance.src = source.toUpperCase();
    orderDetailsModalRef.componentInstance.totPrice = 0.00;
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
