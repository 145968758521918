import { Component, OnInit } from '@angular/core';
import { PendingUserApprovalService } from './pending-user-approval.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';


@Component({
  selector: 'app-pending-user-approval',
  templateUrl: './pending-user-approval.component.html',
  styleUrls: ['./pending-user-approval.component.css']
})
export class PendingUserApprovalComponent implements OnInit {


  constructor(private pendingUserApprovalService: PendingUserApprovalService, private commonService:CommonServiceService,private titleService: Title ) { }

  @BlockUI() blockUI : NgBlockUI;
  partyId:any;
  reqStatus:any;
  selectedStatus: any="Pending";
  tbLloaded:any;
  userList:any=[];
  iTotalRecords:any;
  iTotalDisplayRecords:any;
  accountNumber:any="";
  recPerPage=10;
  pageNum=1;
  numOfRecsMsg:any="";
  arrPgLinks:any=[];
  checkedUsers:any=[];

  showApproveSucessMsg = false;
  showApproveFailMsg = false;
  showRejectSuccessMsg = false;
  userName:any;
  firstName:any;
  lastName:any;
  baUSerList:any=[];
  baiTotalCount:any=0;
  searchClicked: boolean = false;

  ngOnInit() {
    this.titleService.setTitle("Pending Approval Queue");
    this.getAllUsers(true);
  }

  getAllUsers(resetFlg){
    if(resetFlg==true){
      this.pageNum=1;
    }
	this.showApproveSucessMsg = false;
  	this.showApproveFailMsg = false;
	this.showRejectSuccessMsg = false;
	
    this.blockUI.start();
    this.checkedUsers=[];
    var param = {
			"PartyId"  : this.partyId,
      "ReqStatus" : this.selectedStatus,
      "start":  (this.pageNum == 1 ) ? 0 : ( (this.pageNum -1 ) * (this.recPerPage) ),
      "length": this.recPerPage,
		};
    this.pendingUserApprovalService.getAllUsers(param).subscribe(
      data=>{
        this.userList = data.data;        
        for(let user of this.userList){
          
          if(user.adminOpt=="" || user.adminOpt=="Y" ){
            user.adminOpt="Y";

          }
          else {
            user.adminOpt="N";
          }

        }
        
        this.iTotalRecords= data.iTotalRecords;
        this.iTotalDisplayRecords= data.iTotalDisplayRecords;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.iTotalDisplayRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.iTotalDisplayRecords,this.pageNum,this.recPerPage);
        console.log("Printing userlist below:");
        console.log(this.userList);
        console.log("Printing iTotalDisplayRecords below:");
        console.log(this.iTotalDisplayRecords);
        this.blockUI.stop();

      }
    );

  }


 getBAUsers(){
	
  this.blockUI.start();

  var param = {
    "firstName"  : this.firstName,
    "lastName" : this.lastName,
    "userName": this.userName,
    "start":  1,
    "length": this.recPerPage,
  };

  this.pendingUserApprovalService.getBAList(param).subscribe(
    data=>{
      console.log(data);
      this.baUSerList= data.data;
      this.baiTotalCount = data.iTotalDisplayRecords;

      this.blockUI.stop();
    }
    
  );


    
  }

  clearSearch(){
  this.firstName='';
  this.lastName ='';
  this.userName = '';
  this.getBAUsers();
    
  }

  clearButton(event:any){
    if(event){
      event.preventDefault();
    }
    this.partyId="";
    this.accountNumber = '';
    this.selectedStatus="Pending";
    this.pageNum=1;
    this.getAllUsers(true);
	this.showApproveSucessMsg = false;
  	this.showApproveFailMsg = false;
	this.showRejectSuccessMsg = false;

  }

  markUser(user, pbChkBox){
    console.log("Printign param user: "+ user );
    console.log("Printign param checkbox: "+ pbChkBox );
    if(pbChkBox.checked){
      this.checkedUsers.push(user);

    }

    else{
      this.checkedUsers= this.checkedUsers.filter(val => val !== user);
      
    }

    console.log(this.checkedUsers);

  }

  downloadRegTempExcel(){
    console.log("Printing inside downloadRegTempExcel");
    var url  = this.pendingUserApprovalService.getdownloadRegTempExcel();
    window.location.href=url;
  }


  exportBtn() {
    this.blockUI.start();
    var param = {
			"PartyId"  : this.partyId,
      "ReqStatus" : this.selectedStatus,
      "start": 1,
      "length": 9999,
		};

    var headerArray:any[] = ["lastName",	"accountName",	"userName",
    	"userId",	"serialNo",	"firstName",	"phone",	"adminOpt",	"partyId",	
      "reqSource",	"email",	"registerDate",	"status"
    ];

    this.pendingUserApprovalService.getexportBtnData(param).subscribe(
      data=>{
        this.commonService.jsonToExcel(data.data,"PendingApprovedUsers",true,true,headerArray);
        this.blockUI.stop();

      }
    );

    }



  approveBtn() {
	this.showApproveSucessMsg = false;
	this.showApproveFailMsg = false;
	this.showRejectSuccessMsg = false;
	
     if(this.checkedUsers.length === 0)
       {
       alert("Please select atleast one pending User");
       }
     else
       {
        this.blockUI.start();
       for(var i = this.checkedUsers.length; i--;) {

        this.pendingUserApprovalService.approveUser(this.checkedUsers[i]).subscribe(
          data=>{
            console.log(data);
			console.log(data.statusFlg);
            this.getAllUsers(true);
            this.checkedUsers=[];
            // call the ngOnit to reload the page
			if(data.statusFlg == 'S') {
				this.showApproveSucessMsg = true;	
			} else {
				this.showApproveFailMsg = true;
			}
			
            this.blockUI.stop();
          }
        );

      }

    }
}


rejectedBtn() {
	
	this.showApproveSucessMsg = false;
  	this.showApproveFailMsg = false;
	this.showRejectSuccessMsg = false;

  if(this.checkedUsers.length === 0)
    {
    alert("Please select atleast one pending User");
    }
  else
    {
     this.blockUI.start();
    for(var i = this.checkedUsers.length; i--;) {

     this.pendingUserApprovalService.rejectUser(this.checkedUsers[i]).subscribe(
       data=>{
         console.log(data);
         this.getAllUsers(true);
         this.checkedUsers=[];

		if(data.statusFlg == 'S') {
			this.showRejectSuccessMsg = true;	
		} else {
			this.showApproveFailMsg = true;
		}
			
         // call the ngOnit to reload the page
         this.blockUI.stop();
       }
     );

   }

 }
}



}
