import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  
  //externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/contactUs-myCsa.html';  
  externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/contactUs-myCsa.html';  
  contactHtml;
  isCpUser;
  sub:any;
  topic:string;


  constructor(  private http: HttpClient, private sanitizer: DomSanitizer, private router: Router, private activatedRoute: ActivatedRoute,private titleService: Title )
   {
   
     this.sub = this.activatedRoute.params.subscribe(params => {
      this.topic = params['topic'];
    });

   }
  
  // ngOnInit(): void {
  //     this.http.get(this.externalUrl, { responseType: 'text' }).subscribe(data => {
  //         this.contactHtml = this.sanitizer.bypassSecurityTrustHtml(data);
  //     })
     
  // }

  ngOnInit(): void {
    this.titleService.setTitle("ContactUs");
    this.http.get(this.externalUrl, { responseType: 'text' }).subscribe(data => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');

      const links = doc.querySelectorAll('a');
      links.forEach(link => {
        const href = link.getAttribute('href');
        if (href && href.includes('contactFaq.htm?view=faq&topic=accountManagement')) {
          //link.setAttribute('routerLink', '/faq?topic=accountManagement');
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=accountManagement');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=deviceManagement')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=deviceManagement');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=userManagement')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=userManagement');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=autoToner')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=autoToner');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=orderSupplies')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=orderSupplies');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=servicingDevices')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=servicingDevices');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=meterReads')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=meterReads');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=invoicing')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=invoicing');
        }
        if (href && href.includes('contactFaq.htm?view=faq&topic=taxForms')) {
          link.removeAttribute('href');
          link.setAttribute('href', '/myCanonApp/#/faq?topic=taxForms');
        }
      });

      this.contactHtml = this.sanitizer.bypassSecurityTrustHtml(doc.documentElement.innerHTML);

    });
  }
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}
 selectVariable()
{
let selectID = "#select-" + this.getQueryVariable("topic");
if (selectID != "") {
    $(selectID).prop("selected", "selected");
    $(".faqs-topic-container").removeClass("csa-show");
    let content = "#" + this.getQueryVariable("topic") + "-option-section";
    $(content).addClass("csa-show");
}
$('#faqs-topic-select').change(function () {
    switch ($(this).val()) {
        case "accountManagement-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#accountManagement-option-section").addClass("csa-show");
            break;
        case "deviceManagement-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#deviceManagement-option-section").addClass("csa-show");
            break;
        case "userManagement-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#userManagement-option-section").addClass("csa-show");
            break;
        case "autoToner-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#autoToner-option-section").addClass("csa-show");
            break;
        case "orderSupplies-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#orderSupplies-option-section").addClass("csa-show");
            break;
        case "servicingDevices-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#servicingDevices-option-section").addClass("csa-show");
            break;
        case "meterReads-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#meterReads-option-section").addClass("csa-show");
            break;
        case "invoicing-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#invoicing-option-section").addClass("csa-show");
            break;
        case "taxForms-option":
            $(".faqs-topic-container").removeClass("csa-show");
            $("#taxForms-option-section").addClass("csa-show");
            break;
        default:
            $(".faqs-topic-container").removeClass("csa-show");
            $("#accountManagement-option-section").addClass("csa-show");
    }
});
}
}
