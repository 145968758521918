import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './common/login/login.component';

import { RouteGuardService } from './service/common-service/route-guard.service';

import { DashboardComponent } from './common/dashboard/dashboard.component';
import { HomeComponent } from './common/home/home.component';
import { ContactUsComponent } from './common/contact-us/contact-us.component';
import { UserTermsConditionComponent } from './common/user-terms-condition/user-terms-condition.component';
import { UserTwoFactorComponent } from './common/user-two-factor/user-two-factor.component';
import { FaqComponent } from './common/faq/faq.component';
import { MyDevicesComponent } from './account-mgmt/my-devices/my-devices.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { UserPreferencesComponent } from './common/user-preferences/user-preferences.component';
import { OrderContractSuppliesComponent } from './product-supplies/order-contract-supplies/order-contract-supplies.component';
import { AccountDevicesComponent } from './account-mgmt/account-devices/account-devices.component';
import { OrderSuppliesSubmitMeterReadsComponent } from './product-supplies/order-supplies-submit-meter-reads/order-supplies-submit-meter-reads.component';
import { OpenOrdersComponent} from './product-supplies/open-orders/open-orders.component';
import { ClosedOrdersComponent} from './product-supplies/closed-orders/closed-orders.component';
import { RequestServiceComponent } from './service-request/request-service/request-service.component';
import { CreateServiceComponent } from './service-request/create-service/create-service.component';
import { ClosedServiceComponent } from './service-request/closed-service/closed-service.component';
import { OpenServiceComponent } from './service-request/open-service/open-service.component';
import { CfsInvoiceInquiryComponent } from './invoices/cfs-invoice-inquiry/cfs-invoice-inquiry.component';
import { MeterHistoryComponent } from './meter-reads/meter-history/meter-history.component';
import { SubmitMeterReadsComponent } from './meter-reads/submit-meter-reads/submit-meter-reads.component';
import { OrderSuppliesListingComponent } from './product-supplies/order-supplies-listing/order-supplies-listing.component';
import { OrderSuppliesShippingAddressComponent } from './product-supplies/order-supplies-shipping-address/order-supplies-shipping-address.component';
import { CsaInvoiceInquiryComponent } from './invoices/csa-invoice-inquiry/csa-invoice-inquiry.component';
import { OrderSuppliesReviewComponent } from './product-supplies/order-supplies-review/order-supplies-review.component';
import { OrderSuppliesViewCartComponent} from './product-supplies/order-supplies-view-cart/order-supplies-view-cart.component';
import { AccountSupportComponent } from './account-mgmt/account-support/account-support.component';
import { AccountSupportResponseComponent } from './account-mgmt/account-support/account-support-response/account-support-response.component';
import { OrderInquiryComponent } from './product-supplies/order-inquiry/order-inquiry.component';
import {RequestDevicesComponent} from './account-mgmt/request-devices/request-devices.component';
import {RegisterUserComponent} from './register-user/register.component';
import { PaymentMethodComponent } from './invoices/payment-method/payment-method.component';
import { RequestDevicesProcessedComponent} from './account-mgmt/request-devices-processed/request-devices-processed.component';
import { RequestDevicesProcessedConfirmationComponent } from './account-mgmt/request-devices-processed-confirmation/request-devices-processed-confirmation.component';
import { AssignDevicesComponent } from './account-mgmt/assign-devices/assign-devices.component';
import { OtherCanonProductsComponent} from './product-supplies/other-canon-products/other-canon-products.component';
import { DeviceDetailsComponent } from './account-mgmt/device-details/device-details.component';
import { ConfiguratorMyquotesComponent } from './configurator/customer-portal/configurator-myquotes/configurator-myquotes.component';
import { OtherCanonProductsCheckoutComponent } from './product-supplies/other-canon-products-checkout/other-canon-products-checkout.component';
import { OtherCanonProductsShopcartComponent } from './product-supplies/other-canon-products-shopcart/other-canon-products-shopcart.component';
import { ConfiguratorCreatequoteComponent } from './configurator/customer-portal/configurator-createquote/configurator-createquote.component';
import { OtherCanonProductsCartconfirmationComponent } from './product-supplies/other-canon-products-cartconfirmation/other-canon-products-cartconfirmation.component';
import { EliteComponent } from './elite/elite.component';
import { AlertComponent} from './fleet-mgmt/alert/alert.component';
import { TonerLevelComponent } from './fleet-mgmt/toner-level/toner-level.component';
import { MeterReadsComponent } from './fleet-mgmt/meter-reads/meter-reads.component';
import { ContractBillReportsComponent } from './contract-bill-reports/contract-bill-reports.component';
import { ConfiguratorBuildquoteComponent } from './configurator/customer-portal/configurator-buildquote/configurator-buildquote.component';
import { RequestprintassessmentComponent} from './fleet-mgmt/requestprintassessment/requestprintassessment.component';
import { SystemadministrationComponent} from './fleet-mgmt/systemadministration/systemadministration.component';
import { SystemadministrationEditlinksComponent } from './fleet-mgmt/systemadministration-editlinks/systemadministration-editlinks.component';
import { ConfiguratorBuildoptionComponent } from './configurator/customer-portal/configurator-buildoption/configurator-buildoption.component';
import { ConfiguratorSummaryComponent } from './configurator/customer-portal/configurator-summary/configurator-summary.component';
import { ConfiguratorShippingComponent } from './configurator/customer-portal/configurator-shipping/configurator-shipping.component';
import { ConfiguratorMaintenanceComponent } from './configurator/customer-portal/configurator-maintenance/configurator-maintenance.component';
import { ConfiguratorConfirmationComponent } from './configurator/customer-portal/configurator-confirmation/configurator-confirmation.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AutoTonerDetailsComponent } from './common/auto-toner-details/auto-toner-details.component';
import { AutoTonerUploadDownloadComponent } from './common/auto-toner-upload-download/auto-toner-upload-download.component';
import { AutoTonerUploadDownloadModalComponent } from './common/auto-toner-upload-download-modal/auto-toner-upload-download-modal.component';
import { RemotesupportComponent } from './fleet-mgmt/remotesupport/remotesupport.component';
import { EliteHomeComponent } from './elite/elite-home.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { SiteSurveyComponent} from './survey/site-survey/site-survey.component';
import { MfpSettingComponent } from './survey/mfp-setting/mfp-setting.component';
import { PurchaseSuppliesComponent } from './product-supplies/purchase-supplies/purchase-supplies.component';
import { P66LoginComponent } from './common/p66/p66-login.component';
import { P66NewRegistrationComponent } from './common/p66/p66-new-registration/p66-new-registration.component';
import { ConfiguratorAdminComponent } from './admin/configurator/configurator-admin/configurator-admin.component';
import { ProductCatalogComponent } from './webadmin/product-catalog/product-catalog.component';
import { CreateCatalogComponent } from './webadmin/create-catalog/create-catalog.component';
import { DefineLevelsComponent } from './admin/configurator/define-levels/define-levels.component';
import { LeaseoptionsComponent } from './admin/configurator/leaseoptions/leaseoptions.component';
import { ManageleveldetailsComponent } from './admin/configurator/manageleveldetails/manageleveldetails.component';
import { ConfigUsersComponent} from './admin/configurator/config-users/config-users.component';
import { ProductExclusionComponent } from './admin/configurator/product-exclusion/product-exclusion.component';
import { ManagePricebookComponent } from './admin/configurator/manage-pricebook/manage-pricebook.component';
import { RoleDefinitionComponent} from './webadmin/role-definition/role-definition.component';
import { RoleManageComponent} from './webadmin/role-definition/role-manage/role-manage.component';
import { UserSearchComponent } from './webadmin/user-search/user-search.component';
import { PendingUserApprovalComponent } from './webadmin/pending-user-approval/pending-user-approval.component';
import { DefineLookupsComponent } from './webadmin/define-lookups/define-lookups.component';
import { EnterLookupsComponent } from './webadmin/enter-lookups/enter-lookups.component';
import { UserroleAssignmentComponent } from './webadmin/userrole-assignment/userrole-assignment.component';
import { PendingSerialApprovalComponent } from './webadmin/pending-serial-approval/pending-serial-approval.component';
import { LookupsDefinitionComponent } from './webadmin/lookups-definition/lookups-definition.component';
import { ReviewApprovalSerialsComponent } from './webadmin/review-approval-serials/review-approval-serials.component';
import { MarketingAdminComponent } from './webadmin/marketing-admin/marketing-admin.component';
import { GridRoleComponent } from './webadmin/grid-role/grid-role.component';
import { LookupMaintainValueComponent } from './webadmin/lookup-maintain-value/lookup-maintain-value.component';
import { CsaInvoicePaymentComponent } from './invoices/csa-invoice-payment/csa-invoice-payment.component';
import { SalesRepUserSearchComponent } from './sales-rep/sales-rep-user-search/sales-rep-user-search.component';
import { TonerReturnComponent } from './product-supplies/toner-return/toner-return.component';
import { TonerReturnConfirmationComponent } from './product-supplies/toner-return-confirmation/toner-return-confirmation.component';
import { TonerReturnHistoryComponent } from './product-supplies/toner-return-history/toner-return-history.component';
const routes: Routes = [

  { path: '', component: LoginComponent,data:{ showHeader: false, showSidebar: false, showFooter: false, loginCss :true } },

  { path: 'login', component: LoginComponent,  data: { showHeader: false, showSidebar: false, showFooter: false, loginCss :true} },

  //{ path: 'dashboard', component: DashboardComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }}

  { path: 'dashboard', component: DashboardComponent,  canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: false, showFooter: true }},

  // { path: 'home', component: HomeComponent,  canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'resetpassword', component: ResetPasswordComponent,data: { showHeader: false, showSidebar: false, showFooter: true }},
 
  { path: 'contactus', component: ContactUsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
 
  { path: 'preferences', component: UserPreferencesComponent,  canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'termsconditions', component: UserTermsConditionComponent,  canActivate:[RouteGuardService], data: { showHeader: false, showSidebar: false, showFooter: true }},

  { path: 'twoFactor', component: UserTwoFactorComponent,  canActivate:[RouteGuardService], data: { showHeader: false, showSidebar: false, showFooter: false }},
 
  { path: 'faq', component: FaqComponent,  canActivate:[RouteGuardService], data:{ showHeader: true, showSidebar: false, showFooter: true} },

  { path: 'mydevices', component: MyDevicesComponent, canActivate:[RouteGuardService],  data: { showHeader: true, showSidebar: false, showFooter: true}},

  { path: 'salesrepUsersearch', component: SalesRepUserSearchComponent, canActivate:[RouteGuardService],  data: { showHeader: true, showSidebar: false, showFooter: true}},
  
  { path: 'assigndevices', component: AssignDevicesComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
 
  { path: 'accountdevices', component: AccountDevicesComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'ordersupplies', component: OrderContractSuppliesComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'osmeterreads', component: OrderSuppliesSubmitMeterReadsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
    
  { path: 'opensupplyorders', component: OpenOrdersComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'closedsupplyorders', component: ClosedOrdersComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'servicerequest', component: RequestServiceComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'createservice', component: CreateServiceComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'closedsr', component: ClosedServiceComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'opensr', component: OpenServiceComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'cfsinvoiceinquiry', component: CfsInvoiceInquiryComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'submitmeterreads', component: SubmitMeterReadsComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'meterreadhistory', component: MeterHistoryComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'ordersupplieslisting', component: OrderSuppliesListingComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'osshippingaddress', component: OrderSuppliesShippingAddressComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'csainvoiceinquiry', component: CsaInvoiceInquiryComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'osreview', component: OrderSuppliesReviewComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'ordersuppliesviewcart', component: OrderSuppliesViewCartComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'invoicepayment', component: CsaInvoicePaymentComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'accountsupport', component: AccountSupportComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'accountsupportresponse', component: AccountSupportResponseComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'orderinquiry', component: OrderInquiryComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  // { path: 'userregbyserial', component: RegisterUserBySerialNumberComponent, data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'requestdevices', component: RequestDevicesProcessedComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'userreg/:qry', component: RegisterUserComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  // { path: 'userreg', component: RegisterUserComponent, data: { showHeader: true, showSidebar: false, showFooter: true }},
  
 // { path: 'requestdevicesprocessed', component: RequestDevicesProcessedComponent, data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path: 'requestdeviceprocessedconfirmation', component: RequestDevicesProcessedConfirmationComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true}},
  
  { path: 'paymentmethods', component: PaymentMethodComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path: 'othercanonproducts', component: OtherCanonProductsComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'devicedetails', component: DeviceDetailsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'othercanonproductcheckout', component: OtherCanonProductsCheckoutComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'othercanonproductshopcart', component: OtherCanonProductsShopcartComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'createquote', component: ConfiguratorCreatequoteComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'myquotes', component: ConfiguratorMyquotesComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'buildquote', component: ConfiguratorBuildquoteComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'buildoption', component: ConfiguratorBuildoptionComponent,  canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'configsummary', component: ConfiguratorSummaryComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'configshipping', component: ConfiguratorShippingComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'configmaint', component: ConfiguratorMaintenanceComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'configconfirmation', component: ConfiguratorConfirmationComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path: 'othercanonproductcartconfirmation', component: OtherCanonProductsCartconfirmationComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path: 'elite', component: EliteComponent, data: { showHeader: false, showSidebar: false, showFooter: false }},

  { path: 'elitereads', component: EliteComponent, data: { showHeader: false, showSidebar: false, showFooter: false }},
  
  { path: 'ctrbillreports', component: ContractBillReportsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
   
   {path: 'fleetmgmtalerts', component:AlertComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
   
   {path:'fleetmgmttonerlevel', component: TonerLevelComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

   {path:'fleetmgmtmeterreads', component:MeterReadsComponent,canActivate:[RouteGuardService], data:{showHeader: true, showSidebar: false, showFooter: true}},

   {path: 'requestprintassessment', component: RequestprintassessmentComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

   {path:'fleetmgmtsystemadminstration', component:SystemadministrationComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
   
   {path:'fleetmgmtsysadminedtlinks', component:SystemadministrationEditlinksComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true } },
   
   {path: 'home', component: HomepageComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
   
	  { path: 'autotonerdetails', component: AutoTonerDetailsComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'atuploaddownload', component: AutoTonerUploadDownloadComponent,canActivate:[RouteGuardService], data: { showHeader: false, showSidebar: false, showFooter: false }},

  { path: 'atuploaddownloadmodal', component: AutoTonerUploadDownloadModalComponent,canActivate:[RouteGuardService], data: { showHeader: false, showSidebar: false, showFooter: false }},
  
  {path:'fleetmgmtremotesupport', component:RemotesupportComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'usermgmt', component:ManageUserComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'sitesurvey', component:SiteSurveyComponent, data:{showHeader: false, showSidebar: false, showFooter: false}},

  {path:'mfpsetting', component:MfpSettingComponent, canActivate:[RouteGuardService], data:{showHeader: true, showSidebar: false, showFooter: true}},
  
  {path:'shopsupplies', component:PurchaseSuppliesComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  { path: 'redirectFromP66', component: P66LoginComponent, data: { showHeader: false, showSidebar: false, showFooter: true }},

  { path: 'p66NewReg', component: P66NewRegistrationComponent, data: { showHeader: false, showSidebar: false, showFooter: false }},

  {path:'configadmin', component:ConfiguratorAdminComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'productCatalog', component:ProductCatalogComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'createCatalog', component:CreateCatalogComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  {path:'definelevels', component:DefineLevelsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'leaseoptions', component:LeaseoptionsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'manageleveldetails', component:ManageleveldetailsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
   
  {path:'configusers', component:ConfigUsersComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'productexclusion', component: ProductExclusionComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'managepricebook', component:ManagePricebookComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }} ,

  {path:'roledefinition', component:RoleDefinitionComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'rolemanage', component:RoleManageComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'pendingusrapproval', component:PendingUserApprovalComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'userSearch', component:UserSearchComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'definelookups',component: DefineLookupsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'enterlookups', component:EnterLookupsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'maintainlookupvalue', component:LookupMaintainValueComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'userRoleAssignment', component:UserroleAssignmentComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'pendingserialapproval', component:PendingSerialApprovalComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  {path:'createlookup', component:LookupsDefinitionComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'reviewapprovedserials', component:ReviewApprovalSerialsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'marketingAdmin', component:MarketingAdminComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'gridrole', component:GridRoleComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'tonerreturn', component: TonerReturnComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'tonerreturnconfirm', component: TonerReturnConfirmationComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},

  {path:'tonerreturnhistory', component: TonerReturnHistoryComponent,canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }}


];

@NgModule({

   imports: [RouterModule.forRoot(routes,{ useHash: true, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled',

       scrollOffset: [0, 64], onSameUrlNavigation: 'reload',relativeLinkResolution: 'legacy'})],

  //imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]

})

export class AppRoutingModule {

 }

