import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SwitchUserService } from 'src/app/admin/switch-user/switch-user.service';
import { SalesRepService } from 'src/app/sales-rep/sales-rep.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: string;
  salesTeamFlag: string="N";
  loggedUserName: null;
  userName: string;
  p66User: string = "N"; 
  constructor(private router: Router, private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService,private suService:SwitchUserService, private spService:SalesRepService) { }
  
  ngOnInit(): void {
   // throw new Error('Method not implemented.');

   this.suService.switchUserNotifyObservable$.subscribe(() => {
   this.onloadheader();
   })
   this.onloadheader()
   if(this.userName.indexOf("P66") != -1)
   {
    this.p66User ="Y";
   }
   this.checkSalesRespUser();
  }
  onloadheader()
  {
    this.user =sessionStorage.getItem('fullName');
    this.userName =sessionStorage.getItem('userName');
    this.checkSwitchedUser();
  }
  logout() {

    this.loginAuthenticationService.logout();
    this.loggedUserName = null;
    //this.router.navigate(['login']);
  }
  checkSwitchedUser(){
    let oParams = { };
    let data = this.suService.checkSwitchedUser(oParams).subscribe(
      data=>{
         if(data){
           let suf = data["switchedUserFullName"]; 
           if(suf !== "")
           {
            this.user =suf;
           }
          
          }
      });
   }

   checkSalesRespUser(){
    let oParams = { };
    let data = this.spService.checkSalesRespUser().subscribe(
      data=>{
         if(data){
           let flg = data["flg"]; 
           if(flg !== "")
           {
            this.salesTeamFlag =flg;
           }
          
          }
      });
   }

   onSwitchUserClick() {
    // Your function logic in the header component
    console.log('Switch user button clicked in the header component');
    this.checkSwitchedUser();
  }
}