import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderInquiryService } from 'src/app/product-supplies/order-inquiry/order-inquiry.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;

  @Input() ordID;
  @Input() src;
  @Input() totPrice = 0.0;

  orderHeader:any;
  orderLineList = [];
  isBillableOrder:boolean=false;
  invList = "";
  constructor(private orderInquiryService: OrderInquiryService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getOrderDetails();
  }

  getOrderDetails() {
	
    this.orderHeader = undefined;
    this.orderLineList = [];
    this.isBillableOrder = false;
    this.invList = "";
    
    var param = {
      "orderId" 	: this.ordID,
      "orderBy" 	: "PART_NUM",
      "sortBy" 	: "ASC",
      "action" 	: "open",
      "source" 	: this.src,
      "totalAmt" 	: this.totPrice
    }
    this.blockUI.start();
    let data = this.orderInquiryService.getOrderDetails(param).subscribe(
        data=>{
          console.log(data);
      this.orderHeader = data.orderHeaderDetails;
        this.orderLineList = data.orderLineDetails;
        for(let i =0;i<this.orderLineList.length;i++){
          if(this.orderLineList[i].strReqDlvyDate==undefined || this.orderLineList[i].strReqDlvyDate==null || this.orderLineList[i].strReqDlvyDate==''){
            this.orderLineList[i].strReqDlvyDate='-';
  
          }
  
        }
        console.log(this.orderLineList);
        this.isBillableOrder = data.isBillableOrder;
        this.invList = data.invoiceDetail;

        let tmpOrdLinLst=this.orderLineList;
        for(let k in tmpOrdLinLst){
             let objOl= tmpOrdLinLst[k];
             let shpUrl="";
             let trkNum="";
             shpUrl=objOl["strShipUrl"];
             trkNum=objOl["strTrackNum"];
             let arrShpURL=[];
             let arrShpTrk=[];

             if(shpUrl){
              arrShpURL=shpUrl.split("$$$");
             }
             if(trkNum){
              arrShpTrk=trkNum.split(",");
             }

             let tmpObjTrkUrl={};
             for(let trk in arrShpTrk){
              let sTrk="";
              sTrk=arrShpTrk[trk];
              sTrk=sTrk.trim();
              arrShpTrk[trk]=sTrk;
              tmpObjTrkUrl[sTrk]="";
               for(let iUrl in arrShpURL){
                  let urlStr=""; 
                  urlStr =arrShpURL[iUrl]
                   if(urlStr.includes(sTrk)){
                    tmpObjTrkUrl[sTrk]=urlStr;
                      
                   }
               }
             }
             
             objOl["strShipUrl"]=tmpObjTrkUrl;
             objOl["strTrackNum"]=arrShpTrk;

             tmpOrdLinLst[k]=objOl; 
        }
        this.orderLineList=tmpOrdLinLst;

        this.blockUI.stop();
        });
    
    
  }	

  closeOrderDetails(){
    this.activeModal.close();
  }

  
}
