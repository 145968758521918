import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { RequestListingService } from './request-listing.service';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-request-service',
  templateUrl: './request-service.component.html',
  styleUrls: ['./request-service.component.css']
})
export class RequestServiceComponent {
  @BlockUI() blockUI : NgBlockUI;

  serviceRequestList:any=[];
  modelList:[];
  numOfRecsMsg:string="";
  pageNum=1;
  totalRecords=0;
  recPerPage=10;
  start:any=1;
  end:any=10;
  arrPgLinks:any=[];
  serialNumber:string="";
  model:string="";
  location:string="";
  selectedUserAccts:string="";
  userAccountList=[];
  pleaseWaitCls:boolean = false;
  orderBy:string="";
  sortColumn: string="";
  userList=[];
  dateRanges="30";
  numOfDaysList=[];
  showMsg=false;
  displayAlertMsg: any;
  displayStyle = "none";
  createSrParam: any;
  displayMsgStyle: string;
  ocMsg: string;
  displayOpenStyle= "none";
  incidentNumber: any;
  incidentDate: any;
  dispPhone: any;
  dispatcherMsg: string;
  callCusaFlag: any;
  callCusaMsg= "";
  searchAll= "";
  srCreateFlag = 'N';
  showNoRecord:boolean=false;
  constructor( private commonService:CommonServiceService,private requestListingService:RequestListingService,private router: Router,private modalService: NgbModal,private titleService: Title,private route: ActivatedRoute) { }

  ngOnInit( ) {

    this.route.queryParams.subscribe(
      params => {
        let sSrl=params['srl'];
        if(sSrl){
          this.serialNumber=sSrl;
        }

        this.srCreateFlag = params['srCreateFlag']
       
       }
    )
    this.titleService.setTitle("Service Request");
    
    if(this.srCreateFlag === 'Y'){
      this.searchAll = 'Y';
    }

    this.getRequestServiceList(1,null);

    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });
      this.commonService.setCommonFunctions();
      this. displayAlert();

    
   
   }

  getServiceRequestsOnSearch = function(pgNum){
    this.searchAll = "Y";
    this.getRequestServiceList(pgNum);
  }
  
   getRequestServiceList(pgNum: any,event: any){
    if(event){
      event.preventDefault();
    }
    this.blockUI.start();
    this.showNoRecord =false;
    this.pageNum=pgNum;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("pageNum :"+pgNum);
    console.log("recPerPage :"+this.recPerPage);
    let oParams = { "pageNum": pgNum, "pageSize": this.recPerPage, 
    "srhSerialNum":this.serialNumber,"srhModelNum":this.model,"srhAddress":this.location,"partyIDs":this.selectedUserAccts,"searchAll":this.searchAll ,"orderBy":this.orderBy,"sortColumn":this.sortColumn};
    let data = this.requestListingService.getRequestServiceList(oParams).subscribe(
      data=>{        
         this.totalRecords=data.NumRecs;             
         this.serviceRequestList = data.srList; 
         this.modelList=data.modelList.split("|");
			   this.userList=data.assignToList.split("|");
			   this.numOfDaysList=data.numDaysList.split("|");
         this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
         this.showNoRecord =true;
         if(this.srCreateFlag === 'Y' && this.totalRecords > 0){
            this.displayMsg(this.serviceRequestList[0], '');
          }
        
         this.blockUI.stop();
      });
      
  }
  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.serialNumber="";
    this.model="";
    this.location="";
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
   // this.userAccountList=[];
    this.searchAll = '';
    this.getRequestServiceList(1,null);
  }

  exportToExcel(){
   let url="servicerequest/downloadExcel";
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   //let qryStr="srhSerialNum="+this.serialNumber+"&model="+this.model+"&location="+this.location+"&selectedUserAccts="+this.selectedUserAccts+"&view=serviceRequest";
   let params = { "srhSerialNum":this.serialNumber,"srhModelNum":this.model,"srhAddress":this.location,"partyIDs":this.selectedUserAccts,"searchAll":"" ,"orderBy":this.orderBy,"sortColumn":this.sortColumn
   ,"view":'serviceRequest'};
   let qryStr = Object.keys(params)
  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
  .join('&');
   url=url+"?"+qryStr;
   this.commonService.exportToExcel(url);
 }
 displayMsg(param,event)
 {
  if(event){
    event.preventDefault();
  }
  this.createSrParam =param;
 if(this.displayAlertMsg == 'Y')
 {
 // const modalElement = document.getElementById('display-msg-Modal');
   // if (modalElement) {
   //   modalElement.style.display = 'block';
  //  }
  //this.modalService.open('displayMsgModal');
  this.displayStyle='flex';
   
 }
 else
 {
  this.redirectToCreate();
 }
 }
 closeDisplayModal()
 {
  this.displayStyle='none';
 }
 closeOpenModal()
 {
  this.displayOpenStyle='none';
 }
 displayAlert()
 {
  let data = this.requestListingService.getDisplayAlert().subscribe(
    data=>{
      if(data.status == 'S'){
      this.displayAlertMsg = data.displayalert;
      }
      else
      {
        this.displayAlertMsg = 'N';
      }
      console.log("this.displayAlertMsg:"+this.displayAlertMsg);
    });
 }

 redirectToCreate()
 {
  this.displayStyle='none';
  this.displayOpenStyle='none';
  this.blockUI.start();
  const navigationExtras: NavigationExtras = {
    queryParams: this.createSrParam
  };
  let param = { "serialNumber": this.createSrParam.serialNumber, "source": this.createSrParam.source};
  let data = this.requestListingService.getOpenCall(param).subscribe(data=>{
      let response = data;
      this.blockUI.stop();     
      var allowHeatWSCall = false;
        
      if(response!=null){
        this.incidentNumber=data.incidentNumber;
        this.incidentDate=data.incidentDate;
        var taskNumber=data.taskNumber;
        this.dispPhone=data.dispPhone;
         this.callCusaFlag=data.callCusaFlag;
        var incidentMsg=data.incidentMsg;
        //callCusaFlag = data.callCusaFlag;
        
        if(this.callCusaFlag != null && this.callCusaFlag != undefined && this.callCusaFlag=='Y'){
          
            	this.callCusaMsg = "We are unable to place a service request for this device.  Please contact Canon USA Call Center at "
                               +this.dispPhone+" for assistance placing your service request";

         // this.ocMsg = thcallCusaMsg;
          this.openOcMsgModal();					
          
        }else if(incidentMsg != null && incidentMsg != undefined && incidentMsg=='Y'){
          
         
            
            this.dispatcherMsg = "Please call a dispatcher at " + this.dispPhone +
            " if you need additional assistance with service on this device or to update the existing request";
         // this.ocMsg = openCallMsg;
          this.openOcMsgModal();
            
          allowHeatWSCall = true;
          }else{
            this.router.navigate(['/createservice'],navigationExtras);
          }
      }else{					
        this.router.navigate(['createservice'],navigationExtras);

      }
    });


 }
  openOcMsgModal() {
    this.displayOpenStyle='flex';
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
