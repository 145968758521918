import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { OpenServiceService } from 'src/app/service-request/open-service/open-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap'
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-open-service',
  templateUrl: './open-service.component.html',
  styleUrls: ['./open-service.component.css']
})
export class OpenServiceComponent implements OnInit {
	
	  @BlockUI() blockUI : NgBlockUI;
	
	  openServiceRequestList=[];
	  userAccountList=[];
	  selectedUserAccts:string="";
	  serialEqpNumber:string="";
	  requestNumber:string="";
	  model:string="";
	  users="";s
	  userList=[];
	  modelList=[];
	  dateRanges="30";
	  numOfDaysList=[];
	  showMsg=false;
	  recPerPage=10;
	  arrPgLinks=[];
	  pageNum=1;
	  msgClass;
	  srchTxt ="";
	  numOfRecsMsg="";
	  totalRecords=0;
	  isVisible=[];
	  additionalNotes=[];
	  showNotes=[];

	  cancelReason = "";
	  cancelNotes = "";
	  reasonList = [];
	  crMdlMsg = "";
	  dispMsg = false;
	  showCRDiv = true;
	
	  cancelReqNum= "";	
	  cancelReqSource = "";
	
		
	  pleaseWaitCls:boolean = false;	

	  @ViewChild('cancel-request-modal') cancelRequestModal: ElementRef;

  	constructor(private commonService:CommonServiceService, private openServiceService:OpenServiceService
				, private util : Util, private modalService: NgbModal, private titleService: Title) { }

  	ngOnInit() {
		this.titleService.setTitle("Open Service Request");
		this.getOpenServiceRequests(1, null);
		
		let data = this.commonService.getUserAccounts().subscribe(
	      data=>{
	        this.userAccountList = data.data;
	      });

		
		this.openServiceService.getCancelReasons().subscribe(data => {
			if (data != null) {
				console.log('getOpenServiceRequests:', data);
				this.reasonList=data.CancelReasons.split("|");		
			}
		});
		
		this.commonService.setCommonFunctions(); 	 
  	}

	clearAll(event:any) {
	
		if(event){
	      event.preventDefault();
	    }

		this.selectedUserAccts="";
		this.requestNumber="";
		this.serialEqpNumber="";
		this.model="";
		this.users="";
		this.dateRanges="30";
		
		for(let actIdx in this.userAccountList){
	      this.userAccountList[actIdx]["enabled"]=true;
	    }

		this.getOpenServiceRequests(1, null);

	
	}

	getOpenServiceRequests(pagenumber,event: any) {
		
		if(event!=null){
      		event.preventDefault();
    	}

		this.showMsg=false;
		//this.pleaseWaitCls=true;
		this.blockUI.start();
		
		this.pageNum=pagenumber;
		this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
		console.log("Full User list : " + this.userAccountList);
		console.log("Sel User list : " + this.selectedUserAccts);
		
		var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"partyIDs":this.selectedUserAccts,
			"srhReqNum": this.requestNumber,
    		"srhSerialNum":this.serialEqpNumber,
			"srhModelNum":this.model,
			"srhAssignedTo":this.users,
			"srhDuration":this.dateRanges,
			//"sortCol":this.sortCol,
			//"orderBy":this.orderBy,
			//"partyIDs":
	    };
		
		this.openServiceService.getOpenServiceRequests(param).subscribe(data => {
		
		if (data != null) {
		    
			console.log('getOpenServiceRequests:', data);
			this.openServiceRequestList = data.data;
			this.modelList=data.modelList.split("|");
			this.userList=data.assignToList.split("|");
			this.numOfDaysList=data.numDaysList.split("|");
			this.totalRecords=data.totalRecords;
			
			this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         	this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

			if(this.totalRecords == 0) {
				this.showMsg=true;
			}
			//this.pleaseWaitCls=false;
			this.blockUI.stop();
		}

    });
		
		
	}
	

	toggleDiv(indexOfelement, event: any) {
		this.isVisible[indexOfelement] = this.isVisible[indexOfelement] ? false : true;
	}
	
	getAdditionalInst(requestNumber,indexOfelement) {
		var param = {
			"reqNum": requestNumber,
		}
		
		this.openServiceService.getAdditionalInstructions(param).subscribe(data => {
		
			if (data != null) {
			    
				//console.log('getAdditionalInst:', data);
				this.additionalNotes[indexOfelement] = data.AI;
				this.showNotes[indexOfelement] = true;
			}

    	});
				
		
	}	
	
	onCancelRequestModalClick(source, requestNumber)	{
		this.dispMsg=false;
		this.crMdlMsg ="";
		this.showCRDiv=true;
		this.cancelReqNum = requestNumber;
		this.cancelReqSource = source;
		this.cancelReason = "";
		this.cancelNotes = "";
		
	}
		
	
	submitCancelReason() {
		// on submit 
		var param = {
			"incNum" : this.cancelReqNum,
			"changeReason" : this.cancelReason,
			"changeNote" : this.cancelNotes,
			"mSource" : this.cancelReqSource
		}
		
		console.log(param);		
		
		this.openServiceService.cancelServiceRequest(param).subscribe(data => {	
			if (data != null && data.CSR == "OK") {
			    this.crMdlMsg="The request has been cancelled successfully. click Close button to close the window.";
				this.dispMsg=true;
				this.showCRDiv=false;
			}

    	});
					
		
	}	
	
	
	closeCR() {
		//this.cancelRequestModal.nativeElement.setAttribute('aria-hidden', 'true');
		//let element: HTMLElement = document.getElementById('modalspan') as HTMLElement;
		//element.modal('close');	
		this.getOpenServiceRequests(1, null);	
	}
	
	exportToExcel(){
		let url="servicerequest/downloadExcel";
		this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
		var params = {
			"partyIDs":"",
			"srhReqNum": this.requestNumber,
    		"srhSerialNum":this.serialEqpNumber,
			"srhModelNum":this.model,
			"srhAssignedTo":"All Users",
			"srhDuration":this.dateRanges,
			"view":'openServiceRequest',
			"sortColumn":"incident_number",
			"orderBy":"D"
			//"orderBy":this.orderBy,
			//"partyIDs":
	    };
		let qryStr = Object.keys(params)
  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
  .join('&');
		url=url+"?"+qryStr;
		this.commonService.exportToExcel(url);
	  }


stopVideo(id: string){
		console.log('Printing id value: ', id);
		const video = document.getElementById(id) as HTMLVideoElement;
		if(video){
		 video.pause();
		 //video.load();
		}
}
	
}
