import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  defaultUrl: string;
  constructor(private http:HttpClient,private util:Util) {
     this.defaultUrl=util.getBaseUrl();
   }

//    getDashboard = function () {
//     let url = this.defaultUrl + "dboard/getDashBoardDetails?dType='C'";
//     let params = {};
//     return this.http
//         .post(url, params.toString, httpOptions)
//         .pipe(catchError((this.util.handleError("getDashboard"))));
// }

getDashboard = (paramObj) => {
  
  let url = this.defaultUrl + 'dboard/getDashBoardDetails?';
  let params = new URLSearchParams();
  for (let key in paramObj) {
      params.set(key, paramObj[key])
  }
  let qryStr = params.toString();
  url = url + qryStr;

  return this.http
      .post(url, qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getDashboard"))));

}

getInvPdf(invNumber){
  var url="invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNumber+"&source=ORACLE";
  window.open(this.util.getBaseUrl() + url);
}


}
