import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CfsInvoiceInquiryService } from './cfs-invoice-inquiry.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cfs-invoice-inquiry',
  templateUrl: './cfs-invoice-inquiry.component.html',
  styleUrls: ['./cfs-invoice-inquiry.component.css']
})
export class CfsInvoiceInquiryComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  numOfRecsMsg:string="";
  statusList:any=[];
  invList:any=[];
  arrPgLinks:any=[];
  userAccountList:any=[];
  pleaseWaitCls:boolean = false;
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  start:number=1;
  end:number=10;
  selectedUserAccts:string = "";
  srchInvoiceNumber:string = "";
  srchInvoiceFromDate:Date = null;
  srchInvoiceToDate:Date = null;
  status:string = "";
  selectedInvoiceNumber:string = "";
  showMsg : boolean = false;
  msg : string = "";

  @ViewChild('downloadModal') downloadModalTemplate:TemplateRef<any>;
  downloadModal: BsModalRef;


  constructor(private commonService:CommonServiceService, private cfsInvoiceInquiryService:CfsInvoiceInquiryService, 
                private util : Util, private modalService: BsModalService, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("CFS Invoice Inquiry");

    this.blockUI.start();

    this.getStatusList();
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
        this.blockUI.stop();
        //this.getCfsInvoiceList(1, null);
      });
    
    //this.commonService.setCommonFunctions();
    this.commonService.setMoreInfo();
    this.commonService.expandCollapseAll();
    this.commonService.keepSearchSectionOpen();
  }

  openModal() {
    this.downloadModal = this.modalService.show(this.downloadModalTemplate);
  }
  
  closeModal() {
    this.downloadModal.hide();
  }

  getStatusList(){
    
    let data = this.cfsInvoiceInquiryService.getStatusList().subscribe(
     data=>{
       this.statusList = data;
     });
 }

 changeRecPerPage(event:any) {
  this.end = this.recPerPage - 1;
  this.start = 0;
  this.getCfsInvoiceList(1,event);
 }

 getInvPdf(inv){
  var paramObj = {};
  paramObj["custNum"] = inv.customerNumber;
  paramObj["invNum"] = inv.invNumber;
  paramObj["source"] = inv.source;
  this.selectedInvoiceNumber = inv.invNumber;
  
  let data = this.cfsInvoiceInquiryService.downloadPdf(paramObj).subscribe(
    data=>{
      let binary = data.fileData;
      if (binary != null && binary !== 'undefined') {
      const pdfWindow = window.open('');
      pdfWindow.document.write('<title>Canon U.S.A. - Invoices</title>');
      pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${encodeURI(binary)}'></iframe>`);
      }
      else
      {
      if (data.error_flag) {
        this.openModal();
      }
    }
      this.blockUI.stop();
    });  
}

 getCfsInvoiceList(pgNum: any,event: any){
  if(event!=null){
   event.preventDefault();
  }

  const format = 'dd-MMM-yyyy';
  const locale = 'en-US';

  this.blockUI.start();

  this.clearMsg();

  this.pleaseWaitCls=true;
  this.pageNum=pgNum;
  this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
  console.log("pageNum :"+pgNum);
  console.log("recPerPage :"+this.recPerPage);
      let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.srchInvoiceNumber, 
                "invDateFrom" : (this.srchInvoiceFromDate == null ? '' :formatDate(this.srchInvoiceFromDate, format, locale)),
                "invDateTo" : (this.srchInvoiceToDate == null ?'': formatDate(this.srchInvoiceToDate, format, locale)) , "status" : this.status, "pageNum":this.pageNum, "recsPerPage":this.recPerPage };

  let data = this.cfsInvoiceInquiryService.getInvoiceList(oParams).subscribe(
    data=>{
       this.invList = data.data; 
       this.totalRecords=data.noOfRecs;

       if(this.totalRecords == 0){
        this.showMsg = true;
        this.msg = "No Records Found";
      }
       this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
       this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
       this.pleaseWaitCls=false;
       this.blockUI.stop();
      });

 }


 exportToExcel(){
  if(event){
    event.preventDefault();
  }
  const format = 'dd-MMM-yyyy';
  const locale = 'en-US';
  let url="cfsInvoice/cfsInvoiceExport";
  this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
  
  let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.srchInvoiceNumber, "invDateFrom" : (this.srchInvoiceFromDate == null ? '' :formatDate(this.srchInvoiceFromDate, format, locale)),
  "invDateTo" : (this.srchInvoiceToDate == null ?'': formatDate(this.srchInvoiceToDate, format, locale)), "status" : this.status, "pageNum":this.pageNum, "recsPerPage":this.recPerPage };


                 
  let qryStr="";
   for (let entry in oParams) {
     qryStr += entry + '=' + encodeURIComponent(oParams[entry]) + '&';
   }
   qryStr = qryStr.substring(0, qryStr.length-1); // remove last '&'

   this.commonService.exportToExcel(url+"?"+qryStr);
 }

 clearAll() {
	this.srchInvoiceToDate = null;
	this.srchInvoiceFromDate = null;
	this.srchInvoiceNumber = '';
	this.status = '';
  for(let actIdx in this.userAccountList){
    this.userAccountList[actIdx]["enabled"]=true;
  }
  this.getCfsInvoiceList(1, null);
	
 }
 clearMsg() {
  this.showMsg = false;
  this.msg = "";
}

stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }

}
