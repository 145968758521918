import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Util } from 'src/app/app.util';

@Component({
  selector: 'app-configurator-summary',
  templateUrl: './configurator-summary.component.html',
  styleUrls: ['./configurator-summary.component.css', '../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorSummaryComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  totalRecords = 0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any = [];
  myBuild = {} as any;
  configId: number = -1;
  quoteId: number;
  imgUrl = "https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any = [];
  productMaintenance;
  maintenanceAmt;
  showShippingBtn = false;
  showColorDetails = true;
  deviceDetails = {};

  //Get Id from cart
  listAccessory = [];
  listAccessoryType = [];
  localOptions = {};
  servicePlan = {} as any;
  selectedTermRange = {} as any;
  address = {} as any;
  scEmail = '';
  returns :string='';
  configLength: any;
  quoteStatusVal: any;
  quoteNumber: any;
  quoteDate: any;
  quoteUserEmail: any;
  attachments: any;
  configMap = {} as any;
  buildSummary = {} as any;
  successTextAlert: string;
  showSuccessAlert: boolean;
  quoteName: any;
  successReturnTextAlert: string;
  showReturnSuccessAlert: boolean;
  failureReturnTextAlert: string;
  showFailureReturnAlert: boolean;
  sOptionsArray: [string, unknown][];
  shipping = {} as any;
  fileError =  '';
  genericError =  '';
  fileDescError =  '';
  fileDesc ="";
  myFile: File | undefined;
  defaultUrl: string;
  fileSuccess ="";
  // Accessories in cart 
  constructor(private configquoteService: ConfiguratorQuotesService, private route: ActivatedRoute, private el: ElementRef, private renderer: Renderer2, private titleService: Title, private router: Router,private util : Util) { }

  ngOnInit() {
    this.defaultUrl = this.util.getBaseUrl();
    this.titleService.setTitle("Configurator Build Summary");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
    });
    this.getQuoteDetails(this.quoteId);
  }

  getQuoteDetails(quoteId) {
    this.blockUI.start();
    let oParams = { "quoteId": quoteId };
    var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams, 'getQuoteConfigDetails').subscribe(
      quoteDetailsPromise => {
        console.log('getquoteDetails', quoteDetailsPromise.data);
        let quoteDetails = JSON.parse(quoteDetailsPromise.data);
        this.configLength = quoteDetails.config.length;
        let quoteStatus = quoteDetails.quoteStatus;
        this.quoteStatusVal = quoteDetails.quoteStatus;
        this.quoteNumber = quoteDetails.quoteNumber;
        this.quoteDate = quoteDetails.quoteDate;
        this.quoteUserEmail = quoteDetails.quoteUserEmail;
        this.returns = quoteDetails.returns;
        this.attachments = quoteDetails.attachments;
        this.configMap = {};

        for (var c in quoteDetails.config) {
          this.configMap[quoteDetails.config[c].configId] = quoteDetails.config[c];

          this.configMap[quoteDetails.config[c].configId].imgSrc = this.imgUrl + quoteDetails.config[c].model + ".jpg";
         
          for (var s in quoteDetails.shipping) {
            console.log('Shipping:',quoteDetails.config[c].configId);            
            if (quoteDetails.shipping[s].configId == quoteDetails.config[c].configId) {
              console.log('Shipping:ConfigId',quoteDetails.shipping[s].configId);
              this.configMap[quoteDetails.config[c].configId].shipping = [] as any;
              this.configMap[quoteDetails.config[c].configId].shipping[0] = quoteDetails.shipping[s];
              console.log('Shipping:Shipping',quoteDetails.shipping[s]);
            }
          }

          this.configMap[quoteDetails.config[c].configId].options = {} as any;

          for (var o in quoteDetails.options) {
            //console.log('value:',quoteDetails.config[c].configId);
            if (quoteDetails.options[o].configId == quoteDetails.config[c].configId) {
              if (this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType]) {
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = quoteDetails.options[o];
              }
              else {
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = [];
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = quoteDetails.options[o];
              }
            }

          }


            // Initialize maintenance object
            this.configMap[quoteDetails.config[c].configId].maintenance = [] as any;
          for (var key in quoteDetails.maintenance) {
            if (quoteDetails.maintenance[key].configId == quoteDetails.config[c].configId) {             
              this.configMap[quoteDetails.config[c].configId].maintenance[key] = quoteDetails.maintenance[key];
            }
          }

        }
        console.log('this.configMap', this.configMap);
        //this.buildSummary = this.configMap;
        // this.buildSummary = {};

        this.myBuild = JSON.parse(sessionStorage.getItem('myBuild'));

        // this.buildSummary = this.configMap;
        this.buildSummary = Object.values(this.configMap);

        console.log('buildSummary', this.buildSummary);
        this.blockUI.stop();
      }
    );
  }
  getOptionTotal(configId) {
    var total = 0;
    for (var optionType in this.configMap[configId].options) {
      for (var i in this.configMap[configId].options[optionType]) {
        total = total + parseFloat((this.configMap[configId].options[optionType][i].price * this.configMap[configId].configQty).toString());
      }

    }
    total += parseFloat((this.configMap[configId].amount * this.configMap[configId].configQty).toString());
    return total;

  }
  getMaintenanceTotal = function (configId) {
    var total = 0;
    var totalMaint = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else {

        total = total + parseFloat(parseFloat(this.configMap[configId].maintenance[maint].baseRate) + (parseFloat(this.configMap[configId].maintenance[maint].avgVolUser) * parseFloat(this.configMap[configId].maintenance[maint].cpcRate)).toString());
        totalMaint = total * parseInt(this.configMap[configId].maintenance[maint].termUser);
      }

    }

    return totalMaint;
  }

  getBaseRate(configId) {
    var total = 0;
    var maintenance = this.configMap[configId].maintenance;
    if (maintenance != null && maintenance != 'undefined') {
      for (var maint in maintenance) {
        //console.log('maint',this.configMap[configId].maintenance[maint]);
        if (maintenance[maint].isRejected == undefined || maintenance[maint].isRejected == 'Y') {
          return total;
        } else {
          total = total + parseFloat(maintenance[maint].baseRate);
        }
      }
      return total;
    }
  }

  getColorClick(configId) {
    var total = 0;
    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else if (this.configMap[configId].maintenance[maint].meterType == "Color") {

        total = total + parseFloat(this.configMap[configId].maintenance[maint].cpcRate);
      }

    }

    return total;
  }

  isMaintenanceDeclined = function (configId) {
    var flag = true;
    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        flag = true;
      } else {
        flag = false;
      }
    }

    return flag;
  }

  getBwClick = function (configId) {
    var total = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else if (this.configMap[configId].maintenance[maint].meterType == "Black") {

        total = total + parseFloat(this.configMap[configId].maintenance[maint].cpcRate);
      }

    }

    return total;
  }

  getMaintenanceTerm = function (configId) {

    var term = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return term;
      } else {

        term = this.configMap[configId].maintenance[maint].termUser;
      }

    }

    return term + ' Months';
  }
  removeAccessory = function (configId, accessoryId) {
    for (var i in this.buildSummary[configId].listAccessoryTypeDetails) {
      for (let index in this.buildSummary[configId].listAccessoryTypeDetails[i].listAccessory) {
        if (this.buildSummary[configId].listAccessoryTypeDetails[i].listAccessory[index].itemId == accessoryId) {
          this.buildSummary[configId].listAccessoryTypeDetails[i].listAccessory[index].checked = false;
        }
      }
    }
    this.buildTotal();
  }
  removeFromCart = function (configId) {
    //var hostName = window.location.hostname;
    // var pathName = window.location.pathname;
    let removeFromCartPromise = this.configquoteService.removeConfig(configId).subscribe(
      removeFromCartPromise => {
        this.getQuoteDetails();
        console.log("Printing configLength: ",this.configLength );
        if (this.configLength <= 1) {
          this.router.navigate(["/createquote"]);

        }
        this.configLength = this.configLength -1;
        window.location.reload();
      },
      function (reason) {
        console.log(reason);
      });
    //getQuoteDetails();
  }
  saveQuote() {
    this.quoteName;
    this.configquoteService.setQuote(this.quoteName, this.quoteDate);
  }
  getQuoteName() {
    let oParams = { "quoteId": this.quoteId };
    let res = this.configquoteService.configQuoteGenericMtd(oParams, 'getQuoteName').subscribe(
      res => {
        this.quoteName = res.quoteName;
      },
      function (reason) {
        console.log(reason);
      });
  }
  saveQuoteName(quoteName,event) {
    if (event) {
      event.preventDefault();
    }
    this.successTextAlert = "Quote Saved";
    this.showSuccessAlert = false;
    //showPleaseWait();
    let oParams = { "quoteId": this.quoteId, "quoteName": this.quoteName, "action": 'email' };
    let res = this.configquoteService.configQuoteGenericMtd(oParams, 'saveQuoteName').subscribe(
      res => {
        this.quoteName = res.quoteName;
        this.showSuccessAlert = true;
      },
      function (reason) {
        console.log(reason);
      });
  }
  closeShowSuccessAlert = function (value) {
    this[value] = !this[value];
  }

  emailAndSubmitQuote = function (action, event) {
    if (event) {
      event.preventDefault();
    }
    this.blockUI.start();
    let scEmail = this.scEmail;
    this.isQuoteSubErrExist = false;
    this.emailErrorExists = false;
    this.emailSuccess = false;
    let oParams = { "quoteId": this.quoteId, "scEmail": this.scEmail, "action": action };
    let res = this.configquoteService.configQuoteGenericMtd(oParams, 'submitAndEmailQuote').subscribe(
      res => {
        let flag = res.flag;
        if (flag == "S") {

          if (action === "submit") {
            //window.location.href=pName+"?view=configBuildQuotes";
            this.quoteConfirmation(event);
          } else if (action == "email") {
            this.emailSuccess = true;
            this.emailSuccessMessage = res.data.successMessage;
          }
        } else {
          

          if (res.data.errorMessage && res.data.errorMessage.length > 0) {
            this.isQuoteSubErrExist = true;
            this.quoteSubErrMessageList = res.data.errorMessage;
          }

          if (res.data.emailErrorMessage && res.data.emailErrorMessage.length > 0) {
            this.emailErrorExists = true;
            this.emailErrMessageList = res.data.emailErrorMessage;
          }
        }
        this.blockUI.stop();
      }
      
      ,
      function (reason) {
        console.log(reason);
      });
    this.blockUI.stop();
  }
  quoteConfirmation(event) {
    if (event) {
      event.preventDefault();
    }
    let oParams = { "quoteId": this.quoteId };
    const navigationExtras: NavigationExtras = {
      queryParams: oParams

    };
    this.router.navigate(['/configconfirmation'], navigationExtras);
  }
  editBuildSummary(event:Event,configId) {
    if (event) {
      event.preventDefault();
    }
    let oParams = { "quoteId": this.quoteId,"configId":configId};
    const navigationExtras: NavigationExtras = {
      queryParams: oParams

    };
    this.router.navigate(['/buildquote'], navigationExtras);
  }
  openUploadModal(event:Event) {
    if (event) {
      event.preventDefault();
    }
  }
  isOptionExist(options) {
    var optionExist = false;

    if (options != null && options != 'undefined') {
      for (var option in options) {
        optionExist = true;
        break;
      }
    }
    return optionExist;
  }
  continue(event){
    if (event) {
      event.preventDefault();
    }
    let oParams = { "quoteId": this.quoteId};
    const navigationExtras: NavigationExtras = {
      queryParams: oParams

    };
    this.router.navigate(['/createquote'], navigationExtras);
  }
  openReturnsModal()
  {
    this.successReturnTextAlert = "";
    this.showReturnSuccessAlert = false;
    this.failureReturnTextAlert = "";
    this.showFailureReturnAlert = false;
     this.returns == ''
  }

  addReturns(returns,event) {
    if (event) {
      event.preventDefault();
    }
    if (returns == '') {
      return;
    }
    this.successReturnTextAlert = "Returns saved";
    this.showReturnSuccessAlert = false;
    this.failureReturnTextAlert = "Exception occured.";
    this.showFailureReturnAlert = false;
    //showPleaseWait();
    let oParams = { "quoteId": this.quoteId ,"returns": returns};
    let result = this.configquoteService.configQuoteGenericMtd(oParams, 'saveReturns').subscribe(
      result => {
        if (result.flag == 'S') {
          this.returns = returns;
          this.showReturnSuccessAlert = true;
        } else {
          this.showFailureReturnAlert = true;
        }

      },
      function (reason) {
        console.log(reason);
      });
  }
  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.myFile = file;
  }
  uploadDoc = function () {
    console.log("Inside uploadDOC" + this.quoteId);

    this.fileError = "";
    this.genericError = "";
    this.fileDescError = "";

    this.attachments = this.attachments;
    var data = {} as any;
    data.quoteId = this.quoteNumber;
    data.fileDesc = this.fileDesc;
    data.file = this.myFile;

    let result = this.configquoteService.uploadFileToUrl(data).subscribe(
      result => {
        var status = result.status;
        if (status === "S") {
          this.attachments = result.attachments;
          this.attachments = result.attachments;
          console.log("Success");
        } else {
          console.log("Error");
          this.fileError = result.fileError ? result.fileError : '';
          this.genericError = result.genericError ? result.genericError : '';
          this.fileDescError = result.fileDescError ? result.fileDescError : '';
        }
      },
      function (reason) {
        console.log(reason);
      });
    //buildConfigurationService.uploadFileToUrl(data, "");

  }
  downloadDoc = function (id,event) {
    if(event)
    {
      event.preventDefault();
    }
    console.log("Inside downloadDoc" + id);
    var url= this.defaultUrl + "configurator/downloadFile?id=" + id;
    window.open(url);
  }

  deleteDoc = function (id,event) {
    if(event)
    {
      event.preventDefault();
    }
    console.log("Inside deleteDoc" + this.quoteId);

    let deletePromise = this.configquoteService.deleteDoc(id, this.quoteId).subscribe(
      deletePromise => {
        var status = deletePromise.status;
        if (status === "S") {
         // this.attachments = deletePromise.data.attachments;
         // this.attachments = deletePromise.data.attachments;
          console.log("Success");
          this.fileSuccess ="Successfully deleted";
        } else {
          console.log("Error");
          this.fileError = deletePromise.data.fileError ? deletePromise.data.fileError : '';
          this.genericError = deletePromise.data.genericError ? deletePromise.data.genericError : '';
        }
      },
      function (reason) {
        console.log(reason);
      });
  }

}
