import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ManagePriceBookService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


getPriceListLOV(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "configuratorAdmin/getPriceListLOV?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getPriceListLOV")))
	  }	



getPriceBookList(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "configuratorAdmin/getPriceBookList?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getPriceListLOV")))
	  }	
    
getBundleDetails(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "configuratorAdmin/getBundleDetails?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getBundleDetails")))
	  }
    
upsertPricebookDetails(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "configuratorAdmin/upsertPriceBookDetails?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("upsertPricebookDetails")))
	  }	
    


}


