import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ConfigUsersService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


  getConfigUsers=function(partyId) {
    let url = this.defaultUrl + "configuratorAdmin/getConfigUsers?partyId="+ partyId;
    console.log();
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getConfigUsers"))));
    
  }


}
