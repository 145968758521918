import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderSuplliesListingService } from './order-supplies-listing.service';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
@Component({
  selector: 'app-order-supplies-listing',
  templateUrl: './order-supplies-listing.component.html',
  styleUrls: ['./order-supplies-listing.component.css']
})
export class OrderSuppliesListingComponent implements OnInit {
 @BlockUI() blockUI : NgBlockUI;
  itemList: any = [];
	supCatList = [];
	serNum: any = "";
	model: any = "";
	address: any = "";
	errorMsg = "";
	clrErrMsg = "";
	bwErrMsg = "";
	isCartEmpty = true;
	cartItemCount = 0;
	paasFlag = "N";
  contractId="";
  sectionId="";
  instanceId="";
  machSmSerial='N';
  itemInfoArray = [];
  itemInTheSession;
  supCatgArray = [];
  pleaseWaitCls:boolean = false;
  userId = sessionStorage.getItem('userName');
  contractNumber:any;
	items: any;
	autoToner: any;
	autoTonerPhaseFlag: any;
	displayTonerMsg = false;
	@ViewChild('tonerMsgModal')  tonerMsgTemplate:TemplateRef<any>;
	tonerMsgModalRef: BsModalRef	

    constructor( private commonService:CommonServiceService,private router: Router, private modalService: BsModalService,
      private activatedRoute: ActivatedRoute, private orderSuplliesListingService: OrderSuplliesListingService
  ) {
    console.log("Insinde Constructor..");
    console.log("Printing: " + this.activatedRoute);
     this.activatedRoute.queryParams.subscribe(params => {
      this.serNum = params['serial_number'];
      this.model = params['model'];
      this.address = params['address'];
      this.contractId= params['contractId'];
      this.sectionId = params['sectionId'];
       this.instanceId = params['instanceId'];
	   this.contractNumber = params['contractNum'];
      


    });

   }

  ngOnInit() {
	this.commonService.setCommonFunctions(); // Add in  ngOnInit  in your Component.ts
	this.commonService.sortView();
	console.log("Printing userId in listing page: "+ this.userId + " & session Storage value: "+ sessionStorage.getItem("userName"));
    console.log("Inside ngonIt");
     this.getdata();
    this.itemInTheSession = localStorage.getItem(this.userId + '__ItemDetail');
     if( this.itemInTheSession !== null && this.itemInTheSession !== undefined && this.itemInTheSession !== "" ){
     this.itemInfoArray = JSON.parse( this.itemInTheSession );
       }
console.log("printing length: "+ this.itemInfoArray.length);

   if( this.itemInfoArray.length > 0 ){
     this.isCartEmpty = false;
     this.cartItemCount = this.itemInfoArray.length;
    }
	 
  }

  	//$scope.onPrint = function(){
		//window.print();

  onPrint(){
	window.print();
}

  getdata(){
	this.blockUI.start();
	console.log("Pritning getData for Please wait testing..True..");
	
    var adPromise = this.orderSuplliesListingService.getItemList(this.serNum, this.contractId, this.sectionId, this.instanceId, this.model).subscribe(
      data=>{
		//this.blockUI.stop();
		console.log("Pritning getData for Please wait testing..False..");
		
              this.itemList = data.data;
              console.log(this.itemList);
               this.autoToner = data.autoToner;	
              this.autoTonerPhaseFlag = data.autoTonerPhaseFlag;
              this.supCatList = data.supCatg;
              this.paasFlag = data.paasFlag;

			  for (let item of this.itemList){					
				if(item.qtyList.length > 0 && !item.addedInCart){
					item.qty = item.qtyList[0];
					item.addedInCart = false;
				     }
                   }this

		// Toner Flag Changes
		var tonerFlgNCnt=0;
		var dispQty:any=0; //Using this for calculating limit Qty for SupCat 02 ,03 , 04
		for (let item of this.itemList){					

			if(item.qtyList.length > 0 && !item.addedInCart){
				item.qty = item.qtyList[0];
				item.addedInCart = false;
			}

			if(item.supCatg=='01'||item.supCatg=='02'||item.supCatg=='03'||item.supCatg=='04'){
			
				if(item.supCatg!='01'){
					dispQty=item.dispQty;
				}
				
				if(item.tonerFlg=='N'){
					this.displayTonerMsg = true;
					if(item.supCatg!='01'){
						tonerFlgNCnt++;
					}
	    			item.qtyList =[0];
					item.addedInCart = true;

				}
			}
		}

if(tonerFlgNCnt>0){ 
	var limitQty:any= (3-tonerFlgNCnt) * ((dispQty/3)); 
   
   for (let item of this.itemList){
	   
	   
	   if(item.supCatg=='02'||item.supCatg=='03'||item.supCatg=='04'){
		   item.dispQty=limitQty;
		   //console.log("New dispQty : "+limitQty);
		   if(item.tonerFlg=='Y'){
			   var arrTmp=[];
				 for(let k of item.qtyList){
					 if(k<=limitQty){
					   arrTmp.push(k);
					}
			   }
				  item.qtyList=arrTmp;
		   }
	   }
   }
							   
}

// End Toner Flag Changes
						 
if( this.itemInfoArray.length > 0 ){
	for (let storageItem of this.itemInfoArray){
		for (let item of this.itemList){
			if( storageItem.segment1 == item.segment1 && storageItem.serialNumber == item.serialNumber ){
				item.qty =storageItem.qty;
				item.addedInCart = true;
				this.machSmSerial = "Y";
			}
		}
	}
}

for (let i of  this.supCatList){
	this.supCatgArray.push(i.supCatg);
}

 if( this.autoToner > 0 ){	
	 if(this.autoTonerPhaseFlag == 'Y'){
		this.errorMsg = "Your machine has been identified as eligible for auto toner replenishment, please contact 1-800-613-2228 for more information";
	 }else{
		this.errorMsg = "The serial selected is set up to automatically ship toner when low. Please call our Customer Service department at 1-800-613-2228 for assistance.";
	 }
	 
	 for (let item of this.itemList){
			if(this.supCatgArray != undefined && this.supCatgArray.length >0 && this.supCatgArray.indexOf(item.supCatg) !== -1){
				item.hideQty = true;
			}else{
				item.hideQty = false;
			}
		}
 }
 //hidePleaseWait();
 this.blockUI.stop();

 if(this.displayTonerMsg){
	this.tonerMsgModalRef = this.modalService.show(this.tonerMsgTemplate);
 }




}


)

  }
	fnAddToCart(){
		console.log("Priting inside add to cart function");
		var submitFlag = 'Y';
		var qtyTr="";
		var dispClrTonerVal = '';
		var selectedArray = [];
		var clrMsg="";
		var bwMsg="";
		var passMsg="";
		var dispBwTonerVal = '';
		this.clrErrMsg = "";
		this.bwErrMsg = "";
		
		this.itemList.forEach((value:any) =>{
			var clrTonerSelQty=0;
			var bwTonerSelQty: any;
			var itemToAdd = value; 
			if(!itemToAdd.addedInCart && itemToAdd.qty > 0){
				this.itemList.forEach((value1:any) =>{
					var item = value1; 
					
					if(this.paasFlag == "Y"){
						if((item.supCatg=='01' || item.supCatg=='02' || item.supCatg=='03' || item.supCatg=='04') && item.minQtyFlag != "Y"){
							var clrTonerVal = item.dispQty;
							clrTonerSelQty = (clrTonerSelQty)+(item.qty);
							if((clrTonerSelQty)>(clrTonerVal)){
								passMsg = "The quantity available for selection is the Total CAP Remaining for toner and maintenance kit. "+
								"For toners (B/C/M/Y) , total quantity for all toners per serial cannot exceed total toner CAP remaining.";
								submitFlag = "N";
								return false;
							}
						}
					}else{
						if((item.supCatg=='02' || item.supCatg=='03' || item.supCatg=='04') && item.minQtyFlag != "Y"){
							var clrTonerVal = item.dispQty;
							console.log("Printing tonner values before:");
							console.log(clrTonerSelQty);
							console.log(typeof item.qty);
							clrTonerSelQty = (clrTonerSelQty)+(item.qty);
							console.log("Printing tonner values");
							console.log(clrTonerSelQty);
							console.log(clrTonerVal);
							console.log(item.qty);
							if((clrTonerSelQty)>(clrTonerVal)){
								clrMsg = item.clrCtrMsg;
								dispClrTonerVal =clrTonerVal;
								submitFlag = "N";
								return false;
							}
						}else if(item.supCatg=='01' && item.minQtyFlag != "Y"){
							var bwTonerVal = item.dispQty;
							bwTonerSelQty = (bwTonerSelQty)+(item.qty);
							if((bwTonerSelQty)>(bwTonerVal)){
								bwMsg = item.bwCtrMsg;
								dispBwTonerVal =bwTonerVal;
								console.log('view msg : '+bwMsg);
								submitFlag = "N";
								return false;
							}
							
						}
					}				
				});
				
				selectedArray.push( itemToAdd );
			}
		});
		console.log("Printing submitted flag: "+submitFlag );

		if(submitFlag=='Y'){

			if( this.itemInfoArray.length > 0 ){
				selectedArray = selectedArray.concat( this.itemInfoArray );
			}
			
			if( selectedArray.length !== 0 ){
				var s = JSON.stringify( selectedArray );
        localStorage.setItem(this.userId + '__ItemDetail', s);
				if(this.machSmSerial =='N'){
		
					var model =  this.model;
					var instanceId = this.instanceId;
					var contractNumber = this.contractNumber;
					var contractId = this.contractId;
					var serialNumber = this.serNum;
					var info =  model+'|'+instanceId+'|'+contractNumber+'|'+contractId+'|'+serialNumber;
					var machDataSet = localStorage.getItem(this.userId + '__MachDetail');
					if( machDataSet !== null && machDataSet !== undefined && machDataSet !== "" ){
						console.log('machDataSet : '+ machDataSet);
						if( machDataSet.length > 0 ){
							info = info+"*"+machDataSet;
							console.log('info : '+ info);
						}
					}
					localStorage.setItem(this.userId + '__MachDetail', info);
				}
				this.router.navigate(['ordersuppliesviewcart']);
			}
		}else{

			console.log("Printing error msg: "+ clrMsg + ' '+ dispClrTonerVal);
			if((clrMsg).trim().length>0){
				this.clrErrMsg = clrMsg+' '+dispClrTonerVal;
			}else if((bwMsg).trim().length>0){
				this.bwErrMsg = bwMsg+' '+dispBwTonerVal;
			}else if((passMsg).trim().length>0){
				this.clrErrMsg = passMsg;
			}
		}
	}




  //JavaScripts Below HTML code in canonCpOrderSuppMtrReads.jsp file.
// itemInTheSession = getFromStorage(userId + '__ItemDetail');
// if( itemInTheSession !== null && itemInTheSession !== undefined && itemInTheSession !== "" ){
//   itemInfoArray = JSON.parse( itemInTheSession );
// }

// if( itemInfoArray.length > 0 ){
//   $scope.isCartEmpty = false;
//   $scope.cartItemCount = itemInfoArray.length;
// }

stopVideo(id: string){
	console.log('Printing id value: ', id);
	const video = document.getElementById(id) as HTMLVideoElement;
	if(video){
	 video.pause();
	 //video.load();
	}
   }

closeModal(){
	this.tonerMsgModalRef.hide();
}   

}



