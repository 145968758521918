import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { AccountDevicesService } from './account-devices.service';
import { Util } from 'src/app/app.util';
import { Router ,NavigationExtras} from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AutoTonerUploadDownloadModalComponent } from 'src/app/common/auto-toner-upload-download-modal/auto-toner-upload-download-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-account-devices',
  templateUrl: './account-devices.component.html',
  styleUrls: ['./account-devices.component.css']
})
export class AccountDevicesComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  acctDeviceList=[];
  userAccountList=[];
  instDtDurationObj:any=[];
  assignedTos:any=[];
  ttInfo:string="";
  assignedTo:string="";
  serialNumber:string="";
  model:string="";
  location:string="";
  coverageType:string="C";
  duration:string="";
  pageNum:any=0;
  totalRecords:any=0;
  numOfRecsMsg:string="";
  recPerPage:any=10;
  arrPgLinks:any=[]; 
  selectedUserAccts:string="";
  atAccess : string = 'N';
 
  constructor(private commonService:CommonServiceService,private accountDevicesService:AccountDevicesService, private util : Util, private router : Router, private modalService: NgbModal,private titleService: Title) { }
 
  ngOnInit() {
    this.titleService.setTitle("Account Devices"); 
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });
      this.getInitialData();
      this.getAccountDevicesList(1,null);
      this.commonService.setCommonFunctions();
        
  }

  
  getAccountDevicesList(pgNum: any,event: any){
    if(event!=null){
      event.preventDefault();
    }
    this.blockUI.start();
    this.pageNum=pgNum;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("pageNum :"+pgNum);
    let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, "serialNumber":this.serialNumber,
    "coverageType":this.coverageType,"model":this.model,"location":this.location,
    "duration":this.duration, "assignedTo":this.assignedTo, "selectedUserAccts":this.selectedUserAccts };
    console.log("recPerPage :"+this.recPerPage);let data = this.accountDevicesService.getAccountDevicesList(oParams).subscribe(
     data=>{

         this.acctDeviceList = data.adData; 
         this.totalRecords=data.totalItems;
         this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
         this.blockUI.stop();
        
     });
 
 }


  getInitialData(){
    
    let data = this.accountDevicesService.getInitialData().subscribe(
     data=>{
       console.log(data);
       this.instDtDurationObj=data["installDateDuration"];
       this.assignedTos=data["assignedTos"];
	   this.assignedTo="loadAllMachines";	
       this.ttInfo=data["ttInfo"];
       this.atAccess = data["atAccess"];
     });
 
 }

 clearSearch(event:any){
  if(event){
    event.preventDefault();
  }
  this.serialNumber="";
  this.model="";
  this.location="";
  this.coverageType="C";
  this.assignedTo="loadAllMachines";
  this.duration="";
  for(let actIdx in this.userAccountList){
    this.userAccountList[actIdx]["enabled"]=true;
  }
  this.getAccountDevicesList(1,null);
}


exportToExcel(){
  let url="accountdevices/exportData";
  this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
  let qryStr="serialNumber="+this.serialNumber+"&model="+this.model+"&location="+
  this.location+"&duration="+this.duration+"&assignedTo="+this.assignedTo+
  "&coverageType="+this.coverageType+"&selectedUserAccts="+this.selectedUserAccts ;
  url=url+"?"+qryStr;
  this.commonService.exportToExcel(url);
}

getRequestdevices(){
  this.router.navigate(["requestdevices"]);
  
}

  requestAT(){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    
    this.modalService.open(AutoTonerUploadDownloadModalComponent, ngbModalOptions);
  }

  displayAccDtls(param,event)
  {
    param['fromPage']='accountDevices';
    const navigationExtras: NavigationExtras = {
      queryParams: param
    };
   if(event){
     event.preventDefault();
   }
   this.router.navigate(['/devicedetails'],navigationExtras);
    
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }


}
