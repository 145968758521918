import { Component, OnInit } from '@angular/core';
import { EnterLookupsService } from './enter-lookups.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Component({
  selector: 'app-enter-lookups',
  templateUrl: './enter-lookups.component.html',
  styleUrls: ['./enter-lookups.component.css']
})
export class EnterLookupsComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  orderCol: any;
  orderDir: any;
  view: any='lookupValues';
  lookupName:any="";
  count:any=0;
  lookupserachList:any=[];
  recPerPage=10;
  pageNum:any=1;
  numOfRecsMsg:any="";
  arrPgLinks:any=[];

  constructor(private enterLookupsService:EnterLookupsService, private commonService:CommonServiceService,private router: Router ) { }

  ngOnInit() {
    this.lookupSearch();
  }

  lookupSearch() {
    this.blockUI.start();
  var param = {
        "view"  : this.view,
        "orderCol" : this.orderCol,
        "orderDir" : this.orderDir
      };
  
  var reqBody ={
    "lookupName" : this.lookupName,
    "viewName":this.view
  }   
   
    this.enterLookupsService.getlookupSearch(param,reqBody).subscribe(
      data=>{
        console.log(data);
        this.lookupserachList=data.Views;
        this.count= data.count;
        console.log("Printing record per page: ", this.recPerPage);
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.count,this.pageNum,this.recPerPage);
        this.blockUI.stop();
  
      }
    );
  
    
  
  }
  
  
  clearSearch(){
    this.lookupName="";
    this.pageNum=1;
    this.lookupSearch();
  
  }

  lookupDefinition(lookupId){
    console.log("Printing lookup Id:" +lookupId);
    this.router.navigate(['/maintainlookupvalue'], { queryParams: { lookupId: lookupId} });
    
    }

}
