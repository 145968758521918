import { Component, OnInit } from '@angular/core';
import { RemoteSupportService } from './remotesupport.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-remotesupport',
  templateUrl: './remotesupport.component.html',
  styleUrls: ['./remotesupport.component.css']
})
export class RemotesupportComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  rmtList:any=[];
  totalItems:any=0;
  pageSize:any=10;
  firstItem:any=0;
  lastItem:any=0;
  numPages:any=0;
  curPage:any=1;
  asc_desc_ordr:any="";
  sortColumn:any="";
  ref2:any="";
  process: string;
  serNum: any;
  ref1: any;
  srchModelNum:any="";
  modelList:any=[];
  srchInstLocn:any="";
  srhSerialNum:any="";
  srchAlias:any="";
  startPage:any=0;
  endPage:any=0;
  pageNumberArray:any=[];
  evenRntList:any=[];
  oddRntList:any=[];

  constructor(private remoteSupportService:RemoteSupportService) { }

  ngOnInit() {
    this.blockUI.start();
    var input:any = {};
    input.srchInstLocn = this.srchInstLocn;
    input.srhSerialNum = this.srhSerialNum;
    input.srchModelNum = this.srchModelNum;
    input.srchAlias = this.srchAlias;  
    input.pageNum= this.curPage;
    input.pageSize=this.pageSize;
    input.sortColumn=this.sortColumn;
    input.ASC_DESC_ORDR= this.asc_desc_ordr;
    input.process = this.process;
this.remoteSupportService.getdefaultreqhandler(input).subscribe(

  data=>{
   this.rmtList=data.rmtList;
   this.totalItems=data.totalItems;
   this.pageSize=data.pageSize;
   this.firstItem=data.firstItem;
   this.lastItem=data.lastItem;
   this.numPages=data.numPages;
   this.curPage=data.curPage;
   this.asc_desc_ordr=data.asc_desc_ordr;
   this.sortColumn=data.sortColumn;
   this.modelList=data.modelList;
   this.srchModelNum=data.srchModelNum;
   this.srhSerialNum=data.srhSerialNum;
   this.srchInstLocn=data.srchInstLocn;
   this.srchAlias=data.srchAlias;

    this.startPage = 0;
    this.endPage = 0;
   
   if ((this.curPage%10) == 0) {
     this.startPage = (this.curPage-1)/10*10+1;
   } else {
    var temp = Math.floor(this.curPage/10);
     this.startPage = temp*10+1;
   }

   if ((this.startPage+9) < this.numPages) {
     this.endPage = this.startPage+9;
   } else {
     this.endPage = this.numPages;
   }
   for(let i = this.startPage; i <= this.endPage; i++){
    this.pageNumberArray.push(i);

   }
   console.log("Printing current page");
   console.log(this.curPage);
   console.log(this.startPage);
   console.log(this.endPage);
   console.log(this.pageNumberArray);

   if(this.rmtList.length>0){
    var b=parseInt(this.firstItem.toString());
    var l=parseInt(this.lastItem.toString());
    for(let i = b-1; i <l; i++) {
      if(i%2==0){
        this.evenRntList.push(this.rmtList[i]);
      }
      else {
        this.oddRntList.push(this.rmtList[i]);
      }

    }

   }
   this.blockUI.stop();
  });

  }

   getNextPage(pageNum) {
    this.pageNumberArray=[];
   this.curPage= pageNum;
   this.evenRntList=[];
   this.oddRntList=[];
  this.ngOnInit();
   // return this.
  }
  
 // $("#srchBtn").click
  searchButton(){
    this.pageNumberArray=[];
    this.curPage=1;
    this.evenRntList=[];
    this.oddRntList=[];
   this.ngOnInit();
   
  }

  validateIPaddress(ipaddress) {
	  if(ipaddress=="")
		  {
		  console.log("validate true Ip null");
		  return (true) ; 
		  }
	  else if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) 
	  {  
		 console.log("validate true");
	    return (true) ; 
	  } 
	  console.log("validate false");
	  alert("You have entered an invalid IP address!");  
	  return (false) ; 
	} 

  changeCase(e){
    var s="";
    s=$(e).val();
    $(e).val(s.toUpperCase());
  }


  doSortColumn(sortCol){
    //var form = document.srchFrm;
    //form.sortColumn.value = sortCol;
    var ASC_DESC_ORDR = (this.asc_desc_ordr =="A") ? "D" : "A";

      return ASC_DESC_ORDR;
  }

 
   doSubmit(mode,serNum,me) { 
    var alertMessage="";
    
    if (mode=="")
    {
      //var form = document.srchFrm;
      //form.ref1.value = $("#ref1_"+serNum).val();
      //form.ref2.value = $("#ref2_"+serNum).val();
      if(this.ref2=="")
        {
        alert("Please Enter Ip Address and click on the Launch button to save.");
        }
      else{
         var justLaunchMe="http://"+this.ref2;
       window.open(justLaunchMe, 'Supplies','scrollbars,width=1801,height=800,left=100,top=100');
      }
       return;
    }
     if (mode=="UPDATE")
    {
    if (me.value == me.defaultValue){
            return;
    }
    alertMessage="Do you want to update?";
      this.process="UPDATE";
    }
 
   // var form = document.srchFrm;
    if(serNum != null)
    {
    var sNumber = this.serNum;
    var ref1 = this.ref1;
    var ref2 = this.ref2;
   

   if(this.validateIPaddress(ref2))
     {
             if (mode=="UPDATE") {

            this.remoteSupportService.postUpdatelaunch(this.serNum, this.process, this.ref1, this.ref2).subscribe(
              data=>{
                if ((data) == "V") {
                  $('#counterMsgErR')
                      .html(
                          "Please enter valid IP address and try again");
               }
               else if ((data) == "E") {
                  $('#counterMsgErR')
                      .html(
                          "IP address already exists;please use valid IP address and try again");
               }else if ((data) == "U") {
                  $('#counterMsgSuR')
                  .html(
                      "Record Updated successfully.");
                }
                else if ((data) == "I") {
                  $('#counterMsgSuR')
                  .html(
                      "Request submitted successfully.");
                }
                else {
                  $('#counterMsgSuR')
                  .html(
                      "Request submitted successfully.");
                }

              }
            );  
     
          }
      }
        else{
          
          return;
        }
    }
 }



	//$('a.serialNum').on('click', 
  serialNum(e){

		e.preventDefault();
		var obj = {};
		var $tr = $(this).parents('tr');
		var address = $tr.find('td')[4].innerHTML;
		var model = $tr.find('td')[3].innerHTML;
		var serial = $tr.find('td')[2].innerHTML;
		// obj.serialNumber = serial;
		// obj.model = model;
		// obj.machineAddress = address;
		// var s = JSON.stringify(obj);
		// sessionStorage.setItem('location', s);
		
		// link = $(this)[0].href;
		
		// window.location.href = link;
	}

  

}
