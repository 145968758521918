import { Component, OnInit } from '@angular/core';
import { ContractSuppliesService } from 'src/app/product-supplies/contract-supplies.service';
import { saveAs } from 'file-saver';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-auto-toner-upload-download',
  templateUrl: './auto-toner-upload-download.component.html',
  styleUrls: ['./auto-toner-upload-download.component.css']
})
export class AutoTonerUploadDownloadComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  selectedFiles: FileList;
  currentFile: File;
  status: string;
  displayreviewresults: any;
  genericError : string = '';

  constructor(private csService: ContractSuppliesService) { }

  ngOnInit() {
  }

  downloadAT(){
    this.blockUI.start();
    this.csService.downloadAutoToner().subscribe(
      response  => {
        console.log("done.....");
        //window.open(response);
        /* let blob:any = new Blob([response], { type: 'application/xlsx; charset=utf-8' });
			  const url= window.URL.createObjectURL(blob);
			  window.open(url);  */     

        /* const blob = new Blob([response.body], { type: response.headers.get('content-type') });
        let fileName = '';
        fileName = fileName || response.headers.get('content-disposition').split(';')[0];
        const file = new File([blob], fileName, { type: response.headers.get('content-type') });
        saveAs(file); */

        const blob = new Blob([response], { type: 'application/xlsx; charset=utf-8' });
        let fileName = 'AutoTonerDetails.xlsx';
        //const file = new File([blob], fileName, { type: 'application/xlsx' });
        //saveAs(file, 'test.xlsx');
        saveAs(blob, fileName);
        this.blockUI.stop();
      }

    )
  }


  reviewResults(){
    this.blockUI.start()
    this.csService.reviewResults().subscribe(
      response  => {
        const blob = new Blob([response], { type: 'application/xlsx; charset=utf-8' });
        let fileName = 'AutoTonerErrorDetails.xlsx';
        //const file = new File([blob], fileName, { type: 'application/xlsx' });
        //saveAs(file, 'test.xlsx');
        saveAs(blob, fileName);
        this.blockUI.stop();
      }

    )
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  upload() {
    this.blockUI.start();

    if(this.selectedFiles){
      this.currentFile = this.selectedFiles.item(0);
      this.csService.uploadAutoTonerDetails(this.currentFile).subscribe(
        event => {
          console.log(event);
          this.displayreviewresults = event.displayreviewresults;
          this.status = event.status;
          this.blockUI.stop();
        /*  if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.message = event.body.message;
            this.fileInfos = this.uploadService.getFiles();
          } */
        },
        err => {
          /* this.progress = 0;
          this.message = 'Could not upload the file!';
          this.currentFile = undefined; */
        });

      //this.selectedFiles = undefined;
      }else{
        this.genericError = 'Please select a file.';
        this.status = 'E';
        this.blockUI.stop();
      }
    
    }

  
}


