import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
    }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
    headers: new HttpHeaders({

    }).set('Content-Type', 'application/json')
};

@Injectable({
    providedIn: 'root'
})

export class MeterHistoryService {
    defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }


    getMeterReadsHistory = (paramObj) => {
        let url = this.defaultUrl + 'meterReadHistory/getHist?';
        let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;

        return this.http
            .post(url, qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

    }

    getSrlInvReadDetails = (paramObj) => {

        let url = this.defaultUrl + 'meterReadHistory/gird?';
        let params = new URLSearchParams();
        params.set("srlNum", paramObj)
        let qryStr = params.toString();
        url = url + qryStr;
        return this.http
            .post(url, qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));

    }


    getModelList = function () {
        let url = this.defaultUrl + "meterReadHistory/getdropDownMeterHist";
        let params = {};
        return this.http
            .post(url, params.toString, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
    }


    createServiceTicket = (paramObj) => {
        let url = this.defaultUrl + 'meterReadHistory/cerct?';
        let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;

        return this.http
            .post(url, qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
       
    }

    gsnld = (paramObj) => {
        let url = this.defaultUrl + 'meterReadHistory/gsnld?';
        let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        return this.http
            .post(url, qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
    }

    downloadData = (paramObj) => {
        let url = this.defaultUrl + 'meterReadHistory/mhde?';
        let params = new URLSearchParams();
        for (let key in paramObj) {
            params.set(key, paramObj[key])
        }
        let qryStr = params.toString();
        url = url + qryStr;
        return this.http
            .post(url, qryStr, httpOptions)
            .pipe(catchError((this.util.handleError("getMeterReadsHistory"))));
       
    }


	fullExcelDownload = () => {
        let url = this.defaultUrl + 'meterReadHistory/fullExportToExcel';
        url = url ;
        return this.http
            .post(url, null, httpOptions)
            .pipe(catchError((this.util.handleError("fullExcelDownload"))));
       
    }

}