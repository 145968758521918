import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CreateSrService } from './create-sr.service';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';
//import * as $ from 'jquery';
import { TceppsCreditCardSevice } from 'src/app/service/common-service/tcepps-service.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
//declare var creditCard:any; 
import "jquery";
import { UpperCasePipe } from '@angular/common';

declare var $: JQueryStatic;
declare var jQuery: JQueryStatic; 

 

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.css']
})
export class CreateServiceComponent {
  @BlockUI() blockUI : NgBlockUI;
  instanceId: any ="";
  serialNum: any ="";
  externalRef: any ="";
  model: any ="";
  installAddress: any ="";
  source: any ="";
  serviceData: any;
  page: string;
  serialNumber: any="";
  contactName: any ="";
  workAreaCode: any="";
  workNumber: any="";
  workExtension: any="";
  conRoom: any="";
  requestorWorkAreaCode: any="";
  requestorWorkNumbervalue: any="";
  requestorWorkExtension: any="";
  poNumber: any;
  emailAddress: any="";
  mobileNumber: any="";
  hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
	hourTypes = ['AM', 'PM'];
  locationList  =[];
	locationListCopy = [];
	problemSummaryList :any=[];
	problemDescList :any=[];
	problemCodeList :any=[];
	callTypes = [];
	selectedCallType: any;
	selectedOpenHr = '8';
	selectedOpenHrType = 'AM';
	selectedCloseHr = '5';
	selectedCloseHrType = 'PM';
	selectedOtherInstallLoc = {} as any;
	srResponseData = {}as any;
	//createForm:  any;
  diffrentLocation ="";
  selectedProbSummary: string = ''
  //selectedProbSummary ="";
  selectedProbDesc: string = ''
  billToLocationList: any[] = [];
  selectedOtherBillLoc:string = '';
  otherBillToLocationAddress: any;
  p: number = 1;
  paymentType: string;
  creditCardNum:String ="";
  ccErrorMessage: string;
  userId: any;
  atHomeServiceFlag: any;
  speInst: any;
  ccExpDate:String ="";
  diffrentBillLocation: string;
  profileId: any;
  selectedProbCode: string = ''
  ccCardType:String ="";
  requestorWorkNumber: any;
  submitted = false;
  pageNum: number = 1;
  recPerPage = 10;
  recordCount;
  noOfPages;
  searchText: string;
  searchFilterPropertyNames : string[] = ['address'];
  rWorkAreaCodeHiddenValue: boolean = true;
  rWorkNumberHiddenValue: boolean= true;
  otherInstallLocNumberHiddenValue: boolean= true;
  otherBillLocNumberHiddenValue: boolean= true;
  poNumNumberHiddenValue: boolean= true;
  contactNameHiddenValue: boolean= true;
  emailAddressHiddenValue: boolean= true;
  workAreaCodeHiddenValue: boolean= true;
  workAreaCodeMinHiddenValue: boolean= true;
  workNumberHiddenValue: boolean= true;
  conRoomHiddenValue: boolean= true;
  pbmSummaryHiddenValue: boolean= true;
  pbmDescHiddenValue: boolean= true;
  pbmCodeHiddenValue: boolean= true;
  diffrentLocHiddenValue: boolean= true;
  selAgreeHiddenValue: boolean =true;
  otherBillLocHiddenValue: boolean=true;
  displayAfterHoursModal = 'false';
  displayHomeModal = 'false';
  displayAfterHoursStyle = 'none';
  displayHomeStyle = 'none';
  @ViewChild('installAddrModal') installTemplate:TemplateRef<any>;
  showNoAddrMsg : boolean = false;
  installAddrModalRef: BsModalRef
  showLoader: boolean = false;
  selectedDescription: any;
  selectedLookupCode: any;
  selectedProbLookupCode: any;
  msg: string;
  machineStatusFlag: string = 'Y';
  @ViewChild('afterHoursModal') afterHoursTemplate:TemplateRef<any>;
  afterHoursModalRef: BsModalRef
  @ViewChild('atHomeModal')  atHomeTemplate:TemplateRef<any>;
  atHomeModalRef: BsModalRef
  @ViewChild('enterCCModal')  enterCCTemplate:TemplateRef<any>;
  enterCCModalRef: BsModalRef

  private _customerInfo: { customerName: any; customerAddress1: any; customerAddress2: string; customerCity: any; customerState: any; customerZIP: any; customerEmail: string; customerPhone: string; customerCountryCode: string; top: string; paymentType:string};
  createForm: any;
  selAgree: string;
  workNumberS21HiddenValue: boolean=true;
  defaultUrl: string;
  heatServReq: string;
  nonEntitledBilledFlag: any;
  
  
  constructor(private createService:CreateSrService, private util : Util,private route : ActivatedRoute, private modalService: BsModalService,private titleService: Title, private tceppsCreditCardSevice: TceppsCreditCardSevice,private commonServiceService:CommonServiceService, private router: Router, private upperCasePipe : UpperCasePipe) { 
    this.defaultUrl = this.util.getBaseUrl();
  }

  ngOnInit( ) {
    this.titleService.setTitle("Create Service Request");
    this.route.queryParams.subscribe(
      params => {
        this.serialNum =params['serialNumber'];
        this.instanceId = params['instanceId'];
        this.externalRef = params['equipmentNumber'];
        this.model = params['model'];
        this.installAddress = params['installAddress'];
        this.source = params['source'];
       
      }
    )
   
    this.getCreateSrInfo();

    // this.tceppsCreditCardSevice.testingFun();
     this.commonServiceService.setCommonFunctions();
   }

    // convenience getter for easy access to form fields
   
    onSubmit()
    {
      alert("No error onsubmit");
    }
   
   getCreateSrInfo(){
   
    this.blockUI.start();
   let paramObj = {
      instance_id: this.instanceId,
      serial_number:this.serialNum,
      external_reference: this.externalRef,
      model: this.model,
      location: this.installAddress,
      source: this.source,
    };
    let dataSubscription  = this.createService.getCreateSrInfo(paramObj).subscribe(
      data=>{
        this.page ="create";        
        console.log("data:" + data);
        
        if (data !== undefined) {
          this.serviceData = data;

          console.log("this.serviceData.checkAHS:" + this.serviceData.checkAHS); 
        console.log("this.serviceData.source:" + this.serviceData.source);
       
         console.log("this.serviceData:"+this.serviceData);
         console.log("this.serviceData.csrData:"+this.serviceData.csrData.serialNumber);
         console.log("this.serviceData.csrData.canonBuLocationText:"+this.serviceData.csrData.canonBuLocationText);
         this.blockUI.stop();
         this.serviceData.pmtFlg = '';  
         this.serialNumber =this.serviceData.csrData.serialNumber;
         this.contactName = this.serviceData.csrData.defaultPartyName;
         this.workAreaCode = this.serviceData.workAreaCode;
         this.workNumber = this.serviceData.workNumber;
         this.workExtension = this.serviceData.workExtension;
         this.conRoom = this.serviceData.conRoom; 
         this.requestorWorkAreaCode = this.serviceData.requestorWorkAreaCode;
         this.requestorWorkNumbervalue = this.serviceData.requestorWorkNumber;
         this.requestorWorkExtension = this.serviceData.requestorWorkExtension;
         this.poNumber = this.serviceData.csrData.poNum;
         this.emailAddress = this.serviceData.csrData.userName;
         this.mobileNumber = this.serviceData.csrData.mobileNum;
         this.conRoom = this.serviceData.conRoom;
         this.heatServReq =data.heatServReq;
         console.log("this.heatServReq:"+this.heatServReq);
				 this.getProblemSummary();	
         //If amazon flag is Y then get details for call types
					if(data.source === 'S21' && data.csrData.amznCustFlag == 'Y' && data.checkAHS != 'Y'){
						this.getAmazonCallTypes(this.serviceData.csrData.ahsCdEnblFlg);
						//this.amazonCallTypeDetails();
					}
					
					//If bill to location details
					if(data.heatServReq != 'Y' && this.serviceData.csrData.canonBillToPartySiteId != -1){
						this.getBillToLocations();
					}
					
					//check if it is after hours
					if(data.checkAHS === 'Y' && this.serviceData.csrData.amznCustFlag != 'Y' && data.source === 'S21'){
						this.showAfterHoursModal(this.serviceData);	
					}				
         else if (this.serviceData.checkAHS === 'Y' && this.serviceData.csrData.amznCustFlag == 'Y' && this.serviceData.source === 'S21') {
            const paramObj = {
                serial_number: this.serialNum,
                model: this.model,
                source: 'AHS',
                mthd: 'gctp'
            };
   
            let data = this.createService.getAHSCallType(paramObj).subscribe(
              result=>{
                    if (result != null) {        
                          this.selectedCallType = {
                            callTypeId: data.callTypeId,
                            callType: data.callType
                          };
                        
        
                        if (this.serviceData.source === 'S21' && this.serviceData.csrData.amznCustFlag == 'Y') {
                            this.getAmazonCallTypes('Y');
                        }
                    }
                },
                (error) => {
                    console.error(error);
                    this.showLoader = false;
                }
            );
        }else if (this.serviceData.checkAHS === 'Y' && this.serviceData.source === 'Oracle'){
          this.showAfterHoursModal(this.serviceData);	
        }
        
      }
      (error) => {
        console.error(error);
        this.showLoader = false;
    }
      });

      
  }

  getBillToLocations() {
    let paramObj = {
      source: this.source
    };
    this.blockUI.start();
    let dataSubscription  = this.createService.getBillToLocation(paramObj).subscribe(
      data=>{
      this.billToLocationList = data;
      var defaultOtherLocId = this.serviceData.csrData.canonBillToPartySiteId;
      
	  //this.selectedOtherBillLoc =this.serviceData.csrData.billToLoc;

      try {	
     // const selectedOtherBillLocList = this.billToLocationList.filter(item => item.partySiteId === defaultOtherLocId);
     // this.selectedOtherBillLoc = selectedOtherBillLocList[0];
      }
      catch (error) {
        console.error('getBillToLocations:', error);
      }
      });
      this.blockUI.stop();
  }

  getInstallLocations() {
    let paramObj = {
      source: this.source
    };
    this.blockUI.start();
    let dataSubscription  = this.createService.getInstallLocation(paramObj).subscribe(
      data=>{
      this.locationList = data;
      this.locationListCopy = data;
      });
      this.blockUI.stop();
  }

 selectDiffInstall(){
   // if(this.diffrentLocation == 'Yes'){
    //  const selectedOtherInstallLocList = this.locationListCopy.find(item => item.address === this.serviceData.csrData.canonBuLocationText);  
     // this.selectedOtherInstallLoc = selectedOtherInstallLocList[0];     
   // }
  }
 otherBillToSelected(){
  try {
  const otherBillToLocationAddress = this.billToLocationList.find(item => item.partySiteId === this.selectedOtherBillLoc);
  this.otherBillToLocationAddress = otherBillToLocationAddress[0].address;
} catch (error) {
  console.error('Error:', error);
}
  }
  getAmazonCallTypes(ahsFlag) {
    this.blockUI.start();
    let paramObj = {
      custNum: this.serviceData.csrData.emailCustNum,
      ahsCdEnblFlg:ahsFlag,
      model: this.serviceData.model,
      branch: this.serviceData.csrData.branchCode
    };
   
    let dataSubscription  = this.createService.getAmazonCallType(paramObj).subscribe(
      data=>{
      this.callTypes = data;
      if(this.callTypes.length > 0){
        this.selectedCallType = this.callTypes[0];
        this.blockUI.stop();
        this.amazonCallTypeDetails();
      }
      });
  }
  amazonCallTypeDetails() {
    this.blockUI.start();
    let paramObj = {
      instanceId: this.instanceId,
      callTpCd:this.selectedCallType.callTypeId,
      serNum: this.serialNum,
      source: this.source
    };

    let dataSubscription  = this.createService.getCallTypeDetails(paramObj).subscribe(
      data=>{
      let callTypeDetails = data;
      this.serviceData.csrData.billableMessage = callTypeDetails.billMsg;
      this.serviceData.csrData.billableMessage_02 = callTypeDetails.billMsg2;
      this.serviceData.csrData.billableFlag = callTypeDetails.billableFlag;
      this.serviceData.csrData.billableCode = callTypeDetails.billCode;
      this.serviceData.csrData.ahsCdEnblFlg = callTypeDetails.ahsCdEnblFlg;
      this.serviceData.csrData.aftHrsPopDplyFlg = callTypeDetails.aftHrsPopDplyFlg;
      this.serviceData.csrData.laborChrgFlg = callTypeDetails.laborFlag;
      this.serviceData.csrData.partsFlag = callTypeDetails.partsFlag;
      this.serviceData.csrData.ccHoldNote = callTypeDetails.ccHoldNote;
      //$("#callTypeId").val($("#amznCallType option:selected").val());
      this.serviceData.ahsMsg = callTypeDetails.billMsg;
      this.blockUI.stop();
      });
     
  }
  getProblemSummary() {
    let paramObj = {
      source: this.source,
      returnAttr:'problemType'
    };
    this.blockUI.start();
    let dataSubscription  = this.createService.getProbleCode(paramObj).subscribe(
      data=>{
      this.problemSummaryList = data.probCodeList;      
      });
      this.blockUI.stop();	
  }

  getProblemDesc(selectedValue: string) {
    console.log("the LookupCode:"+selectedValue);
    console.log("the Desc:"+this.selectedProbSummary);
    this.blockUI.start();
    const selectedItem = this.problemSummaryList.find(item => item.description === selectedValue);

    if (selectedItem) {
      this.selectedDescription = selectedItem.description;
      this.selectedProbLookupCode = selectedItem.lookupCode;
    }
   // this.selectedProbSummary =selectedItem;
    if(this.selectedProbSummary){
    let paramObj = {
      source: this.source,
      probCode:this.selectedProbLookupCode,
      returnAttr:'problemDesc'
    };
    let dataSubscription  = this.createService.getProbleDesc(paramObj).subscribe(
      data=>{      this.problemDescList = data.probDescList;
     
      });
    }	
    this.blockUI.stop();
  }

  getProblemCode(selectedValue: string) {
    this.blockUI.start();
   // this.selectedProbDesc = this.problemSummaryList.find(item => item.lookupCode === selectedValue);
   const selectedItem = this.problemDescList.find(item => item.description === this.selectedProbDesc);
    if(this.serviceData.source === 'S21' && this.selectedProbSummary  && this.selectedProbDesc){
     
    let paramObj = {
      source: this.source,
      probCode:this.selectedProbLookupCode,
      probDesc:selectedItem.lookupCode,
      returnAttr:'problemCode'
    };
    let dataSubscription  = this.createService.getProbleCode(paramObj).subscribe(
      data=>{
      this.problemCodeList = data.probCodeList;
     
      });
  }
  this.blockUI.stop();
  }
  
openModel() {
  this.searchText ="";
  this.installAddrModalRef = this.modalService.show(this.installTemplate);
}
closeModel(event: Event) {
  event.preventDefault();
  this.installAddrModalRef.hide();
}

 showInstallLocationModal() {
    try {
	  this.showNoAddrMsg = false;
      this.openModel();   
      this.showLoader =true;
      let paramObj = {
        source: this.source
      };
      this.blockUI.start();
      let dataSubscription  = this.createService.getInstallLocation(paramObj).subscribe(
        data=>{
          
        //this.locationList.address="---I cannot find the install address in this list---";
        this.locationList = data;
       // this.locationList.push({ address: "---I cannot find the install address in this list---" ,partySiteId :""});
        
        this.showLoader =false;
		if(this.locationList.length == 0){
			this.showNoAddrMsg = true;
		}   
		   
      });     
        this.blockUI.stop();
    } catch (error) {
      console.error('Error:', error);
    }
  }
  selectInstallLocation(addr,event: Event) { 
    event.preventDefault();

    if(addr == undefined || addr == '' ){
      this.selectedOtherInstallLoc={};
      this.selectedOtherInstallLoc.partySiteId = '';
      this.selectedOtherInstallLoc.installLocation = '';
    }
    else{
      this.selectedOtherInstallLoc = addr;	
      this.selectedOtherInstallLoc = JSON.parse(JSON.stringify(addr));
    }
   
    
    console.log("selectedOtherInstallLoc:"+this.selectedOtherInstallLoc);
    this.closeModel(event);
  }
  validatePaymentInfo() {
    let ts= 0;
    const paramObj = {
      sequenceNumber: this.serviceData.csrData.sequenceNumber
    };
  
    const qryStr = new URLSearchParams(paramObj).toString();
    let dataSubscription  = this.createService.validatePaymentInfo(paramObj).subscribe(
      data=>{
        let sRes = data.validatePaymentInfo;
    //    sRes = sRes.trim();
  
        if (sRes !== '') {
          if (sRes === "-1") {
            // window closed
          } else if (sRes.indexOf(":") > 0) {
            console.log("sRes:" + sRes);
            const sResSplit = sRes.split(":");
            this.creditCardNum = sResSplit[0];
  
            const expMnth = sResSplit[1];
            this.ccExpDate = expMnth.slice(0, 2) + "-" + expMnth.slice(5, 7);
  
            this.serviceData.pmtFlg = "Y";
          }
        } else {
          if (ts < 1440) { // 2 hrs
            ts++;
            this.checkProfile();
          }
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }
  
  checkProfile() {
    setTimeout(() => {
      this.validatePaymentInfo();
    }, 5000);
  }
  openCardInfo() {
    this.checkProfile();
  
    const queryParams = `?args=${this.serviceData.encSeqNum}${this.serviceData.encFirstName}${this.serviceData.encLastName}${this.serviceData.encStreet}${this.serviceData.encCity}${this.serviceData.encState}${this.serviceData.encPostalCode}${this.serviceData.encSource}${this.serviceData.encIsWeb}${this.serviceData.encMrchCode}${this.serviceData.encDbName}`;
  
    const url = `${this.serviceData.csrData.pciUrl}${queryParams}`;
  
    window.open(url);
  }

  invoiceSelected() {
    this.msg = "";
   // this.createForm.get('payType').setErrors({ 'ccError': null });
  }
  validateForm(){
    var isValid = true;
  this.rWorkAreaCodeHiddenValue= true;
  this.rWorkNumberHiddenValue= true;
  this.otherInstallLocNumberHiddenValue= true;
  this.otherBillLocNumberHiddenValue= true;
  this.poNumNumberHiddenValue= true;
  this.contactNameHiddenValue= true;
  this.emailAddressHiddenValue= true;
  this.workAreaCodeHiddenValue= true;
  this.workAreaCodeMinHiddenValue= true;
  this.workNumberHiddenValue= true;
  this.conRoomHiddenValue= true;
  this.pbmSummaryHiddenValue= true;
  this.pbmDescHiddenValue= true;
  this.pbmCodeHiddenValue= true;
  this.diffrentLocHiddenValue= true;
  this.selAgreeHiddenValue =true;
  this.otherBillLocHiddenValue =true;
  if(this.serviceData.heatServReq === 'Y')
  {
    if((this.requestorWorkAreaCode === undefined || (this.requestorWorkAreaCode).trim() === "")){
      this.rWorkAreaCodeHiddenValue = false;
      isValid = false;
    }
    if((this.requestorWorkNumber === undefined || (this.requestorWorkNumber).trim() === "")){
      this.rWorkNumberHiddenValue = false;
      isValid = false;
    }
  }

  if(this.serviceData.csrData.billableFlag === 'Y')
  {
  if((this.selAgree === undefined || (this.selAgree).trim() === "")){
    this.selAgreeHiddenValue = false;
    isValid = false;
  }
  if(this.selAgree == 'yes' && (this.diffrentBillLocation == 'yes' && this.serviceData.csrData.canonBillToPartySiteId != -1))
  {
    if((this.selectedOtherBillLoc === 'Select an address from the list' || (this.selectedOtherBillLoc) === "")){
      this.otherBillLocNumberHiddenValue = false;
      isValid = false;
    }
  }
  
  // JR : Is this validation needed ? 
  /*
  if(this.selAgree == 'yes' && (this.diffrentBillLocation == 'yes' && this.serviceData.csrData.canonBillToPartySiteId != -1))
  {
      this.otherBillLocHiddenValue = false;
      isValid = false;
  }
  */

  if(this.selAgree == 'yes')
  {
    if(this.serviceData.csrData.billableFlag == 'Y' &&this.selAgree == 'yes' && this.paymentType == 'INVOICE' && !this.poNumber){
      this.poNumNumberHiddenValue = false;
      isValid = false;
    }
  }
}
    if((this.contactName == undefined || (this.contactName).trim() == "" ||  this.contactName == null)){
      this.contactNameHiddenValue = false;
      isValid = false;
    }
    if((this.emailAddress === undefined || (this.emailAddress).trim() === "")){
      this.emailAddressHiddenValue = false;
      isValid = false;
    }

    if((this.workAreaCode === undefined || (this.workAreaCode).trim() === "")){
      this.workAreaCodeHiddenValue = false;
      isValid = false;
    }
 
    if(this.workAreaCode){
      if((this.workAreaCode).length !=3)
      {
      this.workAreaCodeMinHiddenValue = false;
      isValid = false;
      }
    }
  

    if((this.workNumber === undefined || (this.workNumber).trim() === "")){
      this.workNumberHiddenValue = false;
      isValid = false;
    }

    if((this.conRoom === undefined || (this.conRoom).trim() === "")){
      this.conRoomHiddenValue = false;
      isValid = false;
    }
    if((this.selectedProbSummary === undefined || (this.selectedProbSummary).trim() === "")){
      this.pbmSummaryHiddenValue = false;
      isValid = false;
    }
    if((this.selectedProbDesc === undefined || (this.selectedProbDesc).trim() === "")){
      this.pbmDescHiddenValue = false;
      isValid = false;
    }
    if(this.serviceData.source === 'S21')
    {
    if((this.selectedProbCode === undefined || (this.selectedProbCode).trim() === "")){
      this.pbmCodeHiddenValue = false;
      isValid = false;
    }
  }
    if((this.diffrentLocation === undefined || (this.diffrentLocation).trim() === "")){
      this.diffrentLocHiddenValue = false;
      isValid = false;     
    }
    if(this.diffrentLocation === 'Yes')
    {
    if((this.selectedOtherInstallLoc === undefined || (this.selectedOtherInstallLoc) === "")){
      this.otherInstallLocNumberHiddenValue = false;
      isValid = false;
    }
    if (this.paymentType === 'C') {
      if (this.source === 'Oracle' && !this.creditCardNum) {
         this.ccErrorMessage = 'Card Number is required.';
         isValid = false;
       } else if (this.source === 'S21' && !this.creditCardNum) {
         this.ccErrorMessage = 'Please click on the credit card again and enter details';
         isValid = false;
      }
      }
  }
    return isValid;

  }
  continue() {
    if (!(this.validateForm())){
      console.log("form not valid..................");
	  //alert("Form validation failed...");

     }
     else{
    let valid = true;
   
    if (valid) {
      let paramObj = {
        serNum: this.serialNum,
        source: this.source
      };
     this.blockUI.start();
     // const qryStr = new URLSearchParams(paramObj).toString();

    //  const dataPromise = this.createService.getReviewServiceDetails(qryStr);
      let dataSubscription  = this.createService.getReviewServiceDetails(paramObj).subscribe(
        (data) => {
          const reviewData = data;

          this.userId = reviewData.userId;
          this.serviceData.homeServReqFlag = reviewData.homeServReqFlag;
          this.serviceData.heatServReq = reviewData.heatServReq;
          this.serviceData.customerName = reviewData.customerName;
          this.serviceData.srSupportEmail = reviewData.srSupportEmail;
          this.serviceData.srSupportPhone = reviewData.srSupportPhone;
          this.serviceData.userPhone = reviewData.userPhone;
          this.blockUI.stop();
          this.page = 'review';
        
          if (this.serviceData.homeServReqFlag === 'Y') {
            this.showAtHomeServiceModal();
          }

          window.scrollTo(0, 0);
        },
        (reason) => {
          console.error(reason);
        }
      );
    }
  }
  }
  closeDisplayModal()
  {
   //this.displayHomeStyle='none';
   //this. displayHomeModal = 'false';
   this.atHomeModalRef.hide();
  }
  setHomeServReqFlg(flag)
  {
    this.atHomeServiceFlag = flag;
    this.closeDisplayModal();
  }
  showAtHomeServiceModal() {
  //  this.displayHomeStyle='flex';
  //  this. displayHomeModal = 'true';
  this.atHomeModalRef = this.modalService.show(this.atHomeTemplate,{
    class: 'modal-dialog-centered',ignoreBackdropClick: true, 
    keyboard: false
  });
  }

  selectCallType(serviceCallType,event){
    if(this.serviceData.source == 'S21'){
      this.selectS21CallType(serviceCallType, event);
    }else{
      this.selectOracleCallType(serviceCallType, event);
    }
  }

  selectOracleCallType(serviceCallType,event){

    event.preventDefault();
    
    this.blockUI.start();

    if(serviceCallType === 'AHS'){

      let oParams = { 
        serialNumber: this.serviceData.csrData.serialNumber, 
        canonBillToAccountId: this.serviceData.csrData.canonBillToAccountId, 
        contractId: this.serviceData.csrData.contractId,
        nonEntitledBilledFlag: this.serviceData.csrData.nonEntitledBilledFlag, 
        travelRate: this.serviceData.csrData.travelRate,
        billableMessage : this.serviceData.csrData.billableMessage,
        billableMessage_02 : this.serviceData.csrData.billableMessage_02 
       };
    
      this.createService.getOracleAHSCallType(oParams).subscribe(
        data => {
          if(data != null){
            this.selectedCallType = {};
            this.selectedCallType.callTypeId = data.callTypeId;
            this.selectedCallType.callType = data.callType;
              
            this.nonEntitledBilledFlag = this.upperCasePipe.transform(this.serviceData.csrData.nonEntitledBilledFlag);
              
            if(this.nonEntitledBilledFlag == 'BILLABLE'){
              this.serviceData.csrData.billableFlag = data.billableFlag;
              this.serviceData.csrData.billableCode = data.billCode;	
              this.serviceData.csrData.partsFlag = data.partsFlag;
              this.serviceData.csrData.labourRate = data.labourRate;
              this.serviceData.csrData.billableMessage = data.billableMessage;
              this.serviceData.csrData.billableMessage_02 = data.billableMessage_02;
            }
            this.blockUI.stop();
            this.closeAfterHoursModal();
          }
        }
      )
    }else{
      this.blockUI.stop();
      this.closeAfterHoursModal();
    }

  }

  selectS21CallType(serviceCallType,event) {
    console.log("inside selectS21CallType");
    event.preventDefault();
    let valid = true;
    //const phNumberLength = $("#wareacode").val().trim().length + $("#wnumber").val().trim().length;
    const phNumberLength = this.workAreaCode.length + this.workNumber.length;
    if (phNumberLength > 20) {
      this.workNumberS21HiddenValue =false;
     
      valid = false;
    }
  
    if (valid) {
      const paramObj = {
        serial_number: this.serialNum,
        model: this.model,
        source: serviceCallType,
        mthd: 'gctp'
      };
      this.blockUI.start();
      let dataSubscription  = this.createService.getAHSCallType(paramObj).subscribe(
        (data) => {
          if (data != null) {
            this.blockUI.stop();
              this.selectedCallType = {
                callTypeId: data.callTypeId,
                callType: data.callType
              };
            
            if (serviceCallType === 'AHS') {
              this.closeAfterHoursModal();
            }
          }
        },
        reason => {
          console.log(reason);
          this.showLoader = false;
        }
      );
    }
  
    if (serviceCallType === 'NORMAL') {
      const paramObj = {
        instance_id: this.instanceId,
        serial_number: this.serialNum,
        external_reference: this.externalRef,
        model: this.model,
        location: this.installAddress,
        source: this.source,
        mthd: 'csrj',
        afterHourRegularCall: 'Y'
      };
  
      let dataSubscription  = this.createService.getCreateSrInfo(paramObj).subscribe(
        data=>{
          if (data !== undefined) {
            this.serviceData = data;
          }
          this.showLoader = false;
          this.blockUI.stop();
          this.closeAfterHoursModal();         
        },
        error  => {
          console.log(error );
        }
      );
    }
  }
  
  closeAfterHoursModal()
  {
  // this.displayAfterHoursStyle='none';
 //  this.displayAfterHoursModal = 'false';
 this.afterHoursModalRef.hide();
  }
  showAfterHoursModal(serviceData) {
    this.serviceData =serviceData;
 //   this.displayAfterHoursModal = 'true';
   // this.displayAfterHoursStyle='flex';
   this.afterHoursModalRef = this.modalService.show(this.afterHoursTemplate, {
    class: 'modal-dialog-centered', ignoreBackdropClick: true, 
        keyboard: false
  });
  }
  updateServiceRequest ()
  {
    this.page='create';
    window.scrollTo(0, 0);
  }
  
  submitServiceRequest() {
    this.page = 'confirm';
    this.blockUI.start();

    let paramObj = {
      serial_number: this.serialNum,
      severityId: this.serviceData.csrData.severityId,
      contractId: this.serviceData.csrData.contractId,
      contractLineId: this.serviceData.csrData.contractLineId,
      contractNum: this.serviceData.csrData.contractNum,
      instance_id: this.instanceId,
      callTypeId: this.selectedCallType ? this.selectedCallType.callTypeId : this.serviceData.csrData.callTypeId,
      categoryId: this.serviceData.csrData.categoryId,
      po: this.poNumber = (!this.poNumber || this.poNumber.trim() === '') ? '' : this.poNumber,
      hprobSum: this.selectedProbSummary + '-' + this.selectedProbDesc,
      newAddress: (this.diffrentLocation === 'Yes' && this.selectedOtherInstallLoc === '-1') ? this.selectedOtherInstallLoc : '',
      insLoc: this.serviceData.csrData.canonBuLocationText,
      resourceId: this.serviceData.csrData.resourceId,
      contactName: this.contactName,
      conRoom: this.conRoom,
      speInst:this.speInst = (!this.speInst || this.speInst.trim() === '') ? '' : this.speInst,
      userId: this.userId,
      canonPartySiteId: this.serviceData.csrData.canonPartySiteIdV,
      hoursFrom1: this.selectedOpenHr,
      hoursFrom1Type: this.selectedOpenHrType,
      hoursFrom2: this.selectedCloseHr,
      hoursFrom2Type: this.selectedCloseHrType,
      workAreaCode: this.workAreaCode,
      workNumber: this.workNumber,
      workExtension: this.workExtension,
      billableCode: this.serviceData.csrData.billableCode,
      otherLoc: (this.diffrentLocation === 'Yes' && this.selectedOtherInstallLoc === '-1') ? this.selectedOtherInstallLoc.partySiteId : this.serviceData.csrData.canonPartySiteIdV,
      billableFlag: this.serviceData.csrData.billableFlag,
      credit_card_holder: this.serviceData.csrData.personFirstName+" "+this.serviceData.csrData.personLastName,
      creditCardNum: this.creditCardNum = (!this.creditCardNum || this.creditCardNum.trim() === '') ? '' : this.creditCardNum,
      ccExpDt: this.ccExpDate = (!this.ccExpDate || this.ccExpDate.trim() === '') ? '' : this.ccExpDate,
      ccCardType:  this.serviceData.source === 'S21'? this.ccCardType : 'Others',
      zipCd: this.serviceData.csrData.postalCode,
      //PONum: this.creditCardNum = (!this.creditCardNum || this.creditCardNum.trim() === '') ? '' : this.creditCardNum,,
      creditSecFlag: this.paymentType === 'C' ? 'true' : 'false',
      lbrChrgFlg: this.serviceData.csrData.laborChrgFlg,
      partsFlg: this.serviceData.csrData.partsFlag,
      probCode: this.selectedProbSummary,
      source: this.source,
      homeSrvFlg: this.source === 'S21' ? 'N' : this.atHomeServiceFlag,
      model: this.model,
      canonBillToPartySiteId: this.serviceData.csrData.canonBillToPartySiteId,//check if diff bill to is selected
      //installPartySiteId:( this.diffrentLocation =='Yes' && this.selectedOtherInstallLoc == '-1') ? '' : this.selectedOtherInstallLoc.partySiteId,
      installPartySiteId:this.diffrentLocation === 'Yes' ? (this.selectedOtherInstallLoc.partySiteId === '-1' ? '' : this.selectedOtherInstallLoc.partySiteId) : '',
      emailCustomerName: this.serviceData.csrData.emailCustName,
      emailCustNum: this.serviceData.csrData.emailCustNum,
      poNumber: this.poNumber = (!this.poNumber || this.poNumber.trim() === '') ? '' : this.poNumber,
      emailAddress: this.emailAddress,
      countryCode: this.serviceData.csrData.countryCode,
      selLocYes: this.diffrentLocation === 'Yes' ? 'on' : '',
      billLocYes: this.diffrentBillLocation === 'Yes' ? 'on' : '',
      canonBillToAccountId: this.serviceData.csrData.canonBillToAccountId,
      ccCardTypeExt: '', // Keep it empty as you mentioned
      profileId: this.profileId, // Ensure profileId is defined and initialized
      bankAccount: '', // Keep it empty as you mentioned
      creditCard: this.paymentType === 'C' ? 'on' : '',
      otherBillLoc: this.diffrentBillLocation === 'Yes' ? this.selectedOtherBillLoc : '',
      probTypeCode: this.serviceData.source === 'S21' ? this.selectedProbCode : '',
      mblNumber: this.mobileNumber,
      external_reference: this.externalRef,
      machineStatusFlag: this.machineStatusFlag

    };

   // const qryStr = new URLSearchParams(paramObj).toString();

   // const dataPromise = this.createService.submitServiceRequest(qryStr);

   // dataPromise.subscribe(
    let dataSubscription  = this.createService.submitServiceRequest(paramObj).subscribe(
      (data) => {
       
          this.srResponseData = data;
       
        window.scrollTo(0, 0);
        this.blockUI.stop();
      },
      (reason) => {
        console.error(reason);
        this.blockUI.stop();
      }
    );
  }

  submitHeatWSServiceRequest ()
  {
    this.page = 'confirm';

    this.blockUI.start();

    let paramObj = {
      serial_number: this.serialNum,
      location: this.serviceData.csrData.canonBuLocationText,
      customerName: this.serviceData.csrData.customerName,
      source: this.source,
      requestorName: this.serviceData.csrData.defaultPartyName,
			requestorWorkAreaCode : this.requestorWorkAreaCode,
			requestorWorkNumber: this.requestorWorkNumber,
			requestorWorkExtension : this.requestorWorkExtension,
			contactName: this.contactName,
			workAreaCode: this.workAreaCode,
			workNumber: this.workNumber,
			workExtension: this.workExtension,
			hoursFrom1: this.selectedOpenHr,
			hoursFrom1Type: this.selectedOpenHrType,
			hoursFrom2: this.selectedCloseHr,			
			hoursFrom2Type: this.selectedCloseHrType,
			conRoom : this.conRoom,
			hprobSum: this.selectedProbSummary+ '-' + this.selectedProbDesc,
			speInst: this.speInst

    };
    
    let dataSubscription  = this.createService.submitServiceRequestWithHeatWS(paramObj).subscribe(
      (response) => {
        if(response != null){
						
       //   var dtVal = response.data;
          var dataFlg = response;
        
        if(dataFlg != null){
          
          var data="";
          
          if(dataFlg.status != null && dataFlg.status != undefined && dataFlg.status=='S'){
            
            if(dataFlg.incident_id != null && dataFlg.incident_id != undefined){
              
              data=dataFlg.incident_id;
              
              this.srResponseData.errorFlag = 'Y';
              this.srResponseData.heatMsg = 'We have received your service request. You will receive an email confirmation with your service request number shortly.';
            }
          }else{
            if(dataFlg.incident_id != null && dataFlg.incident_id != undefined){
              data=dataFlg.incident_id;
            }
            this.srResponseData.errorFlag = 'Y';
            this.srResponseData.heatMsg = 'Error occured while creating service request.' + data;
            
          }
        }else{
          this.srResponseData.errorFlag = 'Y';
          this.srResponseData.heatMsg = 'Error while creating service request.';
        }
        }
        window.scrollTo(0, 0);
        this.blockUI.stop();
      },
      (error) => {
        this.srResponseData = "Error occured while submitting the data";
        console.error(error);
        this.blockUI.stop();
      }
    );
  }
// enterCC = function(paymentType){
			
//     this.profileId = "";	
//     this.msg = "";
    
//     /*if($('#customerName').val() == null || $('#customerName').val().trim() == ""){
//       alert("Please choose the BillTo Address");
//       return false ;
//     }*/
//     this.enterCCModalRef = this.modalService.show(this.enterCCTemplate);
   
//       if(!this.profileId){
//          this.msg = "Credit Card details applied successfully."; ///Bank Account
//       }
//     }
enterCC(paymentType){

	this.profileId = "";	
  this.msg = "";
  let _customerInfo={}
			/*if($('#customerName').val() == null || $('#customerName').val().trim() == ""){
				alert("Please choose the BillTo Address");
				return false ;
			}*/
			
    /*  
    var formCustomerInfo= {
        _customerInfo: {
        "customerName": this.serviceData.csrData.defaultPartyName,
        "customerAddress1": this.serviceData.csrData.street,
        "customerAddress2": '',
        "customerCity": this.serviceData.csrData.city,
        "customerState": this.serviceData.csrData.state,
        "customerZIP": this.serviceData.csrData.postalCode,
        "customerEmail": "",
        "customerPhone": "",
        "customerCountryCode": "",
        "top": "700px"
      }
    }		
            
     */            
			
			
     _customerInfo= this.CustomerInfo();
      let objThis=this;

			_customerInfo= $.extend(_customerInfo, {paymentType:paymentType}); 
      console.log("Printing Before hitting creditCard function in ts file..");
      console.log("printing method params: ", $.extend);
      //var completeURL =  window.location.origin + "/#/jsp/creditCardRequestS21CSA_ASCC.jsp";
      var completeURL = this.defaultUrl + "servicerequest/readJSP";
      console.log("Printing completeURL: ",completeURL);
			$.creditCard(completeURL,$.extend({ 
					action:"open"
					},_customerInfo)
          ).done(function(data){
				console.log("Printing data after hitting creditCard function of tcepps.js class: ",data);
				//if(this.paymentType == 'C'){
					objThis.ccCardType = data.cardBrand;
					objThis.ccExpDate = data.ccExp;
					objThis.creditCardNum = "************"+data.ccAccountNum;
				//}  
				
          objThis.profileId = data.customerRefNum;
				
				if(!objThis.profileId){
          objThis.msg = "Credit Card details applied successfully."; ///Bank Account
				}
			});
  }

  CustomerInfo(){
    return {
         "customerName":this.serviceData.csrData.defaultPartyName,
         "customerAddress1":this.serviceData.csrData.street,
         "customerAddress2":'',
         "customerCity":this.serviceData.csrData.city,
         "customerState":this.serviceData.csrData.state,
         "customerZIP":this.serviceData.csrData.postalCode,
         "customerEmail":"",
         "customerPhone":"",
         "customerCountryCode":"",
         "top":"700px"
         };
        }


  stopVideo(id: string){
          console.log('Printing id value: ', id);
          const video = document.getElementById(id) as HTMLVideoElement;
          if(video){
           video.pause();
           //video.load();
          }
    }   
    
    cancelServiceRequest(){
      this.closeAfterHoursModal();
      this.router.navigate(['servicerequest']);
    }

 }


