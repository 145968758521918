import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserIdleModule } from 'angular-user-idle';
import { BlockUIModule } from 'ng-block-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common/login/login.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './layout/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HeaderComponent } from './layout/header/header.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { MatDatepickerModule,MatNativeDateModule,MatInputModule} from '@angular/material';
import { DatePipe, CurrencyPipe, UpperCasePipe } from '@angular/common';
import { NumberFieldDirective } from './customdirectives/number-field.directive';
import { CurrencyFieldDirective } from './customdirectives/currency-field.directive';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MenuComponent } from './layout/menu/menu.component';
import { MaterialModule } from './customdirectives/material.module';
import { HomeComponent } from './common/home/home.component';
import { ContactUsComponent } from './common/contact-us/contact-us.component';
import { EncrDecr } from './service/common-service/encrdecr.service';
import { UserTermsConditionComponent } from './common/user-terms-condition/user-terms-condition.component';
import { UserTwoFactorComponent } from './common/user-two-factor/user-two-factor.component';
import { FaqComponent } from './common/faq/faq.component';
import { MyDevicesComponent } from './account-mgmt/my-devices/my-devices.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { UserPreferencesComponent } from './common/user-preferences/user-preferences.component';
import { OrderContractSuppliesComponent } from './product-supplies/order-contract-supplies/order-contract-supplies.component';
import { AccountDevicesComponent } from './account-mgmt/account-devices/account-devices.component';
import { RequestServiceComponent } from './service-request/request-service/request-service.component';
import { CreateServiceComponent} from './service-request/create-service/create-service.component';
import { BlockTemplateComponent } from './common/block-template.component';
import { OrderSuppliesSubmitMeterReadsComponent } from './product-supplies/order-supplies-submit-meter-reads/order-supplies-submit-meter-reads.component';
import { OpenOrdersComponent } from './product-supplies/open-orders/open-orders.component';
import { ClosedOrdersComponent } from './product-supplies/closed-orders/closed-orders.component'; 
import { ClosedServiceComponent } from './service-request/closed-service/closed-service.component';
import { OpenServiceComponent } from './service-request/open-service/open-service.component';
import { CfsInvoiceInquiryComponent } from './invoices/cfs-invoice-inquiry/cfs-invoice-inquiry.component';
import { MeterHistoryComponent } from './meter-reads/meter-history/meter-history.component';
import { OrderSuppliesListingComponent } from './product-supplies/order-supplies-listing/order-supplies-listing.component';
import { SubmitMeterReadsComponent } from './meter-reads/submit-meter-reads/submit-meter-reads.component';
import { OrderSuppliesShippingAddressComponent } from './product-supplies/order-supplies-shipping-address/order-supplies-shipping-address.component';
import { CsaInvoiceInquiryComponent } from './invoices/csa-invoice-inquiry/csa-invoice-inquiry.component';
import { SearchFilterPipe } from './custompipes/search-filter.pipe';
import { OrderSuppliesReviewComponent } from './product-supplies/order-supplies-review/order-supplies-review.component';
import { OrderSuppliesViewCartComponent } from './product-supplies/order-supplies-view-cart/order-supplies-view-cart.component';
import { AccountSupportComponent } from './account-mgmt/account-support/account-support.component';
import { AccountSupportResponseComponent } from './account-mgmt/account-support/account-support-response/account-support-response.component';
import { OrderInquiryComponent } from './product-supplies/order-inquiry/order-inquiry.component';
//import {RegisterUserBySerialNumberComponent} from './register-user/serialnum/serialnum.component';
import { ModalModule } from 'ngx-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { RequestDevicesComponent } from './account-mgmt/request-devices/request-devices.component';
import {RegisterUserComponent} from './register-user/register.component';
import { PaymentMethodComponent } from './invoices/payment-method/payment-method.component';
import { NewPaymentComponent } from './invoices/new-payment/new-payment.component';
import { RequestDevicesProcessedComponent } from './account-mgmt/request-devices-processed/request-devices-processed.component';
import { RequestDevicesProcessedConfirmationComponent } from './account-mgmt/request-devices-processed-confirmation/request-devices-processed-confirmation.component';
import { AssignDevicesComponent } from './account-mgmt/assign-devices/assign-devices.component';
import { OtherCanonProductsComponent } from './product-supplies/other-canon-products/other-canon-products.component';
import { DeviceDetailsComponent } from './account-mgmt/device-details/device-details.component';
import { SavedPaymentComponent } from './invoices/saved-payment/saved-payment.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AutopayComponent } from './invoices/autopay/autopay.component';
import { ConfiguratorMyquotesComponent } from './configurator/customer-portal/configurator-myquotes/configurator-myquotes.component';
import { OtherCanonProductsCheckoutComponent } from './product-supplies/other-canon-products-checkout/other-canon-products-checkout.component';
import { OtherCanonProductsShopcartComponent } from './product-supplies/other-canon-products-shopcart/other-canon-products-shopcart.component';
import { ConfiguratorCreatequoteComponent } from './configurator/customer-portal/configurator-createquote/configurator-createquote.component';
import { OtherCanonProductsCartconfirmationComponent } from './product-supplies/other-canon-products-cartconfirmation/other-canon-products-cartconfirmation.component';
import { SalesRepComponent } from './sales-rep/sales-rep.component';
import { EliteComponent } from './elite/elite.component';
import { AlertComponent } from './fleet-mgmt/alert/alert.component';
import { TonerLevelComponent } from './fleet-mgmt/toner-level/toner-level.component';
import { MeterReadsComponent } from './fleet-mgmt/meter-reads/meter-reads.component';
import { ContractBillReportsComponent } from './contract-bill-reports/contract-bill-reports.component';
import { ConfiguratorBuildquoteComponent } from './configurator/customer-portal/configurator-buildquote/configurator-buildquote.component';
import { RequestprintassessmentComponent } from './fleet-mgmt/requestprintassessment/requestprintassessment.component';
import { SystemadministrationComponent } from './fleet-mgmt/systemadministration/systemadministration.component';
import { SystemadministrationEditlinksComponent } from './fleet-mgmt/systemadministration-editlinks/systemadministration-editlinks.component';
import { ConfiguratorMaintenanceComponent } from './configurator/customer-portal/configurator-maintenance/configurator-maintenance.component';
import { ConfiguratorBuildoptionComponent } from './configurator/customer-portal/configurator-buildoption/configurator-buildoption.component';
import { ConfiguratorSummaryComponent } from './configurator/customer-portal/configurator-summary/configurator-summary.component';
import { ConfiguratorShippingComponent } from './configurator/customer-portal/configurator-shipping/configurator-shipping.component';
import { ConfiguratorConfirmationComponent } from './configurator/customer-portal/configurator-confirmation/configurator-confirmation.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AutoTonerDetailsComponent } from './common/auto-toner-details/auto-toner-details.component';
import { AutoTonerUploadDownloadComponent } from './common/auto-toner-upload-download/auto-toner-upload-download.component';
import { AutoTonerUploadDownloadModalComponent } from './common/auto-toner-upload-download-modal/auto-toner-upload-download-modal.component';
import { AutoTonerDetailsModalComponent } from './common/auto-toner-details-modal/auto-toner-details-modal.component';
import { RemotesupportComponent } from './fleet-mgmt/remotesupport/remotesupport.component';
import { OrderDetailsComponent } from './product-supplies/order-details/order-details.component';
import { EliteHomeComponent } from './elite/elite-home.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { UpdateSerialComponent } from './manage-user/update-serial/update-serial.component';
import { SwitchUserComponent } from './admin/switch-user/switch-user.component';
import { SiteSurveyComponent } from './survey/site-survey/site-survey.component';
import { MfpSettingComponent } from './survey/mfp-setting/mfp-setting.component';
import { PurchaseSuppliesComponent } from './product-supplies/purchase-supplies/purchase-supplies.component';
import { P66LoginComponent } from './common/p66/p66-login.component';
import { HtmlPipe } from './custompipes/html-pipe';
import { P66NewRegistrationComponent } from './common/p66/p66-new-registration/p66-new-registration.component';

import { ConfiguratorAdminComponent } from './admin/configurator/configurator-admin/configurator-admin.component';
import { ProductCatalogComponent } from './webadmin/product-catalog/product-catalog.component';
import { CreateCatalogComponent } from './webadmin/create-catalog/create-catalog.component';
import { DefineLevelsComponent } from './admin/configurator/define-levels/define-levels.component';
import { LeaseoptionsComponent } from './admin/configurator/leaseoptions/leaseoptions.component';
import { ManageleveldetailsComponent } from './admin/configurator/manageleveldetails/manageleveldetails.component';
import { ConfigUsersComponent } from './admin/configurator/config-users/config-users.component';
import { ProductExclusionComponent } from './admin/configurator/product-exclusion/product-exclusion.component';
import { ManagePricebookComponent } from './admin/configurator/manage-pricebook/manage-pricebook.component';
import { RoleDefinitionComponent } from './webadmin/role-definition/role-definition.component';
import { UserSearchComponent } from './webadmin/user-search/user-search.component';
import { PendingUserApprovalComponent } from './webadmin/pending-user-approval/pending-user-approval.component';
import { DefineLookupsComponent } from './webadmin/define-lookups/define-lookups.component';
import { EnterLookupsComponent } from './webadmin/enter-lookups/enter-lookups.component';
import { RoleManageComponent } from './webadmin/role-definition/role-manage/role-manage.component';
import { UserroleAssignmentComponent } from './webadmin/userrole-assignment/userrole-assignment.component';
import { PendingSerialApprovalComponent } from './webadmin/pending-serial-approval/pending-serial-approval.component';
import { LookupsDefinitionComponent } from './webadmin/lookups-definition/lookups-definition.component';
import { ReviewApprovalSerialsComponent } from './webadmin/review-approval-serials/review-approval-serials.component';
import { MarketingAdminComponent } from './webadmin/marketing-admin/marketing-admin.component';
import { GridRoleComponent } from './webadmin/grid-role/grid-role.component';
import { LookupMaintainValueComponent } from './webadmin/lookup-maintain-value/lookup-maintain-value.component';
import { CsaInvoicePaymentComponent } from './invoices/csa-invoice-payment/csa-invoice-payment.component';
import { SubmitPaymentComponent } from './invoices/submit-payment/submit-payment.component';
import { PhoneMaskDirective } from './customdirectives/phone-mask.directive';
import { SalesRepUserSearchComponent } from './sales-rep/sales-rep-user-search/sales-rep-user-search.component';
import { TonerReturnComponent } from './product-supplies/toner-return/toner-return.component';
import { TonerReturnConfirmationComponent } from './product-supplies/toner-return-confirmation/toner-return-confirmation.component';
import { TonerReturnHistoryComponent } from './product-supplies/toner-return-history/toner-return-history.component';
import { UserDetailsComponent } from './common/user-details/user-details.component';
import { MeterReadsValueDirective } from './customdirectives/meter-reads-value.directive';

@NgModule({
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    ModalModule.forRoot()
    ,
     // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 7200 (120 minutes), `timeout` is 60 (1 minute) 
    // and `ping` is 10 (10 seconds).
    UserIdleModule.forRoot({idle: 7200, timeout: 60, ping: 10}),
    ReactiveFormsModule,
   BrowserAnimationsModule,
   MaterialModule,
   MatNativeDateModule,
   MatInputModule,
   MatDatepickerModule,  
   Ng2SearchPipeModule,
   NgxPaginationModule,
   FormsModule,
   NgApexchartsModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,   
    FooterComponent,
    HeaderComponent,
    SideNavComponent,  
    NumberFieldDirective,
    AccessDeniedComponent,
    CurrencyFieldDirective,
    MenuComponent,
    HomeComponent,
    ResetPasswordComponent,
    ContactUsComponent,
    UserPreferencesComponent,
    UserTermsConditionComponent,
    UserTwoFactorComponent,
    FaqComponent,
    MyDevicesComponent,
    OrderContractSuppliesComponent,
    AccountDevicesComponent,
    RequestServiceComponent,
    CreateServiceComponent,
    BlockTemplateComponent,
    OrderSuppliesSubmitMeterReadsComponent,
    OpenOrdersComponent,
    ClosedOrdersComponent,
	ClosedServiceComponent,
	OpenServiceComponent,
  CfsInvoiceInquiryComponent,
  MeterHistoryComponent,
  OrderSuppliesListingComponent,
  SubmitMeterReadsComponent,
  OrderSuppliesShippingAddressComponent,
  CsaInvoiceInquiryComponent,
  OrderSuppliesReviewComponent,
  OrderSuppliesViewCartComponent,
  AccountSupportComponent,
  AccountSupportResponseComponent,
  OrderInquiryComponent,
  SearchFilterPipe,
  RequestDevicesComponent,
  RegisterUserComponent,
  PaymentMethodComponent,
  NewPaymentComponent,
  RequestDevicesProcessedComponent,
  RequestDevicesProcessedConfirmationComponent,
  AssignDevicesComponent,
  OtherCanonProductsComponent,
  DeviceDetailsComponent,
  SavedPaymentComponent,
  AutopayComponent,
  ConfiguratorMyquotesComponent,
  OtherCanonProductsCheckoutComponent,
  OtherCanonProductsShopcartComponent,
  ConfiguratorCreatequoteComponent,
  OtherCanonProductsCartconfirmationComponent,
  SalesRepComponent,
  EliteComponent,
  AlertComponent,
  TonerLevelComponent,
  MeterReadsComponent,
  ContractBillReportsComponent,
  ConfiguratorBuildquoteComponent,
  RequestprintassessmentComponent,
  SystemadministrationComponent,
  ConfiguratorMaintenanceComponent,
  ConfiguratorBuildoptionComponent,
  ConfiguratorSummaryComponent,
  ConfiguratorShippingComponent,
  ConfiguratorConfirmationComponent,
  HomepageComponent,
  AutoTonerDetailsComponent,
  AutoTonerUploadDownloadComponent,
  AutoTonerUploadDownloadModalComponent,
  AutoTonerDetailsModalComponent,
  RemotesupportComponent,
  OrderDetailsComponent,
  SystemadministrationEditlinksComponent,
  EliteHomeComponent,
  ManageUserComponent,
  UpdateSerialComponent,
  SwitchUserComponent,
  SiteSurveyComponent,
  MfpSettingComponent,
  PurchaseSuppliesComponent,
  P66LoginComponent,
  HtmlPipe,
  P66NewRegistrationComponent,
  
  ConfiguratorAdminComponent,
  ProductCatalogComponent,
  CreateCatalogComponent,
  DefineLevelsComponent,
  LeaseoptionsComponent,
  ManageleveldetailsComponent,
  ConfigUsersComponent,
  ProductExclusionComponent,
  ManagePricebookComponent,
  RoleDefinitionComponent,
  UserSearchComponent,
  PendingUserApprovalComponent,
  DefineLookupsComponent,
  EnterLookupsComponent,
  RoleManageComponent,
  UserroleAssignmentComponent,
  PendingSerialApprovalComponent,
  LookupsDefinitionComponent,
  ReviewApprovalSerialsComponent,
  MarketingAdminComponent,
  GridRoleComponent,
  LookupMaintainValueComponent,
  CsaInvoicePaymentComponent,
  SubmitPaymentComponent,
  PhoneMaskDirective,
  SalesRepUserSearchComponent,
  TonerReturnComponent,
  TonerReturnConfirmationComponent,
  TonerReturnHistoryComponent,
  UserDetailsComponent,
  MeterReadsValueDirective
  ],
  providers: [
    DatePipe, Title,CurrencyPipe,EncrDecr,NgbActiveModal, UpperCasePipe
    // {
    //    provide:HTTP_INTERCEPTORS, useClass: HttpInterceptorBasicAuthService, multi:true
    //  }
  ],
  entryComponents: [BlockTemplateComponent, AutoTonerDetailsModalComponent, OrderDetailsComponent, UpdateSerialComponent,SubmitPaymentComponent, UserDetailsComponent],     
  bootstrap: [AppComponent]
})
export class AppModule { }
 