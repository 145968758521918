import { Component, Input, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { RoleDefinitionService } from 'src/app/webadmin/role-definition/role-definition.service';
import { RoleManageService } from 'src/app/webadmin/role-definition/role-manage/role-manage.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {  ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-role-manage',
  templateUrl: './role-manage.component.html',
  styleUrls: ['./role-manage.component.css']
})

export class RoleManageComponent implements OnInit {

  	constructor(private commonService:CommonServiceService, private roleManageService : RoleManageService, private roleDefService : RoleDefinitionService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title, private activatedRoute: ActivatedRoute, private viewPortScroller: ViewportScroller) { }

    @BlockUI() blockUI : NgBlockUI;

	entity;
	entity_id;
	role_id;
	parent_menu_id;
	portletFlag;
	isCreateMode:boolean = false;
	
	roleObj;
	
	parentmenu = [];  // menuId, menuLabel
	error_flag_pm;
	
	menu_results = [];
	tab_results = [];
	error_flag_mh;
	
	apps = [];
	entities = [];
	
	c_entity_ID;
	selEntity="";
	selEntityId;
	c_menu_ID;
	
	showSuccessMsg = false;
	showErroMsg = false;
	
	showRoleErroMsg = false;
	showRoleInvalidErroMsg = false;
	showRoleReqErroMsg= false;
	
	displayStyle = "none";
	
	@Input() param :any;
	
	
  	ngOnInit() {
		/*
		this.entity = this.param.entity;
		this.entity_id = this.param.entity_id;
		this.role_id = this.param.role_id;
		this.parent_menu_id = this.param.parent_menu_id;
		this.portletFlag = this.param.portletFlag;
		this.isCreateMode = (this.param.createMode) == undefined ? false : this.param.createMode;
		*/

		this.getAppsAndEntities();

		/*
		var data = '{"appId":2617,"appCode":"CUSTOMER_PORTAL","entityId":19040,"entity":"CP_ACCOUNT","roleId":2242,"roleName":"CP_UNIFLOW_AIR_LIQUIDE","roleDescription":"DO NOT USE View My Devices, Account Devices, Account Support, & uniFLOW form (Air Liquide Only)","adminFlag":null,"readonlyFlag":"N","parentMenuId":1144,"parentMenuLabel":"ACCOUNT MANAGEMENT"}';
		this.roleObj = JSON.parse(data);
		
		this.portletFlag = 'N';
		
		this.parent_menu_id = 1144;
		
		*/
		
		this.portletFlag = this.activatedRoute.snapshot.paramMap.get('portletFlag');
		
		if(this.activatedRoute.snapshot.paramMap.get('role') != undefined ) {
			this.roleObj = JSON.parse(this.activatedRoute.snapshot.paramMap.get('role'));
		}
				
		if(this.activatedRoute.snapshot.paramMap.get('isCreateMode') != undefined ) {
			this.isCreateMode = Boolean(this.activatedRoute.snapshot.paramMap.get('isCreateMode'));
		}
		
		if (this.roleObj != undefined) {
			this.parent_menu_id =  this.roleObj.parentMenuId;
			this.entity = this.roleObj.entity;
			this.entity_id = this.roleObj.entityId;
			this.role_id = this.roleObj.roleId;
			
			this.getDropdownParentMenus();
			this.getParentMenuHierarchy();
		} 
		
  	}

	createMode() {
		return this.isCreateMode;
	}
	
	
	getAppsAndEntities() {
		var resp = this.roleDefService.getAppsAndEntities().subscribe(resp => {
			if (resp != null) {
				console.log('getAppsAndEntities():', resp);
				this.apps = resp.apps;
				this.entities = resp.entities;
			}
		});	
  	} 	
	
	async getDropdownParentMenus() {
		
		var param = {
			"entity_id" : this.entity_id,
		};
		
		var resp = await this.roleManageService.getDropdownParentMenusSync(param); //.subscribe(resp => {
			if (resp != null) {
				console.log('getDropdownParentMenus():', resp);
				this.error_flag_pm = resp.error_flag;
				this.parentmenu = resp.parentmenu;
			}
		//});			
		
	}
	
	getParentMenuHierarchy() {
		
		var param = {
			"role_id" : this.role_id,
			"parent_menu_id" : this.parent_menu_id
		};
		
		//this.blockUI.start();
		this.roleManageService.getParentMenuHierarchy(param).subscribe(resp => {
			if (resp != null) {
				console.log('getParentMenuHierarchy():', resp);
				this.error_flag_mh = resp.error_flag;
				this.menu_results = resp.menu_results;
				this.tab_results = resp.tab_results;
				//this.blockUI.stop();
			}
		});			
			
	}
	
	
	toRoleDefinition() {
		this.route.navigate(["roledefinition"]);
	}
	
	onChkBoxClick(menu, element: HTMLInputElement) {
		
		if(element.name == 'menu_access_flag') {
			if (!element.checked) {
				menu.menuAccessFlag = 'N';
				menu.menuReadonlyFlag = 'N';
				element  = document.getElementById("readOnlyChkMenu" + menu.menuId) as HTMLInputElement;
				element.checked = false;
			}
		} else if(element.name == 'menu_readonly_flag') {
			if (element.checked) {
				menu.menuAccessFlag = 'Y';
				menu.menuReadonlyFlag = 'Y';
				element  = document.getElementById("accessChkMenu" + menu.menuId) as HTMLInputElement;
				element.checked = true;
			}
		}
		 
	}
	
	onSave() {
		
		if(this.onRoleNameChange()) {
			window.scroll(0,0);	
			return;
		}
		
		var element: HTMLInputElement;
		
		element  = document.getElementById("showExternalFlag") as HTMLInputElement;
		if (element != null) {
			if(element.checked) {
				this.roleObj.showExternalFlag = 'Y';
				this.roleObj.show_external_flag = 'Y';
			} else {
				this.roleObj.showExternalFlag = 'N';
				this.roleObj.show_external_flag = 'N';
			}
		}
		
		element  = document.getElementById("portletFlag") as HTMLInputElement;
		if (element != null) {
			if(element.checked) {
				this.roleObj.portletFlag = 'Y';
			} else {
				this.roleObj.portletFlag = 'N';
			}
		}
		
		for(var menu of this.menu_results) {
			
			element  = document.getElementById("accessChkMenu" + menu.menuId) as HTMLInputElement;
			if(element.checked){
				menu.menuAccessFlag = 'Y';
			} else {
				menu.menuAccessFlag = 'N';
			}
			
			element  = document.getElementById("readOnlyChkMenu" + menu.menuId) as HTMLInputElement;
			if(element.checked){
				menu.menuReadonlyFlag  = 'Y';
			} else {
				menu.menuReadonlyFlag = 'N';
			}			
			
		}
		
		if(this.isCreateMode) {
			
			this.roleObj.entity = this.selEntity;
			this.roleObj.entityId = this.selEntityId;
			this.roleObj.roleId = "";
			this.roleObj.lvl =  "1",
			
   		 	this.roleObj.parentMenuId = this.parentmenu[0].menuId,
    		this.roleObj.parentMenuLabel = this.parentmenu[0].menuLabel
			
		}
		
		this.roleObj.subMenu = this.menu_results;		

		console.log(this.roleObj);
		
		var param = {
			"data" : JSON.stringify(this.roleObj), 
		}
		
		this.showErroMsg =false;
		this.showSuccessMsg = false;	
		
		this.blockUI.start();
		
		this.roleManageService.upsertAllPermissions(param).subscribe(resp => {
			if (resp != null) {
				console.log('upsertAllPermissions():', resp);
				this.blockUI.stop();
				if(resp.status == 'S') {
					this.showSuccessMsg = true;	
					this.displayStyle='block';
				} else {
					this.showErroMsg = true;
					window.scroll(0,0);	
				}
				
				
			}
		});	
		
	}
	
	
	async onEntityChange() {
		this.entity_id = this.c_entity_ID;
		//this.getDropdownParentMenus();
		var param = {
			"entity_id" : this.entity_id,
		};
		
		var resp = await this.roleManageService.getDropdownParentMenusSync(param); 
		if (resp != null) {
			console.log('getDropdownParentMenus():', resp);
			this.error_flag_pm = resp.error_flag;
			this.parentmenu = resp.parentmenu;
		}
		
		
		var choosenEntity = this.entities.filter(enti=> enti.entityId == this.c_entity_ID  );
		console.log(choosenEntity);
		this.selEntity = choosenEntity[0].entityCode;
		this.selEntityId = choosenEntity[0].entityId;
		
		//get  menu_results
		console.log(this.parentmenu[0].menuId);
		this.parent_menu_id = this.parentmenu[0].menuId;
		this.getParentMenuHierarchy() ;		
	}
	
	
	onRoleNameChange() {
		
		var roleName_REG = "/^[a-zA-Z0-9_]*$/";
		let text = this.roleObj.roleName;
		
		let regex: RegExp = /^[a-zA-Z0-9_]+$/;
		
		let match = regex.test(text);
		console.log(match); 
		
		var isError = false;
		if(!match) {
			isError = true;
			this.showRoleInvalidErroMsg = true;
		} else {
			this.showRoleInvalidErroMsg = false;
		}
		
		if(this.roleObj.roleName == '') {
			isError = true;
			this.showRoleReqErroMsg = true;
		} else {
			this.showRoleReqErroMsg = false;
		}
		
		if(isError) {
			this.showRoleErroMsg = true;
		} else {
			this.showRoleErroMsg = false;
		}
		
		return isError;
	}

 }
