import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ContractSuppliesService } from '../contract-supplies.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-order-supplies-review',
  templateUrl: './order-supplies-review.component.html',
  styleUrls: ['./order-supplies-review.component.css']
})
export class OrderSuppliesReviewComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  cartExpireDate : any
  selectedItemArray : any[] = [];
  machInfo : any
  attnLbl : string = '';
  success : boolean = false;
  locName : string = '';
  locId : string = '';
  shpMthd : string = '';
  displayConfirm : boolean = false
  poNum : string = '';
  errMsg : string = '';

  constructor(private route: ActivatedRoute,  private datePipe: DatePipe, 
    private router: Router, private commonService:CommonServiceService,
    private csService: ContractSuppliesService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.shpMthd = params['shpMthd'];
        this.loadInitialData();
      }
    )


  }

  loadInitialData(){

    //this.commonService.setMoreInfo();

    this.blockUI.start();

    let currentDate = new Date();
    currentDate.setDate( currentDate.getDate() + 1 );
    this.cartExpireDate = this.datePipe.transform(currentDate, 'dd-MMM-yyyy');


    let userName = sessionStorage.getItem('userName');

    if(localStorage.getItem(userName + '__ItemDetail') != null){
      this.selectedItemArray = JSON.parse(localStorage.getItem(userName + '__ItemDetail'));
    }

    if(this.selectedItemArray.length == 0){
      this.router.navigate(['ordersupplies']);
    }

    if(localStorage.getItem(userName + '__MachDetail') !== null){
      this.machInfo = localStorage.getItem(userName + '__MachDetail');
      console.log('machInfo : '+ this.machInfo);
    }

    if(localStorage.getItem(userName + '__AttnLbl') !== null){
    	this.attnLbl = localStorage.getItem(userName + '__AttnLbl');
    }

    this.checkShipTo(); 

    this.blockUI.stop();

    //this.commonService.setMoreInfo() call was not showing up the popup so for now added 3s timeout
    var objThis = this;
    setTimeout(function(){
      objThis.commonService.setMoreInfo();
    },3000) ;

  }

  onPrint(){
		window.print();
	}

  emptyCartConfirm() {
    let userName = sessionStorage.getItem('userName');

		localStorage.removeItem(userName + '__ItemDetail');
		localStorage.removeItem(userName + '__MachDetail');
		localStorage.removeItem(userName + '__AttnLbl');
		localStorage.removeItem(userName + '__ShipAddrs');

		this.router.navigate(['ordersupplies']);
	}

  checkShipTo(){

    let userName = sessionStorage.getItem('userName');

    if(localStorage.getItem(userName + '__ShipAddrs') !== null){
    	var shipAddrssInTheSession = localStorage.getItem(userName + '__ShipAddrs');

      if (shipAddrssInTheSession !== null && shipAddrssInTheSession !== undefined) {
        var tempArray = shipAddrssInTheSession.split('|');
        this.locName = tempArray[0];
        this.locId = tempArray[1];
      }

      if(this.locId==null || this.locId=="null" || this.locId==undefined || this.locId.length<1){
        this.router.navigate(['osshippingaddress']);
        //Old code was redirected to reviewCart which does not exists and I think it should be redirected to shipping address page
        //window.location.href = "ordSup.htm?view=reviewCart";
      }
    }
  }

  placeOrder(){

    this.blockUI.start();

    this.checkShipTo();

    let params = { 
      "itemsData": JSON.stringify(this.selectedItemArray), 
      "shipMthd": this.shpMthd,
      "locId": this.locId,
      "attnLbl": this.attnLbl,
      "poNum" : this.poNum
     };

     this.csService.createSupplyOrder(params).subscribe(
      data => {
        console.log(data);

        if(data[0] !=null && data[0].trim() >0){
          this.displayConfirm = true
        }else{
          if(data[1] !=null && data[1].trim()!='' && data[1].trim() !='E'){
            this.errMsg = data[1].trim()+" Please contact Customer Service Department at 1-800-613-2228 for assistance.";
          }else{
            this.errMsg = "Not able to create order, please contact Customer Service Department at 1-800-613-2228, Email: MyCanonBusiness@cusa.canon.com";	
          }
        }

        //$scope.orderPlaced = true

        let userName = sessionStorage.getItem('userName');

        localStorage.removeItem(userName + '__ItemDetail');
				localStorage.removeItem(userName + '__MachDetail');
				localStorage.removeItem(userName + '__AttnLbl');
				localStorage.removeItem(userName + '__ShipAddrs'); 

        this.blockUI.stop();
      }
      
    )

  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
