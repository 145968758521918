import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class TonerReturnConfirmationService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util) { 
    this.baseUrl = this.util.getBaseUrl();
  }

  createShipLabel(data) : Observable<any>{
    let url = this.baseUrl + 'fedex/createShipLabel';

    return this.http.post(url, JSON.stringify(data), httpOptions).pipe(catchError(this.handleError('createShipLabel', [])));
  }

  handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
  

  }
}
