import { Component} from '@angular/core';
import { TonerReturnConfirmationService } from './toner-return-confirmation.service';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-toner-return-confirmation',
  templateUrl: './toner-return-confirmation.component.html',
  styleUrls: ['./toner-return-confirmation.component.css']
})

export class  TonerReturnConfirmationComponent{

  @BlockUI() blockUI : NgBlockUI;

  returnNum : any;

  createLabelErrorMsg : string = '';

  constructor(private route: ActivatedRoute, private tonerReturnConfirmationService : TonerReturnConfirmationService) { }

  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {            
      this.returnNum =  params['returnNum'];  
	  });

  }

  createShipLabel(){

    this.blockUI.start();

    this.createLabelErrorMsg = '';

    let details = { 
      // returnNum: "20487900"
      RETURN_NUMBER: this.returnNum
    };

    this.tonerReturnConfirmationService.createShipLabel(details).subscribe(
      data => {
        this.blockUI.stop();
        console.log("data.status:" + data.status);
        console.log("data.LABEL_URL:" + data.label_url);
        if(data.status == 'S'){
          //window.location = data.label_url;
          window.open(data.LABEL_URL, '_blank');
        }else{
            this.createLabelErrorMsg = 'Sorry, unable to create shipment label. Please try again later'; 
        }
      });
  }
}
