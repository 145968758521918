import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-configurator-buildoption',
  templateUrl: './configurator-buildoption.component.html',
  styleUrls: ['./configurator-buildoption.component.css','../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorBuildoptionComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  totalRecords=0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any =[];
  myBuild ={} as any;
  configId: number =-1;
  quoteId: number;
  imgUrl="https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any =[];
  menuDiv = true;  
  validRequireOneOfItem = true;
  addAcc= false;
  showPage: boolean =false;
  isSectionOpen = false;
  renderer: any;
  constructor(private configquoteService:ConfiguratorQuotesService,private route : ActivatedRoute,private router: Router,private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Options");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
     //this.configId =1060;
    });
   this.getbuildOption();
  }
  getbuildOption()
  {
    this.blockUI.start();   
  let oParams = {"quoteId":this.quoteId,"configId":this.configId};
  let data = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
    data=>{       
        this.myBuild = JSON.parse(data.data);
           console.log('myBuild getbuildOption',this.myBuild);
           this.blockUI.stop();
          this.validRequireOneOfItem = true;
           this.myBuild =this.configquoteService.setConfigData(this.myBuild);
           this.myBuild.maintDetailsAvailable = false;
           if(this.myBuild.maintenance.length > 0){
            this.myBuild.maintDetailsAvailable = true;
            var maintDetails = this.myBuild.maintenance[0];
            
            if(maintDetails.isRejected == 'Y'){
              this.myBuild.maintTerm = "No Maintenance";
            }else{
              this.myBuild.maintTerm = maintDetails.termUser + " Months (" + maintDetails.contractType + ")";
            }
            this.myBuild.maintenanceAmt =this.configquoteService.getMaintTotal(this.myBuild.maintenance);
          }						                   
          this.myBuild.imgSrc = this.imgUrl + this.myBuild.model+".jpg";
          let params = {"quoteId":this.quoteId,"partyId":this.myBuild.partyId,"configId":this.configId,"productGroupId":this.myBuild.productGroupId,"itemCode":this.myBuild.machineItemCode,"purchaseType":this.myBuild.purchaseType,
           "leaseRate":this.myBuild.leaseRate,"bundleName":this.myBuild.bundleName,"priceListId":this.myBuild.priceListId,"configQty":this.myBuild.configQty}
        
          //let oParams = {"quoteId":1013,"partyId":'14669070',"configId":1087,"productGroupId":'1362',"itemCode":'4963C002',"purchaseType":'L',
         // "leaseRate":'.025',"bundleName":'imageRUNNER ADVANCE DX 6860I- Bundle A',"priceListId":'769914',"configQty":1}
          let buildOptionPromise =  this.configquoteService.configQuoteGenericMtd(params,'getMachineItems').subscribe(
            buildOptionPromise=>{        
              let optionData = buildOptionPromise.data;
              this.updateScopeData(optionData);
            },
          );
    },
  );
  }

  updateScopeData(optionData: any) {
    this.myBuild.listRequiredAccessoryTypeDetails = optionData.listRequiredAccessoryTypeDetails;
    this.myBuild.listAccessoryTypeDetails = optionData.listAccessoryTypeDetails;
    this.myBuild.listRequiredAccessory = optionData.listRequiredAccessory;
    this.myBuild.listRequireOneOfItemAccessory = optionData.listRequireOneOfItemAccessory;
    this.myBuild.listRequiredBundleAccessory = optionData.listRequiredBundleAccessory;
    this.myBuild.bundleIncludesRequireOneOfItemAccessory = optionData.bundleIncludesRequireOneOfItemAccessory;
    console.log('myBuild',this.myBuild);
  } 
  isHeaderEnable = function(listAccessory){
    return ConfiguratorQuotesService.isHeaderEnable(listAccessory);
  }
  isOptionChecked = function(opt){
    return ConfiguratorQuotesService.isOptionChecked(opt);
  }

   findLengthItems (listAccessory) {
       return ConfiguratorQuotesService.findLengthItems(listAccessory);
   }
   deleteItemMyBuildOptions(partyId, productGroupId, 
    machineItemCode, purchaseType, leaseRate, accessoryType, option, bundleName, priceListId, configQty) 
    {  
      let oParams = {"quoteId":this.quoteId,"partyId":partyId,"productGroupId":productGroupId,"machineItemCode":machineItemCode,"purchaseOption":this.myBuild.purchaseOption,
      "leaseRate":leaseRate,"bundleName":bundleName,"priceListId":priceListId,"configQty":configQty,"purchaseType":purchaseType,
      "option":option,"accessoryType":accessoryType}
  
      let deleteAction =  this.configquoteService.configQuoteGenericMtd(oParams,'deleteOptionItem').subscribe(
      deleteAction=> {
      console.log('result', deleteAction);
      var optionData = deleteAction.data.data;
      this.updateScopeData(optionData);
    },
    function(reason){
      console.log(reason);
    }
    );
  }
  //Remove machine level in the Options page
  removeFromCartOptionsPage (configId,quoteId) {
    let oParams = {"quoteId":this.quoteId,"configId":this.configId};
	    var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(quoteId,'getQuoteConfigDetails').subscribe(
	    quoteDetailsPromise =>{
	    		console.log('getquoteDetails', quoteDetailsPromise);
	            var quoteDet = quoteDetailsPromise.data;
	            var configLength = quoteDet.config.length;
	            console.log('configLength',configLength);
              this.configquoteService.removeConfig(configId);
	         	var pathName = window.location.pathname;
	        	if(configLength > 1){
	        		window.location.href = pathName+'?view=configBuildSummary&quoteId='+quoteId;
	        	}else {
	        	    window.location.href = pathName+'?view=configModels&quoteId='+quoteId;
	        	}
	         },
	        function(reason) {
	            console.log(reason);
	        });
		}

  //Add accessories in the Options page cart
  addToMyBuildOptions(partyId, productGroupId, 
    machineItemCode, purchaseType, leaseRate, accessoryType, 
    option, bundleName, priceListId, configQty) 
{
this.blockUI.start();
this.validRequireOneOfItem = true;
let addAction =  this.configquoteService.addToMyBuildOptions(partyId, this.quoteId, 
    this.configId, productGroupId, machineItemCode, purchaseType, leaseRate, 
    accessoryType, option, bundleName, priceListId, configQty);
addAction.then(
    function(result) {
      console.log('result', result);
      this.blockUI.stop();
      var optionData = result.data.data;
      this.updateScopeData(optionData);
    }
  );
}

updMyBuildOptions (partyId, productGroupId, 
    machineItemCode, purchaseType, leaseRate, accessoryType, option, 
    bundleName, priceListId, configQty) {

let updAction =  this.configquoteService.updMyBuildOptions(partyId, this.quoteId, 
    this.configId, productGroupId, machineItemCode, purchaseType, leaseRate, 
    accessoryType, option, bundleName, priceListId, configQty).subscribe(
      updAction =>{
        console.log('result', updAction);
        var optionData = updAction.data;
        this.updateScopeData(optionData);
      }
    );
      
}
invokeMaintenance (bundleIncludesRequireOneOfItemAccessory,event:Event) {
  if(event){
    event.preventDefault();
  }
  this.validRequireOneOfItem = true;
  let oParams = { "quoteId": this.quoteId, "configId": this.configId };
  const navigationExtras: NavigationExtras = {
    queryParams: oParams

  };
  var validateRequiredOneOfItem = true;
  if(!bundleIncludesRequireOneOfItemAccessory){
    validateRequiredOneOfItem = this.configquoteService.validateRequiredOneOfItem(this.myBuild.listRequireOneOfItemAccessory);
  }
  
  if(validateRequiredOneOfItem)
  {
    this.router.navigate(["/configmaint"], navigationExtras);
   // window.location.href = maintURL;
  }
  else 
  {
    this.validRequireOneOfItem = false;
  }
  
}
gotoAnchor(x: string) {
  const newHash = 'anchor' + x;
  
  if (this.router.url.includes('#' + newHash)) {
    // Scroll to the element
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.querySelector('#' + fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }
    });
  } else {
    // Navigate to the element
    this.router.navigate([], { fragment: newHash, relativeTo: this.route });
  }
 }

isMaintenanceAvailable()
{
  if(this.myBuild.maintenance.length > 0){
    return true;
}
  else
  {
    return false;
  }
}
routePage(event,pageName)
{
 if(event)
 {
  event.preventDefault();
 }
 let oParams = {"quoteId":this.quoteId,"configId":this.configId};
 const navigationExtras: NavigationExtras = {
   queryParams: oParams

 };
 this.router.navigate([pageName], navigationExtras);
}
//Remove machine level in the Options page
removeFromCart(configId,event) {
 if(event)
 {
  event.preventDefault();
 }
let oParams = {"quoteId":this.quoteId};
  var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
  quoteDetailsPromise =>{
      console.log('getquoteDetails', quoteDetailsPromise);
          var quoteDet = JSON.parse(quoteDetailsPromise.data);
          var configLength = quoteDet.config.length;
          console.log('configLength',configLength);
          this.configquoteService.removeConfig(configId);
         
         const navigationExtras: NavigationExtras = {
          queryParams: oParams
         };
          if(configLength > 1){
            this.router.navigate(['/configsummary'], navigationExtras);
          }else {
            this.router.navigate(['/createquote'], navigationExtras);
          }
       },
      function(reason) {
          console.log(reason);
      });
}

}
