import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-configurator-buildquote',
  templateUrl: './configurator-buildquote.component.html',
  styleUrls: ['./configurator-buildquote.component.css', '../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorBuildquoteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  totalRecords = 0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any = [];
  myBuild = {} as any;
  configId: number = -1;
  quoteId: number;
  imgUrl = "https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any = [];
  constructor(private configquoteService: ConfiguratorQuotesService, private route: ActivatedRoute, private titleService: Title,private el: ElementRef, private renderer: Renderer2, private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Models");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
    });
    this.getbuildJSON();
  }


  getbuildJSON() {    
    this.myBuild = JSON.parse(sessionStorage.getItem('myBuild'));
    this.quoteId = this.quoteId !== undefined ? this.quoteId : -1;
    this.configId = this.configId !== undefined ? this.configId : -1;
   
    if (this.configId != -1) {
      this.blockUI.start();
      console.log('configId', this.configId);
      this.myBuild = {} as any;
      this.myBuild.lease = {} as any;
      let oParams = { "quoteId": this.quoteId, "configId": this.configId };
      let data = this.configquoteService.configQuoteGenericMtd(oParams, 'getQuoteConfigDetails').subscribe(
        data => {         
          this.configDetails = JSON.parse(data.data);
          
          console.log('configDetails', this.configDetails);
          this.myBuild = this.configDetails.config[0];
          if (this.configDetails.config[0].feature1) {
            this.myBuild.feature1 = this.configDetails.config[0].feature1.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
          if (this.configDetails.config[0].feature2) {
            this.myBuild.feature2 = this.configDetails.config[0].feature2.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
          if (this.configDetails.config[0].feature3) {
            this.myBuild.feature3 = this.configDetails.config[0].feature3.replace(/&#39;/gi, "'").replace("\u0092", "'").replace(/\u0094/gi, "''");
          }
          console.log('myBuild with ConfigDetails', this.myBuild);
          this.myBuild.imgSrc = this.imgUrl + this.myBuild.model + ".jpg";

          this.myBuild.priceListId = this.configDetails.config[0].pricelistId;
          this.myBuild.bundleName = this.configDetails.config[0].bundleName;
          this.myBuild.purchaseType = this.configDetails.config[0].purchaseType;
          this.myBuild.configQty = this.configDetails.config[0].configQty;
          let params = {"quoteId":this.quoteId,"partyId":this.myBuild.partyId,"configId":this.configId,"productGroupId":this.myBuild.productGroupId,"itemCode":this.myBuild.itemCode,"purchaseType":this.myBuild.purchaseOption,
          "leaseRate":this.myBuild.leaseRate,"bundleName":this.myBuild.bundleName,"priceListId":this.myBuild.priceListId,"configQty":this.myBuild.configQty}
          let buildOptionPromise = this.configquoteService.configQuoteGenericMtd(params, 'getMachineItems').subscribe(
            buildOptionPromise => {
              let optionData = buildOptionPromise.data;
              this.updateScopeData(optionData);
            },
            (reason) => {
              console.log(reason);
            }
          );
          this.myBuild.maintDetailsAvailable = false;
          if (this.configDetails.maintenance.length > 0) {
            this.myBuild.maintDetailsAvailable = true;
            var maintDetails = this.configDetails.maintenance[0];

            if (maintDetails.isRejected == 'Y') {
              this.myBuild.maintTerm = "No Maintenance";
            } else {
              this.myBuild.maintTerm = maintDetails.termUser + " Months (" + maintDetails.contractType + ")";
            }
            this.myBuild.maintenanceAmt = this.configquoteService.getMaintTotal(this.configDetails.maintenance);
          }
          this.blockUI.stop();
          this.getPurchaseOptions(this.myBuild.templateId, this.myBuild.partyId, this.myBuild.purchasePrice, this.myBuild.purchaseType, this.configDetails);

        },
        function (reason) {
          console.log(reason);
        });

    } else {
      this.getPurchaseOptions(this.myBuild.templateId, this.myBuild.partyId, this.myBuild.purchasePrice, this.myBuild.purchaseType, null);
    }
   
  }

  updateScopeData(optionData: any) {
    this.myBuild.listRequiredAccessoryTypeDetails = optionData.listRequiredAccessoryTypeDetails;
    this.myBuild.listAccessoryTypeDetails = optionData.listAccessoryTypeDetails;
    this.myBuild.listRequiredAccessory = optionData.listRequiredAccessory;
    this.myBuild.listRequireOneOfItemAccessory = optionData.listRequireOneOfItemAccessory;
    this.myBuild.listRequiredBundleAccessory = optionData.listRequiredBundleAccessory;
    this.myBuild.bundleIncludesRequireOneOfItemAccessory = optionData.bundleIncludesRequireOneOfItemAccessory;
    console.log('myBuild', this.myBuild);
  }
  isHeaderEnable = function(listAccessory){
    return ConfiguratorQuotesService.isHeaderEnable(listAccessory);
  }
  isOptionChecked = function(opt){
    return ConfiguratorQuotesService.isOptionChecked(opt);
  }

  getPurchaseOptions(templateId, partyId, price, purchaseType, configDetails) {
    this.myBuild.purchaseOptions = {} as any;
   this.blockUI.start();
    let oParams = { "templateId": templateId, "partyId": partyId, "purchasePrice": price };
    let data = this.configquoteService.configQuoteGenericMtd(oParams, 'getLeaseOptions').subscribe(
      data => {
        this.leaseOptions = data.data;
        this.blockUI.stop();
        if (purchaseType === 'Equipment Lease SHOP' || purchaseType === 'Equipment SHOP') {
          for (let i in this.leaseOptions) {
            this.leaseOptions[i].price = this.leaseOptions[i].price;
            this.leaseOptions[i].months = this.leaseOptions[i].term;
            this.myBuild.purchaseOptions[this.leaseOptions[i].term] = this.leaseOptions[i];

          }
        }

        if (purchaseType === 'Equipment Purchase SHOP' || purchaseType === 'Equipment SHOP') {
          this.myBuild.purchaseOptions['Purchase'] = {
            Term: 'Purchase',
            months: 'Purchase',
            price: this.myBuild.purchasePrice, // Make sure purchasePrice is defined somewhere
            Price: this.myBuild.purchasePrice // Make sure purchasePrice is defined somewhere
          };
        }

        if (configDetails) {
          if (configDetails.config[0].purchaseOption === 'P') {
            this.myBuild.selectedPurchaseOption = 'Purchase';
            this.myBuild.lease = 'p';
          } else {
            this.myBuild.selectedPurchaseOption = configDetails.config[0].leaseTerm;
            this.myBuild.lease = this.myBuild.purchaseOptions[this.myBuild.selectedPurchaseOption];
          }
        }
      },
      (reason) => {
        console.log(reason);
      }
    );

    console.log('this.myBuild-Device Page', this.myBuild);
    console.log('purchaseOptions:', this.myBuild.purchaseOptions);
  }
  saveQuote(event) {
    if(event){
      event.preventDefault();
    }
    if (this.myBuild.lease !== '') {
      this.blockUI.start();
      if (this.myBuild.quoteId < 0) {
        let oParams = { "quoteId": this.myBuild.quoteId, "quoteName": this.myBuild.quoteName, "userQuoteName": this.myBuild.userQuoteName, "status": this.myBuild.status };
        let data = this.configquoteService.configQuoteGenericMtd(oParams, 'saveQuote').subscribe(
          data => {
            this.blockUI.stop();
            this.myBuild.quoteId = data.quoteId;
            console.log('quoteId', this.myBuild.quoteId);
            this.saveConfig(event);
          }
        )
      } else{
        this.saveConfig(event);
      }

    }
  }
  saveConfig(event) {
    if(event){
      event.preventDefault();
    }
    this.blockUI.start();
    let purchaseOption;
    let leaseTerm;
    let amount;
    if (this.myBuild.selectedPurchaseOption == 'Purchase') {
      purchaseOption = 'P';
      leaseTerm = 0;
      amount = this.myBuild.purchasePrice + '';
    } else {
      purchaseOption = 'L';
      leaseTerm = this.myBuild.purchaseOptions[this.myBuild.selectedPurchaseOption].months;
      amount = this.myBuild.purchaseOptions[this.myBuild.selectedPurchaseOption].price;
    }
    /*var parameter = {quoteId:this.myBuild.quoteId, configId:this.myBuild.configId, itemCode:this.myBuild.itemCode, itemDescription:this.myBuild.itemDescription,
        productGroupId:this.myBuild.productGroupId,purchaseOption:purchaseOption, leaseTerm:leaseTerm, amount:amount, imageName:this.myBuild.image,imagePath:this.myBuild.image};*/
    var postData = {} as any;
    postData.quoteId = this.myBuild.quoteId;
    postData.configId = this.myBuild.configId;
    postData.itemCode = this.myBuild.itemCode;
    postData.itemDescription = this.myBuild.itemDescription;
    postData.productGroupId = this.myBuild.productGroupId;
    postData.purchaseOption = purchaseOption;
    postData.leaseTerm = leaseTerm;
    postData.amount = amount;
    postData.imageName = this.myBuild.image;
    postData.imagePath = this.myBuild.image;
    postData.leaseRate = this.myBuild.lease.leaseRate;
    postData.model = this.myBuild.model;
    postData.partyId = this.myBuild.partyId;
    postData.templateId = this.myBuild.templateId;
    postData.purchasePrice = this.myBuild.purchasePrice;
    postData.pricelistId = this.myBuild.pricelistId;
    postData.pricelistName = this.myBuild.pricelistName;
    postData.bundleName = this.myBuild.bundleName;
    postData.purchaseType = this.myBuild.purchaseType;
    postData.configQty = this.myBuild.configQty;
    console.log("postData:" + postData);
    let oParams = { "quoteId": this.myBuild.quoteId, "quoteName": this.myBuild.quoteName, "userQuoteName": this.myBuild.userQuoteName, "status": this.myBuild.status };
    let data = this.configquoteService.configQuoteGenericMtd(postData, 'saveConfiguration').subscribe(
      result => {
        this.blockUI.stop();
        if (!result.error_flag && result.configId != null) {
          var qId = this.myBuild.quoteId;
          this.myBuild = {};
          this.myBuild.configId = result.configId;
         //this.myBuild.configId = "1060";
          this.myBuild.quoteId = qId;

          sessionStorage.setItem('myBuild', JSON.stringify(this.myBuild));
          console.log('configId saveconfig', this.myBuild.configId);          
          var qnc = this.myBuild.quoteId + '&configId=' + this.myBuild.configId;
          console.log('qnc', qnc);
          let oParams = { "quoteId": this.myBuild.quoteId, "configId": this.myBuild.configId};
          const navigationExtras: NavigationExtras = {
            queryParams: oParams

          };
          this.router.navigate(['/buildoption'], navigationExtras);
        }
      }
    );
  }



  deleteItemMyBuildOptions(partyId, productGroupId,
    machineItemCode, purchaseType, leaseRate, accessoryType, option, bundleName, priceListId, configQty) {
    let oParams = {
      "quoteId": this.quoteId, "partyId": partyId, "productGroupId": productGroupId, "machineItemCode": machineItemCode, "purchaseOption": this.myBuild.purchaseOption,
      "leaseRate": leaseRate, "bundleName": bundleName, "priceListId": priceListId, "configQty": configQty, "purchaseType": purchaseType,
      "option": option, "accessoryType": accessoryType
    }

    let deleteAction = this.configquoteService.configQuoteGenericMtd(oParams, 'deleteOptionItem').subscribe(
      deleteAction => {
        console.log('result', deleteAction);
        var optionData = deleteAction.data.data;
        this.updateScopeData(optionData);
      },
      function (reason) {
        console.log(reason);
      }
    );
  }

  //Find length of cart items
  findLengthItems(listAccessory) {
    return ConfiguratorQuotesService.findLengthItems(listAccessory);
  }

  disableAddOptions = true;

  addToMyBuild() {
    console.log('myBuild', this.myBuild);

    if (this.myBuild.selectedPurchaseOption == 'Purchase') {
      this.myBuild.lease = 'p';
    } else {
      this.myBuild.lease = this.myBuild.purchaseOptions[this.myBuild.selectedPurchaseOption];
    }
    this.disableAddOptions = false;
    //buildConfigurationService.addBundle(this.myBuild);
  }

  isPurchaseOptionChecked() {
    if (this.myBuild.lease == '') {
      return false;
    } else {
      const element = this.el.nativeElement.querySelector('.Opt > a');
      this.renderer.removeClass(element, 'disableNav');
      return true;
    }
  }
  isMaintenanceAvailable()
  {
    if(this.myBuild.maintenance.length > 0){
      return true;
  }
    else
    {
      return false;
    }
}
routePage(event,pageName)
{
   if(event)
   {
    event.preventDefault();
   }
   let oParams = { "quoteId": this.myBuild.quoteId, "configId": this.myBuild.configId};
   const navigationExtras: NavigationExtras = {
     queryParams: oParams

   };
   this.router.navigate([pageName], navigationExtras);
 }
//Remove machine level in the Options page
removeFromCart(configId,event) {
   if(event)
   {
    event.preventDefault();
   }
  let oParams = {"quoteId":this.quoteId};
    var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
    quoteDetailsPromise =>{
        console.log('getquoteDetails', quoteDetailsPromise);
            var quoteDet = JSON.parse(quoteDetailsPromise.data);
            var configLength = quoteDet.config.length;
            console.log('configLength',configLength);
            this.configquoteService.removeConfig(configId);
           
           const navigationExtras: NavigationExtras = {
            queryParams: oParams
           };
            if(configLength > 1){
	        		this.router.navigate(['/configsummary'], navigationExtras);
	        	}else {
              this.router.navigate(['/createquote'], navigationExtras);
	        	}
         },
        function(reason) {
            console.log(reason);
        });
  }

}
