import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NewPaymentService } from 'src/app/invoices/new-payment/new-payment.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { AutopayService } from '../autopay/autopay.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.css']
})
export class NewPaymentComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  @Output() saveComplete = new EventEmitter<number>()

  pciURL:SafeResourceUrl = ''; 
  achURL:SafeResourceUrl = '';
  activeTab:string = "new";
  ccSequenceNumber:string = '';
  achSequenceNumber:string = '';
  expiryMon:string = '';
  expiryYear:string = '';
  paymentMethod:string = '';
  monthErrMsg:string = '';
  yearErrMsg:string = '';
  showCC:boolean = false;
  saveSuccessMessage:string = '';
  addedPayMsg:string = '';
  accountNumber:string = '';

  constructor(private newPaymentService:NewPaymentService, private sanitizer: DomSanitizer, private autopayService:AutopayService) { }
  private subscription: Subscription;

  ngOnInit() {
    this.loadData();

    this.subscription = this.autopayService.cardObservable$.subscribe(() => {
      this.paymentMethod = 'card';
      $("#ach-select-payment-method").val("card");

    });

    this.subscription = this.autopayService.bankObservable$.subscribe(() => {
      this.paymentMethod = 'bank';
      $("#ach-select-payment-method").val("bank");

    });

  }

  loadData() {
    this.paymentMethod = '';
    let data = this.newPaymentService.getACHInfo().subscribe(
      data=>{
        this.achURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.achUrl);
        this.accountNumber = data.accountNumber;
        this.achSequenceNumber = data.sequenceNumber;
      });

    let data1 = this.newPaymentService.getPCIDetails().subscribe(
      data1=>{
        this.pciURL = this.sanitizer.bypassSecurityTrustResourceUrl(data1.ccDetails.newPCIUrl);
        this.ccSequenceNumber = data1.ccDetails.sequenceNumber;
      }
    );

  }

  tabSelected(tabSelected:string) {
    this.activeTab = tabSelected;
  }

  validatePaymentInfo(type:string) {
		this.blockUI.start();
    console.log('Inside validatePaymentInfo')
    
    var paramObj = {};	
    if (type == 'cc')
      paramObj["sequenceNumber"] = this.ccSequenceNumber;
    else 
      paramObj["sequenceNumber"] = this.achSequenceNumber;

    let validatePaymentInfoPromise = this.newPaymentService.validatePaymentInfo(paramObj).subscribe(
      data=>{
        var sRes = "";
        sRes = data.response.validatePaymentInfo;
        this.blockUI.stop();
 
          if(sRes=="-1"){
            // window closed
          }else if(type=='cc') {	
               console.log('sRes=' + sRes);

            //sRes=0190163817781111:09-2029:VISA                 	
                var expMnth = sRes.split(":")[1];
                
               this.expiryMon = expMnth.charAt(0) + expMnth.charAt(1)
               this.expiryYear = expMnth.charAt(3) + expMnth.charAt(4) + expMnth.charAt(5) + expMnth.charAt(6)

              var ccDetails = {};	
              ccDetails["EXPIRY_MONTH"] = this.expiryMon;
              ccDetails["EXPIRY_YEAR"] = this.expiryYear;
              ccDetails["SEQ_ID"] = this.ccSequenceNumber;

               this.saveCCPayment(ccDetails);
               this.blockUI.stop();
               this.paymentMethod = ''
               //this.tabSelected('save');
            }
      });

  }

  saveCCPayment(ccDetails){
    var paramObj = {};	
    
    //this.showCC = true;
    this.expiryMon = ccDetails.EXPIRY_MONTH;
    this.expiryYear = ccDetails.EXPIRY_YEAR;
    this.ccSequenceNumber = ccDetails.SEQ_ID
    
    paramObj["sequenceNumber"] = this.ccSequenceNumber;
    paramObj["expiryMon"] = this.expiryMon;
    paramObj["expiryYear"] = this.expiryYear;
      
    var qryStr=$.param(paramObj);
    
    let data = this.newPaymentService.saveCCPayment(paramObj).subscribe(
      data=>{
        if(data.status == 'S'){
          this.monthErrMsg = '';
          this.yearErrMsg = '';
          //this.showCC = false;
          this.saveSuccessMessage = 'The payment method is saved successfully.';
          //this.getExistingCCDetailsDetails();
          this.addedPayMsg = 'The payment method is saved successfully.';
          window.scrollTo(0, 0);
        }else{
          this.monthErrMsg = data.monthErrMsg;
          this.yearErrMsg = data.yearErrMsg;
        }  
        this.saveComplete.emit(1); 
        this.newPaymentService.notifyNewPaymentAdded();   
      });

  }

  validatePaymentInfo_ACHAuto() {
    console.log('Validate ACH Auto');
    this.blockUI.start();
    var paramObj = {};	
      paramObj["sequenceNumber"] = this.achSequenceNumber;

      let data = this.newPaymentService.validatePaymentInfoACH(paramObj).subscribe(
        data=>{
          if (data.status == 'success') {
            this.blockUI.stop();
            this.insertACHAutoContDetails();
          }
        });
  

  }
  
  insertACHAutoContDetails() {
    var paramObj = {};	
      paramObj["sequenceNumber"] = this.achSequenceNumber;
      paramObj["accountNumber"] = this.accountNumber;
      paramObj["source"] = "New";
    var qryStr=$.param(paramObj);
    this.callInsertACHAutoContDeets(qryStr);
  }

  callInsertACHAutoContDeets(qryStr) {
    let data = this.newPaymentService.insertACHAutoContDeets(qryStr).subscribe(
      data=>{
        this.blockUI.stop();
        if (data.status == 'S'){
          $('#ach-select-payment-method').prop('selectedIndex', 0);
          this.blockUI.stop();
          this.newPaymentService.notifyNewPaymentAdded();
          //this.tabSelected('save');
          //this.getInitialData();
        }	
        //this.saveComplete.emit(1);
	           		
      });
    
  }

/*   startTimer() {
    setTimeout(() => {
      this.validatePaymentInfo_ACHAuto();
    }, 50000); // 5000 milliseconds = 5 seconds
  } */


  @HostListener('window:message', ['$event'])
  onWindowBlur(event: any): void {
    if(event.data == "Submitted"){		
      this.blockUI.start();
          console.log('Submitted');		
    }else
    if(event.data == "Rejected"){		
      this.blockUI.stop();
          console.log('Rejected');
    }else
    if(event.data == "Accepted"){
          console.log('Accepted');
          this.validatePaymentInfo("cc");
    }else 
    if(event.data == "AcceptedSaveACH") {
          console.log('AcceptedSaveACH');
          //this.validatePaymentInfo("ach");
          this.validatePaymentInfo_ACHAuto();
          //this.startTimer();
    } else 
    if (event.data == "SubmittedSaveACH") {
        console.log('SubmittedSaveACH');
    } 
    else 
    if (event.data == "ErrorSaveACH") {
          console.log('ErrorSaveACH');
          this.blockUI.stop();
    }
  }  

}
