import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TonerReturnHistoryService } from './toner-return-history.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Title } from '@angular/platform-browser';
import { TonerReturnConfirmationService } from '../toner-return-confirmation/toner-return-confirmation.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-toner-return-history',
  templateUrl: './toner-return-history.component.html',
  styleUrls: ['./toner-return-history.component.css']
})
export class TonerReturnHistoryComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  returnList : any[];

  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  start:any=1;
  end:any=10;
  arrPgLinks:any=[];

  showMsg : boolean = false;
  msg : string = "";

  returnDate : Date = null;
  returnNum : string = '';
  referenceNum : string = '';
  custName :  string = '';

  createLabelErrorMsg : string = '';

  constructor(private tonerReturnHistoryService : TonerReturnHistoryService, private commonService:CommonServiceService,
              private titleService: Title, private tonerReturnConfirmationService : TonerReturnConfirmationService) { }

  ngOnInit() {
    this.titleService.setTitle("Toner Return History");
    this.getInitialData();
  }

  getInitialData(){
    this.commonService.setCommonFunctions();
    this.getReturnHistory(1);
  }

  getReturnHistory(pageNum){
    this.blockUI.start();

    this.createLabelErrorMsg = '';
    
    this.returnList = [];

    this.pageNum = pageNum;

    const format = 'dd-MMM-yyyy';
    const locale = 'en-US';
    
    let searchData = { 
      "PAGE_NUM": this.pageNum, 
      "REC_PER_PAGE": this.recPerPage, 
      "RETURN_DATE":(this.returnDate == null ? '' :formatDate(this.returnDate, format, locale)),
      "RETURN_NUMBER":this.returnNum,
      "REFERENCE_NUMBER":this.referenceNum,
      "CUSTOMER_NAME":this.custName,
     };

     this.tonerReturnHistoryService.getReturnHistory(searchData).subscribe(

      data => {

        if(data.status == 'S'){
          this.returnList = data.returnList;  

          this.totalRecords = data.totalRec;

          if(this.totalRecords == 0){
            this.showMsg = true;
            this.msg = "No Records Found";
          }
          
          this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
          this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);


          this.blockUI.stop();
        }else{
          //TODO - Error message
          this.blockUI.stop();
        }
      }
    );
  }

  createShipLabel(returnData){

    this.createLabelErrorMsg = '';

     
    this.blockUI.start();

    let details = { 
      // returnNum: "20487900"
      RETURN_NUMBER: returnData.returnNumber
    };
  
    this.tonerReturnConfirmationService.createShipLabel(details).subscribe(
      data => {
        this.blockUI.stop();
        if(data.status == 'S'){
          //window.location = data.label_url;
          window.open(data.LABEL_URL, '_blank');
        }else{
            this.createLabelErrorMsg = 'Sorry, unable to create shipment label. Please try again later'; 
        }
      });
  }

  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.pageNum = 1;
    this.recPerPage = 10;
    this.returnDate = null;
    this.returnNum = "";
    this.referenceNum = "";
    this.custName = "";

    this.clearMsg();
    this.createLabelErrorMsg = '';

    this.getReturnHistory(1);
  }

  clearMsg() {
		this.showMsg = false;
		this.msg = "";
	}

  exportToExcel(){
    this.tonerReturnHistoryService.exportReturns();
  }
}
