import { Component, OnInit, ViewChild } from '@angular/core';
//import { DashboardService } from '../common/dashboard/dashboard.service';
import { ChartComponent } from 'ng-apexcharts';
//import { ChartOptions } from '../app.component';
import * as ApexCharts from 'apexcharts';
import { ChartOptions } from 'src/app/app.component';
import { DashboardService } from './dashboard.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Util } from 'src/app/app.util';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  @ViewChild("chart1") chart1: ChartComponent;   
  public chartOptions: Partial<ChartOptions>  | any; 
  public chartOptions1: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  public chartOptions3: Partial<ChartOptions> | any;
  public chartOptions4: Partial<ChartOptions> | any;
  public chartOptions5: Partial<ChartOptions> | any;

  uptime: any="";
  downtime: any ="";
  totalDevices: any=0;
  covDv: any=0;
  uncoDe: any=0;
  service_ytd: any="";
  supplies_ytd: any="";
  equipment_ytd: any="";
  totalBalance: any="";
  pastDueBalance: any="";
  lastPaymentReceived: any="";
  res: any ={};
  loadingAppli;
  dType: string = "";
  invoice_access: String ="";
  tableLoader: boolean=true;
  MR_3170: any=[];
  MONTH:any =[];
  BW_AVG_READ: any=[];
  CLR_AVG_READ: any=[];
  MR_7190: any=[];
  MONTH_7190:any =[];
  BW_AVG_READ_7190: any=[];
  CLR_AVG_READ_7190: any=[];
  MR_2130: any=[];
  MONTH_2130:any =[];
  BW_AVG_READ_2130: any=[];
  CLR_AVG_READ_2130: any=[];
  osrtotalRecords: any="";
  osototalRecords: any="";
  oitotalRecords: any="";
  openServiceRequestList: any =[];
  openSupplyOrderList: any =[];
  openInvoiceList: any=[];
  result: any ={};

  monthly: any =[];//new Array();
  mr_2130_available: boolean= false;
  meterRead_20_30_ErrorMsg: string = '';
  recPerPage:any=10;
  pageNum:any=1;
  count:any=0;
  numOfRecsMsg:any="";


  
  

  constructor(private dashboardService: DashboardService,private commonService:CommonServiceService,private util : Util) { }

  ngOnInit() {
    
    this.loaddboard();
    this.loaddboard1();
    this.loaddboard2();

    //add timeout and inside this timeout for 30 seconds call setCommonFunctions function 
    setTimeout( () =>{
    this.commonService.setCommonFunctions();
    console.log("Inside setTimeout..");
     
    },30000);


  }



  loaddboard(){
    this.blockUI.start();
    this.loadingAppli= false;
    let oParams = {
      "dType": 'C'
    };
    
    this.dashboardService.getDashboard(oParams).subscribe(
      (res: any) => {

        for(let i=0; i<res.MR_3170.length; i++){
          this.monthly.push(res.MR_3170[i].MONTH);
          this.BW_AVG_READ.push(res.MR_3170[i].BW_AVG_READ);
          this.CLR_AVG_READ.push(res.MR_3170[i].CLR_AVG_READ);
      }
      


      for(let i=0; i<res.MR_7190.length; i++){
        this.MONTH_7190.push(res.MR_7190[i].MONTH);
        this.BW_AVG_READ_7190.push(res.MR_7190[i].BW_AVG_READ);
        this.CLR_AVG_READ_7190.push(res.MR_7190[i].CLR_AVG_READ);
    }

    for(let i=0; i<res.MR_2130.length; i++){
      this.MONTH_2130.push(res.MR_2130[i].MONTH);
      this.BW_AVG_READ_2130.push(res.MR_2130[i].BW_AVG_READ);
      this.CLR_AVG_READ_2130.push(res.MR_2130[i].CLR_AVG_READ);
  }
      this.res=res;
        this.uptime=res.UP_TIME;
        this.downtime=res.DOWN_TIME;
        this.covDv=res.COVERED_DEVICE;
        this.totalDevices=res.TOTAL_DEVICE;
        this.uncoDe=this.totalDevices-this.covDv;
        this.service_ytd=res.SERVICE_YTD;
        this.supplies_ytd=res.SUPPLIES_YTD;
        this.equipment_ytd=res.EQUIPMENT_YTD;
        if(this.supplies_ytd=="" || this.supplies_ytd == null  || this.supplies_ytd == undefined){
          this.supplies_ytd=0;
        }
        if(this.equipment_ytd=="" || this.equipment_ytd == null || this.equipment_ytd== undefined){
          this.equipment_ytd=0;
        }
        if(this.service_ytd=="" || this.service_ytd == null || this.service_ytd== undefined){
          this.service_ytd=0;
        }
        if(this.res.MR_2130_AVAILABLE == true){
          this.mr_2130_available =true;
          this.meterReadTrend_20_30();
          
        }
        else{
          this.mr_2130_available =false;
          this.meterRead_20_30_ErrorMsg="No Data Available";
        }
        //this.MR_3170=res.MR_3170;

        this.averageTime();
        this.contractcoveredDevices();
        this.SpentYTD();
        this.loadingAppli= true;
        //this.getAverage()
       this.meterReadTrend_30_70();
        //this.meterReadTrend_30_70_1(ar);
        this.meterReadTrend_70_90();
        this.meterReadTrend_20_30();
        this.blockUI.stop();
      },
      (error: any) => {
        
      }
    );
   
   
  }

  loaddboard1(){
    this.blockUI.start();
    this.loadingAppli= false;
    let oParams = {
      "dType": 'T'
    };
    this.dashboardService.getDashboard(oParams).subscribe(
      (res: any) => {
        this.totalBalance=res.TOTAL_BAL;
        this.pastDueBalance=res.PAST_DUE_BAL;
        this.lastPaymentReceived=res.LAST_PAY_DATE;
        this.invoice_access=res.INVOICE_ACCESS;
        this.tableLoader=false;
        this.osrtotalRecords=res.SR.TOTAL_SR_RECORD;
        this.openServiceRequestList= res.SR.SR_LIST;
        this.osototalRecords= res.ORDER.TOTAL_ORDER_RECORD;
        this.openSupplyOrderList= res.ORDER.ORDER_LIST;
        this.blockUI.stop();
      },
      (error: any) => {
        
      }
    );
   
   
  }

  loaddboard2(){
    this.blockUI.start();
    this.loadingAppli= false;
    let oParams = {
      "dType": 'I'
    };
    this.dashboardService.getDashboard(oParams).subscribe(
      (res: any) => {
        this.tableLoader=false;
        this.oitotalRecords=res.INVOICE.TOTAL_INVOICE_RECORD;
        console.log("Printing oitotalRecords value: ", this.oitotalRecords);
        this.openInvoiceList= res.INVOICE.INVOICE_LIST;
        this.count= this.openInvoiceList.length;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
        
        this.blockUI.stop();
      },
      (error: any) => {
        
      }
    );
   
   
  }

  pageChange(){
    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
  }
  

  averageTime(){
    
    this.chartOptions = {
      legend: {
        show:true,
        position: 'bottom'
        // floating: true,
        // verticalAlign: 'bottom',
        // align:'center'
      }, 
      series: [this.uptime,this.downtime],
      chart: {
        type: "pie",
        width: 350
      },
      labels: ["Average Uptime","Average Downtime"],
      colors: ['#00e66f','#ff4da9'], 
      
      responsive: [
        {
          breakpoint: 480,
        
              style: {
                colors: ['#00e66f','#ff4da9']
              }
        }
      ],
    };
  }


  contractcoveredDevices(){
    this.chartOptions1 = {
      series: [parseFloat(this.covDv),parseFloat(this.uncoDe)],
      chart: {
        width: 350,
        type: "pie"
      },
      labels: ["Covered Devices","Not Covered Devices"],
      colors: ['#ff7b00', '#0084ff'],
      legend: {
        show:true,
        position: 'bottom'
        // floating: true,
        // verticalAlign: 'bottom',
        // align:'center'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  SpentYTD(){
    console.log('Printing SpentYTD data: ', this.service_ytd, this.supplies_ytd, this.equipment_ytd);
    this.chartOptions2 = {
      series: [parseFloat(this.service_ytd),parseFloat(this.supplies_ytd),parseFloat(this.equipment_ytd)
        ],//,parseFloat(this.equipment_ytd)
        //series: [this.service_ytd,this.supplies_ytd,this.equipment_ytd
       // ],
      chart: {
        width: 350,
        type: "pie"
      },
      labels: ["Service","Supplies","Equipment"],
      colors: ['#00daff', '#ff4da9', '#0084ff'],
      legend: {
        show:true,
        position: 'bottom'
        // floating: true,
        // verticalAlign: 'bottom',
        // align:'center'
      },
      tooltip:{
        y: {
          formatter: function (val) {
              return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
      }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
   }




meterReadTrend_30_70(){

  for(let i=0;i<12;i++){
    if(this.BW_AVG_READ[i]==undefined || this.BW_AVG_READ[i]==null){
      this.BW_AVG_READ[i]="0";
  
    }

  }

  for(let i=0;i<12;i++){
    if(this.CLR_AVG_READ[i]==undefined || this.CLR_AVG_READ[i]==null){
      this.CLR_AVG_READ[i]="0";
  
    }

  }
  


  for(let i=0;i<12;i++){
    if(this.monthly[i]==undefined){
      this.monthly[i]="";
  
    }

  }

  console.log("Printing this.monthly value: ", this.monthly);
  console.log("Printing inside meterReadTrend_30_70 todisplay all elements of BW: BW_AVG_READ list");
  for (let bwavgRead of this.BW_AVG_READ) {
    console.log(bwavgRead);
  }

  console.log("Printing inside meterReadTrend_30_70 to display all elements of Corlor:  CLR_AVG_READ");
  for (let clravgRead of this.CLR_AVG_READ) {
    console.log(clravgRead);
  }
  console.log("Printing inside meterReadTrend_30_70: BW ", 
  this.BW_AVG_READ[0],this.BW_AVG_READ[1],this.BW_AVG_READ[2],this.BW_AVG_READ[3],this.BW_AVG_READ[4],
        this.BW_AVG_READ[5],this.BW_AVG_READ[6],this.BW_AVG_READ[7],this.BW_AVG_READ[8]
        ,this.BW_AVG_READ[9],this.BW_AVG_READ[10],this.BW_AVG_READ[11]);

  console.log("Printing inside meterReadTrend_30_70: Color ",
  this.CLR_AVG_READ[0],this.CLR_AVG_READ[1],this.CLR_AVG_READ[2],this.CLR_AVG_READ[3],this.CLR_AVG_READ[4],
        this.CLR_AVG_READ[5],this.CLR_AVG_READ[6],this.CLR_AVG_READ[7],this.CLR_AVG_READ[8]
        ,this.CLR_AVG_READ[9],this.CLR_AVG_READ[10],this.CLR_AVG_READ[11]);      
  this.chartOptions3 = {

    series: [
      {
        name: "BW",
        
        data: [this.BW_AVG_READ[0],this.BW_AVG_READ[1],this.BW_AVG_READ[2],this.BW_AVG_READ[3],this.BW_AVG_READ[4],
        this.BW_AVG_READ[5],this.BW_AVG_READ[6],this.BW_AVG_READ[7],this.BW_AVG_READ[8]
        ,this.BW_AVG_READ[9],this.BW_AVG_READ[10],this.BW_AVG_READ[11]
      ]
      },
      {
        name: "Color",
      //  data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      data: [this.CLR_AVG_READ[0],this.CLR_AVG_READ[1],this.CLR_AVG_READ[2],this.CLR_AVG_READ[3],this.CLR_AVG_READ[4],
        this.CLR_AVG_READ[5],this.CLR_AVG_READ[6],this.CLR_AVG_READ[7],this.CLR_AVG_READ[8]
        ,this.CLR_AVG_READ[9],this.CLR_AVG_READ[10],this.CLR_AVG_READ[11]
      ]
      }
    ],
    chart: {
      type: "bar",
      height: 350,
      width:"100%"
    },
  
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
  
    markers: {
      colors: ['#00e66f','#ff4da9']
   },
    stroke: {
      show: true,
      width: 2 //,
     // colors: ["transparent"]
    },
    xaxis: {
      categories: [
       // this.monthly
       this.monthly[0],this.monthly[1],this.monthly[2],this.monthly[3],this.monthly[4],
        this.monthly[5],this.monthly[6],this.monthly[7],this.monthly[8]
       ,this.monthly[9],this.monthly[10],this.monthly[11]
      ]
    },
    yaxis: {
      title: {
        text: " pages"
      }
    },
    // tooltip: {
    //   y: {
    //     formatter: function(val) {
    //       return val + " pages";
    //     }
    //   }
    // }
  };
}






meterReadTrend_70_90(){

  for(let i=0;i<12;i++){
    if(this.BW_AVG_READ_7190[i]==undefined || this.BW_AVG_READ_7190[i]==null){
      this.BW_AVG_READ_7190[i]="0";
  
    }

  }

  for(let i=0;i<12;i++){
    if(this.CLR_AVG_READ_7190[i]==undefined || this.CLR_AVG_READ_7190[i]==null){
      this.CLR_AVG_READ_7190[i]="0";
  
    }

  }

  for(let i=0;i<12;i++){
    if( this.MONTH_7190[i]==undefined){
      this.MONTH_7190[i]="";
    }

  }


  this.chartOptions4 = {
    series: [
      {
        name: "BW",
       // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        data: [this.BW_AVG_READ_7190[0],this.BW_AVG_READ_7190[1],this.BW_AVG_READ_7190[2],this.BW_AVG_READ_7190[3],this.BW_AVG_READ_7190[4],
        this.BW_AVG_READ_7190[5],this.BW_AVG_READ_7190[6],this.BW_AVG_READ_7190[7],this.BW_AVG_READ_7190[8],
        this.BW_AVG_READ_7190[9],this.BW_AVG_READ_7190[10],this.BW_AVG_READ_7190[11]]
      },
      {
        name: "Color",
      //  data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      data: [this.CLR_AVG_READ_7190[0],this.CLR_AVG_READ_7190[1],this.CLR_AVG_READ_7190[2],this.CLR_AVG_READ_7190[3],this.CLR_AVG_READ_7190[4],
        this.CLR_AVG_READ_7190[5],this.CLR_AVG_READ_7190[6],this.CLR_AVG_READ_7190[7],this.CLR_AVG_READ_7190[8],
        this.CLR_AVG_READ_7190[9],this.CLR_AVG_READ_7190[10],this.CLR_AVG_READ_7190[11]]
      
      }
    ],
    chart: {
      type: "bar",
      height: 500,
      width:"100%"
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2 //,
     // colors: ["transparent"]
    },
    xaxis: {
      categories: [
       // this.MONTH
       this.MONTH_7190[0],this.MONTH_7190[1],this.MONTH_7190[2],this.MONTH_7190[3],this.MONTH_7190[4],
        this.MONTH_7190[5],this.MONTH_7190[6],this.MONTH_7190[7],this.MONTH_7190[8],
        this.MONTH_7190[9],this.MONTH_7190[10],this.MONTH_7190[11]
      ]
    },
    yaxis: {
      title: {
        text: ""
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        
      }
    }
  };
}
  

meterReadTrend_20_30(){

  this.chartOptions5 = {
    series: [
      {
        name: "BW",
       // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        data: [this.BW_AVG_READ_2130[0],this.BW_AVG_READ_2130[1],this.BW_AVG_READ_2130[2],this.BW_AVG_READ_2130[3],this.BW_AVG_READ_2130[4],
        this.BW_AVG_READ_2130[5],this.BW_AVG_READ_2130[6],this.BW_AVG_READ_2130[7],this.BW_AVG_READ_2130[8]
        ,this.BW_AVG_READ_2130[9],this.BW_AVG_READ_2130[10],this.BW_AVG_READ_2130[11]
      ]
      },
      {
        name: "Color",
      //  data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      data: [this.CLR_AVG_READ_2130[0],this.CLR_AVG_READ_2130[1],this.CLR_AVG_READ_2130[2],this.CLR_AVG_READ_2130[3],this.CLR_AVG_READ_2130[4],
        this.CLR_AVG_READ_2130[5],this.CLR_AVG_READ_2130[6],this.CLR_AVG_READ_2130[7],this.CLR_AVG_READ_2130[8],
        this.CLR_AVG_READ_2130[9],this.CLR_AVG_READ_2130[10],this.CLR_AVG_READ_2130[11]
      ]
      
      }
    ],
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2 //,
     // colors: ["transparent"]
    },
    xaxis: {
      categories: [
       // this.MONTH
       this.MONTH_2130[0],this.MONTH_2130[1],this.MONTH_2130[2],this.MONTH_2130[3],this.MONTH_2130[4],
        this.MONTH_2130[5],this.MONTH_2130[6],this.MONTH_2130[7],this.MONTH_2130[8],
        this.MONTH_2130[9],this.MONTH_2130[10],this.MONTH_2130[11]
      ]
    },
    yaxis: {
      title: {
        text: ""//$ (thousands)
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        // formatter: function(val) {
        //   return "$ " + val + " thousands";
        // }
      }
    }
  };
}


getAverage(){
  let options = {
    series: [this.uptime, this.downtime],
    labels: ['Average Uptime', 'Average Downtime'],
    colors: ['#00e66f','#ff4da9'],
    chart1: {
        type: 'pie',
        width: '100%'
    },
    noData: {
        text: 'No Data Available',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
    legend: {
        position: 'bottom'
    },
    tooltip: {
        style: {
            fontSize: '18px',
            fontFamily: undefined
        },
    },
    dataLabels: {
        style: {
            fontSize: '16px'
        },
        textAnchor: 'start',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom'
            }
        }
    }]
};
let chart1 = new ApexCharts(document.querySelector("#contractCoveredDevices"), options);
if (this.uptime != 0 && this.downtime !=0) {
  chart1.render();
}
}

nodata(){
  this.meterRead_20_30_ErrorMsg = this.res.MR_2130_AVAILABLE == false ? "No Data Available" : ''
}


getInvPdf(invNumber){
  var url="invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNumber+"&source=ORACLE";
  window.open(this.util.getBaseUrl() + url);
}

// getInvPdfNot (invNum){
//   if ( angular.element('#invoice-payment-trig').length ) {
//    //
//    var url="invoicepdf.htm?mthd=gipfe&invoice_number="+invNum;
//    window.open(url);
//    //hidePleaseWait();
//    }
//  }

}
