import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auto-toner-details-modal',
  templateUrl: './auto-toner-details-modal.component.html',
  styleUrls: ['./auto-toner-details-modal.component.css']
})
export class AutoTonerDetailsModalComponent implements OnInit {
  @Input() serialNum : string
  @Input() pageSource: string
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log('AutoTonerDetailsModalComponent : ' + this.serialNum);
  }

  closeLoginModal() {
    this.activeModal.close();
  }

}
