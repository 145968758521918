import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class SalesRepUserSearchService {

  defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }

     
  getUsersList=function(oParams)
  {
      let url = this.defaultUrl + "salesrep/gsrru";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();

      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getUsersList"))));
    }

    getUserDevicesList=function(oParams)
    {
        let url = this.defaultUrl + "salesrep/gsrrud";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
  
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("getUserDevicesList"))));
      }


}
