import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Util } from '../app.util';
import { CommonServiceService } from '../service/common-service/common-service.service';
import { SalesRepService } from './sales-rep.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-rep',
  templateUrl: './sales-rep.component.html',
  styleUrls: ['./sales-rep.component.css']
})
export class SalesRepComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  @ViewChild('salesRepModal') salesRepTemplate:TemplateRef<any>;
  salesRepModalRef: BsModalRef

  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[];
  acctList:any=[];
  defaultAcct:any={};
  accNum:string="";
  accName:string="";
  showMsg:any=false;
  msg:any="";
  msg_accts_loading="Loading accounts...";
  msg_update_acct="Updating account...";
  
  

  constructor( private salesRepService: SalesRepService, private commonService:CommonServiceService,private util : Util, private modalService: BsModalService, private router:Router) { }

  ngOnInit() {
    this.getSalesRepDefaultAccount();
  }

  getSalesRepAccts (pgNum: any,event: any){
     if(event!=null){
      event.preventDefault();
    }
    this.showMsg=true;
    this.msg=this.msg_accts_loading;
    this.openModel();
    this.blockUI.start();
    this.pageNum=pgNum;
     let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage, 
                                "accNum":this.accNum,"accName":this.accName };
      let data = this.salesRepService.getSalesRepAccts(oParams).subscribe(
        data=>{
            this.acctList = data.acctList; 
            this.totalRecords=data.totalRecords;
            this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
            this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
            this.blockUI.stop();
            this.showMsg=false;
            this.msg="";
        });

    
  }
  getSalesRepDefaultAccount(){
    let data =this.salesRepService.getSalesRepDefaultAccount().subscribe(
        data=>{
           this.defaultAcct=data;
           console.log(this.defaultAcct);
        }
    );
  }

  switchSalesRepAccts(accNum,partyId){
    this.showMsg=true;
    this.msg=this.msg_update_acct;
    let oParams = { "accNum": accNum, "partyId":partyId};
    let data= this.salesRepService.switchSalesRepAccts(oParams).subscribe(
       data=>{ 
           let flg=data.flag;
           
           console.log("flag : "+flg);
            if(flg=="S"){
             //this.router.navigate(['home']);
             this.router.navigate(['home']).then(() => {
              window.location.reload(); 
            });
             this.showMsg=false;
             this.msg="";
           }else{
            this.msg="Error Occured while updating Account. Please select different Account";
           }
          
       });
  }


  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.accName="";
    this.accNum="";
    this.recPerPage="10";
    this.getSalesRepAccts(1,null);
  }


  openModel() {
    this.salesRepModalRef = this.modalService.show(this.salesRepTemplate);
   }
  closeModel() {
    this.salesRepModalRef.hide(); 
  }

}
