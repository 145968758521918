import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserserviceService } from 'src/app/service/common-service/userservice.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  
  userDetailsForm : FormGroup;

  constructor(private formBuilder: FormBuilder, private activeModal: NgbActiveModal,
              private userService : UserserviceService, private router:Router) { }

  ngOnInit() {
    this.userDetailsForm = this.formBuilder.group({
      firstName: [sessionStorage.getItem('firstName'), Validators.required],
      lastName: [sessionStorage.getItem('lastName'), Validators.required],
      phoneNumber: [sessionStorage.getItem('phoneNumber'), [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}$')]]
    })
  }

  closeModal() {
    this.activeModal.close();
    this.router.navigate(['home']); 
  }

  saveUserDetails(){

    

    if (this.userDetailsForm.invalid) {
      // Mark all controls as touched to trigger validation display
      Object.keys(this.userDetailsForm.controls).forEach(key => {
        this.userDetailsForm.get(key).markAsTouched();
      });
      
      //this.userDetailsForm.markAsTouched();
      return;
    }

    this.blockUI.start();

      let param = {
        "firstName": this.userDetailsForm.value.firstName,
        "lastName" : this.userDetailsForm.value.lastName,
        "phoneNumber" : this.userDetailsForm.value.phoneNumber
      }
  
      console.log("param:" + JSON.stringify(param));
  
      this.userService.saveUserDetails(param).subscribe(
        data =>{
  
          //Save the latest data in session
          sessionStorage.setItem('phoneNumber',this.userDetailsForm.value.phoneNumber); 
          sessionStorage.setItem('firstName',this.userDetailsForm.value.firstName); 
          sessionStorage.setItem('lastName',this.userDetailsForm.value.lastName); 

          //this.router.navigate(['home']);
          this.blockUI.stop();
          this.activeModal.close();
          
          
  
        }
      )
    

  }
}
