import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OpenOrderService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }
  
  getOpenSupplyOrders=function(oParams)
  {
      let url = this.defaultUrl + "openSupply/opSupOrdReq?"; // "trackSupplies.htm?mthd=gso?";
      let qryStr=$.param(oParams);  
       url=url+qryStr;
      return this.http
        .post(url,null, httpOptions)
        .pipe(catchError((this.util.handleError("getOpenSupplyRequests"))));
    }
    getOpenSupplyOrdersJSON=function(url)
    {
        return this.http
          .post(url,null, httpOptions)
          .pipe(catchError((this.util.handleError("getOpenSupplyRequests"))));
      }

      getOpenSupplyOrdersJSONNew=function(url)
      {
          // let url = this.defaultUrl + "openSupply/opOrderDetailsExportJSON?"; 
          // let qryStr=$.param(oParams);  
          //  url=url+qryStr;
          return this.http
            .post(url,null, httpOptions)
            .pipe(catchError((this.util.handleError("getOpenSupplyRequests"))));
        }

  getOrderDetails=function(oParams)
  {
      let url = this.defaultUrl + "orderInquiry/orderDetail?"; // "trackSupplies.htm?mthd=gso?";
      let qryStr=$.param(oParams);  
       url=url+qryStr;
      return this.http
        .post(url,null, httpOptions)
        .pipe(catchError((this.util.handleError("getOrderDetails"))));
    }

    getSupplyOrderItems=function(oParams)
    {
        let url = this.defaultUrl + "openSupply/opSupplyOrderItems?"; // trackSupplies.htm?mthd=gsoi;
        let qryStr=$.param(oParams);  
         url=url+qryStr;
        return this.http
          .post(url,null, httpOptions)
          .pipe(catchError((this.util.handleError("getCloseSupplyRequests"))));
      } 
      
      
      getUsers=function()
      {
          let url = this.defaultUrl + "openSupply/opSupOrdDrpDwn"; // trackSupplies.htm?mthd=gddj;

          return this.http
            .get(url, httpOptions)
            .pipe(catchError((this.util.handleError("getUsers"))));
        }  


}
