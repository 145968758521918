import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractSuppliesService } from '../contract-supplies.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-supplies-shipping-address',
  templateUrl: './order-supplies-shipping-address.component.html',
  styleUrls: ['./order-supplies-shipping-address.component.css']
})
export class OrderSuppliesShippingAddressComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  selectedItemArray : any[] = [];
  cartExpireDate : any
  serialNumber : string
  shipAddrDetails : string[]
  locationName : string
  partySiteId : string
  shpMthd : string
  shpMthdCd : string
  attenLbl : string = '';
  errMsg :  string
  locationsArray : any[]

  //Search related
  search: string;
  searchFilterPropertyNames : string[] = ['address'];

  
  constructor(private datePipe: DatePipe, private router: Router,
      private csService : ContractSuppliesService, private commonService:CommonServiceService) { }

  ngOnInit() {
    this.getInitialData();
  }

  getInitialData(){

    this.blockUI.start();

    let currentDate = new Date();
    currentDate.setDate( currentDate.getDate() + 1 );
    this.cartExpireDate = this.datePipe.transform(currentDate, 'dd-MMM-yyyy');

    this.commonService.setMoreInfo();

    let userName = sessionStorage.getItem('userName');
    let itemDetailsKey = userName + '__ItemDetail'
    let itemDetails = localStorage.getItem(itemDetailsKey);

    if(itemDetails !== null){
      this.selectedItemArray = JSON.parse(itemDetails);
    }

    if(this.selectedItemArray.length == 0){
      this.router.navigate(['ordersupplies']);
    }else{
      this.serialNumber = this.selectedItemArray[0].serialNumber;
    }

    let shipParam = {
      "serialNumber" :  this.serialNumber
    }
    
    /* forkJoin(
      this.csService.getShipToSite(shipParam),
      this.csService.getAttnLbl(shipParam)
    ).pipe(
      map(([shipData, attnData]) => {
        this.processShipData(shipData);
        this.processAttnLabelData(attnData);
        this.blockUI.stop();

      } 
      )
    ) */

    this.csService.getShipToSite(shipParam).subscribe(
      data => {
        this.shipAddrDetails = data;

        if(this.shipAddrDetails != null){

          let shipAddrInTheSession = localStorage.getItem(userName + '__ShipAddrs');
          
          if (shipAddrInTheSession === null || shipAddrInTheSession === undefined || shipAddrInTheSession === "" || 
              (shipAddrInTheSession != null && (shipAddrInTheSession.split('|')[0].trim() == "" || shipAddrInTheSession.split('|')[0] == "null"))) {	
            this.locationName = this.shipAddrDetails[1];
            this.partySiteId = this.shipAddrDetails[0];
          } else if (shipAddrInTheSession !== null) {
            try{
              var tempArray = shipAddrInTheSession.split('|');
              var locName = tempArray[0];
              var locId = tempArray[1];
              this.locationName = locName;
              this.partySiteId = locId;
            } catch (e) {
              this.locationName = this.shipAddrDetails[1];
              this.partySiteId = this.shipAddrDetails[0];
            }
          }						 
          this.shpMthd = this.shipAddrDetails[2];
          this.shpMthdCd = this.shipAddrDetails[3];

          this.blockUI.stop();
       }
      }
    )


    this.csService.getAttnLbl(shipParam).subscribe(
      data => {
        
        if(data != null){
          var attnLblInTheSession = localStorage.getItem(userName + '__AttnLbl');
          
          if (attnLblInTheSession === null || attnLblInTheSession === undefined || attnLblInTheSession === "" ) {								    
            this.attenLbl = data.attnLbl;
          } else if (attnLblInTheSession !== null) {
            try{									 
              this.attenLbl = attnLblInTheSession;
            } catch (e) {									
              this.attenLbl = data.attnLbl;
            }
          }					 
        }  
      }
    )
    
  }

  processShipData(data){
    this.shipAddrDetails = data;

    let userName = sessionStorage.getItem('userName');

    if(this.shipAddrDetails != null){

      let shipAddrInTheSession = localStorage.getItem(userName + '__ShipAddrs');
      
      if (shipAddrInTheSession === null || shipAddrInTheSession === undefined || shipAddrInTheSession === "" || 
          (shipAddrInTheSession != null && (shipAddrInTheSession.split('|')[0].trim() == "" || shipAddrInTheSession.split('|')[0] == "null"))) {	
        this.locationName = this.shipAddrDetails[1];
        this.partySiteId = this.shipAddrDetails[0];
      } else if (shipAddrInTheSession !== null) {
        try{
          var tempArray = shipAddrInTheSession.split('|');
          var locName = tempArray[0];
          var locId = tempArray[1];
          this.locationName = locName;
          this.partySiteId = locId;
        } catch (e) {
          this.locationName = this.shipAddrDetails[1];
          this.partySiteId = this.shipAddrDetails[0];
        }
      }						 
      this.shpMthd = this.shipAddrDetails[2];
      this.shpMthdCd = this.shipAddrDetails[3];

   }
  }

  processAttnLabelData(data){
    let userName = sessionStorage.getItem('userName');

    if(data != null){
      var attnLblInTheSession = localStorage.getItem(userName + '__AttnLbl');
      
      if (attnLblInTheSession === null || attnLblInTheSession === undefined || attnLblInTheSession === "" ) {								    
        this.attenLbl = data.attnLbl;
      } else if (attnLblInTheSession !== null) {
        try{									 
          this.attenLbl = attnLblInTheSession;
        } catch (e) {									
          this.attenLbl = data.attnLbl;
        }
      }					 
    }  
  }
  onPrint(){
		window.print();
	}

  checkSpecialChars(){
		try {
      var str="";
			str = this.attenLbl;
			if(str && str.length>0){
		 	  str=str.replace(/[^a-zA-Z0-9 \-']/gi, "");          
			  this.attenLbl = str;			 
			}
		}catch (e) {
      console.log(e.message);
		}
	}

  checkOutCart(){

		this.errMsg = "";
	
    let attnLbl = this.attenLbl;
		let locationName = this.locationName;

    let userName = sessionStorage.getItem('userName');

		if(attnLbl == null ||  (attnLbl != null && attnLbl.trim().length == 0)){
			this.errMsg = "'Attn Label' is mandatory for prompt delivery of your supplies.";
			if(localStorage.getItem(userName + '__AttnLbl') !== null){
				localStorage.removeItem(userName + '__AttnLbl');
			} 
		}else if(locationName == null || (locationName != null && locationName.trim().length == 0) || locationName == "null" )	{
			this.errMsg = "'Ship To' is mandatory for delivery of your supplies. Please select 'Ship To' using the 'Change Ship To' hyperlink.";
			if(localStorage.getItem(userName + '__ShipAddrs') !== null){
				localStorage.removeItem(userName + '__ShipAddrs');
			} 
		}else{
			localStorage.setItem(userName + '__AttnLbl', attnLbl);
			
			if(localStorage.getItem(userName + '__ItemDetail') !== null){
				this.selectedItemArray = JSON.parse(localStorage.getItem(userName + '__ItemDetail'));
			}			
		
			if( this.selectedItemArray.length !== 0 ){
				var s = JSON.stringify( this.selectedItemArray );
				//console.log( s );
				localStorage.setItem(userName + '__ItemDetail', s);
				var shipAddrs = this.locationName + '|' + this.partySiteId;
				localStorage.setItem(userName + '__ShipAddrs', shipAddrs);
        
        let params = {
          "shpMthd": this.shpMthd 
        }

        this.router.navigate(['osreview'], {queryParams : params});
			}
		}
	}

  continueShop(){
		this.errMsg = "";

    let userName = sessionStorage.getItem('userName');

		var attnLbl = this.attenLbl;

    if(attnLbl == null ||  (attnLbl != null && attnLbl.trim().length == 0)){
			this.errMsg = "'Attn Label' is mandatory for prompt delivery of your supplies.";
		}else{
			localStorage.setItem(userName + '__AttnLbl', attnLbl);
			/* setInStorage(userId + '__PoNum', poNum); */

			if(localStorage.getItem(userName + '__ItemDetail') !== null){
				this.selectedItemArray = JSON.parse(localStorage.getItem(userName + '__ItemDetail'));
			}	
			
			if(this.selectedItemArray.length !== 0 ){
				var s = JSON.stringify( this.selectedItemArray );
				localStorage.setItem(userName + '__ItemDetail', s);						
				var shipAddrs = this.locationName + '|' + this.partySiteId;
				localStorage.setItem(userName + '__ShipAddrs', shipAddrs);

        this.router.navigate(['ordersupplies']);
			}
		}
	}


  emptyCartConfirm() {
    let userName = sessionStorage.getItem('userName');

		localStorage.removeItem(userName + '__ItemDetail');
		localStorage.removeItem(userName + '__MachDetail');
		localStorage.removeItem(userName + '__AttnLbl');
		localStorage.removeItem(userName + '__ShipAddrs');

		this.router.navigate(['ordersupplies']);
	}

  getShipToLocations(){
    this.blockUI.start();
    this.csService.getShipToLocations().subscribe(
      data => {
        this.locationsArray = data.data;
        this.blockUI.stop();
      }
    )
  }

  selectAddress(loc){
    this.locationName = loc.address;
    this.partySiteId = loc.partySiteId;
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
