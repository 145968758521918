import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-user-two-factor',
  templateUrl: './user-two-factor.component.html',
  styleUrls: ['./user-two-factor.component.css']
})
export class UserTwoFactorComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  errMsg: string = "";
  userAuthCode: string = "";
  errorMessage = '';  
  radBtnSelect=''; 
  username: string = "";
  invalidLogin = false;
  showLoader :boolean =false;
  loggedUserName = '';
  authCodeErrMsg = '';
  showDidNotGetCode:boolean = true;
  visible:boolean = false
  authCodeForm: FormGroup;
  emailAddr: string = '';
        
  constructor(
    private loginAuthenticationService: LoginAuthenticationService,
    private router: Router) { }

 
  ngOnInit() {
    this.errorMessage = '';
    this.radBtnSelect = '';
    let salesRep = sessionStorage.getItem("salesTeamFlag");

    if(salesRep  === "Y"){
      this.emailAddr = sessionStorage.getItem("email");
    }else{
      this.emailAddr = this.loginAuthenticationService.getAuthenticatedUser();
    }
    this.loggedUserName = this.loginAuthenticationService.getAuthenticatedUser();
    this.authCodeForm = new FormGroup({
      uAuthCode: new FormControl()
     
    });    
    this.authCodeForm.setValidators([Validators.required]);
         window.history.pushState(null, null, window.location.href);
         window.onpopstate = function () {
         window.history.go(1);
       };
       let tfaSuccess =sessionStorage.getItem("tfaSuccess");
       if(!tfaSuccess)
       {
       this.getTwoFactorData();
       }
       
  }
  getTwoFactorData() {
    this.loginAuthenticationService.twoFactorAuthentication() .subscribe(
      dataAuthCode => {   
        var tfaSuccess = dataAuthCode.tfaSuccess;
        this.blockUI.stop(); 
        if(tfaSuccess == 'N'){
         sessionStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
         sessionStorage.setItem("otpRequestTime",  dataAuthCode.otpRequestTime);                  
             // this.router.navigate(['twoFactor']);
              //this.closeLoginModal(this.loggedUserName);        
            //  sessionStorage.setItem("route", data.route);   
        }else{                         
               // this.userIdle.startWatching();                    
               // this.closeLoginModal(this.loggedUserName);                       
                this.router.navigate(['home']);                   
                 
        }
      }
     );
    }
  verifyTwoFactorAuthCode(form: NgForm, e) { 
    if ( form.invalid) {
      return;
   }   
   
   this.blockUI.start();
      this.username =  this.loggedUserName;
      var oData = {
        "userAuthCode":	this.userAuthCode.trim(),
        "twoFactorAuthCode":sessionStorage.getItem("twoFactorAuthCode"),
        "radBtnSelect" :this.radBtnSelect,
        "otpRequestTime":sessionStorage.getItem("otpRequestTime")    			
      }

      this.loginAuthenticationService.verifyTwoFactorAuthCode(oData).subscribe
      (data => { 
            var errFlg = data.errFlg;          
            if(errFlg == "E"){
              this.blockUI.stop();   
              this.userAuthCode = '';   
              this.errorMessage = " Verification code expired. Please click on Resend Code for a new verification code.";   
              localStorage.removeItem("twoFactorAuthCode");
              localStorage.removeItem("otpRequestTime");   
            }else if(errFlg == "Y"){       
              this.blockUI.stop();         
              this.userAuthCode = '';     
              this.errorMessage = "Incorrect verification code. Please enter the correct verification code.";             
            }else{ 
             // this.route ="home";
              this.router.navigate(["home"]);
              this.blockUI.stop();
               
          }     
      })    
  }

  yesRegisterDevice(){   
    this.radBtnSelect = "Yes";  
    
  }

  noRegisterDevice(){   
    this.radBtnSelect = "No";  
    
  }

  numberValdtn(){
    this.userAuthCode = 	this.userAuthCode.trim().replace(/[^0-9]/g,''); 
  }

  backToLoginPage(){
    this.errorMessage = '';
    this.loginAuthenticationService.logout();   
  }

  toggle(){
    var didntGetTheCodeBtn = document.querySelector("#secure-2-auth-more-info-btn");
    var moreInfoSection = document.querySelector("#secure-2-auth-more-info-content-section");
    moreInfoSection.classList.toggle("secure-hidden");    
  }

  resendVerCode(){ 
    this.radBtnSelect = '';
    this.userAuthCode = '';
        this.loginAuthenticationService.generateVerCodeAndSendEmail() .subscribe(
              dataAuthCode => {   
                  this.blockUI.stop();
                  //localStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
                 // localStorage.setItem("otpRequestTime",  dataAuthCode.otpRequestTime);
                  sessionStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
                  sessionStorage.setItem("otpRequestTime",  dataAuthCode.otpRequestTime); 
                  this.errorMessage = '';   
                  var errFlg = dataAuthCode.errFlg;          
                  if(errFlg == "Y"){                  
                    this.errorMessage = "Error in sending the Verification code email.";
                  }      
              },
              error => {
              console.log(error);    
              }
        );
  }


 
  }

  


