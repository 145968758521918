import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class RemoteSupportService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


   getdefaultreqhandler = function(input){

    let url = this.defaultUrl + "rmtsupport/defaultreqhandler"; 
    
    console.log("Printing inside getUrl method...");
    console.log(url);
    return this.http
    .post(url,input, httpOptions)
    .pipe(catchError((this.util.handleError("getData"))));
	}

   

  postUpdatelaunch=function(sNumber, process,ref1, ref2 ){
    let url = this.defaultUrl + "rmtsupport/updatelaunch"; 
    var data:any = {};
    	data.sNumber = sNumber;
    	data.process = process;
      data.ref1 = ref1;
      data.ref2 = ref2;
    	// var productDetailsPromise = httpService.postJsonData('products.htm/gpcd',data);
     	// return productDetailsPromise;
    return this.http
    .post(url,data, httpOptions)
    .pipe(catchError((this.util.handleError("postUpdatelaunch"))));
	}






  


}
