import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { formatDate } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';
import { Util } from 'src/app/app.util';
import { CsaInvoicePaymentService } from './csa-invoice-payment.service';
import { SubmitPaymentComponent } from '../submit-payment/submit-payment.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SubmitPaymentService } from '../submit-payment/submit-payment.service';

@Component({
  selector: 'app-csa-invoice-payment',
  templateUrl: './csa-invoice-payment.component.html',
  styleUrls: ['./csa-invoice-payment.component.css']
})
export class CsaInvoicePaymentComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  numOfRecsMsg:string="";
  invoicePaymentList:any=[];
  selectedInvoiceList:any=[];
  arrPgLinks:any=[];
  userAccountList:any=[];
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  start:number=1;
  end:number=10;
  selectedUserAccts:string = "";
  invoiceNum:string = "";
  urnNumber:string = "";
  receiptNumber:string = "";
  orderNum:string = "";
  serialEqpNum:string = "";
  billToAccNum:string = "";
  billToAddress:string = "";
  installAddress:string = "";
  transactionFromDate:Date = new Date();
  transactionToDate:Date = new Date();
  dueFromDate:Date = null;
  dueToDate:Date = null;
  transTypeSearch:string = "";
  tranStatusSearch:string = "";
  sDocType:string = "";
  status:string = null;
  selectedInvoice:any = '';
  target:any = '';
  noneSelected:boolean = false; 
  showMsg : boolean = false;
  msg : string = "";

  @ViewChild('autoPayModal') autoPayModalTemplate:TemplateRef<any>;
  autoPayModal: BsModalRef;

  constructor(private commonService:CommonServiceService, private invoicePaymentService: CsaInvoicePaymentService,
              private util : Util,  
              private modalService: BsModalService, private titleService: Title, 
              private ngbModalService: NgbModal, private submitPaymentService : SubmitPaymentService) { 

  }

  ngOnInit() {
    this.titleService.setTitle("CSA Invoice Payment");

    this.blockUI.start();

    const twoYearFromNow = new Date();
    twoYearFromNow.setFullYear(twoYearFromNow.getFullYear() - 2);

    this.transactionToDate = new Date();
    this.transactionFromDate = twoYearFromNow;

    this.commonService.getUserAccounts().subscribe(
    data=>{
      this.userAccountList = data.data;
      this.blockUI.stop();
      this.getInvoicePaymentRequest(1, null);
    });

    this.commonService.setCommonFunctions();

    this.submitPaymentService.makePaymentModalClosedObservable$.subscribe(() => {
      this.resetValues();
      //this.getInvoicePaymentRequest(1, null);
    })
  }

  exportToExcel(event:any){
    if(event){
      event.preventDefault();
    }
    let url="invoicePayment/invoicePaymentExport";
    const format = 'dd-MMM-yyyy';
    const locale = 'en-US';

    let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.invoiceNum, "urnNumber" : this.urnNumber, 
              "receiptNumber" : this.receiptNumber, "contractNumber" : this.orderNum, "serialNumber": this.serialEqpNum, 
              "billToAccNumber" : this.billToAccNum, "billToLocation" : this.billToAddress, "installLocation" : this.installAddress,
              "invDateFrom" : (this.transactionFromDate == null ? '' :formatDate(this.transactionFromDate, format, locale)), "invDateTo" : (this.transactionToDate == null ? '' : formatDate(this.transactionToDate, format, locale)), 
              "dueDateFrom" : (this.dueFromDate == null ? '' :formatDate(this.dueFromDate, format, locale)),  "dueDateTo" : (this.dueToDate == null ? '' : formatDate(this.dueToDate, format, locale)), 
              "invType" : this.transTypeSearch, "tranStatus" : this.tranStatusSearch, 
              "sDocType" : this.sDocType == '' ? 'ALL' : this.sDocType,
              "pageNum":this.pageNum, "recsPerPage":this.recPerPage }; 

    let qryStr="";
    for (let entry in oParams) {
      qryStr += entry + '=' + encodeURIComponent(oParams[entry]) + '&';
    }
    qryStr = qryStr.substring(0, qryStr.length-1); // remove last '&'

    this.commonService.exportToExcel(url+"?"+qryStr);

  }

  resetValues() {
    this.numOfRecsMsg="";
    this.invoicePaymentList=[];
    this.selectedInvoiceList=[];
    this.arrPgLinks=[];
    //this.userAccountList=[];
    this.pageNum=0;
    this.totalRecords=0;
    this.recPerPage=10;
    this.start=1;
    this.end=10;
    this.selectedUserAccts = "";
    this.invoiceNum = "";
    this.urnNumber = "";
    this.receiptNumber = "";
    this.orderNum = "";
    this.serialEqpNum = "";
    this.billToAccNum = "";
    this.billToAddress = "";
    this.installAddress = "";
    this.transactionFromDate = new Date();
    this.transactionToDate = new Date();
    this.dueFromDate = null;
    this.dueToDate = null;
    this.transTypeSearch = "";
    this.tranStatusSearch = "";
    this.sDocType = "";
    this.status = null;
    this.selectedInvoice = '';
    this.target = '';
    this.noneSelected = false; 

    const twoYearFromNow = new Date();
    twoYearFromNow.setFullYear(twoYearFromNow.getFullYear() - 2);
    this.transactionToDate = new Date();
    this.transactionFromDate = twoYearFromNow;
  
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }

    this.clearMsg();
    this.getInvoicePaymentRequest(1, null);

  }

  getInvoicePaymentRequest(pgNum: any,event: any){
    if(event!=null){
      event.preventDefault();
    }
    this.blockUI.start();
    this.clearMsg();
    const format = 'dd-MMM-yyyy';
    const locale = 'en-US';
    
    this.pageNum=pgNum;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("pageNum :"+pgNum);
    console.log("recPerPage :"+this.recPerPage);
    let oParams = { "userAccts":this.selectedUserAccts, "invNumber" : this.invoiceNum, "urnNumber" : this.urnNumber, 
              "receiptNumber" : this.receiptNumber, "contractNumber" : this.orderNum, "serialNumber": this.serialEqpNum, 
              "billToAccNumber" : this.billToAccNum, "billToLocation" : this.billToAddress, "installLocation" : this.installAddress,
              "invDateFrom" : (this.transactionFromDate == null ? '' : formatDate(this.transactionFromDate, format, locale)), "invDateTo" : (this.transactionToDate == null ? '' : formatDate(this.transactionToDate, format, locale)), 
              "dueDateFrom" : (this.dueFromDate == null ? '' :formatDate(this.dueFromDate, format, locale)),  "dueDateTo" : (this.dueToDate == null ? '' : formatDate(this.dueToDate, format, locale)), 
              "invType" : this.transTypeSearch, "tranStatus" : this.tranStatusSearch, 
              "sDocType" : this.sDocType == '' ? 'ALL' : this.sDocType,
              "pageNum":this.pageNum, "recsPerPage":this.recPerPage };
    
    this.invoicePaymentService.getInvoiceList(oParams).subscribe(
    results=>{
      this.invoicePaymentList = results.data ? results.data : []; 
      this.totalRecords = results.noOfRecs ? results.noOfRecs : 0;

      if(this.totalRecords == 0){
        this.showMsg = true;
        this.msg = "No Records Found";
      }
      this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
      this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
      this.blockUI.stop();
    });
  }

  filterSelected(invoice:any, event:any) {
    var paramObj = {};	
    paramObj["invoiceNumber"] = invoice.invNumber;
    this.selectedInvoice = invoice; 
    
    var qryStr=$.param(paramObj);
    this.target = event.target;
    if (event.target['checked'] == true) {
      invoice.selected = true;
    
      /* this.invoicePaymentService.getAutoPayStsFlag(qryStr).subscribe(
      data=>{
        if (data.status == 'Y') {
          this.openModal();
        }
      }); */
    
    }else {
      invoice.selected = false; 
    }
    
    this.selectedInvoiceList = this.invoicePaymentList.filter(function(tag) {
    return tag.selected == true;
    }); 
  }

  getInvPdf(invNumber){
    var url="invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNumber+"&source=ORACLE";
    window.open(this.util.getBaseUrl() + url);
  }


  downloadPdf (inv){ 
    let source = inv.source;
    let invoiceType = inv.invoiceType;
    let invNum = inv.invNumber;
    let fileId = inv.fileId;
    let pathWithFile = inv.pathWithFile;
    let fileName = inv.fileName;
    
  if(source == 'S21' || source == 's21'){
    if(invoiceType == 'MSB'){
      window.open('invoicePdf/getInvoicePdfExcelForEmanage?invoice_number='+invNum);
    }
    else if (invoiceType == 'SB'){
      var excelUrl = "invoicePdf/getInvoiceExcelForEmanage?invoice_number="+invNum+"&invoiceType="+invoiceType+"&fileId="+fileId+"&pathWithFile="+pathWithFile+"&source="+source+"&fileName="+fileName;
            window.open(excelUrl);				
    }else if (invoiceType != 'MSB' && invoiceType != 'SB')
        {
           if (fileId != null)
             {
        window.open("invoicePdf/getInvoicePdfForEmanage?invoice_number="+invNum+"&invoiceType="+invoiceType+"&fileId="+fileId+"&pathWithFile="+pathWithFile+"&source="+source+"&fileName="+fileName);
            }	            
         } 
  }                             		                           	
}

  closeModal() {
    this.autoPayModal.hide();
  }

  cancelModal() {
    this.target['checked'] = false; 
    this.invoicePaymentList.forEach((payment) => {
      if (payment.invNumber == this.selectedInvoice.invNumber) {
        payment.selected = false; 
        this.selectedInvoiceList = this.selectedInvoiceList.filter(item => item.invNumber !== payment.invNumber);
      }
    });
    
    this.closeModal();
  }

  confirm() {
    this.closeModal();
  }

  openModal() {
    this.autoPayModal = this.modalService.show(this.autoPayModalTemplate);
  }

  openMakePaymentModal(){
    if (this.selectedInvoiceList.length <= 0) {
      this.noneSelected = true;
      return;
    } else {
      this.noneSelected = false;
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        windowClass: 'scrollable-modal',
        size: <any>'xl' 
      };
      const makePaymentModalRef = this.ngbModalService.open(SubmitPaymentComponent, ngbModalOptions);
      makePaymentModalRef.componentInstance.selectedInvoiceList= this.selectedInvoiceList;
    }
  }

  clearMsg() {
		this.showMsg = false;
		this.msg = "";
	}

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}
