import { Component, OnInit } from '@angular/core';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-configurator-confirmation',
  templateUrl: './configurator-confirmation.component.html',
  styleUrls: ['./configurator-confirmation.component.css', '../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorConfirmationComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  totalRecords = 0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any = [];
  myBuild = {} as any;
  configId: number = -1;
  quoteId: number;
  imgUrl = "https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any = [];
  productMaintenance;
  maintenanceAmt;
  showShippingBtn = false;
  showColorDetails = true;
  deviceDetails = {};

  //Get Id from cart
  listAccessory = [];
  listAccessoryType = [];
  localOptions = {};
  servicePlan = {} as any;
  selectedTermRange = {} as any;
  address = {} as any;
  scEmail = '';
  returns = '';
  configLength: any;
  quoteStatusVal: any;
  quoteNumber: any;
  quoteDate: any;
  quoteUserEmail: any;
  attachments: any;
  configMap = {} as any;
  buildSummary = {} as any;
  successTextAlert: string;
  showSuccessAlert: boolean;
  quoteName: any;
  successReturnTextAlert: string;
  showReturnSuccessAlert: boolean;
  failureReturnTextAlert: string;
  showFailureReturnAlert: boolean;
  sOptionsArray: [string, unknown][];
  userName: string ="";

  // Accessories in cart 
  constructor(private configquoteService: ConfiguratorQuotesService, private route: ActivatedRoute,private titleService: Title,private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Confirmation");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
    });
     this.userName =sessionStorage.getItem("fullName");
   this.getQuoteDetails( this.quoteId);
  }

  isOptionExist(options) {
    var optionExist = false;

    if (options != null && options != 'undefined') {
      for (var option in options) {
        optionExist = true;
        break;
      }
    }
    return optionExist;
  }
  isMaintenanceDeclined = function (configId) {
    var flag = true;
    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        flag = true;
      } else {
        flag = false;
      }
    }

    return flag;
  }
  getQuoteDetails(quoteId) {
    let oParams = { "quoteId": quoteId};
    var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams, 'getQuoteConfigDetails').subscribe(
      quoteDetailsPromise => {
        console.log('getquoteDetails', quoteDetailsPromise.data);
        let quoteDetails = JSON.parse(quoteDetailsPromise.data);
        this.configLength = quoteDetails.config.length;
        let quoteStatus = quoteDetails.quoteStatus;
        this.quoteStatusVal = quoteDetails.quoteStatus;
        this.quoteNumber = quoteDetails.quoteNumber;
        this.quoteDate = quoteDetails.quoteDate;
        this.quoteUserEmail = quoteDetails.quoteUserEmail;
        this.returns = quoteDetails.returns;
        this.attachments = quoteDetails.attachments;
        this.configMap = {} as any;
        for (var c in quoteDetails.config) {
          this.configMap[quoteDetails.config[c].configId] = quoteDetails.config[c];

          this.configMap[quoteDetails.config[c].configId].imgSrc = this.imgUrl + quoteDetails.config[c].model + ".jpg";
         
          for (var s in quoteDetails.shipping) {
            console.log('Shipping:',quoteDetails.config[c].configId);            
            if (quoteDetails.shipping[s].configId == quoteDetails.config[c].configId) {
              console.log('Shipping:ConfigId',quoteDetails.shipping[s].configId);
              this.configMap[quoteDetails.config[c].configId].shipping = [] as any;
              this.configMap[quoteDetails.config[c].configId].shipping[0] = quoteDetails.shipping[s];
              console.log('Shipping:Shipping',quoteDetails.shipping[s]);
            }
          }

          this.configMap[quoteDetails.config[c].configId].options = {} as any;

          for (var o in quoteDetails.options) {
            //console.log('value:',quoteDetails.config[c].configId);
            if (quoteDetails.options[o].configId == quoteDetails.config[c].configId) {
              if (this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType]) {
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = quoteDetails.options[o];
              }
              else {
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = [];
                this.configMap[quoteDetails.config[c].configId].options[quoteDetails.options[o].optionType] = quoteDetails.options[o];
              }
            }

          }


            // Initialize maintenance object
            this.configMap[quoteDetails.config[c].configId].maintenance = [] as any;
          for (var key in quoteDetails.maintenance) {
            if (quoteDetails.maintenance[key].configId == quoteDetails.config[c].configId) {             
              this.configMap[quoteDetails.config[c].configId].maintenance[key] = quoteDetails.maintenance[key];
            }
          }

        }
        console.log('this.configMap', this.configMap);
        //this.buildSummary = this.configMap;
       // this.buildSummary = {};

        this.myBuild = JSON.parse(sessionStorage.getItem('myBuild'));

       // this.buildSummary = this.configMap;
        this.buildSummary = Object.values(this.configMap);

        console.log('buildSummary', this.buildSummary);

      }
    );
  }
  getOptionTotal(configId) {
    var total = 0;
    for (var optionType in this.configMap[configId].options) {
      for (var i in this.configMap[configId].options[optionType]) {
        total = total + parseFloat((this.configMap[configId].options[optionType][i].price * this.configMap[configId].configQty).toString());
      }

    }
    total += parseFloat((this.configMap[configId].amount * this.configMap[configId].configQty).toString());
    return total;

  }
  getMaintenanceTotal = function (configId) {
    var total = 0;
    var totalMaint = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else {

        total = total + parseFloat(parseFloat(this.configMap[configId].maintenance[maint].baseRate) + (parseFloat(this.configMap[configId].maintenance[maint].avgVolUser) * parseFloat(this.configMap[configId].maintenance[maint].cpcRate)).toString());
        totalMaint = total * parseInt(this.configMap[configId].maintenance[maint].termUser);
      }

    }

    return totalMaint;
  }

  getBaseRate(configId) {
    var total = 0;
    var maintenance = this.configMap[configId].maintenance;
    if (maintenance != null && maintenance != 'undefined') {
      for (var maint in maintenance) {
        //console.log('maint',this.configMap[configId].maintenance[maint]);
        if (maintenance[maint].isRejected == undefined || maintenance[maint].isRejected == 'Y') {
          return total;
        } else {
          total = total + parseFloat(maintenance[maint].baseRate);
        }
      }
      return total;
    }
  }

  getColorClick(configId) {
    var total = 0;
    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else if (this.configMap[configId].maintenance[maint].meterType == "Color") {

        total = total + parseFloat(this.configMap[configId].maintenance[maint].cpcRate);
      }

    }

    return total;
  }


  getBwClick = function (configId) {
    var total = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return total;
      } else if (this.configMap[configId].maintenance[maint].meterType == "Black") {

        total = total + parseFloat(this.configMap[configId].maintenance[maint].cpcRate);
      }

    }

    return total;
  }

  getMaintenanceTerm = function (configId) {

    var term = 0;

    for (var maint in this.configMap[configId].maintenance) {
      //console.log('maint',this.configMap[configId].maintenance[maint]);
      if (this.configMap[configId].maintenance[maint].isRejected == undefined || this.configMap[configId].maintenance[maint].isRejected == 'Y') {
        return term;
      } else {

        term = this.configMap[configId].maintenance[maint].termUser;
      }

    }

    return term + ' Months';
  }
  createQuoteRedirect(event){
    if(event){
      event.preventDefault();
    }
    let oParams = { "quoteId": -1};
    const navigationExtras: NavigationExtras = {
      queryParams: oParams

    };
    this.router.navigate(['/createquote'], navigationExtras);

  }

  getQuoteListingPage(){
    this.router.navigate(['/myquotes']);
  }
}
