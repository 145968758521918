import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OtherCanonProductsCheckoutService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


   getShipping=function(){
    let url = this.defaultUrl + "productsCheckout/gsal"; // "productsCheckout.htm/gsal;
    return this.http
    .get(url, httpOptions)
    .pipe(catchError((this.util.handleError("getData"))));
	}

  getBillingLocations=function(){
    let url = this.defaultUrl + "productsCheckout/gbal"; // "productsCheckout.htm/gbal;
    return this.http
    .get(url, httpOptions)
    .pipe(catchError((this.util.handleError("getData"))));
	}



  postJsonData=function(cart){
    let url = this.defaultUrl + "productsCheckout/so"; // 'productsCheckout.htm/so',cart)
    return this.http
    .post(url,cart, httpOptions)
    .pipe(catchError((this.util.handleError("postJsonData"))));
	}






  


}
