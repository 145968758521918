import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class AutopayService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  private cardNotify = new Subject<any>();
  cardObservable$ = this.cardNotify.asObservable();

  private bankNotify = new Subject<any>();
  bankObservable$ = this.bankNotify.asObservable();

  private autopayNotify = new Subject<any>();
  autopayObservable$ = this.autopayNotify.asObservable();

  autopay=function(){
    this.autopayNotify.next();
  }

  card=function(){
    this.cardNotify.next();
  }

  bank=function(){
    this.bankNotify.next();
  }

  getExistingACHInfo=function()
  {
      let url = this.defaultUrl + "paymentMethod/getExistingACHInfo";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getExistingACHInfo"))));
  }

  geccl=function()
  {
      let url = this.defaultUrl + "paymentMethod/geccl";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("geccl"))));
  }

  getContractInfo=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/getContractInfo?";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    url += qryStr;

      return this.http
        .get(url, '', httpOptions)
        .pipe(catchError((this.util.handleError("getContractInfo"))));
  }

  getAutoPayPageFlagOnStatus=function()
  {
      let url = this.defaultUrl + "paymentMethod/getAutoPayPageFlagOnStatus";
      
      return this.http
        .get(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getAutoPayPageFlagOnStatus"))));
  }

  insertAutoPayContractDtls=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/insertAutoPayContractDtls";
    let qryStr=$.param(oParams);  
    url = url + "?" + qryStr;

      return this.http
        .post(url, '', httpOptions)
        .pipe(catchError((this.util.handleError("insertAutoPayContractDtls"))));
  }

  changePaymentInfo=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/changePaymentInfo";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

      return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("changePaymentInfo"))));
  }

  insertACHAutoContDetails=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/insertACHAutoContDetails?";
    let qryStr=$.param(oParams);  
    url=url+qryStr;

      return this.http
        .get(url, '', httpOptions)
        .pipe(catchError((this.util.handleError("insertACHAutoContDetails"))));
  }

  deleteCCPayment=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/deleteCC";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

      return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("deleteCCPayment"))));
  }

  deleteACHPayment=function(oParams)
  {
    let url = this.defaultUrl + "paymentMethod/deleteACHPayment";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

      return this.http
        .post(url, qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("deleteACHPayment"))));
  }
}
