import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router'
import { map, catchError } from 'rxjs/operators';
import { Observable, Subscription, of,throwError } from 'rxjs';
import { Util } from 'src/app/app.util';
import { canonRegisterUser } from 'src/app/common/bean/canonRegisterUser';

import { canonRegisterUserStatus } from 'src/app/common/bean/canonRegisterUserStatus';

const httpJsonOptions = {
  headers: new HttpHeaders({     
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class RegisterService {
  defaultUrl : string;
   userFullName:string ="";
  userName :string ="";
  isloggedIn: boolean;
  userRoles: any;
  status: string ="";
  statusFlag: string ="";
  // sanitized1: DomSanitizer;
  // displayText: SafeHtml;
  

  // registerStatus: RegisterUser;
  // registerStatus :RegisterStatus;
  constructor(private http: HttpClient, private router: Router, private util : Util) { 
	  	  this.defaultUrl = this.util.getBaseUrl();	  
        // this.sanitized1 = sanitized;
  }

  // addRegisterUser(registerUser: RegisterUser): Observable<RegisterUser> {
  addRegisterUser(registerUser: canonRegisterUser) {
    console.log("I am here ------> 1")
    console.log(registerUser)
    // return this.http.post<RegisterUser>("http://localhost:8070/csaApp4/registeruser", registerUser,{})
    return this.http.post<any>(this.defaultUrl+"registeruser", registerUser,{}); 
  }

  validateEmail(inputEmail: string){
    console.log("I am here ------> 2")
    return this.http.get<any>(this.defaultUrl+"validatedomain?emailAddress="+inputEmail,{});
  }

  validateSerialNumber(serialNumber: string){
    console.log("I am here ------> 3")
    return this.http.get<any>(this.defaultUrl+"validateserial?serial="+serialNumber,{});
  }

  validateSerialNumberAndZipCode(serialNumber: string,zipCode: string){
    console.log("I am here ------> 4")
    return this.http.get<any>(this.defaultUrl+"validateserial?serial="+serialNumber+"&zipcode="+zipCode,{});
  }

  getSerialNumbers(selectedUserAccts: string){
    console.log("I am here ------> 5")
    return this.http.get<any>(this.defaultUrl+"getserialslist?selectedUserAccts="+selectedUserAccts,{});
  }

  getModels(selectedUserAccts: string){
    console.log("I am here ------> 6")
    return this.http.get<any>(this.defaultUrl+"getmodelslist?selectedUserAccts="+selectedUserAccts,{});
  }

  getLocations(selectedUserAccts: string){
    console.log("I am here ------> 7")
    return this.http.get<any>(this.defaultUrl+"getlocationslist?selectedUserAccts="+selectedUserAccts,{});
  }

  getNotes(selectedUserAccts: string){
    console.log("I am here ------> 8")
    return this.http.get<any>(this.defaultUrl+"getnoteslist?selectedUserAccts="+selectedUserAccts,{});
  }

  getUsers(selectedUserAccts: string){
    console.log("I am here ------> 9")
    return this.http.get<any>(this.defaultUrl+"getuserslist?selectedUserAccts="+selectedUserAccts,{});
  }


  getRoles(){
    console.log("I am here ------> 10")
    return this.http.post<any>(this.defaultUrl+"getuserrolelist",{});
  }

  
  createUser(registerUser: canonRegisterUser){
    console.log("I am here ------> 11")
    return this.http.post<any>(this.defaultUrl+"createuser",registerUser,{});
  }

  downloadTemplate(): Observable<any>{
    console.log("I am here ------> 12")
    // const headers = new HttpHeaders({'Accept':'application/octet-stream'})

    const httpOptions:Object = {
      headers: new HttpHeaders({     
      }).set('Accept','application/xlsx'),responseType:'blob'
    };
    return this.http.get<any>(this.defaultUrl+"downloadtemplate",httpOptions);
    // return this.http.get<any>(this.defaultUrl+"downloadtemplate",{});
  }

  uploadTemplate(formData: FormData): Observable<any>{
    console.log("I am here ------> 13")
    // const headers = new HttpHeaders({'Accept':'application/octet-stream'})

    // const httpOptions:Object = {
    //   headers: new HttpHeaders({     
    //   }).set('Content-Type','application/xlsx; boundary=----WebKitFormBoundaryEu5y2Hd6lrBT8uR0')
    // };
    return this.http.post<any>(this.defaultUrl+"uploadtemplate",formData,{});
  }

  reviewResults(): Observable<any>{
    console.log("I am here ------> 14")
    // const headers = new HttpHeaders({'Accept':'application/octet-stream'})

    const httpOptions:Object = {
      headers: new HttpHeaders({     
      }).set('Accept','application/octet-stream'),responseType:'blob'
    };
    return this.http.get<any>(this.defaultUrl+"downloadresults",httpOptions);
  }

//    /**
//  * Handle Http operation that failed.
//  * Let the app continue.
//  * @param operation - name of the operation that failed
//  * @param result - optional value to return as the observable result
//  */
//    private handleError<T>(operation = "operation", result?: T) {
//     return (error: any): Observable<T> => {
//       // TODO: send the error to remote logging infrastructure
//       console.error(error); // log to console instead

//       // TODO: better job of transforming error for user consumption
//       console.log(`${operation} failed: ${error.message}`);

//       // Let the app keep running by returning an empty result.
//       return of(result as T);
//     };
//   }

private handleError(error: HttpErrorResponse) {
  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error);
    console.log("error ----> 1")
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, body was: `, error.error);
  }
  console.log("error ----> 2")
  // Return an observable with a user-facing error message.
  return throwError(() => new Error('Something bad happened; please try again later.'));
}

}

  export class AuthResponse {

	  constructor(public authenticated: boolean,public userName:string,public userFullName:string) {
    }
}

export class ResetResponse {

  constructor(public status:string,public statusFlag:string) {
  }
  

}