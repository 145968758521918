import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class ConfiguratorAdminService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }

    getCustomers=function(oParams) {
    let url = this.defaultUrl + "configuratorAdmin/getCustomers";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getCustomers"))));
    
  }

  getSelectedCustomer=function(oParams) {
    let url = this.defaultUrl + "configuratorAdmin/getSelectedCustomer";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getSelectedCustomer"))));
    
  }

  updateStatus=function(oParams) {
    let url = this.defaultUrl + "configuratorAdmin/updateStatus";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("updateStatus"))));
    
  }


  getUserPartyTemplates=function(oParams) {
    let url = this.defaultUrl + "configuratorAdmin/getUserPartyTemplates";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("getUserPartyTemplates"))));
    
  }

  updateUserConfigParty=function(oParams) {
    let url = this.defaultUrl + "configuratorAdmin/updateUserConfigParty";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();

    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("updateUserConfigParty"))));
    
  }


}
