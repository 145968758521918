import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ActivatedRoute } from '@angular/router';
import { OpenOrderService } from './open-orders.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsComponent } from '../order-details/order-details.component';
@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.css']
})
export class OpenOrdersComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  openSupplyList = [];
  myDeviceList:any=[];
  orderDetails = "";
  dateRanges = "30";
  userList = [];
  start = 1;
  end = 0;
  recPerPage: any = "10";
  pageNum = 1;
  arrPgLinks = [];
  recMsg = "";
  msg = "";
  showMsg = false;
  msgClass = "";	
  
  invoices = [];
  isVisible = [];
  orderNumber: any="";
  serialEqpNumber: any = "";
  model: any = "";  
  poNumber :any = "";
  users: any = "";
  selectedUserAccts:string="";
  userAccountList=[];
  pleaseWaitCls:boolean = false;
  totalRecords: any;
  numOfRecsMsg: string;
  sub: any;
  serialNumber:string="";
  location:string="";
  
  orderHeader:any;
  orderLineList = [];
  isBillableOrder:boolean=false;
  invList = "";
  selectedOrder: any;
  closedOrderService: any;
  duration: string;
  assignedTo: string;
  coverageType: string;

  constructor( private commonService:CommonServiceService, private openOrderService: OpenOrderService, 
      private util : Util, private modalService: NgbModal) { }



  ngOnInit() {

    this.blockUI.start();
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
        this.blockUI.stop();
      });

      this.blockUI.start();
      let userData = this.openOrderService.getUsers().subscribe(
        userData=>{
          this.userList = userData.assignedTos;
          this.blockUI.stop();
        });

      this.commonService.setCommonFunctions();
      this.getOpenSupplyOrders(1);


  }

  toggleDiv (id: any, event: any ,orderHeaderId: any){	
    console.log("Printing toggleDiv in open supply orders");	
    event.preventDefault();			
    //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;	  	
    this.isVisible[id] = this.isVisible[id]?false : true;		
    //var orderList = $filter('filter')(this.closeSupplyList, {'strOrdId' : orderHeaderId}, true);
    var orderList = this.openSupplyList.filter(item => item.strOrdId === orderHeaderId);
        
        if(orderList.length > 0){
           this.selectedOrder = orderList[0];
        }
     
    if(!this.selectedOrder.itemInfoAvailable){
      //this.pleaseWaitCls = true;
      this.blockUI.start();

      var trackingShipment = {
        "orderNum" : orderHeaderId,
        "source" : this.selectedOrder.source
      }	

            var adPromise = this.openOrderService.getSupplyOrderItems(trackingShipment).subscribe(
    data=>{
      console.log(data);
      var orderTrackItems = data;
          
      this.selectedOrder.partDetailsList = orderTrackItems;

      this.selectedOrder.itemInfoAvailable = 'Y';

      //this.pleaseWaitCls = false;
      this.blockUI.stop();
      
    
  });   

  

    }
    //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true; 		
  } 

  clearSearch(event:any){
    if(event){
      event.preventDefault();
    }
    this.orderNumber = "";
    this.serialEqpNumber = "";
    this.model = "";
    this.users = "";
    this.poNumber = "";
    this.dateRanges = "30";
    for(let actIdx in this.userAccountList){
      this.userAccountList[actIdx]["enabled"]=true;
    }
    this.getOpenSupplyOrders(1);
  }

   
  getTrackingURLMsg() {}


  getOrderDetails(ordID, src, totPrice) {
	
    const orderDetailsModalRef = this.modalService.open(OrderDetailsComponent, {size: <any>'xl'});
    orderDetailsModalRef.componentInstance.ordID = ordID;
    orderDetailsModalRef.componentInstance.src = src;
    orderDetailsModalRef.componentInstance.totPrice = totPrice;
	
	
  }	

  exportToExcel(){
    this.blockUI.start();
    var paramObj = {};
    paramObj["srch_order_num"] = this.orderNumber;
    paramObj["srch_serail_num"] = this.serialEqpNumber;
    paramObj["model"] = this.model;
    paramObj["poNumber"] = this.poNumber;
    paramObj["assgined_to"] = this.users;
    var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
    var endItem = (this.pageNum * parseInt(this.recPerPage));
    paramObj["start"] = 1;
    paramObj["length"] = 10000;
    
    var dNow = new Date();
     var d = new Date();
    var perivousdays;
    var fromdate;
    var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    if (this.dateRanges === "15")
          {
      perivousdays = new Date(d.setDate(d.getDate()-15));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
    else if (this.dateRanges === "30")
          {
      perivousdays = new Date(d.setDate(d.getDate()-30));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
       else if(this.dateRanges === "90")
       {
         perivousdays = new Date(d.setDate(d.getDate()-90));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "180")
       {
         perivousdays = new Date(d.setDate(d.getDate()-180));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "365")
       {
         perivousdays = new Date(d.setDate(d.getDate()-365));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "547")
       {
         perivousdays = new Date(d.setDate(d.getDate()-547));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       } 

    let url= "openSupply/opOrderDetailsExportJSON";//"mydevices/donwloadExcel";
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Printing Selected account.. for exportToExcel method.. ");
    console.log(this.selectedUserAccts);
    
    let qryStr = "srch_from_date="+ fromdate+ "&srch_to_date="+ todate+"&order_status=Open"+ 
                 "&model="+  this.model + "&srch_order_num="+this.orderNumber+"&srch_serail_num="+
                 this.serialEqpNumber+"&poNumber="+ this.poNumber+"&assgined_to="+
                 this.users+"&start=1" +"&length=10000"+"&party_ids="+ this.selectedUserAccts;
                 url=url+"?"+qryStr;
                 console.log("Printing url: ", url);
                 //this.commonService.jsonToExcel(url);
                 this.openOrderService.getOpenSupplyOrdersJSON(url).subscribe(
                  data=>{
                    console.log("Prnting ", data.data);
                    this.commonService.jsonToExcel(data.data,"OpenSupply",true,false,null);
                    this.blockUI.stop();
                  }
                 );
  }

  exportToExcel1(){

    this.blockUI.start();
    var paramObj = {};
    paramObj["srch_order_num"] = this.orderNumber;
    paramObj["srch_serail_num"] = this.serialEqpNumber;
    paramObj["model"] = this.model;
    paramObj["poNumber"] = this.poNumber;
    paramObj["assgined_to"] = this.users;
    var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
    var endItem = (this.pageNum * parseInt(this.recPerPage));
    paramObj["start"] = 1;
    paramObj["length"] = 10000;
    
    var dNow = new Date();
     var d = new Date();
    var perivousdays;
    var fromdate;
    var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    if (this.dateRanges === "15")
          {
      perivousdays = new Date(d.setDate(d.getDate()-15));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
    else if (this.dateRanges === "30")
          {
      perivousdays = new Date(d.setDate(d.getDate()-30));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
       else if(this.dateRanges === "90")
       {
         perivousdays = new Date(d.setDate(d.getDate()-90));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "180")
       {
         perivousdays = new Date(d.setDate(d.getDate()-180));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "365")
       {
         perivousdays = new Date(d.setDate(d.getDate()-365));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "547")
       {
         perivousdays = new Date(d.setDate(d.getDate()-547));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       } 

    paramObj["srch_from_date"] = fromdate;
    paramObj["srch_to_date"] = todate;
    paramObj["order_status"] = "Open";
    var arrUserAcc = [];
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Printing Selected account..");
    console.log(this.selectedUserAccts);
    paramObj["party_ids"] = this.selectedUserAccts;
    
    var adPromise = this.openOrderService.getOpenSupplyOrdersJSON(paramObj).subscribe(
      data=>{
         let jd = data.data;
         console.log("Printing jd value in exportToExcel method:   ", jd);	
         if(jd.length>0){
          this.commonService.jsonToExcel(jd,"OpenSupply",true,false,null);	
         }else{
          jd=["No Records Found","","","","","","",""];
          this.commonService.jsonToExcel(jd,"OpenSupply",false,false,null);	
         }
        			
          this.blockUI.stop();
          
       
    });
  }

  exportToExcel2(){
    this.blockUI.start();
			
			this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
      // var param = {
			// 	"pageNum": 1,
			// 	"pageSize":this.totalRecords, 
			// 	"partyIDs":"",
			// 	// "srhReqNum": this.requestNumber,
			// 	// "srhSerialNum":this.serialEqpNumber,
			// 	// "srhModelNum":this.model,
			// 	// "srhAssignedTo":"All Users",
			// 	// "srhDuration":this.dateRanges,

      //   "srch_order_num": this.orderNumber,
      //   "srch_serail_num": this.serialEqpNumber,
      //   "model": this.model,
      //   "poNumber":this.poNumber,
      //   "assgined_to" : this.users,

      //  "start": 1,
      // "length" : 10000
			// };

      var paramObj = {};
      paramObj["pageNum"]= 1;
      paramObj["pageSize"]=this.totalRecords, 
      paramObj["srch_order_num"] = this.orderNumber;
      paramObj["srch_serail_num"] = this.serialEqpNumber;
      paramObj["model"] = this.model;
      paramObj["poNumber"] = this.poNumber;
      paramObj["assgined_to"] = this.users;
      var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
      var endItem = (this.pageNum * parseInt(this.recPerPage));
      paramObj["start"] = 1;
      paramObj["length"] = this.totalRecords;
      
      var dNow = new Date();
       var d = new Date();
      var perivousdays;
      var fromdate;
      var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
      if (this.dateRanges === "15")
            {
        perivousdays = new Date(d.setDate(d.getDate()-15));
        fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
            }
      else if (this.dateRanges === "30")
            {
        perivousdays = new Date(d.setDate(d.getDate()-30));
        fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
            }
         else if(this.dateRanges === "90")
         {
           perivousdays = new Date(d.setDate(d.getDate()-90));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "180")
         {
           perivousdays = new Date(d.setDate(d.getDate()-180));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "365")
         {
           perivousdays = new Date(d.setDate(d.getDate()-365));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "547")
         {
           perivousdays = new Date(d.setDate(d.getDate()-547));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         } 
  
      paramObj["srch_from_date"] = fromdate;
      paramObj["srch_to_date"] = todate;
      paramObj["order_status"] = "Open";
      var arrUserAcc = [];
      //arrUserAcc = getSelecteUserAccounts();
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
      console.log("Printing Selected account..");
      console.log(this.selectedUserAccts);
      paramObj["party_ids"] = this.selectedUserAccts;
      
      var adPromise = this.openOrderService.getOpenSupplyOrders(paramObj).subscribe(
        data=>{

          this.commonService.jsonToExcel(data.data,"OpenSupply",true,false,null);	
          this.blockUI.stop();
        });



  }


  getOpenSupplyOrders(pgNum: any){
    //showPleaseWait();
    //this.pleaseWaitCls = true;
    this.blockUI.start();
    this.pageNum = pgNum;
    this.showMsg = false;
    this.isVisible = [];
    var currentElement = $("#rpg").val();
    if(currentElement != undefined){
      this.recPerPage = currentElement;
    }
    var paramObj = {};
    paramObj["srch_order_num"] = this.orderNumber;
    paramObj["srch_serail_num"] = this.serialEqpNumber;
    paramObj["model"] = this.model;
    paramObj["poNumber"] = this.poNumber;
    paramObj["assgined_to"] = this.users;
    var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
    var endItem = (this.pageNum * parseInt(this.recPerPage));
    paramObj["start"] = startItem-1;
    paramObj["length"] = this.recPerPage;
    
    var dNow = new Date();
     var d = new Date();
    var perivousdays;
    var fromdate;
    var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    if (this.dateRanges === "15")
          {
      perivousdays = new Date(d.setDate(d.getDate()-15));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
    else if (this.dateRanges === "30")
          {
      perivousdays = new Date(d.setDate(d.getDate()-30));
      fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
          }
       else if(this.dateRanges === "90")
       {
         perivousdays = new Date(d.setDate(d.getDate()-90));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "180")
       {
         perivousdays = new Date(d.setDate(d.getDate()-180));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "365")
       {
         perivousdays = new Date(d.setDate(d.getDate()-365));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       }
       else if(this.dateRanges === "547")
       {
         perivousdays = new Date(d.setDate(d.getDate()-547));
         fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
       } 

    paramObj["srch_from_date"] = fromdate;
    paramObj["srch_to_date"] = todate;
    paramObj["order_status"] = "Open";
    var arrUserAcc = [];
    //arrUserAcc = getSelecteUserAccounts();
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Printing Selected account..");
    console.log(this.selectedUserAccts);
    paramObj["party_ids"] = this.selectedUserAccts;
    
    var adPromise = this.openOrderService.getOpenSupplyOrders(paramObj).subscribe(
      data=>{
      //function(result){
        this.openSupplyList = data.data;					
        this.totalRecords = data.recordsTotal;
        if (this.openSupplyList.length < 1) {
          this.showMsg = true;
          this.msg = "No Records found";
          this.msgClass = "alert-danger";
        }
        
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
        //hidePleaseWait();
        //this.pleaseWaitCls = false;
        this.blockUI.stop();
      // },
      // function(reason) {
      //   this.msg = "Error while getting records.";
      //   this.msgClass = "alert-danger";
      //   this.showMsg = true;
      //   //hidePleaseWait();
      //   this.pleaseWaitCls = false;
      //   console.log(reason);					
      // };

// below setUserDropDownList is directly set in html code so it's no needed here.
//  setUserDropDownList(){
//     //showPleaseWait();
//     var adPromise = openSupplyRequestService.getUserDropDownList();
//     adPromise.then(
//       function(result){
//         $scope.userList = result.data.assignedTos;
        
//       },
//       function(reason){					
//         console.log(reason);
        
//       });
//       hidePleaseWait();
//   }
  
  
  //below clearAll is directly set in html code so it's no needed here
  // $scope.clearAll = function(){
  //   //showPleaseWait();
  //   setUserAccounts();
  //   $scope.orderNumber = "";
  //   $scope.serialEqpNumber = "";
  //   $scope.model = "";
  //   $scope.users = "";
  //   $scope.poNumber = "";
  //   $scope.dateRanges = "30";
  //   $scope.getOpenSupplyOrders(1);
  //   setTimeout(() => {
  //   showPleaseWait();
  //   }, 500);
  // }
});
  
  // getOrderDetails (orderId,source,totalAmt){
  //   //showPleaseWait();
  //   this.pleaseWaitCls = true;
  //   var paramObj = {};
  //   paramObj["orderId"] = orderId;
  //   paramObj["orderBy"] = "PART_NUM";
  //   paramObj["sortBy"] = "ASC";
  //   paramObj["action"] = "open";
  //   paramObj["source"] = source;
  //   paramObj["totalAmt"] = totalAmt;
    
  //   var adPromise = openSupplyRequestService.getOrderDetails(paramObj);
  //   adPromise.then(
  //     function(result){
  //       this.orderDetails = result.data;
  //       var idx = this.orderDetails.indexOf("<form");
  //       var substring = this.orderDetails.substr(idx);
  //       var substr1 = substring.substr(0,substring.indexOf("<script"));
  //       this.orderDetails = substr1;					
  //       $("#orderDetailsData").html(this.orderDetails);
  //       $('#order-details-modal').modal('show');
  //       //$scope.orderDetailsModal($scope.orderDetails);
  //     },
  //     function(reason){
  //       console.log(reason);
  //     }
  //   );
  //   //hidePleaseWait();
  //   this.pleaseWaitCls = false;
  // }
  
  
  
  // $scope.getTrackingUrl = function(partNum,orderId,source,lineNum){
  //   showPleaseWait();
  //   var paramObj = {};
  //   paramObj["orderId"] = orderId;
  //   paramObj["partNumber"] = partNum;
  //   paramObj["lineNum"] = lineNum;
  //   paramObj["source"] = source;
    
  //   var adPromise = openSupplyRequestService.getTrackingURL(paramObj);
  //   adPromise.then(
  //     function(result){
  //       var trackingList = result.data;
    
  //     console.log(result.data);
    
  //       if (!Array.isArray(trackingList) || !trackingList.length) {
  //         alert("Tracking status is not available now. Please try again later.");
  //       }else{
  //         if(trackingList.length == 1){
  //           if(trackingList[0].trackingUrl){
  //             window.open(trackingList[0].trackingUrl, "_blank");
  //           }else{
  //             alert("Tracking status is not available now. Please try again later.");	
  //           }
            
  //         }else{
  //           var htmlData = '';
  //           for (i = 0; i < trackingList.length; i++) {
  //             if(trackingList[i].trackingUrl){
  //               htmlData += "<a target='_blank' href='" + trackingList[i].trackingUrl + "'>" + trackingList[i].trackingNum + "</a><br>";	
  //             }else{
  //               htmlData += trackingList[i].trackingNum + "<br>";
  //             }
              
  //           }
  //           $('#tdata').html(htmlData);
  //           $('#trackingModal').modal('show');
  //         }
  //       }
  //     },
  //     function(reason){
  //       console.log(reason);
  //     }
  //   );
  //   hidePleaseWait();
  // }
  
  // exportToExcel(){
  //   let url="mydevices/donwloadExcel";
  //   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
  //   let qryStr="serialNumber="+this.serialNumber+"&model="+this.model+"&location="+this.location+"&selectedUserAccts="+this.selectedUserAccts ;
  //   url=url+"?"+qryStr;
  //   this.commonService.exportToExcel(url);
  // }
  
  // $scope.getInvPdf = function(invNum,source){
  //   showPleaseWait();
  //   var url="invoicepdf.htm?mthd=gipfe&invoice_number="+invNum + "&source="+source;
  //   window.open(url);
  //   hidePleaseWait();
  // }
  
  // $scope.getInvPdfS21 = function(invNum){
  //   showPleaseWait();
  //   var url="invoicepdf.htm?mthd=gipdfe&invoice_number="+invNum;
  //   window.open(url);
  //   hidePleaseWait();
  // } 
  
  // $scope.toggleDiv = function(id, event,orderHeaderId){		
  //   event.preventDefault();			
  //     //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;	  	
  //     $scope.isVisible[id] = $scope.isVisible[id]?false : true;		
  //     //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;  		
      
      
  //     var orderList = $filter('filter')($scope.openSupplyList, {'strOrdId' : orderHeaderId}, true);
          
  //         if(orderList.length > 0){
  //             $scope.selectedOrder = orderList[0];
  //         }
       
  //     if(!$scope.selectedOrder.itemInfoAvailable){
  //       showPleaseWait();
  //       var trackingShipment = {
  //         "orderNum" : orderHeaderId,
  //         "source" : $scope.selectedOrder.source
  //       }	
           
  //        var orderItemPromise = openSupplyRequestService.getSupplyOrderItems(trackingShipment);
    
  //        orderItemPromise.then(
  //         function(results){		
                
  //           var orderTrackItems = results.data;
            
  //           $scope.selectedOrder.partDetailsList = orderTrackItems;
    
  //           $scope.selectedOrder.itemInfoAvailable = 'Y';

  //           hidePleaseWait();
  //         },
  //         function(reason){
  //           console.log(reason);
  //         }
  //       );
  //     }
  // }
  
  // $scope.clearMsg = function() {
  //   $scope.showMsg = false;
  //   $scope.msg = "";
  //   $scope.msgClass = "";
  // }
  
  // $scope.setRecMsg = function() {
  //   $scope.recMsg = "";
  //   $scope.start = (parseInt($scope.recPerPage) * ($scope.pageNum - 1)) + 1;
  //   $scope.end = (parseInt($scope.recPerPage) * $scope.pageNum);
  //   if ($scope.end > $scope.totalRecords) {
  //     $scope.end = $scope.totalRecords;
  //   }
  //   if ($scope.totalRecords > 0)
  //     $scope.recMsg = "Showing " + $scope.start + " to " + $scope.end + " of " + $scope.totalRecords;

  // }




  //   $scope.setPgLinks = function() {
  //   $scope.arrPgLinks = [];
  //   var tr = $scope.totalRecords;
  //   var rpp = parseInt( $scope.recPerPage);
  //   if (tr > rpp) {
  //     var tl = tr / rpp;
  //     var nop = (tr % rpp == 0) ? parseInt(tl) : parseInt(tl) + 1;

  //     var t2 = nop;
  //     var t1=1;
  //     if (nop > 10){ 
        
  //       var pgNum=$scope.pageNum;
         
  //       if(pgNum<10){
  //         t1=pgNum;
  //         t2=pgNum+9;
  //       }else{
  //         t1=pgNum-5;
  //         t2=pgNum+4;
  //       }
        
  //       for (var i = t1; i <= t2; i++) {
  //             if(i<nop){
  //                 $scope.arrPgLinks.push(i);
  //                       }
  //         }
        
  //      }else{
  //       for (var i = t1; i <= t2; i++) {
  //         $scope.arrPgLinks.push(i);
  //         }
  //     }
      

  //   }

  // }
 
  
  // $scope.setUserDropDownList();
  
  // setTimeout(() => {
  //   $scope.getOpenSupplyOrders(1);
  // }, 500);


}

stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }


}
