import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class OrderInquiryService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


   getOrderInquiryRequestList=function(paramObj){
    let url = this.defaultUrl + "orderInquiry/inqOrd?"; // "orderInquiry.htm?mthd=gsoi";
		let qryStr = $.param(paramObj);
    url=url+qryStr;
		//var adPromise = httpMyCsaService.postData('orderInquiry.htm?mthd=gsoi', qryStr);
		//return adPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getOrderInquiryRequestList"))));
	}

  getOrderInquiryListJSON=function(paramObj){
    let url = this.defaultUrl + "orderInquiry/inqOrdInvoiceExportJSON?";  ;
		let qryStr = $.param(paramObj);
    url=url+qryStr;
	   return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getOrderInquiryRequestList"))));
	}

  getInvoiceNumbers=function(orderNum){
    //We don't have any method goin in CanonCpOrderInquiryController in csaAppsOracleS21-servlet.xml
    let url = this.defaultUrl + "orderInquiry/NeedtoFix?"; // "trackSupplies.htm?mthd=goin&orderNumber='+orderNum";
		let qryStr = $.param(orderNum);
    url=url+qryStr;
		//var adPromise = httpMyCsaService.postData('trackSupplies.htm?mthd=goin&orderNumber='+orderNum);
		//return adPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("getInvoiceNumbers"))));
	}

  exportToExcel=function(orderNum){
    let url = this.defaultUrl + "orderInquiry/inqOrdInvoiceExportJSON?";
		let qryStr = $.param(orderNum);
    url=url+qryStr;
		// var qryStr = $.param(paramObj);
		// var adPromise = httpMyCsaService.postData('orderInquiry.htm?mthd=geoiej',qryStr);
		// return adPromise;
    return this.http
    .post(url,null, httpOptions)
    .pipe(catchError((this.util.handleError("exportToExcel"))));
	}


  
  getOpenSupplyOrders=function(oParams)
  {
      let url = this.defaultUrl + "openSupply/opSupOrdReq?"; // "trackSupplies.htm?mthd=gso?";
      let qryStr=$.param(oParams);  
       url=url+qryStr;
      return this.http
        .post(url,null, httpOptions)
        .pipe(catchError((this.util.handleError("getOpenSupplyRequests"))));
    }

  getOrderDetails=function(oParams)
  {
    // var qryStr = $.param(paramObj);
		// var adPromise = httpMyCsaService.postData('orderInquiry.htm?mthd=godi',qryStr);
		// return adPromise;
      let url = this.defaultUrl + "orderInquiry/orderDetail?"; 
      let qryStr=$.param(oParams);  
       url=url+qryStr;
      return this.http
        .post(url,null, httpOptions)
        .pipe(catchError((this.util.handleError("getOrderDetails"))));
    }

    getSupplyOrderItems=function(oParams)
    {
        let url = this.defaultUrl + "openSupply/opSupplyOrderItems?"; // trackSupplies.htm?mthd=gsoi;
        let qryStr=$.param(oParams);  
         url=url+qryStr;
        return this.http
          .post(url,null, httpOptions)
          .pipe(catchError((this.util.handleError("getCloseSupplyRequests"))));
      } 
      
      
      getUsers=function()
      {
          let url = this.defaultUrl + "openSupply/opSupOrdDrpDwn"; // trackSupplies.htm?mthd=gddj;

          return this.http
            .get(url, httpOptions)
            .pipe(catchError((this.util.handleError("getUsers"))));
        }  


}
