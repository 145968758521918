import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
import { saveAs } from 'file-saver';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class TonerReturnHistoryService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util) { 
    this.baseUrl = this.util.getBaseUrl();
  }

  getReturnHistory(searchData) : Observable<any>{
    let url = this.baseUrl + 'tonerReturn/getHistory';

    return this.http.post(url, searchData, httpOptions).pipe(catchError(this.handleError('getReturnHistory', [])));
  }

  exportReturns(){

    let url = this.baseUrl + 'tonerReturn/downloadReturnExcel';

    this.http.get(url, {responseType: 'blob'}).subscribe(
      response => {
        console.log("inside exportReturns");

        const blob = new Blob([response], { type: 'application/xls; charset=utf-8' });
        let fileName = 'ReturnHistory.xls';
        //const file = new File([blob], fileName, { type: 'application/xlsx' });
        //saveAs(file, 'test.xlsx');
        saveAs(blob, fileName);
  
      }
      
    
    )

  }

  handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
  

  }
}
