import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ConfiguratorQuotesService } from '../configurator-quotes.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-configurator-shipping',
  templateUrl: './configurator-shipping.component.html',
  styleUrls: ['./configurator-shipping.component.css','../configurator-myquotes/configurator-myquotes.component.css']
})
export class ConfiguratorShippingComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  totalRecords=0;
  templateId: any;
  partyId: any;
  purchaseType: any;
  leaseOptions: any =[];
  myBuild ={} as any;
  configId: number =-1;
  quoteId: number;
  imgUrl="https://mycanonbusiness.usa.canon.com/cpImages/config/";
  configDetails: any =[];
 productMaintenance;
  maintenanceAmt;
  showShippingBtn = false;
  showColorDetails = true;
 deviceDetails = {};
 recordCount;
  //Get Id from cart
 listAccessory = [];
 listAccessoryType = [];
 localOptions = {};
  servicePlan ={} as any;
  selectedTermRange ={} as any;
  address = {} as any;
  firstName ="";
  lastName ="";
  phoneNumber ="";
  displayShippingStyle= "none";
  selectedShippingAddress: any;
  shippingList: any =[];
  searchText="";
  pageNum: number = 1;
  recPerPage = 10;
  searchFilterPropertyNames : string[] = ['shippingFullAddress'];
  showLoader: boolean = false;
  secInsLoc2: any;
  @ViewChild('shippingAddressModal') shippingTemplate:TemplateRef<any>;
  shippingAddrModalRef: BsModalRef
  phone="";  
  address1: any;
  address2: any;
  city: any;
  state ="0";
  postalCode: any;
  newAddress: boolean = false;
  regMsgTop: string ="";
  regMsgDown: string ="";
  addrOption: string ="";
  isAlertSuccess: boolean = true;
  showNewAddress: boolean = false;
  displayShipping = "none";
		        // Accessories in cart 
  constructor(private configquoteService:ConfiguratorQuotesService,private route : ActivatedRoute,private el: ElementRef, private renderer: Renderer2, private modalService: BsModalService,private titleService: Title, private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle("Configurator Shipping");
    this.route.queryParams.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.configId = params['configId'];
    });
    let userFullName =sessionStorage.getItem("fullName");
    const splited: string[] = userFullName.split(/\s+/);
    let firstname: string = splited[0].toLowerCase();
    this.firstName = firstname.charAt(0).toUpperCase() + firstname.slice(1);    
    let lastname: string = splited[1].toLowerCase();
    this.lastName = lastname.charAt(0).toUpperCase() + lastname.slice(1);
    this.phone=sessionStorage.getItem("phoneNumber") === "null"?'':sessionStorage.getItem("phoneNumber");
    console.log('First Name:', this.firstName);
    console.log('Last Name:', this.lastName);
    
    this.loadData(this.quoteId,this.configId);
  }
  loadData(quoteId,configId) {
    this.quoteId =quoteId;
    this.configId =configId;
  let oParams = {"quoteId":quoteId,"configId":configId};
  var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(  
  quoteDetailsPromise =>{
        this.myBuild = JSON.parse(quoteDetailsPromise.data);

        var configDetails = this.myBuild.config[0];
        this.myBuild.itemDescription = configDetails.itemDescription;
        this.myBuild.purchaseOption = configDetails.purchaseOption;
        if(configDetails.leaseTerm != null && configDetails.leaseTerm != undefined)
        {
          this.myBuild.leaseTerm = configDetails.leaseTerm;
        }else
        {
          this.myBuild.leaseTerm = "";
        }
        console.log('result', this.myBuild);
				this.myBuild = this.configquoteService.setConfigData(this.myBuild);
        if(this.myBuild.maintenance.length > 0){
          this.myBuild.maintDetailsAvailable = true;
          var maintDetails = this.myBuild.maintenance[0];
          
          if(maintDetails.isRejected == 'Y'){
            this.myBuild.maintTerm = "No Maintenance";
          }else{
            this.myBuild.maintTerm = maintDetails.termUser + " Months (" + maintDetails.contractType + ")";
          }
          this.myBuild.maintenanceAmt = this.configquoteService.getMaintTotal(this.myBuild.maintenance);
        }
        
        this.myBuild.imgSrc = this.imgUrl + this.myBuild.model+".jpg";	
       
        
            let oParams = {"quoteId":quoteId,"partyId":this.myBuild.partyId,"configId":configId,"productGroupId":this.myBuild.productGroupId,"itemCode":this.myBuild.machineItemCode,"purchaseType":this.myBuild.purchaseType,
            "leaseRate":this.myBuild.leaseRate,"bundleName":this.myBuild.bundleName,"priceListId":this.myBuild.priceListId,"configQty":this.myBuild.configQty}
         
            let buildOptionPromise =  this.configquoteService.configQuoteGenericMtd(oParams,'getMachineItems').subscribe(
              buildOptionPromise=>{        
                let optionData = buildOptionPromise.data;
                this.updateScopeData(optionData);
              },
              (reason) => {
                console.log(reason);
              }
            );
      }
  );
  }
  updateScopeData(optionData: any) {
    this.myBuild.listRequiredAccessoryTypeDetails = optionData.listRequiredAccessoryTypeDetails;
    this.myBuild.listAccessoryTypeDetails = optionData.listAccessoryTypeDetails;
    this.myBuild.listRequiredAccessory = optionData.listRequiredAccessory;
    this.myBuild.listRequireOneOfItemAccessory = optionData.listRequireOneOfItemAccessory;
    this.myBuild.listRequiredBundleAccessory = optionData.listRequiredBundleAccessory;
    this.myBuild.bundleIncludesRequireOneOfItemAccessory = optionData.bundleIncludesRequireOneOfItemAccessory;
    console.log('myBuild',this.myBuild);
  } 
  isHeaderEnable = function(listAccessory){
    return ConfiguratorQuotesService.isHeaderEnable(listAccessory);
  }
isOptionChecked = function(opt){
    return ConfiguratorQuotesService.isOptionChecked(opt);
  }
   findLengthItems (listAccessory) {
       return ConfiguratorQuotesService.findLengthItems(listAccessory);
   }
   //Remove machine level in the Options page
   removeFromCartOptionsPage (configId,quoteId) {
     let oParams = {"quoteId":this.quoteId,"configId":this.configId};
       var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
       quoteDetailsPromise =>{
           console.log('getquoteDetails', quoteDetailsPromise);
               var quoteDet = quoteDetailsPromise.data;
               var configLength = quoteDet.config.length;
               console.log('configLength',configLength);
               this.configquoteService.removeConfig(configId);
              var pathName = window.location.pathname;
             if(configLength > 1){
               window.location.href = pathName+'?view=configBuildSummary&quoteId='+quoteId;
             }else {
                 window.location.href = pathName+'?view=configModels&quoteId='+quoteId;
             }
            },
           function(reason) {
               console.log(reason);
           });
     }
     openModel() {
      this.searchText ="";
      this.shippingAddrModalRef = this.modalService.show(this.shippingTemplate);
    }
     closeShippingAddressModal(event: Event)
  {
    event.preventDefault();
    this.shippingAddrModalRef.hide();
  }
  showShippingAddressModal() {
    this.openModel();   
    this.showNewAddress = false;
    let oParams = {"criteria":this.searchText,"configId":this.configId};
    this.blockUI.start();
    let shipDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getShippingLocation').subscribe(
      shipDetailsPromise =>{
        console.log('shipDetailsPromise', shipDetailsPromise); 
        this.showLoader =false;   
            this.shippingList = shipDetailsPromise.data;
            this.totalRecords=shipDetailsPromise.iTotalDisplayRecords;
            this.blockUI.stop();
    }
    );
  }
  selectShippingLocation(addr,event: Event)
  {
    event.preventDefault();
    this.displayShipping='none';
    this.selectedShippingAddress = JSON.parse(JSON.stringify(addr));
    console.log("selectedShippingAddress:"+this.selectedShippingAddress);
    this.secInsLoc2 =this.selectedShippingAddress.shippingFullAddress;
    
    this.closeShippingAddressModal(event);
  }
   newAddressSelected(){
    this.showNewAddress = !this.showNewAddress;
    this.secInsLoc2 ="";
    this.displayShipping='block';
   // $('#build-shipping-form-wrapper').show();
  }
  validate() {
    this.regMsgTop = '';
    this.regMsgDown = '';
    const firstname = this.firstName;
    const lastname = this.lastName;
    const address1 = this.address1;
    const address2 = this.address2;
    const city = this.city;
    const state = this.state;
    const postalCode = this.postalCode;
    const phone = this.phone;
    const shipTo = this.secInsLoc2;
  
    if (this.addrOption === 'newAddress') {
      if (!firstname) {
        this.regMsgTop += "<div>Please provide your firstname!</div>";
        this.regMsgDown += "<div>Please provide your firstname!</div>";
       // return false;
      }
  
      if (!lastname) {
        this.regMsgTop += "<div>Please provide your lastname!</div>";
        this.regMsgDown += "<div>Please provide your lastname!</div>";
       // return false;
      }
  
      if (!address1) {
        this.regMsgTop += "<div>Please provide your address1!</div>";
        this.regMsgDown += "<div>Please provide your address1!</div>";
       // return false;
      }
  
      if (!city) {
        this.regMsgTop += "<div>Please provide your city!</div>";
        this.regMsgDown  += "<div>Please provide your city!</div>";
        //return false;
      }
  
      if (state === '0') {
        this.regMsgTop += "<div>Please provide your state!</div>";
        this.regMsgDown += "<div>Please provide your state!</div>";
        //return false;
      }
  
      if (!postalCode) {
        this.regMsgTop+= "<div>Please provide your postalCode in the format #####.</div>";
        this.regMsgDown += "<div>Please provide your postalCode in the format #####.</div>";
       // return false;
      }
  
      if (!phone) {
        this.regMsgTop += "<div>Please provide your phone!</div>";
        this.regMsgDown+= "<div>Please provide your phone!</div>";
       // return false;
      }
      if(firstname == "" || lastname == "" || address1 == "" || city == "" || state == "0" || postalCode == "" || phone == ""   ){
        this.isAlertSuccess = false;
        return false;
      }
    } else {
      if (!firstname) {
        this.regMsgTop += "<div>Please provide your firstname!</div>";
        this.regMsgDown += "<div>Please provide your firstname!</div>";
        //return false;
      }
  
      if (!lastname) {
        this.regMsgTop += "<div>Please provide your lastname!</div>";
        this.regMsgDown += "<div>Please provide your lastname!</div>";
       // return false;
      }
  
      if (!phone) {
        this.regMsgTop += "<div>Please provide your phone!</div>";
        this.regMsgDown+= "<div>Please provide your phone!</div>";
       // return false;
      }
  
      if (!shipTo || shipTo === 'undefined') {
        this.regMsgTop += "<div>Please select Address from Existing Address or Create New Address!</div>";
        this.regMsgDown += "<div>Please select Address from Existing Address or Create New Address!</div>";
      //  return false;
      }
      if (firstname == "" || lastname == "" || phone == "" || shipTo == "" || shipTo == "undefined"  ) {
        this.isAlertSuccess = false;
        return false;
    }
    }
  
    return true;
  }
   onSave(elem,event){ 
    if(event){
      event.preventDefault();
    }
  let validateform= this.validate();
  //let validateform= true;
  console.log("validate form:"+validateform);
  if(validateform==true)
  { 	
        let psn ="";
        if (this.newAddress) {
             console.log("new Address saving in DB");
              this.insertShippingAddress(this.quoteId,this.configId,this.firstName,this.lastName,this.address1,this.address2,this.city,this.state,this.postalCode,this.phone,psn);   		
               }
      else
        {
        console.log("existing Address saving in DB");		       
        this.insertShippingAddress(this.quoteId,this.configId,this.firstName,this.lastName,this.selectedShippingAddress.shippingAddress1,this.selectedShippingAddress.shippingAddress1,this.selectedShippingAddress.shippingCity,this.selectedShippingAddress.shippingState,this.selectedShippingAddress.shippingPostalCode,this.selectedShippingAddress.shippingPhone,psn); 
        }  
   
      }
  
  }
  clearRegMsgTop() {
    this.regMsgTop = ''; // Clear the content
    this.regMsgDown = '';
  }
  
  toggleAlertClass() {
    this.isAlertSuccess = !this.isAlertSuccess;
  }
   insertShippingAddress(quoteId,configId,firstname,lastname,address1,address2,city,state,postalCode,phone,psn){ 
     this.clearRegMsgTop();
           console.log("configId"+configId);
         var qryStr="quoteId="+quoteId+"&configId="+configId+"&firstname="+firstname+"&lastname="+lastname+"&address1="+address1+"&address2="+address2+"&city="+city+"&state="+state+"&postalCode="+postalCode+"&phone="+phone+"&psn="+psn;  				
         let params = {
          quoteId: quoteId,
          configId: configId,
          firstname: firstname,
          lastname: lastname,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          postalCode: postalCode,
          phone: phone,
          psn: psn
        };
         let data = this.configquoteService.configQuoteGenericMtd(params,'insertShippingDtls').subscribe(
          data =>{          
             let status=data.status;
              if(status=='S'){
                console.log("Address Saved");
                let oParams = { "quoteId": this.quoteId};
                const navigationExtras: NavigationExtras = {
                  queryParams: oParams
      
                };
                this.router.navigate(['/configsummary'], navigationExtras);
           
              }else{
                    let errMsg=data.errMsg;
                    alert(errMsg +" Please Enter valid Address");
              $("#regMsgTop").html("Please Enter valid Address!").addClass("alert alert-danger").show();	
              $("#regMsgDown").html("Please Enter valid Address!").addClass("alert alert-danger").show();
              }
          }
        );
      }
      routePage(event,pageName)
      {
       if(event)
       {
        event.preventDefault();
       }
       let oParams = {"quoteId":this.quoteId,"configId":this.configId};
       const navigationExtras: NavigationExtras = {
         queryParams: oParams
      
       };
       this.router.navigate([pageName], navigationExtras);
      }
      //Remove machine level in the Options page
      removeFromCart(configId,event) {
       if(event)
       {
        event.preventDefault();
       }
      let oParams = {"quoteId":this.quoteId};
        var quoteDetailsPromise = this.configquoteService.configQuoteGenericMtd(oParams,'getQuoteConfigDetails').subscribe(
        quoteDetailsPromise =>{
            console.log('getquoteDetails', quoteDetailsPromise);
                var quoteDet = JSON.parse(quoteDetailsPromise.data);
                var configLength = quoteDet.config.length;
                console.log('configLength',configLength);
                this.configquoteService.removeConfig(configId);
               
               const navigationExtras: NavigationExtras = {
                queryParams: oParams
               };
                if(configLength > 1){
                  this.router.navigate(['/configsummary'], navigationExtras);
                }else {
                  this.router.navigate(['/createquote'], navigationExtras);
                }
             },
            function(reason) {
                console.log(reason);
            });
      }
    }