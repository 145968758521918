import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util) {
    this.baseUrl = this.util.getBaseUrl();
  }

  /* getDoc() : Observable<any>{

    let url = this.baseUrl + 'agreement/getDoc';

    return this.http.get(url, {responseType: 'blob'});
  } */

  getDoc(){

    let url = this.baseUrl + 'agreement/getDoc';

    this.http.get(url, {responseType: 'blob'}).subscribe(
      response => {
        console.log("inside getDoc");

        const blob = new Blob([response], { type: 'application/pdf; charset=utf-8' });
        let fileName = 'CSAMasterAgreement.pdf';
        //const file = new File([blob], fileName, { type: 'application/xlsx' });
        //saveAs(file, 'test.xlsx');
        saveAs(blob, fileName);
  
      }
      
    
    )

  }

  handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
  

  }


}
