import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { SalesRepUserSearchService } from './sales-rep-user-search.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-sales-rep-user-search',
  templateUrl: './sales-rep-user-search.component.html',
  styleUrls: ['./sales-rep-user-search.component.css']
})
export class SalesRepUserSearchComponent implements OnInit {


  @BlockUI() dBlockUI : NgBlockUI;

  @ViewChild('devicesModal') devicesTemplate:TemplateRef<any>;
  devicesModalRef: BsModalRef


  ADMIN_ROLE="Admin Role";
  INVOICE_ROLE="Invoice Role";

  userList:any=[];
  numOfRecsMsg:string="";
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  arrPgLinks:any=[]; 
  userAccountList=[];

 // search params
 
 selectedUserAccts:string="";
 name:string="";
 email:string=""; 
 partyName:string="";
 serialNumber:string="";
 status:string="ALL"; 
 source:string="ALL";
 

 // Devices 
 devicesUserName:string="";
 devicesNumOfRecsMsg:string="";
 devicesPageNum:any=0;
 devicesTotalRecords:any=0;
 devicesRecPerPage:any=10;
 devicesArrPgLinks:any=[]; 
 userDevicesList=[];

  constructor(private commonService:CommonServiceService , private searchService: SalesRepUserSearchService,private modalService: BsModalService) { }

  ngOnInit() {
    this.commonService.setCommonFunctions();
    

    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
      });
    this.commonService.setCommonFunctions();

    this.getUserList(1,null); 

  }

  exportToExcel(){
    
  this.dBlockUI.start();
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   let oParams = { "pageNum": 1, "recPerPage": 1000,"selectedUserAccts":this.selectedUserAccts
    , "name":this.name, "email":this.email, "partyName":this.partyName, "serialNumber":this.serialNumber
    ,"status":this.status,"source":this.source
   };

  let data = this.searchService.getUsersList(oParams).subscribe(
    data=>{

       let uList = [];
        uList=data.list; 
       let objArr=[];
       if(uList&&uList.length>0){
         let lblsArr=["First Name","Last Name","Email","Account Number","Account Name","Status","Registration Date","Source","Invoice Role","Admin Role","Last Logon Date"];
          objArr.push(lblsArr);
           for(let idx in uList){
             let objU= uList[idx];
             let tmpArr=[];
                 tmpArr.push(objU["firstName"]);
                 tmpArr.push(objU["lastName"]);
                 tmpArr.push(objU["email"]);
                 tmpArr.push(objU["accountNumber"]);
                 tmpArr.push(objU["accountName"]);
                 tmpArr.push(objU["status"]);
                 tmpArr.push(objU["registrationDate"]);
                 tmpArr.push(objU["source"]);
                 tmpArr.push(objU["invoiceRole"]);
                 tmpArr.push(objU["adminRole"]);
                 tmpArr.push(objU["lastLogonDate"]);
              objArr.push(tmpArr);
           }
       }else{
          objArr=[["No data found."] ];
       }
       this.commonService.jsonToExcel(objArr,"UserList",false,false,null);

       this.dBlockUI.stop();
    });

    
  }


  getUserList(pgNum: any,event: any){
    if(event!=null){
     event.preventDefault();
   }
   this.dBlockUI.start();
   this.pageNum=pgNum;
   this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
   console.log("pageNum :"+pgNum);
   console.log("recPerPage :"+this.recPerPage);
   let oParams = { "pageNum": pgNum, "recPerPage": this.recPerPage,"selectedUserAccts":this.selectedUserAccts
     , "name":this.name, "email":this.email, "partyName":this.partyName, "serialNumber":this.serialNumber
     ,"status":this.status,"source":this.source
    };

   let data = this.searchService.getUsersList(oParams).subscribe(
     data=>{
        this.userList = data.list; 
        this.totalRecords=data.totalRecords;
        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
        this.dBlockUI.stop();
     });
 
 }

 clearUserSearch(event:any){
    
  if(event){
    event.preventDefault();
  }
  this.name="";
  this.email="";
  this.partyName="";
  this.serialNumber="";
  this.source="ALL";
  this.status="ALL";
  for(let actIdx in this.userAccountList){
    this.userAccountList[actIdx]["enabled"]=true;
  }
 
}

 getUserDevices(devicesPgNum: any,event:any){
   if(event!=null){
     event.preventDefault();
   }
 
   this.dBlockUI.start();
   this.devicesPageNum=devicesPgNum;
   
  let oParams = { "pageNum": devicesPgNum, "recPerPage": this.devicesRecPerPage,"email":this.devicesUserName 
   };

  let data = this.searchService.getUserDevicesList(oParams).subscribe(
    data=>{
       this.userDevicesList = data.list; 
       this.devicesTotalRecords=data.totalRecords;
       this.devicesNumOfRecsMsg=this.commonService.getNumOfRecsMsg(this.devicesTotalRecords,this.devicesRecPerPage,this.devicesPageNum);
       this.devicesArrPgLinks=this.commonService.getPgLinks(this.devicesTotalRecords,this.devicesPageNum,this.devicesRecPerPage);
        this.dBlockUI.stop();
       
    });
    
     
 }
 
 openDevicesModel(oUser:any) {  
  this.devicesUserName=oUser.email;  
  this.devicesModalRef = this.modalService.show(this.devicesTemplate);
  this.getUserDevices(1,null); 
 }
closeDevicesModel() {
  this.devicesUserName="";
  this.devicesModalRef.hide(); 
}


}
