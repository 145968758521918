import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Util } from 'src/app/app.util';
import { canonUser } from 'src/app/common/bean/canonUser';
const httpJsonOptions = {
  headers: new HttpHeaders({     
  }).set('Content-Type', 'application/json')
};
@Injectable({
  providedIn: 'root'
})

export class LoginAuthenticationService {
  defaultUrl : string;
   userFullName:string ="";
  userName :string ="";
  isloggedIn: boolean;
  userRoles: any;
  status: string ="";
  statusFlag: string ="";
  fullName: string;
  constructor(private http: HttpClient, private router: Router, private util : Util) { 
	  	  this.defaultUrl = this.util.getBaseUrl();	  
  }

getAuthenticatedUser() {
	this.userName =sessionStorage.getItem('userName');
    return this.userName;
  }
 
getAuthenticatedUserFullName() {
	this.userFullName =sessionStorage.getItem('userFullName');
    return this.userFullName;
  }
  isUserLoggedIn() {
     let user = sessionStorage.getItem('userName');
     return !(user === null);
   }
	 
      
    executeJDBCAuthenticationService(username, password) {
    let beanUrl = this.defaultUrl + "login";
    const body = {
      username, password
    }
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post<canonUser>(beanUrl, body, { headers: headers })
      .pipe(
        map(
          data => {            
             this.userName=data.userName;
             this.fullName=data.fullName;
             sessionStorage.setItem('userName',this.userName);
             sessionStorage.setItem('fullName',this.fullName);
             sessionStorage.setItem('salesTeamFlag',data.salesTeamFlag);
             sessionStorage.setItem('tcId',data.tcId);
             sessionStorage.setItem('oTwoFacAuthFlg',data.oTwoFacAuthFlg);
             sessionStorage.setItem('pwdChangeFlag',data.pwdChangeFlag);
             sessionStorage.setItem('route',data.route);
             sessionStorage.setItem('email',data.emailAddress); 
             sessionStorage.setItem('phoneNumber',data.phoneNumber); 
             sessionStorage.setItem('firstName',data.firstName); 
             sessionStorage.setItem('lastName',data.lastName); 
             
            return data;
          }
        )
      );
  }   
  logout() {

    const httpOptions = {
      headers: new HttpHeaders({
      }).set('Content-Type', 'application/json')
    };
    return this.http.get(this.defaultUrl + 'logout').subscribe(data => {
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('fullName');
      sessionStorage.removeItem('salesTeamFlag');
      sessionStorage.removeItem('tcId');
      sessionStorage.removeItem('oTwoFacAuthFlg');
      sessionStorage.removeItem('route');
      sessionStorage.removeItem('pwdChangeFlag');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('phoneNumber');
     // sessionStorage.removeItem('twoFactorAuthCode');
      sessionStorage.removeItem('myBuild');
      sessionStorage.removeItem("tfaSuccess");
      sessionStorage.removeItem("firstName");
      sessionStorage.removeItem("lastName");
      console.log("logout url successoutput :" + data);
      this.router.navigate(['login']);
    });
  }
  forgotPassword= function (data){
    let reqUrl=this.defaultUrl+"login";
    let url = this.defaultUrl + "vsnwu?resetusername="+data.resetusername+"&serial="+data.serial+"&reqUrl="+reqUrl;

    return this.http
    .post(url, data, httpJsonOptions)
    .pipe(catchError((this.util.handleError("forgotPassword"))));
  }
  resetPassword = function (data) {
    // var resetPasswordPromise = http.postJsonData('common.htm/resetPassword', data);
    let initialUrl = this.defaultUrl + "resetPassword?resetusername="+data.resetusername+"&newPassword="+data.newPassword+"&oldPassword="+data.oldPassword;

    return this.http
      .post(initialUrl, '', httpJsonOptions)
      .pipe(catchError((this.util.handleError("resetPassword"))));
  }

  verifyTwoFactorAuthCode(oData) : Observable<any> {
    let url = this.defaultUrl + 'verifyTwoFactorAuthCode';   
    return this.http
      .post(url , oData, httpJsonOptions)
      .pipe(catchError(this.util.handleError("verifyTwoFactorAuthCode")));
  }

  twoFactorAuthentication() : Observable<any> {
    let url = this.defaultUrl + 'twoFactorAuthentication';   
    return this.http
      .post(url , '', httpJsonOptions)
      .pipe(catchError(this.util.handleError("twoFactorAuthentication")));
  }

  generateVerCodeAndSendEmail() : Observable<any> {
    let url = this.defaultUrl + 'generateVerCodeAndSendEmail';   
    return this.http
      .post(url , '', httpJsonOptions)
      .pipe(catchError(this.util.handleError("generateVerCodeAndSendEmail")));
  }
}

  export class AuthResponse {

	  constructor(public authenticated: boolean,public userName:string,public userFullName:string) {
    }
}

export class ResetResponse {

  constructor(public status:string,public statusFlag:string) {
  }
  

}