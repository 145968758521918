import { Component, OnInit } from '@angular/core';
import { DefineLevelsService } from './define-lookups.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Component({
  selector: 'app-define-lookups',
  templateUrl: './define-lookups.component.html',
  styleUrls: ['./define-lookups.component.css']
})
export class DefineLookupsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  orderCol: any;
  orderDir: any;
  view: any='lookupSearch';
  lookupName:any="";
  count:any=0;
  lookupserachList:any=[];
  recPerPage=10;
  pageNum:any=1;
  numOfRecsMsg:any="";
  arrPgLinks:any=[];

  constructor(private defineLevelsService: DefineLevelsService ,private commonService:CommonServiceService,private router: Router ) { }

  ngOnInit() {
    this.lookupSearch();


  }

lookupSearch() {
  this.blockUI.start();
var param = {
			"view"  : this.view,
      "orderCol" : this.orderCol,
      "orderDir" : this.orderDir
		};

var reqBody ={
  "lookupName" : this.lookupName,
  "viewName":this.view
}   
 
  this.defineLevelsService.getlookupSearch(param,reqBody).subscribe(
    data=>{
      console.log(data);
      this.lookupserachList=data.Views;
      this.count= data.count;
      this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.count,this.recPerPage,this.pageNum);
      this.arrPgLinks=this.commonService.getPgLinks(this.count,this.pageNum,this.recPerPage);
      this.blockUI.stop();

    }
  );

	

}

createLookup(){
  // Need to add functionality here.
  this.router.navigate(['createlookup']);

}

clearSearch(){
  this.lookupName="";
  this.pageNum=1;
  this.lookupSearch();

}
lookupDefinition(lookupId){
console.log("Printing lookup Id:" +lookupId);
this.router.navigate(['/createlookup'], { queryParams: { lookupId: lookupId} });

}

}
