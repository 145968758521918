import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from 'src/app/app.util';
import { UserSearchService } from './user-search.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {
  @ViewChild('crModal') crModalTemplate:TemplateRef<any>;
  crModal: BsModalRef;

  @BlockUI() blockUI : NgBlockUI;
  numOfRecsMsg:string="";
  userName:string = '';
  userList:any = [];
  selectedUser:any = [];
  pageNum:any=0;
  totalRecords:any=0;
  recPerPage:any=10;
  start:number=1;
  end:number=10;
  arrPgLinks:any=[];
  showPwd:boolean = false; 
  checkRegex:boolean = true; 
  newPwd = '';
  message: string = "";
  isUpdtPwdChecked: boolean = false;

  constructor(private commonService:CommonServiceService, private userSearchService:UserSearchService, private util : Util,
    private modalService: BsModalService) { }

  ngOnInit() {
  }

  clear() {
    this.numOfRecsMsg="";
    this.userName = '';
    this.userList = [];
    this.selectedUser = [];
    this.pageNum=0;
    this.totalRecords=0;
    this.recPerPage=10;
    this.start=1;
    this.end=10;
    this.arrPgLinks=[];
    this.userName = '';
    this.newPwd = '';
  }

  saveUser() {
    let params = {
      "username" : this.selectedUser.userName,
      "editFlg" : 'Y',
      "firstName" : this.selectedUser.firstName,
      "lastName" : this.selectedUser.lastName,
      "defaultAppCode" : this.selectedUser.defaultAppCode,
      "defaultCustAccount" : this.selectedUser.defaultCustAccount,
      "defaultRole" : '',
      "description" : this.selectedUser.description,
      "email" : this.selectedUser.email,
      "phoneNumber" : this.selectedUser.phoneNumber,
      "newPwd" : this.newPwd,
      "updPwdFlag": this.isUpdtPwdChecked ? "Y" : "N"
    };
    
    let data = this.userSearchService.updateUser(params).subscribe(
      data=>{
        console.log(data);
        this.crModal.hide();
      });

  }

  updatePwd(evt) {
    var target = evt.target;
    if (target.checked) {
      this.showPwd = true;
      this.checkRegex = false;
      this.message = "";
      this.newPwd = "";
    } else {
      this.showPwd = false;
    }
  }

  validateInput() {
    let inputText = this.newPwd;

    var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
    var enoughRegex = new RegExp("(?=.{8,}).*", "g");
    
    var noError = false;
    if (false == enoughRegex.test(inputText)) {
      this.checkRegex  = false;
      this.message = "Password should have minimum 8 characters.";  
    }else if (mediumRegex.test(inputText)) {  
      this.checkRegex = true;
      this.message = "Strong password";
      noError = true;
    } else {
      this.checkRegex  = false;
      this.message = "Password should have 1 Uppercase, 1 Lowercase , 1 Number";  
    }
   
}

  loadEditModal(row) {
    this.selectedUser = row;
    this.showPwd = false;
    this.checkRegex = true;
    this.message = "";
    this.newPwd = "";
    this.isUpdtPwdChecked = false;
    this.crModal = this.modalService.show(this.crModalTemplate);
  }

  cancelEditModal() {
    this.crModal.hide();
  }

  search(pgNum: any,event: any) {
    if(event!=null){
      event.preventDefault();
    }

    this.blockUI.start();
    this.pageNum=pgNum;

    let params = {
      "userName": this.userName.toUpperCase(),
      "pageNum" :this.pageNum,
      "recPerPage" : this.recPerPage
    };
    
    let data = this.userSearchService.getUserList(params).subscribe(
      data=>{
        this.userList = data.userList;
        this.blockUI.stop();
        this.totalRecords=data.totalRecords;
         this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
         this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage); 
      });
  }
}
