import { Component, OnInit } from '@angular/core';
import { AlertService } from './alert.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
url:any="";
  constructor(private alertService:AlertService, private sanitizer: DomSanitizer ) { }

  ngOnInit() {
    document.cookie="ASP.NET_SessionId=0fcj4azfvgzzljx453cv2bzu; .Stackify.Rum=1cc97ed3-3d02-446f-9237-a7366f3658f0";
    // call the service and do subsscribe(
// data=> {url=data;  }
    //);//
    this.blockUI.start();
this.alertService.getUrl().subscribe(
  
  data=> {

    console.log("Printing url: ");
    console.log(data);
    //this.url = data.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    this.blockUI.stop();
  }
  
);

  }





}
