import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from '../../service/common-service/login-authentication.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { RegisterService } from '../../register-user/register.service';
import { canonRegisterUser } from '../bean/canonRegisterUser';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  errorMessage = 'Invalid Credentials';
  invalidLogin = false;
  showModal = false;
  messageColor: string = "";
  message: string = "";
  errorList: string = "";
  btnSubmitted = false;
  route: string;
  @BlockUI() blockUI: NgBlockUI;
  resetusername: any;
  serial: any;
  oldPassword: any;
  newPassword: any;
  confirmPassword: any;

  myForm: FormGroup;
  registerUser: canonRegisterUser;
  displayText: string = "";
  serialNumberDisplayText: string = "";
  serialNumberAndZipCodeDisplayText: string = "";
  quickAccessTypeDisplayText: string = "";
  adminAccessTypeDisplayText: string = "";
  inputEmail: string;
  serialNumber: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  // accessType: string="flexRadioDefault1";
  serialNumberHiddenValue: boolean;
  zipCodeHiddenValue: boolean;
  firstNameDisabledValue: boolean;
  lastNameDisabledValue: boolean;
  phoneNumberDisabledValue: boolean;
  submitButtonDisabledValue: boolean;
  serialNumberDisabledValue: boolean;
  userIdle: any;
  loggedUserName: string;
  zipCodeErrorHiddenValue:boolean;
  accessTypeDisabledValue: boolean;

  @ViewChild('registerModal') registerTemplate:TemplateRef<any>;
  registerModal: BsModalRef;
  
  constructor(private router: Router, private loginAuthenticationService: LoginAuthenticationService, private titleService: Title, private registerService: RegisterService, private formBuilder: FormBuilder, private location: Location, private modalService: BsModalService) {
    this.registerUser = new canonRegisterUser();
    this.displayText = ""

  }
  currentYear: number = new Date().getFullYear();
  ngOnInit() {
    this.titleService.setTitle("Login");
    this.errorMessage = '';
    
    this.resetRegisterData();

    this.loggedUserName = this.loginAuthenticationService.getAuthenticatedUser();

    //, phoneNumber: new FormControl("", Validators.pattern('^[0-9]{10}$'))
    this.myForm = this.formBuilder.group({
      inputEmail: new FormControl("", [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")])
      , serialNumber: new FormControl("", Validators.required)
      , zipCode: new FormControl("", Validators.required)
      , inputFirstName: new FormControl("", Validators.required)
      , inputLastName: new FormControl("", Validators.required)
      , phoneNumber: new FormControl("", Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}$'))
      , accessType: new FormControl("N", Validators.required)
    });

    // this.myForm = new FormGroup({

    //   inputEmail: new FormControl("",[Validators.required,Validators.email])
    //   ,serialNumber: new FormControl("",Validators.required)
    //   ,zipCode: new FormControl("",Validators.required)
    //   ,inputFirstName: new FormControl("",Validators.required)
    //   ,inputLastName: new FormControl("",Validators.required)
    //   ,phoneNumber: new FormControl("",Validators.pattern('^\d{10}$'))
    //   ,accessType: new FormControl("",Validators.required)


    // })


    if($(".section-wrapper").length>0){
        $(".section-wrapper").removeClass("section-wrapper");
      }
   }

  userLogin() {
    //this.showLoader =true;
    this.blockUI.start();
    this.loginAuthenticationService.executeJDBCAuthenticationService(this.username.trim(), this.password.trim()).subscribe
      (data => {
        console.log(data)
        console.log("LoginComponent userlogin Authenticate:" + data.authenticated);
        
        if (data.authenticated == true) {
       
          if (data.pwdChangeFlag == "Y") { 
            if(data.tcId != '0' && data.tcId != null)
            {
              this.router.navigate(['termsconditions']);
            }
            else
            {
              this.router.navigate(['resetpassword']);
            }
          }
          else if (data.oTwoFacAuthFlg == "N") {
            this.loginAuthenticationService.twoFactorAuthentication().subscribe(
              dataAuthCode => {
                var tfaSuccess = dataAuthCode.tfaSuccess;
                this.blockUI.stop();
                if (tfaSuccess == 'N') {
                  sessionStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
                  sessionStorage.setItem("otpRequestTime", dataAuthCode.otpRequestTime);
                  sessionStorage.setItem("tfaSuccess", tfaSuccess);
                  this.errorMessage = '';
                  this.router.navigate(['twoFactor']);
                  //this.closeLoginModal(this.loggedUserName);        
                  sessionStorage.setItem("route", data.route);
                } else {
                  // this.userIdle.startWatching();
                  this.errorMessage = '';
                  this.invalidLogin = false;
                  // this.closeLoginModal(this.loggedUserName);                       
                  this.router.navigate(['home']);


                }
              }
            );
          }
          else {
            this.router.navigate(['home']);
          }
        }
        else {
          this.errorMessage = '';
          this.invalidLogin = true;
          this.errorMessage = "Invalid username and/or password";
          this.blockUI.stop();
          // console.log("Error message:"+data.errorMessage);
        }

        this.blockUI.stop();
      },
        error => {
          console.log(error);
          this.errorMessage = 'Invalid username and/or password';
          this.invalidLogin = true;
          this.blockUI.stop();
        }      
      );


  }
  closeLoginModal(loggedUserName: any) {
    throw new Error('Method not implemented.');
  }
  register() {

    if (this.myForm == undefined) {
      console.log("Undefined.......................>>>>>");
    } else {
      // this.myForm.valueChanges
      console.log(this.myForm.value)

      
      

      this.registerUser.emailAddress = this.myForm.value["inputEmail"];
      this.registerUser.serialNumber = this.myForm.value["serialNumber"];
      this.registerUser.zipCode = this.myForm.value["zipCode"];
      this.registerUser.firstName = this.myForm.value["inputFirstName"];
      this.registerUser.lastName = this.myForm.value["inputLastName"];
      this.registerUser.phoneNumber = this.myForm.value["phoneNumber"];

      console.log("accesstype.......................>>>>>")
      // console.log(this.accessType);

      // this.registerUser.adminOpt = this.accessType === 'flexRadioDefault2' ? 'Y' :'N' ;
      this.registerUser.adminOpt = this.myForm.value["accessType"];
      this.blockUI.start();
      this.registerService.addRegisterUser(this.registerUser).subscribe((response) => {
        this.blockUI.stop();

        // console.log(response);

        // this.registerStatus = response;


        if (response.registerStatus.statusFlg === 'D' || response.registerStatus.statusFlg === 'S') {

          console.log('Status is ..........' + response.registerStatus.statusFlg);
          // this.displayText = this.sanitized.bypassSecurityTrustHtml(`<div>Hello....</div>`);
          this.displayText = `<div class="row submissionSuccess" style="margin-top: 5px;border-bottom:0px;">
        <div class="row mycsa-obj-side-wrap">
          <div class="alert alert-success" role="alert">
            <span style="font-family:'OpticSansMedium'; font-size: 16px;">Registration Confirmation<br><br>
            Thank you for Registering. A Canon U.S.A. Representative will be in contact with you should further 
            information be required to establish your account. You will be notified with your password as soon as your account is active.</span>
          </div>
        </div>
      </div>`;

          // console.log(this.displayText);
        }
        else if (response.registerStatus.statusFlg === 'E') {
          console.log('Status is E ..........');
          // this.displayText = this.sanitized.bypassSecurityTrustHtml(`<div>Hello....</div>`);
          this.displayText = `<div class="row submissionError" style="display: none;">
        <div class="row mycsa-obj-side-wrap">
          <div class="alert alert-danger" role="alert">
            <span style="font-family:'OpticSansMedium'; font-size: 16px;">
            <!-- Request Submission Failed!! Please try again at a later time. -->
              Due to a processing issue, we can not create your account at this time.<br>
                 Please contact our Web Administrator at MyCanonBusiness@cusa.canon.com to register.
            </span>
          </div>
        </div>
      </div>`;
        }

        this.quickAccessTypeDisplayText="";
        this.adminAccessTypeDisplayText="";
        this.myForm.reset();
        this.myForm.disable();
        this.submitButtonDisabledValue = true
        

      }
      
      );
    }



  }

  onKeyEmail(event: any) {
    this.submitButtonDisabledValue = true;
    this.myForm.get('serialNumber').setValue('');
    this.blockUI.start();
   // if (!event.shiftKey && (event.key === "Tab" || event.key === "Enter")) {
      console.log("tab keydown.........");
      console.log(this.inputEmail)
      // console.log(this.serialNumber)

      this.registerService.validateEmail(this.inputEmail).subscribe((response) => {
        console.log(response)
        this.blockUI.stop();
        if(response.flag == 'N'){
          this.serialNumberHiddenValue = false;
          this.serialNumberDisabledValue = false;
          setTimeout(function(){
            let srlInp: HTMLInputElement = <HTMLInputElement>document.querySelector('#serialNumber');
            srlInp.focus();   
          },1000);
           

        }else{
          this.serialNumberHiddenValue = true;
          this.serialNumberDisabledValue = true;
        }
        this.onKey(event);

      })
    //}
  }

  onKeySerialNumber(event: any) {
   
    this.serialNumber = this.serialNumber.trim();
    this.submitButtonDisabledValue = true;
    this.myForm.get('zipCode').setValue('');
    //if (!event.shiftKey && (event.key === "Tab" || event.key === "Enter")) {
      console.log("tab keydown.........");
      // console.log(this.inputEmail)
      console.log(this.serialNumber)

      if (this.serialNumber) {
        this.blockUI.start();

        this.registerService.validateSerialNumber(this.serialNumber).subscribe((response) => {
          console.log(response)
          console.log(response.orgList.data.length)

          if (response.orgList.data.length === 1) {
            this.zipCodeHiddenValue = true;
            this.firstNameDisabledValue = null;
            this.lastNameDisabledValue = null;
            this.phoneNumberDisabledValue = null;
            this.accessTypeDisabledValue = null;
            this.serialNumberDisplayText = "";

            this.registerUser.source = response.orgList.data[0].source;
            this.registerUser.defCustAccount = response.orgList.data[0].partyNumber;
            
          } else if (response.orgList.data.length > 1) {

            /* if(this.myForm.get("zipCode").value != ''){
              this.onKeySerialNumberAndZipCode(event);
            }else{ */
              this.zipCodeHiddenValue = false;
              this.firstNameDisabledValue = true;
              this.lastNameDisabledValue = true;
              this.phoneNumberDisabledValue = true;
              this.accessTypeDisabledValue = true;
            //}

          }
          else {
            this.serialNumberDisplayText = "<div>Invalid Serial Number</div>";
            this.firstNameDisabledValue = true;
            this.lastNameDisabledValue = true;
            this.phoneNumberDisabledValue = true;
            this.accessTypeDisabledValue = true;
          }

          this.blockUI.stop();

        })
      }
    //}
    /* else {
      console.log("serial number is empty");
      this.serialNumberDisplayText = "";
    } */
    this.onKey(event);

  }
  onKeySerialNumberAndZipCode(event: any) {
    this.submitButtonDisabledValue = true;
    //if (!event.shiftKey && (event.key === "Tab" || event.key === "Enter")) {
      console.log("tab keydown.........");
      // console.log(this.inputEmail)
      console.log(this.serialNumber)

      if (this.serialNumber) {
        if (this.zipCode) {
          this.blockUI.start();
          this.registerService.validateSerialNumberAndZipCode(this.serialNumber, this.zipCode).subscribe((response) => {
            console.log(response)
            console.log(response.orgList.data.length)

            if (response.orgList.data.length === 1) {
              // this.zipCodeHiddenValue = true;
              this.firstNameDisabledValue = null;
              this.lastNameDisabledValue = null;
              this.phoneNumberDisabledValue = null;
              this.accessTypeDisabledValue = null;
              this.serialNumberDisplayText = "";

              this.registerUser.source = response.orgList.data[0].source;
              this.registerUser.defCustAccount = response.orgList.data[0].partyNumber;
              this.zipCodeErrorHiddenValue=true;
              
            } else if (response.orgList.data.length > 1) {
              this.zipCodeHiddenValue = false;
              this.firstNameDisabledValue = true;
              this.lastNameDisabledValue = true;
              this.phoneNumberDisabledValue = true;
              this.accessTypeDisabledValue = true;
              this.zipCodeErrorHiddenValue=false;
              this.serialNumberAndZipCodeDisplayText = "<div>Not Found. Please enter a valid Serial number and corresponding zip code.</div>";
            }
            /* else {
              this.serialNumberAndZipCodeDisplayText = "<div>Not Found. Please enter a valid Serial number and corresponding zip code.</div>";
              this.firstNameDisabledValue = true;
              this.lastNameDisabledValue = true;
              this.phoneNumberDisabledValue = true;
              this.accessTypeDisabledValue = true;
              this.zipCodeErrorHiddenValue=false;

            } */

            this.blockUI.stop();

          })
        }
        
      }
      else {
        console.log("serial number is empty");
        this.serialNumberDisplayText = "";
      }
      this.onKey(event);

    //}

  }

  onKey(event: any) {
    console.log("form values");
    console.log(this.myForm.value["inputEmail"]);
    console.log(this.myForm.value["serialNumber"]);
    console.log(this.myForm.value["zipCode"]);
    console.log(this.myForm.value["inputFirstName"]);
    console.log(this.myForm.value["inputLastName"]);


    let validForm = this.isFormValid()

    if(validForm){
      this.submitButtonDisabledValue = false;
      this.firstNameDisabledValue = null;
      this.lastNameDisabledValue = null;
      this.phoneNumberDisabledValue = null;
      this.accessTypeDisabledValue = null;
      console.log("submit button enabled");
    }else{
      console.log("submit button disabled");
      this.submitButtonDisabledValue = true;

      if(!this.myForm.get('inputEmail').valid){
        this.myForm.get("serialNumber").reset();
        this.myForm.get("zipCode").reset();
        this.myForm.get("inputFirstName").reset();
        this.myForm.get("inputLastName").reset();
        this.myForm.get("phoneNumber").reset();
        this.serialNumberHiddenValue = true;
        this.serialNumberDisabledValue = true;
        this.zipCodeHiddenValue = true;
        this.firstNameDisabledValue = true;
        this.lastNameDisabledValue = true;
        this.phoneNumberDisabledValue = true;
        this.accessTypeDisabledValue = true;
      } else if(!this.myForm.get('serialNumber').valid){
        this.myForm.get("zipCode").reset();
        this.myForm.get("inputFirstName").reset();
        this.myForm.get("inputLastName").reset();
        this.myForm.get("phoneNumber").reset();
        this.zipCodeHiddenValue = true;
        this.firstNameDisabledValue = true;
        this.lastNameDisabledValue = true;
        this.phoneNumberDisabledValue = true;
        this.accessTypeDisabledValue = true;
      }else if(!this.zipCodeHiddenValue && !this.myForm.get('zipCode').valid){
        this.myForm.get("inputFirstName").reset();
        this.myForm.get("inputLastName").reset();
        this.myForm.get("phoneNumber").reset();
        this.firstNameDisabledValue = true;
        this.lastNameDisabledValue = true;
        this.phoneNumberDisabledValue = true;
        this.accessTypeDisabledValue = true;
      }
    }
 
   /*  if (this.myForm.value["inputEmail"]
      && ((this.myForm.value["serialNumber"] && this.myForm.value["zipCode"]) || (this.myForm.value["serialNumber"] || this.myForm.value["zipCode"]))
      && this.myForm.value["inputFirstName"]
      && this.myForm.value["inputLastName"]) {
      this.submitButtonDisabledValue = false;
      console.log("submit button enabled");
    } else {
      console.log("submit button disabled");
      this.submitButtonDisabledValue = true;
    } */

  }

  ngOnDestroy() {
    this.myForm.reset();
  }

  onClickQuickAccessTypeHelp() {
    this.adminAccessTypeDisplayText="";
    this.quickAccessTypeDisplayText = this.quickAccessTypeDisplayText != "" ? "" : "display";
  }

  onClickAdminAccessTypeHelp() {
    this.quickAccessTypeDisplayText = "";
    this.adminAccessTypeDisplayText = this.adminAccessTypeDisplayText != "" ? "" : "display";
  }

  resetRegisterData(){
    this.serialNumberHiddenValue = true;
    this.zipCodeHiddenValue = true;
    this.firstNameDisabledValue = true;
    this.lastNameDisabledValue = true;
    this.phoneNumberDisabledValue = true;
    this.accessTypeDisabledValue = true;
    this.submitButtonDisabledValue = true;
    this.serialNumberDisabledValue = true;
    this.zipCodeErrorHiddenValue=true;
    this.serialNumberAndZipCodeDisplayText = '';
    this.displayText = '';
    this.serialNumberDisplayText = '';
  }

  onCloseModal() {
    //location.reload();
    this.myForm.reset(
      {
        accessType:'N'
      }
    );
    this.resetRegisterData();
    this.registerModal.hide();
  }
  resetPW() {
    $("#btnSU").show();
    (<any>$("#pwdModal")).modal('show');
    $("#resetPwdOuterDiv").hide();
    $("#resetPwdInnerDiv").show();
    $("#resetusername ,#serial ,#newPassword , #confirmPassword").val("");

    $("#errMsg").hide();

  }
  sendPwdEmail() {
    this.message = '';
    var forgotpwdinputParams = {
      resetusername: this.resetusername,
      serial: this.serial
    };
    this.loginAuthenticationService.forgotPassword(forgotpwdinputParams).subscribe
      (data => {
        console.log(data);
        console.log("Forgot Password component data:" + data);
        if (data.errFlg == "N") {
          sessionStorage.setItem('serial', this.serial);
          $("#resetPwdOuterDiv").show();
          $("#resetPwdInnerDiv").hide();
          $("#btnSU").hide();
          //window.location.href=data.pageName;
          // this.router.navigateByUrl(data.pageName);
        }
        else {
          $("#errMsg").show();
          this.messageColor = "alert alert-danger";
          this.message = data.errMsg;
        }
      },
        error => {
          console.log(error);

        }
      );
  }

  openModal() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.myForm.enable();
    this.registerModal = this.modalService.show(this.registerTemplate, ngbModalOptions);
    
  }

  isFormValid(){
    let validForm = true;
    const controls = this.myForm.controls;
    for (const name in controls) {

        if(name == 'zipCode'){
          if(!this.zipCodeHiddenValue && controls[name].invalid){
            validForm = false;
          }
        }else{
          if (controls[name].invalid) {
            validForm = false;
          }
        
        }
    }

    return validForm;
      
  }
}





