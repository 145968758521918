import { Component, Input, OnInit } from '@angular/core';
import { ManagePriceBookService } from './manage-pricebook.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';

@Component({
  selector: 'app-manage-pricebook',
  templateUrl: './manage-pricebook.component.html',
  styleUrls: ['./manage-pricebook.component.css']
})
export class ManagePricebookComponent implements OnInit {

  constructor(private commonService:CommonServiceService, private managePriceBookService: ManagePriceBookService) { }

  @BlockUI() blockUI : NgBlockUI;

  levelsList:any = [];  
  templateId:any;
  labelLineId:any;
  partyId:any;
  priceList:any = [];
  servicelist:any =[];
  servicelistId:any;
  itemDesc:any;
  itemCode:any;
  priceListId:any;
  orderCol:any;
  orderDir:any;
  length1:any;
  printBookList:any=[];
  printBookCount:any;
  recPerPage=10;
  pageNum=1;
  numOfRecsMsg:any="";

  bundleDetails = [];
  bundleModelImg="";
  bundleName = "";
  bundleItemNum= "";
  arrPgLinks:any=[];

  @Input() param :any;
  ngOnInit() {
   console.log("param partyId :"+this.param.partyId);
   console.log("param templateId :"+this.param.templateId);
    this.templateId = this.param.templateId;
	this.labelLineId = -1;
	this.partyId = this.param.partyId;
 this.itemDesc ="";
  this.itemCode ="";
  this.priceListId = -1;
  this.orderCol = "itemDescription"
  this.orderDir = "asc";
  this.length1 = 10;
  this.servicelistId=-1;


  this.getPriceListLOV();
  this.getPriceBookList();



  }

  getPriceListLOV() {
    var $el = $("#pricelist_type");
	var $elServ = $("#servicelist_type");
	$el.empty();
	$el.append($("<option selected disabled></option>").attr("value", "-1").text("Select PriceList Type"));
	$el.append($("<option></option>").attr("value", "-1").text("ALL"));
	$elServ.append($("<option selected disabled></option>").attr("value", "-1").text("View service list"));
	
		var param = {
			"partyId"  : this.partyId,
		};
			
		this.managePriceBookService.getPriceListLOV(param).subscribe(resp => {
			if (resp != null) {
				console.log('getPriceListLOV():', resp);
				//this.priceList = resp.responseList[0];
				let map = new Map(Object.entries(resp.responseList[0]));
				map.forEach((values, keys) => {
					var entry = {
						"id" : keys,
						"name" : values
					}
					this.priceList.push(entry);
				});

        let map1 = new Map(Object.entries(resp.responseList[1]));
				map1.forEach((values, keys) => {
					var entry = {
						"id" : keys,
						"name" : values
					}
					this.servicelist.push(entry);
				});
        this.priceList.forEach((price) => {
          $el.append($("<option></option>")
          .attr("value", price.id).text(price.name));
      });

      this.servicelist.forEach((service) => {
        $elServ.append($("<option></option>")
        .attr("value", service.id).text(service.name));
    });
					
			}
		});		
	
  }

  getPriceBookList(){
    var param = {
      "partyId" : this.partyId,
			"templateId"  : this.templateId,
      "itemDesc" : this.itemDesc,
      "itemCode" : this.itemCode,
      "priceListId": this.priceListId,
      "orderCol": this.orderCol,
      "orderDir": this.orderDir,
      "start":  (this.pageNum == 1 ) ? 0 : ( (this.pageNum -1 ) * (this.recPerPage) ),
      "length": this.length1,
		};

    this.managePriceBookService.getPriceBookList(param).subscribe(data => {
      if (data != null) {
        this.printBookList=data.data;
        this.printBookCount= data.iTotalRecords;
        console.log(this.printBookList);

        this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.printBookCount,this.recPerPage,this.pageNum);
        this.arrPgLinks=this.commonService.getPgLinks(this.printBookCount,this.pageNum,this.recPerPage);
        console.log("Printing message : "+ this.numOfRecsMsg);
      }

    });
  }

  upsertPricebookDetails(priceBookEntry, pbChkBox: any){
	
    var param = {
      "invItemId"	: priceBookEntry.itemId,
      "item"		: priceBookEntry.item,
      "itemDesc"	: priceBookEntry.itemDescription,
      "model"		: priceBookEntry.model,
      "templateId": this.templateId + "",
      "partyId"	: this.partyId + "",
      "labelLineId":this.labelLineId + "",
      "bundleName": priceBookEntry.bundleName, 
      "exclude"	: (pbChkBox.checked) ? "EXCLUDE" : "INCLUDE",	
    };
    
    console.log('upsertPricebookDetails()', param);
    this.blockUI.start();
    this.managePriceBookService.upsertPricebookDetails(param).subscribe(resp => {
      console.log('upsertPricebookDetails() -> resp : ', resp);
      this.blockUI.stop();
    });
    
    }


    getBundleDetails(book) {
	
      var param = {
        "priceListId" : book.priceListId,
        "bundleName"  : book.bundleName	
      };
      
      this.managePriceBookService.getBundleDetails(param).subscribe(resp => {
        console.log('getBundleDetails() -> resp : ', resp);
        this.bundleModelImg = "https://mycanonbusiness.usa.canon.com/cpImages/config/"+book.model + ".jpg";
        this.bundleName = book.bundleName;
        this.bundleItemNum = book.item;
        this.bundleDetails = resp.data;
      });	
      }


      getPriceBookListPagination(pageNumber, event:any){
        this.pageNum= pageNumber;



      }


      clearData(){
        this.itemDesc="";
        this.itemCode="";
        this.priceListId=-1;
        this.servicelistId=-1;
        this.pageNum=1;
        this.getPriceBookList();


      }

}
