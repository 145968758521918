import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MeterHistoryService } from './meter-history.service';


@Component({
  selector: 'app-meter-history',
  templateUrl: './meter-history.component.html',
  styleUrls: ['./meter-history.component.css']
})
export class MeterHistoryComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  clear() {
    throw new Error('Method not implemented.');
  }
  getMeterReadsHistory(arg0: number) {
    throw new Error('Method not implemented.');
  }
  msgClass = "";	
  invoiceForm: FormGroup;
  metreRedeData: any = {};
  //srhSerialNum: any="";
  length: any = 1;
  isOpen= true;
  //myMeterHistList:any=[];
  meterReadList: any = [];
  billHistorydetails: any = [];
  machinedetails: any = [];
  meterReadTicket: any = {};
  responseSubmit: any = {};
  assignedTos: any = [];
  invoiceReadDetailsList: any = [];
  modelList: any = [];
  srhSerialNum: string = "";
  srchModelNum: string = "";
  srchInstLocn: string = "";
  selectedUserAccts: string = "";
  srhAssignedTo: string = "";
  pageNum: any = 0;
  userAccountList = [];
  //pleaseWaitCls: boolean = false;
  numOfRecsMsg: string = "";
  totalRecords: any = 0;
  recPerPage: any = 10;
  start: any = 1;
  end: any = 10;
  arrPgLinks: any = [];
  partyIDs: any = [];
  model: string = "";
  sortColumn: string = "";
  orderDir: string = "";
  isVisible = [];
  srlInvReadDetails: any = {};
  meterReadErrorMsg: string = '';
  errNewMsg: any = "";
  userList = [];
  assignedUserList=[];
  meterReadNumber =0;
  salesUser:boolean=false;
  meterReadContractID ="";

  constructor(private meterHistoryService: MeterHistoryService, private commonService: CommonServiceService, private formBuilder: FormBuilder,
    private router: Router) { }

  async ngOnInit() {
    this.blockUI.start();
    await this.getModelLists();
    this.createInvoiceForm();
    this.commonService.setCommonFunctions();
    this.blockUI.stop();
    this.salesUser=this.commonService.isSalesUser();
  }

  callMetereReadHistory(param) {
    console.log(param);
  }
  // expandCollapse (){
  //   this.isOpen =!this.isOpen;
  // }
  createInvoiceForm() {
    this.invoiceForm = this.formBuilder.group({
      readDate: new FormControl("", [Validators.required])
      , curCounterRd: new FormControl("")
      , curTotalRd: new FormControl("", Validators.required)
      , comment: new FormControl("")
    });
  }

  getMeterReadHistoryList(pgNum: any, event: any) {
   
    this.isVisible[this.meterReadNumber] =false;
       if (event != null) {
      event.preventDefault();
    }
    //this.pleaseWaitCls = true;
    this.pageNum = pgNum;
    this.selectedUserAccts = this.commonService.getSelectedUserAccounts(this.userAccountList);
    let oParams = {
      "srhSerialNum": this.srhSerialNum, "srchModelNum": this.srchModelNum, "srchInstLocn": this.srchInstLocn,
      "srhAssignedTo": this.srhAssignedTo, "sortColumn": this.sortColumn, "orderDir": this.orderDir,
      "partyIDs": this.selectedUserAccts, "recPerPage": this.recPerPage, "pageNum": pgNum
    };
    this.blockUI.start();
    this.meterHistoryService.getMeterReadsHistory(oParams).subscribe(
      (res: any) => {
        this.meterReadErrorMsg = res.meterList.length < 1 ? "No entries found." : ''
        this.meterReadList = res.meterList;
        this.totalRecords = res.totalItems;
        this.numOfRecsMsg = this.commonService.getNumOfRecsMsg(this.totalRecords, this.recPerPage, this.pageNum);
        this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords, this.pageNum, this.recPerPage);
       // this.pleaseWaitCls = false;
       console.log("this.arrPgLinks    == " + this.arrPgLinks + "    this.pageNum  " + this.pageNum  +
       "        this.recPerPage    " + this.recPerPage);
       this.blockUI.stop();
      },
      (error: any) => {
        this.meterReadErrorMsg = error ? "No entries found." : ''
      }
    );
    
  }


  // getMeterReadsHistoryList(pgNum: any, event: any) {
  //   if (event != null) {
  //     event.preventDefault();
  //   }
  //   let data = this.meterHistoryService.getMeterReadsHistory(null).subscribe(
  //     data => {
  //       this.srhAssignedTo = "ABELL.HIBLER@KATTENLAW.COM";
  //     });

  // }


  async getModelLists() {
    this.blockUI.start();
    await this.meterHistoryService.getModelList().subscribe(
      res => {
        this.modelList = res.modelList.split("|");
        this.assignedTos = res.userDataRset;
        this.srchModelNum = "";
        this.srhAssignedTo = "";        
        this.blockUI.stop();
        this.getUserAccounts();
        
      });
       
  }


  getUserAccounts() {
    this.blockUI.start();
    this.commonService.getUserAccounts().subscribe(
      (res: any) => {
        this.userAccountList = res.data;
        this.partyIDs = this.userAccountList.map((party) => { return party.partyId ? party.partyId : '' });
        this.blockUI.stop();
        this.getMeterReadHistoryList(1, null);
        
      });
      
  }



  toggleDiv(event: any, serialNumber: any) {
    
    event.preventDefault();
    this.meterReadNumber = event.currentTarget.id;
    this.isVisible[event.currentTarget.id] = this.isVisible[event.currentTarget.id] ? false : true;
    if (this.isVisible[event.currentTarget.id] === true) {
      this.blockUI.start();
      this.meterHistoryService.getSrlInvReadDetails(serialNumber).subscribe(
        (res: any) => {
          res.res.forEach((invoice) => {
            this.srlInvReadDetails = invoice;
            this.blockUI.stop();
          });
        });
        this.invoiceForm.reset();
        this.responseSubmit.success = '';

    }
   
  }


  clearSearch(event: any) {
    if (event) {
      event.preventDefault();
    }
    this.srhSerialNum = "";
    this.srchModelNum = "";
    this.srchInstLocn = "";
    this.srhAssignedTo = "";
    for (let actIdx in this.userAccountList) {
      this.userAccountList[actIdx]["enabled"] = true;
    }
    this.getMeterReadHistoryList(1, null);
  }


  getMeterReadValue(value, isCurrent) {
    var readArr = value.split('@');
    var curRead = parseInt(readArr[1]);
    var prevRead = parseInt(readArr[2]);
    var tmpRead;
    if (prevRead > curRead) {
      tmpRead = curRead;
      curRead = prevRead;
      prevRead = tmpRead;
    }

    if (isCurrent) {
      return curRead;
    } else {
      return prevRead;
    }
  }

  saveEditReads(meterRead) {
    
    if (this.invoiceForm.valid) {
      var inv = meterRead.invoiceReadDetailsList[0];
      var paramObj = {};
      paramObj["submitReadDetails"] = "Yes";
      paramObj["serNum"] = meterRead.serialNumber;
      paramObj["comment"] = this.invoiceForm.value["comment"];//inv.comment;
      paramObj["readDate"] = this.invoiceForm.value["readDate"];
      paramObj["invNum"] = inv.invNum;
      paramObj["totRead"] = this.invoiceForm.value["curTotalRd"];
      paramObj["bwRead"] = "";  //inv.curBWRd
      paramObj["contractId"] = meterRead.contractId;
      paramObj["source"] = meterRead.source;

      if (meterRead.source == "S21" && inv.counterDteails.length > 0) {
        paramObj["counterNames"] = inv.counterDteails.map(function (obj) { return obj.counterName; }).join(', ');
        paramObj["counterReads"] = inv.counterDteails.map(function (obj) { return obj.curCounterRd; }).join(', ');
      }
      this.blockUI.start();
      this.meterHistoryService.createServiceTicket(paramObj).subscribe(
        (response: any) => {

          this.responseSubmit.success = response.success;
          this.responseSubmit.failure = response.failure;
          this.responseSubmit.successmessage = response.response;
          this.blockUI.stop();
        }
      );
    } else {
      this.invoiceForm.controls.readDate.markAsTouched();
      this.invoiceForm.controls.curTotalRd.markAsTouched();

    }
    
  }


  serialNumberLink1(meterRead) {
    this.isOpen = true;
    var inv = meterRead.invoiceReadDetailsList[0];
    var paramObj = {};
    paramObj["serialNumber"] = meterRead.serialNumber;
    paramObj["conNum"] = meterRead.contractNumber;
    paramObj["model"] = meterRead.model;
    paramObj["ref1"] = meterRead.customerRef1 ? meterRead.customerRef1 : '';
    paramObj["ref2"] = meterRead.customerRef2 ? meterRead.customerRef2 : '';
    paramObj["address"] = meterRead.address;
    paramObj["contractId"] = meterRead.contractId;
    paramObj["instanceID"] = meterRead.instanceId;
    paramObj["BWCounter"] = meterRead.BWCounter ? meterRead.BWCounter : '0';
    paramObj["dbuid"] = meterRead.srhAssignedTo ? meterRead.srhAssignedTo : this.srhAssignedTo;
    paramObj["source"] = meterRead.source;

	this.meterReadContractID = meterRead.contractId;
	
    this.blockUI.start();
    this.meterHistoryService.gsnld(paramObj).subscribe(
      (response: any) => {
        this.machinedetails = response;
        this.billHistorydetails = response.billHistorydetails;
        this.assignedUserList =response.assignedUserList;
        this.blockUI.stop();

      }
    );
    
  }


  cancel() {

    location.reload();

  }

  navigateBack(){
    //this.router.navigateByUrl('submitmeterread');
    this.router.navigate(['/','submitmeterreads']);
  }

  download() {
    // need to implement
	console.log(this.machinedetails);
	
	var paramObj = {};
    paramObj["serialNumber"] = this.machinedetails.serialNumber;
    paramObj["conNum"] = this.machinedetails.conNum;
    paramObj["model"] = this.machinedetails.model;
    paramObj["ref1"] = this.machinedetails.ref1;
    paramObj["ref2"] = this.machinedetails.ref2;
    paramObj["address"] = this.machinedetails.address;
    paramObj["contraID"] = this.meterReadContractID; //this.machinedetails.contraId;
    paramObj["instanceID"] = this.machinedetails.instanceId;
    paramObj["BWCounter"] = this.machinedetails.BWCounter ? this.machinedetails.BWCounter : '0';
    paramObj["source"] = 'ORACLE';

    this.blockUI.start();
    this.meterHistoryService.downloadData(paramObj).subscribe(
      (response: any) => {
        
		console.log(response.data);
		this.blockUI.stop();
		
		let arrData=[];
		
		for(let idx in response.data){
			var arr=[];
			arr = response.data[idx];
			arrData.push(arr);
		}		
		
        this.commonService.jsonToExcel(arrData,"CanonMeterReadHistoryReport",false,false,null);		

      }
    );
	
	
  }



  expandCollapse() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  };

  fullExportToExcel() {
	console.log("Download Full Meter History");
	
    this.blockUI.start();
    this.meterHistoryService.fullExcelDownload().subscribe(
      (response: any) => {
        console.log(response.data);
		console.log(response.columns);
		
		let arrData=[];
		let  ts =["CANON - Meter History Report (Run Date:" + new Date() + ")" ,"","","","","","",""];
		arrData.push(ts);
		arrData.push(response.columns);
		
		for(let idx in response.data){
			var arr=[];
			arr = response.data[idx];
			arrData.push(arr);
		}		
		
		if(response.data.length<1){
			let  arr=["No Records Found","","","","","","",""];
	 		arrData.push(arr);
			
		}

         this.commonService.jsonToExcel(arrData,"MeterReadHistoryReport",false,false,null);		
		
        this.blockUI.stop();

      }
    );
	
	
  }

  stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }

}