import { Component, OnInit } from '@angular/core';
import { EncrDecr } from 'src/app/service/common-service/encrdecr.service';
import { FooterService } from 'src/app/service/common-service/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  whatsNewLinksMap: Map<String, String>;

  constructor(
    private footerService: FooterService,private encrDecr: EncrDecr
  ) { }

  ngOnInit(): void {
    this.getWhatsNewLinks();
  }

  getWhatsNewLinks() {
    this.footerService.getWhatsNewLinks().subscribe((data: any) => {
      this.whatsNewLinksMap = data;
    })
  }

  eLearningAuthentication(){
    var today = new Date();
    var secret = today.getFullYear() + '' + this.addZero(today.getMonth() + 1) + '' + this.addZero(today.getDate()) + '' + this.addZero(today.getHours()) + '' + this.addZero(today.getMinutes()) + '' + this.addZero(today.getSeconds());
    var url = window.location.hostname;

    var encryptedVal = this.encrDecr.set('A3110BB81890CEE6', 'A3110BB81890CEE6', secret);
    var eLearnURL = "https://elearning.cusa.canon.com?host=" + url + "?key=" + encryptedVal;
    console.log("eLearnURL:" + eLearnURL);
    window.open(eLearnURL);

  }

  addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
getCurrentYear(): number {
  return new Date().getFullYear();
}

}
