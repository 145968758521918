import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { ActivatedRoute } from '@angular/router';
import { ClosedOrderService } from './closed-orders.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-closed-orders',
  templateUrl: './closed-orders.component.html',
  styleUrls: ['./closed-orders.component.css']
})
export class ClosedOrdersComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  closeSupplyList = [];
  myDeviceList:any=[];
  orderDetails = "";
  dateRanges = "30";
  userList = [];
  start = 1;
  end = 0;
  recPerPage: any = "10";
  pageNum = 1;
  arrPgLinks = [];
  recMsg = "";
  msg = "";
  showMsg = false;
  msgClass = "";	
  invoices = [];
  isVisible = [];
  orderNumber: any="";
  serialEqpNumber: any = "";
  model: any = "";  
  poNumber :any = "";
  users: any = "";
  selectedUserAccts:string="";
  userAccountList=[];
  pleaseWaitCls:boolean = false;
  totalRecords: any;
  numOfRecsMsg: string;
  sub: any;
  serialNumber:string="";
  location:string="";

  orderHeader:any;
  orderLineList = [];
  isBillableOrder:boolean=false;
  invList = "";
  selectedOrder: any;
  duration: string;
  assignedTo: string;
  coverageType: string;
  defaultUrl: string;

  
  constructor(private commonService:CommonServiceService, private closedOrderService: ClosedOrderService,
    private util : Util, private modalService: NgbModal) { 
    this.defaultUrl = this.util.getBaseUrl();
  }



  ngOnInit() {

    this.blockUI.start();
    let data = this.commonService.getUserAccounts().subscribe(
      data=>{
        this.userAccountList = data.data;
        this.blockUI.stop();
      });

      this.blockUI.start();
      let userData = this.closedOrderService.getUsers().subscribe(
        userData=>{
          this.userList = userData.assignedTos;
          this.blockUI.stop();
        });

      this.commonService.setCommonFunctions();
      this.getCloseSupplyOrders(1);

  
    }

    exportToExcel1(){

      this.blockUI.start();
      var paramObj = {};
      paramObj["srch_order_num"] = this.orderNumber;
      paramObj["srch_serail_num"] = this.serialEqpNumber;
      paramObj["model"] = this.model;
      paramObj["poNumber"] = this.poNumber;
      paramObj["assgined_to"] = this.users;
      var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
      var endItem = (this.pageNum * parseInt(this.recPerPage));
      paramObj["start"] = 1;
      paramObj["length"] = 10000;
      
      var dNow = new Date();
       var d = new Date();
      var perivousdays;
      var fromdate;
      var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
      if (this.dateRanges === "15")
            {
        perivousdays = new Date(d.setDate(d.getDate()-15));
        fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
            }
      else if (this.dateRanges === "30")
            {
        perivousdays = new Date(d.setDate(d.getDate()-30));
        fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
            }
         else if(this.dateRanges === "90")
         {
           perivousdays = new Date(d.setDate(d.getDate()-90));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "180")
         {
           perivousdays = new Date(d.setDate(d.getDate()-180));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "365")
         {
           perivousdays = new Date(d.setDate(d.getDate()-365));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         }
         else if(this.dateRanges === "547")
         {
           perivousdays = new Date(d.setDate(d.getDate()-547));
           fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
         } 
  
      paramObj["srch_from_date"] = fromdate;
      paramObj["srch_to_date"] = todate;
      paramObj["order_status"] = "Closed";
      var arrUserAcc = [];
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
      console.log("Printing Selected account..");
      console.log(this.selectedUserAccts);
      paramObj["party_ids"] = this.selectedUserAccts;
      
      var adPromise = this.closedOrderService.getClosedSupplyOrdersJSON(paramObj).subscribe(
        data=>{
           let jd = data.data;	
           console.log("Printing jd value in exportToExcel method:   ", jd);
           if(jd.length>0){
            this.commonService.jsonToExcel(jd,"ClosedSupply",true,false,null);	
           }else{
            jd=["No Records Found","","","","","","",""];
            this.commonService.jsonToExcel(jd,"ClosedSupply",false,false,null);	
           }
                
            this.blockUI.stop();
            
         
      });
    }

    exportToExcel2(){
      this.blockUI.start();
      var dNow = new Date();
      var d = new Date();
     var perivousdays;
     var fromdate;
     var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
     if (this.dateRanges === "15")
           {
       perivousdays = new Date(d.setDate(d.getDate()-15));
       fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
           }
     else if (this.dateRanges === "30")
           {
       perivousdays = new Date(d.setDate(d.getDate()-30));
       fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
           }
        else if(this.dateRanges === "90")
        {
          perivousdays = new Date(d.setDate(d.getDate()-90));
          fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
        }
        else if(this.dateRanges === "180")
        {
          perivousdays = new Date(d.setDate(d.getDate()-180));
          fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
        }
        else if(this.dateRanges === "365")
        {
          perivousdays = new Date(d.setDate(d.getDate()-365));
          fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
        }
        else if(this.dateRanges === "547")
        {
          perivousdays = new Date(d.setDate(d.getDate()-547));
          fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
        } 
      let status = "Closed";
      let url="openSupply/opOrderDetailsExportJSON";
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
      let qryStr="srch_order_num="+ this.orderNumber+"&srch_serail_num="+this.serialEqpNumber+"&model="+
      this.model+"&srch_from_date="+fromdate+"&srch_to_date="+todate+"&assignedTo="+this.users+
      "&poNumber="+this.poNumber+"&start=1"+"&length=1000"+"&order_status="+ status+"&party_ids="+this.selectedUserAccts ;
      url=url+"?"+qryStr;
      console.log("Printing exportToExcel URL for Closed Supply Order: ", url);

      this.closedOrderService.getJsonTOExcelData(url).subscribe(
        data=>{
          console.log(data);
          this.blockUI.stop();
          this.commonService.jsonToExcel(data.data,"ClosedSupply",true,false,null);	
        }
      );

    }


    exportToExcel(){

      this.blockUI.start();
			this.pageNum = 1;
			this.showMsg = false;
			this.isVisible = [];
			var currentElement = $("#rpg").val();
			if(currentElement != undefined){
				this.recPerPage = currentElement;
			}
			var paramObj = {};
			paramObj["srch_order_num"] = this.orderNumber;
			paramObj["srch_serail_num"] = this.serialEqpNumber;
			paramObj["model"] = this.model;
			paramObj["poNumber"] = this.poNumber;
			paramObj["assgined_to"] = this.users;
			var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
			//var endItem = ($scope.pageNum * parseInt($scope.recPerPage));
			paramObj["start"] = 0;
			paramObj["length"] = this.totalRecords;
			
			var dNow = new Date();
	 		var d = new Date();
			var perivousdays;
			var fromdate;
			var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    	
    		
        perivousdays = new Date(d.setDate( eval('d.getDate()-' + parseInt(this.dateRanges))) );
    		fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			
	
			paramObj["srch_from_date"] = fromdate;
			paramObj["srch_to_date"] = todate;
			paramObj["order_status"] = "Closed";
			var arrUserAcc = [];
			//arrUserAcc = getSelecteUserAccounts();
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
			paramObj["party_ids"] = this.selectedUserAccts;
			
			var adPromise = this.closedOrderService.getCloseSupplyOrders(paramObj).subscribe(
        data=>{
				//function(result){
					let jd = data.data;					
					this.totalRecords = data.recordsTotal;
          console.log("Printing jd value in exportToExcel method:   ", jd);	
					if (jd.length > 0) {

            this.commonService.jsonToExcel(jd,"ClosedSupply",true,false,null);	
					}
          else{
            jd=["No Records Found","","","","","","",""];
            this.commonService.jsonToExcel(jd,"ClosedSupply",false,false,null);	
          }
					
          this.blockUI.stop();
      });



    }
  

    getInvPdf (invNum:any,source:any){
      //showPleaseWait();
      //let url="/orderInquiry/inqOrdInvoiceDetEmanage?invoice_number="+invNum + "&source="+source;
      console.log("Printing Invoice PDF link:...");
      console.log(url);
      var url= this.defaultUrl + "openSupply/opInvoiceDetailForEmanage?invoice_number="+invNum + "&source="+source;
      window.open(url);
      //hidePleaseWait();
    }
  
    getInvPdfS21(invNum:any){
      //showPleaseWait();
      //var url="invoicepdf.htm?mthd=gipdfe&invoice_number="+invNum;
      var url= this.defaultUrl + "openSupply/opInvoiceDetailForEmanage?invoice_number="+invNum;
      window.open(url);
      //hidePleaseWait();
    }

    toggleDiv (id: any, event: any ,orderHeaderId: any){		
      event.preventDefault();			
        //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;	  	
        this.isVisible[id] = this.isVisible[id]?false : true;		
        //var orderList = $filter('filter')(this.closeSupplyList, {'strOrdId' : orderHeaderId}, true);
        var orderList = this.closeSupplyList.filter(item => item.strOrdId === orderHeaderId);
		        
		        if(orderList.length > 0){
		           this.selectedOrder = orderList[0];
		        }
			 	
				if(!this.selectedOrder.itemInfoAvailable){
					//this.pleaseWaitCls = true;
          this.blockUI.start();
					var trackingShipment = {
						"orderNum" : orderHeaderId,
						"source" : this.selectedOrder.source
					}	

				  			var adPromise = this.closedOrderService.getSupplyOrderItems(trackingShipment).subscribe(
        data=>{
          console.log(data);
          var orderTrackItems = data;
							
          this.selectedOrder.partDetailsList = orderTrackItems;
  
          this.selectedOrder.itemInfoAvailable = 'Y';

          //this.pleaseWaitCls = false;
          this.blockUI.stop();
					
				
      });   

			

				}
        //$scope['isVisible'+id] = $scope['isVisible'+id] ? false:true;  		
    }

    clearSearch(event:any){
      if(event){
        event.preventDefault();
      }
      this.orderNumber = "";
			this.serialEqpNumber = "";
			this.model = "";
			this.users = "";
			this.poNumber = "";
			this.dateRanges = "30";
      for(let actIdx in this.userAccountList){
        this.userAccountList[actIdx]["enabled"]=true;
      }
      this.getCloseSupplyOrders(1);
    }
 
  getTrackingURLMsg() {}

  getOrderDetails(ordID, src, totPrice) { 

    const orderDetailsModalRef = this.modalService.open(OrderDetailsComponent, {size: <any>'xl'});
    orderDetailsModalRef.componentInstance.ordID = ordID;
    orderDetailsModalRef.componentInstance.src = src;
    orderDetailsModalRef.componentInstance.totPrice = totPrice;
  }	


    getCloseSupplyOrders(pgNum: any){
      //showPleaseWait();
      //this.pleaseWaitCls = true;
      this.blockUI.start();
			this.pageNum = pgNum;
			this.showMsg = false;
			this.isVisible = [];
			var currentElement = $("#rpg").val();
			if(currentElement != undefined){
				this.recPerPage = currentElement;
			}
			var paramObj = {};
			paramObj["srch_order_num"] = this.orderNumber;
			paramObj["srch_serail_num"] = this.serialEqpNumber;
			paramObj["model"] = this.model;
			paramObj["poNumber"] = this.poNumber;
			paramObj["assgined_to"] = this.users;
			var startItem = (this.pageNum * parseInt(this.recPerPage)-(parseInt(this.recPerPage)-1));
			//var endItem = ($scope.pageNum * parseInt($scope.recPerPage));
			paramObj["start"] = startItem-1;
			paramObj["length"] = this.recPerPage;
			
			var dNow = new Date();
	 		var d = new Date();
			var perivousdays;
			var fromdate;
			var todate= dNow.getFullYear() +'-'+ ("0" + (dNow.getMonth() + 1)).slice(-2) + '-' + ("0" + (dNow.getDate())).slice(-2) + ' ' + ("0" + (dNow.getHours())).slice(-2) + ':' + ("0" + (dNow.getMinutes())).slice(-2)+':'+ '00' ;
    	
    		
        perivousdays = new Date(d.setDate( eval('d.getDate()-' + parseInt(this.dateRanges))) );
    		fromdate = perivousdays.getFullYear() +'-'+ ("0" + (perivousdays.getMonth() + 1)).slice(-2) + '-' + ("0" + (perivousdays.getDate())).slice(-2) + ' ' + ("0" + (perivousdays.getHours())).slice(-2) + ':' + ("0" + (perivousdays.getMinutes())).slice(-2)+':'+ '00' ;
			
	
			paramObj["srch_from_date"] = fromdate;
			paramObj["srch_to_date"] = todate;
			paramObj["order_status"] = "Closed";
			var arrUserAcc = [];
			//arrUserAcc = getSelecteUserAccounts();
      this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
			paramObj["party_ids"] = this.selectedUserAccts;
			
			var adPromise = this.closedOrderService.getCloseSupplyOrders(paramObj).subscribe(
        data=>{
				//function(result){
					this.closeSupplyList = data.data;					
					this.totalRecords = data.recordsTotal;
					if (this.closeSupplyList.length < 1) {
						this.showMsg = true;
						this.msg = "No Records found";
						this.msgClass = "alert-danger";
					}
					
					//$scope.setPgLinks();
					//$scope.setRecMsg();
          this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
          this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);
          //hidePleaseWait();
          //this.pleaseWaitCls = false;
          this.blockUI.stop();
				
				// function(reason) {
				// 	$scope.msg = "Error while getting records.";
				// 	$scope.msgClass = "alert-danger";
				// 	$scope.showMsg = true;
				// 	hidePleaseWait();
				// 	console.log(reason);
				// }
      });

    
    
}

stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }

}
