import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

@Injectable({
  providedIn: 'root'
})
export class SubmitPaymentService {

  defaultUrl: string;

  private makePaymentModalCloseNotify = new Subject<any>();
  makePaymentModalClosedObservable$ = this.makePaymentModalCloseNotify.asObservable();

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  notifyMakePaymentModalClosed() {
    this.makePaymentModalCloseNotify.next();
  }
  
  getInvoiceList(oParams){
      let url = this.defaultUrl + "invoicePayment/invoicePaymentRequest";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getInvoiceList"))));
  }

  getCCList() : Observable<any>{
      let url = this.defaultUrl + "makePayment/getCCList?";
      
      return this.http
        .post(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getCCList"))));
  }

  getACHList() : Observable<any>{
      let url = this.defaultUrl + "makePayment/getACHList?";
      
      return this.http
        .post(url,'', httpOptions)
        .pipe(catchError((this.util.handleError("getACHList"))));
  }

  getPaymentDetails(oParams): Observable<any>{
      let url = this.defaultUrl + "makePayment/getPaymentDetails";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getPaymentDetails"))));
  }

  insertACHPaymentDetails(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/insertACHPaymentDetails";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("insertACHPaymentDetails"))));
  }

  insertACHAutoDetails(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/insertACHAutoDetails";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("insertACHAutoDetails"))));
  }

  confirmPayment(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/confirmPayment";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("confirmPayment"))));
  }

  setupAutoPay(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/setupAutoPay";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("confirmPayment"))));
  }

  getAutoPayStsFlag(qryStr){
      let url = this.defaultUrl + "paymentMethod/getAutoPayStsFlag";
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("getAutoPayStsFlag"))));
  }

  validatePaymentInfo(oParams) : Observable<any>{
    let url = this.defaultUrl + "makePayment/validateInfo";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("validatePaymentInfo"))));

  }
    		
	paymentValidation(oParams) : Observable<any>{
	 
    let url = this.defaultUrl + "makePayment/makePaymentvalidation";
    let params = new URLSearchParams();
    for (let key in oParams) {
      params.set(key, oParams[key])
    }
    let qryStr=params.toString();
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("paymentValidation"))));


	}

  validatePaymentInfoACH(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/validateInfoACH";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("validatePaymentInfoACH"))));
  }

  makePaymentvalidationJSON(oParams) : Observable<any>{
      let url = this.defaultUrl + "makePayment/makePaymentvalidation";
      let params = new URLSearchParams();
      for (let key in oParams) {
        params.set(key, oParams[key])
      }
      let qryStr=params.toString();
      
      return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("makePaymentvalidationJSON"))));
  }

  getStatusList(){
    let url = this.defaultUrl + "cfsInvoice/cfsInvoiceStatus";
    let params={};
    return this.http
      .post(url, params.toString, httpOptions)
      .pipe(catchError((this.util.handleError("getStatusList"))));
  }
    
    
  updateACHInvoicePaymentDetails(oParams){

    let url = this.defaultUrl + "makePayment/updateACHInvoicePaymentDetails";

    let params = new URLSearchParams();

    for (let key in oParams) {
      params.set(key, oParams[key])
    }

    let qryStr=params.toString();
    
    return this.http
      .post(url,qryStr, httpOptions)
      .pipe(catchError((this.util.handleError("updateACHInvoicePaymentDetails"))));

    
  }

    	
	insertPaymentAuditDetails(oParams){

    let url = this.defaultUrl + "makePayment/insertACHAuditDetails";

    let params = new URLSearchParams();

    for (let key in oParams) {
      params.set(key, oParams[key])
    }

    let qryStr=params.toString();

		return this.http
        .post(url,qryStr, httpOptions)
        .pipe(catchError((this.util.handleError("updateACHInvoicePaymentDetails"))));

	}

}
