import { Component, OnInit } from '@angular/core';
import { MeterReadsService } from './meter-reads.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-meter-reads',
  templateUrl: './meter-reads.component.html',
  styleUrls: ['./meter-reads.component.css']
})
export class MeterReadsComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  url:any="";

  constructor(private meterReadsService: MeterReadsService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.blockUI.start();
    this.meterReadsService.getUrl().subscribe(
      data=> {
        console.log("Printing url: ");
        console.log(data);
        //this.url = data.url;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
        this.blockUI.stop();
      }
    );

  }

}
