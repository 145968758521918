import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {SiteSurveyService} from './site-survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { Util } from 'src/app/app.util';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { data } from 'jquery';
@Component({
  selector: 'app-site-survey',
  templateUrl: './site-survey.component.html',
  styleUrls: ['./site-survey.component.css']
})
export class SiteSurveyComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  @ViewChild('printModal') printModal:TemplateRef<any>;
	@ViewChild('folderScanModal') folderScanModal:TemplateRef<any>;
	@ViewChild('emailScanModal') emailScanModal:TemplateRef<any>;
  @ViewChild('uploadSurvey') uploadSurvey: TemplateRef<any>;
  sn:string="";
  somNumber: any="";
  tab:string="tab1";
  quoteType:string="";
  error:any="N";
  errorMsg:string="";
  firstName:string="";
  lastName:string="";
  companyName:string="";
  emailAddr:string="";
  phoneNumber:string="";
  address:string="";
  city:string="";
  state:string="";
  zipCode:string="";
  versionNumber:any="";
  itContactExists:string="Y";
  itContactName:string="";
  itEmail:string="";
  itPhone:string="";
  itDept:string="";
  repEmailAddr:string="";
  firstNameError:string="";
  emailAddrError:string="";
  contactInformation:string="";
  successMsg:string="";
  dbsubmitted:string="N";
  surveyreadonly:string="Y";
  status:string= "";
  somStatus:string="";
  states:any=[];
  autoSleepTimes:any=[];
  autoResetTimes:any=[];
  //Device Setting Variables declaration below:
  SomQuoteNumber:string="";
  dsVersionNumber:string="";
  somQuoteFlag:string="";
  autoSleepTime:string="";
  sleepModeEnergy:string="";
  motionSensor:string="";
  autoResetTime:string="";
  autoResetMenu:string="";
  usbPort:string="";
  systemManagerOption:string="";
  systemMngrUserName:string="";
  systemMngrPassword:string="";
  sqltypeName:string="";
  messageDisplay:boolean=false;
  deviceSuccessMsg:string="";
  errorDisplay: boolean;
  deviceErrorMsg: any;
  defaultUrl: string;


  // MFP variables declaration starts here:

printModalRef: BsModalRef;
	folderScanModalRef : BsModalRef;
	emailScanModalRef  : BsModalRef;
	uploadSurveyRef  : BsModalRef;
	//surveyreadonly;
	mfpSetting : any;
	scanFormat : any;
	device : any;
	selectedPrintLanguage="";
	printLanguages = [];
	scanFolderFileFormat="";
	scanFolderLocation;
	scanFormats = [];
	userAccountName;
	userAccountPassword;
	uncOrFolderFullPath;
	scanEmailFileFormat="";
	smptpServerIpAndName;
	smtpLogonRequired;
	scanEmailAuthUserName;
	scanEmailAuthPassword;
	scanDeviceEmailAddress;
	mfpMessageFailDisplay = false;
	mfpMessageSuccessDisplay = false;
	
	// somNumber;
	// versionNumber;
	// somQuoteFlag;
	// somStatus;
	
	printModalDisplay = "none";
	errorMessage:any;
  inValidSubmit: boolean;
  mfpError: any;
  deviceInValid: any;
  contactError: any;
  custUserErr: boolean;
  custPassErr: boolean;
  submitMessageDisplay: boolean;
  surveyDeviceInfo:any;
  surveyContactInfo:any;
  fileError: string;
  genericError: string;
  fileDescError: string;
  attachments: any;
  fileDesc: any;
  myFile: any;
  $parent: any;
  selectedFiles: FileList;
  currentFile: File;

    // MFP variables declaration ends here:

  constructor(private siteSurveyService:SiteSurveyService,private router: Router,private util : Util,private modalService: BsModalService, private activatedRoute: ActivatedRoute,private commonService:CommonServiceService,private titleService: Title) { 
    this.defaultUrl = this.util.getBaseUrl();
    this.activatedRoute.queryParams.subscribe(params => {
      this.sn = params['sn'];
    });
  }

  ngOnInit() {
    this.blockUI.start();
    setTimeout(function (){
      $("#nav-header,#footer-section").hide();
       
      },1000);
    //this.sn='MTM4ODIzNQ==';
    this.siteSurveyService.getUserContactInfo(this.sn).subscribe(
    data=>{
      this.status = data.status;
      if(this.status=='E'){

        this.error='Y';
        this.errorMessage= data.message;

      }
      else{
      this.surveyContactInfo = data.contactInfo;  
      this.quoteType=data.contactInfo.quoteType;
      this.somNumber=data.contactInfo.somQuoteNum;
      this.firstName=data.contactInfo.firstName;
      this.lastName= data.contactInfo.lastName;
      this.companyName= data.contactInfo.companyName;
      this.emailAddr= data.contactInfo.emailAddr;
      this.phoneNumber= data.contactInfo.phoneNumber;
      this.address= data.contactInfo.address;
      this.city= data.contactInfo.city;
      this.state= data.contactInfo.state;
      this.zipCode= data.contactInfo.zipCode;
      this.itContactExists= data.contactInfo.itContactExists;
      this.itContactName= data.contactInfo.itContactName;
      this.itEmail= data.contactInfo.itEmail;
      this.itPhone= data.contactInfo.itPhone;
      this.itDept= data.contactInfo.itDept;
      this.repEmailAddr= data.contactInfo.repEmailAddr;
      this.versionNumber=data.contactInfo.versionNumber;
      this.surveyreadonly=data.readonly;
      this.status = data.status;
      this.somStatus= data.somStatus;
      this.getDeviceDetails();
      this.getmfpSettingDetails();
      
      }
      this.blockUI.stop();

    }

      );


      this.states=this.commonService.getStates();
      this.autoResetTimes=this.commonService.getAutoResetTime();
      this.autoSleepTimes= this.commonService.getAutoSleepTime();
      
      
  }

getmfpSettingDetails(){
// MFP code below:
this.printLanguages = this.getPrintLanguages();
this.scanFormats = this.getScanFormats();

// this.surveyreadonly = 'N';
// this.somNumber = 1388650;
// this.versionNumber = 2;
// this.somQuoteFlag = "S";
// this.somStatus = "InProgress";

var param = {
"somNumber" : this.somNumber,
"versionNumber" : this.versionNumber,
"somQuoteFlag" : this.somQuoteFlag,
"somStatus" : this.somStatus
};
console.log("Printing...Checking is param below:");
console.log(param);
this.blockUI.start();
this.siteSurveyService.getMFPSetting(param).subscribe(data => {
this.blockUI.stop();
if (data != null) {
  console.log('getMFPSetting():', data);
  this.mfpSetting = data;
  if(this.mfpSetting.mfpNetworkType==null){
    this.mfpSetting.mfpNetworkType='Static';
  }

  if(this.mfpSetting.useSsl==null){
    this.mfpSetting.useSsl='No';
  }

  if(this.mfpSetting.infoAuth==null){
    this.mfpSetting.infoAuth='No';
  }
  
  this.selectedPrintLanguage=this.mfpSetting.printLanguage;
  
  this.scanFolderFileFormat= this.mfpSetting.scanFolderFileFormat ;
  this.scanFolderLocation=this.mfpSetting.scanFolderLocation ;
  this.userAccountName=this.mfpSetting.userAccountName ;
  this.userAccountPassword=this.mfpSetting.userAccountPassword;
  this.uncOrFolderFullPath=this.mfpSetting.uncOrFolderFullPath;
  
  this.scanEmailFileFormat=this.mfpSetting.scanEmailFileFormat;
  this.smptpServerIpAndName=this.mfpSetting.smptpServerIpAndName ;
  this.smtpLogonRequired=this.mfpSetting.smtpLogonRequired ;
  this.scanEmailAuthUserName=this.mfpSetting.scanEmailAuthUserName;
  this.scanEmailAuthPassword=this.mfpSetting.scanEmailAuthPassword;
  this.scanDeviceEmailAddress=this.mfpSetting.scanDeviceEmailAddress ;
  
}
});

}

  saveContactInfo(sc){
    var input:any={};
    input.somQuoteNum=this.somNumber;
    // Need to add all inputs from Backend endpoint...
    input.versionNumber=this.versionNumber;
    input.quoteType= this.quoteType;
    input.firstName= this.firstName;
    input.lastName= this.lastName;
    input.companyName = this.companyName;
    input.emailAddr = this.emailAddr;
    input.phoneNumber= this.phoneNumber;
    input.address = this.address;
    input.city = this.city;
    input.state = this.state;
    input.zipCode= this.zipCode;
    input.itContactExists = this.itContactExists;
    input.itContactName = this.itContactName;
    input.itEmail = this.itEmail;
    input.itPhone = this.itPhone;
    input.itDept = this.itDept;
    input.repEmailAddr = this.repEmailAddr;
    input.status = this.status;

     this.siteSurveyService.saveUserContactInfo(input).subscribe(
      data=>{
        if(data.status === 'S'){
          this.successMsg = "Contact Information has been saved successfully.";
          if (sc != ''){
           this.switchCustomTab(sc);	
          }
        }else{
          this.errorMsg = "Sorry we are unable to save the Contact Information.";
          this.firstNameError = data.firstNameError;
          this.emailAddrError = data.emailAddrError;
        }

      }
     );

  }

  switchCustomTab(tabName){
    //this.router.navigate(['mfpsetting'])
    this.tab=tabName;
  }


getDeviceDetails(){
  var input:any={};
    //input.somQuoteNum=this.somNumber;
    input.somNumber= this.somNumber;
    input.versionNumber = this.versionNumber;
    input.somQuoteFlag = this.quoteType;
    input.somStatus = this.somStatus;
    this.siteSurveyService.getDeviceDetails(input).subscribe(
      data=>{
        this.surveyDeviceInfo = data.deviceInfo;

        this.SomQuoteNumber=data.deviceInfo.somQuoteNumber;
        this.dsVersionNumber=data.deviceInfo.versionNumber;
        this.somQuoteFlag=data.deviceInfo.somQuoteFlag;
        this.autoSleepTime=data.deviceInfo.autoSleepTime;
        if(this.autoSleepTime==null || this.autoSleepTime==undefined){
          this.autoSleepTime='15mins';
        }
 
        this.sleepModeEnergy=data.deviceInfo.sleepModeEnergy;
        
        if(this.sleepModeEnergy==null || this.sleepModeEnergy==undefined){
          this.sleepModeEnergy='low';

        }
        this.motionSensor=data.deviceInfo.motionSensor;
        if(this.motionSensor==null || this.motionSensor==undefined){
          this.motionSensor='on';
        }
        this.autoResetTime=data.deviceInfo.autoResetTime;
        if(this.autoResetTime==null || this.autoResetTime==undefined){
          this.autoResetTime='2mins';
        }
        if(this.autoResetMenu==null || this.autoResetMenu==undefined){
            this.autoResetMenu='copyScreen';
        }
        this.autoResetMenu=data.deviceInfo.autoResetMenu;
        this.usbPort=data.deviceInfo.usbPort;
        if(this.usbPort==null || this.usbPort==undefined){
          this.usbPort='Off';

        }
        this.systemManagerOption=data.deviceInfo.systemManagerOption;
        if(this.systemManagerOption==null || this.systemManagerOption==undefined){
          this.systemManagerOption='default';

        }
        this.systemMngrUserName=data.deviceInfo.systemMngrUserName;
        this.systemMngrPassword=data.deviceInfo.systemMngrPassword;
        this.sqltypeName=data.deviceInfo.sqltypeName;
        console.log("Pritnign data..");
        console.log(data);
      }
    );


}

saveDeviceInfo(sc){
  var input:any={};
  input.somQuoteNumber=this.somNumber;
  input.versionNumber= this.dsVersionNumber;
  input.autoSleepTime = this.autoSleepTime;
  input.sleepModeEnergy= this.sleepModeEnergy;
  input.motionSensor = this.motionSensor;
  input.autoResetTime = this.autoResetTime;
  input.autoResetMenu = this.autoResetMenu;
  input.usbPort = this.usbPort;
  input.systemManagerOption= this.systemManagerOption;
  input.systemMngrUserName = this.systemMngrUserName;
  input.systemMngrPassword = this.systemMngrPassword;
  input.sqltypeName = this.sqltypeName;

  this.siteSurveyService.saveDeviceInfo(input).subscribe(
    data=>{
      if(data.status === 'S'){
        this.messageDisplay = true;
        this.deviceSuccessMsg ="Device Settings are saved successfully. "; 
        if(sc != ''){
          this.switchCustomTab(sc);
        }
      }else if(data.status === 'E')
           {
        this.errorDisplay = true;
        this.deviceErrorMsg =data.message;
        this.errorMessage = data.message;
        this.error = 'Y'; 
      }
  }

  );


}


openUploadModal() {
  this.siteSurveyService.getQuoteAttachmentList(this.somNumber).subscribe(
    data=>{
      this.attachments = data.attachments;
    }
  );
  this.uploadSurveyRef = this.modalService.show(this.uploadSurvey);

  }


  getPrintLanguages(){
		var printLanguages = [
            { languageName : "UFRII" },
            { languageName : "PCL" },
            { languageName : "PS" }
		];
		
		return printLanguages;
	}
	
	getScanFormats(){
		var scanFormats = [
	        { formatType : 'PDF' },
	        { formatType : 'TIFF' },
	        { formatType : 'Compact PDF' },
	        { formatType : 'JPG' },
        ];

		return scanFormats;
	}

  printingChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.printing = 'Y';
			this.printModalRef = this.modalService.show(this.printModal);
		} else {
			this.mfpSetting.printing = 'N';
		}
	}
	
	setPrintingInfo() {
		this.mfpSetting.printLanguage = this.selectedPrintLanguage;
		this.closePrintModal();
		console.log(this.mfpSetting.printLanguage);
	}
	
	closePrintModal() {
		this.printModalRef.hide();
	}

  closeUploadSurveyModal() {
    this.uploadSurveyRef.hide();
	}

  closeUploadModal() {
		this.uploadSurveyRef.hide();
	}
	
	scanToFolderChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.scanToFolder = 'Y';
			this.folderScanModalRef = this.modalService.show(this.folderScanModal);
		} else {
			this.mfpSetting.scanToFolder = 'N';
		}
	}

  selectFile(event) {
    console.log("Printing iside slectFile");
    this.selectedFiles = event.target.files;
  }

  uploadAttachment(){
    this.currentFile = this.selectedFiles.item(0);
    console.log("Inside uploadAttachment");
    
    this.fileError = "";
    this.genericError = "";
    this.fileDescError = "";
      
    //this.attachments = $scope.$parent.attachments;
    
   
    
  
    this.submitAttachment();
     
      

  } 


  submitAttachment(){
console.log("Pritning submitAttachment:  ",this.currentFile,this.somNumber, this.fileDesc);
    this.siteSurveyService.uploadAttachment(this.currentFile,this.somNumber, this.fileDesc).subscribe(
      data=>{
        var status = data.status;
        if(status === "S"){
          this.attachments = data.attachments;
          this.$parent.attachments = data.attachments;
           $("#myFile").val('');
           this.fileDesc='';
          console.log("Success");
        }else{
          console.log("Error");
          this.fileError = data.fileError ? data.fileError : '';
          this.genericError = data.genericError ? data.genericError : '';
          this.fileDescError = data.fileDescError ? data.fileDescError : '';
        }
      }
    );
    
  }

  deleteAttachment(id){
    console.log("Printing deleteAttachment ID: ", id);
    this.siteSurveyService.deleteAttachment(id, this.somNumber).subscribe(
      data=>{
        var status = data.status;
        if(status === "S"){
          this.attachments = data.attachments;
          //this.$parent.attachments = data.attachments;
          console.log("Success");
        }else{
          console.log("Error");
          this.fileError = data.fileError ? data.fileError : '';
          this.genericError = data.genericError ? data.genericError : '';
        }
      }
    );

}
	
	setScanToFolderInfo(){
		
		this.mfpSetting.scanFolderFileFormat = this.scanFolderFileFormat;
		this.mfpSetting.scanFolderLocation = this.scanFolderLocation;
		this.mfpSetting.userAccountName = this.userAccountName;
		this.mfpSetting.userAccountPassword = this.userAccountPassword;
		this.mfpSetting.uncOrFolderFullPath = this.uncOrFolderFullPath;
		
		this.closeFolderScanModal();
		console.log(this.mfpSetting);

	}
	
	closeFolderScanModal() {
		this.folderScanModalRef.hide();
	}
	
	scanToEmailChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.scanToEmail = 'Y';
			this.emailScanModalRef = this.modalService.show(this.emailScanModal);
		} else {
			this.mfpSetting.scanToEmail = 'N';
		}
	}
	
	setScanToEmailInfo() {
		
		this.mfpSetting.scanEmailFileFormat = this.scanEmailFileFormat;
		this.mfpSetting.smptpServerIpAndName = this.smptpServerIpAndName;
		this.mfpSetting.smtpLogonRequired = this.smtpLogonRequired;
		this.mfpSetting.scanEmailAuthUserName = this.scanEmailAuthUserName;
		this.mfpSetting.scanEmailAuthPassword = this.scanEmailAuthPassword;
		this.mfpSetting.scanDeviceEmailAddress = this.scanDeviceEmailAddress;		
		
		this.closeEmailScanModal();
		console.log(this.mfpSetting);
	}
	
	closeEmailScanModal() {
		this.emailScanModalRef.hide();
	}
	
	faxChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.fax = 'Y';
		} else {
			this.mfpSetting.fax = 'N';
		}
	}	
	
	faxFrwdChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.faxForward = 'Y';
		} else {
			this.mfpSetting.faxForward = 'N';
		}
	}
	
	efiChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.efiFiery = 'Y';
		} else {
			this.mfpSetting.efiFiery = 'N';
		}
	}
	
	canonCtrlChange(serialElement: any) {
		if(serialElement.checked) {
			this.mfpSetting.canonController = 'Y';
		} else {
			this.mfpSetting.canonController = 'N';
		}
	}
	
	saveMfpSetting(tab) {
		this.mfpMessageFailDisplay = false;
		this.mfpMessageSuccessDisplay = false;

		var param = {
			"mfpSetting" : JSON.stringify(this.mfpSetting)
		}
		
		this.blockUI.start();
		this.siteSurveyService.saveMFPSetting(param).subscribe(data => {
			this.blockUI.stop();
			if (data != null) {
				console.log('saveMFPSetting():', data);
				this.mfpSetting = data;
				if(data.errorExist) {
					this.mfpMessageFailDisplay = true;
				} else {
					this.mfpMessageSuccessDisplay = true;
				}
			}
		});

}

setDefaultValues(setting){
		
  if(!setting.selectedMfpFirmware){
    console.log("Inside 1st if clause of setDefaultValues method.");
    setting.selectedMfpFirmware = 'No';
  }
  if(!setting.mfpNetworkType){
    console.log("Inside 2nd if clause of setDefaultValues method.");
    setting.mfpNetworkType = 'Static';
  }
  if(!setting.useSsl){
    console.log("Inside 3rd if clause of setDefaultValues method.");
    setting.useSsl = 'No';
  }
  if(!setting.infoAuth){
    console.log("Inside 4th if clause of setDefaultValues method.");
    setting.infoAuth = 'No';
    
  }
  
  if(!setting.printing){
    console.log("Inside 5th if clause of setDefaultValues method.");
    setting.printing = 'N';
  }
  
  if(!setting.scanToFolder){
    console.log("Inside 6th if clause of setDefaultValues method.");
    setting.scanToFolder = 'N';
  }
  
  if(!setting.scanToEmail){
    console.log("Inside 7th if clause of setDefaultValues method.");
    setting.scanToEmail = 'N';
  }
  
  if(!setting.fax){
    console.log("Inside 8th if clause of setDefaultValues method.");
    setting.fax = 'N';
  }
  
  if(!setting.faxForward){
    console.log("Inside 9th if clause of setDefaultValues method.");
    setting.faxForward = 'N';
  }
  
  if(!setting.efiFiery){
    console.log("Inside 10th if clause of setDefaultValues method.");
    setting.efiFiery = 'N';
  }
  
  if(!setting.canonController){
    console.log("Inside 11th if clause of setDefaultValues method.");
    setting.canonController = 'N';
  }
  console.log("Printing above above return");
  console.log(setting);
  return setting;
}

validateDeviceSysMgr(){
  var inValid = false;
  if(this.surveyDeviceInfo.systemManagerOption  == 'custom'){
    console.log("Printing inside validateDeviceSysMgr: ", this.surveyDeviceInfo.systemManagerOption);
    this.custUserErr = this.surveyDeviceInfo.systemMngrUserName === null ||  this.surveyDeviceInfo.systemMngrUserName === '';
      this.custPassErr = this.surveyDeviceInfo.systemMngrPassword === null || this.surveyDeviceInfo.systemMngrPassword === ''
      inValid = this.custUserErr || this.custPassErr;
  }
       
     return inValid;
}



submitSurvey(){
		


  this.getmfpSettingDetails();
  this.setDefaultValues(this.mfpSetting);
  this.siteSurveyService.validateSubmit(this.mfpSetting).subscribe(
    data=>{
      console.log("Printing data inside submitSurvey method after calling validateSubmit api ... ");
      console.log(data);
      this.mfpSetting = data;
      this.mfpError = this.mfpSetting.errorExist;
      this.deviceInValid = this
      .validateDeviceSysMgr();
      this.inValidSubmit = this.deviceInValid
      && this.mfpError;
      this.contactError = data.ErrorMap;
      if (this.inValidSubmit) {
        console.log('SubmitSurvey method 1st if block: ', this.inValidSubmit);
        this.errorDisplay = true;
        this.deviceErrorMsg = "Please correct the error in the MFP Settings & Device Settings and save it";
      } else if (this.deviceInValid) {
        console.log('SubmitSurvey method 2nd else if block: ', this.deviceInValid);
        this.errorDisplay = true;
        this.deviceErrorMsg = "Please correct the error in the Device Settings and save it";
      } else if (this.mfpError) {
        console.log('SubmitSurvey method 3rd else if block: ', this.mfpError);
        this.errorDisplay = true;
        this.deviceErrorMsg = "Please correct the error in the MFP Settings and save it";
      } else if (this.surveyContactInfo.emailAddr === '') {
        console.log('SubmitSurvey method 4th else if block: ', this.surveyContactInfo.emailAddr);
        this.errorDisplay = true;
        this.deviceErrorMsg = "Please enter your email address in Contact Info and save it";
      }

      else if (this.surveyContactInfo.firstName === '') {
        console.log('SubmitSurvey method 5th else if block: ', this.surveyContactInfo.firstName);
        this.errorDisplay = true;
        this.deviceErrorMsg = "Please enter your first name in Contact Info and save it";
        ;

      }

      else {
        console.log('SubmitSurvey method 6th else block: ', this.surveyDeviceInfo);
        this.saveDeviceDetails(this.surveyDeviceInfo);

      }
    }
  );
}

saveDeviceDetails(inputPayload){
  this.siteSurveyService.saveDeviceDetails(inputPayload).subscribe(
    data=>{
      console.log("Printing data inside saveDeviceDetails method... ");
      console.log(data);
      if (data.status === 'S') {   
        console.log("Printing data inside if block of saveDeviceDetails method... "); 
        var dataPayload:any = {};
        dataPayload.somNumber = this.somNumber;    	
        dataPayload.versionNumber = this.versionNumber;
        dataPayload.somQuoteFlag = this.quoteType;
        dataPayload.somStatus = this.somStatus							
        this.submitApi(dataPayload);
      }
    }
        

  );
}

submitApi(inputPayload){

  this.siteSurveyService.submitApi(inputPayload).subscribe(
    data=>{
        if (data.status === 'S') {
          console.log("Printing data inside submitApi method when Success... ");
          console.log(data);
          var dataPayload:any = {};
          dataPayload.somNumber = this.somNumber;    	
          dataPayload.versionNumber = this.versionNumber;
          dataPayload.somQuoteFlag = this.quoteType;
          dataPayload.somStatus = this.somStatus		
        
          this.submit(dataPayload);
          

    } else if (data.status === 'E') {

      console.log("Printing data inside submitApi method when Error... ");
      console.log(data);
      this.errorDisplay = true;
      this.deviceErrorMsg = data.message;
    }

  }
  );

}



submit(inputPayload){ 
  this.siteSurveyService.submit(inputPayload).subscribe(
    data=>{
      console.log("Printing data inside submit method... ");
      console.log(data);
              
        if (data.status === 'S') {
          console.log("Printing data inside if block of submit method... ");    
    this.submitMessageDisplay = true;
    // $("#survey-submitted-modal").modal({
    //     show: true,
    //     keyboard: false,
    //     backdrop: 'static'
    //   });
    
  } else if ((data.status === 'E') || (data.message === 'Error Sending Email')) {
    console.log("Printing data inside else if block of submit method... ");
    this.errorDisplay = true;
    if (data.message)
      this.deviceErrorMsg = "Unable to submit the survey details.Please try again later";
    else
      this.deviceErrorMsg = "Unable to submit the survey details.Please try again later";
  }
}
  );
}
    



}
