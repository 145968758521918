import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class AccountDevicesService {
  defaultUrl: string;
  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }

   getInitialData=function()
   {
     let url = this.defaultUrl + "accountdevices/getInitialData";
     let params={};
     return this.http
       .post(url, params.toString, httpOptions)
       .pipe(catchError((this.util.handleError("getInitialData"))));
   }


   getAccountDevicesList=function(oParams)
   {
       let url = this.defaultUrl + "accountdevices/getAccountDevices";
       let params = new URLSearchParams();
       for (let key in oParams) {
         params.set(key, oParams[key])
       }
       let qryStr=params.toString();
 
       return this.http
         .post(url,qryStr, httpOptions)
         .pipe(catchError((this.util.handleError("getAccountDevicesList"))));
     }
 
}
