import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ManageUserService } from './manage-user.service';
import { CommonServiceService } from '../service/common-service/common-service.service';
import { UpdateSerialComponent } from './update-serial/update-serial.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, FormArray, NgControlStatus, AbstractControl } from '@angular/forms';
import { Observable,forkJoin } from 'rxjs';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  userList:any =[];
  selUserStatus: string="ALL";
  selUserType: string ="assigned";
  pageNum: any = 0;
  pageSize: any =10;
  recPerPage: any = 10;
  arrPgLinks: any = [];
  totalRecords: any = 0;
  numOfRecsMsg: string = "";
  msg: string;
  msgClass: string;
  showMsg: boolean;
  msgCt: string;
  showMsgCt: boolean;
  msgClassCt: string;
  msgU: string;
  showMsgU: boolean;
  msgClassU: string;
  userId: any;
  comma: string ="";
    username:string="";
  partyId:string="";
  userdetails: any = [];
  success:boolean =false;
  successMsg: string = '';
  // firstName: string="";
  // lastName: string ="";
  // email: string ="";
  userRol: any=[];
  userRoles: any=[];
  totalRes:any=[];
     //roleId:string[]=[];
     myForm: FormGroup;

     roleDisabled=[true];
     roleIdsJSON ={};
     roleSelected=[];
  bError: boolean;
  errMsg: string="";


     constructor(private manageUserService: ManageUserService,private commonService: CommonServiceService,
      private formBuilder: FormBuilder,private modalService: NgbModal) {
        
       }

  ngOnInit() {
    this.commonService.setCommonFunctions();
    this.getUsersList(1, null);

    this.myForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email])
      , firstName: new FormControl("", Validators.required)
      , lastName: new FormControl("", Validators.required)
      , phNumber: new FormControl("", Validators.pattern('^[0-9]{10}$'))
      , newPwd: new FormControl("", Validators.required)
      , confirmNewPwd: new FormControl("", Validators.required),
      roleId:this.formBuilder.array([])
    });
  }


  getUsersList(pageNum: any, event: any) {
  
	if(event!=null){
      		event.preventDefault();
    	}

  	this.pageNum = pageNum;

    let oParams = {
      "selUserStatus": this.selUserStatus,
      "selUserType": this.selUserType, 
      "pageNum": this.pageNum,
      "pageSize":this.recPerPage
    };
    this.blockUI.start();
    this.manageUserService.getManageUserList(oParams).subscribe(
      (res: any) => {
       this.userList=res.userList;
       this.totalRecords = res.totalRecords;
       this.userId=res.userId;
       this.numOfRecsMsg = this.commonService.getNumOfRecsMsg(this.totalRecords, this.recPerPage, this.pageNum);
       this.arrPgLinks = this.commonService.getPgLinks(this.totalRecords, this.pageNum, this.recPerPage);
       this.blockUI.stop();
      }
    );
    
  }



    clearAll() {
			 
			this.userList = [];
			this.selUserStatus = "ALL";
			this.selUserType = "assigned";
	 		this.msg = "";
			this.msgClass = "";
			this.showMsg = false;
			this.pageNum = 1;
			this.recPerPage = "10";
			this.getUsersList(1, null);
		}
    
    clearMsg() {
			this.showMsg = false;
			this.msg = "";
			this.msgClass = "";
		}
		
	clearMsgCt() {
			this.msgCt = ""; 
		 	this.showMsgCt = false;
			this.msgClassCt = "";
		}

    
		clearMsgU() {
			this.msgU = ""; 
		 	this.showMsgU = false;
			this.msgClassU = "";
		}

    assignUsers() {
			this.clearMsgU();
			var objArr = [];
			var paramObj={};
                paramObj["action"]="A";
                paramObj["baUserId"] = this.userId;
                paramObj["userId"] = "";
             var strUsrs="";
             var baExist=false;
			for (let k = 0; k < this.userList.length; k++) {
				var objTemp = this.userList[k];
				if (objTemp.isChecked) {
					
					if(objTemp.ba_usr_id &&(objTemp.ba_usr_id ==this.userId)){
						baExist=true;
					}else{
					    this.comma="";
		     	    	if(strUsrs.length>0){
		     	    		this.comma=",";
		     	         }	
		     	    	strUsrs=strUsrs+this.comma+objTemp.usr_id;
                    }   
				}  
			}
			if (baExist){
				this.showMsgU = true;
				this.msgU = "BA already exist for User(s) selected";
				this.msgClassU = "alert-danger";
				this.scrollToMsgU();
			}else if (strUsrs.length < 1) {
				this.showMsgU = true;
				this.msgU = "Please select at least one User to Assign";
				this.msgClassU = "alert-danger";
				this.scrollToMsgU();
			}else  {
                  
                paramObj["userId"] = strUsrs;
                //showPleaseWait();
                console.log("Printing paramObj");
                //console.log(paramObj.toString);
				this.manageUserService.assignUsers(paramObj).subscribe(

						(data:any)=>{
						 
						this.showMsgU = true;
						if(data.statusFlg=="Y"){
							this.msgU = "Selected Users Assigned Successfully";
							this.msgClassU = "alert-success";
							this.getUsersList(1, null);
							this.scrollToMsgU();
						}else{
							this.msgU = "Error while Assigning Users";
              this.msgClassU = "alert-danger";
              this.scrollToMsgU();
						}
          });
                    
					}
			}
	


    UnAssignUsers() {
			this.clearMsgU();
			var objArr = [];
			var paramObj={};
                paramObj["action"]="U";
                paramObj["baUserId"] = this.userId;
                paramObj["userId"] = "";
             var strUsrs="";
             var baNotExist=false;
			for (let k = 0; k < this.userList.length; k++) {
				var objTemp = this.userList[k];
				if (objTemp.isChecked) {
					
					if(!objTemp.ba_usr_id){
						baNotExist=true;
					}else{
						this.comma="";
		     	    	if(strUsrs.length>0){
		     	    		this.comma=",";
		     	         }	
		     	    	strUsrs=strUsrs+this.comma+objTemp.usr_id;
                   }   
				}  
			}
			if(baNotExist){
				this.showMsgU = true;
				this.msgU = "BA does not exist for User(s) selected";
				this.msgClassU = "alert-danger";
				this.scrollToMsgU();
			}else if (strUsrs.length < 1) {
				this.showMsgU = true;
				this.msgU = "Please select at least one User to Unassign";
				this.msgClassU = "alert-danger";
				this.scrollToMsgU();
			} else {
                  
                paramObj["userId"] = strUsrs;
                //showPleaseWait();
				this.manageUserService.UnAssignUsers(paramObj).subscribe(
          (data: any) =>{
						 //hidePleaseWait();
						 
             this.showMsgU = true;
						if(data.statusFlg=="Y"){
							this.msgU = "Selected Users Unassigned Successfully";
							this.msgClassU = "alert-success";
							this.getUsersList(1, null);
							this.scrollToMsgU();
						}else{
							this.msgU = "Error while Unassigning Users";
              this.msgClassU = "alert-danger";
              this.scrollToMsgU();
						}
						 
                    
					});
			}
		}




		scrollToMsgU(){
      $('html, body').animate({
             scrollTop: $("#msgU").offset().top-50
         }, 10);
   }


   openUserContactMgmtModal(user):Observable<any>{
    this.username=user.usr_name;
    this.partyId=user.party_id;
    this.success=false;
   // this.roleId = [];
   
      var paramObj1 = {};
        paramObj1["partyId"] = this.partyId;
        paramObj1["userName"] = this.username;
        // this.manageUserService.getUserContactDetails(paramObj).subscribe(
        //   (response: any) => {
        //     this.userdetails = response.userDetails;
        //     console.log("before ******");
        //     this.myForm.value['firstName']=response.userDetails.firstName;
        //     console.log("after********");
            
  
        //     this.getRoles();
            
        //   }
        // );

        var paramObj2 = {};

        console.log("getroles ------->");
        
        paramObj2["userName"] = this.username;
        
        //this.roleId = [];
        this.myForm.value["roleId"]=[];
        


        console.log("*****-->"+this.myForm.value['firstName']);
        console.log("*****-->"+this.myForm.value['lastName']);
        
        this.totalRes=[];
        this.roleDisabled=[];
        this.roleSelected=[];
        this.blockUI.start();
        forkJoin([
          
        this.manageUserService.getUserContactDetails(paramObj1),

        
        
        this.manageUserService.getuserappentityroellist(paramObj2)

         ] ).subscribe(([response1,response2]) =>{
            console.log(response1);
           
            this.userdetails = response1.userDetails;
            console.log("before ******");
            // this.myForm.value['firstName']=response1.userDetails.firstName;
            // this.myForm.controls['firstName'].setValue=response1.userDetails.firstName;

            
            console.log("after********");
            // console.log(response2);

            for(let i=0;i<response2.length;i++){
              
             
              this.totalRes.push(response2[i]);

              console.log(response2[i]);
              console.log(response2[i]["accFlgRoleId"]);

              if(response2[i]["accFlg"] === 'Y')
                this.roleDisabled.push(false);
              else
                this.roleDisabled.push(true);

              
              if(response2[i]["accFlgRoleId"] != ""){
                console.log("role description::::");
                this.roleSelected.push(response2[i]["roles"].filter((rec)=> rec.roleId === response2[i]["accFlgRoleId"])[0]["roleDescription"]);
            }
            else{
              this.roleSelected.push("");
            }

            
              
           
            this.myForm.value["roleId"].push(response2[i].entityId+"_"+response2[i].accFlgRoleId);
            this.blockUI.stop();
          }
          console.log("*****-->"+this.myForm.value['firstName']);

          this.myForm = this.formBuilder.group({
            email: new FormControl(response1.userDetails.email, [Validators.required, Validators.email])
            , firstName: new FormControl(response1.userDetails.firstName, Validators.required)
            , lastName: new FormControl(response1.userDetails.lastName, Validators.required)
            , phNumber: new FormControl(response1.userDetails.phNumber, Validators.pattern('^[0-9]{10}$'))
            , newPwd: new FormControl(this.myForm.value['newPwd'], Validators.required)
            , confirmNewPwd: new FormControl(this.myForm.value['confirmNewPwd'], Validators.required),
            roleId:this.formBuilder.array(this.myForm.value["roleId"])
            // roleId:this.formBuilder.array([])
          });
         })

         
        
        

        return;
      }

      enableRole(i,id){
        console.log("++++++_________________>>");
        console.log(i);
        console.log(id);
        
        this.roleDisabled[i]= !this.roleDisabled[i]

        console.log(this.myForm.value['roleId']);
      
        if(this.roleDisabled[i] === true){
          for (let roleId of this.myForm.value['roleId']){
            // console.log(roleId.split("_")[0]);
            // console.log(id);
            // console.log(String((roleId.split("_")[0]).trim()) === String(id.trim()));
            if (String((roleId.split("_")[0]).trim()) === String(id.trim())){
              console.log("role Id matched.....");
              // delete this.myForm.value['roleId'][String(roleId).trim()];
              const index = this.myForm.value['roleId'].indexOf(roleId, 0);
              console.log(index);
              if (index > -1) {
                this.myForm.value['roleId'].splice(index, 1);
              }
            }
          }
        }else{
          console.log((document.getElementById("select"+i) as HTMLInputElement).value);
          this.myForm.value['roleId'].push((document.getElementById("select"+i) as HTMLInputElement).value);

          
        }

        
        console.log(this.myForm.value['roleId']);
      }

      getRoleIds(id , value) {
        console.log("print -------------*********" );
        console.log('value of' , id , 'is' , value );
        // console.log(pointer[pointer.selectedIndex].value); 
        // console.log(pointer[pointer.selectedIndex].id);
    
        // this.roleIdsJSON[id]=value;
    
        console.log(value);
        console.log(this.myForm.value['roleId'])
        for (let roleId of this.myForm.value['roleId']){
          if (String((roleId.split("_")[0]).trim()) === String(value.split("_")[0].trim())){
            // console.log("role Id matched.....");
            // delete this.myForm.value['roleId'][String(roleId).trim()];
            const index = this.myForm.value['roleId'].indexOf(String(roleId).trim(), 0);
            console.log(index);
            if (index > -1) {
              this.myForm.value['roleId'].splice(index, 1);
              this.myForm.value['roleId'].push(value);
            }
          }
      }

      console.log(this.myForm.value['roleId'])
    
        // if(value != undefined && value.trim() != "" && value != 'Select'){
        //   this.rolesHiddenValue = true;
        // }
    
        
     }

      // getRoles():Observable<any>{
      //   var paramObj = {};

      //   console.log("getroles ------->");
        
      //   paramObj["userName"] = this.username;
        
      //   //this.roleId = [];
      //   this.myForm.value["roleId"]=[];

      //   console.log("*****-->"+this.myForm.value['firstName']);
      //   console.log("*****-->"+this.myForm.value['lastName']);
        
      //   this.totalRes=[];
        
      //   this.manageUserService.getuserappentityroellist(paramObj).subscribe(
      //     (response: any) => {
            
            
      //       for(let i=0;i<response.length;i++){
              
             
      //         this.totalRes.push(response[i]);
              
           
      //       this.myForm.value["roleId"].push(response[i].accFlgRoleId);
      //       // console.log("*****-->"+this.myForm.value['firstName']);
      //     }
          
      //     }
          
      //   );
      
      //   return;
        
      // }

      validateSaveUserDetails(){
        this.clearMsgCt();
        this.errMsg="";
        this.bError=false;
        console.log("In the value AAAAAA" + this.myForm.value['roleId']);

        console.log(this.totalRes);

        var rolesString = "";

        for(let role of this.myForm.value['roleId']){
          if((role.split("_"))[1] != undefined && (role.split("_"))[1].trim() != "" ){
            var matchedJSON = this.totalRes.filter((i)=> i.entityId === role.split("_")[0])[0];

            console.log(matchedJSON);

            if(matchedJSON["assignmentId"] != ""){
              console.log("assignment  present::")
              if(rolesString.indexOf(matchedJSON["appId"]) > -1 ){
                rolesString=rolesString +"_"+ (role.split("_"))[1]+"~"+matchedJSON["assignmentId"];
                console.log("appId present");
                // console.log(rolesString);
              }
              else{
                
                rolesString=rolesString +matchedJSON["appId"]+":"+ (role.split("_"))[1]+"~"+matchedJSON["assignmentId"];
                console.log("appId not present");
                // console.log(rolesString);
              }
            }else{

              console.log("assignment not present::")
              if(rolesString.indexOf(matchedJSON["appId"]) > -1 ){
                
                rolesString=rolesString +"_"+ (role.split("_"))[1].trim()+"~";
              }
              else{
                rolesString=rolesString +matchedJSON["appId"]+":"+ (role.split("_"))[1].trim();
              }
            }
            
          }
        }

        var paramObj = {};
        paramObj["firstName"] = this.myForm.value["firstName"];
        paramObj["lastName"] = this.myForm.value["lastName"];
        paramObj["emailAddress"] = this.myForm.value["email"];//inv.comment;
        paramObj["phoneNumber"] = this.myForm.value["phNumber"];
        var bValidPh=this.validatePhone(this.myForm.value["phNumber"]);
			 if(!bValidPh){
          this.bError=true;
          this.errMsg+="\nPlease enter valid phone";
        }
        paramObj["pwd"] = this.myForm.value["newPwd"];
        paramObj["cpwd"] = this.myForm.value["confirmNewPwd"];
        var bValidPpwd=this.validatePwd(this.myForm.value["newPwd"]);
        if(!bValidPpwd){
          this.bError=true;
          this.errMsg+="\nPlease enter valid password";
        }
        paramObj["userName"] = this.myForm.value["email"];
        
        if(this.bError){
          console.log("Priting inside error block..", this.bError);
          this.showMsgCt = true;
          this.msgCt = this.errMsg;
          this.msgClassCt = "alert-danger";
          return;
        }
        this.blockUI.start();
        this.manageUserService.updatecontactdetails(paramObj).subscribe(
          (response: any) => {
  
            console.log(" the response from contact details update original == " + response);
            this.getUsersList(1, null);
            this.blockUI.stop();
          }
        );

        console.log("rolesString == " + rolesString);
        var paramObj = {};
        paramObj["roles"] = rolesString;
        paramObj["userName"] = this.myForm.value["email"];//inv.comment;
        paramObj["userFullName"] = this.myForm.value["lastName"]+","+this.myForm.value["firstName"];
        console.log(this.myForm.value["lastName"]+","+this.myForm.value["firstName"]);
        this.blockUI.start();
        this.manageUserService.saveupdatedroles(paramObj).subscribe(
          (response: any) => {
            if(response.errFlg == "S")
            this.success=true;
            this.successMsg="All changes Saved";
            console.log(" the response from roles update == " + response.errFlg);

            this.blockUI.stop();
          }
        );

        
        
        var paramObj = {};
        
          (response: any) => {
           
           
          }

    
      }
    
      cancel(){}
    
      register(){}
    
    
      updatecontactdetails(){

        var paramObj = {};
        paramObj["firstName"] = this.myForm.value["firstName"];
        paramObj["lastName"] = this.myForm.value["lastName"];
        paramObj["emailAddress"] = this.myForm.value["email"];//inv.comment;
        paramObj["phoneNumber"] = this.myForm.value["phNumber"];
        var bValidPh=this.validatePhone(this.myForm.value["phNumber"]);
			 if(!bValidPh){
				this.bError=true;
				this.errMsg+="\nPlease enter valid phone";
			}
        paramObj["pwd"] = this.myForm.value["newPwd"];
        var bValidPpwd=this.validatePwd(this.myForm.value["newPwd"]);
        if(!bValidPpwd){
          this.bError=true;
          this.errMsg+="\nPlease enter valid password";
        }
        paramObj["cpwd"] = this.myForm.value["confirmNewPwd"];
        if(this.myForm.value["newPwd"] && this.myForm.value["newPwd"]!=this.myForm.value["confirmNewPwd"]){
          this.bError=true;
          this.errMsg+="\n New password and Confirm password are not same.";
        }
        paramObj["userName"] = this.myForm.value["email"];
        
        this.manageUserService.saveupdatedroles(paramObj).subscribe(
          (response: any) => {
  
            console.log(" the response from contact details update ngoninit here..  == " + response);
            this.ngOnInit();
          }
          
        );

        if(this.bError){
          this.showMsgCt = true;
          this.msgCt = this.errMsg;
          this.msgClassCt = "alert-danger";
        }
        
      }

      validatePwd (pwdVal){
	
        var psId="#ps";
        var b=false;
        $(psId).css({"color":"#000000"});
         // var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g"); // lower,upper,num, speical
          //var mediumRegex = new RegExp("^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g"); // lower,upper,num
          var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
          var enoughRegex = new RegExp("(?=.{8,}).*", "g");
            if(pwdVal&& pwdVal.trim().length>0){
            if (false == enoughRegex.test(pwdVal)) {
                  $(psId).css({"color":"red"});
                    $(psId).html('Password should have minimum 8 characters.');  
            }else if (mediumRegex.test(pwdVal)) {  
                  $(psId).css({"color":"green"});  
                    $(psId).html('Strong');
                    b=true; 
            } else {
                  $(psId).css({"color":"red"});
                    $(psId).html('Password should have 1 Uppercase, 1 Lowercase , 1 Number');  
            }
            }else{
              b=true;
             }
          return b;
     
        }

      validatePhone(pVal){
        var  sPhoneNum="";
        sPhoneNum=pVal;
          var bValidPhoneNum = false;
          var Validformats = 'Valid Formats:18005551234\r\n' +
            '1 800 555 1234\r\n       ' +
            '+1 800 555-1234\r\n      ' +
            '+86 800 555 1234\r\n     ' +
            '1-800-555-1234\r\n       ' +
            '1 (800) 555-1234\r\n     ' +
            '(800)555-1234\r\n        ' +
            '(800) 555-1234\r\n       ' +
            '(800)5551234\r\n         ' +
            '800-555-1234\r\n         ' +
            '800.555.1234\r\n         ' +
            '800 555 1234x5678\r\n    ' +
            '8005551234 x5678\r\n     ' +
            '1    800    555-1234\r\n ' +
            '1---800---555-1234\r\n   ';
      
      
          if (sPhoneNum && sPhoneNum.trim().length > 0) {
      
            //let pVldtr = new RegExp(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i);
            var pVldtr = new RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);
      
            bValidPhoneNum = pVldtr.test(sPhoneNum);
          }else{
           bValidPhoneNum = true;
           }
      
          return bValidPhoneNum;
        }

  openUserSerialMgmtModal(user) {
		console.log("in openUserSerialMgmtModal()....", user);
		const updateSerialComponentRef = this.modalService.open(UpdateSerialComponent);
    	updateSerialComponentRef.componentInstance.user = user;
  }	
  
	exportToExcel()
	{
    let url="manageuser/getuserslist";
		let params = {
      "selUserStatus": this.selUserStatus,
      "selUserType": this.selUserType, 
      "pageNum": this.pageNum,
      "pageSize":10,
      "exportToExcel":"Yes"     
    }
    let qryStr = Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
     url=url+"?"+qryStr;
     let obj=this.commonService.exportToExcel(url);
   }

   stopVideo(id: string){
    console.log('Printing id value: ', id);
    const video = document.getElementById(id) as HTMLVideoElement;
    if(video){
     video.pause();
     //video.load();
    }
   }
   

	}


