import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemadministrationComponentService } from './systemadministration.service';

@Component({
  selector: 'app-systemadministration',
  templateUrl: './systemadministration.component.html',
  styleUrls: ['./systemadministration.component.css']
})
export class SystemadministrationComponent implements OnInit {
  thereForeURL:any="";
  veraURL:any="";
  prismalyticsURL:any="";
  presseroURL:any="";
  colorlynxURL:any="";
  colorlynxProfilerURL:any="";
  irisxtractURL:any="";
  openTextRightFaxURL:any="";
  fax2mailURL:any="";
  intelligentgradingURL:any="";
  eCopyURL:any="";
  uniFlowURL:any="";

  constructor(private systemadministrationComponentService:SystemadministrationComponentService,private router: Router) { }

  ngOnInit() {

    this.systemadministrationComponentService.getUrl().subscribe(
      data=> {
        console.log("Printing url: ");
        console.log(data);
        this.thereForeURL= data.soluSupp.therefore;
        this.veraURL= data.soluSupp.vera;
        this.prismalyticsURL=data.soluSupp.prismalytics;
        this.presseroURL= data.soluSupp.pressero;
        this.colorlynxURL= data.soluSupp.colorlynx;
        this.colorlynxProfilerURL=data.soluSupp.colorlynxProfiler;
        this.irisxtractURL= data.soluSupp.irisxtract;
        this.openTextRightFaxURL=data.soluSupp.openTextRightFax;
        this.fax2mailURL=data.soluSupp.fax2mail;
        this.intelligentgradingURL=data.soluSupp.intelligentgrading;
        this.eCopyURL=data.soluSupp.eCopy;
        this.uniFlowURL=data.soluSupp.uniFlow;

      }
    );
    
  }
  editLinkButton(){
    this.router.navigate(['fleetmgmtsysadminedtlinks']);
  }

  //$("#thereForeURLBtn")
    clickThereForeURLBtn() {
        //var thereForeURL = document.getElementById('thereForeURL').value
        // alert(uniFlowURL);
        if ((this.thereForeURL).trim().length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.thereForeURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//veraURL
//$("#veraURLBtn")

      clickVeraURLBtn() {
        //var veraURL = document.getElementById('veraURL').value
        // alert(veraURL);
        if ($.trim(this.veraURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.veraURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }

//prismalyticsURL
//$("#prismalyticsURLBtn")
      clickPrismalyticsURL() {
        //var prismalyticsURL = document.getElementById('prismalyticsURL').value
        // alert(prismalyticsURL);
        if ($.trim(this.prismalyticsURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.prismalyticsURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//presseroURL
//$("#presseroURLBtn")

      clickPresseroURL() {
        //var presseroURL = document.getElementById('presseroURL').value
        // alert(uniFlowURL);
        if ($.trim(this.presseroURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.presseroURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }
//colorlynxURL
//$("#colorlynxURLBtn")

      clickColorlynxURL() {
        //var colorlynxURL = document.getElementById('colorlynxURL').value
        // alert(uniFlowURL);
        if ($.trim(this.colorlynxURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.colorlynxURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//colorlynxProfilerURL
//$("#colorlynxProfilerURLBtn")
      clickColorlynxProfilerURL() {
        //var colorlynxProfilerURL = document.getElementById('colorlynxProfilerURL').value
        // alert(uniFlowURL);
        if ($.trim(this.colorlynxProfilerURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.colorlynxProfilerURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }

//irisxtractURL
//$("#irisxtractURLBtn")
      clickIrisxtractURL() {
        //var irisxtractURL = document.getElementById('irisxtractURL').value
        // alert(uniFlowURL);
        if ($.trim(this.irisxtractURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.irisxtractURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//openTextRightFaxURL
//$("#openTextRightFaxURLBtn")

      clickOpenTextRightFaxURL() {
        //var openTextRightFaxURL = document.getElementById('openTextRightFaxURL').value
        // alert(uniFlowURL);
        if ($.trim(this.openTextRightFaxURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.openTextRightFaxURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }

//fax2mailURL
//$("#fax2mailURLBtn")

      clickFax2mailURL() {
        //var fax2mailURL = document.getElementById('fax2mailURL').value
        // alert(fax2mailURL);
        if ($.trim(this.fax2mailURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.fax2mailURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//intelligentgradingURL
//$("#intelligentgradingURLBtn")
      clickIntelligentgradingURLBtn() {
        //var intelligentgradingURL = document.getElementById('intelligentgradingURL').value
        // alert(intelligentgradingURL);
        if ($.trim(this.intelligentgradingURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.intelligentgradingURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }

//eCopyURL
//$("#eCopyURLBtn")

      clickECopyURL() {
        //var eCopyURL = document.getElementById('eCopyURL').value
        // alert(eCopyURL);
        if ($.trim(this.eCopyURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.eCopyURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }


//uniFLOW
//$("#uniFLOWURLBtn")
      clickUniFlowURL() {
        //var uniFlowURL = document.getElementById('uniFLOWURL').value
        // alert(uniFlowURL);
        if ($.trim(this.uniFlowURL).length < 1) {
          alert("This Site is not accessible");
          return;
        } else {
          var newWindow = window
              .open(this.uniFlowURL, 'Test',
                  'scrollbars,width=800,height=550,left=100,top=100');
          newWindow.focus();
        }
      }

}
