import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], keyword:any, properties : string[], exactMatch = false): any[] {

    if(!items) return [];

    if(!keyword) return items;

    if(properties.length == 0){
      if(exactMatch){
        return items.filter(item => item.toLowerCase() === (keyword.toLowerCase()));
      }else {
        return items.filter(item => item.toLowerCase().indexOf(keyword.toLowerCase()) > -1 );
      }
      
    }else{
      return items.filter(
        item => {
          var itemFound: Boolean = false;

          for(let i=0; i<properties.length; i++){

            if(exactMatch){
              if(item[properties[i]] && item[properties[i]].toString().toLowerCase() == (keyword.toString().toLowerCase())){
                itemFound = true;
                break;
              }
            }else{
              if(item[properties[i]] && item[properties[i]].toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) !== -1){
                itemFound = true;
                break;
              }
            }
            
          }
          return itemFound;
        }
      );
    }

    
  }
}
