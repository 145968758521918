import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Menu, MenuService } from './menu.service';
import { Router } from '@angular/router';
import { EncrDecr } from 'src/app/service/common-service/encrdecr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementService } from 'src/app/account-mgmt/agreement/agreement.service';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SwitchUserService } from 'src/app/admin/switch-user/switch-user.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild('headerNav', {read: ElementRef}) public headerNav!: ElementRef<any>;
  @BlockUI() blockUI: NgBlockUI;
  menuList : Menu[];
  selectedNavId : number = -1;
  selectedHelpNav : boolean = false;

  constructor(private menuService: MenuService, private router: Router, private commonService: CommonServiceService,
    private modalService: NgbModal, private agreeService:AgreementService,private suService:SwitchUserService) { }

  ngOnInit() {
    this.blockUI.start();
    this.suService.switchUserNotifyObservable$.subscribe(() => {
     this.getMenu();
    })
    
    this.getMenu();
   
  }
getMenu()
{
  this.menuService.getMenu().subscribe(
    response => {
      this.menuList = response
      this.blockUI.stop();
      this.setMenuNavigation(); 
    },
    error =>{

    }
  )
}
  toggleSubMenu(nav){

    this.selectedNavId = nav.menuId;
    /* if(!nav){
      this.selectedNavId = -1;
    }else {
      if(this.selectedNavId == -1){
        this.selectedNavId = nav.menuId;
      }else{
        this.selectedNavId = -1;
      }
    } */
  }

  toggleHelpSubMenu(){

    this.selectedHelpNav = true;
    /* if(!nav){
      this.selectedNavId = -1;
    }else {
      if(this.selectedNavId == -1){
        this.selectedNavId = nav.menuId;
      }else{
        this.selectedNavId = -1;
      }
    } */
  }

  handleSubNav(subNavItem) {
    this.selectedNavId = -1;

    console.log(">>>>>>>>"+subNavItem.route);

    if(subNavItem.route == 'agreement'){
      //this.modalService.open(AgreementComponent);
      this.agreeService.getDoc();
    }else{
      // this.router.navigate(['/',subNavItem.route]);
      this.router.navigateByUrl("/"+subNavItem.route);

      //this.router.navigate(['/','dashboard']);

    }
    
  }

  handleHelpFulLinkSubNav(){
    this.selectedHelpNav = false;
  }


  eLearningAuthentication(){
    this.commonService.eLearningAuthentication();

  }

  setSubMenuPosition() {
    const element = this.headerNav.nativeElement;
    const parents = document.getElementsByClassName('parent') as any;
    for(let i=0; i< parents.length; i++) {
        const leftPos = parents[i].offsetLeft;
        parents[i].getElementsByClassName('sub-wrapper')[0].style.insetInlineStart = (leftPos - element.scrollLeft)+'px';
    }
  }

  sideScroll(direction,speed,distance,step) {
    const element = this.headerNav.nativeElement;
    


    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        if(direction === 'left') {
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
            this.setSubMenuPosition();
        }
    }, speed);
  }

  scrollLeft() {
    this.sideScroll('left', 100,100,80);
  }
  scrollRight() {
    this.sideScroll('right', 100,100,80);
  }


  setMenuNavigation(){
 
    $( "html" ).on( "click", function() {
       $('.nav-site-links .link-group a').removeClass("nav-link-active");
          $('.link-content-container').removeClass("openSubMenu");
    });
    
    
    $('#nav-site-wrapper,.link-content-container').on( "click", function(event) {
           event.stopPropagation();
      });

 

 
    // nav site scroll actions
    $('#nav-link-controls-right').click(function () {

      var currentWidth = $('#nav-site-wrapper').width();


      $('.nav-site-wrapper').animate({
          scrollLeft: '+=' + currentWidth
      }, 300);
  });
  $('#nav-link-controls-left').click(function () {

      var currentWidth = $('#nav-site-wrapper').width();

      $('.nav-site-wrapper').animate({
          scrollLeft: '-=' + currentWidth
      }, 300);
  });
  
 
  $( "#nav-link-controls-right" ).ready(function(){
setTimeout(function () {
  if ($(".ui-multiselect").length){
      $(".ui-multiselect-checkboxes").css({
          'width': ($(".ui-multiselect").width() + 'px')
        });
    } 

// menu open/close Start

$('#account-management-trig').click(function () {

  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#account-management-trig-sub-menu').addClass("openSubMenu");
});
$('#product-supplies-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#product-supplies-trig-sub-menu').addClass("openSubMenu");
});
$('#invoice-payment-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#invoice-payment-trig-sub-menu').addClass("openSubMenu");
});
$('#service-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#service-trig-sub-menu').addClass("openSubMenu");
});
$('#meter-reads-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#meter-reads-trig-sub-menu').addClass("openSubMenu");
});
$('#user-registration-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#user-registration-trig-sub-menu').addClass("openSubMenu");
});
$('#reports-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#reports-trig-sub-menu').addClass("openSubMenu");
});
$('#fleet-mgmt-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#fleet-mgmt-trig-sub-menu').addClass("openSubMenu");
});
$('#helpful-tools-trig').click(function () {
  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#helpful-tools-trig-sub-menu').addClass("openSubMenu");
});

$('#customer-quoting-portal').click(function () {
$('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#customer-quoting-portal-sub-menu').addClass("openSubMenu");
});

$('#marketing-admin-trig').click(function () {

  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#marketing-admin-trig-sub-menu').addClass("openSubMenu");
});

$('#web-admin-trig').click(function () {

  $('.nav-site-links .link-group a').removeClass("nav-link-active");
  $(this).addClass("nav-link-active");
  $('.link-content-container').addClass("openSubMenu");
  $('.link-content').removeClass("openSubMenu");
  $('#web-admin-trig-sub-menu').addClass("openSubMenu");
});

$('#nav-link-controls-right, #nav-link-controls-left').click(function () {
  $('.nav-link-sub-container').removeClass("openSubMenu");


});
    

// End menu open/close




if (window.location.href.indexOf("service.htm") > -1 || window.location.href.indexOf("meter.htm") > -1 || window.location.href.indexOf("userRegist.htm") > -1 
|| window.location.href.indexOf("userManagement.htm") > -1 || window.location.href.indexOf("meterhistory.htm") > -1 || window.location.href.indexOf("ctrBill.htm") > -1
|| window.location.href.indexOf("configurator.htm") > -1 || window.location.href.indexOf("mds.htm") > -1) {
               var currentWidth = $('#nav-site-wrapper').width();           	
             $('.nav-site-wrapper').animate({
                   scrollLeft: '+=' + currentWidth
               }, 5);   
      }	
      }, 50);
});
 // nav links actions

       



  }


}
