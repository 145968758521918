import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '../../app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class SavedPaymentService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getCCList=function()
    {
        let url = this.defaultUrl + "paymentMethod/geccl";
        
        return this.http
          .get(url,'', httpOptions)
          .pipe(catchError((this.util.handleError("getCCList"))));
    }

    getACHList=function()
    {
        let url = this.defaultUrl + "paymentMethod/getACHList";
        
        return this.http
          .get(url,'', httpOptions)
          .pipe(catchError((this.util.handleError("getACHList"))));
    }

    getInitialData=function()
    {
        let url = this.defaultUrl + "paymentMethod/gid";
        
        return this.http
          .get(url,'', httpOptions)
          .pipe(catchError((this.util.handleError("getInitialData"))));
    }

    updateBankInfo=function(oParams)
    {
        let url = this.defaultUrl + "paymentMethod/updateBankInfo";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
        
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("updateBankInfo"))));
    }

    saveCCPayment=function(oParams)
    {
        let url = this.defaultUrl + "paymentMethod/saveCC";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
        
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("saveCCPayment"))));
    }

    deleteCCPayment=function(oParams)
    {
        let url = this.defaultUrl + "paymentMethod/deleteCC";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
        
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("deleteCCPayment"))));
    }

    deleteACHInfo=function(oParams)
    {
        let url = this.defaultUrl + "paymentMethod/deleteACHInfo";
        let params = new URLSearchParams();
        for (let key in oParams) {
          params.set(key, oParams[key])
        }
        let qryStr=params.toString();
        
        return this.http
          .post(url,qryStr, httpOptions)
          .pipe(catchError((this.util.handleError("deleteACHInfo"))));
    }

}
