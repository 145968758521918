import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractSuppliesService } from '../contract-supplies.service';
import { DatePipe } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-order-supplies-submit-meter-reads',
  templateUrl: './order-supplies-submit-meter-reads.component.html',
  styleUrls: ['./order-supplies-submit-meter-reads.component.css']
})
export class OrderSuppliesSubmitMeterReadsComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  serialNum : string;
  contractId : string;
  contractNum : string;
  sectionId : string;
  instanceId : string;
  model : string;
  source : string;
  address : string;

  mtrErrMsg : string = '';
  showReads : boolean = false;
  counterRdFlgs : string[];
  counterRds : any[];

  currentDate : any
  constructor(private route : ActivatedRoute, private csService: ContractSuppliesService,
    private router : Router, private datePipe: DatePipe ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.serialNum = params['serialNum'];
        this.contractId = params['contractId'];
        this.contractNum = params['contractNum'];
        this.sectionId = params['sectionId'];
        this.instanceId = params['instanceId'];
        this.model = params['model'];
        this.source = params['source'];
        this.address = params['address'];

        this.loadInitialData();
      }
    )
  }

  loadInitialData(){

    this.blockUI.start();

    this.currentDate = this.datePipe.transform(new Date(), 'dd-MMM-yyyy');
    let paramsRRF = { 
      "serialNumber": this.serialNum, 
      "contractId": this.contractId 
     };

    this.csService.getReadsReqFlag(paramsRRF).subscribe(
      data => {
        this.counterRdFlgs = data;  

        this.getCounterReadDetails();

        
      }
    )

  }

  getCounterReadDetails(){
    let paramsCRD = { 
      "serial_number": this.serialNum, 
      "contractNumber": this.contractNum,
      "instance_id": this.instanceId,
      "readTpe": "I"
     };

    this.csService.getCounterReadDetails(paramsCRD).subscribe(
      data => {
 
        if(data != null && data.length > 0){

          this.counterRds = data;

          var arrTmpRd = this.counterRds;
              
          if(arrTmpRd !=null){
                
            var bLastReadExist=true;
                
            for(var k in arrTmpRd){
              
              var bReadReq = false;
              var obj = arrTmpRd[k];
              var readingReqFlg = obj["readingReqFlg"];
              
              if(readingReqFlg && readingReqFlg == "Y"){
                    bReadReq=true;
              }
                  
              var str = "";
              str = obj.lastReadFlag
                  
              if(str != null){
                        
                var pData = str.split("*");
                     
                if(pData[0] != null &&  pData[0] != undefined){
                  obj["lstRdFlg"] = pData[0];
                      
                  if(bReadReq && pData[0] != "Y"){
                    bLastReadExist=false;
                  }
                }	
                
                if(readingReqFlg == 'N'){
                  obj["previousRd"] = obj["lastReadingAmnt"];
                }

                if(pData[1] != null &&  pData[1] != undefined)
                  obj["previousRd"] = pData[1];
                       
                  if(pData[2] != null &&  pData[2] != undefined)
                    obj["previousDt"] = pData[2];
                }
                arrTmpRd[k] = obj;
                  
              }
              
              this.counterRds = arrTmpRd;
                
              // 0 - supReadReqFlag,  1	- isImageware,   2 - mpsFlg    
              var supReadReqFlag = this.counterRdFlgs[0];
              var isImageware = this.counterRdFlgs[1];
              var mpsFlg = this.counterRdFlgs[2];
  
              if(bLastReadExist){
                this.gotoAddToCartPage();
              }else if (supReadReqFlag=="N" || isImageware=="Y" || mpsFlg=="Yes"){
                this.gotoAddToCartPage();
              }else{
                this.showReads=true;	
              } 
            }
          } 
          this.blockUI.stop();
      }
    )
  }

  submitMeter(){
		this.mtrErrMsg = ""; 

    var cntrRds='';
    var submitVal = true;
    var readsExist = false;

    for(var j=0;j<this.counterRds.length ;j++){

      var read = this.counterRds[j];
      
      if(!read.readOnly && read.readingReqFlg == 'Y' &&  !read.currentRd){
        this.mtrErrMsg = "Supplies Counter Read is required.";
        submitVal = false;
        this.fnScrollToEle(); 
        return;
      }

      if(!read.readOnly && read.readingReqFlg == 'Y' &&  read.currentRd != '' && read.currentRd !== undefined && read.previousRd != '' && read.previousRd !== undefined){
        var curCounterRd = parseInt(read.currentRd);
        var lstReadAmt = parseInt(read.previousRd);
        readsExist = true;

        if(curCounterRd < lstReadAmt){	
          this.mtrErrMsg = "Current meter read should be greater than last meter read.";
          submitVal = false;
          this.fnScrollToEle(); 
          return;
        }
      }
					
      if(read.currentRd == '' || read.currentRd == 'null'  || read.currentRd == undefined){
        read.currentRd = 0;
      }

      if(j==0){
        cntrRds = read.counterName +":"+ read.currentRd;
      }else{
        cntrRds+=","+read.counterName +":"+ read.currentRd;
      }
    }
				
    if((submitVal) && (readsExist)){
      let params = {
        "readMeatersData": JSON.stringify(this.counterRds), 
        "reads": cntrRds, 
        "date": this.currentDate, 
        "contractNum": this.contractNum, 
        "contract_id": this.contractId, 
        "readTpe":'T'      
      }

      
      this.csService.submitMeterReadsSup(params).subscribe(
        data => {
          var errorFlg = data[0];
          var errorMsg = data[1];

          if(errorFlg == "S"){
            this.gotoAddToCartPage();
          }else{
            this.mtrErrMsg = errorMsg;
            this.fnScrollToEle();
            return;
          }
        }
      )

    }

 	}

  gotoAddToCartPage(){
    let params = {
      "serial_number": this.serialNum,  
      "contractId": this.contractId, 
      "contractNum": this.contractNum, 
      "sectionId": this.sectionId, 
      "model": this.model, 
      "instanceId": this.instanceId, 
      "address": this.address, 
    }

    this.router.navigate(['ordersupplieslisting'], {queryParams : params});
  }

  fnScrollToEle=function (){
    document.getElementById("eleMtrErr").scrollIntoView();
  }
}
