import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'src/app/app.util';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})

export class RequestPrintAssessmentService {
	defaultUrl: string;

   constructor(private http: HttpClient, private util : Util) {
    this.defaultUrl = this.util.getBaseUrl();
   }


  getPhoneNum=function(){
    let url = this.defaultUrl + "mdsc/assessmentInfo"; 
    console.log("Printing inside getPhoneNum method...");
    console.log(url);
    return this.http
    .get(url, httpOptions)
    .pipe(catchError((this.util.handleError("getPhoneNum"))));
	}
// Need to change arguments to 6 values in it..
  sendEmail=function(bPersonValue, bEmailValue, bTelValue, tPersonValue, tEmailValue, tTelValue){
    let url = this.defaultUrl + "mdsc/sendmdsemail"; // "products.htm/gpcd',data";
    var data:any = {};
    	data.bPersonValue = bPersonValue;
    	data.bEmailValue = bEmailValue;
      data.bTelValue= bTelValue;
      data.tPersonValue = tPersonValue;
      data.tEmailValue = tEmailValue;
      data.tTelValue = tTelValue;
    	// var productDetailsPromise = httpService.postJsonData('products.htm/gpcd',data);
     	// return productDetailsPromise;
    return this.http
    .post(url,data, httpOptions)
    .pipe(catchError((this.util.handleError("sendEmail"))));
	}



}
