import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Util } from 'src/app/app.util';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class PendingUserApprovalService {

  defaultUrl: string;

    constructor(private http: HttpClient, private util: Util) {
        this.defaultUrl = this.util.getBaseUrl();
    }
    

 


	getAllUsers(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.defaultUrl + "userApprove/getPendingApprovalUser?" + qryStr;
		console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getBAUsers")))
	  }	


getexportBtnData(data): Observable<any>{
  let qryStr=$.param(data); 
  let initialUrl = this.defaultUrl + "userApprove/getPendingApprovalUserExport?" + qryStr;
  console.log("Pritnign initialUrl: " + initialUrl);
	    return this.http
	      .get(initialUrl, httpOptions)
	      .pipe(catchError(this.util.handleError("getexportBtnData")))

}

getdownloadRegTempExcel(){
  let url = this.defaultUrl+"userApprove/downloadUserRegTemplate";
  return url;
}

approveUser(userId) : Observable<any> {
  let qryStr=$.param(userId); 
  let initialUrl = this.defaultUrl + "userApprove/setApprovedUsers?" + qryStr;
  console.log("Printing initialUrl of approveUser: " + initialUrl);
	    return this.http
	      .get(initialUrl, httpOptions)
	      .pipe(catchError(this.util.handleError("approveUser")))



}

rejectUser(userId) : Observable<any> {
  let qryStr=$.param(userId); 
  let initialUrl = this.defaultUrl + "userApprove/setRejectUsers?" + qryStr;
  console.log("Printing initialUrl of approveUser: " + initialUrl);
	    return this.http
	      .get(initialUrl, httpOptions)
	      .pipe(catchError(this.util.handleError("getexportBtnData")))



}

getBAList(data): Observable<any> {
  let data1 = JSON.stringify(data);
let qryStr=$.param(data); 
  let initialUrl = this.defaultUrl + "userApprove/getBAList?" + qryStr;
console.log("Pritnign initialUrl: " + initialUrl);
  return this.http
    .post(initialUrl, data1, httpOptions)
    .pipe(catchError(this.util.handleError("getBAUsers")))
}

}


