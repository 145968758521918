export class canonSearchDevice {
    
    
    serialNumber: string;
    model: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    notes: string;
    
    
 }