import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { AccountSupportService } from 'src/app/account-mgmt/account-support/account-support.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-account-support',
  templateUrl: './account-support.component.html',
  styleUrls: ['./account-support.component.css']
})
export class AccountSupportComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;

  custSupPhoneNum = "";
  contactName = "";
  emailId = "";
  areaCode= "";
  firstThreeDigits= "";
  lastFourDigits = "";
  extension = "";
  problemCodeList = [];
  servicePrograms = [];
  transactionComments= [];
  mpsProgramCount="";

  selAction:string = "default";
  objAction = {};
  sAcId = "";

  srcEntryList = [];
  serialTxt = "";
  modelTxt = "";
  addressTxt = "";
  selActionFlg = "N";

  selectedSerials = [];
  selectedOraSerials = [];
  selectedS21Serials = [];

  addrChangeSerials = [];
  correctMeterReadSerials = [];

  searchSerialsTotal = 0;	

  //Pagination related
  pageNum: number = 1;
  recPerPage = 10;
  numOfRecsMsg="";
  arrPgLinks=[];
  showNoRecsMsg = false;

  pleaseWaitCls:boolean = false;

  counterDetailsHTML : string = "";
  
  @ViewChild('si_addtl_info') si_addtl_info: ElementRef;
  @ViewChild('oi_addtl_info') oi_addtl_info: ElementRef;	
  @ViewChild('ii_addtl_info') ii_addtl_info: ElementRef;	
  @ViewChild('ci_addtl_info') ci_addtl_info: ElementRef;	
  @ViewChild('ac_addtl_info') ac_addtl_info: ElementRef;
  @ViewChild('rd_addtl_info') rd_addtl_info: ElementRef;
	

  constructor(private commonService:CommonServiceService,private accountService:AccountSupportService
				, private util : Util, private modalService: NgbModal, private route : Router,
				private titleService: Title) { }

  	ngOnInit() {
	
		this.titleService.setTitle("Account Support");
		
		this.objAction["changeAddress"]=false;
    	this.objAction["correctReads"]=false;
    	this.objAction["contractInquiry"]=false;
    	this.objAction["invoiceInquiry"]=false;
    	this.objAction["serviceInquiry"]=false;
    	this.objAction["otherInquiry"]=false;
	
		this.accountService.getData(null).subscribe(data => {
			if (data != null) {
				console.log('getData():', data);

				this.problemCodeList=data.problemCodes; 
				this.servicePrograms=data.servicePrograms;
				this.transactionComments=data.transactionComments;
				this.contactName = data.userName;
				this.emailId = data.userEmailId;
				this.mpsProgramCount = data.mpsProgramCount;
				this.areaCode= data.areaCode;
				this.firstThreeDigits= data.phoneNumber;
				this.lastFourDigits = data.phoneNumberLast4;
				this.extension = data.extension;
				this.custSupPhoneNum= data.custSupPhoneNum;
				
			}
		});
		
		this.commonService.setCommonFunctions();	
		
  	}


    setAction(){
    	var fn="[setAction]";   

		this.selectedSerials = [];
		this.selectedOraSerials = [];
  		this.selectedS21Serials = [];
		this.addrChangeSerials = [];
		this.correctMeterReadSerials = [];

		
		var sAction = this.selAction;
		console.log("Selected Option:" + sAction);
		
    	this.fnClearError();
    //	console.log(fn+"sAction:"+sAction);
    	try {
    		
    	this.fnClear();	
	  	var OTHER_QUESTION="otherQuestion";

    	this.objAction["changeAddress"]=false;
    	this.objAction["correctReads"]=false;
    	this.objAction["contractInquiry"]=false;
    	this.objAction["invoiceInquiry"]=false;
    	this.objAction["serviceInquiry"]=false;
    	this.objAction["otherInquiry"]=false;

    	if(sAction){
    		
    		this.selActionFlg="N";    // setting this flag to Y for Meter Reads  
    		
    		//var sAcTypeId="#sActionType";

    		if(sAction=="changeAddress"){
    			this.objAction["changeAddress"]=true;
    			//$(sAcId).val("changeAddress");
    		}else if(sAction=="correctMeterReads"){
    			this.objAction["correctReads"]=true;
    			//$(sAcId).val("correctMeterReads");
    			this.selActionFlg="Y";
    		}else if(sAction=="CONTRACT_INQUIRY"){
    			//$(sAcId).val(OTHER_QUESTION);
    			this.objAction["contractInquiry"]=true;
    		}else if(sAction=="INVOICE_INQUIRY"){
    			this.objAction["invoiceInquiry"]=true;
    			//$(sAcId).val(OTHER_QUESTION);
    		}else if(sAction=="SERVICE_INQUIRY"){
    			this.objAction["serviceInquiry"]=true;
    			//$(sAcId).val(OTHER_QUESTION);
    		}else if(sAction=="OTHER_INQUIRY"){
    			this.objAction["otherInquiry"]=true;
    			//$(sAcId).val(OTHER_QUESTION);
    		}
    		
			console.log(this.objAction);
    		//$(sAcTypeId).val(sAction);
    		//$("#selActionFlg").val(selActionFlg); 
    		
    	}
     
    	} catch (e) {
			console.log(fn+"ERROR:"+e.message);
		}
    }
    
	loadSerialData() {
		this.serialTxt = "";
		this.modelTxt = "";
		this.addressTxt = "";
		//this.selectedSerials = [];
		//this.selectedOraSerials = [];
  		//this.selectedS21Serials = [];
		//this.addrChangeSerials = [];

		
		this.searchSerialData(1,null);
		/*
		this.pleaseWaitCls = true;
		
		var param = {
		      "serialNumber": this.serialTxt,
		      "selActionFlg": this.selActionFlg,
		      "model": this.modelTxt,
			  "address" : this.addressTxt,
		      "orderCol":   "serialNumber",
		      "orderDir": 	"asc",
		      "start": 1,
		      "length": this.recPerPage
		    };	
		
		this.accountService.serialNumberSearch(param).subscribe(data => {
			if (data != null) {
				console.log('serialNumberSearch():', data);

				this.srcEntryList=data.data; 
				this.pleaseWaitCls = false;
				
			}
		});			
		*/
	}
	
	searchSerialData(pagenumber,event: any) {
		
		if(event!=null){
      		event.preventDefault();
    	}		
		
		this.pageNum = pagenumber;
		var start = (this.pageNum == 1 ) ? 0 : ( (this.pageNum -1 ) * (this.recPerPage) ) + 1;
		this.showNoRecsMsg=false;
		this.pleaseWaitCls = true;
		var param = {
		      "serialNumber": this.serialTxt,
		      "selActionFlg": this.selActionFlg,
		      "model"	: this.modelTxt,
			  "address" : this.addressTxt,
		      "orderCol": "serialNumber",
		      "orderDir": "asc",
		      "start"	: start,
		      "length"	: (this.pageNum == 1 ) ? this.recPerPage  : this.recPerPage -1
		    };	

		
		this.accountService.serialNumberSearch(param).subscribe(data => {
			if (data != null) {
				console.log('serialNumberSearch():', data);

				this.srcEntryList=data.data; 
				this.searchSerialsTotal = data.recordsTotal;
				
				this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.searchSerialsTotal,this.recPerPage,this.pageNum);
         		this.arrPgLinks=this.commonService.getPgLinks(this.searchSerialsTotal,this.pageNum,this.recPerPage);
				
				this.pleaseWaitCls = false;
				
				if(this.searchSerialsTotal == 0) {
					this.showNoRecsMsg=true;
				}
				
			}
		});			
		
	}
	
	shouldBeChecked(serialNum) {
		 
		if(this.selAction =="changeAddress"){
			var item = this.addrChangeSerials.filter(item => item.serialNumber == serialNum);
			if(item != undefined && item.length > 0) {
				return true;
			}
			
			return false;
    	}else if(this.selAction=="correctMeterReads"){
			var item = this.correctMeterReadSerials.filter(item => item.serialNumber == serialNum);

			if(item != undefined && item.length > 0) {
				return true;
			}
			
			return false;
		} else {
			
			var item = this.selectedSerials.filter(item => item == serialNum);

			if(item != undefined && item.length > 0) {
				return true;
			}
			
			return false;			
			
		}
		
		return false;
	
	}
	
	removeCorrectMeterReadSerial(serialNumber){
		this.correctMeterReadSerials  = this.correctMeterReadSerials.filter(item => item.serialNumber != serialNumber);
	}
	
	removeAddrChgSerial(serialNumber){
		this.addrChangeSerials = this.addrChangeSerials.filter(item => item.serialNumber != serialNumber);
	}
	
	async setSrl(serialElement: any, source, idx) {
			
		console.log(serialElement.value + ":" + serialElement.checked + ":" + source + ":" + idx);
		
		if(this.selAction=="correctMeterReads") {
			
			var element: HTMLInputElement;
			element  = document.getElementById("serialData_"+idx) as HTMLInputElement;
			//console.log(element.value);
			var selSerial = this.fromJson(element.value);
						
			if(serialElement.checked) {

				// get counter data for this serial 
				var param = {
					"serialNumber" 	: selSerial.serialNumber,
					"instanceId" 	: selSerial.instanceId,
					};
				
				let data = await this.accountService.getCounterDetailsSync(param);
				console.log('getCounterDetails():', data);
				selSerial.invoiceNumber = data.invoiceNumber;
				
				var counterDetails = [];
				for(var iCounter  of data.alCtrDetails) {
					var tokens = iCounter.split(":");
					if(tokens[1].toUpperCase() == "YES"  ){
						tokens[1] = "required";
						tokens[2] = "background-color:#FFFF66";
					} else {
						tokens[1] = " ";
						tokens[2] = " ";
					}
					counterDetails.push(tokens);
				}
				
				selSerial.counterDetails = counterDetails; //data.alCtrDetails;
				
				// add row to table
				this.removeCorrectMeterReadSerial(selSerial.serialNumber);
				this.correctMeterReadSerials.push(selSerial);
				this.fnClearError();
			} else {
				// remove row from table
				this.removeCorrectMeterReadSerial(selSerial.serialNumber);
			}
			
			console.log("correctMeterReadSerials : " , this.correctMeterReadSerials);
		
			setTimeout(function (){
       			$(".mat-form-field-infix").css({"width":"100%"});
     		},2000);
		
		} else if(this.selAction=="changeAddress") {
			
			var element: HTMLInputElement;
			element  = document.getElementById("serialData_"+idx) as HTMLInputElement;
			//console.log(element.value);
			var selSerial = this.fromJson(element.value);
						
			if(serialElement.checked) {
				// add row to table
				this.removeAddrChgSerial(selSerial.serialNumber);
				this.addrChangeSerials.push(selSerial);
				this.fnClearError();
			} else {
				// remove row from table
				//this.addrChangeSerials = this.addrChangeSerials.filter(item => item.serialNumber != selSerial.serialNumber);
				this.removeAddrChgSerial(selSerial.serialNumber);
			}
			
			console.log(this.addrChangeSerials);
			
			setTimeout(function (){
       			$(".mat-form-field-infix").css({"width":"100%"});
     		},2000);			
			
		} else {
		
		if(serialElement.checked) {
			this.fnClearError();
			this.selectedSerials = this.selectedSerials.filter(item => item != serialElement.value);
			this.selectedSerials.push(serialElement.value);
			
			if(source.toLowerCase() === 'oracle') {
				this.selectedOraSerials = this.selectedOraSerials.filter(item => item != serialElement.value);
				this.selectedOraSerials.push(serialElement.value);
			} else {
				this.selectedS21Serials = this.selectedS21Serials.filter(item => item != serialElement.value);
				this.selectedS21Serials.push(serialElement.value);
			}
			
		} else {
			this.selectedSerials = this.selectedSerials.filter(item => item != serialElement.value);
			
			if(source.toLowerCase() === 'oracle') {
				this.selectedOraSerials = this.selectedOraSerials.filter(item => item != serialElement.value);
			} else {
				this.selectedS21Serials = this.selectedS21Serials.filter(item => item != serialElement.value);
			}			
		}
		
		console.log(this.selectedSerials);
		console.log("Oracle serials : "+this.selectedOraSerials); 
		console.log("S21 serials : " + this.selectedS21Serials);  	
		
		}
	}
	
	
	submitCorrectMeterReads() {
		if(this.correctMeterReadSerials.length<1){
			this.fnShowError("Please select a serial number");
		  	this.scrollToMsg();
			return ;
	   	}

		var error=false;
		error = this.validateRequired(this.rd_addtl_info);

		let elemArray = document.querySelectorAll('#correctMeterReadPanel .required');
		for(var currElem in elemArray) {
			var valRes = this.validateRequired(elemArray[currElem]);
			if(valRes){
				error = valRes;
			}
		}

		if (error) {	
		  	this.fnShowError("Please enter all required fields");
		  	this.scrollToMsg();
	   	}else{
			console.log("submitting correct meter reads request...");
			this.fnClearError();
			
			
			var serialParamArr = [];
			
			for(let cmrSerial of this.correctMeterReadSerials) {
				
				var invoiceNumber, incorrectReadDate;
				var updatedMeterReadArr = [];
				
				var element: HTMLInputElement;
				element = document.getElementById("invoiceNumber_" + cmrSerial.serialNumber) as HTMLInputElement;
				invoiceNumber = element.value;
				element = document.getElementById("incorrectReadDate_" + cmrSerial.serialNumber) as HTMLInputElement;
				incorrectReadDate = element.value;
				
				for(var loopIdx in cmrSerial.counterDetails) {
					
					var counterName, counterRead;
					
					element = document.getElementById("counterRead_" + cmrSerial.serialNumber + "_" + loopIdx) as HTMLInputElement;
					counterRead = element.value;
					element = document.getElementById("counterName_" + cmrSerial.serialNumber + "_" + loopIdx) as HTMLInputElement;
					counterName = element.value;
					
					var param = {
						"counterName"	: counterName,
						"counterRead" 	: counterRead,
					};
					
					updatedMeterReadArr.push(param);
				}
				
				var postParam = {
					"serialNumber" 	: cmrSerial.serialNumber,
					"source"		: cmrSerial.source,
					"invoiceNumber" : invoiceNumber,
					"incorrectReadDate" : incorrectReadDate,
					"counterDetails": this.toJson(updatedMeterReadArr),
				}
				
				serialParamArr.push(postParam);
			}
			
			var addtlInfo = this.rd_addtl_info.nativeElement.value;
			var ctName = this.contactName;			
			var ctEmail = this.emailId;
			var ctPhone = "";
			var ctPhoneExt= "";  
			this.areaCode=(this.areaCode)?this.areaCode.trim():""; 
			this.firstThreeDigits=(this.firstThreeDigits)?this.firstThreeDigits.trim():"";
			this.lastFourDigits=(this.lastFourDigits)?this.lastFourDigits.trim():"";
			this.extension=(this.extension)?this.extension.trim():"";  
			if(this.areaCode.length>0  && this.firstThreeDigits.length>0 && this.lastFourDigits.length>0){
				ctPhone = (this.areaCode + "-" + this.firstThreeDigits + "-" + this.lastFourDigits);
				if(this.extension != null && this.extension.length>0){
					ctPhoneExt = this.extension;
				}
			}
			
			
			var postparam = {
				"addtlInfo" 	: addtlInfo,
				"ctName"		: ctName,
				"ctEmail" 		: ctEmail,
				"ctPhone" 		: ctPhone,
				"ctPhoneExt" 	: ctPhoneExt,
				"serialParam"	: this.toJson(serialParamArr)
				};
			
			
			console.log("CorrectMeterRead-payload : ", postparam);
			
			// call service
			this.blockUI.start();
			this.accountService.submitCorrectMeterReads(postparam).subscribe(data => {
				if (data != null) {
					console.log('submitCorrectMeterReads():', data);
					var ticketNumber = data.requestTicketNumber;
					var retMsg = data.message;
					this.blockUI.stop();
					this.route.navigate(["accountsupportresponse" , {custCareTicketNumber : ticketNumber} ] );
				} 
			});						
			
			
		}		
		
	}
	
	
	submitAddrChange() {
		if(this.addrChangeSerials.length<1){
			this.fnShowError("Please select a serial number");
		  	this.scrollToMsg();
			return ;
	   	}

		var error=false;
		error = this.validateRequired(this.ac_addtl_info);

		let elemArray = document.querySelectorAll('#tblChangeAddr .required');
		for(var currElem in elemArray) {
			var valRes = this.validateRequired(elemArray[currElem]);
			if(valRes){
				error = valRes;
			}
		}

		if (error) {	
		  	this.fnShowError("Please enter all required fields");
		  	this.scrollToMsg();
	   	}else{
			console.log("submitting address change request...");
			this.fnClearError();
			
			var updateParams = [];
			
			for(var curSerial of this.addrChangeSerials) {
				
				var newAdd, newAdd2, newCity, newState, newZip, newEffDt;
				
				var element: HTMLInputElement;
				element = document.getElementById("newstreetAddress_" + curSerial.serialNumber) as HTMLInputElement;
				newAdd = element.value;
				element = document.getElementById("newstreetAddress2_" + curSerial.serialNumber) as HTMLInputElement;
				newAdd2 = element.value;
				element = document.getElementById("newCity_" + curSerial.serialNumber) as HTMLInputElement;
				newCity = element.value;
				element = document.getElementById("newState_" + curSerial.serialNumber) as HTMLInputElement;
				newState = element.value;
				element = document.getElementById("newPostalCode_" + curSerial.serialNumber) as HTMLInputElement;
				newZip = element.value;
				element = document.getElementById("newAddrEffDate_" + curSerial.serialNumber) as HTMLInputElement;
				newEffDt = element.value;
			
				var param = {
				"serialNumber" 	: curSerial.serialNumber,
				"source"	: curSerial.source,
				"address" 	: newAdd,
				"address2" 	: newAdd2,
				"city" 		: newCity,
				"state" 	: newState,
				"zipcode" 	: newZip,
				"effDate" 	: newEffDt,
				};
				
				updateParams.push(param);	
			}
			
			var addtlInfo = this.ac_addtl_info.nativeElement.value;
			
			var ctName = this.contactName;
			var ctEmail = this.emailId;
			var ctPhone = "";
			var ctPhoneExt= "";
			this.areaCode=(this.areaCode)?this.areaCode.trim():""; 
			this.firstThreeDigits=(this.firstThreeDigits)?this.firstThreeDigits.trim():"";
			this.lastFourDigits=(this.lastFourDigits)?this.lastFourDigits.trim():"";
			this.extension=(this.extension)?this.extension.trim():"";  
			if(this.areaCode.trim().length>0  && this.firstThreeDigits.trim().length>0 && this.lastFourDigits.trim().length>0){
				ctPhone = (this.areaCode.trim() + "-" + this.firstThreeDigits.trim() + "-" + this.lastFourDigits.trim());
				if(this.extension != null && this.extension.trim().length>0){
					ctPhoneExt = this.extension.trim();
				}
			}
			
			var altName, altEmail, altPh1, altPh2, altPh3, altPh4
			var element: HTMLInputElement;
			element = document.getElementById("altCtNameAc") as HTMLInputElement;
			altName = element.value;
			element = document.getElementById("altCtEmailAc") as HTMLInputElement;
			altEmail = element.value;
			element = document.getElementById("altPAreaCodeAc") as HTMLInputElement;
			altPh1 = element.value;
			element = document.getElementById("altPMid4Ac") as HTMLInputElement;
			altPh2 = element.value;
			element = document.getElementById("altPLastFourAc") as HTMLInputElement;
			altPh3 = element.value;
			element = document.getElementById("altPExtensionAc") as HTMLInputElement;
			altPh4 = element.value;
			
			var altPhone , altPhoneExt ="";
			
			if(altPh1.trim().length>0  && altPh2.trim().length>0 && altPh3.trim().length>0){
				altPhone = (altPh1.trim() + "-" + altPh2.trim() + "-" + altPh3.trim());
				if(this.extension != null && this.extension.trim().length>0){
					altPhoneExt = altPh4.trim();
				}
			}			
			
			var postparam = {
				"sAction" 		: "ChangeAddress",
				"addtlInfo" 	: addtlInfo,
				"ctName"		: ctName,
				"ctEmail" 		: ctEmail,
				"ctPhone" 		: ctPhone,
				"ctPhoneExt" 	: ctPhoneExt,
				"altName"		: altName,
				"altEmail" 		: altEmail,
				"altPhone" 		: altPhone,
				"altPhoneExt" 	: altPhoneExt,
				"updAdd4Serials": this.toJson(updateParams)
				};
			
			console.log(postparam);
			
			this.blockUI.start();
			this.accountService.submitAddressChange (postparam).subscribe(data => {
				if (data != null) {
					console.log('submitAddrChange():', data);
					var ticketNumber = data.requestTicketNumber;
					var retMsg = data.message;
					this.blockUI.stop();
					this.route.navigate(["accountsupportresponse" , {custCareTicketNumber : ticketNumber} ] );
				} 
			});						
			
			
		}

	}
	
	submitServiceInquiry() {

	   if(this.selectedSerials.length<1){
			this.fnShowError("Please select a serial number");
		  	this.scrollToMsg();
			return ;
	   }

		var error=false;
		
		if(this.objAction["contractInquiry"]==true) {
			error = this.validateRequired(this.ci_addtl_info);
		} else if(this.objAction["invoiceInquiry"]==true) {
			error = this.validateRequired(this.ii_addtl_info);
		} else if(this.objAction["serviceInquiry"]==true) {
			error = this.validateRequired(this.si_addtl_info);
		} else if(this.objAction["otherInquiry"]==true) {
			error = this.validateRequired(this.oi_addtl_info);
		} 

		if (error) {	
		  	this.fnShowError("Please enter all required fields");
		  	this.scrollToMsg();
	   }else{
			console.log("submitting Service Inquiry...");
			
			this.fnClearError();
			
			var ctEmail = this.emailId;
			var ctPhone = "";
			var ctPhoneExt= "";
			this.areaCode=(this.areaCode)?this.areaCode.trim():""; 
			this.firstThreeDigits=(this.firstThreeDigits)?this.firstThreeDigits.trim():"";
			this.lastFourDigits=(this.lastFourDigits)?this.lastFourDigits.trim():"";
			this.extension=(this.extension)?this.extension.trim():"";  
			if(this.areaCode.trim().length>0  && this.firstThreeDigits.trim().length>0 && this.lastFourDigits.trim().length>0){
				ctPhone = (this.areaCode.trim() + "-" + this.firstThreeDigits.trim() + "-" + this.lastFourDigits.trim());
				if(this.extension != null && this.extension.trim().length>0){
					ctPhoneExt = this.extension.trim();
				}
			}
			
			
			
			var addtlInfo = "";
			let otherqlist2= "";
			
			if(this.objAction["contractInquiry"]==true) {
				addtlInfo = this.ci_addtl_info.nativeElement.value;
				otherqlist2= "CONTRACT_INQUIRY";
			} else if(this.objAction["invoiceInquiry"]==true) {
				addtlInfo = this.ii_addtl_info.nativeElement.value;
				otherqlist2= "INVOICE_INQUIRY";
			} else if(this.objAction["serviceInquiry"]==true) {
				addtlInfo = this.si_addtl_info.nativeElement.value;
				otherqlist2= "SERVICE_INQUIRY";
			} else if(this.objAction["otherInquiry"]==true) {
				addtlInfo = this.oi_addtl_info.nativeElement.value;
				otherqlist2= "OTHER_INQUIRY";
			} 
			
			
			var srlsOra = this.selectedSerials.toString();
			
			var param = {
				"sAction" 		: "otherQuestion",
				"otherqlist2" 	: otherqlist2,
				"ctEmail" 		: ctEmail,
				"ctPhone" 		: ctPhone,
				"ctPhoneExt" 	: ctPhoneExt,
				"addtlInfo" 	: addtlInfo,
				"srlsOra" 		: this.selectedOraSerials.toString(),
				"srlsS21"		: this.selectedS21Serials.toString(),
				};
			
			this.blockUI.start();
			this.accountService.submitServiceInquiry (param).subscribe(data => {
				if (data != null) {
					console.log('submitServiceInquiry():', data);
					var ticketNumber = data.requestTicketNumber;
					var retMsg = data.message;
					this.blockUI.stop();
					this.route.navigate(["accountsupportresponse" , {custCareTicketNumber :ticketNumber} ] );
				} 
			});						
			
	   }
		
		
	}
	
	validateRequired(eleId){
    	
    	var error=false;
    	var sVal=""; 
		if (eleId instanceof HTMLInputElement || eleId instanceof HTMLSelectElement) {
			sVal = eleId.value;
			if(sVal.length<1){
			 	eleId.classList.add("required_field");
			 	error=true;
			}else{
				eleId.classList.remove("required_field");
			}							
		} else if (eleId instanceof ElementRef) {
			sVal= eleId.nativeElement.value;
			if(sVal.length<1){
			 	eleId.nativeElement.classList.add("required_field");
			 	error=true;
			}else{
				eleId.nativeElement.classList.remove("required_field");
			}				
		}
    	
		
    	    	
    	return error;
    	    	
    }	



    setReadExpandCollapse(eleId : HTMLElement){  
		console.log(eleId.children[0]);
		if(eleId.children[0].classList.contains("fa-plus")) {
			eleId.children[0].classList.remove("fa-plus");
      		eleId.children[0].classList.add("fa-minus");    	
		} else {
			eleId.children[0].classList.add("fa-plus");
      		eleId.children[0].classList.remove("fa-minus"); 
		} 

		    }



    fnClear(){
    	$("#hSerNum").val("");
    	
    }

    fnShowError(errMsg){
    	   $("#msg").html(errMsg).show(); 
		   this.scrollToMsg();
    }

    fnClearError(){
 	   $("#msg").html("").hide(); 

	    this.si_addtl_info.nativeElement.classList.remove("required_field");
		this.ci_addtl_info.nativeElement.classList.remove("required_field");
		this.oi_addtl_info.nativeElement.classList.remove("required_field");
		this.ii_addtl_info.nativeElement.classList.remove("required_field");
		this.ac_addtl_info.nativeElement.classList.remove("required_field");

	   this.scrollToMsg();
 	}

	scrollToMsg(){ 
   	 $('html, body').animate({
   	        scrollTop: $("#msg").offset().top-60
   	    }, 100);
   	}

	toJson(data) {
		return JSON.stringify(data);
	}
	
	fromJson(data) {
		return JSON.parse(data);
	}

	onCancel() {
		window.location.reload();
	}

	stopVideo(id: string){
		console.log('Printing id value: ', id);
		const video = document.getElementById(id) as HTMLVideoElement;
		if(video){
		 video.pause();
		 //video.load();
		}
	   }
	
}
