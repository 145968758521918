import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class RoleManageService {
  
  	baseUrl="";
 	
  	constructor(private http: HttpClient, private util : Util) { 
		this.baseUrl = this.util.getBaseUrl();
  	}

  
	getDropdownParentMenus(data): Observable<any> {
		let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
		
	    let initialUrl = this.baseUrl + "sysadmin/getDropdownParentMenus?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getDropdownParentMenus")))
	}
	
	getDropdownParentMenusSync(data): Promise<any>{
		return this.getDropdownParentMenus(data).toPromise();
	}	


	getParentMenuHierarchy(data): Observable<any> {
		let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
		
	    let initialUrl = this.baseUrl + "sysadmin/getParentMenuHierarchy?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getParentMenuHierarchy")))
	}
	
	
	upsertAllPermissions(data): Observable<any> {
		let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
		
	    let initialUrl = this.baseUrl + "sysadmin/upsertAllPermissions?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("upsertAllPermissions")))
	}	

}
