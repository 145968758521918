import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CreateCatalogService } from './create-catalog.service';
import { ProductCatalogService } from '../product-catalog/product-catalog.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-catalog',
  templateUrl: './create-catalog.component.html',
  styleUrls: ['./create-catalog.component.css']
})
export class CreateCatalogComponent implements OnInit {
  @ViewChild('custSearchModal') custSearchModalTemplate:TemplateRef<any>;
  custSearchModal: BsModalRef;

  @ViewChild('imgSearchModal') imgSearchModalTemplate:TemplateRef<any>;
  imgSearchModal: BsModalRef;

  @ViewChild('configSearchModal') configSearchModalTemplate:TemplateRef<any>;
  configSearchModal: BsModalRef;

  @ViewChild('imgUploadModal') imgUploadModalTemplate:TemplateRef<any>;
  imgUploadModal: BsModalRef;

  @BlockUI() blockUI : NgBlockUI;

  private selectedFile: File | null = null;
  customerList:any = [];
  customerNum:string = "";
  customerName:string = "";
  prodCatList:any=[];
  configuration:string = '';
  itemImage:string = '';
  comment1:string = '';
  itemUrl:string = '';
  comment2:string = '';
  emailAddress:string = '';
  comment3:string = '';
  itemName0:string = '';
  itemDesc0:string = '';
  qty0:string = '';
  startDate0:string = '';
  endDate0:string = '';
  itemName1:string = '';
  itemDesc1:string = '';
  qty1:string = '';
  startDate1:string = '';
  endDate1:string = '';
  itemName2:string = '';
  itemDesc2:string = '';
  qty2:string = '';
  startDate2:string = '';
  endDate2:string = '';
  img:any;
  imgList:any = [];
  configList:any = [];

  constructor(private createCatalogService:CreateCatalogService, private modalService: BsModalService,
              private prodCatalogService:ProductCatalogService, private sanitizer:DomSanitizer) { }

  ngOnInit() {
    this.fetchProductCatalog();
  }

  fetchProductCatalog() {
    let data = this.prodCatalogService.getProductCatalog().subscribe(
      data=>{
          this.prodCatList = data;
          this.blockUI.stop();
      });
  }


  resetValues() { 
    this.selectedFile = null;
    this.customerList = [];
    this.customerNum = "";
    this.customerName = "";
    this.configuration = "";
    this.itemImage = "";
    this.comment1 = "";
    this.itemUrl = "";
    this.comment2 = "";
    this.emailAddress = "";
    this.comment3 = "";
    this.itemName0 = "";
    this.itemDesc0 = "";
    this.qty0 = "";
    this.startDate0 = "";
    this.endDate0 = "";
    this.itemName1 = "";
    this.itemDesc1 = "";
    this.qty1 = "";
    this.startDate1 = "";
    this.endDate1 = "";
    this.itemName2 = "";
    this.itemDesc2 = "";
    this.qty2 = "";
    this.startDate2 = "";
    this.endDate2 = "";
    this.imgList = [];
    this.configList = [];
  }

  populateCustomer(row) {
    this.customerNum = row.strCustNumber;
    this.customerName = row.strCustomerName;
    this.cancelModal();
  }

  populateConfig(row) {
    this.configuration = row.strConfiguration;
    this.cancelConfigModal();
  }

  // Function to handle file selection
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  loadCustomerModal() {
    this.custSearchModal = this.modalService.show(this.custSearchModalTemplate);
  }

  loadImageModal() {
    this.searchImages();
    this.imgSearchModal = this.modalService.show(this.imgSearchModalTemplate);
  }

  loadConfigModal() {
    this.config();
    this.configSearchModal = this.modalService.show(this.configSearchModalTemplate);
  }

  config() {
    let params = {
      "isGeneric": true
    };

    let data = this.createCatalogService.getConfiguration(params).subscribe(
        data=>{
          this.configList = data;
        });
  }

  loadImageUploadModal() {
    this.imgUploadModal = this.modalService.show(this.imgUploadModalTemplate);
  }

  searchCustomer() {
    let params = {
      "customer": $('#customer').val()
    };

    let data = this.createCatalogService.getCustomers(params).subscribe(
      data=>{
          this.customerList = data;    
      });
  }

  searchImages() {
    let data = this.createCatalogService.getImages().subscribe(
      data=>{
          this.customerList = data;

          this.customerList.forEach(cust => {
            this.getImg(cust.strImageDet);   
          });  
    
      });
  }

  selectImg(i) {
    this.itemImage = this.imgList[i];
    this.cancelImgModal();
  }

  transformImg(item) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(item);
  }

  getImg(str) {
    let params = {
      "imgNm": str
    };

    let data = this.createCatalogService.getImg(params).subscribe(
      data=>{
        this.imgList.push(data.image);
      });
  }

  cancelModal() {
    this.custSearchModal.hide();
  }

  cancelImgModal() {
    this.imgSearchModal.hide();
  }

  cancelConfigModal() {
    this.configSearchModal.hide();
  }

  cancelImgUploadModal() {
    this.imgUploadModal.hide();
  }

  saveCatalog() { 
    this.blockUI.start();
    let params = {
      "action" : 'saveCatalog',
      "customerNum": this.customerNum,
      "customerName" : this.customerName,
      "configuration" : this.configuration,
      "itemImage" : this.itemImage,
      "prodCat" : '',
      "prodFam" : '',
      "comment1" : this.comment1,
      "itemUrl" : this.itemUrl,
      "comment2" : this.comment2,
      "emailAddress" : this.emailAddress,
      "comment3" : this.comment3,
      "itemCount" : 1,
      "itemType0" : 'Base Unit',
      "itemName0" : this.itemName0, 
      "itemDesc0" : this.itemDesc0,
      "qty0" : this.qty0,
      "startDate0" : this.startDate0,
      "endDate0" : this.endDate0,
      "itemType1" : 'Accessory',
      "itemName1" : this.itemName1, 
      "itemDesc1" : this.itemDesc1,
      "qty1" : this.qty1,
      "startDate1" : this.startDate1,
      "endDate1" : this.endDate1,
      "itemType2" : 'Accessory',
      "itemName2" : this.itemName2, 
      "itemDesc2" : this.itemDesc2,
      "qty2" : this.qty2,
      "startDate2" : this.startDate2,
      "endDate2" : this.endDate2
    };
    
    let data = this.createCatalogService.createCatalog(params).subscribe(
      data=>{
        this.blockUI.stop();
        console.log(data);
      });
  }
 
    // Function to handle form submission
    onSubmit() {
      // Ensure a file is selected
      if (!this.selectedFile) {
        console.error('No file selected');
        return;
      }
  
      // Assuming you want to send the form data to the same URL as in the original form
      const url = 'catalog.htm?mthd=ui';
  
      // Create FormData and append the file
      const formData = new FormData();
      formData.append('file', this.selectedFile);
    
      this.createCatalogService.uploadFile(formData).subscribe((response) => {
        this.configSearchModal.hide();
      })
    
    }

}
