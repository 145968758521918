import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class OpenServiceService {

	baseUrl="";
	
  	constructor(private http: HttpClient, private util : Util) { 
		this.baseUrl = this.util.getBaseUrl();
	}
	
	getOpenServiceRequests(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "servicerequest/getOSR?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getOpenServiceRequests")))
	  }	

	getAdditionalInstructions(data): Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "servicerequest/getAI?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("getAdditionalInstructions")))
	  }

	getCancelReasons(): Observable<any> {
	    let initialUrl = this.baseUrl + "servicerequest/getCancelRR";
	    return this.http
	      .post(initialUrl, null, httpOptions)
	      .pipe(catchError(this.util.handleError("getCancelReasons")))
	  }

	cancelServiceRequest(data):Observable<any> {
	    let data1 = JSON.stringify(data);
		let qryStr=$.param(data); 
	    let initialUrl = this.baseUrl + "servicerequest/cancelSR?" + qryStr;
	    return this.http
	      .post(initialUrl, data1, httpOptions)
	      .pipe(catchError(this.util.handleError("cancelServiceRequest")))
	  }

		

}
