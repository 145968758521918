import { Component, OnInit, } from '@angular/core';
import { Util } from 'src/app/app.util';
import { CommonServiceService } from 'src/app/service/common-service/common-service.service';
import { OpenServiceService } from 'src/app/service-request/open-service/open-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap'
import { RegisterService } from './register.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { canonRegisterUser } from '../common/bean/canonRegisterUser';
import { canonSearchDevice } from '../common/bean/canonSearchDevice';
import {ActivatedRoute} from '@angular/router'





@Component({
  selector: 'app-register-user',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterUserComponent implements OnInit {


	
	  @BlockUI() blockUI : NgBlockUI;
	
	  openServiceRequestList=[];
	  userAccountList=[];
	  selectedUserAccts:string="";
	  serialEqpNumber:string="";
	  requestNumber:string="";
	  model:string="";
	  users="";s
	  userList=[];
	//   modelList=[];
	  dateRanges="30";
	  numOfDaysList=[];
	  showMsg=false;
	  recPerPage=10;
	  arrPgLinks=[];
	  pageNum=1;
	  msgClass;
	  srchTxt ="";
	  numOfRecsMsg="";
	  totalRecords=0;
	  isVisible=[];
	  additionalNotes=[];
	  showNotes=[];

	  cancelReason = "";
	  cancelNotes = "";
	  reasonList = [];
	  crMdlMsg = "";
	  dispMsg = false;
	  showCRDiv = true;
	
	  cancelReqNum= "";	
	  cancelReqSource = "";
	
		
	  pleaseWaitCls:boolean = false;	

    serialList = [];
    modelList = [];
    locationList = [];
    notesList = [];
    rolesList = [];
    usersList = [];
    myForm: FormGroup;
    searchForm: FormGroup;
    uploadForm: FormGroup;
    registerUser: canonRegisterUser;
    searchDevice: canonSearchDevice;
    roleIdsJSON ={};
    roles="";
    appId="";
    regTyp="";
    searchSerialNumberHiddenValue = false;
    searchModelHiddenValue = true;
    searchAddressHiddenValue = true;
    searchCityHiddenValue = true;
    searchStateHiddenValue = true;
    searchPostalCodeHiddenValue = true;
    searchNotesHiddenValue = true;
    searchUsernameHiddenValue = true;
    serialNumberHiddenValue = false;
    modelHiddenValue = true;
    locationHiddenValue = true;
    notesHiddenValue = true;
    usersHiddenValue = true;
    massregistrationHiddenValue = true;
    userinfoHiddenValue = false;
    accountsHiddenValue = false;
    serialNumberErrorHiddenValue = true;
    modelErrorHiddenValue = true;
    locationErrorHiddenValue = true;
    notesErrorHiddenValue = true;
    usersErrorHiddenValue = true;
    firstNameHiddenValue = true;
    lastNameHiddenValue = true;
    emailHiddenValue = true;
    phoneNumberHiddenValue = true;
    rolesHiddenValue = true;
    roleDisabled = [true];
    checkDisabled = [false];
    // roleChecked = [false];
    // defaultSelectedValue = [true];
    createUserDisabled = true;
    createUserSuccessful = false;
    displayText = "";
    displayTextUpload = "";
    serialSelected = true;
    modelSelected = false;
    locationSelected = false;
    notesSelected = false;
    usersSelected = false;
    massregistrationSelected = false;

    uploadedFile: File;

    processingText = "";
    resultsTextHiddenValue = true;
    entityDescriptionJSON={};
    
    listChecked = false;
	  @ViewChild('cancel-request-modal') cancelRequestModal: ElementRef;

  	constructor(private commonService:CommonServiceService
        ,private registerService: RegisterService
				, private formBuilder: FormBuilder
        ,private activatedRoute: ActivatedRoute) { 

         

        }

  	ngOnInit() {

      this.registerUser = new canonRegisterUser();
      this.searchDevice = new canonSearchDevice();
      // this.showMsg = false;
      
      
        this.activatedRoute.params.subscribe(params => {
          
          // this.activatedRoute.queryParams.subscribe(params => {
          let param = params['qry'];
          console.log("^^^^^^^^^^^^^^^^^^^^^"+param); // Print the parameter to the console. 
          if(param === 'serial'){
            this.regTyp="SERIAL";
            this.serialSelected = true;
          }else if(param === 'model'){
            this.regTyp="MODEL";
            this.modelSelected = true
          }else if(param === 'location'){
            this.regTyp = "LOCATION";
            this.locationSelected = true;
          }
          else if(param === 'notes'){
            this.regTyp = "NOTES";
            this.notesSelected = true;
          }
          else if(param === 'username'){
            this.regTyp = "USERNAME";
            this.usersSelected = true;
          }
          else if(param === 'massreg'){
            this.regTyp = "MASS_REGISTRATION";
            this.massregistrationSelected = true;
            this.userinfoHiddenValue = true;
          }
          else{
            console.log("*************** not a valid path");
          }

          console.log("^^^^^^^^"+this.regTyp);
          

          




this.serialNumberErrorHiddenValue = true;
this.modelErrorHiddenValue = true;
this.locationErrorHiddenValue = true;
this.notesErrorHiddenValue = true;
this.usersErrorHiddenValue = true;
this.firstNameHiddenValue = true;
this.lastNameHiddenValue = true;
this.emailHiddenValue = true;
this.phoneNumberHiddenValue = true;
this.rolesHiddenValue = true;
this.createUserDisabled = false;
this.createUserSuccessful = false;
this.displayText = "";

// this.getRoles();



let data = this.commonService.getUserAccounts().subscribe(
    data=>{
      this.userAccountList = data.data;
    });

// this.commonService.setCommonFunctions(); 	 

this.myForm = this.formBuilder.group({
  inputEmail: new FormControl("", [Validators.required, Validators.email])
  , serialNumber: new FormControl("", Validators.required)
  , model: new FormControl("", Validators.required)
  , partySiteNum: new FormControl("", Validators.required)
  , notes: new FormControl("", Validators.required)
  , username: new FormControl("", Validators.required)
  , inputFirstName: new FormControl("", Validators.required)
  , inputLastName: new FormControl("", Validators.required)
  , phoneNumber: new FormControl("", [Validators.required,Validators.pattern('^[0-9]{10}$')])
  // , accessType: new FormControl("", Validators.required)
  // ,roleIds: new FormControl("", Validators.required)
  
});

this.searchForm = this.formBuilder.group({
  
  serialNumber: new FormControl(""),
  model: new FormControl(""),
  address: new FormControl(""),
  city: new FormControl(""),
  state: new FormControl(""),
  postalCode: new FormControl(""),
  notes: new FormControl(""),
  username: new FormControl("")
  
});

this.uploadForm = this.formBuilder.group({fileName: new FormControl("")})

console.log(this.roleDisabled);

this.getData(1,null);

      });

      
      this.commonService.setCommonFunctions(); 
        
  	}

    

    

	clearAll(event:any) {
	
		if(event){
	      event.preventDefault();
	    }

		this.selectedUserAccts="";
		this.requestNumber="";
		this.serialEqpNumber="";
		this.model="";
		this.users="";
		this.dateRanges="30";
		
		for(let actIdx in this.userAccountList){
	      this.userAccountList[actIdx]["enabled"]=true;
	    }

	}

  selectAll(){

    if(this.regTyp === 'MODEL'){
      this.listChecked = !(this.listChecked );
      // console.log(this.modelList);
      // console.log(this.modelList['modelNumList']);
      if(this.listChecked == false){
        this.registerUser.regValues = "";
      }else{
      for(let row of this.modelList){
        if(this.registerUser.regValues != ""){
          if(!(this.registerUser.regValues.indexOf(row['modelName']) > 0))
            this.registerUser.regValues = this.registerUser.regValues + ","+row['modelName'];
        }
        else{
          this.registerUser.regValues = row['modelName'];
        }
        // console.log(row);
      }
    }
      
    }
    else if(this.regTyp === 'LOCATION'){
      this.listChecked = !(this.listChecked );
      // console.log(this.modelList);
      // console.log(this.modelList['modelNumList']);
      if(this.listChecked == false){
        this.registerUser.regValues = "";
      }else{
      for(let row of this.locationList){
        if(this.registerUser.regValues != ""){
          if(!(this.registerUser.regValues.indexOf(row['partySiteNum']) > 0))
            this.registerUser.regValues = this.registerUser.regValues + ","+row['partySiteNum'];
        }
        else{
          this.registerUser.regValues = row['partySiteNum'];
        }
        // console.log(row);
      }
    }
      
    }

    else if(this.regTyp === 'NOTES'){
      this.listChecked = !(this.listChecked );
      // console.log(this.modelList);
      // console.log(this.modelList['modelNumList']);
      if(this.listChecked == false){
        this.registerUser.regValues = "";
      }else{
      for(let row of this.notesList){
        if(this.registerUser.regValues != ""){

          row.note1+'~#'+row.note2
          if(!(this.registerUser.regValues.indexOf(row['note1']) > 0) || !(this.registerUser.regValues.indexOf(row['note2']) > 0) )
            this.registerUser.regValues = this.registerUser.regValues + ","+row['note1']+"~#"+row['note2'];
        }
        else{
          this.registerUser.regValues = row['note1']+"~#"+row['note2'];
        }
        // console.log(row);
      }
    }
      
    }

    else if(this.regTyp === 'USERNAME'){
      this.listChecked = !(this.listChecked );
      // console.log(this.modelList);
      // console.log(this.modelList['modelNumList']);
      if(this.listChecked == false){
        this.registerUser.regValues = "";
      }else{
      for(let row of this.usersList){
        if(this.registerUser.regValues != ""){
          if(!(this.registerUser.regValues.indexOf(row['userName']) > 0))
            this.registerUser.regValues = this.registerUser.regValues + ","+row['userName'];
        }
        else{
          this.registerUser.regValues = row['userName'];
        }
        // console.log(row);
      }
    }
      
    }

    console.log(this.registerUser.regValues);
  }

 resetRequired(id,value,checked:any = false){
  console.log("^^^^^----------->>");
  console.log('value of:' , id , ',is:' , value ,',checked is:'+checked);

  if(checked === true ){

    if ((this.registerUser.regValues != undefined && this.registerUser.regValues.trim() != "" && !(this.registerUser.regValues.indexOf(value) > 0))){
      this.registerUser.regValues = this.registerUser.regValues +","+value;
    }else{
      this.registerUser.regValues = value;
    }
  }
  else{

    this.registerUser.regValues = this.registerUser.regValues.replace(value,"");
    
  }

  

  // if(new RegExp("^,").test(this.registerUser.regValues) ){
    this.registerUser.regValues= this.registerUser.regValues.replace(/^,/,"");
    
  // }

  // if(new RegExp(",$").test(this.registerUser.regValues) ){
    this.registerUser.regValues = this.registerUser.regValues.replace(/,$/,"");
    
  // }

  
  // console.log(this.registerUser.model.replace(/,+/g,","));
   
  this.registerUser.regValues = this.registerUser.regValues.replace(/,+/g,",");

  // if(this.registerUser.model.split(",")[0] === "")

  console.log("this.registerUser.regValues:"+this.registerUser.regValues);
  
  
  if(id === "inputFirstName" && value != undefined && value.trim() != ""){
    this.firstNameHiddenValue = true;
  }
  if(id === "inputLastName" && value != undefined && value.trim() != ""){
    this.lastNameHiddenValue = true;
  }
  if(id === "inputEmail" && value != undefined && value.trim() != ""){
    this.emailHiddenValue = true;
  }
  if(id === "phoneNumber" && value != undefined && value.trim() != ""){
    this.phoneNumberHiddenValue = true;
  }

  
 }

  getRoles(){
    this.registerService.getRoles().subscribe((response) => {

      // console.log(response);

      var rolesArray = response.roles;

      this.appId = response.appcodes[0].appId;

      // console.log(rolesArray);

      var entityArray=[];

      // var rolesJSON = {};

      for (let d of rolesArray){
        // console.log(d);
        // console.log(d["entity"]);
        if (!(entityArray.some(fn=> fn === d['entity']))){
          // console.log('does not exist...');
          entityArray.push(d["entity"]);
        }
        else{
          // console.log('exists');
        }

        // console.log(entityArray);

      }

      
      
      for(let entity of entityArray){
        
        if (entity != "DASHBOARD")
          this.rolesList.push(rolesArray.filter(fn => fn["entity"] === entity ));
        if(entity == "CP_ACCOUNT")
          this.entityDescriptionJSON[entity]="ACCOUNT"
        else if(entity == "CP_PRODUCTS")
          this.entityDescriptionJSON[entity]="PRODUCTS"
        else if(entity == "CP_INV_PMT")
          this.entityDescriptionJSON[entity]="INVOICE PAYMENT"
        else if(entity == "CP_SERVICE")
          this.entityDescriptionJSON[entity]="SERVICE"
        else if(entity == "CP_METER_READS")
          this.entityDescriptionJSON[entity]="METER READS"
        else if(entity == "CP_REPORT")
          this.entityDescriptionJSON[entity]="REPORT"
        

      }

      
      console.log(this.rolesList);

      for (let i in this.rolesList){
        this.roleDisabled[i] = true;
        // this.defaultSelectedValue[i] = true;
        // this.roleChecked[i] = false;

      }

      

    })

    
  }

  getRegTyp(value) {
    console.log("print -------------*********" );
    console.log('regTyp value is' , value );
    
  
    this.regTyp = value;
  
    
  
    this.searchForm = this.formBuilder.group({
  
      serialNumber: new FormControl(""),
      model: new FormControl(""),
      address: new FormControl(""),
      city: new FormControl(""),
      state: new FormControl(""),
      postalCode: new FormControl(""),
      notes: new FormControl(""),
      username: new FormControl("")
      
    });
  
    this.getData(1,null);
    this.searchDevice = null;
    this.searchDevice = new canonSearchDevice();
  
    
  }

  getData(pageNumber,event:any){


console.log("in getData().......................");

if(this.regTyp === "SERIAL"){

  this.serialSelected = true;
  this.modelSelected = false;
  this.locationSelected = false;
  this.notesSelected = false;
  this.usersSelected = false;
  this.massregistrationSelected = false;
  
  this.searchSerialNumberHiddenValue = false;
  this.searchModelHiddenValue = true;
  this.searchAddressHiddenValue = true;
  this.searchCityHiddenValue = true;
  this.searchStateHiddenValue = true;
  this.searchPostalCodeHiddenValue = true;
  this.searchNotesHiddenValue = true;
  this.searchUsernameHiddenValue = true;
  
  this.serialList=[];
  
  
  this.serialNumberHiddenValue = false;
  this.modelHiddenValue = true;
  this.locationHiddenValue = true;
  this.notesHiddenValue = true;
  this.usersHiddenValue = true;
  this.massregistrationHiddenValue = true;

  this.userinfoHiddenValue = false;
  this.accountsHiddenValue = false;

  
  // this.serialNumberErrorHiddenValue = true;
  this.getSerialNumbers(pageNumber,event);
}
else if(this.regTyp === "MODEL"){

    this.modelSelected = true;
    this.serialSelected = false;
    this.locationSelected = false;
    this.notesSelected = false;
    this.usersSelected = false;
    this.massregistrationSelected = false;

    this.searchModelHiddenValue = false;
    this.searchSerialNumberHiddenValue = true;
    this.searchAddressHiddenValue = true;
    this.searchCityHiddenValue = true;
    this.searchStateHiddenValue = true;
    this.searchPostalCodeHiddenValue = true;
    this.searchNotesHiddenValue = true;
    this.searchUsernameHiddenValue = true;

    this.modelList=[];
    
    this.modelHiddenValue = false;
    this.serialNumberHiddenValue = true;
    this.locationHiddenValue = true;
    this.notesHiddenValue = true;
    this.usersHiddenValue = true;
    this.massregistrationHiddenValue = true;

    this.userinfoHiddenValue = false;
    this.accountsHiddenValue = false;

    // this.modelErrorHiddenValue = true;
    
    
    this.getModels(pageNumber,event);
}
else if(this.regTyp === "LOCATION"){

  console.log("in location.........%%%%%%%%%");

  this.locationSelected = true;
  this.serialSelected = false;
  this.modelSelected = false;
  this.notesSelected = false;
  this.usersSelected = false;
  this.massregistrationSelected = false;

  this.searchSerialNumberHiddenValue = true;
  this.searchModelHiddenValue = true;
  this.searchAddressHiddenValue = false;
  this.searchCityHiddenValue = false;
  this.searchStateHiddenValue = false;
  this.searchPostalCodeHiddenValue = false;
  this.searchNotesHiddenValue = true;
  this.searchUsernameHiddenValue = true;

  this.locationList=[];
  
  this.locationHiddenValue = false;
  this.modelHiddenValue = true;
  this.serialNumberHiddenValue = true;
  this.notesHiddenValue = true;
  this.usersHiddenValue = true;
  this.massregistrationHiddenValue = true;

  this.userinfoHiddenValue = false;
  this.accountsHiddenValue = false;

  // this.modelErrorHiddenValue = true;
  
  
  this.getLocations(pageNumber,event);
}
else if(this.regTyp === "NOTES"){

this.notesSelected = true;
this.serialSelected = false;
this.modelSelected = false;
this.locationSelected = false;
this.usersSelected = false;
this.massregistrationSelected = false;

this.searchNotesHiddenValue = false;
this.searchSerialNumberHiddenValue = true;
this.searchModelHiddenValue = true;
this.searchAddressHiddenValue = true;
this.searchCityHiddenValue = true;
this.searchStateHiddenValue = true;
this.searchPostalCodeHiddenValue = true;
this.searchUsernameHiddenValue = true;

this.notesList=[];

this.notesHiddenValue = false;
this.serialNumberHiddenValue = true;
this.modelHiddenValue = true;
this.locationHiddenValue = true;
this.usersHiddenValue = true;
this.massregistrationHiddenValue = true;

this.userinfoHiddenValue = false;
this.accountsHiddenValue = false;


// this.modelErrorHiddenValue = true;


this.getNotes(pageNumber,event);
}
else if(this.regTyp === "USERNAME"){

this.usersSelected = true;
this.locationSelected = false;
this.serialSelected = false;
this.modelSelected = false;
this.notesSelected = false;
this.massregistrationSelected = false;

this.searchUsernameHiddenValue = false;
this.searchSerialNumberHiddenValue = true;
this.searchModelHiddenValue = true;
this.searchAddressHiddenValue = true;
this.searchCityHiddenValue = true;
this.searchStateHiddenValue = true;
this.searchPostalCodeHiddenValue = true;
this.searchNotesHiddenValue = true;

this.usersList=[];

this.usersHiddenValue = false;
this.locationHiddenValue = true;
this.modelHiddenValue = true;
this.serialNumberHiddenValue = true;
this.notesHiddenValue = true;
this.massregistrationHiddenValue = true;

this.userinfoHiddenValue = false;
this.accountsHiddenValue = false;

// this.modelErrorHiddenValue = true;


this.getUsers(pageNumber,event);
}
else if(this.regTyp === "MASS_REGISTRATION"){

this.massregistrationSelected = true;
this.locationSelected = false;
this.serialSelected = false;
this.modelSelected = false;
this.notesSelected = false;
this.usersSelected = false;


this.searchSerialNumberHiddenValue = true;
this.searchModelHiddenValue = true;
this.searchAddressHiddenValue = true;
this.searchCityHiddenValue = true;
this.searchStateHiddenValue = true;
this.searchPostalCodeHiddenValue = true;
this.searchNotesHiddenValue = true;
this.searchUsernameHiddenValue = true;

// this.usersList=[];

this.massregistrationHiddenValue = false;
this.locationHiddenValue = true;
this.modelHiddenValue = true;
this.serialNumberHiddenValue = true;
this.notesHiddenValue = true;
this.usersHiddenValue = true;

this.userinfoHiddenValue = true;
this.accountsHiddenValue = true;

// this.modelErrorHiddenValue = true;


// this.getUsers(1,null);
}
else{
  console.log("TBD>>>>>........");
  
}

    this.firstNameHiddenValue = true;
    this.lastNameHiddenValue = true;
    this.emailHiddenValue = true;
    this.phoneNumberHiddenValue = true;
    this.rolesHiddenValue = true;
    this.serialNumberErrorHiddenValue = true;
    this.modelErrorHiddenValue = true;

    this.myForm.enable();
    this.rolesList = [];
    this.getRoles();
    
    this.createUserSuccessful = false;
    this.createUserDisabled = false;

    for (let i in this.rolesList){
      this.roleDisabled[i] = true;
      this.checkDisabled[i] = false;
    }
    

    
  }

  

  

  getSerialNumbers(pagenumber,event: any) {

    if(event!=null){
      event.preventDefault();
    }

    this.showMsg=false;
    //this.pleaseWaitCls=true;
    this.blockUI.start();

    this.pageNum=pagenumber;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Full User list : " + this.userAccountList);
    console.log("Sel User list : " + this.selectedUserAccts);

    var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"selectedUserAccts":this.selectedUserAccts,
			
	    };

    this.registerService.getSerialNumbers(this.selectedUserAccts).subscribe((response) => {

      console.log(response);

      // console.log(response.serialNumList);
      this.totalRecords = response.serialNumList.split(",").length;
      console.log(this.totalRecords);

      if(this.searchForm.value['serialNumber'] != undefined && this.searchForm.value['serialNumber'] != "" ){
        console.log("search form serial number:&&&&---->",this.searchForm.value['serialNumber'])

        this.totalRecords = 0;
        this.serialList=null;

        for(let i of response.data){
          if(i.serialNumber === this.searchForm.value['serialNumber']){
            this.serialList = [];
            this.serialList.push(i);
            console.log(this.serialList);
            this.totalRecords = 1;
          }
        }
      }
      
      else{

      var pagesData={};

      var y=1;

      // var jsonObj = JSON.parse('""');

      const chunkSize = this.recPerPage;
      for (let i = 0; i < response.data.length; i += chunkSize) {
          const chunk = response.data.slice(i, i + chunkSize);
          // do 
          // let y=i+=1
          // jsonObj[(i++).toString()].push(chunk);
          pagesData[y++] = chunk


      }

      // console.log(pagesData);

      this.serialList = pagesData[pagenumber];

      
			//this.pleaseWaitCls=false;

    }

    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
    this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

    if(this.totalRecords == 0) {
    this.showMsg=true;
    }
			this.blockUI.stop();
      
      // this.myForm.reset();
      // this.myForm.disable();
      // this.submitButtonDisabledValue = true

    })

    

  }

  getModels(pagenumber,event: any) {

    if(event!=null){
      event.preventDefault();
    }

    this.showMsg=false;
    //this.pleaseWaitCls=true;
    this.blockUI.start();

    this.pageNum=pagenumber;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Full User list : " + this.userAccountList);
    console.log("Sel User list : " + this.selectedUserAccts);

    var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"selectedUserAccts":this.selectedUserAccts,
			
	    };

    this.registerService.getModels(this.selectedUserAccts).subscribe((response) => {

      console.log(response);

      // console.log(response.serialNumList);
      this.totalRecords = response.modelNumList.split(",").length;
      console.log(this.totalRecords);

      if(this.searchForm.value['model'] != undefined && this.searchForm.value['model'] != "" ){
        console.log("search form model:&&&&---->",this.searchForm.value['model'])

        this.totalRecords = 0;
        this.modelList=null;

        for(let i of response.data){
          if(i.modelNumber === this.searchForm.value['model']){
            this.modelList = [];
            this.modelList.push(i);
            console.log(this.modelList);
            this.totalRecords = 1;
          }
        }
      }else{

      var pagesData={};

      var y=1;

      // var jsonObj = JSON.parse('""');

      const chunkSize = this.recPerPage;
      for (let i = 0; i < response.data.length; i += chunkSize) {
          const chunk = response.data.slice(i, i + chunkSize);
          // do 
          // let y=i+=1
          // jsonObj[(i++).toString()].push(chunk);
          pagesData[y++] = chunk


      }

      // console.log(pagesData);

      this.modelList = pagesData[pagenumber];

      
			//this.pleaseWaitCls=false;

    }

    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
    this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

    if(this.totalRecords == 0) {
    this.showMsg=true;
    }
			this.blockUI.stop();
      
      // this.myForm.reset();
      // this.myForm.disable();
      // this.submitButtonDisabledValue = true

    })

    

  }

  getLocations(pagenumber,event: any) {

    if(event!=null){
      event.preventDefault();
    }

    this.showMsg=false;
    //this.pleaseWaitCls=true;
    this.blockUI.start();

    this.pageNum=pagenumber;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Full User list : " + this.userAccountList);
    console.log("Sel User list : " + this.selectedUserAccts);

    var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"selectedUserAccts":this.selectedUserAccts,
			
	    };

    this.registerService.getLocations(this.selectedUserAccts).subscribe((response) => {

      console.log(response);

      

      this.totalRecords = 0;
      this.locationList=[];

      if((this.searchForm.value['address'] != undefined && this.searchForm.value['address'] != "") ||
          (this.searchForm.value['city'] != undefined && this.searchForm.value['city'] != "" ) ||
            (this.searchForm.value['state'] != undefined && this.searchForm.value['state'] != "") ||
              (this.searchForm.value['postalCode'] != undefined && this.searchForm.value['postalCode'] != "")
              ){

                console.log("search parameter entered......for location.....");

      if(this.searchForm.value['address'] != undefined && this.searchForm.value['address'] != "" ){
        console.log("search form address:&&&&---->",this.searchForm.value['address'])

        

        for(let i of response.data){
          if(i.address === this.searchForm.value['address']){
            
            this.locationList.push(i);
            console.log(this.locationList);
            this.totalRecords += 1;
          }
        }
      }
      
      

      if(this.searchForm.value['city'] != undefined && this.searchForm.value['city'] != "" ){
        console.log("search form city:&&&&---->",this.searchForm.value['city'])

        

        for(let i of response.data){
          if(i.city === this.searchForm.value['city']){
            
            this.locationList.push(i);
            console.log(this.locationList);
            this.totalRecords += 1;
          }
        }
      }

      if(this.searchForm.value['state'] != undefined && this.searchForm.value['state'] != "" ){
        console.log("search form state:&&&&---->",this.searchForm.value['state'])

        

        for(let i of response.data){
          if(i.state === this.searchForm.value['state']){
            
            this.locationList.push(i);
            console.log(this.locationList);
            this.totalRecords += 1;
          }
        }
      }

      if(this.searchForm.value['postalCode'] != undefined && this.searchForm.value['postalCode'] != "" ){
        console.log("search form address:&&&&---->",this.searchForm.value['postalCode'])

        

        for(let i of response.data){
          if(i.postalCode === this.searchForm.value['postalCode']){
            
            this.locationList.push(i);
            console.log(this.locationList);
            this.totalRecords += 1;
          }
        }
      }
      
    }
      else{

      // console.log(response.serialNumList);
      this.totalRecords = response.partySiteNumList.split(",").length;
      console.log(this.totalRecords);

      var pagesData={};

      var y=1;

      // var jsonObj = JSON.parse('""');

      const chunkSize = this.recPerPage;
      for (let i = 0; i < response.data.length; i += chunkSize) {
          const chunk = response.data.slice(i, i + chunkSize);
          // do 
          // let y=i+=1
          // jsonObj[(i++).toString()].push(chunk);
          pagesData[y++] = chunk


      }

      // console.log(pagesData);

      this.locationList = pagesData[pagenumber];

      
			//this.pleaseWaitCls=false;

    }

    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
    this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

    if(this.totalRecords == 0) {
    this.showMsg=true;
    }
			this.blockUI.stop();
      
      // this.myForm.reset();
      // this.myForm.disable();
      // this.submitButtonDisabledValue = true

    })

    

  }

  getNotes(pagenumber,event: any) {

    if(event!=null){
      event.preventDefault();
    }

    this.showMsg=false;
    //this.pleaseWaitCls=true;
    this.blockUI.start();

    this.pageNum=pagenumber;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Full User list : " + this.userAccountList);
    console.log("Sel User list : " + this.selectedUserAccts);

    var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"selectedUserAccts":this.selectedUserAccts,
			
	    };

    this.registerService.getNotes(this.selectedUserAccts).subscribe((response) => {

      console.log(response);

      // console.log(response.serialNumList);
      this.totalRecords = response.notesList.split("~#").length;
      console.log(this.totalRecords);

      if(this.searchForm.value['notes'] != undefined && this.searchForm.value['notes'] != "" ){
        console.log("search form notes:&&&&---->",this.searchForm.value['notes'])

        this.totalRecords = 0;
        this.notesList=null;

        for(let i of response.data){
          if(i.note1 === this.searchForm.value['notes'] || i.note2 === this.searchForm.value['notes']){
            this.notesList = [];
            this.notesList.push(i);
            console.log(this.notesList);
            this.totalRecords = 1;
          }
        }
      }else{

      var pagesData={};

      var y=1;

      // var jsonObj = JSON.parse('""');

      const chunkSize = this.recPerPage;
      for (let i = 0; i < response.data.length; i += chunkSize) {
          const chunk = response.data.slice(i, i + chunkSize);
          // do 
          // let y=i+=1
          // jsonObj[(i++).toString()].push(chunk);
          pagesData[y++] = chunk


      }

      // console.log(pagesData);

      this.notesList = pagesData[pagenumber];

      
			//this.pleaseWaitCls=false;

    }

    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
    this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

    if(this.totalRecords == 0) {
    this.showMsg=true;
    }
			this.blockUI.stop();
      
      // this.myForm.reset();
      // this.myForm.disable();
      // this.submitButtonDisabledValue = true

    })

    

  }

  getUsers(pagenumber,event: any) {

    if(event!=null){
      event.preventDefault();
    }

    this.showMsg=false;
    //this.pleaseWaitCls=true;
    this.blockUI.start();

    this.pageNum=pagenumber;
    this.selectedUserAccts=this.commonService.getSelectedUserAccounts(this.userAccountList);
    console.log("Full User list : " + this.userAccountList);
    console.log("Sel User list : " + this.selectedUserAccts);

    var param = {
			"pageNum": pagenumber,
			"pageSize": this.recPerPage, 
			"selectedUserAccts":this.selectedUserAccts,
			
	    };

    this.registerService.getUsers(this.selectedUserAccts).subscribe((response) => {

      console.log(response);

      // console.log(response.serialNumList);
      this.totalRecords = response.data.length;
      console.log(this.totalRecords);

      if(this.searchForm.value['username'] != undefined && this.searchForm.value['username'] != "" ){
        console.log("search form username:&&&&---->",this.searchForm.value['username'])

        this.totalRecords = 0;
        this.usersList=null;

        for(let i of response.data){
          if(i.userName === this.searchForm.value['username'] ){
            this.usersList = [];
            this.usersList.push(i);
            console.log(this.usersList);
            this.totalRecords = 1;
          }
        }
      }else{

      var pagesData={};

      var y=1;

      // var jsonObj = JSON.parse('""');

      const chunkSize = this.recPerPage;
      for (let i = 0; i < response.data.length; i += chunkSize) {
          const chunk = response.data.slice(i, i + chunkSize);
          // do 
          // let y=i+=1
          // jsonObj[(i++).toString()].push(chunk);
          pagesData[y++] = chunk


      }

      // console.log(pagesData);

      this.usersList = pagesData[pagenumber];

      
			//this.pleaseWaitCls=false;

    }

    this.numOfRecsMsg=this.commonService.getNumOfRecsMsg(this.totalRecords,this.recPerPage,this.pageNum);
    this.arrPgLinks=this.commonService.getPgLinks(this.totalRecords,this.pageNum,this.recPerPage);

    if(this.totalRecords == 0) {
    this.showMsg=true;
    }
			this.blockUI.stop();
      
      // this.myForm.reset();
      // this.myForm.disable();
      // this.submitButtonDisabledValue = true

    })

    

  }

  

  getRoleIds(id , value) {
    console.log("print -------------*********" );
    console.log('value of' , id , 'is' , value );
    // console.log(pointer[pointer.selectedIndex].value); 
    // console.log(pointer[pointer.selectedIndex].id);

    this.roleIdsJSON[id]=value;

    console.log(this.roleIdsJSON);

    if(value != undefined && value.trim() != "" && value != 'Select'){
      this.rolesHiddenValue = true;
    }

    
 }

 


  createUser(){
    
    if (this.myForm == undefined ) {
    console.log("Undefined.......................>>>>>");
  } else {
    // this.myForm.valueChanges
    console.log(this.myForm.value);
    console.log(this.myForm.value["inputEmail"]);
    
    this.registerUser.serialNumber = this.myForm.value["serialNumber"];
    this.registerUser.model = this.myForm.value["model"];
    this.registerUser.partySiteNum = this.myForm.value["partySiteNum"];
    this.registerUser.notes = this.myForm.value["notes"];
    this.registerUser.username = this.myForm.value["username"];
    
    this.registerUser.firstName = this.myForm.value["inputFirstName"];
    this.registerUser.lastName = this.myForm.value["inputLastName"];
    this.registerUser.emailAddress = this.myForm.value["inputEmail"];
    this.registerUser.phoneNumber = this.myForm.value["phoneNumber"];

    this.serialNumberErrorHiddenValue = true;
    this.modelErrorHiddenValue = true;
    this.firstNameHiddenValue = true;
    this.lastNameHiddenValue = true;
    this.emailHiddenValue = true;
    this.phoneNumberHiddenValue = true;
    this.rolesHiddenValue = true;

    

    console.log("roleIds.......................>>>>>")
    // console.log( Object.values(this.roleIdsJSON));
    var cnt=0;
    this.roles="";
    for (let i in this.roleIdsJSON){
      console.log(i);
      if(cnt >0){
        this.roles = this.roles+"~_"+this.roleIdsJSON[i];
        // this.roles = "~_"+this.roles;
      }
      else{
        this.roles = this.roles+this.roleIdsJSON[i]
      }
      cnt++;
    }
    this.roles=this.appId+":"+this.roles+"~";
    console.log(this.roles);

    this.registerUser.regType = this.regTyp;
    if(this.regTyp === "SERIAL"){
      this.registerUser.regValues = this.myForm.value["serialNumber"];
    }
    // else if(this.regTyp === "MODEL"){
    //     this.registerUser.regValues = this.myForm.value["model"];
    // }
    // else if(this.regTyp === "LOCATION"){
    //   this.registerUser.regValues = this.myForm.value["partySiteNum"];
    // }
    // else if(this.regTyp === "NOTES"){
    //   this.registerUser.regValues = this.myForm.value["notes"];
    // }
    // else if(this.regTyp === "USERNAME"){
    //   this.registerUser.regValues = this.myForm.value["username"];
    // }
    this.registerUser.roles = this.roles;

    if (!(this.validateForm())){
     console.log("form not valid..................")
    }
    else{

    // this.registerUser.roleIds = this.myForm.value["roleIds"];


    

    // this.registerUser.adminOpt = this.accessType === 'flexRadioDefault2' ? 'Y' :'N' ;
    // this.registerUser.adminOpt = this.myForm.value["accessType"];
    this.blockUI.start();
    console.log(this.registerService.createUser(this.registerUser).subscribe((response) => {

      console.log(response);
      console.log(response.errFlg);

      // this.registerStatus = response;

      

      if(response.errFlg != undefined && response.errFlg != 'E'){
        this.createUserSuccessful = true;
        this.myForm.reset();
        //this.myForm.disable();
        this.createUserDisabled = false;
        $("select.selRl").each(function (){
          $(this).val("");
          });
      
          $(".chekBox123").each(function (){
            $(this).prop("checked", false);
            });

            for (let i in this.rolesList){
              this.roleDisabled[i] = true;
              this.checkDisabled[i] = false;
            }
          for(let j in  this.roleIdsJSON){
            this.roleIdsJSON[j]="";
          }
        this.displayText = `<div class="row submissionSuccess" style="display: none;">
        <div class="row mycsa-obj-side-wrap">
          <div class="alert alert-success" role="alert">
            <span style="font-family:'OpticSansMedium'; font-size: 16px;">
            User Created Successfully.</span>
          </div>
        </div>
      </div>`;
        
      }
      else{
        this.displayText = `<div class="row submissionError" style="display: none;">
        <div class="row mycsa-obj-side-wrap">
          <div class="alert alert-danger" role="alert">
            <span style="font-family:'OpticSansMedium'; font-size: 16px;">
            `+response.errMsg+`
            </span>
          </div>
        </div>
      </div>`;
      }
      this.blockUI.stop();
    }
    ));
  }
  }



}

enableRole(i,id){
  console.log("++++++_________________>>");
  console.log(i);
  console.log(id);
  
  this.roleDisabled[i]= !this.roleDisabled[i]

  if(this.roleDisabled[i] === true){
    
  }
  
  
}

onCloseModal() {
  this.myForm.reset();
  this.searchForm.reset();
  this.uploadForm.reset();

  $("select.selRl").each(function (){
    $(this).val("");
    });

    $(".chekBox123").each(function (){
      $(this).prop("checked", false);
      });  
  for (let i in this.rolesList){
    this.roleDisabled[i] = true;
    this.checkDisabled[i] = false;
  }
for(let j in  this.roleIdsJSON){
  this.roleIdsJSON[j]="";
}
  
this.rolesHiddenValue = false;


  //location.reload();
}

validateForm(){
  var isValid = true;

  if(this.regTyp === "SERIAL"){
    console.log("serial number hidden value...."+this.serialNumberErrorHiddenValue)
    console.log("serial value...."+this.registerUser.serialNumber)
    if((this.registerUser.serialNumber === undefined || (this.registerUser.serialNumber).trim() === "")){
        this.serialNumberErrorHiddenValue = false;
        isValid = false;
    }
  }
  else if(this.regTyp === "MODEL"){
    console.log("model hidden value...."+this.modelErrorHiddenValue)
    console.log("model value...."+this.registerUser.regValues)
    if((this.registerUser.regValues === undefined || (this.registerUser.regValues).trim() === "")){
        this.modelErrorHiddenValue = false;
        isValid = false;
    }
  }
  else if(this.regTyp === "LOCATION"){
    console.log("location hidden value...."+this.locationErrorHiddenValue)
    // console.log("location value...."+this.registerUser.regValues)
    // if((this.registerUser.regValues === undefined || (this.registerUser.regValues).trim() === "")){
    console.log("location value...."+this.registerUser.partySiteNum)
    if((this.registerUser.partySiteNum === undefined || (this.registerUser.partySiteNum).trim() === "")){
        this.locationErrorHiddenValue = false;
        isValid = false;
    }
  }
  else if(this.regTyp === "NOTES"){
    console.log("notes hidden value...."+this.notesErrorHiddenValue)
    console.log("notes value...."+this.registerUser.regValues)
    if((this.registerUser.regValues === undefined || (this.registerUser.regValues).trim() === "")){
        this.notesErrorHiddenValue = false;
        isValid = false;
    }
  }
  else if(this.regTyp === "USERNAME"){
    console.log("users hidden value...."+this.usersErrorHiddenValue)
    console.log("users value...."+this.registerUser.regValues)
    if((this.registerUser.regValues === undefined || (this.registerUser.regValues).trim() === "")){
        this.usersErrorHiddenValue = false;
        isValid = false;
    }
  }
  

  if((this.registerUser.firstName === undefined || (this.registerUser.firstName).trim() === "")){
    this.firstNameHiddenValue = false;
    isValid = false;
  }

  if((this.registerUser.lastName === undefined || (this.registerUser.lastName).trim() === "")){
    this.lastNameHiddenValue = false;
    isValid = false;
  }

  if((this.registerUser.phoneNumber === undefined || (this.registerUser.phoneNumber).trim() === "" )){
    this.phoneNumberHiddenValue = false;
    isValid = false;
  }

  if((this.registerUser.emailAddress === undefined || (this.registerUser.emailAddress).trim() === "")){
    this.emailHiddenValue = false;
    isValid = false;
  }

  console.log("roles-------------------->>")

  console.log(this.roles+"00000");

  if( this.roles === "" || (this.roles.includes(':~') && this.roles.split(":~")[1].trim() == "")){
    console.log("roles is empty.......")
    this.rolesHiddenValue = false;
    isValid = false;
  }else{
    this.rolesHiddenValue = true;
  }

  if(this.myForm.get('phoneNumber').hasError('pattern')){
    isValid = false;
  }

  

  return isValid;


}


	
	
downloadExcel() { 

  this.registerService.downloadTemplate().subscribe((response) => {

    console.log("got the response........");
    console.log(response);
    console.log(response.headers);


    // var blob = new Blob([response], {type: 'application/xlsx'});
    var blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url= window.URL.createObjectURL(blob);
    let a = document.createElement("a") 
    a.href = url;
    a.download = "CanonMassUserUploadtemplate.xls";
    // console.log(url);
    // window.open(url,"test.xls");

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
          
    

  })

}

getFile(files: FileList){
  console.log(files.item(0));
  this.uploadedFile = files.item(0);
}

uploadExcel(){

  var fileName = this.uploadForm.value['fileName'];
  var file = this.uploadedFile;

  console.log("before uploading file......");
  console.log(file);

  if(file != undefined){

  var formData:FormData = new FormData();
  formData.append('file1',this.uploadedFile);

  this.processingText = `<span style="display: none;">
  
  It may take a few minutes to complete the processing...<br>Please wait while your file is being processed.
  
  </span>`

  

  this.registerService.uploadTemplate(formData).subscribe((response) => {
    console.log(response);

    this.processingText = ``;

    if (response.statusFlg === 'S') {

      console.log('Status is ..........' + response.statusFlg);
      // this.displayText = this.sanitized.bypassSecurityTrustHtml(`<div>Hello....</div>`);
      this.displayTextUpload = `<div class="row submissionSuccess" style="display: none;">
    <div class="row mycsa-obj-side-wrap">
      <div class="alert alert-success" role="alert">
        <<br>&nbsp;&nbsp;<h3 align="center">Upload Results</h3>		
        <p align="center">
         <BR>
         &nbsp;&nbsp;Thank you for taking the time to submit the registration. 
       <BR><br><font color="009933"><b>
       &nbsp;&nbsp;The user details have been successfully processed and the user will receive a notification of the registration.
       </b></font><BR><br><BR>
      </div>
    </div>
  </div>`;

      // console.log(this.displayText);
    }
    else if (response.statusFlg === 'E') {
      console.log('Status is E ..........');
      // this.displayText = this.sanitized.bypassSecurityTrustHtml(`<div>Hello....</div>`);
      this.displayTextUpload = `<div class="row submissionError" style="display: none;">
    <div class="row mycsa-obj-side-wrap">
      <div class="alert alert-danger" role="alert">
      <p align="center">
			<BR>
			&nbsp;&nbsp;Thank you for taking the time to submit the registration. 
			<BR><br> <font color="800000"><b>
	   	    &nbsp;&nbsp;There are records that require your attention.
			<BR>
			&nbsp;&nbsp;Please review the results and resubmit. 
			</b></font>
			<BR><br>
			</p>
      </div>
    </div>
  </div>`;
    }

    this.resultsTextHiddenValue = false;

  }

  
  );

}else{
  console.log("file is empty.....");
  this.processingText = `<span style="display: none;">
  
  File is empty...Please choose a file before clicking upload.
  
  </span>`
}

}

reviewResults(event: Event) {
  event.preventDefault();
  console.log("review results.......")
  this.registerService.reviewResults().subscribe((response) => {
    console.log(response);

    var blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url= window.URL.createObjectURL(blob);
    let a = document.createElement("a") 
    a.href = url;
    a.download = "CanonMassUserUploadResults.xls";
    // console.log(url);
    // window.open(url,"test.xls");

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  );
}

stopVideo(id: string){
  console.log('Printing id value: ', id);
  const video = document.getElementById(id) as HTMLVideoElement;
  if(video){
   video.pause();
   //video.load();
  }
 }
	
}


 