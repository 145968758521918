import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  oldPassword: any;
  newPassword: any;
  confirmPassword: any;
  messageColor: string;
  message: string;
  userName: string;
  userFullName: string;
  blockUI: any;
  userIdle: any;
 twoFactorFlag ="";
  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService, private titleService: Title) { }
  ngOnInit() {
    this.titleService.setTitle("Change Password");
      this.userName =this.getAuthenticatedUser();
  }
  resetPassword()
{
  var inputParams = { oldPassword:this.oldPassword, 
    newPassword : this.newPassword, confirmPassword : this.confirmPassword ,resetusername : this.userName};
  var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
   var enoughRegex = new RegExp("(?=.{8,}).*", "g");
   
   var noError = false;
   if (false == enoughRegex.test(this.newPassword)) {
     this.messageColor  = "alert alert-danger";
     this.message = "Password should have minimum 8 characters.";  
   }else if(this.newPassword != this.confirmPassword){
     this.messageColor  = "alert alert-danger";
     this.message = "New password & Confirm password should be same.";
   } else if (mediumRegex.test(this.newPassword)) {  
     this.messageColor = "alert alert-success";
     this.message = "Strong password";
     noError = true;
   } else {
     this.messageColor  = "alert alert-danger";
     this.message = "Password should have 1 Uppercase, 1 Lowercase , 1 Number";  
   }
   if(noError)
   {
      this.loginAuthenticationService.resetPassword(inputParams).subscribe
     (data =>{
      console.log(data.status);
      console.log("Reset component data:"+data); 
      if(data.status == "S"){
        this.twoFactorFlag =sessionStorage.getItem('oTwoFacAuthFlg');
       if(this.twoFactorFlag == "N")
        {
          this.router.navigate(['twoFactor']);
      }
      else
      {
        this.router.navigate(['home']);  
      }
      }   
     else
     {
      this.messageColor  = "alert alert-danger";
      this.message = "Error resetting password";
     }
   },
    error=>{
      console.log(error);
     
    }
     );
   }
}

getAuthenticatedUser() {
	this.userName =sessionStorage.getItem('userName');
    return this.userName;
  }
  getAuthenticatedUserFullName() {
    this.userFullName =sessionStorage.getItem('fullName');
      return this.userFullName;
    }
}
